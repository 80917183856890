import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { AddressDto, CreateAddressDto, UpdateAddressDto } from "./address.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<AddressDto>(`Address/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<AddressDto[]>(`Address`, config);
  },
  Create(dto: CreateAddressDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AddressDto>("Address", dto, config);
  },
  Update(dto: UpdateAddressDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<AddressDto>("Address", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`Address/${id}`, config);
  },
};

export default services;
