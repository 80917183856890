import { useNavigate, useParams } from "react-router-dom";
import IcrafoyServives from "../../services/icra-foy/icra-foy-services";
import { useContext, useEffect, useState } from "react";
import {
  IcraFoyDto,
  IcraFoyKarsiYanDto,
  IcraFoyMuvekkilDto,
} from "../../services/icra-foy/icra-foy.dto";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MuvekkilTipi } from "../../services/muvekkil/muvekkil.dto";
import { TabPanel, TabView } from "primereact/tabview";
import TalepBilgisi from "./components/talep-bilgisi/TalepBilgisi";
import TarihceKullaniciNotu from "./components/tarihce-kullanici-notlari/TarihceKullaniciNotu";
import TebligatBilgisi from "./components/tebligat-bilgisi/TebligatBilgisi";
import TalimatDosyasi from "./components/talimat-dosyasi/TalimatDosyasi";

import { NumericFormat } from "react-number-format";
import DepartmentText from "../../components/primitives/DepartmentText";
import moment from "moment";
import AdresBilgileri from "./components/adres-bilgileri/AdresBilgileri";
import IletisimBilgileri from "./components/iletisim-bilgileri/IletisimBilgileri";
import BelgeArsivi from "./components/belge-arsivi/BelgeArsivi";
import { useQuery } from "@tanstack/react-query";
import TalepBilgisiService from "../../services/talep-bilgisi/talep-bilgisi.services";
import { TalepDurumu } from "../../services/talep-bilgisi/talep-bilgisi.dto";
import AlacakKalemi from "./components/alacak-kalemi/AlacakKalemi";
import MasrafBilgisi from "./components/masraf-bilgisi/MasrafBilgisi";
import TahsilatBilgisi from "./components/tahsilat-bilgisi/TahsilatBilgisi";
import { Dialog } from "primereact/dialog";
import KampanyaMektubu from "./components/kampanya-mektubu/KampanyaMektubu";
import UrunBilgisi from "./components/urun-bilgisi/UrunBilgisi";
import { LayoutContext } from "../../layout/context/layoutcontext";

const IcraFoyDetail = (props: any) => {
  let { id } = useParams();

  const { setBlocked } = useContext(LayoutContext)
  const [tumDosyalar, setTumDosyalar] = useState<IcraFoyDto[]>([]);
  const [visibleBorcluTumDosyalariDialog, setVisibleBorcluTumDosyalariDialog] =
    useState<boolean>(false);

  const {
    data: icraFoyResponse,
    refetch: refetchIcraFoy,
    isLoading: icraFoyIsLoading,
  } = useQuery({
    queryKey: ["IcraFoy"],
    refetchOnMount: "always",
    queryFn: () => IcrafoyServives.Get(Number(id)),
  });

  const {
    data: talepBilgisiResponse,
    refetch: refetchTalepBilgisi,
    isLoading: talepBilgisiResponseIsLoading,
  } = useQuery({
    queryKey: ["TalepBilgisi"],
    refetchOnMount: true,
    queryFn: () => TalepBilgisiService.GetAllByIcraFoy(Number(id)),
  });

  const [selectedBorclu, setSelectedBorclu] = useState<
    IcraFoyKarsiYanDto | undefined
  >(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    setBlocked(talepBilgisiResponseIsLoading || icraFoyIsLoading)
  }, [talepBilgisiResponseIsLoading, icraFoyIsLoading])
  useEffect(() => {
    if (icraFoyResponse?.result?.data?.icraFoyKarsiYans[0]) {
      setSelectedBorclu(icraFoyResponse?.result?.data?.icraFoyKarsiYans[0]);
    }
  }, [id, icraFoyIsLoading]);

  useEffect(() => {
    refetchIcraFoy();
  }, [id]);

  useEffect(() => {
    if (selectedBorclu) {
      setBlocked(true)
      IcrafoyServives.GetAllByBorcluId(selectedBorclu.karsiYan.id).then(
        (res) => {
          if (!res?.result?.error && res?.result?.data)
            setTumDosyalar(res?.result?.data);
          setBlocked(false)
        }
      ).finally(() => setBlocked(false));
    }
  }, [selectedBorclu]);

  const TalepDurumuBody = () => {
    if (talepBilgisiResponse?.result?.data.length === 0) {
      return <div className="bg-red-500 text-white py-1 px-1">Yok</div>;
    }

    if (
      talepBilgisiResponse?.result?.data.filter(
        (x) =>
          x.talepDurumu !== TalepDurumu.Kabul &&
          x.talepDurumu !== TalepDurumu.RedUyarisiz
      ).length === 0
    ) {
      return (
        <div className="bg-green-500 text-white py-1 px-1">
          <i className="pi pi-check" style={{ color: "white" }}></i>
        </div>
      );
    }

    return (
      <div className="bg-yellow-500 text-white py-1 px-1">
        <i
          className="pi pi-exclamation-triangle "
          style={{ color: "black" }}
        ></i>
      </div>
    );
  };

  const tabs: any[] = [
    {
      title: "Alacak Kalemleri",
      content: icraFoyResponse?.result?.data ? (
        <AlacakKalemi icraFoy={icraFoyResponse.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Masraf Bilgileri",
      content: icraFoyResponse?.result?.data ? (
        <MasrafBilgisi icraFoy={icraFoyResponse.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Tahsilat Bilgileri",
      content: icraFoyResponse?.result?.data ? (
        <TahsilatBilgisi icraFoy={icraFoyResponse.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Talep Bilgileri",
      content: icraFoyResponse?.result?.data ? (
        <TalepBilgisi icraFoy={icraFoyResponse.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Tarihçe/Kullanıcı Notları",
      content: icraFoyResponse?.result?.data ? (
        <TarihceKullaniciNotu icraFoy={icraFoyResponse?.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Adres Bilgileri",
      content: icraFoyResponse?.result?.data ? (
        <AdresBilgileri
          borclular={icraFoyResponse?.result?.data.icraFoyKarsiYans}
          onUpsert={refetchIcraFoy}
          selectedBorcluId={selectedBorclu?.karsiYanId}
        />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "İletişim Bilgileri",
      content: icraFoyResponse?.result?.data ? (
        <IletisimBilgileri
          borclular={icraFoyResponse?.result?.data.icraFoyKarsiYans}
          onUpsert={refetchIcraFoy}
          selectedBorcluId={selectedBorclu?.karsiYanId}
        />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Ürün Bilgileri",
      content: icraFoyResponse?.result?.data ? (
        <UrunBilgisi icraFoy={icraFoyResponse?.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Haciz Bilgileri",
      content: "content 6",
      isActive: true,
    },
    {
      title: "Taahhüt Bilgileri",
      content: "content 7",
      isActive: true,
    },
    {
      title: "Tebligat Bilgileri",
      content: icraFoyResponse?.result?.data ? (
        <TebligatBilgisi icraFoy={icraFoyResponse?.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Belge Arşivi",
      content: icraFoyResponse?.result?.data ? (
        <BelgeArsivi icraFoy={icraFoyResponse?.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Kampanya Mektupları",
      content: icraFoyResponse?.result?.data ? (
        <KampanyaMektubu icraFoy={icraFoyResponse?.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
    {
      title: "Müzekkereler",
      content: "content 12",
      isActive: false,
    },
    {
      title: "Talimat Dosyaları",
      content: icraFoyResponse?.result?.data ? (
        <TalimatDosyasi icraFoy={icraFoyResponse?.result?.data} />
      ) : (
        <></>
      ),
      isActive: true,
    },
  ];

  return (
    <div>
      <div
        className="p-4 font-bold border-round-lg"
        style={{ background: "#fff", border: "1px solid #e5e7eb" }}
      >
        <h4 className="m-0">İcra Föy Detay</h4>
      </div>

      <div className="grid mt-3">
        <div className="col-12 bg-white">
          <div
            className="border-1 border-round px-3 py-2 h-full"
            style={{ border: "1px solid #e5e7eb" }}
          >
            <div className="font-semibold text-lg pt-3 pb-0 text-primary">
              Dosya Bilgileri
            </div>

            <div className="grid mt-2">
              <div className="col-12 md:col-6 grid">
                <div className="col-6 font-bold flex justify-content-between">
                  <p className="m-0">FÖY NO</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  {icraFoyResponse?.result?.data?.foyNo}
                </div>

                <div className="col-6 font-bold flex justify-content-between">
                  <p className="m-0">İCRA MÜD.</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  <DepartmentText
                    value={icraFoyResponse?.result?.data?.icraMudurlugu}
                  />
                </div>

                <div className="col-6 font-bold flex justify-content-between">
                  <p className="m-0">DOSYA NO</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  {icraFoyResponse?.result?.data?.dosyaNo}
                </div>

                <div className="col-6 font-bold flex justify-content-between">
                  <p className="m-0 text-red-500">BÜR. VER. TARİHİ</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  {moment(icraFoyResponse?.result?.data?.kayitTarihi).format(
                    process.env.REACT_APP_DATE_FORMAT
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6 grid">
                <div className="col-6 font-bold flex justify-content-between">
                  <p className="m-0">TAKİP TARİHİ</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  {moment(icraFoyResponse?.result?.data?.takipTarihi).format(
                    process.env.REACT_APP_DATE_FORMAT
                  )}
                </div>

                <div className="col-6 font-bold flex justify-content-between">
                  <p className="m-0">TAKİP ÇIKIŞI</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  {icraFoyResponse?.result?.data?.takipCikisi
                    ?.toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "TRY",
                      currencyDisplay: "code",
                    })
                    .replace("TRY", "")}
                </div>

                <div className="col-6 font-bold flex justify-content-between">
                  <p className="m-0">TAKİP ŞEKLİ</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  {icraFoyResponse?.result?.data?.takipSekli}
                </div>

                <div className="col-6 font-bold flex justify-content-between">
                  <p
                    className="m-0 text-primary cursor-pointer"
                    onClick={() => setVisibleBorcluTumDosyalariDialog(true)}
                  >
                    BORÇLUNUN TÜM DOSYALARI
                  </p>
                  <p>:</p>
                </div>
                <div className="col-6">{tumDosyalar.length}</div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          onHide={() => {
            setVisibleBorcluTumDosyalariDialog(false);
          }}
          visible={visibleBorcluTumDosyalariDialog}
          header={
            <div className="font-semibold text-lg text-primary">
              Borçlunun Tüm Dosyaları
            </div>
          }
        >
          <div className="col-12 bg-white">
            <div className="overflow-x-auto">
              <DataTable
                value={tumDosyalar}
                showGridlines
                scrollable
                scrollHeight="250px"
                style={{ minHeight: "7rem" }}
                selectionMode={"single"}
                onSelectionChange={(e: any) => {
                  navigate(`/app/icra-foy-detay/${e.value.id}`);
                  setVisibleBorcluTumDosyalariDialog(false);
                }}
              >
                <Column
                  field="icraMudurlugu"
                  header="İCRA MÜDÜRLÜĞÜ"
                  sortable
                  body={(rowData: IcraFoyDto) => {
                    return (
                      <span>
                        <DepartmentText value={rowData.icraMudurlugu} />
                      </span>
                    );
                  }}
                ></Column>
                <Column
                  field="dosyaNo"
                  header="DOSYA NO"
                  sortable
                  sortField="dosyaNo"
                ></Column>
              </DataTable>
            </div>
          </div>
        </Dialog>
      </div>

      <div className="grid mt-3">
        <div className="col-12 xl:col-4 bg-white">
          <div
            className="border-1 border-round px-3 py-2 h-full"
            style={{ border: "1px solid #e5e7eb" }}
          >
            <div className="overflow-x-auto">
              <div className="col-12 font-semibold text-lg text-primary">
                Müvekkiller
              </div>
              <DataTable
                value={icraFoyResponse?.result?.data?.icraFoyMuvekkils}
                showGridlines
                scrollable
                scrollHeight="150px"
                style={{ minHeight: "7rem" }}
              >
                <Column
                  field="muvekkil.adSoyad"
                  header="ADI SOYADI/UNVANI"
                  sortable
                  body={(rowData: IcraFoyMuvekkilDto) => {
                    return (
                      <span>
                        {rowData.muvekkil.tip === MuvekkilTipi.Gercek
                          ? rowData.muvekkil.adSoyad
                          : rowData.muvekkil.unvan}
                      </span>
                    );
                  }}
                ></Column>
                <Column
                  field="tip"
                  header="TARAF TİPİ"
                  sortable
                  body={(rowData: IcraFoyMuvekkilDto) => {
                    return <span>{rowData.tip}</span>;
                  }}
                ></Column>
              </DataTable>
            </div>

            <div className="mt-5 overflow-x-auto">
              <div className="col-12 font-semibold text-lg text-primary">
                Borçlular
              </div>
              <DataTable
                value={icraFoyResponse?.result?.data?.icraFoyKarsiYans.map(
                  (x: IcraFoyKarsiYanDto) => {
                    return {
                      ...x,
                      adSoyad:
                        x.karsiYan.tip === MuvekkilTipi.Gercek
                          ? x.karsiYan.adSoyad
                          : x.karsiYan.unvan,
                    };
                  }
                )}
                showGridlines
                scrollable
                scrollHeight="160px"
                style={{ minHeight: "7rem" }}
                selectionMode={"single"}
                selection={selectedBorclu}
                onSelectionChange={(e: any) => setSelectedBorclu(e.value)}
                dataKey="karsiYanId"
                loading={icraFoyIsLoading}
              >
                <Column
                  field="adSoyad"
                  header="ADI SOYADI/UNVANI"
                  sortable
                  body={(rowData: IcraFoyKarsiYanDto) => {
                    return (
                      <span>
                        {rowData.karsiYan.tip === MuvekkilTipi.Gercek
                          ? rowData.karsiYan.adSoyad
                          : rowData.karsiYan.unvan}
                      </span>
                    );
                  }}
                ></Column>
                <Column field="tip" header="BORÇLU TİPİ" sortable></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div className="col-12 xl:col-4 bg-white">
          <div
            className="border-1 border-round px-3 py-2 h-full"
            style={{ border: "1px solid #e5e7eb" }}
          >
            <div className="col-12 font-semibold text-lg text-primary">
              Dosya Özet Bilgileri
            </div>
            <div className="border-1 bg-green-400 p-3 text-center font-bold text-4xl">
              {icraFoyResponse?.result?.data?.dosyaDurumu.name}
            </div>
            <div className="mt-3 px-2">
              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Tebligat
                </div>
                <div className="font-bold" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="text-left" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>

              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Talep
                </div>
                <div className="font-bold" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  <div className="text-center">{TalepDurumuBody()}</div>
                </div>
              </div>
              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Maaş Haczi
                </div>
                <div className="" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>
              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Araç Haczi
                </div>
                <div className="font-bold" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>
              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Tapu Haczi
                </div>
                <div className="" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>
              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Menkul Haczi
                </div>
                <div className="" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>

              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Haciz İhb.
                </div>
                <div className="" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>

              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Al. Ol. Dosyalar
                </div>
                <div className="" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>

              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Taahhüt
                </div>
                <div className="font-bold" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>

              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Son Sorgu Tarihi
                </div>
                <div className="font-bold" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>

              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Son İşlem Tarihi
                </div>
                <div className="" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>
              <div className="flex flex-row mt-2">
                <div className="font-bold" style={{ flex: "60%" }}>
                  Agent
                </div>
                <div className="" style={{ flex: "5%" }}>
                  :
                </div>
                <div className="" style={{ flex: "35%" }}>
                  {"var"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 xl:col-4 bg-white">
          <div
            className="border-1 border-round px-3 py-2 h-full"
            style={{ border: "1px solid #e5e7eb" }}
          >
            <div className="col-12 font-semibold text-lg text-primary">
              Dosya Hesabı
            </div>
          </div>
        </div>
      </div>

      <TabView className="mt-3" scrollable>
        {tabs.map((tab: any) => {
          return (
            tab.isActive && (
              <TabPanel key={tab.title} header={tab.title}>
                <p className="m-0 ">{tab.content}</p>
              </TabPanel>
            )
          );
        })}
      </TabView>
    </div>
  );
};

export default IcraFoyDetail;
