import React, { Component, useEffect, useRef, useState } from "react";
import { InvoiceStatusType, OrderDto, OrderStatusType, ProductInOrderDto } from "../../services/admin/admin.dto";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";

const Order = (props: { orders: OrderDto[] }) => {
  const [filtersOrders, setFiltersOrders] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValueOrders, setGlobalFilterValueOrders] = useState<string>("");
  const dt = useRef<DataTable<OrderDto[]>>(null);

  const onGlobalFilterChangeOrders = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filtersOrders };
    // @ts-ignore
    _filters["global"].value = value;
    setFiltersOrders(_filters);
    setGlobalFilterValueOrders(value);
  };

  const clearFilterOrders = () => {
    initFiltersOrders();
  };
  const initFiltersOrders = () => {
    setFiltersOrders({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiceMailSendDate: commonFilterDateIs,
      status: commonFilterContains,
      createdDate: commonFilterDateIs,
      invoiceSignDate: commonFilterDateIs,
      totalPrice: commonFilterContains,
      products: commonFilterContains,
      invoiceStatus: commonFilterContains,
      didInvoiceMailSend: commonFilterContains
    });
    setGlobalFilterValueOrders("");
  };

  useEffect(() => {
    initFiltersOrders();
  }, []);

  return (
    <div>
      <DataTable
        ref={dt}
        value={props.orders}
        dataKey="id"
        showGridlines
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Toplam {totalRecords} sipariş"
        filters={filtersOrders}
        // filterDisplay="row"
        //loading={subUsersResponseIsLoading}
        globalFilterFields={[
          "invoiceMailSendDate",
          "status",
          "createdDate",
          "invoiceSignDate",
          "totalPrice",
          "products",
          "invoiceStatus",
          "didInvoiceMailSend"
        ]}
        emptyMessage="Sipariş bulunmamaktadır."
        header={CreateHeader(
          "Siparişler ",
          globalFilterValueOrders,
          (e) => onGlobalFilterChangeOrders(e),
          clearFilterOrders
        )}
        columnResizeMode="expand"
      >
        {/* <Column body={actionBodyTemplateLicense} exportable={false} style={{ width: "9em" }}></Column> */}
        <Column
          field="createdDate"
          header="Sipariş Tarihi"
          sortable
          body={(rowData) => (
            <span>
              {rowData.createdDate ? moment(rowData.createdDate).format(process.env.REACT_APP_DATE_FORMAT) : ""}
            </span>
          )}
          filterField="createdDate"
          filter
          filterPlaceholder="Tarihe göre arayın"
          filterElement={Filter.DateFilterTemplate}
          filterMatchMode={FilterMatchMode.DATE_IS}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tarihe göre filtreleyin</div>}
        />
        <Column
          field="products"
          header="Ürünler"
          sortable
          body={(rowData: OrderDto) => (
            <div>
              {rowData.products.map((product, index) => (
                <div key={index}>
                  <span>{`Adet: ${product.count}, Ay: ${product.month}, Fiyat: ${product.price.toFixed(2)} TL`}</span>
                </div>
              ))}
            </div>
          )}
          filterField="products"
          filter
          filterPlaceholder="Ürün bilgilerine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Ürün bilgilerine göre filtreleyin</div>}
        ></Column>
        <Column
          field="status"
          header="Sipariş Durumu"
          sortable
          body={(rowData) => (
            <div className="flex justify-content-center align-items-center p-0 m-0">
              {rowData.status === OrderStatusType.Created ? (
                <div className="p-0 flex align-items-center">OLUŞTURULDU</div>
              ) : rowData.status === OrderStatusType.Approved ? (
                <div className="p-0 flex align-items-center">ONAYLANDI</div>
              ) : rowData.status === OrderStatusType.NotApproved ? (
                <div className="p-0 flex align-items-center">ONAYLANMADI</div>
              ) : rowData.status === OrderStatusType.Returned ? (
                <div className="p-0 flex align-items-center">İADE EDİLDİ</div>
              ) : null}
            </div>
          )}
          filterField="status"
          filter
          filterPlaceholder="Duruma göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>}
        ></Column>
        <Column
          field="totalPrice"
          header="Toplam Fiyat"
          sortable
          body={(rowData) => <span>{rowData.totalPrice.toFixed(2)} TL</span>}
          filterField="totalPrice"
          filter
          filterPlaceholder="Fiyata göre arayın"
          filterApply={Filter.FilterApplyTemplate}
          filterClear={Filter.FilterClearTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Fiyata göre filtreleyin</div>}
        />
        <Column
          field="invoiceStatus"
          header="Fatura Durumu"
          sortable
          body={(rowData) => (
            <div className="flex justify-content-center align-items-center p-0 m-0">
              {rowData.invoiceStatus === InvoiceStatusType.NotCreated ? (
                <div className="p-0 flex align-items-center">OLUŞTURULMADI</div>
              ) : rowData.invoiceStatus === InvoiceStatusType.Draft ? (
                <div className="p-0 flex align-items-center">TASLAK</div>
              ) : rowData.invoiceStatus === InvoiceStatusType.Signed ? (
                <div className="p-0 flex align-items-center">İMZALANDI</div>
              ) : null}
            </div>
          )}
          filterField="invoiceStatus"
          filter
          filterPlaceholder="Fatura Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Fatura Durumuna göre filtreleyin</div>}
        ></Column>
        <Column
          field="invoiceSignDate"
          header="Fatura İmzalanma Tarihi"
          sortable
          body={(rowData) => (
            <span>
              {moment(rowData.invoiceSignDate).isValid()
                ? moment(rowData.invoiceSignDate).format(process.env.REACT_APP_DATE_FORMAT)
                : ""}
            </span>
          )}
          filterField="invoiceSignDate"
          filter
          filterPlaceholder="Tarihe göre arayın"
          filterElement={Filter.DateFilterTemplate}
          filterMatchMode={FilterMatchMode.DATE_IS}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tarihe göre filtreleyin</div>}
        />
        <Column
          field="didInvoiceMailSend"
          header="Mail Durumu"
          sortable
          body={(rowData) => (
            <div className="flex justify-content-center align-items-center p-0 m-0">
              {rowData.didInvoiceMailSend === true ? (
                <div className="p-0 flex align-items-center">
                  <div className="px-5 py-1 bg-green-100 text-green-900 border-round font-medium">Gönderildi</div>
                </div>
              ) : (
                <div className="p-0 flex align-items-center">
                  <div className="px-2 py-1 bg-blue-100 text-blue-900 border-round font-medium text-center">
                    Gönderilmedi
                  </div>
                </div>
              )}
            </div>
          )}
          filterField="didInvoiceMailSend"
          filter
          filterPlaceholder="Mail Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Mail Durumuna göre filtreleyin</div>}
        ></Column>
        <Column
          field="invoiceMailSendDate"
          header="Mail Gönderilme Tarihi"
          sortable
          body={(rowData) => (
            <span>
              {moment(rowData.invoiceMailSendDate).isValid()
                ? moment(rowData.invoiceMailSendDate).format(process.env.REACT_APP_DATE_FORMAT)
                : ""}
            </span>
          )}
          filterField="invoiceMailSendDate"
          filter
          filterPlaceholder="Mail Gönderilme Tarihine göre arayın"
          filterElement={Filter.DateFilterTemplate}
          filterMatchMode={FilterMatchMode.DATE_IS}
          filterFooter={() => (
            <div className="px-3 pt-0 pb-3 text-center">Mail Gönderilme Tarihine göre filtreleyin</div>
          )}
        />
      </DataTable>
    </div>
  );
};

export default Order;
