import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import {
  DosyaDTO,
  HisseListDTO,
  SerhListDTO,
  SorguSonucTakbisDVO,
  TakbisHisseMahrumiyetResponseDTO,
  TakbisHisseResponseDTO
} from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const TakbisMahrumiyet = (props: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  takbisData?: SorguSonucTakbisDVO;
  takbisHisseData?: HisseListDTO;
  dosyaId: string;
  kisiKurumId: number;
}) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<SerhListDTO[]>>(null);

  const {
    data: TakbisMahrumiyetResponse,
    refetch: refetchTakbisMahrumiyet,
    isLoading: takbisMahrumiyetResponseLoading
  } = useQuery({
    queryKey: [
      "TakbisMahrumiyet",
      props.dosyaId,
      props.kisiKurumId,
      props.takbisData?.tasinmazDVO?.tasinmazId,
      props.takbisHisseData?.hisseId
    ],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() =>
        uyapService.GetTakbisMahrumiyetBilgiler(
          props.dosyaId,
          props.kisiKurumId,
          props.takbisData?.tasinmazDVO?.tasinmazId || 0,
          props.takbisHisseData?.hisseId || 0
        )
      )
  });

  useEffect(() => {
    setBlocked(takbisMahrumiyetResponseLoading)
  }, [takbisMahrumiyetResponseLoading])

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      serhBeyanTip: commonFilterContains,
      cumle: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const data = TakbisMahrumiyetResponse?.result?.serhList?.map((item, index) => ({ ...item, key: index })) || [];

    const headers = ["Tip", "Açıklama"];

    const filteredData = data.map(({ serhBeyanTip, cumle }) => ({
      serhBeyanTip,
      cumle
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TakbisMahrumiyet");
  };

  return (
    <div>
      {TakbisMahrumiyetResponse?.error && (
        <div className="card p-2 lg:p-5 mt-2">Hata : {TakbisMahrumiyetResponse.error}</div>
      )}
      {TakbisMahrumiyetResponse?.result && (
        <Dialog
          header={
            props.takbisData?.tasinmazDVO?.tasinmazId + " - " + props.takbisHisseData?.hisseId + " - Takdiyat Bilgisi"
          }
          visible={props.visible}
          maximizable
          style={{ width: "50%", height: "50%" }}
          onHide={() => {
            if (!props.visible) return;
            props.setVisible(false);
          }}
        >
          <div className="card p-2 lg:p-5 mt-2">
            <div className="overflow-x-auto">
              <DataTable
                value={TakbisMahrumiyetResponse?.result?.serhList}
                ref={dt}
                dataKey="serhBeyanId"
                showGridlines
                scrollable
                //scrollHeight="250px"
                // selectionMode={"single"}
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                emptyMessage="Taraf Bilgisi bulunamadı."
                filters={filters}
                globalFilterFields={["serhBeyanTip", "cumle"]}
                columnResizeMode="expand"
                header={CreateHeader(
                  "Takbis Mahrumiyet",
                  globalFilterValue,
                  (e) => onGlobalFilterChange(e),
                  clearFilter,
                  exportCSV,
                  exportExcel
                )}
              >
                <Column
                  header="No"
                  body={(rowData, { rowIndex }) => rowIndex + 1}
                  className="w-4rem text-center"
                ></Column>
                <Column
                  field="serhBeyanTip"
                  header="Tip"
                  sortable
                  filterField="serhBeyanTip"
                  filter
                  filterPlaceholder="Tip'e göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Tip'e göre filtreleyin</div>;
                  }}
                ></Column>
                <Column
                  field="cumle"
                  header="Açıklama"
                  sortable
                  filterField="cumle"
                  filter
                  filterPlaceholder="Açıklama'ya göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Açıklama'ya göre filtreleyin</div>;
                  }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default TakbisMahrumiyet;
