import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import UrunBilgisiService from "../../../../services/urun-bilgisi/urun-bilgisi.service";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../../../utils/DropdownValues";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useToast } from "../../../../utils/toast";
import {
  CreateUrunBilgisiDto,
  UpdateUrunBilgisiDto,
  UrunBilgisiDto,
} from "../../../../services/urun-bilgisi/urun-bilgisi.dto";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import { InputText } from "primereact/inputtext";
import { useQuery } from "@tanstack/react-query";
import IlceIlService from "../../../../services/ilce-il/ilce-il.services";
import { useContext, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const now: Date = new Date();

const UpsertUrunBilgisi = (props: any) => {
  const urunBilgisi: UrunBilgisiDto = props.urunBilgisi;
  const setUrunBilgisi = props.setUrunBilgisi;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchUrunBilgisi = props.refetchUrunBilgisi;
  const icraFoy: IcraFoyDto = props.icraFoy;
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  let updateUrunBilgisiDto: UpdateUrunBilgisiDto;

  if (urunBilgisi === null || urunBilgisi === undefined) {
    updateUrunBilgisiDto = {
      id: 0,
      no: "",
      tip: "",
      subeNo: "",
      subeAdi: "",
    };
  } else {
    updateUrunBilgisiDto = {
      ...urunBilgisi,
    };
  }

  const formik = useFormik<UpdateUrunBilgisiDto>({
    initialValues: updateUrunBilgisiDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateUrunBilgisiDto> = {};

      if (!data.no) {
        errors.no = "Ürün No alanı boş bırakılamaz";
      }

      if (!data.tip) {
        errors.tip = "Ürün Tipi alanı boş bırakılamaz";
      }

      if (!data.subeNo) {
        errors.subeNo = "Şube No alanı boş bırakılamaz";
      }

      if (!data.subeAdi) {
        errors.subeAdi = "Şube Adı alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      if (formik.values.id === 0) {
        var kampanyaTarihiCreateDto: CreateUrunBilgisiDto = {
          ...formik.values,
          icraFoyId: icraFoy.id,
        };
        UrunBilgisiService.Create(kampanyaTarihiCreateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setUrunBilgisi(undefined);
            refetchUrunBilgisi();
            toast.show("Ürün Bilgisi başarıyla eklendi.", "success");
          }
        });
      } else {
        var kampanyaMektubuUpdateDto: UpdateUrunBilgisiDto = {
          ...formik.values,
        };
        UrunBilgisiService.Update(kampanyaMektubuUpdateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setUrunBilgisi(undefined);
            refetchUrunBilgisi();
            toast.show("Ürün Bilgisi başarıyla güncellendi.", "success");
          }
        });
      }
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.id === 0 ? "Ürün Bilgisi Ekle" : "Ürün Bilgisi Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setUrunBilgisi(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setUrunBilgisi(undefined);
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={formik.submitForm}
          />
        </div>
      }
      className="wh-100-50"
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="no">
            Ürün No
          </label>
          <InputText
            id="no"
            name="no"
            value={formik.values.no}
            onChange={(e) => {
              formik.setFieldValue(
                "no",
                e.target.value.toLocaleUpperCase("tr")
              );
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "no"),
            })}
          />
          {FormikValueIsValid(formik, "no") && (
            <div className="p-error mt-3">{formik.errors.no}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tip">
            Ürün Tipi
          </label>
          <Dropdown
            id="tip"
            name="tip"
            filter
            value={formik.values.tip}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tip", e.value);
            }}
            options={DropdownDatas.GetUrunTipi()}
            optionLabel="label"
            optionValue="value"
            placeholder="Ürün Tipi Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tip"),
            })}
          />
          {FormikValueIsValid(formik, "tip") && (
            <div className="p-error mt-3">{formik.errors.tip}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="subeNo">
            Şube No
          </label>
          <InputText
            id="subeNo"
            name="subeNo"
            value={formik.values.subeNo}
            onChange={(e) => {
              formik.setFieldValue(
                "subeNo",
                e.target.value.toLocaleUpperCase("tr")
              );
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "subeNo"),
            })}
          />
          {FormikValueIsValid(formik, "subeNo") && (
            <div className="p-error mt-3">{formik.errors.subeNo}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="subeAdi">
            Şube Adı
          </label>
          <InputText
            id="subeAdi"
            name="subeAdi"
            value={formik.values.subeAdi}
            onChange={(e) => {
              formik.setFieldValue(
                "subeAdi",
                e.target.value.toLocaleUpperCase("tr")
              );
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "subeAdi"),
            })}
          />
          {FormikValueIsValid(formik, "subeAdi") && (
            <div className="p-error mt-3">{formik.errors.subeAdi}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertUrunBilgisi;
