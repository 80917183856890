import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import MasrafBilgisiService from "../../../../services/masraf-bilgisi/masraf-bilgisi.services";
import { MasrafBilgisiDto } from "../../../../services/masraf-bilgisi/masraf-bilgisi.dto";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import UpsertMasrafBilgisi from "./UpsertMasrafBilgisi";
import { Calendar } from "primereact/calendar";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const MasrafBilgisi = (props: any) => {
  const [masrafBilgisi, setMasrafBilgisi] = useState<MasrafBilgisiDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<MasrafBilgisiDto[]>>(null);
  const [visibleUpsertMasrafBilgisiDialog, setVisibleUpsertMasrafBilgisiDialog] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)
  const icraFoy: IcraFoyDto = props.icraFoy;

  const {
    data: masrafBilgisiResponse,
    refetch: refetchMasrafBilgisi,
    isLoading: masrafBilgisiResponseIsLoading
  } = useQuery({
    queryKey: ["MasrafBilgisi"],
    refetchOnMount: true,
    queryFn: () => MasrafBilgisiService.GetAllByIcraFoy(icraFoy.id)
  });

  useEffect(() => {
    setBlocked(masrafBilgisiResponseIsLoading)
  }, [masrafBilgisiResponseIsLoading])
  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Masraf Bilgisi"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertMasrafBilgisiDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: MasrafBilgisiDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setMasrafBilgisi(rowData);
            setVisibleUpsertMasrafBilgisiDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            deleteMasrafBilgisiConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dateTime: commonFilterDateIs,
      miktar: commonFilterContains,
      tur: commonFilterContains,
      aciklama: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const deleteMasrafBilgisi = (rowData: MasrafBilgisiDto) => {
    setBlocked(true)
    MasrafBilgisiService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Masraf bilgisi başarıyla silindi.", "success");
        refetchMasrafBilgisi();
      }
    });
  };

  const deleteMasrafBilgisiConfirm = (rowData: MasrafBilgisiDto) => {
    confirmDialog({
      message: "Masraf bilgisini silmek istediğinize emin misiniz?",
      header: "Masraf Bilgisi Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteMasrafBilgisi(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  // export excel
  const exportExcel = () => {
    const data = masrafBilgisiResponse?.result?.data || [];

    const headers = ["Tarih", "Miktar", "Tür", "Açıklama"];

    const filteredData = data.map((data) => ({
      dateTime: new Date(data.dateTime).toLocaleDateString(),
      miktar: data.miktar,
      tur: data.tur,
      aciklama: data.aciklama
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "MasrafBilgileri");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={masrafBilgisiResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} masraf bilgisi"
          filters={filters}
          // filterDisplay="row"
          loading={masrafBilgisiResponseIsLoading}
          globalFilterFields={["dateTime", "miktar", "tur", "aciklama"]}
          emptyMessage="Masraf bilgisi bulunamadı."
          header={CreateHeader("Masraf Bilgisi", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "6rem" }}></Column>
          <Column
            field="dateTime"
            header="Tarih"
            sortable
            body={(rowData: MasrafBilgisiDto) => {
              return <span>{new Date(rowData.dateTime).toLocaleDateString()}</span>;
            }}
            filterField="dateTime"
            filter
            filterPlaceholder="Tarihe göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Tarihe göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="miktar"
            header="Miktar"
            sortable
            body={(rowData: MasrafBilgisiDto) => {
              return (
                <span>
                  {rowData.miktar
                    ?.toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "TRY",
                      currencyDisplay: "code"
                    })
                    .replace("TRY", "")}
                </span>
              );
            }}
            filterField="miktar"
            filter
            filterPlaceholder="Miktara göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Miktara göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="tur"
            header="Tür"
            sortable
            filterField="tur"
            filter
            filterPlaceholder="Tür'e göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Tür'e göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="aciklama"
            header="Açıklama"
            sortable
            filterField="aciklama"
            filter
            filterPlaceholder="Açıklamaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Açıklamaya göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertMasrafBilgisi
        visible={visibleUpsertMasrafBilgisiDialog}
        setVisible={setVisibleUpsertMasrafBilgisiDialog}
        masrafBilgisi={masrafBilgisi}
        setMasrafBilgisi={setMasrafBilgisi}
        refetchMasrafBilgisi={refetchMasrafBilgisi}
        icraFoy={icraFoy}
      />
    </div>
  );
};

export default MasrafBilgisi;
