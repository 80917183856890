import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  TelephoneDto,
  CreateTelephoneDto,
  UpdateTelephoneDto,
} from "./telephone.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TelephoneDto>(`Telephone/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TelephoneDto[]>(`Telephone`, config);
  },
  Create(
    dto: CreateTelephoneDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TelephoneDto>("Telephone", dto, config);
  },
  Update(
    dto: UpdateTelephoneDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<TelephoneDto>("Telephone", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`Telephone/${id}`, config);
  },
};

export default services;
