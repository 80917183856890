import { useEffect, useState } from "react";
import { useToast } from "../../utils/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { t, use } from "i18next";
import { useElectron } from "../../utils/electron-connection";

const SettingsPage = () => {
  const toast = useToast();
  const [selectedFolderPath, setSelectedFolderPath] = useState<string>("");
  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center overflow-hidden"
  );
  const electron = useElectron();

  const readSettings = async () => {
    const settings = await electron.getSettings();
    if (settings?.folderPath) {
      setSelectedFolderPath(settings.folderPath);
    }
  };

  useEffect(() => {
    readSettings();
  }, []);

  const saveSettings = async () => {
    if (!selectedFolderPath) {
      toast.show("Lütfen klasör seçiniz.", "error");
      return;
    }

    const settings = await electron.getSettings();

    if (!settings) {
      toast.show("Ayarlar dosyası okunamadı.", "error");
      return;
    }

    settings.folderPath = selectedFolderPath;
    const res = await electron.setSettings(settings);

    if (!res) {
      toast.show("Ayarlar kaydedilirken bir sorun oluştu", "error");
      return;
    }

    toast.show("Ayarlar başarıyla kaydedildi.", "success");
  };

  const openFile = async () => {
    const filePath = await electron.showElectronFolderDiyalog();
    if (filePath) {
      setSelectedFolderPath(filePath);
    }
  };

  return (
    <form className={containerClassName}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
          }}
        >
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
            <div className="text-center mb-5">
              <img src="/images/logo.png" alt="Image" height="70" className="mb-3" />
              <div className="text-900 text-3xl font-medium mb-3">{t("Settings")}</div>
            </div>

            <div>
              <div className="grid">
                <div className="col-12 md:col-5 flex align-items-center">
                  <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                    {t("FolderPathForFiles")}
                  </label>
                </div>
                <div className="col-12 md:col-7">
                  <div className="p-inputgroup flex-1">
                    <Button label={t("ChangeFolderPath")} type="button" onClick={async () => await openFile()} />
                    <InputText
                      id="newEmail"
                      type="text"
                      placeholder={t("FolderPath")}
                      className={classNames("w-full md:w-30rem")}
                      value={selectedFolderPath}
                      style={{ padding: "1rem" }}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-12 mt-5 ">
                  <div className="flex justify-content-end">
                    <Button
                      label={t("SaveSettings")}
                      type="button"
                      className="p-3 text-xl"
                      onClick={saveSettings}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SettingsPage;
