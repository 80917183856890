import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions,
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import TalepBilgisiService from "../../../../services/talep-bilgisi/talep-bilgisi.services";
import { TalepBilgisiDto } from "../../../../services/talep-bilgisi/talep-bilgisi.dto";
import { useQuery } from "@tanstack/react-query";
import UpsertTalepBilgisi from "./UpsertTalepBilgisi";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import DropdownDatas from "../../../../utils/DropdownValues";
import { Calendar } from "primereact/calendar";
import { CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const TalepBilgisi = (props: any) => {
  const [talepBilgisi, setTalepBilgisi] = useState<TalepBilgisiDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TalepBilgisiDto[]>>(null);
  const [visibleUpsertTalepBilgisiDialog, setVisibleUpsertTalepBilgisiDialog] =
    useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)
  const icraFoy: IcraFoyDto = props.icraFoy;

  const {
    data: talepBilgisiResponse,
    refetch: refetchTalepBilgisi,
    isLoading: talepBilgisiResponseIsLoading,
  } = useQuery({
    queryKey: ["TalepBilgisi"],
    refetchOnMount: true,
    queryFn: () => TalepBilgisiService.GetAllByIcraFoy(icraFoy.id),
  });

  useEffect(() => {
    setBlocked(talepBilgisiResponseIsLoading)
    if (!talepBilgisiResponseIsLoading) {
      talepBilgisiResponse?.result?.data.forEach((p) => {
        p.borclularNames = p.borclular.map((p) => p.adSoyad).join(", ");
      });
    }
  }, [talepBilgisiResponseIsLoading])



  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Talep Bilgisi"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertTalepBilgisiDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: TalepBilgisiDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setTalepBilgisi(rowData);
            setVisibleUpsertTalepBilgisiDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            deleteMuvekkilConfirm(rowData);
          }}
        />
        <Button
          icon="pi pi-times"
          rounded
          outlined
          tooltip="Ret Uyarısı Kaldır"
          severity="secondary"
          onClick={() => {
            redUyarisiKaldirConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      yazimTarihi: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      talepTuru: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      borclularNames: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      talepDurumu: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      aciklama: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      creatorUserName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const deleteTalepBilgisi = (rowData: TalepBilgisiDto) => {
    setBlocked(true)
    TalepBilgisiService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Talep Bilgisi başarıyla silindi.", "success");
        refetchTalepBilgisi();
      }
    });
  };

  const retUyarisiKaldırmaTalepBilgisi = (rowData: TalepBilgisiDto) => {
    setBlocked(true)
    TalepBilgisiService.RetUyarisiKaldir(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Ret uyarısı başarıyla kaldırıldı.", "success");
        refetchTalepBilgisi();
      }
    });
  };

  const deleteMuvekkilConfirm = (rowData: TalepBilgisiDto) => {
    confirmDialog({
      message: "Talep bilgisini silmek istediğinize emin misiniz?",
      header: "Talep Bilgisi Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteTalepBilgisi(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
    });
  };

  const redUyarisiKaldirConfirm = (rowData: TalepBilgisiDto) => {
    confirmDialog({
      message:
        "Talep bilgisinin ret uyarısını kaldırmak istediğinizden emin misiniz?",
      header: "Talep Bilgisi Ret Uyarısı Kaldırma",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => retUyarisiKaldırmaTalepBilgisi(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
    });
  };

  // export excel
  const exportExcel = () => {
    const data = talepBilgisiResponse?.result?.data || [];

    const headers = ["Yazım Tarihi", "Talep Türü", "Borçlular", "Talep Durumu", "Açıklama", "Kullanıcı"];

    const filteredData = data.map((data) => ({
      yazimTarihi: new Date(data.yazimTarihi).toLocaleDateString(),
      talepTuru: data.talepTuru,
      borclularNames: data.borclularNames,
      talepDurumu: DropdownDatas.GetTalepDurumuString(data.talepDurumu),
      aciklama: data.aciklama,
      creatorUserName: data.creatorUserName,
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TalepBilgisi");

  };



  return (
    <div>
      <div className="card">
        <Toolbar
          className="mb-4"
          start={leftToolbarTemplate}
          end={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={talepBilgisiResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} talep bilgisi"
          filters={filters}
          // filterDisplay="row"
          loading={talepBilgisiResponseIsLoading}
          globalFilterFields={[
            "yazimTarihi",
            "talepTuru",
            "borclularNames",
            "talepDurumu",
            "aciklama",
            "creatorUserName",
          ]}
          emptyMessage="Talep Bilgisi bulunamadı."
          header={CreateHeader("Talep Bilgisi", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
        >
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ width: "13em" }}
          ></Column>
          <Column
            field="yazimTarihi"
            header="Yazım Tarihi"
            sortable
            body={(rowData: TalepBilgisiDto) => {
              return (
                <span>
                  {new Date(rowData.yazimTarihi).toLocaleDateString()}
                </span>
              );
            }}
            filterField="yazimTarihi"
            filter
            filterPlaceholder="Yazım Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Yazım Tarihine göre filtreleyin
                </div>
              );
            }}
          ></Column>
          <Column
            field="talepTuru"
            header="Talep Türü"
            sortable
            filterField="talepTuru"
            filter
            filterPlaceholder="Talep Türüne göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Talep Türüne göre filtreleyin
                </div>
              );
            }}
          ></Column>
          <Column
            field="borclularNames"
            header="Borçlular"
            sortable
            filterField="borclularNames"
            filter
            filterPlaceholder="Borçlulara göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Borçlulara göre filtreleyin
                </div>
              );
            }}
          ></Column>
          <Column
            field="talepDurumu"
            header="Talep Durumu"
            sortable
            body={(rowData: TalepBilgisiDto) => {
              return (
                <span>
                  {DropdownDatas.GetTalepDurumuString(rowData.talepDurumu)}
                </span>
              );
            }}
            filterField="talepDurumu"
            filter
            filterPlaceholder="Talep Durumuna göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Talep Durumuna göre filtreleyin
                </div>
              );
            }}
          ></Column>
          <Column
            field="aciklama"
            header="Açıklama"
            sortable
            filterField="aciklama"
            filter
            filterPlaceholder="Açıklamaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Açıklamaya göre filtreleyin
                </div>
              );
            }}
          ></Column>
          <Column
            field="creatorUserName"
            header="Kullanıcı"
            sortable
            filterField="creatorUserName"
            filter
            filterPlaceholder="Kullanıcıya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Kullanıcıya göre filtreleyin
                </div>
              );
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertTalepBilgisi
        visible={visibleUpsertTalepBilgisiDialog}
        setVisible={setVisibleUpsertTalepBilgisiDialog}
        talepBilgisi={talepBilgisi}
        setTalepBilgisi={setTalepBilgisi}
        refetchTalepBilgisi={refetchTalepBilgisi}
        icraFoy={icraFoy}
      />
    </div>
  );
};

export default TalepBilgisi;
