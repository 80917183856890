import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import AccountService from "../../services/account/account.service";
import AdminService from "../../services/admin/admin.services";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { Button } from "primereact/button";
import { useToast } from "../../utils/toast";
import { CreateSubUserDto, UpdateSubUserDto, UpsertSubUserDto, UserDto } from "../../services/account/account.dto";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { InputMask } from "primereact/inputmask";
import { Password } from "primereact/password";
import { useContext } from "react";
import { LayoutContext } from "../../layout/context/layoutcontext";

const UpsertSubUsersPage = (props: any) => {
  const subUser: UserDto = props.subUser;
  const setSubUser = props.setSubUser;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchSubUsers = props.refetchSubUsers;
  const isAdminPanel = props.isAdminPanel;
  const id = props.id;
  const toast = useToast();
  const { t } = useTranslation();
  const { setBlocked } = useContext(LayoutContext);

  let upsertSubUserDto: UpsertSubUserDto;

  if (subUser === null || subUser === undefined) {
    upsertSubUserDto = {
      userName: "",
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      id: ""
    };
  } else {
    upsertSubUserDto = {
      ...subUser,
      password: "",
      passwordConfirm: ""
    };
  }

  const formik = useFormik<UpsertSubUserDto>({
    initialValues: upsertSubUserDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpsertSubUserDto> = {};
      if (!data.userName) {
        errors.userName = "Kullanıcı Adı alanı boş bırakılamaz";
      }

      if (!data.firstName) {
        errors.firstName = "Ad alanı boş bırakılamaz";
      }

      if (!data.lastName) {
        errors.lastName = "Soyad alanı boş bırakılamaz";
      }

      if (!subUser) {
        if (!data.email) {
          errors.email = "Şifre alanı boş bırakılamaz";
        }

        if (!data.password) {
          errors.password = "Şifre alanı boş bırakılamaz";
        }

        if (!data.passwordConfirm) {
          errors.passwordConfirm = "Şifre Tekrar alanı boş bırakılamaz";
        }

        if (data.password !== data.passwordConfirm) {
          errors.passwordConfirm = "Şifreler uyuşmuyor";
        }

        if (!data.phoneNumber) {
          errors.phoneNumber = "Telefon Numarası alanı boş bırakılamaz";
        }
      }

      return errors;
    },
    onSubmit: async () => {
      setBlocked(true);
      if (isAdminPanel) {
        if (formik.values.id === "") {
          var createSubUserDto: CreateSubUserDto = {
            ...formik.values
          };
          const res = await AdminService.CreateSubUser(createSubUserDto, id ?? "");
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setSubUser(undefined);
            refetchSubUsers();
            toast.show("Alt kullanıcı başarıyla eklendi.", "success");
          }
        } else {
          var updateSubUserDto: UpdateSubUserDto = {
            ...formik.values
          };
          const res = await AdminService.UpdateSubUser(updateSubUserDto);
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setSubUser(undefined);
            refetchSubUsers();
            toast.show("Alt kullanıcı başarıyla güncellendi.", "success");
          }
        }
      } else {
        if (formik.values.id === "") {
          var createSubUserDto: CreateSubUserDto = {
            ...formik.values
          };
          const res = await AccountService.CreateSubUser(createSubUserDto);
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setSubUser(undefined);
            refetchSubUsers();
            toast.show("Alt kullanıcı başarıyla eklendi.", "success");
          }
        } else {
          var updateSubUserDto: UpdateSubUserDto = {
            ...formik.values
          };
          const res = await AccountService.UpdateSubUser(updateSubUserDto);
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setSubUser(undefined);
            refetchSubUsers();
            toast.show("Alt kullanıcı başarıyla güncellendi.", "success");
          }
        }
      }
      setBlocked(false);
    }
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={formik.values.id === "" ? "Alt Kullanıcı Ekle" : "Alt Kullanıcı Güncelle"}
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setSubUser(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setSubUser(undefined);
            }}
          />
          <Button label={"Kaydet"} className="md:px-6 justify-content-center" onClick={formik.submitForm} />
        </div>
      }
      className="wh-100-50"
      // style={{ width: "60vw" }}
    >
      <div className="grid">
        <div className="col-12 md:col-6">
          <label htmlFor="firstName" className="text-primary">
            {t("FirstName")}
          </label>
          <InputText
            id="firstName"
            type="text"
            placeholder={t("FirstName")}
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "firstName")
            })}
            value={formik.values.firstName}
            onChange={(e) => formik.setFieldValue("firstName", e.target.value.toLocaleUpperCase("tr"))}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "firstName") && <div className="p-error mt-3">{formik.errors.firstName}</div>}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="lastName" className="text-primary">
            Soy isim
          </label>
          <InputText
            id="lastName"
            type="text"
            placeholder="Soy isim"
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "lastName")
            })}
            value={formik.values.lastName}
            onChange={(e) => formik.setFieldValue("lastName", e.target.value.toLocaleUpperCase("tr"))}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "lastName") && <div className="p-error mt-3">{formik.errors.lastName}</div>}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="userName" className="text-primary">
            {t("UserName")}
          </label>
          <InputText
            id="userName"
            type="text"
            placeholder={t("UserName")}
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "userName")
            })}
            value={formik.values.userName}
            onChange={(e) => formik.setFieldValue("userName", e.target.value)}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "userName") && <div className="p-error mt-3">{formik.errors.userName}</div>}
        </div>
        {formik.values.id === "" && (
          <div className="col-12 md:col-6">
            <label htmlFor="email" className="text-primary">
              {t("Email")}
            </label>
            <InputText
              id="email"
              type="email"
              placeholder={t("EmailAddress")}
              className={classNames("w-full  mt-2", {
                "p-invalid": FormikValueIsValid(formik, "email")
              })}
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              style={{ padding: "1rem" }}
              autoComplete="email"
            />
            {FormikValueIsValid(formik, "email") && <div className="p-error mt-3">{formik.errors.email}</div>}
          </div>
        )}

        {formik.values.id === "" && (
          <div className="col-12 md:col-6">
            <label htmlFor="phoneNumber" className="text-primary">
              {t("PhoneNumber")}
            </label>
            <InputMask
              id="phoneNumber"
              keyfilter="int"
              mask="(0999) 999-99-99"
              placeholder={t("PhoneNumber")}
              className={classNames("w-full  mt-2", {
                "p-invalid": FormikValueIsValid(formik, "phoneNumber")
              })}
              value={formik.values.phoneNumber}
              onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
              style={{ padding: "1rem" }}
            />
            {FormikValueIsValid(formik, "phoneNumber") && (
              <div className="p-error mt-3">{formik.errors.phoneNumber}</div>
            )}
          </div>
        )}

        {formik.values.id === "" && (
          <>
            <div className="col-12 md:col-6">
              <label htmlFor="password" className="text-primary">
                {t("Password")}
              </label>
              <Password
                inputId="password"
                value={formik.values.password}
                onChange={(e) => formik.setFieldValue("password", e.target.value)}
                placeholder={t("Password")}
                autoComplete="new-password"
                className="block w-full  mt-2"
                toggleMask={true}
                feedback={false}
                inputClassName={classNames("block w-full p-3", {
                  "p-invalid": FormikValueIsValid(formik, "password")
                })}
              ></Password>
              {FormikValueIsValid(formik, "password") && <div className="p-error mt-3">{formik.errors.password}</div>}
            </div>

            <div className="col-12 md:col-6">
              <label htmlFor="passwordConfirm" className="text-primary">
                Parola Tekrar
              </label>
              <Password
                inputId="passwordConfirm"
                value={formik.values.passwordConfirm}
                onChange={(e) => formik.setFieldValue("passwordConfirm", e.target.value)}
                placeholder="Parola Tekrar"
                autoComplete="new-password"
                className="block w-full mt-2"
                toggleMask={true}
                feedback={false}
                inputClassName={classNames("block w-full p-3", {
                  "p-invalid": FormikValueIsValid(formik, "passwordConfirm")
                })}
              ></Password>
              {FormikValueIsValid(formik, "passwordConfirm") && (
                <div className="p-error mt-3">{formik.errors.passwordConfirm}</div>
              )}
            </div>
          </>
        )}

        {/* <div className="col-12 md:col-6">
          <label htmlFor="icHatDahilisi" className="text-primary">
            İç Hat Dahilisi
          </label>
          <InputText
            id="icHatDahilisi"
            type="string"
            placeholder="İç Hat Dahilisi"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "icHatDahilisi")
            })}
            value={formik.values.icHatDahilisi}
            onChange={(e) => formik.setFieldValue("icHatDahilisi", e.target.value)}
          />
          {FormikValueIsValid(formik, "icHatDahilisi") && (
            <div className="p-error mt-3">{formik.errors.icHatDahilisi}</div>
          )}
        </div>

        <div className="col-12 md:col-6">
          <label htmlFor="garantiHtsKullaniciAdi" className="text-primary">
            Garanti HTS Kullanıcı Adı
          </label>
          <InputText
            id="garantiHtsKullaniciAdi"
            type="string"
            placeholder="Garanti HTS Kullanıcı Adı"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "garantiHtsKullaniciAdi")
            })}
            value={formik.values.garantiHtsKullaniciAdi}
            onChange={(e) => formik.setFieldValue("garantiHtsKullaniciAdi", e.target.value)}
          />
          {FormikValueIsValid(formik, "garantiHtsKullaniciAdi") && (
            <div className="p-error mt-3">{formik.errors.garantiHtsKullaniciAdi}</div>
          )}
        </div>

        <div className="col-12 md:col-6">
          <label htmlFor="garantiHtsBuroKodu" className="text-primary">
            Garanti HTS Büro Kodu
          </label>
          <InputText
            id="garantiHtsBuroKodu"
            type="string"
            placeholder="Garanti HTS Büro Kodu"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "garantiHtsBuroKodu")
            })}
            value={formik.values.garantiHtsBuroKodu}
            onChange={(e) => formik.setFieldValue("garantiHtsBuroKodu", e.target.value)}
          />
          {FormikValueIsValid(formik, "garantiHtsBuroKodu") && (
            <div className="p-error mt-3">{formik.errors.garantiHtsBuroKodu}</div>
          )}
        </div>

        <div className="col-12 md:col-6">
          <label htmlFor="garantiHtsSifresi" className="text-primary">
            Garanti HTS Şifresi
          </label>
          <InputText
            id="garantiHtsSifresi"
            type="string"
            placeholder="Garanti HTS Şifresi"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "garantiHtsSifresi")
            })}
            value={formik.values.garantiHtsSifresi}
            onChange={(e) => formik.setFieldValue("garantiHtsSifresi", e.target.value)}
          />
          {FormikValueIsValid(formik, "garantiHtsSifresi") && (
            <div className="p-error mt-3">{formik.errors.garantiHtsSifresi}</div>
          )}
        </div> */}
      </div>
    </Dialog>
  );
};

export default UpsertSubUsersPage;
