import React, { useState, useRef, useEffect, useContext } from "react";
import {
  DataTable,
  DataTableExpandedRows,
  DataTableFilterMeta,
  DataTableValueArray,
} from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions,
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import TarihceKullaniciNotuService from "../../../../services/tarihce-kullanici-notlari/tarihce-kullanici-notlari.services";
import {
  TarihceKullaniciNotuDto,
  CreateTarihceKullaniciNotuDto,
  UpdateTarihceKullaniciNotuDto,
} from "../../../../services/tarihce-kullanici-notlari/tarihce-kullanici-notlari.dto";
import { useQuery } from "@tanstack/react-query";
import UpsertTarihceKullaniciNotu from "./UpsertTarihceKullaniciNotu";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import DropdownDatas from "../../../../utils/DropdownValues";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import { max } from "moment";
import { Calendar } from "primereact/calendar";
import { CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const TarihceKullaniciNotu = (props: any) => {
  const [tarihceKullaniciNotu, setTarihceKullaniciNotu] =
    useState<TarihceKullaniciNotuDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TarihceKullaniciNotuDto[]>>(null);
  const [
    visibleUpsertTarihceKullaniciNotuDialog,
    setVisibleUpsertTarihceKullaniciNotuDialog,
  ] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)
  const icraFoy: IcraFoyDto = props.icraFoy;

  const {
    data: tarihceKullaniciNotuResponse,
    refetch: refetchTarihceKullaniciNotu,
    isLoading: tarihceKullaniciNotuResponseIsLoading,
  } = useQuery({
    queryKey: ["TarihceKullaniciNotu"],
    refetchOnMount: true,
    queryFn: () => TarihceKullaniciNotuService.GetAllByIcraFoy(icraFoy.id),
  });

  useEffect(() => {
    setBlocked(tarihceKullaniciNotuResponseIsLoading)
  }, [tarihceKullaniciNotuResponseIsLoading])

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Tarihçe/Kullanıcı Notu Bilgisi"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertTarihceKullaniciNotuDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: TarihceKullaniciNotuDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setTarihceKullaniciNotu(rowData);
            setVisibleUpsertTarihceKullaniciNotuDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          onClick={() => {
            deleteTarihceKullaniciNotuConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      creationTime: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      creatorUserName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      tur: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      aciklama: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const deleteTarihceKullaniciNotuBilgisi = (
    rowData: TarihceKullaniciNotuDto
  ) => {
    setBlocked(true)
    TarihceKullaniciNotuService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Tarihçe Kullanıcı Notu başarıyla silindi.", "success");
        refetchTarihceKullaniciNotu();
      }
    });
  };

  const deleteTarihceKullaniciNotuConfirm = (
    rowData: TarihceKullaniciNotuDto
  ) => {
    confirmDialog({
      message: "Tarihçe Kullanıcı Notunu silmek istediğinize emin misiniz?",
      header: "Tarihçe Kullanıcı Notu Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteTarihceKullaniciNotuBilgisi(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
    });
  };

  // export excel
  const exportExcel = () => {
    const data = tarihceKullaniciNotuResponse?.result?.data || [];

    const headers = ["Kullanıcı", "Tarih", "Saat", "Tür", "Açıklama"];

    const filteredData = data.map((data) => ({
      creatorUserName: data.creatorUserName,
      creationDate: new Date(data.creationTime).toLocaleDateString(),
      creationTime: new Date(data.creationTime).toLocaleTimeString(),
      tur: data.tur,
      aciklama: data.aciklama,
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TarhçeKullanıcıNotu");

  };

  return (
    <div>
      <div className="card">
        <Toolbar
          className="mb-4"
          start={leftToolbarTemplate}
          end={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          ref={dt}
          value={tarihceKullaniciNotuResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} tarihce kullanıcı notları"
          filters={filters}
          // filterDisplay="row"
          loading={tarihceKullaniciNotuResponseIsLoading}
          globalFilterFields={[
            "creatorUserName",
            "tur",
            "aciklama",
            "creationTime",
          ]}
          emptyMessage="Kullanıcı tarihçe bilgisi bulunamadı."
          header={CreateHeader("Tarihçe Kullanıcı Notları", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
        >
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ width: "9rem" }}
          ></Column>
          <Column
            field="creatorUserName"
            header="Kullanıcı"
            sortable
            filterField="creatorUserName"
            filter
            filterPlaceholder="Kullanıcıya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Kullanıcıya göre filtreleyin
                </div>
              );
            }}
          ></Column>
          <Column
            field="creationTime"
            header="Tarih"
            sortable
            body={(rowData: TarihceKullaniciNotuDto) => {
              return (
                <span>
                  {rowData.creationTime
                    ? new Date(rowData.creationTime).toLocaleDateString()
                    : ""}
                </span>
              );
            }}
            filterField="creationTime"
            filter
            filterPlaceholder="Kayıt Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Kayıt Tarihine göre filtreleyin
                </div>
              );
            }}
          ></Column>
          <Column
            field="creationTime"
            header="Saat"
            sortable
            body={(rowData: TarihceKullaniciNotuDto) => {
              return (
                <span>
                  {rowData.creationTime
                    ? new Date(rowData.creationTime).toLocaleTimeString()
                    : ""}
                </span>
              );
            }}
          ></Column>
          <Column
            field="tur"
            header="Tür"
            sortable
            filterField="tur"
            filter
            filterPlaceholder="Türe göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Türe göre filtreleyin
                </div>
              );
            }}
          ></Column>
          <Column
            field="aciklama"
            header="Açıklama"
            sortable
            filterField="aciklama"
            filter
            filterPlaceholder="Açıklamaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return (
                <div className="px-3 pt-0 pb-3 text-center">
                  Açıklamaya göre filtreleyin
                </div>
              );
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertTarihceKullaniciNotu
        visible={visibleUpsertTarihceKullaniciNotuDialog}
        setVisible={setVisibleUpsertTarihceKullaniciNotuDialog}
        tarihceKullaniciNotu={tarihceKullaniciNotu}
        setTarihceKullaniciNotu={setTarihceKullaniciNotu}
        refetchTarihceKullaniciNotu={refetchTarihceKullaniciNotu}
        icraFoy={icraFoy}
      />
    </div>
  );
};

export default TarihceKullaniciNotu;
