import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AdminService from "../../../services/admin/admin.services";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../../utils/toast";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";
import { commonFilterContains, CreateHeader, Filter } from "../../filter/base-filter";
import { AppLogDto } from "../../../services/admin/admin.dto";
import { LayoutContext } from "../../../layout/context/layoutcontext";

const AppLogsPage = () => {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<AppLogDto[]>>(null);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: logResponse,
    refetch: refetchLogs,
    isLoading: logResponseIsLoading
  } = useQuery({
    queryKey: ["Logs"],
    refetchOnMount: true,
    queryFn: () => AdminService.GetAllAppLogs()
  });

  useEffect(() => {
    setBlocked(logResponseIsLoading)
  }, [logResponseIsLoading])

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "user.userName": commonFilterContains,
      "user.firstName": commonFilterContains,
      "user.lastName": commonFilterContains,
      "user.email": commonFilterContains,
      eventDateTime: commonFilterContains,
      event: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const exportExcel = () => {
    const data = logResponse?.result?.data || [];

    const headers = ["Kullanıcı Adı", "İsim", "Soyisim", "E-posta", "Ne Zaman", "Olay"];

    const filteredData = data.map((data) => ({
      userName: data.user.userName,
      firstName: data.user.userName,
      lastName: data.user.lastName,
      email: data.user.email,
      eventDateTime: data.eventDateTime ? new Date(data.eventDateTime).toLocaleDateString() : "",
      event: data.event
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "AnaKullanıcılar");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          showGridlines
          value={logResponse?.result?.data || []}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} / {last} - Toplam {totalRecords} log"
          filters={filters}
          // filterDisplay="row"
          loading={logResponseIsLoading}
          globalFilterFields={["event", "eventDateTime", "user.userName", "user.email", "user.phoneNumber", "user.tc"]}
          emptyMessage="Log bulunamadı."
          header={CreateHeader("Uygulama Logları", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
        >
          <Column
            field="user.userName"
            header="Kullanıcı Adı"
            sortable
            filterField="user.userName"
            filter
            filterPlaceholder="Kullanıcı Adına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Kullanıcı Adına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="user.firstName"
            header="İsim"
            sortable
            filterField="user.firstName"
            filter
            filterPlaceholder="İsme göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İsme göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="user.lastName"
            header="Soyisim"
            sortable
            filterField="user.lastName"
            filter
            filterPlaceholder="Soyisme göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Soyisme göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="user.email"
            header="E-posta"
            sortable
            filterField="user.email"
            filter
            filterPlaceholder="E-postaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">E-postaya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="eventDateTime"
            header="Ne Zaman"
            sortable
            filterField="eventDateTime"
            filter
            body={(rowData) => {
              const date = new Date(rowData.eventDateTime);
              return date ? date.toLocaleDateString("tr-TR") + " " + date.toLocaleTimeString("tr-TR") : "";
            }}
            filterPlaceholder="Zamana göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Tarihe göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="event"
            header="Olay"
            sortable
            showFilterMatchModes={false}
            filter
            filterPlaceholder="Olaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default AppLogsPage;
