import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MonthlyType } from "../../services/admin/admin.dto";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../utils/toast";
import { useBasket } from "../../utils/basket-provider";
import { Checkbox } from "primereact/checkbox";
import { LicenceToBeExtendedDto, NewLicenceItemDto } from "../../services/payment/payment.dto";
import paymentService from "../../services/payment/payment.services";

const BasketPage = (props: any) => {
  const toast = useToast();
  const navigate = useNavigate();
  const basket = useBasket();
  const [onay, setOnay] = useState<{
    kullanimVeLisansOnay: boolean;
    faturaBilgileriOnay: boolean;
    kvkkAcikRizaOnay: boolean;
    iysEmailOnay: boolean;
    iysSmsOnay: boolean;
    iysAramaOnay: boolean;
  }>({
    kullanimVeLisansOnay: false,
    faturaBilgileriOnay: false,
    kvkkAcikRizaOnay: false,
    iysEmailOnay: false,
    iysSmsOnay: false,
    iysAramaOnay: false
  });

  const increaseNewLicenseCount = (item: NewLicenceItemDto) => {
    const newLicenses = basket.getBasket().newLicences;
    const license = newLicenses.find((x) => x.id === item.id);
    if (license) {
      license.count++;
      basket.setBasket({
        ...basket.getBasket(),
        newLicences: [...newLicenses]
      });
    }
  };

  const decreaseNewLicenseCount = (item: NewLicenceItemDto) => {
    const newLicenses = basket.getBasket().newLicences;
    const license = newLicenses.find((x) => x.id === item.id);
    if (license) {
      if (license.count > 1) {
        license.count--;
        basket.setBasket({
          ...basket.getBasket(),
          newLicences: [...newLicenses]
        });
      }
    }
  };

  const increaseNewLicenseMonth = (item: NewLicenceItemDto) => {
    const newLicenses = basket.getBasket().newLicences;
    const license = newLicenses.find((x) => x.id === item.id);
    if (license && license.month !== MonthlyType.TwelveMonth) {
      if (license.month === MonthlyType.Monthly) {
        license.month = MonthlyType.ThreeMonth;
      } else if (license.month === MonthlyType.ThreeMonth) {
        license.month = MonthlyType.SixMonth;
      } else {
        license.month = MonthlyType.TwelveMonth;
      }

      basket.setBasket({
        ...basket.getBasket(),
        newLicences: [...newLicenses]
      });
    }
  };

  const decreaseNewLicenseMonth = (item: NewLicenceItemDto) => {
    const newLicenses = basket.getBasket().newLicences;
    const license = newLicenses.find((x) => x.id === item.id);
    if (license && license.month !== MonthlyType.Monthly) {
      if (license.month === MonthlyType.TwelveMonth) {
        license.month = MonthlyType.SixMonth;
      } else if (license.month === MonthlyType.SixMonth) {
        license.month = MonthlyType.ThreeMonth;
      } else {
        license.month = MonthlyType.Monthly;
      }

      basket.setBasket({
        ...basket.getBasket(),
        newLicences: [...newLicenses]
      });
    }
  };

  const increaseExtendLicenseMonth = (item: LicenceToBeExtendedDto) => {
    const extendLicenses = basket.getBasket().licencesToBeExtended;
    const license = extendLicenses.find((x) => x.licenseId === item.licenseId);
    if (license && license.month !== MonthlyType.TwelveMonth) {
      if (license.month === MonthlyType.Monthly) {
        license.month = MonthlyType.ThreeMonth;
      } else if (license.month === MonthlyType.ThreeMonth) {
        license.month = MonthlyType.SixMonth;
      } else {
        license.month = MonthlyType.TwelveMonth;
      }

      basket.setBasket({
        ...basket.getBasket(),
        licencesToBeExtended: [...extendLicenses]
      });
    }
  };

  const decreaseExtendLicenseMonth = (item: LicenceToBeExtendedDto) => {
    const extendLicenses = basket.getBasket().licencesToBeExtended;
    const license = extendLicenses.find((x) => x.licenseId === item.licenseId);
    if (license && license.month !== MonthlyType.Monthly) {
      if (license.month === MonthlyType.TwelveMonth) {
        license.month = MonthlyType.SixMonth;
      } else if (license.month === MonthlyType.SixMonth) {
        license.month = MonthlyType.ThreeMonth;
      } else {
        license.month = MonthlyType.Monthly;
      }

      basket.setBasket({
        ...basket.getBasket(),
        licencesToBeExtended: [...extendLicenses]
      });
    }
  };

  return (
    <div>
      <div className="card">
        <div className="grid">
          <div className="col-12 lg:col-8">
            <div className="card">
              <div className="flex justify-content-between align-items-center">
                <div className="text-2xl font-bold">Yeni Lisanslar</div>
                <Button
                  label={"Yeni Lisans Ekle"}
                  severity="success"
                  className="md:px-6"
                  onClick={() => navigate("/app/ucret-tablosu")}
                />
              </div>

              <DataTable
                value={basket.getBasket().newLicences}
                scrollable
                scrollHeight="247px"
                tableStyle={{ minWidth: "50rem" }}
                className="mt-5"
              >
                <Column
                  field="count"
                  header="Lisans Adedi"
                  body={(rowData: NewLicenceItemDto) => {
                    return (
                      <div className="flex gap-3">
                        <i
                          className="pi pi-minus text-2xl text-red-500 cursor-pointer"
                          onClick={() => decreaseNewLicenseCount(rowData)}
                        ></i>
                        <div>{rowData.count} Adet</div>
                        <i
                          className="pi pi-plus text-2xl text-green-500 cursor-pointer"
                          onClick={() => increaseNewLicenseCount(rowData)}
                        ></i>
                      </div>
                    );
                  }}
                ></Column>
                <Column
                  field="month"
                  header="Süre"
                  body={(rowData: NewLicenceItemDto) => {
                    return (
                      <div className="flex gap-3">
                        <i
                          className="pi pi-minus text-2xl text-red-500 cursor-pointer"
                          onClick={() => decreaseNewLicenseMonth(rowData)}
                        ></i>
                        <div style={{ width: "30px" }}>{rowData.month} Ay</div>
                        <i
                          className="pi pi-plus text-2xl text-green-500 cursor-pointer"
                          onClick={() => increaseNewLicenseMonth(rowData)}
                        ></i>
                      </div>
                    );
                  }}
                ></Column>
                <Column
                  field="month"
                  header="Fiyat"
                  body={(rowData: NewLicenceItemDto) => {
                    return (
                      <div>
                        {basket
                          .getALicenseTotalPrice(rowData.month, rowData.count)
                          ?.toLocaleString("tr-TR", {
                            style: "currency",
                            currency: "TRY",
                            currencyDisplay: "code"
                          })
                          .replace("TRY", "")}
                      </div>
                    );
                  }}
                ></Column>
                <Column
                  field="month"
                  header=""
                  body={(rowData: NewLicenceItemDto) => {
                    return (
                      <Button
                        icon="pi pi-times"
                        outlined
                        tooltip="Sil"
                        rounded
                        severity="danger"
                        aria-label="Sil"
                        onClick={() => {
                          const newLicenses = basket.getBasket().newLicences;
                          const license = basket
                            .getBasket()
                            .newLicences.find((x) => x.month === rowData.month && x.count === rowData.count);
                          if (license) {
                            newLicenses.splice(newLicenses.indexOf(license), 1);
                            basket.setBasket({
                              ...basket.getBasket(),
                              newLicences: [...newLicenses]
                            });
                          }
                        }}
                      />
                    );
                  }}
                ></Column>
              </DataTable>
            </div>

            <div className="card mt-5">
              <div className="flex justify-content-between align-items-center">
                <div className="text-2xl font-bold">Süresi Artacak Lisanslar</div>
                <Button
                  label={"Lisanslarım"}
                  severity="success"
                  className="md:px-6"
                  onClick={() => navigate("/app/lisanslarim")}
                />
              </div>
              <DataTable
                value={basket.getBasket().licencesToBeExtended}
                scrollable
                scrollHeight="240px"
                tableStyle={{ minWidth: "50rem" }}
                className="mt-5"
              >
                <Column field="name" header="Lisans Adı"></Column>
                <Column
                  field="month"
                  header="Süre"
                  body={(rowData: LicenceToBeExtendedDto) => {
                    return (
                      <div className="flex gap-3">
                        <i
                          className="pi pi-minus text-2xl text-red-500 cursor-pointer"
                          onClick={() => decreaseExtendLicenseMonth(rowData)}
                        ></i>
                        <div>{rowData.month} Ay</div>
                        <i
                          className="pi pi-plus text-2xl text-green-500 cursor-pointer"
                          onClick={() => increaseExtendLicenseMonth(rowData)}
                        ></i>
                      </div>
                    );
                  }}
                ></Column>
                <Column
                  field="month"
                  header="Fiyat"
                  body={(rowData: LicenceToBeExtendedDto) => {
                    return (
                      <div>
                        {basket
                          .getALicenseTotalPrice(rowData.month, 1)
                          ?.toLocaleString("tr-TR", {
                            style: "currency",
                            currency: "TRY",
                            currencyDisplay: "code"
                          })
                          .replace("TRY", "")}
                      </div>
                    );
                  }}
                ></Column>
                <Column
                  field="month"
                  header=""
                  body={(rowData: LicenceToBeExtendedDto) => {
                    return (
                      <Button
                        icon="pi pi-times"
                        outlined
                        tooltip="Sil"
                        rounded
                        severity="danger"
                        aria-label="Sil"
                        onClick={() => {
                          const licencesToBeExtended = basket.getBasket().licencesToBeExtended;
                          const license = basket
                            .getBasket()
                            .licencesToBeExtended.find((x) => x.licenseId === rowData.licenseId);
                          if (license) {
                            licencesToBeExtended.splice(licencesToBeExtended.indexOf(license), 1);
                            basket.setBasket({
                              ...basket.getBasket(),
                              licencesToBeExtended: [...licencesToBeExtended]
                            });
                          }
                        }}
                      />
                    );
                  }}
                ></Column>
              </DataTable>
            </div>
          </div>
          <div className="col-12 lg:col-4">
            <div className="card py-3 mb-3">
              <div className="flex justify-content-between align-items-center">
                <div className="text-xl font-bold">Fatura Bilgileri</div>
                <div>
                  <Button label={"Profilim"} className="md:px-6" onClick={() => navigate("/app/profile")} />
                </div>
              </div>
              <div className="grid mt-3">
                <div className="col-4">Ad:</div>
                <div className="col-8">{basket.getUser()?.firstName}</div>

                <div className="col-4">Soyad:</div>
                <div className="col-8">{basket.getUser()?.lastName}</div>

                <div className="col-4">TC:</div>
                <div className="col-8">{basket.getUser()?.tc}</div>

                {basket.getUser()?.vergiNumarasi && basket.getUser()?.vergiDairesi && (
                  <>
                    <div className="col-4">Vergi Numarası:</div>
                    <div className="col-8">{basket.getUser()?.vergiNumarasi}</div>

                    <div className="col-4">Vergi Dairesi:</div>
                    <div className="col-8">{basket.getUser()?.vergiDairesi}</div>
                  </>
                )}

                <div className="col-4">Adres:</div>
                <div className="col-8">{basket.getUser()?.address}</div>
              </div>
            </div>

            <div className="card py-3 mb-3">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="kullanimVeLisansKosullari"
                  checked={onay.kullanimVeLisansOnay === true}
                  onChange={(e) => setOnay({ ...onay, kullanimVeLisansOnay: e.checked ?? false })}
                  className="mr-2"
                ></Checkbox>
                <div>
                  <label className="text-color" htmlFor="kullanimVeLisansKosullari">
                    <a target="_blank" href="https://www.pratikportal.com/Home/KullanimLisansKosullari">
                      Pratik Portal Website ve Masaüstü Uygulaması Kullanım ve Lisans Koşullarını
                    </a>{" "}
                    kabul ediyorum.
                  </label>
                </div>
              </div>

              <div className="flex align-items-center mt-3">
                <Checkbox
                  inputId="faturaBilgileri"
                  checked={onay.faturaBilgileriOnay === true}
                  onChange={(e) => setOnay({ ...onay, faturaBilgileriOnay: e.checked ?? false })}
                  className="mr-2"
                ></Checkbox>
                <div>
                  <label className="text-color" htmlFor="faturaBilgileri">
                    Fatura bilgilerimin doğruluğunu kabul ediyorum.
                  </label>
                </div>
              </div>
            </div>

            {(basket.getUser()?.kvkkAcikRizaOnay === false ||
              basket.getUser()?.iysEmailAcikRizaOnay === false ||
              basket.getUser()?.iysPhoneAcikRizaOnay === false ||
              basket.getUser()?.iysSmsAcikRizaOnay === false) && (
              <div className="card py-3 mb-3">
                <div>
                  Kampanya ve yeniliklerden haberdar olmak üzere tarafınıza elektronik ileti ticari gönderilmesini
                  istediğiniz kanalları seçin.
                </div>
                <div className="mt-3">
                  <a
                    href="https://www.pratikportal.com/Home/IysAydinlatmaMetni"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    Ticari Elektronik İleti Aydınlatma ve Onay Metnini okumak için tıklayınız.
                  </a>
                </div>

                {basket.getUser()?.kvkkAcikRizaOnay === false && (
                  <div className="flex align-items-center mt-3">
                    <Checkbox
                      inputId="kvkkAcikRizaOnay"
                      checked={onay.kvkkAcikRizaOnay === true}
                      onChange={(e) => setOnay({ ...onay, kvkkAcikRizaOnay: e.checked ?? false })}
                      className="mr-2"
                    ></Checkbox>
                    <div>
                      <label className="text-color" htmlFor="kvkkAcikRizaOnay">
                        <a target="_blank" href="https://www.pratikportal.com/Home/AcikRizaMetni">
                          Pratik Portal Yazılımı Kişisel Verilerin İşlenmesine İlişkin Açık Rıza Metnini
                        </a>{" "}
                        onaylıyorum.
                      </label>
                    </div>
                  </div>
                )}

                {basket.getUser()?.iysEmailAcikRizaOnay === false && (
                  <div className="flex align-items-center mt-3">
                    <Checkbox
                      inputId="iysEmailOnay"
                      checked={onay.iysEmailOnay === true}
                      onChange={(e) => setOnay({ ...onay, iysEmailOnay: e.checked ?? false })}
                      className="mr-2"
                    ></Checkbox>
                    <div>
                      <label className="text-color" htmlFor="iysEmailOnay">
                        Email
                      </label>
                    </div>
                  </div>
                )}

                {basket.getUser()?.iysSmsAcikRizaOnay === false && (
                  <div className="flex align-items-center mt-2">
                    <Checkbox
                      inputId="iysSmsOnay"
                      checked={onay.iysSmsOnay === true}
                      onChange={(e) => setOnay({ ...onay, iysSmsOnay: e.checked ?? false })}
                      className="mr-2"
                    ></Checkbox>
                    <div>
                      <label className="text-color" htmlFor="iysSmsOnay">
                        SMS
                      </label>
                    </div>
                  </div>
                )}

                {basket.getUser()?.iysPhoneAcikRizaOnay === false && (
                  <div className="flex align-items-center mt-2">
                    <Checkbox
                      inputId="iysAramaOnay"
                      checked={onay.iysAramaOnay === true}
                      onChange={(e) => setOnay({ ...onay, iysAramaOnay: e.checked ?? false })}
                      className="mr-2"
                    ></Checkbox>
                    <div>
                      <label className="text-color" htmlFor="iysAramaOnay">
                        Arama
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="card py-3">
              <div className="text-2xl text-center font-bold">Toplam Tutar</div>
              <div className="text-2xl text-center mt-2 font-semibold">
                {basket
                  .getTotalPriceInBasket()
                  ?.toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                    currencyDisplay: "code"
                  })
                  .replace("TRY", "")}{" "}
              </div>
              <div className="flex justify-content-center">
                <Button
                  label={"Alışverişi Tamamla"}
                  className="md:px-6 justify-content-center mt-5 text-2xl"
                  disabled={!onay.kullanimVeLisansOnay || !onay.faturaBilgileriOnay}
                  onClick={async () => {
                    if (basket.getBasket().newLicences.length + basket.getBasket().licencesToBeExtended.length === 0) {
                      toast.show("Sepetinizde ürün bulunmamaktadır.", "error");
                      return;
                    }

                    const res = await paymentService.CreatePayment({
                      ...basket.getBasket(),
                      kvkkAcikRiza: onay.kvkkAcikRizaOnay,
                      iysEmail: onay.iysEmailOnay,
                      iysSms: onay.iysSmsOnay,
                      iysArama: onay.iysAramaOnay
                    });

                    if (res && res.result?.data) {
                      navigate("/app/payment", { state: { paymentUrl: res.result.data.paymentUrl } });
                      basket.setUserInformation();
                    }
                  }}
                />
              </div>

              <div className="text-center mt-5">
                Alışverişi tamamlayıp ödemeniz gerçekleştiğinde yukarıdaki bilgilerle faturanız kesilecektir.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketPage;
