import React, { useEffect, useState, useRef, ReactNode } from "react";
import { Button } from "primereact/button";
import { Tree, TreeExpandedKeysType } from "primereact/tree";
import { TreeNode } from "primereact/treenode";
import { FileViewerTemplateDTO } from "../../../../components/@fileViewer/fileViewer.dto";
import { Checkbox } from "primereact/checkbox";
import { Toolbar } from "primereact/toolbar";
import { EvrakNode } from "./EvrakNode";
import { useEvrakListeleyici, IFilterOptions, EvrakTreeNode } from "./useEvrakListeleyici";
import { useToast } from "../../../../utils/toast";
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from "primereact/progressspinner";
import { EvrakBulkDownloader } from "./EvrakBulkDownloader";

interface ExtendedTreeNode extends TreeNode {
  expandableKey?: string;
}

export interface EvrakListeleyiciProps {
  evraklar?: any;
  template?: FileViewerTemplateDTO[];
  onNodeClick?: (e: any) => void;
  children?: React.ReactNode;
  mainFolderLabel?: string;
  dosyaId: string;
  dosyaNo: string;
  birimAdi: string;
  dosyaTur: string;
  loading: boolean;
  bulkDownloader?: (nodes: any, setDownloadOpen: (e: boolean) => void, isDownloadOpen?: boolean, setSelection?: (arg : any) => void) => ReactNode;
  onBulkDownloadOpen?: (arg: boolean) => void;
}

const filterOptionKeys = {
  gonderenYerKisi: "Gönderen Yer Kişi",
  onaylandigiTarih: "Onaylandığı Tarih",
  sistemeGonderildigiTarih: "S. Gönderildiği Tarih",
  tip: "Tip"
};

interface IExpandedKeys {
  [key: string]: {
    [innerKey: string]: boolean;
  };
}

export const EvrakListeleyici: React.FC<EvrakListeleyiciProps> = ({
  evraklar,
  template,
  onNodeClick,
  children,
  mainFolderLabel,
  dosyaId,
  dosyaNo,
  birimAdi,
  dosyaTur,
  loading,
  bulkDownloader,
  onBulkDownloadOpen
}) => {
  const [nodes, setNodes] = useState<any>([]);
  const fileViewColRef = useRef<HTMLDivElement>(null);
  const [selection, setSelection] = useState<any>();
  const [topluIndirAktif, setTopluIndirAktif] = useState(false);
  const [secmeModu, setSecmeModu] = useState<"multiple" | "checkbox" | "single">("single");
  const [selectedDoc, setSelectedDoc] = useState<ExtendedTreeNode>();
  const { flatArray, sorter } = useEvrakListeleyici();
  const nodeRef = useRef<(HTMLDivElement | null)[]>([]);
  const expandedKeys = useRef<TreeExpandedKeysType>({
    ...(mainFolderLabel && { [mainFolderLabel]: true }),
    tumEvraklar: true,
    son20Evrak: true
  });

  const toast = useToast();
  /*
  toolbar alanlarini acar
  */
  const [isFilterOpen, setIsFilterOpne] = useState(false);
  const [isDownloadOpen, setDownloadOpen] = useState(false);
  /*
  evrak gosterim alaninin genisligini ayarlar
  */
  const [fileColWidth, setFileColWitdh] = useState(0);
  const filterInitial = {
    gonderenYerKisi: [],
    // onaylandigiTarih: [],
    // sistemeGonderildigiTarih: [],
    tip: []
  };
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({
    gonderenYerKisi: [],
    // onaylandigiTarih: [],
    // sistemeGonderildigiTarih: [],
    tip: []
  });
  const [selectedFilterOption, setSelectedFilterOption] = useState<IFilterOptions>({
    gonderenYerKisi: [],
    // onaylandigiTarih: [],
    // sistemeGonderildigiTarih: [],
    tip: []
  });

  /*
  evrak gosterim alaninin genisligini ayarlar
  */

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setFileColWitdh(entries[0].contentRect.width);
    });

    if (fileViewColRef.current) {
      observer.observe(fileViewColRef.current as HTMLDivElement);
    }

    return () => {
      if (fileViewColRef.current) {
        observer.unobserve(fileViewColRef.current);
      }
    };
  }, []);

  useEffect(() => {
    /*
    filtre secenekleri burada olusturuluyor
    */

    if (evraklar?.result?.tumEvraklar) {
      Object.keys(filterOptions).forEach((filterOption: string) => {
        Object.keys(evraklar?.result?.tumEvraklar).forEach((dosya: any) => {
          evraklar?.result?.tumEvraklar[dosya]?.forEach((evrak: any) => {
            if (!filterOptions[filterOption as keyof IFilterOptions].includes(evrak[filterOption])) {
              filterOptions[filterOption as keyof IFilterOptions].push(evrak[filterOption]);
            }
          });
        });
      });
    }
  }, [evraklar]);

  /* 
  burada evraklar liste icin sort ediliyor
  */
  useEffect(() => {
    console.log('nodes', evraklar)
    setNodes(sorter(evraklar, mainFolderLabel as string, selectedFilterOption));

    setFilterOptions(filterOptions);
  }, [evraklar, selectedFilterOption]);

  /*
  parentta asil yapilan islemi gerceklestiren yer
  */
  const nodeClickhandler = async (e: any) => {
    setSelectedDoc(e.node);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onNodeClick) onNodeClick(selectedDoc);
    }, 500);

    return () => clearTimeout(timeout);
  }, [selectedDoc]);

  const onSelectionChangeHandler = async (selections: any) => {
    setSelection(selections?.value);
  };

  useEffect(() => {
    if (topluIndirAktif) {
      setSecmeModu("checkbox");
    } else {
      setSecmeModu("single");
    }
  }, [topluIndirAktif]);

  const nextHandler = () => {
    const array = flatArray(nodes);
    const index = array.findIndex((item) => item.key == selectedDoc?.key);
    let nodeIndex = index + 1;

    while (!array[nodeIndex]?.data?.evrakId && nodeIndex - index < 5) {
      nodeIndex++;
    }

    if (array[nodeIndex]) {
      nodeRef.current[array[nodeIndex]?.key as number]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
      expandedKeys.current[array[nodeIndex]?.key as string] = true;

      if (array[nodeIndex]?.expandableKey) {
        expandedKeys.current[array[nodeIndex]?.expandableKey as string] = true;
      }
      nodeClickhandler({ node: array[nodeIndex] });
    }
  };

  const prevHandler = () => {
    const array = flatArray(nodes);
    const index = array.findIndex((item) => item.key == selectedDoc?.key);
    let nodeIndex = index - 1;

    while (!array[nodeIndex]?.data?.evrakId && index - nodeIndex < 5) {
      nodeIndex--;
    }

    if (array[nodeIndex]) {
      nodeRef.current[array[nodeIndex]?.key as number]?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      if (array[nodeIndex]) {
        if (array[nodeIndex]?.children && array[nodeIndex]!.children!.length > 0) {
          expandedKeys.current[array[nodeIndex]!.key as string] = true;
        }
        if (array[nodeIndex]?.data?.anaEvrakId) {
          expandedKeys.current[array[nodeIndex]?.data?.anaEvrakId as string] = true;
        }
      }
      nodeClickhandler({ node: array[nodeIndex] });
    }
  };

  useEffect(() => {
    if(onBulkDownloadOpen) onBulkDownloadOpen(isDownloadOpen)
    if (!isDownloadOpen) setTopluIndirAktif(false);
  }, [isDownloadOpen]);

  console.log(selection)

  return (
    <div className="grid">
      <div className="col-4 flex flex-column gap-2">
        <div className="flex gap-3">
          <div className="flex-1">
            <Button
              className="w-full"
              icon="pi pi-arrow-circle-left"
              onClick={prevHandler}
              // disabled={flatArray(nodes).findIndex((item: any) => item.key == selectedDoc?.key) <= 0}
            />
          </div>
          <div className="flex-1">
            <Button className="w-full" icon="pi pi-arrow-circle-right" onClick={nextHandler} />
          </div>
        </div>
        <div className="flex">
          <Toolbar
            className="w-full p-3"
            start={() => (
              <React.Fragment>
                <Button
                  icon="pi pi-filter"
                  className="mr-2"
                  onClick={() => {
                    setIsFilterOpne(!isFilterOpen);
                    setDownloadOpen(false);
                  }}
                />
                {bulkDownloader && (
                  <Button
                    icon="pi pi-upload"
                    onClick={() => {
                      setDownloadOpen(!isDownloadOpen);
                      setTopluIndirAktif(!isDownloadOpen);
                      setIsFilterOpne(false);
                    }}
                  />
                )}
              </React.Fragment>
            )}
          />
        </div>
        {isFilterOpen && (
          <div className="flex flex-column border-1 border-200 border-round gap-3 p-3 relative">
            <Button
              icon="pi pi-refresh"
              className="bg-teal-600 border-teal-700"
              style={{ position: "absolute", right: "0.5rem", top: "0.5rem", width: 26, height: 26 }}
              onClick={() => setSelectedFilterOption(filterInitial)}
            />
            <div className="flex flex-column  p-3 bg-100 gap-5 overflow-x-scroll" style={{ maxHeight: "40vh" }}>
              <div className="flex flex-column gap-3">
                {Object.keys(filterOptions).map((filterOption: string) => {
                  return (
                    <div className="flex flex-column gap-2">
                      <label htmlFor={filterOption} className="font-bold">
                        {filterOptionKeys[filterOption as keyof typeof filterOptionKeys]}
                      </label>
                      <div className="flex flex-wrap gap-2">
                        {(filterOption == "sistemeGonderildigiTarih" || filterOption == "onaylandigiTarih") && (
                          <div className="flex justify-content-center gap-3">
                            <Calendar
                              // value={date}
                              onChange={(e) => console.log(e.value)}
                              minDate={findMinDate(filterOptions[filterOption as keyof IFilterOptions])}
                              maxDate={findMaxDate(filterOptions[filterOption as keyof IFilterOptions])}
                              dateFormat="dd/mm/yy"
                              mask="99/99/9999"
                              locale="tr"
                            />
                            <Calendar
                              // value={date}
                              // onChange={(e) => setDate(e.value)}
                              minDate={findMinDate(filterOptions[filterOption as keyof IFilterOptions])}
                              maxDate={findMaxDate(filterOptions[filterOption as keyof IFilterOptions])}
                              dateFormat="dd/mm/yy"
                              mask="99/99/9999"
                              locale="tr"
                            />
                          </div>
                        )}
                        {filterOption != "sistemeGonderildigiTarih" &&
                          filterOption != "onaylandigiTarih" &&
                          filterOptions[filterOption as keyof IFilterOptions].map((option: string) => {
                            return (
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId={option}
                                  name={option}
                                  value={option}
                                  onChange={(e) => {
                                    if (
                                      e.checked &&
                                      !selectedFilterOption[filterOption as keyof IFilterOptions].includes(option)
                                    ) {
                                      setSelectedFilterOption((prev) => ({
                                        ...prev,
                                        [filterOption as keyof IFilterOptions]: [
                                          ...prev[filterOption as keyof IFilterOptions],
                                          option
                                        ]
                                      }));
                                    }
                                    if (!e.checked) {
                                      setSelectedFilterOption((prev) => ({
                                        ...prev,
                                        [filterOption as keyof IFilterOptions]: [
                                          ...prev[filterOption as keyof IFilterOptions].filter(
                                            (item) => item !== option
                                          )
                                        ]
                                      }));
                                    }
                                  }}
                                  checked={selectedFilterOption[filterOption as keyof IFilterOptions].includes(option)}
                                />
                                <label htmlFor={option} className="ml-2">
                                  {option}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex w-full">
              <Button
                label={"Filtrele"}
                className={"w-full"}
                onClick={() => {
                  setIsFilterOpne(false);
                }}
              ></Button>
            </div>
          </div>
        )}
        {isDownloadOpen && bulkDownloader && bulkDownloader(nodes, setDownloadOpen, isDownloadOpen, setSelection)}
        <div className="border-1 border-200 border-round h-full" style={{ maxHeight: "50vh", overflowX: "scroll" }}>
          <Tree
            value={nodes}
            onNodeClick={nodeClickhandler}
            className="w-full h-full border-0"
            filter
            filterMode="lenient"
            filterPlaceholder="Evrak Ara"
            filterBy={"label,data.aciklama,data.tip"}
            selectionMode={secmeModu}
            selectionKeys={secmeModu == "checkbox" ? selection : (selectedDoc?.key as string)}
            expandedKeys={expandedKeys.current}
            onSelectionChange={onSelectionChangeHandler}
            nodeTemplate={(e: ExtendedTreeNode) => (
              <EvrakNode e={e} ref={(el) => (nodeRef.current[e?.key as number] = el)} />
            )}
          />
        </div>
      </div>
      <div className="col-8" ref={fileViewColRef}>
        {children && React.cloneElement(children as React.ReactElement, { parentWidth: fileColWidth })}
        {!children && loading && (
          <div className="flex justify-content-center align-items-center h-full">
            <ProgressSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

const findMinDate = (dateArray: string[]): Date => {
  const dates: Date[] = dateArray.map((dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return new Date(year, month - 1, day);
  });

  const minDate: Date = new Date(Math.min(...dates.map((date) => date.getTime())));

  // const formattedMinDate: string = `${String(minDate.getDate()).padStart(2, '0')}/${String(minDate.getMonth() + 1).padStart(2, '0')}/${minDate.getFullYear()}`;

  return minDate;
};

const findMaxDate = (dateArray: string[]): Date => {
  const dates: Date[] = dateArray.map((dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return new Date(year, month - 1, day);
  });

  const maxDate: Date = new Date(Math.max(...dates.map((date) => date.getTime())));

  // const formattedMaxDate = `${String(maxDate.getDate()).padStart(2, '0')}/${String(maxDate.getMonth() + 1).padStart(2, '0')}/${maxDate.getFullYear()}`;
  return maxDate;
};
