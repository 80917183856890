import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguTahsilatKalaniModel } from "../TopluSorgu.dto";
import { ExportFile } from "../../../utils/export-file";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { commonFilterContains, CreateHeader, Filter } from "../../filter/base-filter";

const TahsilatKalaniDataTable = (props: { tableData: TopluSorguTahsilatKalaniModel[] }) => {
  const tahsilatKalaniVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguTahsilatKalaniModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      sadeceToplam: commonFilterContains,
      sadeceMasraf: commonFilterContains,
      sadeceDiger: commonFilterContains,
      tahsilat: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Kalan Tahsilat",
      "Kalan Masraf",
      "Kalan Diğer",
      "Kalan Toplam"
    ];

    const filteredData = tahsilatKalaniVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      sadeceToplam: data.sadeceToplam,
      sadeceMasraf: data.sadeceMasraf,
      sadeceDiger: data.sadeceDiger,
      tahsilat: data.tahsilat
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluTahsilatKalani");
  };

  return (
    <div>
      <DataTable
        value={tahsilatKalaniVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="key"
        showGridlines
        emptyMessage="Tahsilat Kalanı Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Tahsilat Kalanı Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "sadeceToplam",
          "sadeceMasraf",
          "sadeceDiger",
          "tahsilat"
        ]}
      >
        <Column
          header="BİRİM ADI"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="DOSYA NO"
          field="ortakDegerler.dosyaNo"
          sortable
          filterField="ortakDegerler.dosyaNo"
          filter
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="ALACAKLI ADI/ÜNVANI"
          field="ortakDegerler.alacakliAdi"
          sortable
          filterField="ortakDegerler.alacakliAdi"
          filter
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="BORÇLU ADI/ÜNVANI"
          field="ortakDegerler.borcluAdi"
          sortable
          filterField="ortakDegerler.borcluAdi"
          filter
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="BORÇLU TC/VK NO"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filterField="ortakDegerler.borcluTCVKNo"
          filter
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="DOSYA DURUMU"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filterField="ortakDegerler.dosyaDurumu"
          filter
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="KALAN TAHSİLAT"
          field="sadeceToplam"
          sortable
          filter
          filterField="sadeceToplam"
          filterPlaceholder="Kalan Tahsilata göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kalan Tahsilata göre filtreleyin</div>}
        />
        <Column
          header="KALAN MASRAF"
          field="sadeceMasraf"
          sortable
          filter
          filterField="sadeceMasraf"
          filterPlaceholder="Kalan Masrafa göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kalan Masrafa göre filtreleyin</div>}
        />
        <Column
          header="KALAN DİĞER"
          field="sadeceDiger"
          sortable
          filter
          filterField="sadeceDiger"
          filterPlaceholder="Kalan Diğere göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kalan Diğere göre filtreleyin</div>}
        />
        <Column
          header="KALAN TOPLAM"
          field="tahsilat"
          sortable
          filter
          filterField="tahsilat"
          filterPlaceholder="Kalan Toplama göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kalan Toplama göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default TahsilatKalaniDataTable;
