import { UserDto } from "../account/account.dto";
import { EntityDto } from "../base/base.dto";

export interface UpdateMainUserDto extends EntityDto<string> {
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  tc: string;
  allowedLawyerTc: string;
  vergiNumarasi?: string;
  vergiDairesi?: string;
  address: string;
  icHatDahilisi?: string;
  garantiHtsKullaniciAdi?: string;
  garantiHtsSifresi?: string;
  garantiHtsBuroKodu?: string;
  baroSicilNumarasi?: string;
  bagliBulunulanBaro?: string;
}

export interface AppLogDto extends EntityDto<number> {
  event: string;
  eventDateTime: Date;
  user: UserDto;
}

export interface UpdatedUserDto {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  tc: string;
  allowedLawyerTc: string;
  vergiNumarasi?: string;
  vergiDairesi?: string;
  address: string;
  icHatDahilisi?: string;
  garantiHtsKullaniciAdi?: string;
  garantiHtsSifresi?: string;
  garantiHtsBuroKodu?: string;
  mainUserId?: string;
  isActive: string;
  isDeleted: string;
  kvkkAcikRizaOnay: boolean;
  kvkkAcikRizaOnayTarihi?: boolean;
  iysEmailAcikRizaOnay: boolean;
  iysSmsAcikRizaOnay: boolean;
  iysPhoneAcikRizaOnay: boolean;
  baroSicilNumarasi?: string;
  bagliBulunulanBaro?: string;
  registerIPNumber?: string;
  registerPortNumber?: string;
  creationTime: Date;
  creatorUserId?: number;
  lastModificationTime?: Date;
  lastModifierUserId?: number;
  licenses: AppLicenseDto[];
  orders: OrderDto[];
  subUsers: UserDto[];
}

export interface AppLicenseDto {
  name: string;
  isActive: boolean;
  startDate: Date;
  endDate: Date;
  id: string;
}

export interface UpsertLicenseDto {
  name: string;
  isActive: boolean;
  endDate: Date;
  id: string;
}

export interface LicenseCreateDto {
  name: string;
  isActive: boolean;
  endDate: Date;
}

export interface LicenseUpdateDto {
  id: string;
  name: string;
  isActive: boolean;
  endDate: Date;
}

export interface OrderDto {
  totalPrice: number;
  status: OrderStatusType;
  products: ProductInOrderDto[];
  createdDate: Date;
  invoiceEttn: string;
  invoiceHtml: string;
  invoiceStatus: InvoiceStatusType;
  invoiceSignDate: Date;
  didInvoiceMailSend: boolean;
  invoiceMailSendDate: Date;
}
export interface ProductInOrderDto {
  count: number;
  month: MonthlyType;
  type: ProductType;
  licenceIdOfTheExtendedLicense?: number;
  price: number;

  //getTotalPrice(): number; // bunu ne yapacaz
}

export interface OrderWithUserDto {
  totalPrice: number;
  status: OrderStatusType;
  products: ProductInOrderDto[];
  createdDate: Date;
  invoiceEttn: string;
  invoiceHtml: string;
  invoiceStatus: InvoiceStatusType;
  invoiceSignDate: Date;
  didInvoiceMailSend: boolean;
  invoiceMailSendDate: Date;
  user: UserDto;
}

export enum ProductType {
  NewLicence = 1,
  ExtendedLicence = 2
}

export enum OrderStatusType {
  Created = 1,
  Approved = 2,
  NotApproved = 3,
  Returned = 4
}

export enum InvoiceStatusType {
  NotCreated = 1,
  Draft = 2,
  Signed = 3
}

export enum MonthlyType {
  Monthly = 1,
  ThreeMonth = 3,
  SixMonth = 6,
  TwelveMonth = 12
}
