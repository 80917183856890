import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { NoContentDto } from "../base/base.dto";
import {
  TalimatDosyasiDto,
  CreateTalimatDosyasiDto,
  UpdateTalimatDosyasiDto,
} from "./talimat-dosyasi.dto";
const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TalimatDosyasiDto>(`TalimatDosyasi/Get/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TalimatDosyasiDto[]>(`TalimatDosyasi/GetAll`, config);
  },
  GetAllByIcraFoy(
    icraFoyId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<TalimatDosyasiDto[]>(
      `TalimatDosyasi/GetAllByIcraFoy/${icraFoyId}`,
      config
    );
  },
  Create(
    dto: CreateTalimatDosyasiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TalimatDosyasiDto>("TalimatDosyasi/Create", dto, config);
  },
  Update(
    dto: UpdateTalimatDosyasiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<TalimatDosyasiDto>("TalimatDosyasi/Update", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`TalimatDosyasi/Delete/${id}`, config);
  },
};

export default services;
