import { FormikErrors, useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../utils/FormikValueIsValid";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { UpdateTelephoneDto } from "../services/telephone/telephone.dto";
import { InputMask } from "primereact/inputmask";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../utils/DropdownValues";
import { UpdateEmailDto } from "../services/email/email.dto";

const now: Date = new Date();
const UpsertEmail = (props: any) => {
  const email: UpdateEmailDto = props.email;
  const onUpsert = props.onUpsert;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;

  let updateEmailDto: UpdateEmailDto;

  if (email === null || email === undefined) {
    updateEmailDto = {
      id: 0,
      epostaAdresi: "",
      iletisim: false,
      aciklama: "",
      creationTime: now,
    };
  } else {
    updateEmailDto = { ...email, creationTime: now };
  }

  const formik = useFormik<UpdateEmailDto>({
    initialValues: updateEmailDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateEmailDto> = {};
      if (!data.epostaAdresi) {
        errors.epostaAdresi = "E-posta alanı boş bırakılamaz";
      } else {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!regex.test(data.epostaAdresi)) {
          errors.epostaAdresi = "Geçerli bir e-posta adresi giriniz";
        }
      }
      if (data.iletisim === undefined || data.iletisim === null) {
        errors.iletisim = "İletişim alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      onUpsert(formik.values);
      setVisible(false);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={formik.values.id === 0 ? "E-posta Ekle" : "E-posta Güncelle"}
      onHide={() => {
        setVisible(false);
        formik.resetForm();
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={() => formik.submitForm()}
          />
        </div>
      }
      className=""
      style={{ width: "50vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="epostaAdresi">
            E-posta Adresi
          </label>
          <InputText
            id="epostaAdresi"
            name="epostaAdresi"
            value={formik.values.epostaAdresi}
            aria-labelledby="phone"
            onChange={(e) => {
              formik.setFieldValue("epostaAdresi", e.target.value);
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "epostaAdresi"),
            })}
          />
          {FormikValueIsValid(formik, "epostaAdresi") && (
            <div className="p-error mt-3">{formik.errors.epostaAdresi}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tip">
            İletişim İzni Var Mı?
          </label>
          <div className="flex flex-wrap gap-5 mt-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="iletisimYes"
                name="iletisim"
                value={true}
                onChange={(e) => formik.setFieldValue("iletisim", e.value)}
                checked={formik.values.iletisim === true}
              />
              <label className="text-primary ml-2" htmlFor="iletisimYes">
                Evet
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="iletisimNo"
                name="iletisim"
                value={false}
                onChange={(e) => formik.setFieldValue("iletisim", e.value)}
                checked={formik.values.iletisim === false}
              />
              <label className="text-primary ml-2" htmlFor="iletisimNo">
                Hayır
              </label>
            </div>
          </div>
          {FormikValueIsValid(formik, "iletisim") && (
            <div className="p-error mt-3">{formik.errors.iletisim}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="aciklama">
            Açıklama
          </label>
          <InputText
            id="aciklama"
            name="aciklama"
            value={formik.values.aciklama}
            onChange={(e) => {
              formik.setFieldValue(
                "aciklama",
                e.target.value.toLocaleUpperCase("tr")
              );
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "aciklama"),
            })}
          />
          {FormikValueIsValid(formik, "aciklama") && (
            <div className="p-error mt-3">{formik.errors.aciklama}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertEmail;
