import { FormikErrors, useFormik } from "formik";
import { AddressDto, UpdateAddressDto } from "../services/address/address.dto";
import { useToast } from "../utils/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../utils/FormikValueIsValid";
import DropdownDatas from "../utils/DropdownValues";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { useQuery } from "@tanstack/react-query";
import IlceIlService from "../services/ilce-il/ilce-il.services";
import { useContext, useEffect } from "react";
import { IlDto, IlceDto } from "../services/ilce-il/ilce-il.sto";
import { LayoutContext } from "../layout/context/layoutcontext";

const now: Date = new Date();
const UpsertAddress = (props: any) => {
  const address: UpdateAddressDto = props.address;
  const onUpsert = props.onUpsert;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;

  const { setBlocked } = useContext(LayoutContext)
  const { data: illerResponse, isLoading: illerResponseIsLoading } = useQuery({
    queryKey: ["Iller"],
    refetchOnMount: true,
    queryFn: () => IlceIlService.GetAllIl()
  });

  const { data: ilcelerResponse, refetch: refetchIlcelerResponse, isLoading: ilcelerResponseIsLoading } = useQuery({
    queryKey: ["Ilceler"],
    refetchOnMount: true,
    queryFn: () => IlceIlService.GetAllIlce(formik.values.ilId)
  });

  useEffect(() => {
    setBlocked(ilcelerResponseIsLoading || illerResponseIsLoading)
  }, [ilcelerResponseIsLoading, illerResponseIsLoading])
  let updateAddressDto: AddressDto;

  if (address === null || address === undefined) {
    updateAddressDto = {
      id: 0,
      address: "",
      ilceId: 0,
      ilId: 0,
      tip: "",
      mernisMi: false,
      aciklama: "",
      creationTime: now
    };
  } else {
    updateAddressDto = {
      ...address,
      tasinmaTarihi: address.tasinmaTarihi ? new Date(address.tasinmaTarihi) : undefined,
      tescilTarihi: address.tescilTarihi ? new Date(address.tescilTarihi) : undefined,
      creationTime: now
    };
  }

  const formik = useFormik<AddressDto>({
    initialValues: updateAddressDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<AddressDto> = {};
      if (!data.address) {
        errors.address = "Adres alanı boş bırakılamaz";
      }
      if (!data.ilceId) {
        errors.ilceId = "İlçe alanı boş bırakılamaz";
      }
      if (!data.ilId) {
        errors.ilId = "İl alanı boş bırakılamaz";
      }
      if (!data.tip) {
        errors.tip = "Tip alanı boş bırakılamaz";
      }
      if (data.mernisMi === undefined || data.mernisMi === null) {
        errors.mernisMi = "Mernis mi alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      onUpsert(formik.values);
      setVisible(false);
      formik.resetForm();
    }
  });

  useEffect(() => {
    refetchIlcelerResponse();
  }, [formik.values.ilId]);

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={formik.values.id === 0 ? "Adres Ekle" : "Adres Güncelle"}
      onHide={() => {
        setVisible(false);
        formik.resetForm();
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
            }}
          />
          <Button label={"Ekle"} className="md:px-6 justify-content-center" onClick={() => formik.submitForm()} />
        </div>
      }
      className=""
      style={{ width: "50vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="ilId">
            İl
          </label>
          <Dropdown
            id="ilId"
            name="ilId"
            filter
            value={formik.values.ilId}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("ilId", e.value);
              let il: IlDto = illerResponse?.result?.data?.find((x) => x.id === e.value)!;
              formik.setFieldValue("il", il);
            }}
            options={illerResponse?.result?.data}
            optionLabel="ilText"
            optionValue="id"
            placeholder="İl Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "ilId")
            })}
          />
          {FormikValueIsValid(formik, "ilId") && <div className="p-error mt-3">{formik.errors.ilId}</div>}
        </div>
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="ilceId">
            İlçe
          </label>
          <Dropdown
            id="ilceId"
            name="ilceId"
            filter
            value={formik.values.ilceId}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("ilceId", e.value);
              let ilce: IlceDto = ilcelerResponse?.result?.data?.find((x) => x.id === e.value)!;
              formik.setFieldValue("ilce", ilce);
            }}
            options={ilcelerResponse?.result?.data}
            optionLabel="ilceText"
            optionValue="id"
            placeholder="İlçe Seçiniz"
            disabled={formik.values.ilId === 0}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "ilceId")
            })}
          />
          {FormikValueIsValid(formik, "ilceId") && <div className="p-error mt-3">{formik.errors.ilceId}</div>}
        </div>
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="address">
            Adres
          </label>
          <InputText
            id="address"
            name="address"
            value={formik.values.address}
            onChange={(e) => {
              formik.setFieldValue("address", e.target.value.toLocaleUpperCase("tr"));
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "address")
            })}
          />
          {FormikValueIsValid(formik, "address") && <div className="p-error mt-3">{formik.errors.address}</div>}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tip">
            Adres Tipi
          </label>
          <Dropdown
            id="tip"
            name="tip"
            value={formik.values.tip}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tip", e.value);
            }}
            options={DropdownDatas.GetAddressType()}
            optionLabel="label"
            optionValue="value"
            placeholder="Tip Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tip")
            })}
          />
          {FormikValueIsValid(formik, "tip") && <div className="p-error mt-3">{formik.errors.tip}</div>}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tip">
            Mernis Mi?
          </label>
          <div className="flex flex-wrap gap-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="mernisMiYes"
                name="mernisMi"
                value={true}
                onChange={(e) => formik.setFieldValue("mernisMi", e.value)}
                checked={formik.values.mernisMi === true}
              />
              <label className="text-primary ml-2" htmlFor="mernisMiYes">
                Evet
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="mernisMiNo"
                name="mernisMi"
                value={false}
                onChange={(e) => formik.setFieldValue("mernisMi", e.value)}
                checked={formik.values.mernisMi === false}
              />
              <label className="text-primary ml-2" htmlFor="mernisMiNo">
                Hayır
              </label>
            </div>
          </div>
          {FormikValueIsValid(formik, "mernisMi") && <div className="p-error mt-3">{formik.errors.mernisMi}</div>}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="address">
            Açıklama
          </label>
          <InputText
            id="aciklama"
            name="aciklama"
            value={formik.values.aciklama}
            onChange={(e) => {
              formik.setFieldValue("aciklama", e.target.value.toLocaleUpperCase("tr"));
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "aciklama")
            })}
          />
          {FormikValueIsValid(formik, "aciklama") && <div className="p-error mt-3">{formik.errors.aciklama}</div>}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tasinmaTarihi">
            Taşınma Tarihi
          </label>
          <Calendar
            value={formik.values.tasinmaTarihi}
            onChange={(e) => formik.setFieldValue("tasinmaTarihi", e.value)}
            dateFormat="dd/mm/yy"
            showIcon
            mask="99/99/9999"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tasinmaTarihi")
            })}
            locale="tr"
            maxDate={new Date()}
          />
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tescilTarihi">
            Tescil Tarihi
          </label>
          <Calendar
            value={formik.values.tescilTarihi}
            onChange={(e) => formik.setFieldValue("tescilTarihi", e.value)}
            dateFormat="dd/mm/yy"
            showIcon
            mask="99/99/9999"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tescilTarihi")
            })}
            locale="tr"
            maxDate={new Date()}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertAddress;
