import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import IcraFoyService from "../../services/icra-foy/icra-foy-services";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../utils/DropdownValues";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useToast } from "../../utils/toast";
import {
  CreateIcraFoyDto,
  CreateIcraFoyKarsiYanDto,
  IcraFoyDto,
  IcraFoyKarsiYanDto,
  UpdateIcraFoyDto,
  UpdateIcraFoyKarsiYanDto,
  UpdateIcraFoyMuvekkilDto,
} from "../../services/icra-foy/icra-foy.dto";
import {
  InputNumber,
  InputNumberValueChangeEvent,
} from "primereact/inputnumber";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { InputMask } from "primereact/inputmask";
import { useQuery } from "@tanstack/react-query";
import MuvekkilService from "../../services/muvekkil/muvekkil.service";
import KarsiYanService from "../../services/karsi-yan/karsi-yan.service";
import KlasorService from "../../services/klasor/klasor.services";
import DosyaDurumuService from "../../services/dosya-durumu/dosya-durumu.services";
import DigerAyrintiService from "../../services/diger-ayrinti/diger-ayrinti.services";
import { InputTextarea } from "primereact/inputtextarea";
import UpsertAddress from "../../components/UpsertAddress";
import { UpdateAddressDto } from "../../services/address/address.dto";
import { KarsiYanDto } from "../../services/karsi-yan/karsi-yan.dto";
import {
  MuvekkilDto,
  MuvekkilTipi,
} from "../../services/muvekkil/muvekkil.dto";
import { useContext, useEffect, useRef, useState } from "react";
import { DosyaDurumuDto } from "../../services/dosya-durumu/dosya-durumu.dto";
import UpsertIcraFoyKarsiYan from "./components/icra-foy-karsi-yan/UpsertIcraFoyKarsiYan";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import UpsertMuvekkil from "../muvekkil/UpsertMuvekkil";
import UpsertIcraFoyMuvekkil from "./components/icra-foy-muvekkil/UpsertIcraFoyMuvekkil";
import classNames from "classnames";
import { LayoutContext } from "../../layout/context/layoutcontext";

const now: Date = new Date();

const UpsertIcraFoy = (props: any) => {

  const { setBlocked } = useContext(LayoutContext)
  const icraFoy: IcraFoyDto = props.icraFoy;
  const setIcraFoy = props.setIcraFoy;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchIcraFoy = props.refetchIcraFoy;
  const toast = useToast();
  const dtIcraFoyKarsiYan = useRef<DataTable<UpdateIcraFoyKarsiYanDto[]>>(null);
  const dtIcraFoyMuvekkil = useRef<DataTable<UpdateIcraFoyMuvekkilDto[]>>(null);
  const [selectedIcraFoyKarsiYan, setSelectedIcraFoyKarsiYans] = useState<
    UpdateIcraFoyKarsiYanDto | undefined
  >(undefined);
  const [selectedIcraFoyMuvekkil, setSelectedIcraFoyMuvekkil] = useState<
    UpdateIcraFoyMuvekkilDto | undefined
  >(undefined);
  const [visibleIcraFoyKarsiYan, setVisibleIcraFoyKarsiYan] = useState(false);
  const [visibleIcraFoyMuvekkil, setVisibleIcraFoyMuvekkil] = useState(false);

  const { data: muvekkilResponse, refetch: refetchMuvekkil, isLoading: muvekkilIsLoading } = useQuery({
    queryKey: ["Muvekkil"],
    refetchOnMount: true,
    queryFn: () => MuvekkilService.GetAll(),
  });

  const { data: karsiYanResponse, refetch: refetchKarsiYan, isLoading: karsiYanIsLoading } = useQuery({
    queryKey: ["KarsiYan"],
    refetchOnMount: true,
    queryFn: () => KarsiYanService.GetAll(),
  });

  const { data: klasorResponse, refetch: refetchKlasor, isLoading: klasorIsLoading } = useQuery({
    queryKey: ["Klasor"],
    refetchOnMount: true,
    queryFn: () => KlasorService.GetAll(),
  });

  const { data: dosyaDurumuResponse, refetch: refetchDosyaDurumu, isLoading: dosyaDurumuIsLoading } = useQuery({
    queryKey: ["DosyaDurumu"],
    refetchOnMount: true,
    queryFn: () => DosyaDurumuService.GetAll(),
  });

  const { data: digerAyrintilarResponse, refetch: refetchDigerAyrintilar, isLoading: digerAyrintilarIsLoading } =
    useQuery({
      queryKey: ["DigerAyrintilar"],
      refetchOnMount: true,
      queryFn: () => DigerAyrintiService.GetAll(),
    });

  const { data: maxFoyNumberResponse, refetch: refetchMaxFoyNumber, isLoading: maxFoyNumberIsLoading } = useQuery(
    {
      queryKey: ["MaxFoyNumber"],
      refetchOnMount: true,
      queryFn: () => IcraFoyService.GetMaxFoyNumber(formik.values.klasorId),
    }
  );

  useEffect(() => {
    setBlocked(maxFoyNumberIsLoading || digerAyrintilarIsLoading || dosyaDurumuIsLoading || klasorIsLoading || karsiYanIsLoading || muvekkilIsLoading)
  }, [maxFoyNumberIsLoading, digerAyrintilarIsLoading, dosyaDurumuIsLoading, klasorIsLoading, karsiYanIsLoading, muvekkilIsLoading])

  let updateIcraFoyDto: UpdateIcraFoyDto;

  if (icraFoy === null || icraFoy === undefined) {
    updateIcraFoyDto = {
      id: 0,
      klasorId: 1,
      dosyaDurumuId: 1,
      kayitTarihi: now,
      foyNo: maxFoyNumberResponse?.result?.data
        ? maxFoyNumberResponse?.result?.data.maxFoyNumber + 1
        : 0,
      takipTuru: "",
      takipYolu: "",
      takipSekli: "",
      aciklama4:
        "Alacağın tahsili tarihine kadar %...... faizi masraf ve vekalet ücreti ile tahsili, kısmi ödemelerde BK.100 e göre yapılmasını talep ederim.",
      aciklama9: "HacizYolu",
      digerAyrintiIds: [],
      takipMahiyeti: "",
      icraMudurlugu: "",
      dosyaNo: "",
      takipTarihi: undefined,
      takipCikisi: 0,
      icraFoyKarsiYans: [],
      icraFoyMuvekkils: [],
      alacakBilgisiIds: [],
    };
  } else {
    updateIcraFoyDto = {
      ...icraFoy,
      foyNo: icraFoy.foyNo,
      digerAyrintiIds: icraFoy.digerAyrintilar.map((x) => x.id),
      icraFoyKarsiYans: icraFoy.icraFoyKarsiYans.map((x) => {
        return { ...x };
      }),
      icraFoyMuvekkils: icraFoy.icraFoyMuvekkils.map((x) => {
        return { ...x };
      }),
      alacakBilgisiIds: icraFoy.alacakBilgileri.map((x) => x.id),
      kayitTarihi: new Date(icraFoy.kayitTarihi),
      takipTarihi: icraFoy.takipTarihi
        ? new Date(icraFoy.takipTarihi)
        : undefined,
    };
  }

  const formik = useFormik<UpdateIcraFoyDto>({
    initialValues: updateIcraFoyDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateIcraFoyDto> = {};
      if (!data.klasorId) {
        errors.klasorId = "Klasör alanı boş bırakılamaz";
      }

      if (!data.dosyaDurumuId) {
        errors.dosyaDurumuId = "Dosya Durumu alanı boş bırakılamaz";
      }

      if (!data.kayitTarihi) {
        errors.kayitTarihi = "Kayıt Tarihi alanı boş bırakılamaz";
      }

      if (!data.foyNo) {
        errors.foyNo = "Föy No alanı boş bırakılamaz";
      }

      if (!data.takipTuru) {
        errors.takipTuru = "Takip Türü alanı boş bırakılamaz";
      }

      if (!data.takipYolu) {
        errors.takipYolu = "Takip Yolu alanı boş bırakılamaz";
      }

      if (!data.takipSekli) {
        errors.takipSekli = "Takip Şekli alanı boş bırakılamaz";
      }

      if (!data.aciklama4) {
        errors.aciklama4 = "1/4 Açıklama alanı boş bırakılamaz";
      }

      if (!data.aciklama9) {
        errors.aciklama9 = "1/9 Açıklama alanı boş bırakılamaz";
      }

      if (!data.takipMahiyeti) {
        errors.takipMahiyeti = "Takip Mahiyeti alanı boş bırakılamaz";
      }

      if (!data.icraFoyKarsiYans || data.icraFoyKarsiYans.length === 0) {
        errors.icraFoyKarsiYans = "Borçlu alanı boş bırakılamaz";
      }

      if (!data.icraFoyMuvekkils || data.icraFoyMuvekkils.length === 0) {
        errors.icraFoyMuvekkils = "Müvekkil alanı boş bırakılamaz";
      }

      if (data.dosyaNo && data.dosyaNo?.length > 5) {
        if (!data.icraMudurlugu) {
          errors.icraMudurlugu = "İcra Müdürlüğü alanı boş bırakılamaz";
        }
      }

      if (data.icraMudurlugu) {
        if (!data.dosyaNo || data.dosyaNo?.length < 6) {
          errors.dosyaNo = "Dosya No alanı boş bırakılamaz";
        }
      }

      if (data.dosyaNo && data.dosyaNo?.length > 5) {
        if (!data.takipTarihi) {
          errors.takipTarihi = "Takip Tarihi alanı boş bırakılamaz";
        }
      }

      if (data.dosyaNo && data.dosyaNo?.length > 5) {
        if (!data.takipCikisi) {
          errors.takipCikisi = "Takip Çıkışı alanı boş bırakılamaz";
        }
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      if (formik.values.id === 0) {
        var icraFoyCreateDto: CreateIcraFoyDto = {
          ...formik.values,
        };
        IcraFoyService.Create(icraFoyCreateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setIcraFoy(undefined);
            refetchIcraFoy();
            toast.show("Icra Föy başarıyla eklendi.", "success");
            setBlocked(false)
          }
        }).finally(() => setBlocked(false));
      } else {
        var icraFoyUpdateDto: UpdateIcraFoyDto = {
          ...formik.values,
        };
        IcraFoyService.Update(icraFoyUpdateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setIcraFoy(undefined);
            refetchIcraFoy();
            toast.show("Icra Föy başarıyla güncellendi.", "success");
            setBlocked(false)
          }
        }).finally(() => setBlocked(false));
      }
    },
  });

  useEffect(() => {
    if (icraFoy === null || icraFoy === undefined) {
      refetchMaxFoyNumber().then((res) => {
        if (res.data?.result?.data?.maxFoyNumber) {
          formik.setFieldValue(
            "foyNo",
            res.data?.result?.data?.maxFoyNumber + 1
          );
        }
      });
    }
  }, [formik.values.klasorId, visible]);

  const deleteIcraFoyKarsiYanConfirm = (rowData: UpdateIcraFoyKarsiYanDto) => {
    confirmDialog({
      message: "Borçluyu silmek istediğinize emin misiniz?",
      header: "Borçlu Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => removeIcraFoyKarsiYan(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
    });
  };

  const removeIcraFoyKarsiYan = (rowData: UpdateIcraFoyKarsiYanDto) => {
    var icraFoyKarsiYans = [...formik.values.icraFoyKarsiYans];
    var index = formik.values.icraFoyKarsiYans.findIndex(
      (x) => x.karsiYanId === rowData.karsiYanId && x.tip === rowData.tip
    );
    icraFoyKarsiYans.splice(index, 1);
    formik.setFieldValue("icraFoyKarsiYans", [...icraFoyKarsiYans]);
  };

  const borcluActionBodyTemplate = (rowData: UpdateIcraFoyKarsiYanDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          tooltip="Düzenle"
          outlined
          className="mr-2"
          onClick={() => {
            setSelectedIcraFoyKarsiYans(rowData);
            setVisibleIcraFoyKarsiYan(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Sil"
          rounded
          outlined
          severity="danger"
          onClick={() => deleteIcraFoyKarsiYanConfirm(rowData)}
        />
      </div>
    );
  };

  const deleteIcraFoyMuvekkilConfirm = (rowData: UpdateIcraFoyMuvekkilDto) => {
    confirmDialog({
      message: "Müvekkili silmek istediğinize emin misiniz?",
      header: "Müvekkil Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => removeIcraFoyMuvekkil(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
    });
  };

  const removeIcraFoyMuvekkil = (rowData: UpdateIcraFoyMuvekkilDto) => {
    var icraFoyMuvekkils = [...formik.values.icraFoyMuvekkils];
    var index = formik.values.icraFoyMuvekkils.findIndex(
      (x) => x.muvekkilId === rowData.muvekkilId && x.tip === rowData.tip
    );
    icraFoyMuvekkils.splice(index, 1);
    formik.setFieldValue("icraFoyMuvekkils", [...icraFoyMuvekkils]);
  };

  const muvekkilActionBodyTemplate = (rowData: UpdateIcraFoyMuvekkilDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          tooltip="Düzenle"
          outlined
          className="mr-2"
          onClick={() => {
            setSelectedIcraFoyMuvekkil(rowData);
            setVisibleIcraFoyMuvekkil(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Sil"
          rounded
          outlined
          severity="danger"
          onClick={() => deleteIcraFoyMuvekkilConfirm(rowData)}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog
        visible={visible}
        modal={true}
        header={formik.values.id === 0 ? "Icra Föy Ekle" : "Icra Föy Güncelle"}
        onHide={() => {
          setVisible(false);
          formik.resetForm();
          setIcraFoy(undefined);
        }}
        footer={
          <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
            <Button
              label={"İptal"}
              className="md:px-6 justify-content-center"
              type="button"
              severity="secondary"
              onClick={() => {
                setVisible(false);
                formik.resetForm();
                setIcraFoy(undefined);
              }}
            />
            <Button
              label={"Kaydet"}
              className="md:px-6 justify-content-center"
              onClick={formik.submitForm}
            />
          </div>
        }
        className=""
        style={{ width: "75vw" }}
      >
        <div className="grid mt-1">
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="klasorId">
              Klasör
            </label>
            <Dropdown
              id="klasorId"
              name="klasorId"
              value={formik.values.klasorId}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("klasorId", e.value);
              }}
              options={klasorResponse?.result?.data}
              optionLabel="name"
              optionValue="id"
              placeholder="Klasör Seçiniz"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "klasorId"),
              })}
            />
            {FormikValueIsValid(formik, "klasorId") && (
              <div className="p-error mt-3">{formik.errors.klasorId}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="dosyaDurumuId">
              Dosya Durumu
            </label>
            <Dropdown
              id="dosyaDurumuId"
              name="dosyaDurumuId"
              value={formik.values.dosyaDurumuId}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("dosyaDurumuId", e.value);
              }}
              options={dosyaDurumuResponse?.result?.data}
              optionLabel="name"
              optionValue="id"
              placeholder="Dosya Durumu Seçiniz"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "dosyaDurumuId"),
              })}
              itemTemplate={(item: DosyaDurumuDto) => {
                return (
                  <div className="flex align-items-center">
                    <span>
                      {item.name ? item.name.toLocaleUpperCase("tr") : ""}
                    </span>
                  </div>
                );
              }}
              valueTemplate={(item: DosyaDurumuDto) => {
                return (
                  <div className="flex align-items-center">
                    <span>
                      {item.name ? item.name.toLocaleUpperCase("tr") : ""}
                    </span>
                  </div>
                );
              }}
            />
            {FormikValueIsValid(formik, "dosyaDurumuId") && (
              <div className="p-error mt-3">{formik.errors.dosyaDurumuId}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="kayitTarihi">
              Föy Kayıt Tarihi
            </label>
            <Calendar
              id="kayitTarihi"
              name="kayitTarihi"
              value={formik.values.kayitTarihi}
              onChange={(e) => {
                formik.setFieldValue("kayitTarihi", e.value);
              }}
              maxDate={now}
              dateFormat="dd/mm/yy"
              showIcon
              locale="tr"
              mask="99/99/9999"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "kayitTarihi"),
              })}
            />
            {FormikValueIsValid(formik, "kayitTarihi") && (
              <div className="p-error mt-3">
                Kayıt Tarihi alanı boş bırakılamaz
              </div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="foyNo">
              Föy Numarası
            </label>
            <InputNumber
              id="foyNo"
              name="foyNo"
              value={formik.values.foyNo}
              onChange={(e: any) => {
                formik.setFieldValue("foyNo", e.value);
              }}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "foyNo"),
              })}
            />
            {FormikValueIsValid(formik, "foyNo") && (
              <div className="p-error mt-3">{formik.errors.foyNo}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="takipTuru">
              Takip Türü
            </label>
            <Dropdown
              id="takipTuru"
              name="takipTuru"
              value={formik.values.takipTuru}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("takipTuru", e.value);
                formik.setFieldValue("takipYolu", "");
                formik.setFieldValue("takipSekli", "");
              }}
              options={DropdownDatas.GetTakipTuru()}
              optionLabel="label"
              optionValue="value"
              placeholder="Takip Türü Seçiniz"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "takipTuru"),
              })}
            />
            {FormikValueIsValid(formik, "takipTuru") && (
              <div className="p-error mt-3">{formik.errors.takipTuru}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="takipYolu">
              Takip Yolu
            </label>
            <Dropdown
              id="takipYolu"
              name="takipYolu"
              value={formik.values.takipYolu}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("takipYolu", e.value);
              }}
              options={DropdownDatas.GetTakipYolu(formik.values.takipTuru)}
              optionLabel="label"
              optionValue="value"
              placeholder="Takip Yolu Seçiniz"
              disabled={!formik.values.takipTuru}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "takipYolu"),
              })}
            />
            {FormikValueIsValid(formik, "takipYolu") && (
              <div className="p-error mt-3">{formik.errors.takipYolu}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="takipSekli">
              Takip Şekli
            </label>
            <Dropdown
              id="takipSekli"
              name="takipSekli"
              value={formik.values.takipSekli}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("takipSekli", e.value);
              }}
              options={DropdownDatas.GetTakipSekli(formik.values.takipYolu)}
              optionLabel="label"
              optionValue="value"
              placeholder="Takip Şekli Seçiniz"
              disabled={!formik.values.takipYolu}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "takipSekli"),
              })}
            />
            {FormikValueIsValid(formik, "takipSekli") && (
              <div className="p-error mt-3">{formik.errors.takipSekli}</div>
            )}
          </div>
          <div className="col-12">
            <label className="text-primary" htmlFor="aciklama4">
              1/4 Açıklaması
            </label>
            <InputTextarea
              id="aciklama4"
              name="aciklama4"
              rows={3}
              value={formik.values.aciklama4}
              onChange={(e) => {
                formik.setFieldValue("aciklama4", e.target.value);
              }}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "aciklama4"),
              })}
            />
            {FormikValueIsValid(formik, "aciklama4") && (
              <div className="p-error mt-3">{formik.errors.aciklama4}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="aciklama9">
              1/9 Açıklaması
            </label>
            <Dropdown
              id="aciklama9"
              name="aciklama9"
              value={formik.values.aciklama9}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("aciklama9", e.value);
              }}
              options={DropdownDatas.GetAciklama9()}
              optionLabel="label"
              optionValue="value"
              placeholder="1/9 Açıklaması Seçiniz"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "aciklama9"),
              })}
            />
            {FormikValueIsValid(formik, "aciklama9") && (
              <div className="p-error mt-3">{formik.errors.aciklama9}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="digerAyrintiIds">
              Diğer Ayrıntılar
            </label>
            <MultiSelect
              id="digerAyrintiIds"
              name="digerAyrintiIds"
              display="chip"
              filter
              value={formik.values.digerAyrintiIds}
              onChange={(e: MultiSelectChangeEvent) => {
                formik.setFieldValue("digerAyrintiIds", e.value);
              }}
              options={digerAyrintilarResponse?.result?.data}
              optionLabel="name"
              optionValue="id"
              placeholder="Diğer Ayrıntılar Seçiniz"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "digerAyrintiIds"),
              })}
            />
            {FormikValueIsValid(formik, "digerAyrintiIds") && (
              <div className="p-error mt-3">
                {formik.errors.digerAyrintiIds}
              </div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="takipMahiyeti">
              Takip Mahiyeti
            </label>
            <Dropdown
              id="takipMahiyeti"
              name="takipMahiyeti"
              value={formik.values.takipMahiyeti}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("takipMahiyeti", e.value);
              }}
              options={DropdownDatas.GetTakipMahiyeti()}
              optionLabel="label"
              optionValue="value"
              placeholder="Takip Mahiyeti Seçiniz"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "takipMahiyeti"),
              })}
            />
            {FormikValueIsValid(formik, "takipMahiyeti") && (
              <div className="p-error mt-3">{formik.errors.takipMahiyeti}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="icraMudurlugu">
              İcra Müdürlüğü
            </label>
            <Dropdown
              id="icraMudurlugu"
              name="icraMudurlugu"
              value={formik.values.icraMudurlugu}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("icraMudurlugu", e.value);
              }}
              filter
              options={DropdownDatas.GetIcraMudurlugu()}
              optionLabel="label"
              optionValue="value"
              placeholder="İcra Müdürlüğü Seçiniz"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "icraMudurlugu"),
              })}
            />
            {FormikValueIsValid(formik, "icraMudurlugu") && (
              <div className="p-error mt-3">{formik.errors.icraMudurlugu}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="dosyaNo">
              Dosya Numarası
            </label>
            <InputMask
              id="dosyaNo"
              name="dosyaNo"
              keyfilter="int"
              mask="9999/9?99999"
              placeholder="2024/0"
              value={formik.values.dosyaNo}
              onChange={(e) => {
                formik.setFieldValue("dosyaNo", e.target.value);
              }}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "dosyaNo"),
              })}
            />
            {FormikValueIsValid(formik, "dosyaNo") && (
              <div className="p-error mt-3">{formik.errors.dosyaNo}</div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="takipTarihi">
              Takip Tarihi
            </label>
            <Calendar
              id="takipTarihi"
              name="takipTarihi"
              maxDate={now}
              value={formik.values.takipTarihi}
              onChange={(e) => {
                formik.setFieldValue("takipTarihi", e.value);
              }}
              mask="99/99/9999"
              dateFormat="dd/mm/yy"
              showIcon
              locale="tr"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "takipTarihi"),
              })}
            />
            {FormikValueIsValid(formik, "takipTarihi") && (
              <div className="p-error mt-3">
                Takip Tarihi alanı boş bırakılamaz
              </div>
            )}
          </div>
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="takipCikisi">
              Takip Çıkışı
            </label>
            <InputNumber
              id="takipCikisi"
              name="takipCikisi"
              locale="tr-TR"
              maxFractionDigits={2}
              minFractionDigits={2}
              value={formik.values.takipCikisi}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                formik.setFieldValue("takipCikisi", e.value);
              }}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "takipCikisi"),
              })}
            />
            {FormikValueIsValid(formik, "takipCikisi") && (
              <div className="p-error mt-3">{formik.errors.takipCikisi}</div>
            )}
          </div>
          <div className="col-12 mt-3">
            <DataTable
              showGridlines
              ref={dtIcraFoyMuvekkil}
              value={formik.values.icraFoyMuvekkils}
              // dataKey="id"
              key={formik.values.icraFoyMuvekkils?.length}
              header={
                <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                  <h4 className="m-0">Müvekkiller</h4>
                  <Button
                    label="Yeni Müvekkil"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={() => {
                      setSelectedIcraFoyMuvekkil(undefined);
                      setVisibleIcraFoyMuvekkil(true);
                    }}
                  />
                </div>
              }
            >
              <Column
                body={muvekkilActionBodyTemplate}
                exportable={false}
                style={{ maxWidth: "3rem" }}
              ></Column>
              <Column
                field="karsiYanId"
                header="Ad Soyad / Ünvan"
                sortable
                body={(rowData: UpdateIcraFoyMuvekkilDto) => {
                  var muvekkil = muvekkilResponse?.result?.data.find(
                    (x) => x.id === rowData.muvekkilId
                  );
                  return (
                    <span>
                      {muvekkil?.tip === MuvekkilTipi.Gercek
                        ? muvekkil?.adSoyad
                        : muvekkil?.unvan}
                    </span>
                  );
                }}
              ></Column>
              <Column field="tip" header="Borçlu Tipi" sortable></Column>
            </DataTable>

            {(formik.values.icraFoyMuvekkils === undefined ||
              formik.values.icraFoyMuvekkils.length < 1) && (
                <div className="p-error mt-3">Müvekkil alanı boş bırakılamaz</div>
              )}
          </div>
          <div className="col-12 mt-3">
            <DataTable
              showGridlines
              ref={dtIcraFoyKarsiYan}
              value={formik.values.icraFoyKarsiYans}
              // dataKey="id"
              key={formik.values.icraFoyKarsiYans?.length}
              header={
                <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                  <h4 className="m-0">Borçlular</h4>
                  <Button
                    label="Yeni Borçlu"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={() => {
                      setSelectedIcraFoyKarsiYans(undefined);
                      setVisibleIcraFoyKarsiYan(true);
                    }}
                  />
                </div>
              }
            >
              <Column
                body={borcluActionBodyTemplate}
                exportable={false}
                style={{ maxWidth: "3rem" }}
              ></Column>
              <Column
                field="karsiYanId"
                header="Ad Soyad / Ünvan"
                sortable
                body={(rowData: UpdateIcraFoyKarsiYanDto) => {
                  var karsiYan = karsiYanResponse?.result?.data.find(
                    (x) => x.id === rowData.karsiYanId
                  );
                  return (
                    <span>
                      {karsiYan?.tip === MuvekkilTipi.Gercek
                        ? karsiYan?.adSoyad
                        : karsiYan?.unvan}
                    </span>
                  );
                }}
              ></Column>
              <Column field="tip" header="Borçlu Tipi" sortable></Column>
            </DataTable>

            {(formik.values.icraFoyKarsiYans === undefined ||
              formik.values.icraFoyKarsiYans.length < 1) && (
                <div className="p-error mt-3">Borçlu alanı boş bırakılamaz</div>
              )}
          </div>
        </div>
      </Dialog>

      <UpsertIcraFoyKarsiYan
        icraFoyKarsiYan={selectedIcraFoyKarsiYan}
        setIcraFoyKarsiYan={setSelectedIcraFoyKarsiYans}
        visible={visibleIcraFoyKarsiYan}
        setVisible={setVisibleIcraFoyKarsiYan}
        karsiYans={karsiYanResponse?.result?.data}
        onUpsert={(karsiYan: UpdateIcraFoyKarsiYanDto) => {
          var karsiYans = [...formik.values.icraFoyKarsiYans];
          var selectedIndex = karsiYans.findIndex(
            (x) =>
              x.karsiYanId === selectedIcraFoyKarsiYan?.karsiYanId &&
              x.tip === selectedIcraFoyKarsiYan?.tip
          );

          var upsertedIndex = karsiYans.findIndex(
            (x) =>
              x.karsiYanId === karsiYan.karsiYanId && x.tip === karsiYan.tip
          );

          if (upsertedIndex > -1) {
            toast.show("Bu borçlu zaten ekli.", "error");
            return;
          }

          if (!selectedIcraFoyKarsiYan) {
            formik.setFieldValue("icraFoyKarsiYans", [
              ...formik.values.icraFoyKarsiYans,
              karsiYan,
            ]);
          } else {
            karsiYans[selectedIndex] = karsiYan;
            formik.setFieldValue("icraFoyKarsiYans", [...karsiYans]);
          }
          setSelectedIcraFoyKarsiYans(undefined);
        }}
      />

      <UpsertIcraFoyMuvekkil
        icraFoyMuvekkil={selectedIcraFoyMuvekkil}
        setIcraFoyMuvekkil={setSelectedIcraFoyMuvekkil}
        visible={visibleIcraFoyMuvekkil}
        setVisible={setVisibleIcraFoyMuvekkil}
        muvekkils={muvekkilResponse?.result?.data}
        onUpsert={(muvekkil: UpdateIcraFoyMuvekkilDto) => {
          var muvekkils = [...formik.values.icraFoyMuvekkils];
          var selectedIndex = muvekkils.findIndex(
            (x) =>
              x.muvekkilId === selectedIcraFoyMuvekkil?.muvekkilId &&
              x.tip === selectedIcraFoyMuvekkil?.tip
          );

          var upsertedIndex = muvekkils.findIndex(
            (x) =>
              x.muvekkilId === muvekkil.muvekkilId && x.tip === muvekkil.tip
          );

          if (upsertedIndex > -1) {
            toast.show("Bu müvekkil zaten ekli.", "error");
            return;
          }

          if (!selectedIcraFoyMuvekkil) {
            formik.setFieldValue("icraFoyMuvekkils", [
              ...formik.values.icraFoyMuvekkils,
              muvekkil,
            ]);
          } else {
            muvekkils[selectedIndex] = muvekkil;
            formik.setFieldValue("icraFoyMuvekkils", [...muvekkils]);
          }
          setSelectedIcraFoyMuvekkil(undefined);
        }}
      />
    </>
  );
};

export default UpsertIcraFoy;
