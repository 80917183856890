import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { NoContentDto } from "../base/base.dto";
import {
  BelgeArsiviDto,
  CreateBelgeArsiviDto,
  UpdateBelgeArsiviDto,
} from "./belge-arsivi.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<BelgeArsiviDto>(`BelgeArsivi/Get/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<BelgeArsiviDto[]>(`BelgeArsivi/GetAll`, config);
  },
  GetAllByIcraFoyId(
    icraFoyId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<BelgeArsiviDto[]>(
      `BelgeArsivi/GetAllByIcraFoyId/${icraFoyId}`,
      config
    );
  },
  Create(
    dto: CreateBelgeArsiviDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<BelgeArsiviDto>("BelgeArsivi/Create", dto, config);
  },
  Update(
    dto: UpdateBelgeArsiviDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<BelgeArsiviDto>("BelgeArsivi/Update", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`BelgeArsivi/Delete/${id}`, config);
  },
};

export default services;
