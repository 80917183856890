import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useToast } from "../../utils/toast";
import { EvrakListeleyici } from "../dosya-detaylari/components/evrak-component/EvrakListeleyici";
import {
  EvrakTreeNode,
  useEvrakListeleyici,
  IEvrakDTO
} from "../dosya-detaylari/components/evrak-component/useEvrakListeleyici";
import { FileViewer, FileDTO, useFileViewer } from "../../components/@fileViewer";
import {
  TumEvraklarResponseDTO,
  EvrakDTO,
  EkEvrakDTO,
  EvraklarDTO
} from "../../services/uyap-sorgulari/uyap-sorgulari.dto";
import {
  DownloadEvrakRequestDto,
  ElecronEvrakResponseDto,
  ElectronGetEvrakResponseDto,
  GetEvrakRequestDto
} from "../dosya-detaylari/components/evrak-component/evrak.models.dto";
import moment from "moment";
import { EvrakBulkDownloader } from "../dosya-detaylari/components/evrak-component/EvrakBulkDownloader";
import { useVatandasPortal } from "../../utils/vatandasPortalContext";

export const VatandasPortal = () => {
  const [vatandasOturum, setVatandasOturum] = useState<any>(null);
  const [evraklar, setEvraklar] = useState<any>();
  const [evraklarMetadata, setEvraklarMetadata] = useState<any>();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [seciliEvrak, setSeciliEvrak] = useState<FileDTO>();
  const [localEvraklar, setLocalEvraklar] = useState<string[]>([]);
  const [evraklarWithLocal, setEvrakWithLocal] = useState<{ result: TumEvraklarResponseDTO }>();
  const toast = useToast();
  const { unarchiveZip, zipFiles, getMimeTypeFromExtension, availableMimeTypes } = useFileViewer();
  const isBulkLoaderOpen = useRef(false)
  const openVatandasPortal = async () => {
    const result = await window.electron?.ipcRenderer?.invoke("open-vatandasportal-window", {});
  };

  const closeVatandasPortal = async () => {
    setEvraklar(undefined);
    setSeciliEvrak(undefined);
    setEvrakWithLocal(undefined);
    setLocalEvraklar([]);
    setVatandasOturum(null);
    const result = await window.electron?.ipcRenderer?.invoke("close-vatandasportal-window", {});
  };

  useEffect(() => {
    window.electron?.ipcRenderer?.on("vatandasportal-get-evraklar", async (event, evraklarResponse) => {
      setEvraklar(evraklarResponse?.evraklar);
      setEvraklarMetadata({ ...evraklarResponse?.metadata });
    });

    window.electron?.ipcRenderer?.on("vatandas-portal-oturum", async (_, data) => {
      setVatandasOturum(data);
    });

    return () => {
      window.electron?.ipcRenderer?.removeAllListeners("vatandasportal-cookies");
    };
  }, []);

  useEffect(() => {
    if (
      !evraklarMetadata?.dosyaId &&
      !evraklarMetadata?.dosyaTur &&
      !evraklarMetadata?.dosyaNo &&
      !evraklarMetadata?.birimAdi
    ) {
      return;
    }
    sendGetAllLocalEvraksMessageToElectron(evraklarMetadata);
  }, [evraklarMetadata]);

  const fileSelectedHandler = async (data: EvrakTreeNode) => {
    if (!data) return;

    setIsFileLoading(true);
    let fileBlob: Blob = new Blob();
    let fileMimeType: string = "";
    try {
      if (data?.data?.location) {
        if (data?.data?.location == "zip" && data?.data?.file) {
          const zipedFileType = getMimeTypeFromExtension(data?.data?.file?.name as string);
          if (!zipedFileType) return console.error("zipden cikarilan dosyanin mimeTypei okunamadi");

          const blob = await data?.data?.file.async("blob");
          fileBlob = blob;
          fileMimeType = zipedFileType;
        } else {
          await sendGetEvrakMessageToElectron({ path: data?.data?.location }, data?.data?.evrakId);
          return;
        }
      } else {
        if (!vatandasOturum?.cookies) throw new Error("vatandas cookie alinamadi");
        if (!data?.data?.evrakId) throw new Error("evrakId not founded");

        const response = await window.electron.ipcRenderer.invoke("vatandasportal-dosya-indir", {
          evrak: data?.data,
          cookies: vatandasOturum?.cookies
        });

        if (!response?.isSuccessful) throw new Error(response?.errorMessage);

        fileMimeType = response.result.mimeType;
        fileBlob = new Blob([response.result.file], { type: "application/download" });

        if (fileBlob.size == 0) {
          setSeciliEvrak({
            mimeType: "text/html",
            data: new Blob(["Dosya açılırken uyap kaynaklı bir hata oluştu. Daha sonra tekrar deneyiniz"], {
              type: "text/html"
            })
          });
          return console.error("fileSelectedHandler: file not founded");
        }

        if (fileBlob?.type == "text/html") {
          setSeciliEvrak({
            mimeType: "text/html",
            data: fileBlob
          });
          return;
        }

        if (fileMimeType == "application/pkcs7-mime" || fileMimeType == "application/eyp") {
          await sendDownloadEvrakMessageToElectron(
            {
              evrakId: data?.data?.evrakId,
              birimAdi: evraklarMetadata?.birimAdi,
              dosyaNo: evraklarMetadata?.dosyaNo,
              dosyaTur: evraklarMetadata?.dosyaTur,
              file: await (fileBlob as Blob).arrayBuffer(),
              fileExtension: availableMimeTypes[fileMimeType],
              onaylandigiTarihNormal: data?.data?.sistemeGonderildigiTarih.replaceAll("/", "-"),
              onaylandigiTarihYilOnce: moment(data?.data?.sistemeGonderildigiTarih, "DD/MM/YYYY").format("YYYY.MM.DD"),
              tur: data?.data?.tur,
              anaEvrakId: data?.data?.anaEvrakId,
              ekEvrakName: `Ek - ${data?.data?.sira}`
            },
            evraklarMetadata
          );
          unarchiveZip(fileBlob as Blob, data?.data?.evrakId);
          return;
        }
      }

      if (!fileMimeType || !fileBlob) return;

      setSeciliEvrak({
        mimeType: fileMimeType,
        data: fileBlob
      });

      if (data?.data?.file) return;

      const localIndex = localEvraklar.findIndex((p) => p.includes(data?.data?.evrakId));

      if (
        localIndex != -1 &&
        localEvraklar[localIndex].includes(evraklarMetadata?.dosyaNo) &&
        localEvraklar[localIndex].includes(evraklarMetadata?.birimAdi) &&
        localEvraklar[localIndex].includes(evraklarMetadata?.dosyaTur)
      )
        return;

      await sendDownloadEvrakMessageToElectron(
        {
          evrakId: data?.data?.evrakId,
          birimAdi: evraklarMetadata?.birimAdi,
          dosyaNo: evraklarMetadata?.dosyaNo,
          dosyaTur: evraklarMetadata?.dosyaTur,
          file: await (fileBlob as Blob).arrayBuffer(),
          fileExtension: availableMimeTypes[fileMimeType],
          onaylandigiTarihNormal: data?.data?.sistemeGonderildigiTarih.replaceAll("/", "-"),
          onaylandigiTarihYilOnce: moment(data?.data?.sistemeGonderildigiTarih, "DD/MM/YYYY").format("YYYY.MM.DD"),
          tur: data?.data?.tur,
          anaEvrakId: data?.data?.anaEvrakId,
          ekEvrakName: `Ek - ${data?.data?.sira}`
        },
        evraklarMetadata
      );
    } catch (error) {
      console.error("bir hata olustu:", error);
    } finally {
      setIsFileLoading(false);
    }
  };

  const sendGetAllLocalEvraksMessageToElectron = async ({
    dosyaId,
    dosyaTur,
    dosyaNo,
    birimAdi
  }: {
    dosyaId: string;
    dosyaTur: string;
    dosyaNo: string;
    birimAdi: string;
  }) => {
    const res: ElecronEvrakResponseDto<string[]> = await window.electron?.ipcRenderer?.invoke("get-all-local-evraks", {
      dosyaId,
      dosyaTur,
      dosyaNo,
      birimAdi
    });

    if (!res.isSuccessful) {
      toast.show(res.errorMessage, "error");
      return;
    }
    // TODO

    const allEvrakNames = res.result;
    setLocalEvraklar(allEvrakNames);
  };

  useEffect(() => {
    const tumEvraklar = evraklar?.result?.tumEvraklar;
    let son20Evrak = evraklar?.result?.son20Evrak;

    if (!son20Evrak || !tumEvraklar) return;

    setEvrakWithLocal({
      result: {
        son20Evrak,
        tumEvraklar
      }
    });

    if (localEvraklar.length > 0) {
      const tumEvraklarWithLocal: EvraklarDTO = {};
      const son20EvrakLocal: IEvrakDTO[] = [];

      Object.keys(tumEvraklar)
        .map((dosyalar: string) => {
          return tumEvraklar[dosyalar].map((dosyaEvrak: any) => {
            return {
              ...dosyaEvrak,
              ekEvrakListesi: dosyaEvrak?.ekEvrakListesi?.map((ekEvrak: any, index: number) => {
                return {
                  ...ekEvrak,
                  sira: index + 1,
                  location:
                    localEvraklar.filter(
                      (item: any) =>
                        item.includes(`${ekEvrak?.evrakId}_`) &&
                        item.includes(`${evraklarMetadata?.dosyaNo.replaceAll("/", "-")}`) &&
                        item.includes(`${evraklarMetadata?.birimAdi}`)
                    )[0] ?? ""
                };
              }),
              location:
                localEvraklar.filter(
                  (item: any) =>
                    item.includes(`${dosyaEvrak?.evrakId}_`) &&
                    item.includes(`${evraklarMetadata?.dosyaNo.replaceAll("/", "-")}`) &&
                    item.includes(`${evraklarMetadata?.birimAdi}`) &&
                    item.includes(dosyaEvrak?.tur)
                )[0] ?? ""
            };
          });
        })
        .forEach((data, index) => {
          tumEvraklarWithLocal[Object.keys(tumEvraklar)[index]] = data;
        });

      son20Evrak.map((dosyaEvrak: any) => {
        son20EvrakLocal.push({
          ...dosyaEvrak,
          ekEvrakListesi: dosyaEvrak?.ekEvrakListesi?.map((ekEvrak: any, index: number) => {
            return {
              ...ekEvrak,
              sira: index + 1,
              location:
                localEvraklar.filter(
                  (item: any) =>
                    item.includes(`${ekEvrak?.evrakId}_`) &&
                    item.includes(`${evraklarMetadata?.dosyaNo.replaceAll("/", "-")}`) &&
                    item.includes(`${evraklarMetadata?.birimAdi}`)
                )[0] ?? ""
            };
          }),
          location:
            localEvraklar.filter(
              (item: any) =>
                item.includes(`${dosyaEvrak?.evrakId}_`) &&
                item.includes(`${evraklarMetadata?.dosyaNo.replaceAll("/", "-")}`) &&
                item.includes(`${evraklarMetadata?.birimAdi}`) &&
                item.includes(dosyaEvrak?.tur)
            )[0] ?? ""
        });
      });

      setEvrakWithLocal({
        result: {
          son20Evrak: son20EvrakLocal,
          tumEvraklar: tumEvraklarWithLocal
        }
      });
    }
  }, [localEvraklar, evraklar]);

  const sendDownloadEvrakMessageToElectron = async (
    downloadEvrakRequestDto: DownloadEvrakRequestDto,
    metadata: any
  ) => {
    
    const response = await window.electron?.ipcRenderer?.invoke("download-evrak", downloadEvrakRequestDto);
    
    const res: ElecronEvrakResponseDto<string> = response;

    if (!res.isSuccessful) {
      toast.show(res.errorMessage, "error");
      return;
    }
    await sendGetAllLocalEvraksMessageToElectron(metadata);
    toast.show("Evrak başarıyla indirildi.", "success");
  };

  // Evrağı okuması için Electron'a mesaj gönderir.
  const sendGetEvrakMessageToElectron = async (getEvrakRequestDto: GetEvrakRequestDto, evrakId?: string) => {
    const response = await window.electron?.ipcRenderer.invoke("get-evrak", getEvrakRequestDto);
    const res: ElecronEvrakResponseDto<ElectronGetEvrakResponseDto> = response;
    
    if (!res.result.data)
      return setSeciliEvrak({
        mimeType: "text/html",
        data: new Blob(["Dosya açılırken hata oluştu - [hata456]"], {
          type: "text/html"
        })
      });

    if (!res.result.mimeType)
      return setSeciliEvrak({
        mimeType: "text/html",
        data: new Blob(["Dosya açılırken hata oluştu - [hata352]"], {
          type: "text/html"
        })
      });

    if (res.result.mimeType == "application/pkcs7-mime" || res.result.mimeType == "application/eyp") {
      unarchiveZip(new Blob([res.result.data]), evrakId as string);
      return;
    }

    setSeciliEvrak({
      mimeType: res.result.mimeType,
      data: new Blob([res.result.data], { type: "application/download" }) // udf formatlarinda bozuk geliyor
    });
  };
  
  return (
    <div className="card">
      <div className="flex flex-column gap-3">
        <div className="flex justify-content-between w-full gap-3">
          <div className="flex gap-1 flex-column">
            <p className="">
              <span className="font-bold">Vatandaş Portal Kullanıcısı: </span>
              <span className="">{!vatandasOturum ? "Giriş yapınız." : vatandasOturum?.username}</span>
            </p>
            <div className="flex gap-2 align-items-center text-red-500 font-bold">
              <i className="pi pi-info-circle text-lg text-red-500" />
              <p className="text-sm">
                Evrak indirmek istediğiniz dosyanın evrak sekmesini açarak Portal'a Aktar butonuna tıklayınız.
              </p>
            </div>
          </div>
          {!vatandasOturum ? (
            <Button label="Vatandaş Portala Bağlan" onClick={openVatandasPortal} />
          ) : (
            <Button severity="danger" label="Bağlantıyı Kes" onClick={closeVatandasPortal} />
          )}
        </div>
        {/* )} */}
        <div>
          {vatandasOturum && evraklarWithLocal && (
            <EvrakListeleyici
              dosyaId={evraklarMetadata?.dosyaId}
              dosyaNo={evraklarMetadata?.dosyaNo}
              dosyaTur={evraklarMetadata?.dosyaTur}
              birimAdi={evraklarMetadata?.birimAdi}
              evraklar={evraklarWithLocal}
              loading={false}
              mainFolderLabel={`${evraklarMetadata?.birimAdi} ${evraklarMetadata?.dosyaNo}`}
              onNodeClick={(data) => {
                if(!isBulkLoaderOpen.current) fileSelectedHandler(data);
              }}
              bulkDownloader={(nodes, setDownloadOpen, _ ,setSelectionOn) => (
                <EvrakBulkDownloader
                  nodes={nodes}
                  dosyaNo={evraklarMetadata?.dosyaNo}
                  birimAdi={evraklarMetadata?.birimAdi}
                  dosyaTur={evraklarMetadata?.dosyaTur}
                  dosyaId={evraklarMetadata?.dosyaId}
                  setDownloadOpen={setDownloadOpen}
                  useContext={useVatandasPortal}
                  setSelection={setSelectionOn}
                />
              )}
              onBulkDownloadOpen={(arg) => {
                isBulkLoaderOpen.current = arg
              }}
            >
              {seciliEvrak && <FileViewer file={seciliEvrak} loading={isFileLoading} />}
            </EvrakListeleyici>
          )}
        </div>
      </div>
    </div>
  );
};
