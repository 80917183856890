import { useEffect } from "react";
import { Carousel } from "primereact/carousel";
import { useBasket } from "../../utils/basket-provider";
import { useSearchParams } from "react-router-dom";
import { useToast } from "../../utils/toast";

function Homepage() {
  const basket = useBasket();
  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");
  const toast = useToast();

  useEffect(() => {
    if (!message) {
      return;
    }

    if (message === "orderApproved") {
      toast.show("Ödemeniz başarıyla alındı. Teşekkür ederiz.", "success");
    }

    if (message === "orderFailed") {
      toast.show("Ödemeniz alınamadı. Lütfen tekrar deneyiniz.", "error");
    }
  }, [message]);

  useEffect(() => {
    basket.setUserInformation();
  }, []);

  const crouselItems: any = [
    {
      name: "Item 1",
      description: "Description for Item 1",
      image: "/images/logo.png"
    },
    {
      name: "Item 2",
      description: "Description for Item 2",
      image: "/images/logo.png"
    },
    {
      name: "Item 3",
      description: "Description for Item 3",
      image: "/images/logo.png"
    },
    {
      name: "Item 4",
      description: "Description for Item 4",
      image: "/images/logo.png"
    },
    {
      name: "Item 5",
      description: "Description for Item 5",
      image: "/images/logo.png"
    }
  ];

  const itemTemplate = (item: any) => {
    return (
      <div className="flex justify-content-center">
        <img height={400} src={item.image} alt={item.description}></img>
      </div>
    );
  };

  return (
    <>
      <div className="card">
        <Carousel
          value={crouselItems}
          numVisible={1}
          numScroll={1}
          // responsiveOptions={responsiveOptions}
          className=""
          circular
          autoplayInterval={3000}
          itemTemplate={itemTemplate}
        />
      </div>

      {basket.getUser() && (
        <div className="text-center">
          <h3>Hoş Geldiniz</h3>
          <h5>{basket.getUser()?.firstName + " " + basket.getUser()?.lastName}</h5>
        </div>
      )}
    </>
  );
}

export default Homepage;
