import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta, DataTableValueArray } from "primereact/datatable";
import { useHub } from "../../../utils/buro-hub";
import uyapService from "../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { commonFilterContains, CreateHeader, Filter } from "../../../pages/filter/base-filter";
import { ExportFile } from "../../../utils/export-file";
import { TarafResponseDTO } from "../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { LayoutContext } from "../../../layout/context/layoutcontext";

const TarafBilgileri = (props: { dosyaId: string }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TarafResponseDTO[]>>(null);

  const {
    data: TarafResponse,
    refetch: refetchTaraf,
    isLoading: tarafResponseLoading
  } = useQuery({
    queryKey: ["Taraf", props.dosyaId],
    refetchOnMount: false,
    gcTime: 1000 * 60 * 11,
    queryFn: async () => await hub.executeUyapService(() => uyapService.GetTarafBilgileri(props.dosyaId))
  });

  useEffect(() => { setBlocked(tarafResponseLoading) }, [tarafResponseLoading])

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      rol: commonFilterContains,
      kisiKurum: commonFilterContains,
      adi: commonFilterContains,
      vekil: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const data = TarafResponse?.result?.map((item, index) => ({ ...item, key: index })) || [];

    const headers = ["Rol", "Tipi", "Adı", "Vekil"];

    const filteredData = data.map(({ rol, kisiKurum, adi, vekil }) => ({
      rol,
      kisiKurum,
      adi,
      vekil
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TarafBilgileri");
  };

  return (
    <div className="mt-2">
      {TarafResponse?.error && <div className="card">Hata : {TarafResponse.error}</div>}
      {TarafResponse?.result && (
        <div>
          <DataTable
            ref={dt}
            value={TarafResponse?.result.map((item, index) => ({ ...item, key: index })) || []}
            dataKey="key"
            showGridlines
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            emptyMessage="Taraf Bilgisi bulunamadı."
            loading={tarafResponseLoading}
            filters={filters}
            globalFilterFields={["rol", "kisiKurum", "adi", "vekil"]}
            columnResizeMode="expand"
            header={CreateHeader(
              "Taraflar",
              globalFilterValue,
              (e) => onGlobalFilterChange(e),
              clearFilter,
              exportCSV,
              exportExcel
            )}
          >
            <Column
              field="rol"
              header="Rol"
              sortable
              filterField="rol"
              filter
              filterPlaceholder="Rol'e göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Rol'e göre filtreleyin</div>;
              }}
            ></Column>
            <Column
              field="kisiKurum"
              header="Tipi"
              sortable
              filterField="kisiKurum"
              filter
              filterPlaceholder="Tip'e göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Tip'e göre filtreleyin</div>;
              }}
            ></Column>
            <Column
              field="adi"
              header="Adı"
              sortable
              filterField="adi"
              filter
              filterPlaceholder="Ad'a göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Ad'a göre filtreleyin</div>;
              }}
            ></Column>

            <Column
              field="vekil"
              header="Vekil"
              sortable
              filterField="vekil"
              filter
              filterPlaceholder="Vekil'e göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Vekil'e göre filtreleyin</div>;
              }}
            ></Column>
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default TarafBilgileri;
