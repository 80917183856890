import React, { useContext, useEffect, useRef, useState } from "react";
import { AracBilgileri, DosyaDTO, EgmResponseDTO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import SorguBilgileri from "./SorguBilgileri";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import EgmMahrumiyet from "./EgmMahrumiyet";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const Egm = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)
  const [visibleEgmMahrumiyetDialog, setVisibleEgmMahrumiyetDialog] = useState(false);
  const [egmData, setEgmData] = useState<AracBilgileri>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<AracBilgileri[]>>(null);

  const {
    data: AracResponse,
    refetch: refetchArac,
    isLoading: aracResponseLoading
  } = useQuery({
    queryKey: ["Egm", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetAracBilgiler(props.dosyaId, props.kisiKurumId, 0))
  });

  useEffect(() => {
    setBlocked(aracResponseLoading)
  }, [aracResponseLoading])

  const hisseBilgisiActionBodyTemplate = (rowData: AracBilgileri) => {
    return (
      <button onClick={() => handleButtonClick(rowData)} className="p-button p-component p-button-info">
        Araç Mahrumiyet
      </button>
    );
  };

  const handleButtonClick = (rowData: AracBilgileri) => {
    setVisibleEgmMahrumiyetDialog(true);
    setEgmData(rowData);
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      plaka: commonFilterContains,
      marka: commonFilterContains,
      model: commonFilterContains,
      ticariAd: commonFilterContains,
      renk: commonFilterContains,
      cins: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const data =
      AracResponse?.result?.sorguSonucDVO?.aracBilgileri?.output?.map((item, index) => ({ ...item, key: index })) || [];

    const headers = ["Plaka", "Marka", "Model", "Tipi", "Renk", "Cins"];

    const filteredData = data.map(({ plaka, marka, model, ticariAd, renk, cins }) => ({
      plaka,
      marka,
      model,
      ticariAd,
      renk,
      cins
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "Egm");
  };

  return (
    <div>
      {AracResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {AracResponse.error}</div>}

      {AracResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={AracResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={AracResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={AracResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={AracResponse?.result?.sorgulayanBilgileri}
          />
          {AracResponse?.result?.sorguSonucDVO?.aracBilgileri?.hatakod != 0 ? (
            <div className="card p-2 lg:p-5 mt-3">
              {AracResponse?.result?.sorguSonucDVO?.aracBilgileri?.hataMesaj.toString()}
            </div>
          ) : (
            <div>
              <div className="overflow-x-auto mt-3">
                <DataTable
                  value={AracResponse?.result?.sorguSonucDVO?.aracBilgileri.output || []}
                  ref={dt}
                  dataKey="plakaSifreli"
                  showGridlines
                  scrollable
                  loading={aracResponseLoading}
                  //scrollHeight="250px"
                  style={{ minHeight: "7rem" }}
                  selectionMode={"single"}
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  emptyMessage="Araç Bilgisi bulunamadı."
                  // filterDisplay="row"
                  filters={filters}
                  // filterDisplay="row"
                  globalFilterFields={["plaka", "marka", "model", "ticariAd", "renk", "vekil"]}
                  columnResizeMode="expand"
                  //header={header}
                  header={CreateHeader(
                    "Egm",
                    globalFilterValue,
                    (e) => onGlobalFilterChange(e),
                    clearFilter,
                    exportCSV,
                    exportExcel
                  )}
                >
                  <Column
                    header="No"
                    body={(rowData, { rowIndex }) => rowIndex + 1}
                    className="w-2rem text-center"
                  ></Column>
                  <Column
                    field="plaka"
                    header="Plaka"
                    sortable
                    filterField="rol"
                    filter
                    filterPlaceholder="Plaka'ya göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Plaka'ya göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="marka"
                    header="Marka"
                    sortable
                    filterField="rol"
                    filter
                    filterPlaceholder="Marka'ya göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Marka'ya göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="model"
                    header="Model"
                    sortable
                    filterField="rol"
                    filter
                    filterPlaceholder="Model'e göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Model'e göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="ticariAd"
                    header="Tipi"
                    sortable
                    filterField="rol"
                    filter
                    filterPlaceholder="Tip'e göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Tip'e göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="renk"
                    header="Renk"
                    sortable
                    filterField="rol"
                    filter
                    filterPlaceholder="Renk'e göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Renk'e göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="cins"
                    header="Cins"
                    sortable
                    filterField="rol"
                    filter
                    filterPlaceholder="Cins'e göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Cins'e göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column body={hisseBilgisiActionBodyTemplate} header="Araç Mahrumiyet"></Column>
                </DataTable>
              </div>

              {visibleEgmMahrumiyetDialog && (
                <>
                  <EgmMahrumiyet
                    visible={visibleEgmMahrumiyetDialog}
                    setVisible={setVisibleEgmMahrumiyetDialog}
                    egmData={egmData}
                    dosyaId={props.dosyaId}
                    kisiKurumId={props.kisiKurumId}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Egm;
