import SorguBilgileri from "../SorguBilgileri";
import { useHub } from "../../../../../utils/buro-hub";
import uyapService from "../../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../../../layout/context/layoutcontext";

const KamuCalisani = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: KamuCalisaniResponse,
    refetch: refetchKamuCalisani,
    isLoading: kamuCalisaniResponseLoading
  } = useQuery({
    queryKey: ["KamuCalisani", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetKamuCalisanBilgiari(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(kamuCalisaniResponseLoading)
  }, [kamuCalisaniResponseLoading])

  return (
    <div>
      {KamuCalisaniResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {KamuCalisaniResponse.error}</div>}
      {KamuCalisaniResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={KamuCalisaniResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={KamuCalisaniResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={KamuCalisaniResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={KamuCalisaniResponse?.result?.sorgulayanBilgileri}
          />
          {KamuCalisaniResponse.result.sorguSonucu === false ? (
            <div className="card p-2 lg:p-5 mt-3">Kayıt bulunamadı</div>
          ) : (
            <>
              {KamuCalisaniResponse?.result?.sorguSonucDVO?.hataKodu != 0 ? (
                <div className="card p-2 lg:p-5 mt-3">
                  {KamuCalisaniResponse?.result?.sorguSonucDVO?.hataMesaji.toString()}
                </div>
              ) : (
                <div className="card p-2 lg:p-5 mt-3">
                  <div className="grid">
                    <div className="col-12">
                      <div className="flex gap-3">
                        <div className="font-bold w-12rem">Kurum</div>
                        <div>{` : ${KamuCalisaniResponse?.result?.sorguSonucDVO?.kurum}`}</div>
                      </div>
                    </div>
                    <div className="col-12 md:col-6">
                      <div className="flex gap-3">
                        <div className="font-bold w-12rem">Kurum İl</div>
                        <div>{` : ${KamuCalisaniResponse?.result?.sorguSonucDVO?.kurumIl}`}</div>
                      </div>
                    </div>
                    <div className="col-12 md:col-6">
                      <div className="flex gap-3">
                        <div className="font-bold w-12rem">Kurum İlçe</div>
                        <div>{` : ${KamuCalisaniResponse?.result?.sorguSonucDVO?.kurumIlce}`}</div>
                      </div>
                    </div>
                    <div className="col-12 md:col-6">
                      <div className="flex gap-3">
                        <div className="font-bold w-12rem">İşe Başlama Tarihi</div>
                        <div>{` : ${KamuCalisaniResponse?.result?.sorguSonucDVO?.iseBaslamaTarihi}`}</div>
                      </div>
                    </div>
                    <div className="col-12 md:col-6">
                      <div className="flex gap-3">
                        <div className="font-bold w-12rem">Ünvan</div>
                        <div>{` : ${KamuCalisaniResponse?.result?.sorguSonucDVO?.unvani}`}</div>
                      </div>
                    </div>
                    <div className="col-12 md:col-6">
                      <div className="flex gap-3">
                        <div className="font-bold w-12rem">Durum</div>
                        <div>{` : ${KamuCalisaniResponse?.result?.sorguSonucDVO?.durumKapsami4C}`}</div>
                      </div>
                    </div>
                    <div className="col-12 md:col-6">
                      <div className="flex gap-3">
                        <div className="font-bold w-12rem">Açıklama</div>
                        <div>{` : ${KamuCalisaniResponse?.result?.sorguSonucDVO?.durumAciklamasi4C}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default KamuCalisani;
