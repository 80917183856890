import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";
import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguDurum, TopluSorguModel, TopluSorguOrtakModel, TopluSorguTur } from "./TopluSorgu.dto";
import { useToast } from "../../utils/toast";
import { filter } from "jszip";
import { ConfirmPopup } from "primereact/confirmpopup";
import { confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import { useElectron } from "../../utils/electron-connection";
import { useNavigate } from "react-router-dom";
import { useTopluSorguIslemler } from "../../utils/toplu-sorgu-islemler";
import { ExportFile } from "../../utils/export-file";

const TopluSorguSonuclarListesi = () => {
  const [topluSorguSonuclarListesi, setTopluSorguSonuclarListesi] = useState<TopluSorguModel[]>([]);
  const toast = useToast();
  const [filtersListeler, setFiltersListeler] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValueListeler, setGlobalFilterValueListeler] = useState<string>("");
  const electron = useElectron();
  const navigate = useNavigate();
  const TopluSorguIslemler = useTopluSorguIslemler();

  const clearFilterListeler = () => {
    initFiltersListeler();
  };

  const initFiltersListeler = () => {
    setFiltersListeler({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      listName: commonFilterContains,
      status: commonFilterContains,
      baslamaTarihi: commonFilterDateIs
    });
    setGlobalFilterValueListeler("");
  };

  useEffect(() => {
    initFiltersListeler();
  }, []);

  const onGlobalFilterChangeListeler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filtersListeler };
    // @ts-ignore
    _filters["global"].value = value;
    setFiltersListeler(_filters);
    setGlobalFilterValueListeler(value);
  };

  const readTopluSorguSonuclarList = async () => {
    const topluSorguSonuclarListJson = await electron.getTopluSorguSonuclarListesi();
    if (topluSorguSonuclarListJson) {
      setTopluSorguSonuclarListesi(topluSorguSonuclarListJson);
    }
  };

  useEffect(() => {
    readTopluSorguSonuclarList();
  }, []);

  const actionBodyTemplate = (rowData: TopluSorguModel) => {
    return (
      <div className="flex justify-content-center">
        <Toast />
        <ConfirmPopup />
        <Button
          icon="pi pi-search"
          rounded
          outlined
          className="mr-2"
          severity="success"
          tooltip="Toplu Sorgu Sonuçlarını Görüntüle"
          onClick={() => {
            const data =
              rowData.status === TopluSorguDurum.DEVAM_EDİYOR && TopluSorguIslemler.topluSorguYapiliyorMu
                ? { model: TopluSorguIslemler.topluSorguModel }
                : { model: rowData };
            navigate("/app/toplu-sorgulama", { state: data });
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          className="mr-2"
          severity="danger"
          tooltip="Sonuç silme"
          onClick={(e) => deletePopup(e, rowData)}
        />
        <Button
          icon="pi pi-file-excel"
          rounded
          outlined
          className="mr-2"
          severity="contrast"
          tooltip="Excele Aktar"
          onClick={(e) => ExportFile.CeateTopluExcelFile(rowData, "TopluSorguSonuclari")}
        />
      </div>
    );
  };

  const deletePopup = (event: { currentTarget: any }, rowData: TopluSorguModel) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Toplu sorgu sonucunu silmek istediğinize emin misiniz?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: async () => {
        let updatedTopluSorguSonuclarListesi: TopluSorguModel[] = [];

        for await (const item of topluSorguSonuclarListesi) {
          if (item.key !== rowData.key) {
            updatedTopluSorguSonuclarListesi.push(item);
          }
        }

        setTopluSorguSonuclarListesi(updatedTopluSorguSonuclarListesi);

        const res = await electron.setTopluSorguSonuclarListesi(updatedTopluSorguSonuclarListesi);
        if (!res) {
          toast.show("Kayıt silinirken bir sorun oluştu", "error");
          return;
        }

        toast.show("Kayıt başarıyla silindi", "success");
      },
      reject: () => {}
    });
  };

  return (
    <>
      <div className="card">
        <DataTable
          value={topluSorguSonuclarListesi ?? []}
          key="key"
          showGridlines
          stripedRows
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          filters={filtersListeler}
          emptyMessage="Hiç Toplu Sorgu Sonuçu Bulunamadı"
          globalFilterFields={["listName", "status", "baslamaTarihi"]}
          header={CreateHeader(
            "Toplu Sorgu Sonuçları Listeleri",
            globalFilterValueListeler,
            (e) => onGlobalFilterChangeListeler(e),
            clearFilterListeler
          )}
          columnResizeMode="expand"
          sortField="baslamaTarihi"
          sortOrder={-1}
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "8rem" }}></Column>
          <Column
            field="listName"
            header="Liste Adı"
            sortable
            filter
            filterField="listName"
            filterPlaceholder="Liste Adına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Liste Adına göre filtreleyin</div>}
          />
          <Column
            field="status"
            header="Durum"
            sortable
            body={(rowData) => (
              <div className="flex justify-content-center align-items-center p-0 m-0">
                {rowData.status === TopluSorguDurum.TAMAMLANDI ? (
                  <div className="p-0 flex align-items-center">
                    <div className="px-3 py-2 bg-green-100 text-green-900 border-round font-medium text-center">
                      {`${TopluSorguDurum[rowData.status]}`}
                    </div>
                  </div>
                ) : (
                  <div className="p-0 flex align-items-center">
                    <div className="px-3 py-2 bg-blue-100 text-blue-900 border-round font-medium text-center">
                      {`${TopluSorguDurum[rowData.status]}`}
                    </div>
                  </div>
                )}
              </div>
            )}
            // body={(rowData) => TopluSorguDurum[rowData.status]}
            filter
            filterField="status"
            filterPlaceholder="Duruma göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>}
          />
          <Column
            header="Toplu Sorgu Tarihi"
            field="baslamaTarihi"
            sortable
            body={(rowData) =>
              rowData.baslamaTarihi
                ? moment(rowData.baslamaTarihi).format("DD.MM.YYYY HH:mm")
                : "Toplu Sorgu Başlatılmadı"
            }
            filter
            filterField="baslamaTarihi"
            filterPlaceholder="Tarihe göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tarihe göre filtreleyin</div>}
          />
        </DataTable>
      </div>
    </>
  );
};

export default TopluSorguSonuclarListesi;
