import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import SorguBilgileri from "./SorguBilgileri";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const SgkHaciz = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);

  const {
    data: SgkHacizResponse,
    refetch: refetchSgkHaciz,
    isLoading: sgkHacizResponseLoading
  } = useQuery({
    queryKey: ["SgkHaciz", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetSgkHacizBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(sgkHacizResponseLoading);
  }, [sgkHacizResponseLoading]);

  return (
    <div>
      {SgkHacizResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {SgkHacizResponse.error}</div>}
      {SgkHacizResponse?.result && (
        <div className="card p-2 lg:p-5 mt-2">
          <SorguBilgileri
            sorguAdı={SgkHacizResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={SgkHacizResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={SgkHacizResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={SgkHacizResponse?.result?.sorgulayanBilgileri}
          />
          <br />

          {SgkHacizResponse?.result?.sorguSonucDVO && SgkHacizResponse?.result?.sorguSonucDVO?.length > 0 && (
            <div className="overflow-x-auto">
              <DataTable
                value={SgkHacizResponse?.result?.sorguSonucDVO?.map((item, index) => ({ ...item, key: index })) || []}
                dataKey="key"
                showGridlines
                scrollable
                scrollHeight="250px"
                loading={sgkHacizResponseLoading}
                style={{ minHeight: "7rem" }}
                selectionMode={"single"}
              >
                <Column
                  header="No"
                  body={(rowData, { rowIndex }) => rowIndex + 1}
                  className="w-4rem text-center"
                ></Column>
                <Column field="kisiTipString" header="Kurum" sortable></Column>
              </DataTable>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SgkHaciz;
