import SorguBilgileri from "../SorguBilgileri";
import { useHub } from "../../../../../utils/buro-hub";
import uyapService from "../../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../../../layout/context/layoutcontext";

const BagkurCalisani = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);

  const {
    data: BagkurCalisaniResponse,
    refetch: refetchBagkurCalisani,
    isLoading: bagkurCalisaniResponseLoading
  } = useQuery({
    queryKey: [props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetBağkurCalisanBilgiler(props.dosyaId, props.kisiKurumId))
  });

  const dateStringTransform = (dateString: string) => {
    if (dateString && dateString.length === 8) {
      const year = parseInt(dateString.substring(0, 4));
      const month = parseInt(dateString.substring(4, 6));
      const day = parseInt(dateString.substring(6, 8));

      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    setBlocked(bagkurCalisaniResponseLoading);
  }, [bagkurCalisaniResponseLoading]);

  return (
    <div>
      {BagkurCalisaniResponse?.error && (
        <div className="card p-2 lg:p-5 mt-3">Hata : {BagkurCalisaniResponse.error}</div>
      )}
      {BagkurCalisaniResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={BagkurCalisaniResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={BagkurCalisaniResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={BagkurCalisaniResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={BagkurCalisaniResponse?.result?.sorgulayanBilgileri}
          />
          {BagkurCalisaniResponse?.result?.sorguSonucDVO?.islemSonuc != 0 ? (
            <div className="card p-2 lg:p-5 mt-3">
              {BagkurCalisaniResponse?.result?.sorguSonucDVO?.anaHataMesaji.toString()}
            </div>
          ) : (
            <div className="card p-2 lg:p-5 mt-3">
              {BagkurCalisaniResponse?.result?.sorguSonucDVO?.tescilKaydi4BList?.[0] ? (
                <div className="grid">
                  <div className={"col-12 md:col-6"}>
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Bağkur No </div>
                      <div className="">{` : ${BagkurCalisaniResponse?.result?.sorguSonucDVO?.tescilKaydi4BList[0].bagNo}`}</div>
                    </div>
                  </div>
                  <div className={"col-12 md:col-6"}>
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Tescil Tarihi </div>
                      <div className="">{` : ${dateStringTransform(BagkurCalisaniResponse?.result?.sorguSonucDVO?.tescilKaydi4BList[0].tescilTarihi.toString())}`}</div>
                    </div>
                  </div>
                  <div className={"col-12 md:col-6"}>
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Meslek İlçe </div>
                      <div className="">{` : ${BagkurCalisaniResponse?.result?.sorguSonucDVO?.tescilKaydi4BList[0].meslekIlce}`}</div>
                    </div>
                  </div>
                  <div className={"col-12 md:col-6"}>
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Meslek İli </div>
                      <div className="">{` : ${BagkurCalisaniResponse?.result?.sorguSonucDVO?.tescilKaydi4BList[0].meslekiIi}`}</div>
                    </div>
                  </div>
                  <div className={"col-12 md:col-6"}>
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Terk Tarihi </div>
                      <div className="">{` : ${dateStringTransform(BagkurCalisaniResponse?.result?.sorguSonucDVO?.tescilKaydi4BList[0].terkTarihi.toString())}`}</div>
                    </div>
                  </div>
                  <div className={"col-12 md:col-6"}>
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Terk Açıklama </div>
                      <div className="">{` : ${BagkurCalisaniResponse?.result?.sorguSonucDVO?.tescilKaydi4BList[0].terkAciklama}`}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BagkurCalisani;
