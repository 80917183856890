import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DosyaDTO,
  SorguSonucTakbisDVO,
  TakbisResponseDTO
} from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import SorguBilgileri from "./SorguBilgileri";
import TakbisHisse from "./TakbisHisse";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const Takbis = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const [visibleTakbisHisseDialog, setVisibleTakbisHisseDialog] = useState(false);
  const [takbisData, setTakbisData] = useState<SorguSonucTakbisDVO>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<SorguSonucTakbisDVO[]>>(null);
  const {
    data: TakbisResponse,
    refetch: refetchTakbis,
    isLoading: takbisResponseLoading
  } = useQuery({
    queryKey: ["Takbis", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetTakbisBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(takbisResponseLoading);
  }, [takbisResponseLoading]);

  const ilIlceBodyTemplate = (rowData: { tasinmazDVO: { il: any; ilce: any } }) => {
    return `${rowData.tasinmazDVO.il} / ${rowData.tasinmazDVO.ilce}`;
  };

  const hisseBilgisiActionBodyTemplate = (rowData: SorguSonucTakbisDVO) => {
    return (
      <button onClick={() => handleButtonClick(rowData)} className="p-button p-component p-button-info">
        Hisse Bilgisi
      </button>
    );
  };

  const handleButtonClick = (rowData: SorguSonucTakbisDVO) => {
    setVisibleTakbisHisseDialog(true);
    setTakbisData(rowData);
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      kurum: commonFilterContains,
      ilIlce: commonFilterContains,
      mahalle: commonFilterContains,
      vasfi: commonFilterContains,
      yuzolcumu: commonFilterContains,
      mevki: commonFilterContains,
      ada: commonFilterContains,
      parsel: commonFilterContains,
      bolum: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const data = TakbisResponse?.result?.sorguSonucDVO?.map((item, index) => ({ ...item, key: index })) || [];

    const headers = [
      "Tapu Müdürlüğü",
      "İl",
      "İlçe",
      "Mahalle",
      "Vasfı",
      "Yüzölçümü",
      "Mevki",
      "Ada No",
      "Parsel No",
      ,
    ];

    if (data.length !== 0) {
      const filteredData = data.map(({ tasinmazDVO, yuzolcumu }) => ({
        kurum: tasinmazDVO?.kurum || "",
        il: tasinmazDVO?.il || "",
        ilce: tasinmazDVO?.ilce || "",
        mahalle: tasinmazDVO?.mahalle || "",
        nitelik: tasinmazDVO?.nitelik || "",
        yuzolcumu: yuzolcumu || "",
        mevkii: tasinmazDVO?.mevkii || "",
        ada: tasinmazDVO?.ada || "",
        parsel: tasinmazDVO?.parsel || ""
      }));

      const worksheetData = [headers, ...filteredData.map(Object.values)];

      ExportFile.SaveAsExcelFile(worksheetData, "Takbis");
    }
  };

  return (
    <div>
      {TakbisResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {TakbisResponse.error}</div>}
      {TakbisResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={TakbisResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={TakbisResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={TakbisResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={TakbisResponse?.result?.sorgulayanBilgileri}
          />

          {TakbisResponse?.result?.sorguSonucDVO?.length === 0 ||
          typeof TakbisResponse?.result?.sorguSonucDVO === "string" ? (
            <div className="card p-2 lg:p-5 mt-3">
              {TakbisResponse?.result?.sorguSonucDVO?.toString().trim() === ""
                ? "Takbis sonucu bulunamadı."
                : TakbisResponse?.result?.sorguSonucDVO?.toString()}
            </div>
          ) : (
            <div>
              <div className="overflow-x-auto mt-3">
                <DataTable
                  value={TakbisResponse?.result?.sorguSonucDVO}
                  ref={dt}
                  showGridlines
                  scrollable
                  loading={takbisResponseLoading}
                  //scrollHeight="250px"
                  //style={{ minHeight: "7rem" }}
                  //selectionMode={"single"}
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  filters={filters}
                  // filterDisplay="row"
                  globalFilterFields={[
                    "kurum",
                    "ilIlce",
                    "mahalle",
                    "vasfi",
                    "yuzolcumu",
                    "mevki",
                    "ada",
                    "parsel",
                    "bolum"
                  ]}
                  //header={header}
                  header={CreateHeader(
                    "Takbis",
                    globalFilterValue,
                    (e) => onGlobalFilterChange(e),
                    clearFilter,
                    exportCSV,
                    exportExcel
                  )}
                  columnResizeMode="expand"
                >
                  <Column
                    header="No"
                    body={(rowData, { rowIndex }) => rowIndex + 1}
                    className="w-2rem text-center"
                  ></Column>
                  <Column
                    field="tasinmazDVO.kurum"
                    header="Tapu Müdürlüğü"
                    sortable
                    filterField="kurum"
                    filter
                    filterPlaceholder="Tapu Müdürlüğü'ne göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Tapu Müdürlüğü'ne göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    body={ilIlceBodyTemplate}
                    header="İl / İlçe"
                    sortable
                    filterField="ilIlce"
                    filter
                    filterPlaceholder="İl / İlçe'ye göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">İl / İlçe'ye göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="tasinmazDVO.mahalle"
                    header="Mahalle"
                    sortable
                    filterField="mahalle"
                    filter
                    filterPlaceholder="Mahalle'ye göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Mahalle'ye göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="tasinmazDVO.nitelik"
                    header="Vasfı"
                    sortable
                    filterField="vasfi"
                    filter
                    filterPlaceholder="Vasfa göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Vasfa göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="yuzolcumu"
                    header="Yüzölçümü"
                    sortable
                    filterField="yuzolcumu"
                    filter
                    filterPlaceholder="Yüzölçümüne göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Yüzölçümüne göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="tasinmazDVO.mevkii"
                    header="Mevki"
                    sortable
                    filterField="mevki"
                    filter
                    filterPlaceholder="Mevkiye göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Mevkiye göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="tasinmazDVO.ada"
                    header="Ada No"
                    sortable
                    filterField="ada"
                    filter
                    filterPlaceholder="Ada No'ya göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Ada No'ya göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="tasinmazDVO.parsel"
                    header="Parsel No"
                    sortable
                    filterField="parsel"
                    filter
                    filterPlaceholder="Parsel No'ya göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Parsel No'ya göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field=""
                    header="Bağımsız Bölüm"
                    sortable
                    body={(rowData) => (
                      <>
                        Kat: {rowData?.tasinmazDVO?.bagimsizBolum?.kat ?? ""}, No:{" "}
                        {rowData?.tasinmazDVO?.bagimsizBolum?.no ?? ""}, Arsa Pay:{" "}
                        {rowData?.tasinmazDVO?.bagimsizBolum?.arsaPay ?? ""}, Arsa Payda:{" "}
                        {rowData?.tasinmazDVO?.bagimsizBolum?.arsaPayda ?? ""}, Kat Mülkiyeti:{" "}
                        {rowData?.tasinmazDVO?.bagimsizBolum?.bagimsizBolumTip ?? ""}
                      </>
                    )}
                    filterField="bolum"
                    filter
                    filterPlaceholder="Bağımsız Bölüm'e göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Bağımsız Bölüm'e göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column body={hisseBilgisiActionBodyTemplate} header="Hisse Bilgisi"></Column>
                </DataTable>
              </div>

              {visibleTakbisHisseDialog && (
                <>
                  <TakbisHisse
                    visible={visibleTakbisHisseDialog}
                    setVisible={setVisibleTakbisHisseDialog}
                    dosyaId={props.dosyaId}
                    kisiKurumId={props.kisiKurumId}
                    takbisData={takbisData}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Takbis;
