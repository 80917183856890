import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguTakbisTopluSorguModel } from "../TopluSorgu.dto";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";
import moment from "moment";

const TakbisDataTable = (props: { tableData: TopluSorguTakbisTopluSorguModel[] }) => {
  const takbisVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguTakbisTopluSorguModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      tapuMudurlugu: commonFilterContains,
      ilIlce: commonFilterContains,
      mahalle: commonFilterContains,
      vasfi: commonFilterContains,
      yuzolcumu: commonFilterContains,
      mevkii: commonFilterContains,
      adaNo: commonFilterContains,
      parselNo: commonFilterContains,
      hisseAciklama: commonFilterContains,
      hisseTip: commonFilterContains,
      tapuBolumDurum: commonFilterContains,
      tasinmazId: commonFilterContains,
      hisseId: commonFilterContains,
      sonHacizTarihi: commonFilterDateIs,
      serhSayisi: commonFilterContains,
      beyanSayisi: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Tapu Müdürlüğü",
      "İl / İlçe",
      "Mahalle",
      "Vasfı",
      "Yüzölçümü",
      "Mevki",
      "Ada No",
      "Parsel No",
      "Hisse Açıklama",
      "Hisse Tipi",
      "Durum",
      "Taşınmaz Id",
      "Hisse Id",
      "Son Haciz Tarihi",
      "Serh Sayısı",
      "Beyan Sayısı"
    ];

    const filteredData = takbisVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      tapuMudurlugu: data.tapuMudurlugu,
      ilIlce: data.ilIlce,
      mahalle: data.mahalle,
      vasfi: data.vasfi,
      yuzolcumu: data.yuzolcumu,
      mevkii: data.mevkii,
      adaNo: data.adaNo,
      parselNo: data.parselNo,
      hisseAciklama: data.hisseAciklama,
      hisseTip: data.hisseTip,
      tapuBolumDurum: data.tapuBolumDurum,
      tasinmazId: data.tasinmazId,
      hisseId: data.hisseId,
      sonHacizTarihi:
        data.sonHacizTarihi && moment(data.sonHacizTarihi).isValid()
          ? moment(data.sonHacizTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
      serhSayisi: data.serhSayisi,
      beyanSayisi: data.beyanSayisi
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluSorguTakbis");
  };

  return (
    <div>
      <DataTable
        value={takbisVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="key"
        showGridlines
        emptyMessage="Takbis Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Takbis Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "tapuMudurlugu",
          "ilIlce",
          "mahalle",
          "vasfi",
          "yuzolcumu",
          "mevkii",
          "adaNo",
          "parselNo",
          "hisseAciklama",
          "hisseTip",
          "tapuBolumDurum",
          "tasinmazId",
          "hisseId",
          "sonHacizTarihi",
          "serhSayisi",
          "beyanSayisi"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filterField="ortakDegerler.dosyaNo"
          filter
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filterField="ortakDegerler.alacakliAdi"
          filter
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filterField="ortakDegerler.borcluAdi"
          filter
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filterField="ortakDegerler.borcluTCVKNo"
          filter
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filterField="ortakDegerler.dosyaDurumu"
          filter
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          field="tapuMudurlugu"
          header="Tapu Müdürlüğü"
          sortable
          filter
          filterField="tapuMudurlugu"
          filterPlaceholder="Tapu Müdürlüğüne göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tapu Müdürlüğüne göre filtreleyin</div>}
        />
        <Column
          field="ilIlce"
          header="İl / İlçe"
          sortable
          filter
          filterField="ilIlce"
          filterPlaceholder="İl / İlçeye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İl / İlçeye göre filtreleyin</div>}
        />
        <Column
          field="mahalle"
          header="Mahalle"
          sortable
          filter
          filterField="mahalle"
          filterPlaceholder="Mahalleye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Mahalleye göre filtreleyin</div>}
        />
        <Column
          field="vasfi"
          header="Vasfı"
          sortable
          filter
          filterField="vasfi"
          filterPlaceholder="Vasfına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Vasfına göre filtreleyin</div>}
        />
        <Column
          field="yuzolcumu"
          header="Yüzölçümü"
          sortable
          filter
          filterField="yuzolcumu"
          filterPlaceholder="Yüzölçümüne göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Yüzölçümüne göre filtreleyin</div>}
        />
        <Column
          field="mevkii"
          header="Mevki"
          sortable
          filter
          filterField="mevkii"
          filterPlaceholder="Mevkiye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Mevkiye göre filtreleyin</div>}
        />
        <Column
          field="adaNo"
          header="Ada No"
          sortable
          filter
          filterField="adaNo"
          filterPlaceholder="Ada Numarasına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Ada Numarasına göre filtreleyin</div>}
        />
        <Column
          field="parselNo"
          header="Parsel No"
          sortable
          filter
          filterField="parselNo"
          filterPlaceholder="Parsel Numarasına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Parsel Numarasına göre filtreleyin</div>}
        />
        <Column
          field="hisseAciklama"
          header="Hisse Açıklama"
          sortable
          filter
          filterField="hisseAciklama"
          filterPlaceholder="Hisse Açıklamasına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Hisse Açıklamasına göre filtreleyin</div>}
        />
        <Column
          field="hisseTip"
          header="Hisse Tipi"
          sortable
          filter
          filterField="hisseTip"
          filterPlaceholder="Hisse Tipine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Hisse Tipine göre filtreleyin</div>}
        />
        <Column
          field="tapuBolumDurum"
          header="Durum"
          sortable
          filter
          filterField="tapuBolumDurum"
          filterPlaceholder="Duruma göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>}
        />
        <Column
          field="tasinmazId"
          header="Taşınmaz Id"
          sortable
          filter
          filterField="tasinmazId"
          filterPlaceholder="Taşınmaz Id'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Taşınmaz Id'ye göre filtreleyin</div>}
        />
        <Column
          field="hisseId"
          header="Hisse Id"
          sortable
          filter
          filterField="hisseId"
          filterPlaceholder="Hisse Id'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Hisse Id'ye göre filtreleyin</div>}
        />
        <Column
          field="sonHacizTarihi"
          header="Son Haciz Tarihi"
          sortable
          filter
          body={(rowData) =>
            rowData.sonHacizTarihi && moment(rowData.sonHacizTarihi).isValid() ? (
              moment(rowData.sonHacizTarihi).format(process.env.REACT_APP_DATE_FORMAT)
            ) : (
              <></>
            )
          }
          filterField="sonHacizTarihi"
          filterPlaceholder="Son Haciz Tarihine göre arayın"
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Son Haciz Tarihine göre filtreleyin</div>}
        />
        <Column
          field="serhSayisi"
          header="Serh Sayısı"
          sortable
          filter
          filterField="serhSayisi"
          filterPlaceholder="Serh Sayısına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Serh Sayısına göre filtreleyin</div>}
        />
        <Column
          field="beyanSayisi"
          header="Beyan Sayısı"
          sortable
          filter
          filterField="beyanSayisi"
          filterPlaceholder="Beyan Sayısına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Beyan Sayısına göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default TakbisDataTable;
