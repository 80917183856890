import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import AccountService from "../../services/account/account.service";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import UpsertSubUsersPage from "./UpsertSubUsersPage";
import { UserDto } from "../../services/account/account.dto";
import { commonFilterContains, CreateHeader, Filter } from "../filter/base-filter";
import { ExportFile } from "../../utils/export-file";
import { LayoutContext } from "../../layout/context/layoutcontext";

const SubUsersComponent = (props: {
  //   onHide?: () => void;
  //   onOk?: (users: UserDto[]) => void;
  isAdminPanel: boolean;
  users?: UserDto[];
  refetchSubUser?: () => void;
  id?: string;
}) => {
  const [subUser, setSubUser] = useState<UserDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<UserDto[]>>(null);
  const [visibleUpsertSubUserDialog, setVisibleUpsertSubUserDialog] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext);

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Alt Kullanıcı"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertSubUserDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: UserDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setSubUser(rowData);
            setVisibleUpsertSubUserDialog(true);
          }}
        />
        {!props.isAdminPanel && (
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            tooltip="Sil"
            severity="danger"
            className="mr-2"
            onClick={() => {
              deleteSubUserConfirm(rowData);
            }}
          />
        )}
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      userName: commonFilterContains,
      email: commonFilterContains,
      phoneNumber: commonFilterContains,
      firstName: commonFilterContains,
      lastName: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const deleteSubUser = async (rowData: UserDto) => {
    setBlocked(true);
    const res = await AccountService.DeleteSubUser(rowData.id!);
    if (res.result && !res.result?.error) {
      toast.show("Alt kullanıcı başarıyla silindi.", "success");
      if (props.refetchSubUser) {
        props.refetchSubUser();
      }
    }
    setBlocked(false);
  };

  const deleteSubUserConfirm = (rowData: UserDto) => {
    confirmDialog({
      message: "Alt kullanıcıyı silmek istediğinize emin misiniz?",
      header: "Alt Kullanıcı Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteSubUser(rowData),
      reject: () => {},
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const exportExcel = () => {
    const data = props.users || [];

    const filteredData = data.map((data) => ({
      userName: data.userName,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber
    }));

    const headers = ["Kullanıcı Adı", "Eposta", "Ad", "Soyad", "Telefon Numarası"];

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "AltKullanıcılar");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={props.users || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} alt kullanıcı"
          filters={filters}
          // filterDisplay="row"
          //loading={subUsersResponseIsLoading}
          globalFilterFields={["userName", "email", "phoneNumber", "firstName", "lastName"]}
          emptyMessage="Alt kullanıcınız bulunmamaktadır."
          header={CreateHeader(
            props.isAdminPanel ? "Alt Kullanıcılar" : "Alt Kullanıcılarım",
            globalFilterValue,
            (e) => onGlobalFilterChange(e),
            clearFilter
          )}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9em" }}></Column>
          <Column
            field="userName"
            header="Kullanıcı Adı"
            sortable
            filterField="userName"
            filter
            filterPlaceholder="Kullanıcı Adı'a göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Kullanıcı Adı'a göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="email"
            header="Eposta"
            sortable
            filterField="email"
            filter
            filterPlaceholder="Epostaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Epostaya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="firstName"
            header="Ad"
            sortable
            filterField="firstName"
            filter
            filterPlaceholder="Ada göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Ada göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="lastName"
            header="Soyad"
            sortable
            filterField="lastName"
            filter
            filterPlaceholder="Soyada göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Soyada göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="phoneNumber"
            header="Telefon Numarası"
            sortable
            filterField="phoneNumber"
            filter
            filterPlaceholder="Telefon Numarasına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Telefon Numarasına göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertSubUsersPage
        visible={visibleUpsertSubUserDialog}
        setVisible={setVisibleUpsertSubUserDialog}
        subUser={subUser}
        setSubUser={setSubUser}
        refetchSubUsers={props.refetchSubUser}
        isAdminPanel={props.isAdminPanel}
        id={props.id}
      />
    </div>
  );
};

export default SubUsersComponent;
