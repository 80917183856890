import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useHub } from "../../../utils/buro-hub";
import uyapService from "../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import { ExportFile } from "../../../utils/export-file";
import { SafahatlarDTO } from "../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { LayoutContext } from "../../../layout/context/layoutcontext";

const Safahat = (props: { dosyaId: string }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<SafahatlarDTO[]>>(null);

  const {
    data: SafahatResponse,
    refetch: refetchSafahat,
    isLoading: safahatResponseLoading
  } = useQuery({
    queryKey: ["Safahat", props.dosyaId],
    gcTime: 1000 * 60 * 11,
    refetchOnMount: false,
    queryFn: async () => await hub.executeUyapService(() => uyapService.GetSafahat(props.dosyaId))
  });

  useEffect(() => {
    setBlocked(safahatResponseLoading)
  }, [safahatResponseLoading])

  const processedData =
    SafahatResponse?.result?.safahatlar.map((item, index) => ({
      ...item,
      key: index,
      safahatTarihiDate: moment(item.safahatTarihi, "MMM DD, YYYY h:mm:ss A").toDate()
    })) || [];

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      islemYapanBirim: commonFilterContains,
      safahatTuruAciklama: commonFilterContains,
      safahatTarihiDate: commonFilterDateIs,
      aciklama: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
    // const header = [
    //     "İşlem Yapan Birim",
    //     "Safahat Türü Açıklama",
    //     "Safahat Tarihi",
    //     "Safahat Açıklama"
    // ];

    // const csvData = [
    //     ...processedData.map(({ islemYapanBirim, safahatTuruAciklama, safahatTarihiDate, aciklama }) => [
    //         islemYapanBirim, safahatTuruAciklama, safahatTarihiDate, aciklama
    //     ]),
    // ];

    // const csvContent = [
    //     header.join(","),
    //     ...csvData.map((e: any[]) => e.join(","))
    // ].join("\n");

    // ExportFile.SaveAsCSVFile(csvContent, "Safahatlar");
  };

  const exportExcel = () => {
    const data = processedData || [];

    const headers = ["İşlem Yapan Birim", "Safahat Türü Açıklama", "Safahat Tarihi", "Safahat Açıklama"];

    const filteredData = data.map(({ islemYapanBirim, safahatTuruAciklama, safahatTarihiDate, aciklama }) => ({
      islemYapanBirim,
      safahatTuruAciklama,
      safahatTarihiDate,
      aciklama
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "Safahatlar");
  };

  return (
    <div>
      {SafahatResponse?.error && <div className="card">Hata : {SafahatResponse.error}</div>}
      {SafahatResponse?.result && (
        <div>
          <DataTable
            value={processedData || []}
            ref={dt}
            dataKey="id"
            showGridlines
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            emptyMessage="Safahat Bilgisi bulunamadı."
            loading={safahatResponseLoading}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            filters={filters}
            globalFilterFields={["islemYapanBirim", "safahatTuruAciklama", "safahatTarihiDate", "aciklama"]}
            header={CreateHeader(
              "Safahatlar",
              globalFilterValue,
              (e) => onGlobalFilterChange(e),
              clearFilter,
              exportCSV,
              exportExcel
            )}
            columnResizeMode="expand"
          >
            <Column
              field="islemYapanBirim"
              header="İşlem Yapan Birim"
              sortable
              filterField="islemYapanBirim"
              filter
              filterPlaceholder="Birim'e göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Birim'e göre filtreleyin</div>;
              }}
            />
            <Column
              field="safahatTuruAciklama"
              header="Safahat Türü Açıklama"
              sortable
              filterField="safahatTuruAciklama"
              filter
              filterPlaceholder="Safahat Türü Açıklamasına göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Safahat Türü Açıklamasına göre filtreleyin</div>;
              }}
            />
            <Column
              field="safahatTarihiDate"
              header="Safahat Tarihi"
              sortable
              body={(rowData) => (
                <span>{rowData.safahatTarihiDate ? moment(rowData.safahatTarihiDate).format(process.env.REACT_APP_DATE_FORMAT) : ""}</span>
              )}
              filterField="safahatTarihiDate"
              //filterClear={Filter.FilterClearTemplate}
              //filterApply={Filter.FilterApplyTemplate}
              filter
              filterPlaceholder="Safahat Tarihine göre arayın"
              filterElement={Filter.DateFilterTemplate}
              filterMatchMode={FilterMatchMode.DATE_IS}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Safahat Tarihine göre filtreleyin</div>;
              }}
            />
            <Column
              field="aciklama"
              header="Safahat Açıklama"
              sortable
              filterField="aciklama"
              filter
              filterPlaceholder="Safahat Açıklamasına göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Safahat Açıklamasına göre filtreleyin</div>;
              }}
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default Safahat;
