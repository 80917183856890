import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import DosyaHesabi from "./components/DosyaHesabi";
import { TabPanel, TabView } from "primereact/tabview";
import DosyaBilgileri from "./components/DosyaBilgileri";
import TarafBilgileri from "./components/TarafBilgileri";
import Evrak from "./components/Evrak";
import Safahat from "./components/Safahat";
import Odeme from "./components/Odeme";
import EvrakGonderme from "./components/EvrakGonderme";
import TahRedBilgileri from "./components/TahRedBilgileri";
import BorcluBilgileri from "./components/BorcluBilgileriComponents";
import { useQuery } from "@tanstack/react-query";
import uyapService from "../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useHub } from "../../utils/buro-hub";
import { DosyaDTO } from "../../services/uyap-sorgulari/uyap-sorgulari.dto";
import TahRedBilgileriIdari from "./components/TahRedBilgileriIdari";
import { LayoutContext } from "../../layout/context/layoutcontext";

const DosyaDetaylariDialog = (props: {
  visible: boolean;
  dosyaId: string;
  dosyaNo: string;
  dosyaTur: string;
  birimAdi: string;
  dosyaTurKod: number;
  handleSetVisibleDosyaDetayleriDialog: (visible: boolean) => void;
}) => {
  const { setBlocked } = useContext(LayoutContext);
  const hub = useHub();
  const [sorguSonucu, setSorguSonucu] = useState<string>("");
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const allTabs: any[] = [
    {
      title: "Dosya Bilgileri",
      name: "ayrinti_bilgileri",
      content: <DosyaBilgileri dosyaId={props.dosyaId} dosyaTurKod={props.dosyaTurKod || 0} />,
      isActive: true
    },
    {
      title: "Dosya Hesabı",
      name: "hesap_bilgileri",
      content: <DosyaHesabi dosyaId={props.dosyaId} />,
      isActive: true
    },
    {
      title: "Taraf Bilgileri",
      name: "taraf_bilgileri",
      content: <TarafBilgileri dosyaId={props.dosyaId} />,
      isActive: true
    },
    {
      title: "Evrak",
      name: "evrak_bilgileri",
      content: (
        <Evrak dosyaId={props.dosyaId} dosyaNo={props?.dosyaNo} birimAdi={props?.birimAdi} dosyaTur={props?.dosyaTur} />
      ),
      isActive: true
    },
    {
      title: "Safahat",
      name: "safahat_bilgileri",
      content: <Safahat dosyaId={props.dosyaId} />,
      isActive: true
    },
    {
      title: "Evrak Gönderme",
      name: "evrak_gonderme",
      content: <EvrakGonderme />,
      isActive: false
    },
    {
      title: "Ödeme",
      name: "odeme",
      content: <Odeme />,
      isActive: false
    },
    {
      title: "Tah./Red. Bilgileri",
      name: "tahsilat_reddiyat_bilgileri",
      content:
        props.dosyaTurKod === 35 || props.dosyaTurKod === 1 ? (
          <TahRedBilgileri dosyaId={props.dosyaId} />
        ) : (
          <TahRedBilgileriIdari dosyaId={props.dosyaId} />
        ),
      isActive: true
    },
    {
      title: "Borçlu Bilgileri",
      name: "borclu_bilgileri",
      content: <BorcluBilgileri dosyaId={props.dosyaId} />,
      isActive: true
    }
  ];

  const {
    data: DosyaSorguTipleriResponse,
    refetch: refetchDosyaSorguTipleri,
    isLoading: dosyaSorguTipleriResponseLoading
  } = useQuery({
    queryKey: ["DosyaSorguTipleri", props.dosyaTurKod],
    refetchOnMount: false,
    queryFn: async () => await hub.executeUyapService(() => uyapService.GetDosyaIslemTurleri(props.dosyaId))
  });

  useEffect(() => {
    setBlocked(dosyaSorguTipleriResponseLoading);
  }, [dosyaSorguTipleriResponseLoading]);

  delay(500);

  useEffect(() => {
    if (DosyaSorguTipleriResponse && DosyaSorguTipleriResponse.result) {
      const b = Object.keys(DosyaSorguTipleriResponse.result).map((key) => {
        if (DosyaSorguTipleriResponse.result) {
          return DosyaSorguTipleriResponse.result[key];
        }
      });
      if (b[0]) {
        setSorguSonucu(b[0]);
      }
    }
  }, [DosyaSorguTipleriResponse]);

  return (
    <Dialog
      header={props.dosyaNo + " " + props.birimAdi + " - " + props.dosyaTur}
      visible={props.visible}
      maximizable
      style={{ width: "70%", height: "70%" }}
      onHide={() => {
        if (!props.visible) return;
        props.handleSetVisibleDosyaDetayleriDialog(false);
      }}
      modal={false}
    >
      <div className="card p-1 lg:p-5">
        <TabView className="mt-3" scrollable>
          {allTabs
            .filter((tab) => tab.isActive === true && sorguSonucu.includes(tab.name))
            .map((tab: any) => {
              return (
                <TabPanel key={tab.title} header={tab.title}>
                  {tab.content}
                </TabPanel>
              );
            })}
        </TabView>
      </div>
    </Dialog>
  );
};

export default DosyaDetaylariDialog;
