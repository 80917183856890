import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  KarsiYanDto,
  CreateKarsiYanDto,
  UpdateKarsiYanDto,
} from "./karsi-yan.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<KarsiYanDto>(`KarsiYan/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<KarsiYanDto[]>(`KarsiYan`, config);
  },
  Create(dto: CreateKarsiYanDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<KarsiYanDto>("KarsiYan", dto, config);
  },
  Update(dto: UpdateKarsiYanDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<KarsiYanDto>("KarsiYan", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<KarsiYanDto>(`KarsiYan/${id}`, config);
  },
};

export default services;
