import { TalepDurumu } from "../services/talep-bilgisi/talep-bilgisi.dto";
import { TebligatDurumu } from "../services/tebligat-bilgisi/tebligat-bilgisi.dto";

const DropdownDatas = {
  GetMuvekkilType() {
    return [
      { label: "Gerçek", value: 1 },
      { label: "Tüzel", value: 2 },
    ];
  },

  GetAddressType() {
    return [
      { label: "Ev", value: "Ev" },
      { label: "İş Yeri", value: "İş Yeri" },
      { label: "Okul", value: "Okul" },
      { label: "Cezaevi", value: "Cezaevi" },
      { label: "Diğer", value: "Diğer" },
    ];
  },

  GetTelefonSahibi() {
    return [
      { label: "Borçlu", value: "Borclu" },
      { label: "İş Yeri", value: "IsYeri" },
      { label: "Aile", value: "Aile" },
      { label: "Eş", value: "Es" },
    ];
  },

  GetBorcluTypes() {
    return [
      { label: "Asıl", value: "Asıl" },
      { label: "Ek Kart", value: "Ek Kart" },
      { label: "Kefil", value: "Kefil" },
      { label: "Mirasçı", value: "Mirasçı" },
      { label: "İş Yeri", value: "İş Yeri" },
      { label: "Vasi", value: "Vasi" },
      { label: "Veli", value: "Veli" },
      { label: "Diğer", value: "Diğer" },
    ];
  },

  GetMuvekkilTypes() {
    return [{ label: "Alacaklı", value: "Alacaklı" }];
  },

  GetTakipTuru() {
    return [
      { label: "İlamlı Takip", value: "IlamliTakip" },
      { label: "İlamsız Takip", value: "IlamsizTakip" },
    ];
  },

  GetCinsiyet() {
    return [
      { label: "Erkek", value: "Erkek" },
      { label: "Kadın", value: "Kadin" },
    ];
  },

  GetTakipYolu(takipTuru: string) {
    if (takipTuru === "IlamsizTakip") {
      return [
        { label: "Genel Haciz Yoluyla Takip", value: "GenelHacizYoluylaTakip" },
      ];
    } else {
      return [];
    }
  },

  GetTakipSekli(takipYolu: string) {
    if (takipYolu === "GenelHacizYoluylaTakip") {
      return [
        {
          label: "ÖRNEK: 7 İlamsız Takiplerde Ödeme Emri - Eski No: 49",
          value: "ÖRNEK: 7",
        },
      ];
    } else {
      return [];
    }
  },

  GetTakipMahiyeti() {
    return [
      { label: "Belgesiz", value: "Belgesiz" },
      { label: "Çocuk Teslimi", value: "CocukTeslimi" },
      { label: "Doğalgaz", value: "Dogalgaz" },
      { label: "Elektrik", value: "Elektrik" },
      { label: "İnternet/TV", value: "Internet/TV" },
      { label: "Kredi Kartı", value: "KrediKarti" },
      { label: "Kredi Sözleşmesi", value: "KrediSozlesmesi" },
      { label: "Nafaka", value: "Nafaka" },
      { label: "Su", value: "Su" },
      { label: "Telefon (Cep)", value: "Telefon(Cep)" },
      { label: "Telefon (Sabit)", value: "Telefon(Sabit)" },
      { label: "Tüketici Hakem Heyeti", value: "TuketiciHakemHeyeti" },
      { label: "Diğer", value: "Diger" },
    ];
  },

  GetAciklama9() {
    return [{ label: "Haciz Yolu", value: "HacizYolu" }];
  },

  GetIcraMudurlugu() {
    return [
      {
        label: "İzmir Banka Alacakları İcra Dairesi",
        value: "İzmir Banka Alacakları İcra Dairesi",
      },
      { label: "İzmir 27. İcra Dairesi", value: "İzmir 27. İcra Dairesi" },
    ];
  },

  GetTalepDurumu() {
    return (Object.keys(TalepDurumu) as Array<keyof typeof TalepDurumu>)
      .map((key) => {
        return {
          label: this.GetTalepDurumuString(TalepDurumu[key]),
          value: TalepDurumu[key],
        };
      })
      .filter((x) => x.label);
  },

  GetTalepDurumuString(talepdurumu: TalepDurumu) {
    switch (talepdurumu) {
      case TalepDurumu.Yazildi:
        return "Yazıldı";
      case TalepDurumu.Imzalandi:
        return "İmzalandı";
      case TalepDurumu.Sorguda:
        return "Sorguda";
      case TalepDurumu.Kabul:
        return "Kabul";
      case TalepDurumu.RedUyarili:
        return "Ret (*)";
      case TalepDurumu.RedUyarisiz:
        return "Ret";
      default:
        return "";
    }
  },

  GetTalepTuru() {
    return [
      {
        label: "Birinci Haciz İhbarnamesi",
        value: "Birinci Haciz İhbarnamesi",
      },
      {
        label: "Maaş Haczi Tekit Talebi",
        value: "Maaş Haczi Tekit Talebi",
      },
      {
        label: "SGK Müzakeresi",
        value: "SGK Müzakeresi",
      },
    ];
  },

  GetTarihceKullaniciNotuTuru() {
    return [
      {
        label: "Kullanıcı Notu",
        value: "Kullanıcı Notu",
      },
      { label: "SMS Bilgisi", value: "SMS Bilgisi" },
    ];
  },

  GetTebligatTuru() {
    return [
      {
        label: "Birinci Haciz İhbarnamesi",
        value: "Birinci Haciz İhbarnamesi",
      },
      { label: "Maaş Haczi", value: "Maaş Haczi" },
      { label: "SGK Müzakkere", value: "SGK Müzakkere" },
    ];
  },

  GetBelgeTipi() {
    return [
      {
        label: "Vekaletname",
        value: "Vekaletname",
      },
      { label: "Diğer", value: "Diğer" },
    ];
  },

  GetTebligatMuhattap() {
    return [
      {
        label: "Türkiye İş Bankası",
        value: "Türkiye İş Bankası",
      },
      { label: "Türkiye Halk Bankası", value: "Türkiye Halk Bankası" },
      { label: "Yapı ve Kredi Bankası", value: "Yapı ve Kredi Bankası" },
    ];
  },

  GetTebligatDurumuString(tebligatDurumu: TebligatDurumu) {
    switch (tebligatDurumu) {
      case TebligatDurumu.Yazildi:
        return "Yazıldı";
      case TebligatDurumu.MasrafVerildi:
        return "Masraf Verildi";
      case TebligatDurumu.Gonderildi:
        return "Gönderildi";
      case TebligatDurumu.Teblig:
        return "Teblig";
      case TebligatDurumu.BilaUyarili:
        return "Bila(*)";
      case TebligatDurumu.BilaUyarisiz:
        return "Bila";
      default:
        return "";
    }
  },

  GetTebligatDurumu() {
    return (Object.keys(TebligatDurumu) as Array<keyof typeof TebligatDurumu>)
      .map((key) => {
        return {
          label: this.GetTebligatDurumuString(TebligatDurumu[key]),
          value: TebligatDurumu[key],
        };
      })
      .filter((x) => x.label);
  },

  GetTebligatSerhi() {
    return [
      {
        label: "EvrakMemuru",
        value: "EvrakMemuru",
      },
      { label: "Memur İhbarname", value: "Memur İhbarname" },
      { label: "Yeşim Açar", value: "Yeşim Açar" },
    ];
  },

  GetAlacakTuru() {
    return [
      {
        label: "Asıl Alacak",
        value: "Asıl Alacak",
      },
      { label: "İşlemiş Faiz", value: "İşlemiş Faiz" },
      { label: "Masraf Alacağı", value: "Masraf Alacağı" },
    ];
  },

  GetMasrafTur() {
    return [
      {
        label: "Masraf",
        value: "Masraf",
      },
    ];
  },

  GetTahsilatTur() {
    return [
      {
        label: "Haricen",
        value: "Haricen",
      },
    ];
  },

  getBaro() {
    return [
      "*BELİRTMEK İSTEMİYORUM",
      "*BİLİRKİŞİ",
      "*AVUKAT/BİLİRKİŞİ DEĞİLİM",
      "ADANA",
      "ADIYAMAN",
      "AFYONKARAHİSAR",
      "AĞRI",
      "AKSARAY",
      "AMASYA",
      "ANKARA 1 NOLU",
      "ANKARA 2 NOLU",
      "ANTALYA",
      "ARDAHAN",
      "ARTVİN",
      "AYDIN",
      "BALIKESİR",
      "BARTIN",
      "BATMAN",
      "BAYBURT",
      "BİLECİK",
      "BİNGÖL",
      "BİTLİS",
      "BOLU",
      "BURDUR",
      "BURSA",
      "ÇANAKKALE",
      "ÇANKIRI",
      "ÇORUM",
      "DENİZLİ",
      "DİYARBAKIR",
      "DÜZCE",
      "EDİRNE",
      "ELAZIĞ",
      "ERZİNCAN",
      "ERZURUM",
      "ESKİŞEHİR",
      "GAZİANTEP",
      "GİRESUN",
      "GÜMÜŞHANE",
      "HAKKARİ",
      "HATAY",
      "IĞDIR",
      "ISPARTA",
      "İSTANBUL 1 NOLU",
      "İSTANBUL 2 NOLU",
      "İZMİR",
      "KAHRAMANMARAŞ",
      "KARABÜK",
      "KARAMAN",
      "KARS",
      "KASTAMONU",
      "KAYSERİ",
      "KIRIKKALE",
      "KIRKLARELİ",
      "KIRŞEHİR",
      "KİLİS",
      "KOCAELİ",
      "KONYA",
      "KÜTAHYA",
      "MALATYA",
      "MANİSA",
      "MARDİN",
      "MERSİN",
      "MUĞLA",
      "MUŞ",
      "NEVŞEHİR",
      "NİĞDE",
      "ORDU",
      "OSMANİYE",
      "RİZE",
      "SAKARYA",
      "SAMSUN",
      "SİİRT",
      "SİNOP",
      "SİVAS",
      "ŞANLIURFA",
      "ŞIRNAK",
      "TEKİRDAĞ",
      "TOKAT",
      "TRABZON",
      "TUNCELİ",
      "UŞAK",
      "VAN",
      "YALOVA",
      "YOZGAT",
      "ZONGULDAK",
    ].map((x) => {
      return { label: x, value: x };
    });
  },

  GetKampanyaMektubuDurum() {
    return [
      { label: "Yazıldı", value: "Yazıldı" },
      { label: "Gönderildi", value: "Gönderildi" },
      { label: "İade", value: "İade" },
    ];
  },

  GetUrunTipi() {
    return [
      { label: "Kredi Kartı", value: "Kredi Kartı" },
      { label: "Taksitli Kredi", value: "Taksitli Kredi" },
      { label: "KMH", value: "KMH" },
      { label: "Çek Taahhüdü", value: "Çek Taahhüdü" },
      { label: "Sigorta", value: "Sigorta" },
      { label: "İhtarname", value: "İhtarname" },
      { label: "Diğer", value: "Diğer" },
    ];
  },

  GetYargıTurleri() {
    return [
      { label: "Ceza", value: "0" },
      { label: "Hukuk", value: "1" },
      { label: "İcra", value: "2" },
      { label: "İdari Yargı", value: "6" },
      { label: "Satış Memurluğu", value: "11" },
      { label: "Arabuluculuk", value: "25" },
      { label: "Cbs", value: "3" },
    ];
  },
};
export default DropdownDatas;
