import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { TahsilatReddiyatResponseDTO } from "../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { Fieldset } from "primereact/fieldset";
import { useHub } from "../../../utils/buro-hub";
import uyapService from "../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { commonFilterContains, commonFilterDateIs, Filter, CreateHeader } from "../../filter/base-filter";
import { Calendar } from "primereact/calendar";
import { ExportFile } from "../../../utils/export-file";
import { LayoutContext } from "../../../layout/context/layoutcontext";

const currencyFormat = (value: number) => {
  if (value == null) {
    return "";
  }
  return value.toLocaleString("tr-TR", { style: "currency", currency: "TRY" });
};

const TahRedBilgileri = (props: { dosyaId: string }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: TahRedResponse,
    refetch: refetchTahRed,
    isLoading: TahRedResponseLoading
  } = useQuery({
    queryKey: ["TahRed", props.dosyaId],
    gcTime: 1000 * 60 * 5,
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetDosyaTahsilatReddiyatBilgileri(props.dosyaId, 0))
  });

  const [filtersTahsilatlar, setFiltersTahsilatlar] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [filtersReddiyatlar, setFiltersReddiyatlar] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [filtersHarclar, setFiltersHarclar] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const [globalFilterValueTahsilatlar, setGlobalFilterValueTahsilatlar] = useState<string>("");
  const [globalFilterValueReddiyatlar, setGlobalFilterValueReddiyatlar] = useState<string>("");
  const [globalFilterValueHarclar, setGlobalFilterValueHarclar] = useState<string>("");

  useEffect(() => {
    setBlocked(TahRedResponseLoading)
  }, [TahRedResponseLoading])

  const clearFilterTahsilatlar = () => {
    initFiltersTahsilatlar();
  };

  const clearFilterReddiyatlar = () => {
    initFiltersReddiyatlar();
  };

  const clearFilterHarclar = () => {
    initFiltersHarclar();
  };

  const initFiltersTahsilatlar = () => {
    setFiltersTahsilatlar({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dosyaNo: commonFilterContains,
      dosyaTurAciklama: commonFilterContains,
      birimAdi: commonFilterContains,
      tahsilatTuru: commonFilterContains,
      tahsilatTarihiFormatted: commonFilterDateIs,
      odeyenKisi: commonFilterContains,
      makbuzNo: commonFilterContains,
      tahsilatTutari: commonFilterContains,
      kalanMiktar: commonFilterContains
    });
    setGlobalFilterValueTahsilatlar("");
  };

  const initFiltersReddiyatlar = () => {
    setFiltersReddiyatlar({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      birimAdi: commonFilterContains,
      dosyaNo: commonFilterContains,
      makbuzNo: commonFilterContains,
      reddiyatTarihiFormatted: commonFilterDateIs,
      reddiyatNedeni: commonFilterContains,
      odeyenKisi: commonFilterContains,
      miktar: commonFilterContains,
      odenecekMiktar: commonFilterContains,
      gelirVergisi: commonFilterContains,
      damgaVergisi: commonFilterContains,
      tahsilHarci: commonFilterContains,
      cezaeviHarcTutari: commonFilterContains
    });
    setGlobalFilterValueReddiyatlar("");
  };

  const initFiltersHarclar = () => {
    setFiltersHarclar({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dosyaNo: commonFilterContains,
      dosyaTurAciklama: commonFilterContains,
      birimAdi: commonFilterContains,
      tahsilatTuru: commonFilterContains,
      tahsilatTarihiFormatted: commonFilterDateIs,
      odeyenKisi: commonFilterContains,
      makbuzNo: commonFilterContains,
      miktar: commonFilterContains
    });
    setGlobalFilterValueHarclar("");
  };

  useEffect(() => {
    initFiltersTahsilatlar();
    initFiltersReddiyatlar();
    initFiltersHarclar();
  }, []);

  const onGlobalFilterChangeTahsilatlar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filtersTahsilatlar };
    // @ts-ignore
    _filters["global"].value = value;
    setFiltersTahsilatlar(_filters);
    setGlobalFilterValueTahsilatlar(value);
  };

  const onGlobalFilterChangeReddiyatlar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filtersReddiyatlar };
    // @ts-ignore
    _filters["global"].value = value;
    setFiltersReddiyatlar(_filters);
    setGlobalFilterValueReddiyatlar(value);
  };

  const onGlobalFilterChangeHarclar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filtersHarclar };
    // @ts-ignore
    _filters["global"].value = value;
    setFiltersHarclar(_filters);
    setGlobalFilterValueHarclar(value);
  };

  const exportExcel = () => {
    const dataTahsilatlar = processedDataTahsilatlar || [];
    const headersTahsilatlar = [
      "Dosya No",
      "Dosya Türü",
      "Birim Adı",
      "Tahsilat Türü",
      "Tahsilat Tarihi",
      "Ödeyen Kişi",
      "Makbuz No",
      "Tahsilat Tutarı",
      "Kalan Miktar"
    ];
    const filteredDataTahsilatlar = dataTahsilatlar.map(
      ({
        dosyaNo,
        dosyaTurAciklama,
        birimAdi,
        tahsilatTuru,
        tahsilatTarihiFormatted,
        odeyenKisi,
        makbuzNo,
        tahsilatTutari,
        kalanMiktar
      }) => ({
        dosyaNo,
        dosyaTurAciklama,
        birimAdi,
        tahsilatTuru,
        tahsilatTarihiFormatted,
        odeyenKisi,
        makbuzNo,
        tahsilatTutari,
        kalanMiktar
      })
    );

    const dataReddiyatlar = processedDataReddiyatlar || [];
    const headersReddiyatlar = [
      "Birim Adı",
      "Dosya No",
      "Makbuz No",
      "Reddiyat Tarihi",
      "Reddiyat Nedeni",
      "Ödeyen Kişi",
      "Miktar",
      "Ödenecek Miktar",
      "Gelir Vergisi",
      "Damga Vergisi",
      "Tahsil Harcı",
      "Cezaevi Harcı"
    ];
    const filteredDataReddiyatlar = dataReddiyatlar.map(
      ({
        birimAdi,
        dosyaNo,
        makbuzNo,
        reddiyatTarihiFormatted,
        reddiyatNedeni,
        odeyenKisi,
        miktar,
        odenecekMiktar,
        gelirVergisi,
        damgaVergisi,
        tahsilHarci,
        cezaeviHarcTutari
      }) => ({
        birimAdi,
        dosyaNo,
        makbuzNo,
        reddiyatTarihiFormatted,
        reddiyatNedeni,
        odeyenKisi,
        miktar,
        odenecekMiktar,
        gelirVergisi,
        damgaVergisi,
        tahsilHarci,
        cezaeviHarcTutari
      })
    );

    const dataHarclar = processedDataHarclar || [];
    const headersHarclar = [
      "Dosya No",
      "Dosya Türü",
      "Birim Adı",
      "Tahsilat Türü",
      "Tahsilat Tarihi",
      "Ödeyen Kişi",
      "Makbuz No",
      "Miktar"
    ];
    const filteredDataHarclar = dataHarclar.map(
      ({
        dosyaNo,
        dosyaTurAciklama,
        birimAdi,
        tahsilatTuru,
        tahsilatTarihiFormatted,
        odeyenKisi,
        makbuzNo,
        miktar
      }) => ({
        dosyaNo,
        dosyaTurAciklama,
        birimAdi,
        tahsilatTuru,
        tahsilatTarihiFormatted,
        odeyenKisi,
        makbuzNo,
        miktar
      })
    );

    const dataToplamlar = {
      toplamTahsilat: currencyFormat(TahRedResponse?.result?.toplamTahsilat ?? 0),
      toplamreddiyat: currencyFormat(TahRedResponse?.result?.toplamreddiyat ?? 0),
      toplamKalan: currencyFormat(TahRedResponse?.result?.toplamKalan ?? 0),
      toplamTahsilHarci: currencyFormat(TahRedResponse?.result?.toplamTahsilHarci ?? 0),
      toplamTeminat: currencyFormat(TahRedResponse?.result?.toplamTeminat ?? 0),
      haricen: currencyFormat(TahRedResponse?.result?.haricen ?? 0)
    };

    const headersToplamlar = ["Tahsilat", "Reddiyat", "Tahsilat Kalanı", "Tahsil Harcı", "Cezaevi Harcı", "Masraf"];

    const worksheetData = [
      headersToplamlar,
      Object.values(dataToplamlar),
      [],
      headersTahsilatlar,
      ...filteredDataTahsilatlar.map(Object.values),
      [],
      headersReddiyatlar,
      ...filteredDataReddiyatlar.map(Object.values),
      [],
      headersHarclar,
      ...filteredDataHarclar.map(Object.values)
    ];

    ExportFile.SaveAsExcelFile(worksheetData, "TahsilatReddiyat");
  };

  const processedDataTahsilatlar =
    TahRedResponse?.result?.tahsilatList.map((item) => ({
      ...item,
      tahsilatTarihiFormatted: moment(item.tahsilatTarihi, "MMM DD, YYYY h:mm:ss A").toDate()
    })) || [];

  const processedDataReddiyatlar =
    TahRedResponse?.result?.reddiyatList.map((item, index) => ({
      ...item,
      key: index,
      reddiyatTarihiFormatted: moment(item.reddiyatTarihi, "MMM DD, YYYY h:mm:ss A").toDate()
    })) || [];

  const processedDataHarclar =
    TahRedResponse?.result?.harcList.map((item, index) => ({
      ...item,
      key: index,
      tahsilatTarihiFormatted: moment(item.tahsilatTarihi, "MMM DD, YYYY h:mm:ss A").toDate()
    })) || [];

  //ceza dosya bilgisi yok
  //hukuk 15
  //icra 35
  //idari yargı 9
  //talimat 1
  //satış memurluğu 168
  //Arabuluculuk 422
  //cbs yok
  return (
    <div>
      {TahRedResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {TahRedResponse.error}</div>}
      {TahRedResponse?.result && (
        <div>
          <div className="overflow-x-auto mt-3">
            <DataTable
              value={processedDataTahsilatlar}
              dataKey="kayitId"
              showGridlines
              emptyMessage="Tahsilat Bilgisi bulunamadı."
              loading={TahRedResponseLoading}
              scrollable
              columnResizeMode="expand"
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              filters={filtersTahsilatlar}
              globalFilterFields={[
                "dosyaNo",
                "dosyaTurAciklama",
                "birimAdi",
                "tahsilatTuru",
                "tahsilatTarihiFormatted",
                "odeyenKisi",
                "makbuzNo",
                "tahsilatTutari",
                "kalanMiktar"
              ]}
              header={CreateHeader(
                "Tahsilatlar",
                globalFilterValueTahsilatlar,
                (e) => onGlobalFilterChangeTahsilatlar(e),
                clearFilterTahsilatlar,
                void 0,
                exportExcel
              )}
            >
              <Column
                field="dosyaNo"
                header="Dosya No"
                sortable
                filter
                filterField="dosyaNo"
                filterPlaceholder="Dosya Numarasına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Dosya Numarasına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="dosyaTurAciklama"
                header="Dosya Türü"
                sortable
                filter
                filterField="dosyaTurAciklama"
                filterPlaceholder="Dosya Türüne göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Dosya Türüne göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="birimAdi"
                header="Birim Adı"
                sortable
                filter
                filterField="birimAdi"
                filterPlaceholder="Birim Adına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="tahsilatTuru"
                header="Tahsilat Türü"
                sortable
                filter
                filterField="tahsilatTuru"
                filterPlaceholder="Tahsilat Türüne göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Tahsilat Türüne göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="tahsilatTarihiFormatted"
                header="Tahsilat Tarihi"
                //body={(rowData) => new Date(rowData.tahsilatTarihi).toLocaleString('tr-TR')}
                body={(rowData) => (
                  <span>
                    {rowData.tahsilatTarihiFormatted
                      ? moment(rowData.tahsilatTarihiFormatted).format(process.env.REACT_APP_DATE_FORMAT)
                      : ""}
                  </span>
                )}
                sortable
                filter
                filterField="tahsilatTarihiFormatted"
                filterPlaceholder="Tahsilat Tarihine göre arayın"
                //filterClear={Filter.FilterClearTemplate}
                //filterApply={Filter.FilterApplyTemplate}
                filterElement={Filter.DateFilterTemplate}
                filterMatchMode={FilterMatchMode.DATE_IS}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Tahsilat Tarihine göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="odeyenKisi"
                header="Ödeyen Kişi"
                sortable
                filter
                filterField="odeyenKisi"
                filterPlaceholder="Ödeyen Kişiye göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Ödeyen Kişiye göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="makbuzNo"
                header="Makbuz No"
                sortable
                filter
                filterField="makbuzNo"
                filterPlaceholder="Makbuz Numarasına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Makbuz Numarasına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="tahsilatTutari"
                header="Tahsilat Tutarı"
                sortable
                filter
                filterField="tahsilatTutari"
                filterPlaceholder="Tahsilat Tutarına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Tahsilat Tutarına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="kalanMiktar"
                header="Kalan Miktar"
                sortable
                filter
                filterField="kalanMiktar"
                filterPlaceholder="Kalan Miktara göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Kalan Miktara göre filtreleyin</div>;
                }}
              ></Column>
            </DataTable>
          </div>

          <div className="overflow-x-auto mt-3">
            <DataTable
              value={processedDataReddiyatlar}
              dataKey="key"
              showGridlines
              emptyMessage="Reddiyat Bilgisi bulunamadı."
              loading={TahRedResponseLoading}
              // filterDisplay="row"
              columnResizeMode="expand"
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              // filterDisplay="row"
              filters={filtersReddiyatlar}
              // filterDisplay="row"
              globalFilterFields={[
                "birimAdi",
                "dosyaNo",
                "makbuzNo",
                "reddiyatTarihiFormatted",
                "reddiyatNedeni",
                "odeyenKisi",
                "miktar",
                "odenecekMiktar",
                "gelirVergisi",
                "damgaVergisi",
                "tahsilHarci",
                "cezaeviHarcTutari"
              ]}
              header={CreateHeader(
                "Reddiyatlar",
                globalFilterValueReddiyatlar,
                (e) => onGlobalFilterChangeReddiyatlar(e),
                clearFilterReddiyatlar,
                void 0,
                exportExcel
              )}
            >
              <Column
                field="birimAdi"
                header="Birim Adı"
                sortable
                filter
                filterField="birimAdi"
                filterPlaceholder="Birim Adına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="dosyaNo"
                header="Dosya No"
                sortable
                filter
                filterField="dosyaNo"
                filterPlaceholder="Dosya Numarasına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Dosya Numarasına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="makbuzNo"
                header="Makbuz No"
                sortable
                filter
                filterField="makbuzNo"
                filterPlaceholder="Makbuz Numarasına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Makbuz Numarasına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="reddiyatTarihiFormatted"
                header="Reddiyat Tarihi"
                body={(rowData) => (
                  <span>
                    {rowData.reddiyatTarihiFormatted
                      ? moment(rowData.reddiyatTarihiFormatted).format(process.env.REACT_APP_DATE_FORMAT)
                      : ""}
                  </span>
                )}
                sortable
                filter
                filterField="reddiyatTarihiFormatted"
                filterPlaceholder="Reddiyat Tarihine göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterElement={Filter.DateFilterTemplate}
                filterMatchMode={FilterMatchMode.DATE_IS}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Reddiyat Tarihine göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="reddiyatNedeni"
                header="Reddiyat Nedeni"
                sortable
                filter
                filterField="reddiyatNedeni"
                filterPlaceholder="Reddiyat Nedeniye göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Reddiyat Nedeniye göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="odeyenKisi"
                header="Ödeyen Kişi"
                sortable
                filter
                filterField="odeyenKisi"
                filterPlaceholder="Ödeyen Kişiye göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Ödeyen Kişiye göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="miktar"
                header="Miktar"
                sortable
                filter
                filterField="miktar"
                filterPlaceholder="Miktara göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Miktara göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="odenecekMiktar"
                header="Ödenecek Miktar"
                sortable
                filter
                filterField="odenecekMiktar"
                filterPlaceholder="Ödenecek Miktara göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Ödenecek Miktara göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="gelirVergisi"
                header="Gelir Vergisi"
                sortable
                filter
                filterField="gelirVergisi"
                filterPlaceholder="Gelir Vergisine göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Gelir Vergisine göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="damgaVergisi"
                header="Damga Vergisi"
                sortable
                filter
                filterField="damgaVergisi"
                filterPlaceholder="Damga Vergisine göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Damga Vergisine göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="tahsilHarci"
                header="Tahsil Harcı"
                sortable
                filter
                filterField="tahsilHarci"
                filterPlaceholder="Tahsil Harcına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Tahsil Harcına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="cezaeviHarcTutari"
                header="Cezaevi Harcı"
                sortable
                filter
                filterField="cezaeviHarcTutari"
                filterPlaceholder="Cezaevi Harcına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Cezaevi Harcına göre filtreleyin</div>;
                }}
              ></Column>
              {/* <Column
                                    field="rol"
                                    header="Rol"
                                ></Column>
                                <Column
                                    field="kisiKurum"
                                    header="Kişi/Kurum"
                                ></Column>
                                <Column
                                    field="vekil"
                                    header="Vekil"
                                ></Column> */}
            </DataTable>
          </div>

          <div className="overflow-x-auto mt-3">
            <DataTable
              value={processedDataHarclar}
              dataKey="key"
              showGridlines
              emptyMessage="Harç Bilgisi bulunamadı."
              loading={TahRedResponseLoading}
              // filterDisplay="row"
              columnResizeMode="expand"
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              // filterDisplay="row"
              filters={filtersHarclar}
              // filterDisplay="row"
              globalFilterFields={[
                "dosyaNo",
                "dosyaTurAciklama",
                "birimAdi",
                "tahsilatTuru",
                "tahsilatTarihiFormatted",
                "odeyenKisi",
                "makbuzNo",
                "miktar"
              ]}
              header={CreateHeader(
                "Harçlar",
                globalFilterValueHarclar,
                (e) => onGlobalFilterChangeHarclar(e),
                clearFilterHarclar,
                void 0,
                exportExcel
              )}
            >
              <Column
                field="dosyaNo"
                header="Dosya No"
                sortable
                filter
                filterField="dosyaNo"
                filterPlaceholder="Dosya Numarasına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Dosya Numarasına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="dosyaTurAciklama"
                header="Dosya Türü"
                sortable
                filter
                filterField="dosyaTurAciklama"
                filterPlaceholder="Dosya Türüne göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Dosya Türüne göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="birimAdi"
                header="Birim Adı"
                sortable
                filter
                filterField="birimAdi"
                filterPlaceholder="Birim Adına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="tahsilatTuru"
                header="Tahsilat Türü"
                sortable
                filter
                filterField="tahsilatTuru"
                filterPlaceholder="Tahsilat Türüne göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Tahsilat Türüne göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="tahsilatTarihiFormatted"
                header="Tahsilat Tarihi"
                body={(rowData) => (
                  <span>
                    {rowData.tahsilatTarihiFormatted
                      ? moment(rowData.tahsilatTarihiFormatted).format(process.env.REACT_APP_DATE_FORMAT)
                      : ""}
                  </span>
                )}
                sortable
                filter
                filterField="tahsilatTarihiFormatted"
                filterPlaceholder="Tahsilat Tarihine göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterElement={Filter.DateFilterTemplate}
                filterMatchMode={FilterMatchMode.DATE_IS}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Tahsilat Tarihine göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="odeyenKisi"
                header="Ödeyen Kişi"
                sortable
                filter
                filterField="odeyenKisi"
                filterPlaceholder="Ödeyen Kişiye göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Ödeyen Kişiye göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="makbuzNo"
                header="Makbuz No"
                sortable
                filter
                filterField="makbuzNo"
                filterPlaceholder="Makbuz Numarasına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Makbuz Numarasına göre filtreleyin</div>;
                }}
              ></Column>
              <Column
                field="miktar"
                header="Miktar"
                sortable
                filter
                filterField="miktar"
                filterPlaceholder="Miktara göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => {
                  return <div className="px-3 pt-0 pb-3 text-center">Miktara göre filtreleyin</div>;
                }}
              ></Column>
            </DataTable>
          </div>

          <div className="overflow-x-auto mt-3">
            <Fieldset legend="Toplamlar">
              <DataTable
                value={[{ key: 1 }]}
                dataKey="key"
                showGridlines
                rows={10}
                emptyMessage="Talep Bilgisi bulunamadı."
                loading={TahRedResponseLoading}
                // filterDisplay="row"
                columnResizeMode="expand"
              >
                <Column
                  field="toplamTahsilat"
                  header="Tahsilat"
                  body={() => currencyFormat(TahRedResponse?.result?.toplamTahsilat ?? 0)}
                ></Column>
                <Column
                  field="toplamreddiyat"
                  header="Reddiyat"
                  body={() => currencyFormat(TahRedResponse?.result?.toplamreddiyat ?? 0)}
                ></Column>
                <Column
                  field="toplamKalan"
                  header="Tahsilat Kalanı"
                  body={() => currencyFormat(TahRedResponse?.result?.toplamKalan ?? 0)}
                ></Column>
                <Column
                  field="toplamTahsilHarci"
                  header="Tahsil Harcı"
                  body={() => currencyFormat(TahRedResponse?.result?.toplamTahsilHarci ?? 0)}
                ></Column>
                <Column
                  field="toplamTeminat"
                  header="Cezaevi Harcı"
                  body={() => currencyFormat(TahRedResponse?.result?.toplamTeminat ?? 0)}
                ></Column>
                <Column
                  field="haricen"
                  header="Masraf"
                  body={() => currencyFormat(TahRedResponse?.result?.haricen ?? 0)}
                ></Column>
              </DataTable>
            </Fieldset>
          </div>
        </div>
      )}
    </div>
  );
};

export default TahRedBilgileri;
