import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  MasrafBilgisiDto,
  CreateMasrafBilgisiDto,
  UpdateMasrafBilgisiDto,
} from "./masraf-bilgisi.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<MasrafBilgisiDto>(`MasrafBilgisi/Get/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<MasrafBilgisiDto[]>(`MasrafBilgisi/GetAll`, config);
  },
  GetAllByIcraFoy(
    icraFoyId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<MasrafBilgisiDto[]>(
      `MasrafBilgisi/GetAllByIcraFoy/${icraFoyId}`,
      config
    );
  },
  Create(
    dto: CreateMasrafBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<MasrafBilgisiDto>("MasrafBilgisi/Create", dto, config);
  },
  Update(
    dto: UpdateMasrafBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<MasrafBilgisiDto>("MasrafBilgisi/Update", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`MasrafBilgisi/Delete/${id}`, config);
  },
};

export default services;
