import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useRef, useState } from "react";
import { AracBilgileri, AracMahrumiyetOutput } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import moment from "moment";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const EgmMahrumiyet = (props: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  egmData?: AracBilgileri;
  dosyaId: string;
  kisiKurumId: number;
}) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<AracMahrumiyetOutput[]>>(null);

  const {
    data: AracMahrumiyetResponse,
    refetch: refetchAracMahrumiyet,
    isLoading: aracMahrumiyetResponseLoading
  } = useQuery({
    queryKey: ["EgmMahrumiyet", props.dosyaId, props.kisiKurumId, props.egmData?.plakaSifreli],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() =>
        uyapService.GetAracMahrumiyetBilgiler(props.dosyaId, props.kisiKurumId, props.egmData?.plakaSifreli || "")
      )
  });

  useEffect(() => {
    setBlocked(aracMahrumiyetResponseLoading);
  }, [aracMahrumiyetResponseLoading]);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ekleyenBirim: commonFilterContains,
      eklemeTarihiFormated: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
      },
      takyidatAdi: commonFilterContains,
      kurumAd: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const data = processedData || [];

    const headers = ["Ekleyen Birim", "Ekleme Tarihi", "Şerh Türü", "Vekil"];

    const filteredData = data.map(({ ekleyenBirim, eklemeTarihi, takyidatAdi, kurumAd }) => ({
      ekleyenBirim,
      eklemeTarihi,
      takyidatAdi,
      kurumAd
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "EgmMahrumiyet");
  };

  const processedData =
    AracMahrumiyetResponse?.result?.sorguSonucDVO?.output?.map((item, index) => ({
      ...item,
      key: index,
      eklemeTarihiFormated: moment(item.eklemeTarihSaat, "DD.MM.YYYY hh:mm:ss").toDate(),
      ekleyenBirim: `${item.ekleyenBirim} ( ${item.evrakSayisi} )`
    })) || [];

  return (
    <div>
      {AracMahrumiyetResponse?.error && (
        <div className="card p-2 lg:p-5 mt-3">Hata : {AracMahrumiyetResponse.error}</div>
      )}

      {AracMahrumiyetResponse?.result?.sorguSonucu ? (
        <div>
          <Dialog
            header={AracMahrumiyetResponse?.result?.sorguSonucDVO?.output?.[0]?.plaka + " - Mahrumiyet Bilgisi"}
            visible={props.visible}
            maximizable
            style={{ width: "60%", height: "60%" }}
            onHide={() => {
              if (!props.visible) return;
              props.setVisible(false);
            }}
          >
            <div className="card p-1 lg:p-5" style={{ height: "100%" }}>
              <div className="overflow-x-auto" style={{ height: "100%" }}>
                <DataTable
                  //value={AracMahrumiyetResponse?.result?.sorguSonucDVO?.output.map((item, index) => ({ ...item, key: index })) || []}
                  value={processedData || []}
                  ref={dt}
                  dataKey="key"
                  showGridlines
                  scrollable
                  //scrollHeight="250px"
                  loading={aracMahrumiyetResponseLoading}
                  style={{ minHeight: "7rem" }}
                  selectionMode={"single"}
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  filters={filters}
                  // filterDisplay="row"
                  globalFilterFields={["ekleyenBirim", "eklemeTarihiFormated", "takyidatAdi", "kurumAd"]}
                  header={CreateHeader(
                    "Egm Mahrumiyet",
                    globalFilterValue,
                    (e) => onGlobalFilterChange(e),
                    clearFilter,
                    exportCSV,
                    exportExcel
                  )}
                  columnResizeMode="expand"
                >
                  <Column
                    header="No"
                    body={(rowData, { rowIndex }) => rowIndex + 1}
                    className="w-4rem text-center"
                    sortable
                  ></Column>
                  <Column
                    field="ekleyenBirim"
                    header="Ekleyen Birim"
                    sortable
                    filterField="ekleyenBirim"
                    filter
                    filterPlaceholder="Ekleyen birime göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Ekleyen birime göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="eklemeTarihiFormated"
                    header="Ekleme Tarihi"
                    sortable
                    body={(rowData) => (
                      <span>
                        {rowData.eklemeTarihiFormated
                          ? moment(rowData.eklemeTarihiFormated).format(process.env.REACT_APP_DATE_FORMAT)
                          : ""}
                      </span>
                    )}
                    filterField="eklemeTarihiFormated"
                    filter
                    filterPlaceholder="Ekleme tarihine göre arayın"
                    filterElement={Filter.DateFilterTemplate}
                    filterMatchMode={FilterMatchMode.DATE_IS}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Ekleme tarihine göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="takyidatAdi"
                    header="Şerh Türü"
                    sortable
                    filterField="takyidatAdi"
                    filter
                    filterPlaceholder="Şerh türüne göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Şerh türüne göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="kurumAd"
                    header="Kurum Adı"
                    sortable
                    filterField="kurumAd"
                    filter
                    filterPlaceholder="Kurum adına göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Kurum adına göre filtreleyin</div>;
                    }}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </Dialog>
        </div>
      ) : (
        <>
          <div className="card p-2 lg:p-5 mt-3">{AracMahrumiyetResponse?.result?.sorguSonucDVO?.hataMesaj}</div>
        </>
      )}
    </div>
  );
};

export default EgmMahrumiyet;
