import { useEventListener, useMountEffect, useUnmountEffect } from "primereact/hooks";
import React, { useContext, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import AppFooter from "./AppFooter";
import AppSidebar from "./AppSidebar";
import AppTopbar from "./AppTopbar";
import AppConfig from "./AppConfig";
import { LayoutContext } from "./context/layoutcontext";
import { addLocale, locale, PrimeReactContext } from "primereact/api";
import { ChildContainerProps, LayoutState, AppTopbarRef } from "../types/types";
import { useHub } from "../utils/buro-hub";
import { ProgressBar } from "primereact/progressbar";
import { useEvrak } from "../utils/evrakContext";
import { useTopluSorguIslemler } from "../utils/toplu-sorgu-islemler";
import { MeterGroup } from "primereact/metergroup";
import { Button } from "primereact/button";
import { useVatandasPortal } from "../utils/vatandasPortalContext";

const Layout = ({ children }: ChildContainerProps) => {
  const { layoutConfig, layoutState, setLayoutState } = useContext(LayoutContext);
  const { setRipple } = useContext(PrimeReactContext);
  const topbarRef = useRef<AppTopbarRef>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] = useEventListener({
    type: "click",
    listener: (event) => {
      const isOutsideClicked = !(
        sidebarRef.current?.isSameNode(event.target as Node) ||
        sidebarRef.current?.contains(event.target as Node) ||
        topbarRef.current?.menubutton?.isSameNode(event.target as Node) ||
        topbarRef.current?.menubutton?.contains(event.target as Node)
      );

      if (isOutsideClicked) {
        hideMenu();
      }
    }
  });
  const hub = useHub();
  const { downloaded, total, stopDownload } = useEvrak();
  const {
    downloaded: vatandasDownloaded,
    total: vatandasTotal,
    stopDownload: vatandasStopDownload
  } = useVatandasPortal();
  const { topluSorguYapilan, totalTopluSorgu } = useTopluSorguIslemler();

  // const pathname = usePathname();
  // const searchParams = useSearchParams();
  // useEffect(() => {
  //   hideMenu();
  //   hideProfileMenu();
  // }, [pathname, searchParams]);

  const [bindProfileMenuOutsideClickListener, unbindProfileMenuOutsideClickListener] = useEventListener({
    type: "click",
    listener: (event) => {
      const isOutsideClicked = !(
        topbarRef.current?.topbarmenu?.isSameNode(event.target as Node) ||
        topbarRef.current?.topbarmenu?.contains(event.target as Node) ||
        topbarRef.current?.topbarmenubutton?.isSameNode(event.target as Node) ||
        topbarRef.current?.topbarmenubutton?.contains(event.target as Node)
      );

      if (isOutsideClicked) {
        hideProfileMenu();
      }
    }
  });

  const hideMenu = () => {
    setLayoutState((prevLayoutState: LayoutState) => ({
      ...prevLayoutState,
      overlayMenuActive: false,
      staticMenuMobileActive: false,
      menuHoverActive: false
    }));
    unbindMenuOutsideClickListener();
    unblockBodyScroll();
  };

  const hideProfileMenu = () => {
    setLayoutState((prevLayoutState: LayoutState) => ({
      ...prevLayoutState,
      profileSidebarVisible: false
    }));
    unbindProfileMenuOutsideClickListener();
  };

  const blockBodyScroll = (): void => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = (): void => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
        " "
      );
    }
  };

  useMountEffect(() => {
    if (setRipple) {
      setRipple(layoutConfig.ripple);
    }
  });

  useEffect(() => {
    if (layoutState.overlayMenuActive || layoutState.staticMenuMobileActive) {
      bindMenuOutsideClickListener();
    }

    layoutState.staticMenuMobileActive && blockBodyScroll();
  }, [layoutState.overlayMenuActive, layoutState.staticMenuMobileActive]);

  useEffect(() => {
    if (layoutState.profileSidebarVisible) {
      bindProfileMenuOutsideClickListener();
    }
  }, [layoutState.profileSidebarVisible]);

  useUnmountEffect(() => {
    unbindMenuOutsideClickListener();
    unbindProfileMenuOutsideClickListener();
  });

  const containerClass = classNames("layout-wrapper", {
    "layout-overlay": layoutConfig.menuMode === "overlay",
    "layout-static": layoutConfig.menuMode === "static",
    "layout-static-inactive": layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === "static",
    "layout-overlay-active": layoutState.overlayMenuActive,
    "layout-mobile-active": layoutState.staticMenuMobileActive,
    "p-input-filled": layoutConfig.inputStyle === "filled",
    "p-ripple-disabled": !layoutConfig.ripple
  });

  const evrakAndTopluSorgu = () => {
    if (total > 0 && downloaded < total && totalTopluSorgu > 0 && topluSorguYapilan < totalTopluSorgu) {
      return (
        <>
          <div className="fixed" style={{ width: 250, height: 100, zIndex: 999, left: 20, bottom: 100 }}>
            <div className="card p-0">
              <div className="p-5 py-3">
                <div className="mb-0">
                  <p>
                    Evraklar İndiriliyor... {downloaded} / {total}
                  </p>
                  <Button label="İptal Et" severity="danger" onClick={stopDownload} />
                </div>
              </div>

              <hr className="mt-0 mb-0" />

              <div className="p-5 py-5">
                <div className=" mt-0">
                  <p>
                    Toplu Sorgu Yapılıyor... {topluSorguYapilan} / {totalTopluSorgu}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    if (total > 0 && downloaded < total) {
      return (
        <div className="fixed" style={{ width: 230, height: 150, zIndex: 999, left: 25, bottom: 5 }}>
          <div className="card flex flex-column gap-2">
            <p>
              Evraklar İndiriliyor... {downloaded} / {total}
            </p>
            {/* <MeterGroup values={[{ label: "Evrak: ", value: (downloaded / total) * 100 }]} />
            <ProgressBar
              value={(downloaded / total) * 100}
              displayValueTemplate={() => (
                <div>
                  {downloaded}/{total}
                </div>
              )}
            ></ProgressBar> */}
            <Button label="İptal Et" severity="danger" onClick={stopDownload} />
          </div>
        </div>
      );
    }

    if (vatandasTotal > 0 && vatandasDownloaded < vatandasTotal) {
      return (
        <div className="fixed" style={{ width: 230, height: 150, zIndex: 999, left: 25, bottom: 5 }}>
          <div className="card flex flex-column gap-2">
            <p>
              Evraklar İndiriliyor... {vatandasDownloaded} / {vatandasTotal}
            </p>
            <Button label="İptal Et" severity="danger" onClick={vatandasStopDownload} />
          </div>
        </div>
      );
    }

    if (totalTopluSorgu > 0 && topluSorguYapilan < totalTopluSorgu) {
      return (
        <div className="fixed" style={{ width: 230, height: 100, zIndex: 999, left: 25, bottom: 5 }}>
          <div className="card">
            <p>
              Toplu Sorgu Yapılıyor... {topluSorguYapilan} / {totalTopluSorgu}
            </p>
            {/* <ProgressBar
              value={(topluSorguYapilan / totalTopluSorgu) * 100}
              displayValueTemplate={() => (
                <div>
                  {topluSorguYapilan}/{totalTopluSorgu}
                </div>
              )}
            ></ProgressBar> */}
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className={containerClass}>
        <AppTopbar ref={topbarRef} />
        <div ref={sidebarRef} className="layout-sidebar">
          <AppSidebar />
        </div>
        <div className="layout-main-container">
          <div className="layout-main">{children}</div>
          <AppFooter />
        </div>
        <AppConfig />
        <div className="layout-mask"></div>
      </div>
      {evrakAndTopluSorgu()}
    </React.Fragment>
  );
};

export default Layout;
