import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { SendSupportMessageDto, SupportMessageDto } from "../../services/support/support-message.dto";
import { useQuery } from "@tanstack/react-query";
import SupportService from "../../services/support/support.services"
import { useToast } from "../../utils/toast";
import { FileUpload, FileUploadRemoveEvent, FileUploadSelectEvent } from "primereact/fileupload";
import { FormikErrors, useFormik } from "formik";
import { CreateSupportDto, SupportDto, SupportStatus } from "../../services/support/support.dto";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import MessageThread from "./components/MessageThread";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

const SupportDetailPage = () => {
    const { id } = useParams<{ id: string }>()
    const toast = useToast()
    const navigate = useNavigate()

    const fileUploadRef = useRef<FileUpload>(null)
    const {
        data: supportDetailResponse,
        refetch: refetchSupportDetail,
        isLoading: supportDetailIsLoading,
    } = useQuery({
        queryKey: ["Support"],
        refetchOnMount: "always",
        queryFn: () => SupportService.Get(Number(id)),
        staleTime: 0
    });
    const data = supportDetailResponse?.result?.data

    const formik = useFormik<CreateSupportDto>({
        initialValues: {
            topic: "",
            message: "",
            files: []
        },
        enableReinitialize: true,
        validate: (validateData) => {
            let errors: FormikErrors<CreateSupportDto> = {};
            if (!data && !validateData.topic) {
                errors.topic = "Konu alanı boş bırakılamaz";
            }

            if (!validateData.message) {
                errors.message = "Mesaj alanı boş bırakılamaz";
            }
            return errors;
        },
        onSubmit: async () => {
            const formData = new FormData();
            formData.append("topic", formik.values.topic);
            formData.append("message", formik.values.message);
            formik.values.files.forEach(fileData => formData.append("files", fileData))
            if (data) {
                formData.append("supportId", String(data.id))
                SupportService.Update(formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => {
                    if (!res?.result?.error) {
                        formik.resetForm();
                        fileUploadRef.current?.clear()
                        refetchSupportDetail();
                        toast.show("Mesaj başarıyla gönderildi.", "success");
                    }
                })
            } else {
                SupportService.Create(formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => {
                    if (!res?.result?.error) {
                        formik.resetForm();
                        toast.show("Mesaj başarıyla gönderildi.", "success");
                        const id = res.result?.data.id
                        fileUploadRef.current?.clear()
                        navigate(`/app/support/${id}`)
                    }
                })
            }
        },
    })

    const onSelectFile = (event: FileUploadSelectEvent) => {
        formik.setFieldValue("files", event.files);
    };

    const onRemoveFile = (event: FileUploadRemoveEvent) => {
        const index = formik.values.files.indexOf(event.file)
        const newFiles = formik.values.files.splice(index, 1)
        formik.setFieldValue("files", newFiles)
    }

    const chooseOptions = {
        icon: "pi pi-fw pi-images",
        iconOnly: false,
        className: "custom-choose-btn",
        label: "Seç",
    };

    const uploadOptions = {
        icon: "",
        iconOnly: true,
        className: "hidden",
        label: "Yükle",
    };
    return (
        <div className="p-4 font-bold border-round-lg msg__content">
            {data ?
                <div>
                    <div className="row">
                        <div className="col-12 mb-1 flex justify-content-between">
                            <div className="text-secondary"><span className="text-primary text-bold mr-1">Konu: </span>{data.topic}</div>
                        </div>
                        <div className="col-12 card border-rounded-sm">
                            <MessageThread data={data} isAdminPage={false} />
                        </div>
                    </div>
                </div> :
                <div><h4 className="mb-4">Destek Talebi Oluştur</h4></div>}
            <div className="row">
                {data ? <></> : <div className="col-6">
                    <label className="text-primary mr-2" htmlFor="topic">Konu:</label>
                    <InputText value={formik.values.topic} onChange={(e) => {
                        formik.setFieldValue("topic", e.target.value);
                    }} className={classNames("w-full mt-2 p-inputtext-lg", {
                        "p-invalid": FormikValueIsValid(formik, "topic"),
                    })}
                    />
                    {FormikValueIsValid(formik, "topic") && (
                        <div className="p-error mt-3">{formik.errors.topic}</div>
                    )}
                </div>}
                <Divider />

                {data?.status != SupportStatus.Closed && <>
                    <div className="col-12">
                        <FileUpload name="files[]"
                            mode="advanced"
                            ref={fileUploadRef}
                            multiple accept="image/*" maxFileSize={1000000}
                            onSelect={onSelectFile}
                            onRemove={onRemoveFile}
                            uploadOptions={uploadOptions}
                            chooseOptions={chooseOptions}
                            emptyTemplate={<p className="m-0">Dosya seçin veya buraya sürükleyin. <br />
                                <span className="text-sm">Sadece resim formatları destekleniyor. (jpg, png, gif, webp, svg).</span></p>} />
                    </div>
                    <div className="col-12">
                        <label className="text-primary" htmlFor="message">Mesaj</label>
                        <InputTextarea id="message"
                            name="message"
                            rows={5}
                            value={formik.values.message}
                            onChange={(e) => {
                                formik.setFieldValue("message", e.target.value);
                            }}
                            className={classNames("w-full mt-2", {
                                "p-invalid": FormikValueIsValid(formik, "message"),
                            })}
                        />
                        {FormikValueIsValid(formik, "message") && (
                            <div className="p-error mt-3">{formik.errors.message}</div>
                        )}
                    </div>
                    <div className="col-12 flex justify-content-end">
                        <Button
                            label="Gönder"
                            className="md:px-6 justify-content-center"
                            onClick={formik.submitForm}
                        />
                    </div></>}

            </div>
        </div>
    )
}

export default SupportDetailPage