import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  IcraFoyDto,
  CreateIcraFoyDto,
  UpdateIcraFoyDto,
  GetMaxFoyNumberDto,
  IcraFoyKarsiYanDto,
} from "./icra-foy.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<IcraFoyDto>(`IcraFoy/Get/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<IcraFoyDto[]>(`IcraFoy/GetAll`, config);
  },
  GetAllIcraFoyWithBorclu(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<IcraFoyKarsiYanDto[]>(
      `IcraFoy/GetAllIcraFoyWithBorclu`,
      config
    );
  },
  Create(dto: CreateIcraFoyDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<IcraFoyDto>("IcraFoy/Create", dto, config);
  },
  Update(dto: UpdateIcraFoyDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<IcraFoyDto>("IcraFoy/Update", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`IcraFoy/Delete/${id}`, config);
  },
  GetMaxFoyNumber(
    klasorId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<GetMaxFoyNumberDto>(
      `IcraFoy/GetMaxFoyNumber/${klasorId}`,
      config
    );
  },
  GetAllByBorcluId(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<IcraFoyDto[]>(`IcraFoy/GetAllByBorcluId/${id}`, config);
  },
};

export default services;
