import React, { useContext, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { LoginDto } from "../../services/auth/auth.dto";
import accountService from "../../services/account/account.service";
import { useAuth } from "../../utils/auth";
import { useHub } from "../../utils/buro-hub";
import { VerifyUserAndSendPhoneNumberConfirmSmsDto } from "../../services/account/account.dto";
import { InputMask } from "primereact/inputmask";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { LayoutContext } from "../../layout/context/layoutcontext";

const VerifyUserAndConfirmPhoneNumber = () => {
  const navigate = useNavigate();
  const { setBlocked } = useContext(LayoutContext);
  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  );

  const formik = useFormik<VerifyUserAndSendPhoneNumberConfirmSmsDto>({
    initialValues: {
      email: "",
      phoneNumber: "",
      password: ""
    },
    enableReinitialize: true,
    validate: (data) => {
      const errors: any = {};

      if (!data.email) {
        errors.email = "E-posta veya kullanıcı adı gereklidir";
      }

      if (!data.phoneNumber) {
        errors.phoneNumber = "Telefon numarası gereklidir";
      }

      return errors;
    },
    onSubmit: async (values) => {
      values.phoneNumber = values.phoneNumber
        .replace(/_/g, "")
        .replace(/ /g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/-/g, "");
      setBlocked(true);
      const res = await accountService.VerifyUserAndSendPhoneNumberConfirmSms(values);
      if (res.result && !res.result.error) {
        navigate(`/phone-verify/${res.result.data.id}/${values.phoneNumber}`, {
          replace: true
        });
      }
      setBlocked(false);
    }
  });

  return (
    <form className={containerClassName} onSubmit={formik.handleSubmit}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
          }}
        >
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
            <div className="text-center mb-5">
              <img src="/images/logo.png" alt="Image" height="50" className="mb-3" />
              <div className="text-900 text-3xl font-medium mb-3">{"Telefon Doğrulama"}</div>
              <span className="text-600 font-medium">
                {"Telefon numaranızı doğrulamak için lütfen aşağıdaki bilgileri girin."}
              </span>
            </div>

            <div>
              <div>
                <label htmlFor="email" className="block text-900 text-xl font-medium mb-2 text-primary">
                  {"Kullanıcı Adı veya E-Posta Adresi"}
                </label>
                <InputText
                  id="email"
                  type="text"
                  placeholder={"Kullanıcı Adı veya E-Posta Adresi"}
                  className="w-full"
                  value={formik.values.email}
                  onChange={(e) => formik.setFieldValue("email", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {formik.errors.email && <div className="p-error mt-3">{formik.errors.email}</div>}
              </div>

              <div className="mt-3">
                <label htmlFor="phoneNumber" className="text-primary text-xl text-900 font-medium">
                  Telefon Numarası
                </label>
                <InputMask
                  id="phoneNumber"
                  keyfilter="int"
                  mask="(0999) 999-99-99"
                  placeholder="Telefon Numarası"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "phoneNumber")
                  })}
                  value={formik.values.phoneNumber}
                  onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "phoneNumber") && (
                  <div className="p-error mt-3">{formik.errors.phoneNumber}</div>
                )}
              </div>

              <div className="mt-3">
                <label htmlFor="password" className="block text-900 font-medium text-xl mb-2 text-primary">
                  {"Parola"}
                </label>
                <div className="login-password">
                  <Password
                    inputId="password"
                    value={formik.values.password}
                    onChange={(e) => formik.setFieldValue("password", e.target.value)}
                    placeholder={"Parola"}
                    toggleMask={true}
                    feedback={false}
                    className="w-full block"
                    inputClassName={classNames("block w-full p-3", {
                      "p-invalid": FormikValueIsValid(formik, "password")
                    })}
                  ></Password>
                  {formik.errors.password && <div className="p-error mt-3">{formik.errors.password}</div>}
                </div>
              </div>

              <Button label="Doğrulama Kodu Gönder" className="w-full p-3 text-xl mt-5" type="submit"></Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default VerifyUserAndConfirmPhoneNumber;
