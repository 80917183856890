import React, { useContext, useEffect, useState } from "react";
import { DisIslerResponseDTO, DosyaDTO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import SorguBilgileri from "./SorguBilgileri";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const DisIsleri = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const {
    data: DisIsleriResponse,
    refetch: refetchDisIsleri,
    isLoading: disIsleriResponseLoading
  } = useQuery({
    queryKey: ["DisIsleri", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetDisIsleriBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(disIsleriResponseLoading)
  }, [disIsleriResponseLoading])

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      adresTipi: commonFilterContains,
      sehir: commonFilterContains,
      adres: commonFilterContains,
      postaKodu: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  return (
    <div>
      {DisIsleriResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {DisIsleriResponse?.error}</div>}

      {DisIsleriResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={DisIsleriResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={DisIsleriResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={DisIsleriResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={DisIsleriResponse?.result?.sorgulayanBilgileri}
          />
          {DisIsleriResponse.result.sorguSonucu ? (
            <div className="overflow-x-auto mt-3">
              <DataTable
                value={DisIsleriResponse?.result?.sorguSonucDVO}
                showGridlines
                scrollable
                loading={disIsleriResponseLoading}
                scrollHeight="250px"
                style={{ minHeight: "7rem" }}
                selectionMode={"single"}
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                filters={filters}
                // filterDisplay="row"
                globalFilterFields={["adresTipi", "sehir", "adres", "postaKodu"]}
                header={CreateHeader("Dış İşleri", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
                columnResizeMode="expand"
              >
                <Column
                  field="adresTipi._value_"
                  header="Adres Türü"
                  sortable
                  filterField="adresTipi"
                  filter
                  filterPlaceholder="Adres Türü'ne göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Adres Türü'ne göre filtreleyin</div>;
                  }}
                ></Column>
                <Column
                  field="sehir"
                  header="Şehir"
                  sortable
                  filterField="sehir"
                  filter
                  filterPlaceholder="Şehre göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Şehre göre filtreleyin</div>;
                  }}
                ></Column>
                <Column
                  field="adres"
                  header="Adres"
                  sortable
                  filterField="adres"
                  filter
                  filterPlaceholder="Adres'e göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Adres'e göre filtreleyin</div>;
                  }}
                ></Column>
                <Column
                  field="postaKodu"
                  header="Posta Kodu"
                  sortable
                  filterField="postaKodu"
                  filter
                  filterPlaceholder="Posta Kodu'na göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Posta Kodu'na göre filtreleyin</div>;
                  }}
                ></Column>
              </DataTable>
            </div>
          ) : (
            <div className="card p-2 lg:p-5 mt-3">Kişiye ait Dış İşleri adres kaydı yok.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DisIsleri;
