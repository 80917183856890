import { DosyaBorcluListesiResponseDTO, DosyaOzetDVO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";

const SorguBilgileri = (props: {
  sorguAdı: string;
  sorgulayanBilgileri?: string;
  borcluBilgileri?: DosyaBorcluListesiResponseDTO;
  dosyaOzetBilgileri?: DosyaOzetDVO;
}) => {
  const { sorgulayanBilgileri, sorguAdı, dosyaOzetBilgileri, borcluBilgileri } = props;

  return (
    <div>
      <div className="card p-2 lg:p-5 mt-3">
        <h3>Sorgulamaya Ait Genel Bilgiler</h3>
        <hr />
        <div className="grid">
          <div className="col-12 flex gap-3">
            <div className="font-bold sm:w-9rem md:w-18rem lg:w-30rem">Gerçekleştirilen Sorgu Türü</div>
            <div>{`: ${sorguAdı}`}</div>
          </div>
          <div className="col-12 flex gap-3">
            <div className="font-bold sm:w-9rem md:w-18rem lg:w-30rem">İşlemi Gerçekleştirenin Adı Soyadı</div>
            <div>{`: ${sorgulayanBilgileri}`}</div>
          </div>
          <div className="col-12 flex gap-3">
            <div className="font-bold sm:w-9rem md:w-18rem lg:w-30rem">Birim Adı/Dosya No</div>
            <div>{`: ${dosyaOzetBilgileri?.birimDVO.birimAdi} - ${dosyaOzetBilgileri?.dosyaNo}`}</div>
          </div>
          {borcluBilgileri?.kisiTumDVO ? (
            <>
              <div className="col-12 flex gap-3">
                <div className="font-bold sm:w-9rem md:w-18rem lg:w-30rem">Borçlunun Adı Soyadı</div>
                <div>{`: ${borcluBilgileri?.kisiTumDVO.adi} ${borcluBilgileri?.kisiTumDVO.soyadi}`}</div>
              </div>
              <div className="col-12 flex gap-3">
                <div className="font-bold sm:w-9rem md:w-18rem lg:w-30rem">Borçlunun TC Kimlik No</div>
                <div>{`: ${borcluBilgileri?.kisiTumDVO.tcKimlikNo}`}</div>
              </div>
              <div className="col-12 flex gap-3">
                <div className="font-bold sm:w-9rem md:w-18rem lg:w-30rem">Borçlunun Baba/Anne Adı</div>
                <div>{`: ${borcluBilgileri?.kisiTumDVO.babaAdi} / ${borcluBilgileri?.kisiTumDVO.anaAdi}`}</div>
              </div>
              <div className="col-12 flex gap-3">
                <div className="font-bold sm:w-9rem md:w-18rem lg:w-30rem">Borçlunun Doğum Yılı</div>
                <div>{`: ${borcluBilgileri?.kisiTumDVO.dogumTarihiStr}`}</div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 flex gap-3">
                <div className="font-bold sm:w-9rem md:w-18rem lg:w-30rem">Borçlunun Kurum Adı</div>
                <div>{`: ${borcluBilgileri?.kurumDVO?.kurumAdi}`}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SorguBilgileri;
