import { Route, Routes } from "react-router-dom";
import App from "../App";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import LandingPage from "../pages/landing/LandingPage";
import Homepage from "../pages/homepage/Homepage";
import { HasPermission } from "../utils/auth";
import { Permission } from "../services/auth/permissions";
import NotFoundPage from "../pages/not-found/NotFound";
import AccessDeniedPage from "../pages/auth/AccessDeniedPage";
import EmailConfirmPage from "../pages/auth/EmailConfirm";
import ChangeEmailPage from "../pages/auth/ChangeEmail";
import NewEmailConfirmPage from "../pages/auth/NewEmailConfirm";
import ChangePasswordPage from "../pages/auth/ChangePassword";
import ProfilePage from "../pages/auth/Profile";
import MuvekkilPage from "../pages/muvekkil/Muvekkil";
import KarsiYanPage from "../pages/karsi-yan/KarsiYan";
import IcraFoysPage from "../pages/icra-foys/IcraFoy";
import IcraFoyDetail from "../pages/icra-foys/IcraFoyDetail";
import SubUsersPage from "../pages/sub-users/SubUsersPage";
import DosyaSorgulama from "../pages/dosya-sorgulama/DosyaSorgulama";
import TopluSorgulama from "../pages/toplu-sorgulama/TopluSorgulama";
import VerificationPage from "../pages/auth/VerificationPage";
import VerifyUserAndConfirmPhoneNumber from "../pages/auth/VerifyUserAndConfirmPhoneNumber";
import RolePage from "../pages/role/Role";
import FrequentlyUsedFiles from "../pages/frequently-used-files/Frequently-Used-Files";
import DurusmaSorgulamaPage from "../pages/durusma-sorgulama/Durusma-Sorgula";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import TopluSorguListesi from "../pages/toplu-sorgulama/TopluSorguListesi";
import TopluSorguSonuclarListesi from "../pages/toplu-sorgulama/TopluSorguSonuclarListesi";
import SettingsPage from "../pages/settings/Settings";
import SupportPage from "../pages/destek-talep/SupportPage";
import SupportDetailPage from "../pages/destek-talep/SupportDetailPage";
import SupportAdminPage from "../pages/destek-talep/SupportAdminPage";
import SupportAdminDetailPage from "../pages/destek-talep/SupportAdminDetailPage";
import UsersPage from "../pages/admin/users/UsersPage";
import TebligatTakip from "../pages/tebligat/TebligatTakip";
import AppLogsPage from "../pages/admin/app-logs/AppLogsPage";
import SubUsersDetails from "../pages/main-user-details/MainUsersDetails";
import OrdersPage from "../pages/admin/orders/OrdersPage";
import LicensesPage from "../pages/licenses/LicensesPage";
import BasketPage from "../pages/basket/basket-page";
import PricingPackets from "../pages/landing/PricingPackets";
import PaymentPage from "../pages/basket/payment";
import { VatandasPortal } from "../pages/vatandas-portal/VatandasPortal";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/email-confirm" element={<EmailConfirmPage />} />
      <Route path="/change-email-confirm" element={<NewEmailConfirmPage />} />
      <Route path="/phone-verify/:userId/:phoneNumber" element={<VerificationPage />} />
      <Route path="/verify-user-and-confirm-phone-number" element={<VerifyUserAndConfirmPhoneNumber />} />
      <Route path="/app" element={<App />}>
        <Route
          path="roles"
          element={
            <HasPermission permissions={[Permission.RoleDefault]}>
              <RolePage />
            </HasPermission>
          }
        />
        <Route
          path="profile"
          element={
            <HasPermission>
              <ProfilePage />
            </HasPermission>
          }
        />
        <Route
          path="settings"
          element={
            <HasPermission>
              <SettingsPage />
            </HasPermission>
          }
        />
        <Route
          path="homepage"
          element={
            <HasPermission>
              <Homepage />
            </HasPermission>
          }
        />
        <Route
          path="change-email"
          element={
            <HasPermission>
              <ChangeEmailPage />
            </HasPermission>
          }
        />
        <Route
          path="change-password"
          element={
            <HasPermission>
              <ChangePasswordPage />
            </HasPermission>
          }
        />
        <Route
          path="muvekkiller"
          element={
            <HasPermission permissions={[Permission.MuvekkilDefault]}>
              <MuvekkilPage />
            </HasPermission>
          }
        />
        <Route
          path="karsi-yanlar"
          element={
            <HasPermission permissions={[Permission.KarsiYanDefault]}>
              <KarsiYanPage />
            </HasPermission>
          }
        />
        <Route
          path="icra-foyleri"
          element={
            <HasPermission permissions={[Permission.IcraFoyDefault]}>
              <IcraFoysPage />
            </HasPermission>
          }
        />
        <Route
          path="icra-foy-detay/:id"
          element={
            <HasPermission permissions={[Permission.IcraFoyDefault]}>
              <IcraFoyDetail />
            </HasPermission>
          }
        />
        <Route
          path="dosya-sorgulama"
          element={
            <HasPermission permissions={[Permission.DosyaSorgulamaDefault]}>
              <DosyaSorgulama />
            </HasPermission>
          }
        />
        <Route
          path="toplu-sorgulama-listesi"
          element={
            <HasPermission permissions={[Permission.DosyaSorgulamaDefault]}>
              <TopluSorguListesi />
            </HasPermission>
          }
        />
        <Route
          path="toplu-sorgulama"
          element={
            <HasPermission permissions={[Permission.DosyaSorgulamaDefault]}>
              <TopluSorgulama />
            </HasPermission>
          }
        />
        <Route
          path="toplu-sorgu-sonuclar-listesi"
          element={
            <HasPermission permissions={[Permission.DosyaSorgulamaDefault]}>
              <TopluSorguSonuclarListesi />
            </HasPermission>
          }
        />
        <Route
          path="alt-kullanicilarim"
          element={
            <HasPermission permissions={[Permission.SubUserDefault]}>
              <SubUsersPage />
            </HasPermission>
          }
        />
        <Route
          path="sik-kullanilanlar"
          element={
            <HasPermission permissions={[Permission.SikKullanilanlarDefault]}>
              <FrequentlyUsedFiles />
            </HasPermission>
          }
        />
        <Route
          path="durusma-sorgula"
          element={
            <HasPermission permissions={[Permission.DurusmaSorgulamaDefault]}>
              <DurusmaSorgulamaPage />
            </HasPermission>
          }
        />
        <Route
          path="admin/support"
          element={
            <HasPermission permissions={[Permission.SupportAdminDefault]}>
              <SupportAdminPage />
            </HasPermission>
          }
        />
        <Route
          path="support"
          element={
            <HasPermission permissions={[Permission.SupportDefault]}>
              <SupportPage />
            </HasPermission>
          }
        />
        <Route
          path="support/:id"
          element={
            <HasPermission permissions={[Permission.SupportGet]}>
              <SupportDetailPage />
            </HasPermission>
          }
        />
        <Route
          path="admin/support/:id"
          element={
            <HasPermission permissions={[Permission.SupportGet]}>
              <SupportAdminDetailPage />
            </HasPermission>
          }
        />
        <Route
          path="support/create"
          element={
            <HasPermission permissions={[Permission.SupportCreate]}>
              <SupportDetailPage />
            </HasPermission>
          }
        />
        <Route
          path="tebligat-takip"
          element={
            <HasPermission permissions={[Permission.TebligatBilgisiDefault]}>
              <TebligatTakip />
            </HasPermission>
          }
        />
        <Route
          path="main-users"
          element={
            <HasPermission permissions={[Permission.MainUsersDefault]}>
              <UsersPage />
            </HasPermission>
          }
        />
        <Route
          path="main-users/:id"
          element={
            <HasPermission>
              <SubUsersDetails />
            </HasPermission>
          }
        />
        <Route
          path="applogs"
          element={
            <HasPermission permissions={[Permission.AppLogDefault]}>
              <AppLogsPage />
            </HasPermission>
          }
        />

        <Route
          path="orders"
          element={
            <HasPermission permissions={[Permission.AdminOrderDefault]}>
              <OrdersPage />
            </HasPermission>
          }
        />

        <Route
          path="lisanslarim"
          element={
            <HasPermission permissions={[Permission.LicencesDefault]}>
              <LicensesPage />
            </HasPermission>
          }
        />

        <Route
          path="sepetim"
          element={
            <HasPermission permissions={[Permission.CreatePayment]}>
              <BasketPage />
            </HasPermission>
          }
        />

        <Route
          path="ucret-tablosu"
          element={
            <HasPermission permissions={[]}>
              <PricingPackets />
            </HasPermission>
          }
        />
        <Route
          path="payment"
          element={
            <HasPermission permissions={[Permission.CreatePayment]}>
              <PaymentPage />
            </HasPermission>
          }
        />
        <Route
          path="vatandas-portal"
          element={
            <HasPermission permissions={[Permission.VatandasPortalDefault]}>
              <VatandasPortal />
            </HasPermission>
          }
        />
      </Route>
      <Route path="/access-denied" element={<AccessDeniedPage />} />
      <Route path="/not-found" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRouter;
