import React, { useEffect, useState, useRef, useContext } from "react";
import { Fieldset } from "primereact/fieldset";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import uyapService from "../../services/uyap-sorgulari/uyap-sorgulari.service";
import { FilterMatchMode } from "primereact/api";
import DosyaDetaylariDialog from "../dosya-detaylari/DosyaDetaylariDialog";
import {
  CbsBirimDTO,
  DosyaDTO,
  DosyaResponseDTO,
  FormikDosyaTarafDTO,
  IlDTO,
  RequestDosyaTarafDTO,
  RequestFormikDosyaKelimeAramaDTO,
  RequestYargıBirimleriDTO
} from "../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { useHub } from "../../utils/buro-hub";
import classNames from "classnames";
import DropdownDatas from "../../utils/DropdownValues";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import moment from "moment";
import uyapDosyaService from "../../services/uyap-dosya/uyap-dosya.service";
import { CreateUyapDosyaDto } from "../../services/uyap-dosya/uyap-dosya.dto";
import { useToast } from "../../utils/toast";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";
import { ExportFile } from "../../utils/export-file";
import { Chips, ChipsChangeEvent } from "primereact/chips";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { useElectron } from "../../utils/electron-connection";
import { DosyaSorguDurum, TopluSorgulanacakDosyaDTO, TopluSorguListesiModel } from "../toplu-sorgulama/TopluSorgu.dto";
import { LayoutContext } from "../../layout/context/layoutcontext";

const DosyaSorgulama = () => {
  const [selectedDosya, setSelectedDosya] = useState<DosyaDTO | null>(null);
  const [visibleDosyaDetayleriDialog, setVisibleDosyaDetayleriDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dosyalarResult, setDosyalarResult] = useState<DosyaResponseDTO>();
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [yargiBirimleri, setYargiBirimleri] = useState<RequestYargıBirimleriDTO[]>([]);
  const [iller, setIller] = useState<IlDTO[]>([]);
  const [cbsYargıBirimleri, setCbsYargıBirimleri] = useState<CbsBirimDTO[]>([]);
  const [visibleKisiAramaDialog, setVisibleKisiAramaDialog] = useState(false);
  const [visibleKurumAramaDialog, setVisibleKurumAramaDialog] = useState(false);
  const [visibleTarihAramaDialog, setVisibleTarihAramaDialog] = useState(true);
  const [aramaTipi, setAramaTipi] = useState<string>("");
  const [yargıTuru, setYargıTuru] = useState<string>("");
  const [kelimeIleAramaDeger, setKelimeIleAramaDeger] = useState<string[]>([]);
  const toast = useToast();
  const dt = useRef<DataTable<DosyaDTO[]>>(null);
  const [selectedDosyaListesi, setSelectedDosyaListesi] = useState<DosyaDTO[]>([]);
  const [visibleTopluSorguList, setVisibleTopluSorguList] = useState<boolean>(false);
  const [selectedTopluSorguListesi, setSelectedTopluSorguListesi] = useState<TopluSorguListesiModel>();
  const [topluSorguListeleri, setTopluSorguListeleri] = useState<TopluSorguListesiModel[]>([]);
  const [checkedKayitliListeyeEkle, setCheckedKayitliListeyeEkle] = useState<boolean>(false);
  const [valueListeName, setValueListeName] = useState("");
  const electron = useElectron();
  const inputRef = useRef<HTMLInputElement>(null);
  const [visibleTopluSorguEkleButton, setVisibleTopluSorguEkleButton] = useState(false);

  const handleSetVisibleDosyaDetayleriDialog = (visible: boolean) => {
    setVisibleDosyaDetayleriDialog(visible);
    if (visible === false) {
      setSelectedDosya(null);
    }
  };

  const formikKelimeArama = useFormik<RequestFormikDosyaKelimeAramaDTO>({
    initialValues: {
      dosyaDurumKod: 0,
      pageNumber: 1,
      pageSize: 500,
      phrases: ""
    },
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      if (!hub.getBuroSettings().connectedSessionLawyerName) {
        toast.show("Lütfen önce Uyap'a bağlanınız.", "error");
        setBlocked(false);
        return;
      }
      setVisibleTopluSorguEkleButton(true);
      setDosyalarResult(undefined);

      const phrasesArray: string[] = typeof values.phrases === "string" ? [values.phrases] : values.phrases;
      const jsonString: string = JSON.stringify(phrasesArray);

      setLoading(true);
      setBlocked(true);

      let res = await hub.executeUyapService(() =>
        uyapService.GetDosyaBilgileriByWord({
          ...values,
          phrases: jsonString
        })
      );

      setLoading(false);
      setBlocked(false);
      if (res?.result) {
        setDosyalarResult(res?.result);
      }
    }
  });

  const dosyaHandlerKelimeArama = () => {
    if (inputRef.current) {
      const currentInputValue = inputRef.current.value.trim();

      if (currentInputValue && !kelimeIleAramaDeger.includes(currentInputValue)) {
        const newValue = [...kelimeIleAramaDeger, currentInputValue];
        setKelimeIleAramaDeger(newValue);
        formikKelimeArama.setFieldValue("phrases", newValue);
        inputRef.current.value = "";
      }
    }

    formik.resetForm();
    formikKelimeArama.submitForm();
  };

  const formik = useFormik<FormikDosyaTarafDTO>({
    initialValues: {
      birimTuru3: "",
      birimTuru2: "",
      dosyaDurumKod: 0,
      dosyaYil: -1,
      dosyaSira: "",
      pageNumber: 1,
      pageSize: 500,
      adi: "", //Kişi
      soyadi: "", //Kişi
      tcKimlikNo: "", //Kişi
      tarafTuru: -1,
      kurumAdi: "", //Kurum
      mersisNo: undefined, //Kurum
      vergiNo: -1, //Kurum
      il: ""
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.birimTuru3) {
        errors.birimTuru3 = "Yargı Türü Zorunludur";
      }
      if (!values.birimTuru2) {
        errors.birimTuru2 = "Yargı Birimi Zorunludur";
      }

      if (values.dosyaAcilisTarihiEnd && values.dosyaAcilisTarihiStart) {
        const startDate = moment(values.dosyaAcilisTarihiStart, "DD.MM.YYYY").toDate();
        const endDate = moment(values.dosyaAcilisTarihiEnd, "DD.MM.YYYY").toDate();

        if (startDate && endDate && startDate > endDate) {
          errors.dosyaAcilisTarihiEnd = "Bitiş Tarihi, Başlangıç Tarihi'nden önce olamaz.";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      setDosyalarResult(undefined);

      const removeEmptyValues = (values: RequestDosyaTarafDTO): RequestDosyaTarafDTO => {
        return Object.fromEntries(
          Object.entries(values).filter(([_, v]) => v !== "" && v !== null && v !== undefined && v !== -1)
        ) as RequestDosyaTarafDTO;
      };

      let res;
      setBlocked(true);
      setLoading(true);
      // 4 Durum var 1. Kurum 2. Kişi 3. Açık 4. Kapalı  // açık kapalıda tarih adları değişiyor
      if (yargıTuru === "3") {
        // Cbs olduğunda
        //formik.setFieldValue("tarafTuru", 0);
        const {
          adi,
          soyadi,
          tcKimlikNo,
          tarafTuru,
          kurumAdi,
          mersisNo,
          dosyaYil,
          dosyaSira,
          dosyaAcilisTarihiStart,
          dosyaAcilisTarihiEnd,
          dosyaKapanisTarihiStart,
          dosyaKapanisTarihiEnd,
          il,
          ...filteredValues
        } = formik.values;

        const filtered = removeEmptyValues(filteredValues);

        res = await hub.executeUyapService(() => uyapService.GetCbsDosyalarDetayli(filtered));
      } else if (
        aramaTipi === "Kurum" &&
        (formik.values.kurumAdi !== "" || formik.values.mersisNo !== null || formik.values.vergiNo !== -1)
      ) {
        formik.setFieldValue("tarafTuru", 1);
        const {
          adi,
          soyadi,
          dosyaAcilisTarihiStart,
          dosyaAcilisTarihiEnd,
          dosyaKapanisTarihiStart,
          dosyaKapanisTarihiEnd,
          il,
          ...filteredValues
        } = formik.values;

        const filtered = removeEmptyValues(filteredValues);

        res = await hub.executeUyapService(() => uyapService.GetDosyalarTarafDetayli(filtered));
      } else if (
        aramaTipi === "Kisi" &&
        (formik.values.adi !== "" || formik.values.soyadi !== "" || formik.values.tcKimlikNo !== "")
      ) {
        formik.setFieldValue("tarafTuru", 0);
        const {
          kurumAdi,
          mersisNo,
          vergiNo,
          dosyaAcilisTarihiStart,
          dosyaAcilisTarihiEnd,
          dosyaKapanisTarihiStart,
          dosyaKapanisTarihiEnd,
          il,
          ...filteredValues
        } = formik.values;

        const filtered = removeEmptyValues(filteredValues);

        res = await hub.executeUyapService(() => uyapService.GetDosyalarTarafDetayli(filtered));
      } else {
        const requestDosyaTarafDto: RequestDosyaTarafDTO = {
          ...formik.values,
          dosyaAcilisTarihiStart: formik.values.dosyaAcilisTarihiStart?.toLocaleDateString("tr-TR"),
          dosyaAcilisTarihiEnd: formik.values.dosyaAcilisTarihiEnd?.toLocaleDateString("tr-TR"),
          dosyaKapanisTarihiStart: formik.values.dosyaKapanisTarihiStart?.toLocaleDateString("tr-TR"),
          dosyaKapanisTarihiEnd: formik.values.dosyaKapanisTarihiEnd?.toLocaleDateString("tr-TR")
        };

        if (formik.values.dosyaDurumKod === 1) {
          const {
            kurumAdi,
            mersisNo,
            adi,
            soyadi,
            tcKimlikNo,
            tarafTuru,
            dosyaKapanisTarihiStart,
            dosyaKapanisTarihiEnd,
            vergiNo,
            il,
            ...filteredValues
          } = requestDosyaTarafDto;

          const filtered = removeEmptyValues(filteredValues);

          res = await hub.executeUyapService(() => uyapService.GetDosyalarDetayli(filtered));
        } else {
          const {
            kurumAdi,
            mersisNo,
            adi,
            soyadi,
            tcKimlikNo,
            tarafTuru,
            dosyaAcilisTarihiEnd,
            dosyaAcilisTarihiStart,
            vergiNo,
            il,
            ...filteredValues
          } = requestDosyaTarafDto;

          const filtered = removeEmptyValues(filteredValues);

          res = await hub.executeUyapService(() => uyapService.GetDosyalarDetayli(filtered));
        }
      }

      setVisibleTopluSorguEkleButton(yargıTuru === "2");

      setLoading(false);
      setBlocked(false);
      if (res?.result) {
        setDosyalarResult(res?.result);
      }
    }
  });

  const dosyaHandler = () => {
    formik.submitForm();
  };

  const handleAddToFavorites = async (rowData: DosyaDTO) => {
    setBlocked(true);
    try {
      const responseEvraklar = await hub.executeUyapService(() => uyapService.GetAllEvrak(rowData.dosyaId));
      const sonEvrakId = responseEvraklar?.result?.son20Evrak?.[0]?.evrakId;
      if (sonEvrakId) {
        const res = await uyapDosyaService.Create({
          birimAdi: rowData.birimAdi,
          dosyaNo: rowData.dosyaNo,
          dosyaTur: rowData.dosyaTur,
          sonEvrakId: sonEvrakId
        } as CreateUyapDosyaDto);

        if (res && res.result && !res.result.error) {
          toast.show("Sık kullanılan dosyalarınıza başarıyla eklendi.", "success");
        }
      } else {
        toast.show("Evrak ID'si bulunamadı.", "error");
      }
    } catch (error) {
      toast.show("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
    }
    setBlocked(false);
  };

  const actionBodyTemplate = (rowData: DosyaDTO) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-search"
          rounded
          outlined
          className="mr-2"
          severity="success"
          tooltip="Detay"
          onClick={() => {
            if (rowData.dosyaId) {
              setSelectedDosya(rowData);
              setVisibleDosyaDetayleriDialog(true);
            } else {
              alert("Dosya ID'si bulunamadı.");
            }
          }}
        />
        <Button
          icon="pi pi-plus"
          rounded
          outlined
          className="mr-2"
          tooltip="Sık Kullanılan Dosyalarıma Ekle"
          onClick={() => handleAddToFavorites(rowData)}
        />
      </div>
    );
  };

  const getYargıBirimleri = async (value: string) => {
    try {
      //   setBlocked(true)
      const response = await hub.executeUyapService(() => uyapService.YargıBirimleriSorgula(value));

      if (response && response.result) {
        setYargiBirimleri(response.result);
      } else {
        console.error("Invalid response format:", response);
      }
      //   setBlocked(false)
    } catch (error) {
      console.error("Error fetching yargi birimi options:", error);
    }
  };

  const getIller = async () => {
    try {
      const response = await hub.executeUyapService(() => uyapService.GetIller());

      if (response && response.result) {
        setIller(response.result);
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching yargi birimi options:", error);
    }
  };

  const getCbsYargıBirimleri = async (value: number) => {
    try {
      const response = await hub.executeUyapService(() => uyapService.CbsBirimSorgula(value));

      if (response && response.result) {
        setCbsYargıBirimleri(response.result);
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching yargi birimi options:", error);
    }
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2010 + 1 }, (_, i) => {
    const year = currentYear - i;
    return { name: year.toString(), value: year };
  });

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      birimAdi: commonFilterContains,
      dosyaNo: commonFilterContains,
      dosyaTur: commonFilterContains,
      dosyaDurum: commonFilterContains,
      dosyaAcilisTarihiFormated: commonFilterDateIs
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const data = processedData || [];

    const headers = ["Birim Adı", "Dosya No", "Dosya Türü", "Dosya Durumu", "Açılış Tarihi"];

    const filteredData = data.map(({ birimAdi, dosyaNo, dosyaTur, dosyaDurum, dosyaAcilisTarihiFormated }) => ({
      birimAdi,
      dosyaNo,
      dosyaTur,
      dosyaDurum,
      dosyaAcilisTarihiFormated
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "Dosyalar");
  };

  const processedData =
    dosyalarResult?.[0]?.map((item, index) => ({
      ...item,
      key: index,
      dosyaAcilisTarihiFormated: moment({
        year: item.dosyaAcilisTarihi.date.year,
        month: item.dosyaAcilisTarihi.date.month - 1,
        day: item.dosyaAcilisTarihi.date.day,
        hour: item.dosyaAcilisTarihi.time.hour,
        minute: item.dosyaAcilisTarihi.time.minute,
        second: item.dosyaAcilisTarihi.time.second
      }).toDate()
    })) || [];

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = e.target as HTMLInputElement;
      const value = input.value.trim();
      if (value) {
        kelimeIleAramaDeger.push(value);
        setKelimeIleAramaDeger([...kelimeIleAramaDeger]);
        formikKelimeArama.setFieldValue("phrases", [...kelimeIleAramaDeger]);
        input.value = "";
      }
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        {visibleTopluSorguEkleButton && (
          <Button
            label="Seçili İcra Dosyalarını Toplu Sorgu Listesine Ekle"
            icon="pi pi-plus"
            severity="success"
            onClick={async () => {
              if (selectedDosyaListesi.length < 1) {
                toast.show("En az bir dosya seçmelisiniz.", "error");
                return;
              }

              readTopluSorguList();

              setVisibleTopluSorguList(true);
            }}
          />
        )}
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const readTopluSorguList = async () => {
    const topluSorguListeleri = await electron.getTopluSorguListesi();
    if (topluSorguListeleri) {
      setTopluSorguListeleri(topluSorguListeleri);
    }
  };

  useEffect(() => {
    readTopluSorguList();
  }, []);

  const yeniListeyeEkleOnClick = async () => {
    try {
      if (valueListeName.length < 1) {
        toast.show("Lütfen liste adi giriniz", "error");
        return;
      }

      const topluSorguyaGonderilcekListe: TopluSorgulanacakDosyaDTO[] = selectedDosyaListesi
        .filter((dosya) => dosya.dosyaTurKod === 35)
        .map((dosya) => ({
          dosyaNo: dosya.dosyaNo,
          dosyaDurumKod: dosya.dosyaDurumKod,
          birimAdi: dosya.birimAdi,
          dosyaTur: dosya.dosyaTur,
          dosyaDurum: dosya.dosyaDurum,
          birimTuru2: dosya.birimTuru2,
          birimTuru3: dosya.birimTuru3,
          isSorgulandi: DosyaSorguDurum.SORGULANMADI
        }));

      if (topluSorguyaGonderilcekListe.length >= 1) {
        topluSorguListeleri.push({
          name: valueListeName,
          key: new Date().getTime(),
          olusturulmaTarihi: moment(new Date()).toDate(),
          liste: topluSorguyaGonderilcekListe
        });

        setTopluSorguListeleri(topluSorguListeleri);

        const res = await electron.setTopluSorguListesi(topluSorguListeleri);
        if (res) {
          toast.show("İcra dosyaları toplu sorgu listesine başarıyla eklendi.", "success");
        }

        setVisibleTopluSorguList(false);
        setValueListeName("");
      } else {
        toast.show("Seçilen dosyaların içerisinde icra dosyası bulunmamaktadır.", "error");
      }
    } catch (error) {
      console.error("Error stopping SignalR connection:", error);
    }
  };

  const kayitliListeyeEkleOnClick = async (topluSorguListesi: TopluSorguListesiModel) => {
    try {
      if (!topluSorguListesi) {
        toast.show("Geçerli Bir Liste Seçiniz", "info");
        return;
      }
      const topluSorguyaGonderilcekListe: TopluSorgulanacakDosyaDTO[] = selectedDosyaListesi
        .filter((dosya) => dosya.dosyaTurKod === 35)
        .map((dosya) => ({
          dosyaNo: dosya.dosyaNo,
          dosyaDurumKod: dosya.dosyaDurumKod,
          birimAdi: dosya.birimAdi,
          dosyaTur: dosya.dosyaTur,
          dosyaDurum: dosya.dosyaDurum,
          birimTuru2: dosya.birimTuru2,
          birimTuru3: dosya.birimTuru3,
          isSorgulandi: DosyaSorguDurum.SORGULANMADI
        }));

      if (topluSorguyaGonderilcekListe.length >= 1) {
        const updatedList = topluSorguListeleri.map((liste) => {
          if (liste.key === topluSorguListesi.key) {
            const existingDosyaNos = new Set(liste.liste.map((dosya) => dosya.dosyaNo));

            const uniqueUpdatedListe = [
              ...liste.liste,
              ...topluSorguyaGonderilcekListe.filter((dosya) => !existingDosyaNos.has(dosya.dosyaNo))
            ];
            return {
              name: liste.name,
              key: liste.key,
              olusturulmaTarihi: liste.olusturulmaTarihi,
              liste: uniqueUpdatedListe
            };
          }
          return liste;
        });
        setTopluSorguListeleri(updatedList);

        const res = await electron.setTopluSorguListesi(updatedList);
        if (res) {
          toast.show("Dosyalar seçilen toplu sorgu listesine başarıyla eklendi.", "success");
        }

        setVisibleTopluSorguList(false);
      } else {
        toast.show("Seçilen dosyaların içerisinde icra dosyası bulunmamaktadır.", "error");
      }
    } catch (error) {
      console.error("Error stopping SignalR connection:", error);
    }
  };

  return (
    <>
      <Dialog
        header="Toplu Sorgu Listeleri"
        visible={visibleTopluSorguList}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visibleTopluSorguList) return;
          setVisibleTopluSorguList(false);
        }}
      >
        <div className="flex align-items-center m-2">
          <Checkbox
            onChange={(e) => setCheckedKayitliListeyeEkle(e.checked as boolean)}
            checked={checkedKayitliListeyeEkle}
          ></Checkbox>
          <label htmlFor="ingredient1" className="ml-2">
            Dosyaları Kayıtlı Listeye Ekle
          </label>
        </div>

        {!checkedKayitliListeyeEkle ? (
          <>
            <div className="card flex justify-content-center flex flex-column gap-2">
              <label className="font-semibold">Liste Adını Belirleyin</label>
              <InputText
                type="text"
                placeholder={"Liste Adını Giriniz"}
                id="username"
                value={valueListeName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValueListeName(e.target.value)}
              />
            </div>
            <div className="flex flex-wrap gap-3 align-items-center justify-content-center mt-2">
              <Button
                className=" bottom-0 right-0"
                label="Dosyaları Yeni Bir Listeye Ekle"
                loading={loading}
                iconPos="right"
                onClick={() => yeniListeyeEkleOnClick()}
              />
            </div>
          </>
        ) : (
          <>
            <p className="m-0">Seçtiğiniz dosyaları ekleyeceğiniz listeyi şeçin</p>

            <DataTable
              value={topluSorguListeleri || []}
              showGridlines
              stripedRows
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              tableClassName="border-round-3xl"
              emptyMessage="Hiç Toplu Sorgu Listesi Bulunamadı"
              globalFilterFields={["name", "olusturulmaTarihi"]}
              loading={loading}
              columnResizeMode="expand"
              selectionMode={"single"}
              selection={selectedTopluSorguListesi}
              onSelectionChange={(e) => setSelectedTopluSorguListesi(e.value as TopluSorguListesiModel)}
              sortField="olusturulmaTarihi"
              sortOrder={-1}
            >
              <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
              <Column
                header="Liste Adı"
                field="name"
                sortable
                filter
                filterField="name"
                filterPlaceholder="Ada göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Ada göre filtreleyin</div>}
              />
              <Column
                header="Oluşturulma Tarihi"
                field="olusturulmaTarihi"
                sortable
                //body={(rowData) => moment(rowData.baslamaTarihi).format(process.env.REACT_APP_DATE_FORMAT)}
                body={(rowData) => moment(rowData.olusturulmaTarihi).format("DD.MM.YYYY HH:mm")}
                filter
                filterField="olusturulmaTarihi"
                filterPlaceholder="Tarihe göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterElement={Filter.DateFilterTemplate}
                filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tarihe göre filtreleyin</div>}
              />
            </DataTable>

            <div className="flex flex-wrap gap-3 align-items-center justify-content-center mt-2">
              <Button
                className=" bottom-0 right-0"
                label="Dosyaları Seçilen Listeye Ekle"
                loading={loading}
                iconPos="right"
                onClick={() => kayitliListeyeEkleOnClick(selectedTopluSorguListesi as TopluSorguListesiModel)}
              />
            </div>
          </>
        )}
      </Dialog>

      <div className="card">
        <div className="flex flex-column gap-4">
          <Fieldset legend="Dosya Sorgulama">
            <div className="flex flex-column gap-4">
              <div className="p-fluid flex flex-wrap align-items-center">
                <div className="flex-grow-1 ">
                  <Chips
                    inputRef={inputRef}
                    value={kelimeIleAramaDeger}
                    onChange={(e: ChipsChangeEvent) => {
                      setKelimeIleAramaDeger(e.value as string[]);
                      formikKelimeArama.setFieldValue("phrases", e.value);
                      formikKelimeArama.submitForm();
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder="Örnek: Dosya Numarası, Birim Adı ..."
                  />
                </div>

                <div className="flex flex-column ml-2">
                  <label className="font-semibold ml-2 text-center">Dosya Durumu</label>

                  <div className="flex align-items-center h-full">
                    <div className="flex align-items-center m-2">
                      <RadioButton
                        inputId="dosyaDurum3"
                        name="durumKodSearch"
                        value="Acik"
                        onChange={(e: RadioButtonChangeEvent) => {
                          formikKelimeArama.setFieldValue("dosyaDurumKod", 0);
                        }}
                        checked={formikKelimeArama.values.dosyaDurumKod === 0}
                      />
                      <label htmlFor="dosyaDurum3" className="ml-2">
                        Açık
                      </label>
                    </div>
                    <div className="flex align-items-center m-2">
                      <RadioButton
                        inputId="dosyaDurum4"
                        name="durumKodSearch"
                        value="Kapali"
                        onChange={(e: RadioButtonChangeEvent) => {
                          formikKelimeArama.setFieldValue("dosyaDurumKod", 1);
                        }}
                        checked={formikKelimeArama.values.dosyaDurumKod === 1}
                      />
                      <label htmlFor="dosyaDurum4" className="ml-2">
                        Kapalı
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-2">
                  <Button
                    className="w-full md:w-auto"
                    label="Sorgula"
                    loading={loading}
                    iconPos="right"
                    onClick={dosyaHandlerKelimeArama}
                  />
                </div>
              </div>

              <Accordion activeIndex={1}>
                <AccordionTab header="Detaylı Arama">
                  <>
                    <div className="flex flex-column md:flex-row gap-4 justify-content-between w-full">
                      <div className="flex flex-column w-full gap-2">
                        <label className="font-semibold">Yargı Türü</label>
                        <Dropdown
                          value={formik.values.birimTuru3}
                          onChange={async (e: DropdownChangeEvent) => {
                            setBlocked(true);
                            if (!hub.getBuroSettings().connectedSessionLawyerName) {
                              toast.show("Lütfen önce Uyap'a bağlanınız.", "error");
                              setBlocked(false);
                              return;
                            }
                            getYargıBirimleri(e.value);
                            formik.resetForm();
                            formik.setFieldValue("birimTuru3", e.value);
                            setYargıTuru(e.value);
                            if (e.value === "3") {
                              getIller();
                              //FORMİK SIFIRLAMA
                            }
                            setBlocked(false);
                          }}
                          options={DropdownDatas.GetYargıTurleri()}
                          optionLabel="label"
                          optionValue="value"
                          placeholder="Yargı Türü Seç"
                          className={classNames("w-full mt-2", {
                            "p-invalid": FormikValueIsValid(formik, "birimTuru3")
                          })}
                          //className="w-full"
                        />
                        {FormikValueIsValid(formik, "birimTuru3") && (
                          <div className="p-error mt-3">{formik.errors.birimTuru3}</div>
                        )}
                      </div>
                      {yargıTuru !== "3" && (
                        <div className="flex flex-column w-full gap-2">
                          <label className="font-semibold">Yargı Birimi</label>
                          <Dropdown
                            value={formik.values.birimTuru2}
                            onChange={(e: DropdownChangeEvent) => {
                              formik.setFieldValue("birimTuru2", e.value);
                            }}
                            options={yargiBirimleri}
                            optionLabel="kod"
                            optionValue="tablo"
                            placeholder="Yargı Birimi Seç"
                            className={classNames("w-full mt-2", {
                              "p-invalid": FormikValueIsValid(formik, "birimTuru2")
                            })}
                          />
                          {FormikValueIsValid(formik, "birimTuru2") && (
                            <div className="p-error mt-3">{formik.errors.birimTuru2}</div>
                          )}
                        </div>
                      )}

                      <div className="flex flex-column w-full gap-2 align-items-center">
                        <label className="font-semibold">Dosya Durumu</label>

                        <div className="flex align-items-center h-full">
                          <div className="flex align-items-center m-2">
                            <RadioButton
                              inputId="dosyaDurum1"
                              name="durumKod"
                              value="Acik"
                              onChange={(e: RadioButtonChangeEvent) => {
                                //setDosyaDurumu(e.value);
                                formik.setFieldValue("dosyaDurumKod", 0);
                              }}
                              checked={formik.values.dosyaDurumKod === 0}
                            />
                            <label htmlFor="dosyaDurum1" className="ml-2">
                              Açık
                            </label>
                          </div>
                          <div className="flex align-items-center m-2">
                            <RadioButton
                              inputId="dosyaDurum2"
                              name="durumKod"
                              value="Kapali"
                              onChange={(e: RadioButtonChangeEvent) => {
                                //setDosyaDurumu(e.value);
                                formik.setFieldValue("dosyaDurumKod", 1);
                              }}
                              checked={formik.values.dosyaDurumKod === 1}
                            />
                            <label htmlFor="dosyaDurum2" className="ml-2">
                              Kapalı
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {yargıTuru === "3" ? (
                      <div>
                        <div className="flex flex-column md:flex-row gap-4 justify-content-between w-full">
                          <div className="flex flex-column w-full gap-2">
                            <label className="font-semibold">İl</label>
                            <Dropdown
                              value={formik.values.il}
                              onChange={(e: DropdownChangeEvent) => {
                                setBlocked(true);

                                formik.setFieldValue("il", e.value);
                                getCbsYargıBirimleri(e.value);
                                setBlocked(false);
                              }}
                              options={iller}
                              optionLabel="ad"
                              optionValue="il"
                              placeholder="Seçiniz"
                              className={classNames("w-full mt-2", {
                                "p-invalid": FormikValueIsValid(formik, "il")
                              })}
                            />
                          </div>

                          <div className="flex flex-column w-full gap-2">
                            <label className="font-semibold">Yargı Birimi</label>
                            <Dropdown
                              value={formik.values.birimTuru2}
                              onChange={(e: DropdownChangeEvent) => {
                                formik.setFieldValue("birimTuru2", e.value);
                              }}
                              options={cbsYargıBirimleri}
                              optionLabel="birimAdi"
                              optionValue="birimId"
                              placeholder="Yargı Birimi Seç"
                              className={classNames("w-full mt-2", {
                                "p-invalid": FormikValueIsValid(formik, "birimTuru2")
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="flex flex-column md:flex-row gap-4 justify-content-between w-full mt-2">
                          <div className="flex flex-column w-full gap-2">
                            <label className="font-semibold">Dosya Yılı</label>
                            <Dropdown
                              value={formik.values.dosyaYil}
                              onChange={(e: DropdownChangeEvent) => {
                                formik.setFieldValue("dosyaYil", e.value);
                                //setYear(e.value);
                              }}
                              showClear
                              options={years}
                              optionLabel="name"
                              optionValue="value"
                              placeholder="Dosya Yıl"
                              className={classNames("w-full mt-2", {
                                "p-invalid": FormikValueIsValid(formik, "dosyaYil")
                              })}
                            />
                          </div>
                          <div className="flex flex-column w-full gap-2">
                            <label className="font-semibold">Dosya No</label>
                            <InputNumber
                              value={formik.values.dosyaSira ? parseInt(formik.values.dosyaSira) : null}
                              onValueChange={(e) =>
                                formik.setFieldValue("dosyaSira", e.value ? e.value.toString() : "")
                              }
                              placeholder="Dosya No"
                              className={classNames("w-full mt-2", {
                                "p-invalid": FormikValueIsValid(formik, "dosyaSira")
                              })}
                              useGrouping={false}
                            />
                          </div>
                          <div className="flex flex-column w-full gap-2 align-items-center">
                            <div className="flex align-items-center h-full">
                              <div className="flex align-items-center m-2 ">
                                <Button
                                  //value={value}
                                  className="border-round"
                                  onClick={() => {
                                    if (aramaTipi === "Tarih") {
                                      setVisibleTarihAramaDialog(false);
                                      setAramaTipi("");
                                    } else {
                                      setVisibleKisiAramaDialog(false);
                                      setVisibleTarihAramaDialog(true);
                                      setAramaTipi("Tarih");
                                    }
                                  }}
                                  value={"Tarih ile Ara"}
                                  label="Tarih ile Ara"
                                  //options={["Tarih ile Ara"]}
                                />
                              </div>
                              <div className="flex align-items-center m-2">
                                <Button
                                  //value={value}
                                  onClick={() => {
                                    if (aramaTipi === "Kisi") {
                                      setVisibleKisiAramaDialog(false);
                                      setAramaTipi("");
                                    } else {
                                      setVisibleKisiAramaDialog(true);
                                      setVisibleTarihAramaDialog(false);
                                      setVisibleKurumAramaDialog(false);
                                      setAramaTipi("Kisi");
                                    }
                                  }}
                                  value={"Kişi ile Ara"}
                                  label="Kişi ile Ara"
                                  //options={["Kişi ile Ara"]}
                                />
                              </div>
                              <div className="flex align-items-center m-2">
                                <Button
                                  //value={value}
                                  onClick={() => {
                                    if (aramaTipi === "Kurum") {
                                      setVisibleKurumAramaDialog(false);
                                      setAramaTipi("");
                                    } else {
                                      setVisibleKisiAramaDialog(false);
                                      setVisibleTarihAramaDialog(false);
                                      setVisibleKurumAramaDialog(true);
                                      setAramaTipi("Kurum");
                                    }
                                  }}
                                  value={"Kurum Adı ile Ara"}
                                  label="Kurum Adı ile Ara"
                                  //options={["Kurum Adı ile Ara"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3">
                          {aramaTipi === "Kisi" && visibleKisiAramaDialog ? (
                            <div className="flex flex-column md:flex-row gap-4 justify-content-between w-full">
                              <div className="flex flex-column md:flex-row gap-4 justify-content-between w-full">
                                <div className="flex flex-column w-full gap-2">
                                  <label className="font-semibold">Adı</label>
                                  <InputText
                                    id="adi"
                                    type="text"
                                    placeholder={"Kişi Adını Giriniz"}
                                    className={classNames("w-full  mt-2", {
                                      "p-invalid": FormikValueIsValid(formik, "adi")
                                    })}
                                    value={formik.values.adi}
                                    onChange={(e) => formik.setFieldValue("adi", e.target.value)}
                                  />
                                </div>
                                <div className="flex flex-column w-full gap-2">
                                  <label className="font-semibold">Soyadı</label>
                                  <InputText
                                    value={formik.values.soyadi}
                                    onChange={(e) => formik.setFieldValue("soyadi", e.target.value)}
                                    placeholder="Soyadını Giriniz"
                                    className={classNames("w-full mt-2", {
                                      "p-invalid": FormikValueIsValid(formik, "soyadi")
                                    })}
                                  />
                                </div>
                                <div className="flex flex-column w-full gap-2">
                                  <label className="font-semibold">Tc Kimlik Numarası</label>
                                  <InputMask
                                    value={formik.values.tcKimlikNo}
                                    placeholder="Tc Kimlik Numarası Giriniz"
                                    onChange={(e) => formik.setFieldValue("tcKimlikNo", e.target.value)}
                                    mask="99999999999"
                                    className={classNames("w-full mt-2", {
                                      "p-invalid": FormikValueIsValid(formik, "tcKimlikNo")
                                    })}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : aramaTipi === "Kurum" && visibleKurumAramaDialog ? (
                            <div className="flex flex-column md:flex-row gap-4 justify-content-between w-full">
                              <div className="flex flex-column md:flex-row gap-4 justify-content-between w-full">
                                <div className="flex flex-column w-full gap-2">
                                  <label className="font-semibold">Kurum Adı</label>
                                  <InputText
                                    value={formik.values.kurumAdi}
                                    onChange={(e) => {
                                      formik.setFieldValue("kurumAdi", e.target.value);
                                    }}
                                    placeholder="Kurum Adını Giriniz"
                                    className={classNames("w-full mt-2", {
                                      "p-invalid": FormikValueIsValid(formik, "kurumAdi")
                                    })}
                                  />
                                </div>
                                <div className="flex flex-column w-full gap-2">
                                  <label className="font-semibold">Mersis Numarası</label>
                                  <InputNumber
                                    value={formik.values.mersisNo}
                                    onChange={(e) => formik.setFieldValue("mersisNo", e.value)}
                                    useGrouping={false}
                                    //mask="99999999999"
                                    //type="number"
                                    placeholder="Mersis No Giriniz"
                                    className={classNames("w-full mt-2", {
                                      "p-invalid": FormikValueIsValid(formik, "mersisNo")
                                    })}
                                  />
                                </div>
                                <div className="flex flex-column w-full gap-2">
                                  <label className="font-semibold">Vergi No</label>
                                  <InputMask
                                    value={formik.values.vergiNo !== undefined ? formik.values.vergiNo.toString() : ""}
                                    placeholder="Vergi No Giriniz"
                                    onChange={(e) => {
                                      formik.setFieldValue("vergiNo", e.target.value);
                                    }}
                                    mask="9999999999"
                                    className={classNames("w-full mt-2", {
                                      "p-invalid": FormikValueIsValid(formik, "vergiNo")
                                    })}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : aramaTipi === "Tarih" && visibleTarihAramaDialog ? (
                            <div className="flex flex-column md:flex-row gap-4 justify-content-between w-full">
                              <div className="flex flex-column w-full gap-2">
                                <label className="font-semibold">Başlanguç Tarihi</label>
                                <Calendar
                                  //value={formik.values.dosyaAcilisTarihiStart ? new Date(formik.values.dosyaAcilisTarihiStart): null}
                                  value={formik.values.dosyaAcilisTarihiStart}
                                  onChange={(e: any) => {
                                    formik.setFieldValue("dosyaAcilisTarihiStart", e.value);
                                    formik.setFieldValue("dosyaKapanisTarihiStart", e.value);
                                  }}
                                  //locale="tr"
                                  placeholder="dd.mm.yyyy"
                                  dateFormat="dd.mm.yy"
                                  mask="99/99/9999"
                                  showIcon
                                  className={classNames("w-full mt-2", {
                                    "p-invalid": FormikValueIsValid(formik, "dosyaAcilisTarihiStart")
                                  })}
                                  locale="tr"
                                />
                              </div>
                              <div className="flex flex-column w-full gap-2">
                                <label className="font-semibold">Bitiş Tarihi</label>
                                <Calendar
                                  value={formik.values.dosyaAcilisTarihiEnd}
                                  onChange={(e: any) => {
                                    formik.setFieldValue("dosyaAcilisTarihiEnd", e.value);
                                    formik.setFieldValue("dosyaKapanisTarihiEnd", e.value);
                                  }}
                                  locale="tr"
                                  placeholder="dd.mm.yyyy"
                                  dateFormat="dd.mm.yy"
                                  mask="99/99/9999"
                                  showIcon
                                  className={classNames("w-full mt-2", {
                                    "p-invalid": FormikValueIsValid(formik, "dosyaAcilisTarihiEnd")
                                  })}
                                />
                                {FormikValueIsValid(formik, "dosyaAcilisTarihiEnd") && (
                                  <div className="p-error mt-3">{formik.errors.dosyaAcilisTarihiEnd}</div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="flex mt-2">
                      <Button
                        className="w-full md:w-auto"
                        label="Detaylı Sorgula"
                        loading={loading}
                        iconPos="right"
                        onClick={dosyaHandler}
                      />
                    </div>
                  </>
                </AccordionTab>
              </Accordion>
            </div>
          </Fieldset>

          <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

          <DataTable
            ref={dt}
            value={processedData}
            showGridlines
            stripedRows
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            filters={filters}
            tableClassName="border-round-3xl"
            emptyMessage="Hiç Dosya Bulunamadı"
            loading={loading}
            globalFilterFields={["birimAdi", "dosyaNo", "dosyaTur", "dosyaDurum", "dosyaAcilisTarihiFormated"]}
            header={CreateHeader("Dosyalar", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
            columnResizeMode="expand"
            selectionMode={"multiple"}
            selection={selectedDosyaListesi}
            onSelectionChange={(e) => setSelectedDosyaListesi(e.value)}
          >
            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>

            <Column body={actionBodyTemplate} exportable={false} style={{ width: "8rem" }}></Column>
            <Column
              field="birimAdi"
              header="Birim Adı"
              sortable
              filter
              filterField="birimAdi"
              filterPlaceholder="Birim Adına göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
            />
            <Column
              field="dosyaNo"
              header="Dosya No"
              sortable
              filter
              filterField="dosyaNo"
              filterPlaceholder="Dosya No'ya göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
            />
            <Column
              field="dosyaTur"
              header="Dosya Türü"
              sortable
              filter
              filterField="dosyaTur"
              filterPlaceholder="Dosya Türüne göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Türüne göre filtreleyin</div>}
            />
            <Column
              field="dosyaDurum"
              header="Dosya Durumu"
              sortable
              body={(rowData) => (
                <div className="flex justify-content-center align-items-center p-0 m-0">
                  {rowData.dosyaDurum === "Açık" ? (
                    <div className="p-0 flex align-items-center">
                      <div className="px-5 py-1 bg-green-100 text-green-900 border-round font-medium">
                        {`${rowData.dosyaDurum}`}
                      </div>
                    </div>
                  ) : (
                    <div className="p-0 flex align-items-center">
                      <div className="px-2 py-1 bg-blue-100 text-blue-900 border-round font-medium text-center">
                        {`${rowData.dosyaDurum}`}
                      </div>
                    </div>
                  )}
                </div>
              )}
              filter
              filterField="dosyaDurum"
              filterPlaceholder="Dosya Durumuna göre arayın"
              filterClear={Filter.FilterClearTemplate}
              filterApply={Filter.FilterApplyTemplate}
              filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
            />
            <Column
              field="dosyaAcilisTarihiFormated"
              header="Açılış Tarihi"
              sortable
              body={(rowData) => (
                <span>
                  {rowData.dosyaAcilisTarihiFormated
                    ? moment(rowData.dosyaAcilisTarihiFormated).format(process.env.REACT_APP_DATE_FORMAT)
                    : ""}
                </span>
              )}
              filterField="dosyaAcilisTarihiFormated"
              filter
              filterPlaceholder="Açılış Tarihine göre arayın"
              //filterClear={Filter.FilterClearTemplate}
              //filterApply={Filter.FilterApplyTemplate}
              filterElement={Filter.DateFilterTemplate}
              filterMatchMode={FilterMatchMode.DATE_IS}
              filterFooter={() => {
                return <div className="px-3 pt-0 pb-3 text-center">Açılış Tarihine göre filtreleyin</div>;
              }}
            />
          </DataTable>
        </div>

        {selectedDosya && (
          <DosyaDetaylariDialog
            visible={visibleDosyaDetayleriDialog}
            handleSetVisibleDosyaDetayleriDialog={handleSetVisibleDosyaDetayleriDialog}
            dosyaId={selectedDosya.dosyaId}
            dosyaTur={selectedDosya.dosyaTur}
            dosyaNo={selectedDosya.dosyaNo}
            birimAdi={selectedDosya.birimAdi}
            dosyaTurKod={selectedDosya.dosyaTurKod}
          />
        )}
      </div>
    </>
  );
};

export default DosyaSorgulama;
