import { FilterOperator, FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import {
  ColumnFilterClearTemplateOptions,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { DataTableFilterMeta } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";

export const Filter = {
  FilterClearTemplate(options: ColumnFilterClearTemplateOptions) {
    return (
      <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} severity="secondary"></Button>
    );
  },

  FilterApplyTemplate(options: ColumnFilterApplyTemplateOptions) {
    return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} severity="success"></Button>;
  },

  DateFilterTemplate(options: ColumnFilterElementTemplateOptions) {
    return (
      <Calendar
        value={options.value}
        onChange={(e: any) => options.filterCallback(e.value)}
        dateFormat="dd.mm.yy"
        placeholder="dd/mm/yyyy"
        mask="99.99.9999"
        locale="tr"
      />
    );
  }
};

export const commonFilterContains = {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
};

export const commonFilterDateIs = {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
};

export const CreateHeader = (
  title: string,
  filterValue: string,
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  clearFilter: () => void,
  exportCSV?: () => void,
  exportExcel?: () => void
) => (
  <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
    <h4 className="m-0">{title}</h4>
    <div className="flex gap-3">
      <span className="p-input-icon-left">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText type="search" placeholder="Ara..." value={filterValue} onChange={onFilterChange} />
        </IconField>
      </span>
      <Button type="button" icon="pi pi-filter-slash" label="Temizle" outlined onClick={clearFilter} />
      {exportCSV && (
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={exportCSV}
          data-pr-tooltip="CSV"
        />
      )}
      {exportExcel && (
        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      )}
    </div>
  </div>
);
