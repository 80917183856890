export interface HubStoreModel {
  reservedUyapSession?: SessionModel;
  reservedSessionLawyerName?: string;
  amISharingUyapSession: boolean;
  connectedSessionLawyerName: string;
  cardPassword: string;
  sessionPin: string;
  maxCookieSize: number;
  keys: string[];
  firstSessionProcessIsStarted: boolean;
}

export enum SessionNumber {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5
}

export interface BuroSettingsModel {
  lawyerSessions: LawyerSessionModel[];
}

export interface LawyerSessionModel {
  lawyerName: string;
  maxCookieSize: number;
  sessions: SessionModel[];
  userSharingSession: UserSharingSessionModel;
}

export interface UserSharingSessionModel {
  userName: string;
  connectionId: string;
}

export interface SessionModel {
  cookie: string;
  sessionNumber: SessionNumber;
  isSessionReserved: boolean;
  reservedUserConnectionId: string;
}

export interface UpdateUserSharingSessionDto {
  userName: string;
  lawyerName: string;
  maxCookieSize: number;
}

export interface UpdateSessionDto {
  cookie: string;
  sessionNumber: SessionNumber;
  lawyerName: string;
}

// Elecron Dtos
export interface ElectronHubResponseDto {
  isSuccessful: boolean;
  cookie: string;
  errorMessage: string;
  sessionNumber: SessionNumber;
  lawyerName: string;
  function: ElectronFunction;
}

export interface ReactToElectronDto {
  function: ElectronFunction;
  tc: string;
  cardPassword: string;
  sessionNumber: SessionNumber;
}

export enum ElectronFunction {
  CreateUyapSession = 1,
  GetLawyerName = 2
}
