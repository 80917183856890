import React from "react";
import ReactDOM from "react-dom/client";
import "./lib/i18n";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./assets/styles/layout.scss";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import RootLayout from "./layout/RootLayout";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRouter from "./lib/AppRouter";
import { AuthProvider } from "./utils/auth";
import { AxiosProvider } from "./utils/axios";
import { ToastProvider } from "./utils/toast";
import { HubProvider } from "./utils/buro-hub";
import { UyapAxiosContextProvider } from "./services/uyap-sorgulari/base/uyap.axios";
import { EvrakProvider } from "./utils/evrakContext";
import { ElectronProvider } from "./utils/electron-connection";
import { TopluSorguIslemlerProvider } from "./utils/toplu-sorgu-islemler";
import { BasketProvider } from "./utils/basket-provider";
import { VatandasPortalProvider } from "./utils/vatandasPortalContext";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "api/";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry(failureCount, error) {
        if (error.message === "Oturum yetersiz." && failureCount < 1) {
          return true;
        }
        return false;
      },
      gcTime: 1000 * 60 * 121 // 121 minutes
      // staleTime: 1000 * 60 * 5, // 5 minutes
    }
  }
});

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ToastProvider>
          <ElectronProvider>
            <AxiosProvider>
              <HubProvider>
                <UyapAxiosContextProvider>
                  <EvrakProvider>
                    <VatandasPortalProvider>
                      <TopluSorguIslemlerProvider>
                        <BasketProvider>
                          <RootLayout>
                            <AppRouter />
                          </RootLayout>
                        </BasketProvider>
                      </TopluSorguIslemlerProvider>
                    </VatandasPortalProvider>
                  </EvrakProvider>
                </UyapAxiosContextProvider>
              </HubProvider>
            </AxiosProvider>
          </ElectronProvider>
        </ToastProvider>
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
