import { useContext, useEffect } from "react";
import SorguBilgileri from "../SorguBilgileri";
import { useHub } from "../../../../../utils/buro-hub";
import uyapService from "../../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { LayoutContext } from "../../../../../layout/context/layoutcontext";

const SgkCalisani = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);

  const {
    data: SgkCalisaniResponse,
    refetch: refetchSgkCalisani,
    isLoading: sgkCalisaniResponseLoading
  } = useQuery({
    queryKey: ["SgkCalisani", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetSskCalisanBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(sgkCalisaniResponseLoading);
  }, [sgkCalisaniResponseLoading]);

  return (
    <div>
      {SgkCalisaniResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {SgkCalisaniResponse.error}</div>}

      {SgkCalisaniResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={SgkCalisaniResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={SgkCalisaniResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={SgkCalisaniResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={SgkCalisaniResponse?.result?.sorgulayanBilgileri}
          />
          {/* {SgkCalisaniResponse?.result?.sorguSonucDVO?.isYeriBilgileri.data.sonuc.basarili === "false" ? (
            <div className="card p-2 lg:p-5 mt-3">
              {SgkCalisaniResponse?.result?.sorguSonucDVO?.isYeriBilgileri.data.sonuc.mesaj.toString()}
            </div>
          ) : (
            <div className="card p-2 lg:p-5 mt-3">
              <div className="grid">
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> İş Yeri Ünvanı </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi??.isyeriUnvan ?? ""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> İş Yeri Adresi </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.isyeriAdresi ?? ""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> İş Yeri İl </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay.ilAdi ?? ""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> İş Yeri Sicil </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.isyeriSicil ?? ""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> İlgili İş Yerindeki Kaydının Sonlanma Dönemi </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.donemYilAy ?? ""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> Durum </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.durumKod ?? ""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> Sicil No </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgi.tescilKaydi4AList[0].sicilNo ?? ""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> İlk İşe Giriş Tarihi </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.ilkIseGirisTarihi ?? ""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> Son İşe Giriş Tarihi </div>
                    <div className="">{` : `}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3 ">
                    <div className="font-bold w-12rem"> Son İşten Çıkış Tarihi </div>
                    <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.istenCikisTarihi ?? ""}`}</div>
                    </div>
                </div>

                {SgkCalisaniResponse?.result?.sorguSonucDVO?.isYeriBilgileri.data.sonuc.isyeriTescil4AOzetArray?.map(
                  (item) => (
                    <>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3 ">
                          <div className="font-bold w-12rem"> Mernis No TC Kimlik No </div>{" "}
                          <div className="">{` : ${item.teMernisNo ?? ""}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3 ">
                          <div className="font-bold w-12rem"> Vergi No </div>
                          <div className="">{` : ${item.teVergiNo ?? ""}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3 ">
                          <div className="font-bold w-12rem"> Alt İş Yeri Ünvanı </div>
                          <div className="">{` : ${item.unvan ?? ""}`}</div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          )} */}
          <div className="card p-2 lg:p-5 mt-3">
            <div className="grid">
              {SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.hataKodu !== 0 ? (
                <div className="card p-2 lg:p-5 mt-3">
                  {SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.hataMesaji.toString()}
                </div>
              ) : (
                <>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> İş Yeri Ünvanı </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.isyeriUnvan ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> İş Yeri Adresi </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.isyeriAdresi ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> İş Yeri İl </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay.ilAdi ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> İş Yeri Sicil </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.isyeriSicil ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> İlgili İş Yerindeki Kaydının Sonlanma Dönemi </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.donemYilAy ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Durum </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.durumKod ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Sicil No </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgi?.tescilKaydi4AList?.[0]?.sicilNo ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> İlk İşe Giriş Tarihi </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.ilkIseGirisTarihi ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Son İşe Giriş Tarihi </div>
                      <div className="">{` : `}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3 ">
                      <div className="font-bold w-12rem"> Son İşten Çıkış Tarihi </div>
                      <div className="">{` : ${SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.istenCikisTarihi ?? ""}`}</div>
                    </div>
                  </div>
                </>
              )}

              {SgkCalisaniResponse?.result?.sorguSonucDVO?.isYeriBilgileri?.data?.sonuc?.basarili === "false" ? (
                <div className="card p-2 lg:p-5 mt-3">
                  {SgkCalisaniResponse?.result?.sorguSonucDVO?.isYeriBilgileri.data.sonuc.mesaj.toString()}
                </div>
              ) : (
                <>
                  {SgkCalisaniResponse?.result?.sorguSonucDVO?.isYeriBilgileri?.data?.sonuc?.isyeriTescil4AOzetArray?.map(
                    (item) => (
                      <>
                        <div className="col-12 md:col-6">
                          <div className="flex gap-3 ">
                            <div className="font-bold w-12rem"> Mernis No TC Kimlik No </div>
                            <div className="">{` : ${item.teMernisNo ?? ""}`}</div>
                          </div>
                        </div>
                        <div className="col-12 md:col-6">
                          <div className="flex gap-3 ">
                            <div className="font-bold w-12rem"> Vergi No </div>
                            <div className="">{` : ${item.teVergiNo ?? ""}`}</div>
                          </div>
                        </div>
                        <div className="col-12 md:col-6">
                          <div className="flex gap-3 ">
                            <div className="font-bold w-12rem"> Alt İş Yeri Ünvanı </div>
                            <div className="">{` : ${item.unvan ?? ""}`}</div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </>
              )}

              {!SgkCalisaniResponse.result.sorguSonucDVO?.sskBilgi?.tescilKaydi4AList?.[0]?.odemeBilgileri ||
              SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgi?.tescilKaydi4AList?.[0]?.odemeBilgileri[0]?.hataKodu !==
                0 ? (
                <></>
              ) : (
                <>
                  <div>Ödeme Bilgileri</div>
                  {SgkCalisaniResponse?.result?.sorguSonucDVO?.sskBilgi?.tescilKaydi4AList[0].odemeBilgileri?.map(
                    (item) => (
                      <>
                        <div className="col-12 md:col-6">
                          <div className="flex gap-3 ">
                            <div className="font-bold w-12rem"> Ödeme </div>
                            <div className="">{` : ${item.odeme ?? ""}`}</div>
                          </div>
                        </div>
                        <div className="col-12 md:col-6">
                          <div className="flex gap-3 ">
                            <div className="font-bold w-12rem"> Aylık Bağlanma Tarihi </div>
                            <div className="">{` : ${""}`}</div>
                          </div>
                        </div>
                        <div className="col-12 md:col-6">
                          <div className="flex gap-3 ">
                            <div className="font-bold w-12rem"> Durum </div>
                            <div className="">{` : ${item.odDurum ?? ""}`}</div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SgkCalisani;
