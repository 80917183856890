import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Filter } from "../../filter/base-filter";

const BagkurEmeklisiDataTable = (props: { tableData: any }) => {
  const { tableData } = props;

  return (
    <div>
      <DataTable
        value={tableData || []}
        dataKey="id"
        showGridlines
        rows={10}
        emptyMessage="Bağkur Emeklisi Bilgisi bulunamadı."
        columnResizeMode="expand"
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filter
          filterField="dosyaNo"
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filter
          filterField="alacakliAdi"
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filter
          filterField="borcluAdi"
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filter
          filterField="borcluTCVKNo"
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filter
          filterField="dosyaDurumu"
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="sonuc"
          sortable
          filter
          filterField="sonuc"
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default BagkurEmeklisiDataTable;
