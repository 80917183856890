export enum Permission {
  SubUserDefault = "SubUserDefault",
  CreateSubUser = "CreateSubUser",
  UpdateSubUser = "UpdateSubUser",
  DeleteSubUser = "DeleteSubUser",
  GetAllUserEmail = "GetAllUserEmail",
  SendIcsEmail = "SendIcsEmail",

  AccountDefault = "AccountDefault",
  AccountUpdate = "AccountUpdate",
  AccountDelete = "AccountDelete",
  EmailChange = "EmailChange",
  ChangePassword = "ChangePassword",

  RoleDefault = "RoleDefault",
  RoleCreate = "RoleCreate",
  RoleUpdate = "RoleUpdate",
  RoleDelete = "RoleDelete",

  MuvekkilDefault = "MuvekkilDefault",
  MuvekkilCreate = "MuvekkilCreate",
  MuvekkilUpdate = "MuvekkilUpdate",
  MuvekkilDelete = "MuvekkilDelete",

  KarsiYanDefault = "KarsiYanDefault",
  KarsiYanCreate = "KarsiYanCreate",
  KarsiYanUpdate = "KarsiYanUpdate",
  KarsiYanDelete = "KarsiYanDelete",

  IcraFoyDefault = "IcraFoyDefault",
  IcraFoyCreate = "IcraFoyCreate",
  IcraFoyUpdate = "IcraFoyUpdate",
  IcraFoyDelete = "IcraFoyDelete",

  DosyaSorgulamaDefault = "DosyaSorgulamaDefault",
  TopluSorgulamaDefault = "TopluSorgulamaDefault",
  DurusmaSorgulamaDefault = "DurusmaSorgulamaDefault",
  SikKullanilanlarDefault = "SikKullanilanlarDefault",

  KlasorDefault = "KlasorDefault",
  KlasorCreate = "KlasorCreate",
  KlasorUpdate = "KlasorUpdate",
  KlasorDelete = "KlasorDelete",

  DosyaDurumuDefault = "DosyaDurumuDefault",
  DosyaDurumuCreate = "DosyaDurumuCreate",
  DosyaDurumuUpdate = "DosyaDurumuUpdate",
  DosyaDurumuDelete = "DosyaDurumuDelete",

  DigerAyrintiDefault = "DigerAyrintiDefault",
  DigerAyrintiCreate = "DigerAyrintiCreate",
  DigerAyrintiUpdate = "DigerAyrintiUpdate",
  DigerAyrintiDelete = "DigerAyrintiDelete",

  TalepBilgisiDefault = "TalepBilgisiDefault",
  TalepBilgisiCreate = "TalepBilgisiCreate",
  TalepBilgisiUpdate = "TalepBilgisiUpdate",
  TalepBilgisiDelete = "TalepBilgisiDelete",
  TalepBilgisiRetUyarisiKaldir = "TalepBilgisiRetUyarisiKaldir",

  TarihceKullaniciNotuDefault = "TarihceKullaniciNotuDefault",
  TarihceKullaniciNotuCreate = "TarihceKullaniciNotuCreate",
  TarihceKullaniciNotuUpdate = "TarihceKullaniciNotuUpdate",
  TarihceKullaniciNotuDelete = "TarihceKullaniciNotuDelete",

  TebligatBilgisiDefault = "TebligatBilgisiDefault",
  TebligatBilgisiCreate = "TebligatBilgisiCreate",
  TebligatBilgisiUpdate = "TebligatBilgisiUpdate",
  TebligatBilgisiDelete = "TebligatBilgisiDelete",
  TebligatBilgisiRetUyarisiKaldir = "TebligatBilgisiRetUyarisiKaldir",

  TalimatDosyasiDefault = "TalimatDosyasiDefault",
  TalimatDosyasiCreate = "TalimatDosyasiCreate",
  TalimatDosyasiUpdate = "TalimatDosyasiUpdate",
  TalimatDosyasiDelete = "TalimatDosyasiDelete",

  BelgeArsiviDefault = "BelgeArsiviDefault",
  BelgeArsiviCreate = "BelgeArsiviCreate",
  BelgeArsiviUpdate = "BelgeArsiviUpdate",
  BelgeArsiviDelete = "BelgeArsiviDelete",

  AddressDefault = "AddressDefault",
  AddressCreate = "AddressCreate",
  AddressUpdate = "AddressUpdate",
  AddressDelete = "AddressDelete",

  PhoneDefault = "PhoneDefault",
  PhoneCreate = "PhoneCreate",
  PhoneUpdate = "PhoneUpdate",
  PhoneDelete = "PhoneDelete",

  EmailDefault = "EmailDefault",
  EmailCreate = "EmailCreate",
  EmailUpdate = "EmailUpdate",
  EmailDelete = "EmailDelete",

  AlacakKalemiDefault = "AlacakKalemiDefault",
  AlacakKalemiCreate = "AlacakKalemiCreate",
  AlacakKalemiUpdate = "AlacakKalemiUpdate",
  AlacakKalemiDelete = "AlacakKalemiDelete",

  MasrafDefault = "MasrafDefault",
  MasrafCreate = "MasrafCreate",
  MasrafUpdate = "MasrafUpdate",
  MasrafDelete = "MasrafDelete",

  TahsilatDefault = "TahsilatDefault",
  TahsilatCreate = "TahsilatCreate",
  TahsilatUpdate = "TahsilatUpdate",
  TahsilatDelete = "TahsilatDelete",

  KampanyaMektubuDefault = "KampanyaMektubuDefault",
  KampanyaMektubuCreate = "KampanyaMektubuCreate",
  KampanyaMektubuUpdate = "KampanyaMektubuUpdate",
  KampanyaMektubuDelete = "KampanyaMektubuDelete",

  UrunBilgisiDefault = "UrunBilgisiDefault",
  UrunBilgisiCreate = "UrunBilgisiCreate",
  UrunBilgisiUpdate = "UrunBilgisiUpdate",
  UrunBilgisiDelete = "UrunBilgisiDelete",

  UyapOturumDefault = "UyapOturumDefault",
  UyapOturumCreate = "UyapOturumCreate",

  UyapDosyaDefault = "UyapDosyaDefault",
  UyapDosyaCreate = "UyapDosyaCreate",
  UyapDosyaUpdate = "UyapDosyaUpdate",
  UyapDosyaDelete = "UyapDosyaDelete",

  MainUsersDefault = "MainUsersDefault",
  MainUserUpdate = "MainUserUpdate",
  UserActivate = "UserActivate",
  UserDeactivate = "UserDeactivate",
  AdminCreateSubUser = "AdminCreateSubUser",
  AdminUpdateSubUser = "AdminUpdateSubUser",

  SupportDefault = "SupportDefault",
  SupportGet = "SupportGet",
  SupportCreate = "SupportCreate",
  SupportUpdate = "SupportUpdate",

  SupportAdminDefault = "SupportAdminDefault",
  SupportAdminReply = "SupportAdminReply",
  SupportAdminClose = "SupportAdminClose",

  AppLogDefault = "AppLogDefault",

  AdminCreateLicense = "AdminCreateLicense",
  AdminUpdateLicense = "AdminUpdateLicense",
  AdminOrderDefault = "AdminOrderDefault",

  CreatePayment = "CreatePayment",
  LicencesDefault = "LicencesDefault",

  VatandasPortalDefault = "VatandasPortalDefault",
}
