import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import SorguBilgileri from "./SorguBilgileri";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { LayoutContext } from "../../../../layout/context/layoutcontext";
import { useContext, useEffect } from "react";

const Banka = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: BankaResponse,
    refetch: refetchBanka,
    isLoading: bankaResponseLoading
  } = useQuery({
    queryKey: ["Banka", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetBankaHesabiBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(bankaResponseLoading)
  }, [bankaResponseLoading])

  return (
    <div>
      {BankaResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {BankaResponse.error}</div>}
      {BankaResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={BankaResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={BankaResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={BankaResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={BankaResponse?.result?.sorgulayanBilgileri}
          />

          <div className="overflow-x-auto mt-2">
            <DataTable
              value={BankaResponse?.result?.sorguSonucDVO?.map((item, index) => ({ ...item, key: index })) || []}
              dataKey="key"
              showGridlines
              scrollable
              scrollHeight="250px"
              style={{ minHeight: "7rem" }}
              selectionMode={"single"}
              loading={bankaResponseLoading}
            >
              <Column
                header="No"
                body={(rowData, { rowIndex }) => rowIndex + 1}
                className="w-4rem text-center"
              ></Column>
              <Column field="bankaAdi" header="Banka Adı" sortable></Column>
            </DataTable>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banka;
