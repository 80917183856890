import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { EmailDto, CreateEmailDto, UpdateEmailDto } from "./email.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<EmailDto>(`Email/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<EmailDto[]>(`Email`, config);
  },
  Create(dto: CreateEmailDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<EmailDto>("Email", dto, config);
  },
  Update(dto: UpdateEmailDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<EmailDto>("Email", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`Email/${id}`, config);
  },
};

export default services;
