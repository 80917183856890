import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useHub } from "../../../utils/buro-hub";
import uyapService from "../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../layout/context/layoutcontext";

const currencyFormat = (value: number) => {
  if (value == null) {
    return "";
  }
  return value.toLocaleString("tr-TR", { style: "currency", currency: "TRY" });
};

const DosyaHesabi = (props: { dosyaId: string }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)
  const {
    data: DosyaHesabiResponse,
    refetch: refetchDosyaHesabi,
    isLoading: dosyaHesabiResponseLoading
  } = useQuery({
    queryKey: ["DosyaHesabi", props.dosyaId],
    refetchOnMount: false,
    gcTime: 1000 * 60 * 3,
    queryFn: async () => await hub.executeUyapService(() => uyapService.GetDosyaHesabi(props.dosyaId))
  });

  useEffect(() => {
    setBlocked(dosyaHesabiResponseLoading)
  }, [dosyaHesabiResponseLoading])

  return (
    <div className="mt-2">
      {DosyaHesabiResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {DosyaHesabiResponse.error}</div>}
      {DosyaHesabiResponse?.result && DosyaHesabiResponse?.result.length > 0 && (
        <div>
          <DataTable
            value={
              DosyaHesabiResponse.result
                .filter((item) => item.degerAlan != null)
                .map((item, index) => ({ ...item, key: index })) || []
            }
            dataKey="key"
            showGridlines
            rows={10}
            emptyMessage="Dosya Hesabı Bilgisi bulunamadı."
            loading={dosyaHesabiResponseLoading}
            columnResizeMode="expand"
          >
            <Column field="textAlan" header="Dosya Hesabı"></Column>
            <Column field="degerAlan" header="Miktar" body={(rowData) => currencyFormat(rowData.degerAlan)}></Column>
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default DosyaHesabi;
