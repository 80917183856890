import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import AlacakKalemiService from "../../../../services/alacak-kalemi/alacak-kalemi.services";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../../../utils/DropdownValues";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useToast } from "../../../../utils/toast";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import {
  CreateAlacakKalemiDto,
  AlacakKalemiDto,
  UpdateAlacakKalemiDto,
} from "../../../../services/alacak-kalemi/alacak-kalemi.dto";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import { KarsiYanDto } from "../../../../services/karsi-yan/karsi-yan.dto";
import { MuvekkilTipi } from "../../../../services/muvekkil/muvekkil.dto";
import {
  InputNumber,
  InputNumberValueChangeEvent,
} from "primereact/inputnumber";
import { useContext } from "react";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const now: Date = new Date();

const UpsertAlacakKalemi = (props: any) => {
  const alacakKalemi: AlacakKalemiDto = props.alacakKalemi;
  const setAlacakKalemi = props.setAlacakKalemi;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchAlacakKalemi = props.refetchAlacakKalemi;
  const icraFoy: IcraFoyDto = props.icraFoy;
  const toast = useToast();

  const { setBlocked } = useContext(LayoutContext)
  let updateAlacakKalemiDto: UpdateAlacakKalemiDto;

  if (alacakKalemi === null || alacakKalemi === undefined) {
    updateAlacakKalemiDto = {
      id: 0,
      alacakTarihi: now,
      alacakMiktari: 0,
      alacakTuru: "",
      faizOrani: 0,
      aciklama: "",
    };
  } else {
    updateAlacakKalemiDto = {
      ...alacakKalemi,
      alacakTarihi: new Date(alacakKalemi.alacakTarihi),
    };
  }

  const formik = useFormik<UpdateAlacakKalemiDto>({
    initialValues: updateAlacakKalemiDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateAlacakKalemiDto> = {};
      if (!data.alacakTarihi) {
        errors.alacakTarihi = "Alacak Tarihi boş bırakılamaz";
      }

      if (!data.alacakMiktari) {
        errors.alacakMiktari = "Alacak Miktarı alanı boş bırakılamaz";
      }

      if (!data.alacakTuru) {
        errors.alacakTuru = "Alacak Türü alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      if (formik.values.id === 0) {
        var alacakKalemiCreateDto: CreateAlacakKalemiDto = {
          ...formik.values,
          icraFoyId: icraFoy.id,
        };
        AlacakKalemiService.Create(alacakKalemiCreateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setAlacakKalemi(undefined);
            refetchAlacakKalemi();
            toast.show("Alacak Kalemi başarıyla eklendi.", "success");
          }
        });
      } else {
        var alacakKalemiUpdateDto: UpdateAlacakKalemiDto = {
          ...formik.values,
          alacakTarihi: formik.values.alacakTarihi,
        };
        AlacakKalemiService.Update(alacakKalemiUpdateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setAlacakKalemi(undefined);
            refetchAlacakKalemi();
            toast.show("Alacak Kalemi başarıyla güncellendi.", "success");
          }
        });
      }
      setBlocked(false)
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.id === 0 ? "Alacak Kalemi Ekle" : "Alacak Kalemi Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setAlacakKalemi(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setAlacakKalemi(undefined);
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={formik.submitForm}
          />
        </div>
      }
      className=""
      style={{ width: "75vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="alacakTarihi">
            Alacak Tarihi
          </label>
          <Calendar
            value={formik.values.alacakTarihi}
            onChange={(e) => formik.setFieldValue("alacakTarihi", e.value)}
            dateFormat="dd/mm/yy"
            showIcon
            locale="tr"
            mask="99/99/9999"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "alacakTarihi"),
            })}
          />
          {!formik.values.alacakTarihi && (
            <div className="p-error mt-3">
              Alacak Tarihi alanı boş bırakılamaz
            </div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="alacakMiktari">
            Alacak Miktarı
          </label>
          <InputNumber
            id="alacakMiktari"
            name="alacakMiktari"
            locale="tr-TR"
            maxFractionDigits={2}
            minFractionDigits={2}
            value={formik.values.alacakMiktari}
            onValueChange={(e: InputNumberValueChangeEvent) => {
              formik.setFieldValue("alacakMiktari", e.value);
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "alacakMiktari"),
            })}
          />
          {FormikValueIsValid(formik, "alacakMiktari") && (
            <div className="p-error mt-3">{formik.errors.alacakMiktari}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="alacakTuru">
            Alacak Türü
          </label>
          <Dropdown
            id="alacakTuru"
            name="alacakTuru"
            value={formik.values.alacakTuru}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("alacakTuru", e.value);
            }}
            options={DropdownDatas.GetAlacakTuru()}
            optionLabel="label"
            optionValue="value"
            placeholder="Alacak Türü Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "alacakTuru"),
            })}
          />
          {FormikValueIsValid(formik, "alacakTuru") && (
            <div className="p-error mt-3">{formik.errors.alacakTuru}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="faizOrani">
            Faiz Oranı
          </label>
          <InputNumber
            id="faizOrani"
            name="faizOrani"
            locale="tr-TR"
            maxFractionDigits={2}
            minFractionDigits={2}
            value={formik.values.faizOrani}
            onValueChange={(e: InputNumberValueChangeEvent) => {
              formik.setFieldValue("faizOrani", e.value);
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "faizOrani"),
            })}
          />
          {FormikValueIsValid(formik, "faizOrani") && (
            <div className="p-error mt-3">{formik.errors.faizOrani}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="aciklama">
            Açıklama
          </label>
          <InputTextarea
            id="aciklama"
            name="aciklama"
            rows={3}
            value={formik.values.aciklama}
            onChange={(e) => {
              formik.setFieldValue("aciklama", e.target.value);
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "aciklama"),
            })}
          />
          {FormikValueIsValid(formik, "aciklama") && (
            <div className="p-error mt-3">{formik.errors.aciklama}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertAlacakKalemi;
