import React from "react";
import { useTranslation } from "react-i18next";

type DepartmentText = {
  value?: string;
};

const DepartmentText = ({ value }: DepartmentText) => {
  const { t } = useTranslation();
  const text = value
    ?.replace(/([a-zA-Z]+)(\d+)/, "$1 $2")
    .replace(/(?=[A-Z])/g, " ")
    .split(" ")
    .map((item) => t(item.toLowerCase()).toLocaleUpperCase("tr"))
    .join(" ");

  return <div>{text}</div>;
};

export default DepartmentText;
