import { DurusmaResponseDto } from "../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import accountService from "../../services/account/account.service";
import { SendIcsEmailDto } from "../../services/account/account.dto";
import { useToast } from "../../utils/toast";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../utils/auth";
import { use } from "i18next";
import { LayoutContext } from "../../layout/context/layoutcontext";

interface IcsMails {
  email: string;
}

interface IcsAlarms {
  alarmNumber: number;
  alarmType: "M" | "H" | "D";
}

const CreateIcsDiyalog = (props: {
  durusmalar: DurusmaResponseDto[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onComplete: () => void;
}) => {
  const durusmalar = props.durusmalar;
  const onComplete = props.onComplete;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const toast = useToast();
  const [emails, setEmails] = useState<IcsMails[]>([]);
  const [email, setEmail] = useState<string>("");
  const [alarmNumber, setAlarmNumber] = useState<number>(1);
  const [alarmType, setAlarmType] = useState<"M" | "H" | "D">("M");
  const [alarms, setAlarms] = useState<IcsAlarms[]>([]);
  const auth = useAuth();
  const { setBlocked } = useContext(LayoutContext)
  const {
    data: allUserEmails,
    refetch: refetchEmails,
    isLoading: isEmailResponseLoading
  } = useQuery({
    queryKey: ["Emails", auth.getUser().id],
    refetchOnMount: false,
    queryFn: () => accountService.GetAllUserEmail()
  });

  useEffect(() => {
    setBlocked(isEmailResponseLoading)
  }, [isEmailResponseLoading])

  useEffect(() => {
    if (allUserEmails && allUserEmails.result && allUserEmails.result.data) {
      setEmails(allUserEmails.result.data.map((email) => ({ email })));
    }
  }, [allUserEmails]);

  const handleDownloadIcsFile = () => {
    const icsFile = createICSFile(
      durusmalar,
      alarms.map((alarm) => `${alarm.alarmNumber}${alarm.alarmType}`)
    );
    const blob = new Blob([icsFile], { type: "text/calendar;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "durusmalar.ics";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    onComplete();
  };

  const handleSendMail = async () => {
    setBlocked(true)
    const icsFile = createICSFile(
      durusmalar,
      alarms.map((alarm) => `${alarm.alarmNumber}${alarm.alarmType}`)
    );

    const sendIcsEmailDto: SendIcsEmailDto = {
      emails: emails.map((value) => value.email),
      icsContent: icsFile
    };

    if (sendIcsEmailDto.emails.length === 0) {
      toast.show("En az bir e-posta adresi eklemelisiniz.", "error");
      return;
    }

    const res = await accountService.SendIcsEmail(sendIcsEmailDto);

    if (res && !res.error) {
      toast.show("E-posta başarıyla gönderildi.", "success");
      onComplete();
      setBlocked(false)
    }
  };

  const handleAddEmail = () => {
    if (email === "") {
      toast.show("E-posta adresi boş olamaz.", "error");
      return;
    }

    if (emails.find((value) => value.email === email)) {
      toast.show("Bu e-posta adresi zaten ekli.", "error");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.show("Geçerli bir e-posta adresi giriniz.", "error");
      return;
    }

    setEmails([...emails, { email }]);
    setEmail("");
  };

  const handleAddAlarm = () => {
    if (alarms.find((value) => value.alarmNumber === alarmNumber && value.alarmType === alarmType)) {
      toast.show("Bu alarm zaten ekli.", "error");
      return;
    }

    setAlarms([...alarms, { alarmNumber, alarmType }]);
    setAlarmNumber(1);
    setAlarmType("M");
  };

  const emailActionBodyTemplate = (rowData: IcsMails) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            setEmails(emails.filter((value) => value.email !== rowData.email));
          }}
        />
      </div>
    );
  };

  const alarmActionBodyTemplate = (rowData: IcsAlarms) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            const alarmIndex = alarms.findIndex(
              (alarm) => alarm.alarmNumber === rowData.alarmNumber && alarm.alarmType === rowData.alarmType
            );
            if (alarmIndex !== -1) {
              setAlarms(alarms.filter((value, index) => index !== alarmIndex));
            }
          }}
        />
      </div>
    );
  };

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={"Takvime Ekle"}
      onHide={() => {
        setVisible(false);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
            }}
          />
          <Button label={"Mail Gönder"} className="md:px-6 justify-content-center" onClick={handleSendMail} />
          <Button
            label={"Ics Dosya İndir"}
            className="md:px-6 justify-content-center"
            onClick={handleDownloadIcsFile}
          />
        </div>
      }
      className=""
      style={{ width: "50vw" }}
    >
      <div className="card">
        <div className="grid">
          <div className="col-12 md:col-6">
            <label htmlFor="email" className="text-primary">
              E-posta Adresi
            </label>
            <InputText
              id="email"
              type="email"
              placeholder="E-posta Adresi"
              className={classNames("w-full mt-2")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ padding: "1rem" }}
              autoComplete="email"
            />
          </div>
          <div className="col-12 md:col-6 mt-auto">
            <Button label="E-posta Ekle" className="px-3 md:px-5 lg:px-8" onClick={handleAddEmail} />
          </div>
        </div>

        <div className="mt-3">
          <DataTable
            value={
              emails.map((value, index) => {
                return { ...value, id: index };
              }) || []
            }
            dataKey="id"
            showGridlines
            emptyMessage="E-posta adresi bulunmamaktadır."
            columnResizeMode="expand"
          >
            <Column field="email" header="E-posta"></Column>
            <Column body={emailActionBodyTemplate} exportable={false} style={{ width: "3rem" }}></Column>
          </DataTable>
        </div>
      </div>

      <div className="card mt-3">
        <div className="grid">
          <div className="col-12 md:col-4">
            {/* <label className="text-primary" htmlFor="alarmNumber">
              Alarm number
            </label> */}
            <InputNumber
              id="alarmNumber"
              name="alarmNumber"
              value={alarmNumber}
              onValueChange={(e) => {
                setAlarmNumber(e.value as number);
              }}
              inputStyle={{ width: "100%" }}
              className="w-full"
              min={1}
              max={60}
            />
          </div>
          <div className="col-12 md:col-4">
            {/* <label className="text-primary" htmlFor="alarmType">
              Alarm type
            </label> */}
            <Dropdown
              id="alarmType"
              name="alarmType"
              value={alarmType}
              onChange={(e: MultiSelectChangeEvent) => {
                setAlarmType(e.value);
              }}
              options={[
                { label: "Dakika", value: "M" },
                { label: "Saat", value: "H" },
                { label: "Gün", value: "D" }
              ]}
              optionLabel="label"
              optionValue="value"
              placeholder="Alarm Tipi"
              className="w-full"
            />
          </div>
          <div className="col-12 md:col-4">
            <Button label="Alarm Ekle" className="px-5 lg:px-7" onClick={handleAddAlarm} />
          </div>
        </div>

        <div className="mt-3">
          <DataTable
            value={
              alarms.map((value, index) => {
                return { ...value, id: index };
              }) || []
            }
            dataKey="id"
            showGridlines
            emptyMessage="Alarm bulunmamaktadır."
            columnResizeMode="expand"
          >
            <Column field="alarmNumber" header=""></Column>
            <Column
              field="alarmType"
              header=""
              body={(rowData: IcsAlarms) => {
                return rowData.alarmType === "M" ? "Dakika" : rowData.alarmType === "H" ? "Saat" : "Gün";
              }}
            ></Column>
            <Column body={alarmActionBodyTemplate} exportable={false} style={{ width: "3rem" }}></Column>
          </DataTable>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateIcsDiyalog;

export function createICSFile(durusmalar: DurusmaResponseDto[], alarms: string[]): string {
  let icsBuilder: string[] = [];

  icsBuilder.push("BEGIN:VCALENDAR");
  icsBuilder.push("VERSION:2.0");
  icsBuilder.push("METHOD:REQUEST");
  icsBuilder.push("PRODID:-//PitechKurumsal//PratikPortal//TR");

  durusmalar.forEach((durusma) => {
    icsBuilder.push("BEGIN:VEVENT");
    icsBuilder.push(
      `SUMMARY:${durusma.yerelBirimAd} ${durusma.dosyaNo} ${durusma.dosyaTurKodAciklama} ${durusma.islemTuruAciklama}`
    );
    icsBuilder.push(`DTSTART:${moment(new Date(durusma.tarihSaat)).utc().format("YYYYMMDDTHHmmss[Z]")}`);
    icsBuilder.push(`DTEND:${moment(new Date(durusma.tarihSaat)).utc().format("YYYYMMDDTHHmmss[Z]")}`);
    icsBuilder.push(`LOCATION:`);
    icsBuilder.push(
      `DESCRIPTION:${durusma.dosyaTaraflari
        .map((taraf) => `${taraf.isim ?? ""} ${taraf.soyad ?? ""} - ${taraf.sifat ?? ""}`)
        .join(", ")}`
    ); // ad soyad - sanık //

    alarms.forEach((alarm) => {
      icsBuilder.push("BEGIN:VALARM");
      icsBuilder.push(`TRIGGER:-PT${alarm}`);
      icsBuilder.push("ACTION:DISPLAY");
      icsBuilder.push("DESCRIPTION:Reminder");
      icsBuilder.push("END:VALARM");
    });

    icsBuilder.push("END:VEVENT");
  });

  icsBuilder.push("END:VCALENDAR");

  return icsBuilder.join("\n");
}
