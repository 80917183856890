import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import IcrafoyServives from "../../services/icra-foy/icra-foy-services";
import { useQuery } from "@tanstack/react-query";
import UpsertIcraFoy from "./UpsertIcraFoy";
import { useToast } from "../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { IcraFoyDto, IcraFoyKarsiYanDto } from "../../services/icra-foy/icra-foy.dto";
import { MuvekkilTipi } from "../../services/muvekkil/muvekkil.dto";
import DropdownDatas from "../../utils/DropdownValues";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { Calendar } from "primereact/calendar";
import { CreateHeader, Filter } from "../filter/base-filter";
import { ExportFile } from "../../utils/export-file";
import { LayoutContext } from "../../layout/context/layoutcontext";

const IcraFoyPage = () => {
  const [icraFoy, setIcraFoy] = useState<IcraFoyDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const { setBlocked } = useContext(LayoutContext)
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<IcraFoyKarsiYanDto[]>>(null);
  const [visibleUpsertIcraFoyDialog, setVisibleUpsertIcraFoyDialog] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  const {
    data: icraFoyResponse,
    refetch: refetchIcraFoy,
    isLoading: icraFoyResponseLoading
  } = useQuery({
    queryKey: ["IcraFoys"],
    refetchOnMount: true,
    queryFn: () => IcrafoyServives.GetAllIcraFoyWithBorclu()
  });

  useEffect(() => {
    setBlocked(icraFoyResponseLoading)
    if (!icraFoyResponseLoading) {
      icraFoyResponse?.result?.data?.forEach((item) => {
        item.icraFoy.muvekkilNames = item.icraFoy.muvekkiller
          .map((x) => (x.tip === MuvekkilTipi.Gercek ? x.adSoyad : x.unvan))
          .join(", ");
      });

      icraFoyResponse?.result?.data?.forEach((x) => {
        if (x.karsiYan.tip === MuvekkilTipi.Tuzel) {
          x.karsiYan.tcNo = x.karsiYan.vergiKimlikNumarasi;
        }
      });
    }
  }, [icraFoyResponseLoading])


  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "icraFoy.muvekkilNames": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      "karsiYan.adSoyad": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      "karsiYan.tcNo": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      tip: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      "icraFoy.foyNo": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      "icraFoy.icraMudurlugu": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      "icraFoy.dosyaNo": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      "icraFoy.takipCikisi": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      "icraFoy.takipTarihi": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
      },
      "icraFoy.takipSekli": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      }
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni İcra Föy"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertIcraFoyDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          tooltipOptions={{ position: "top" }}
          rounded
          tooltip="CSV"
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: IcraFoyKarsiYanDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-search"
          rounded
          outlined
          className="mr-2"
          severity="success"
          tooltip="Detay"
          onClick={() => {
            navigate(`/app/icra-foy-detay/${rowData.icraFoy.id}`);
          }}
        />
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          tooltip="Düzenle"
          onClick={() => {
            setIcraFoy(rowData.icraFoy);
            setVisibleUpsertIcraFoyDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          onClick={() => {
            deleteIcraFoyConfirm(rowData.icraFoy);
          }}
        />
      </div>
    );
  };

  const deleteIcraFoy = (rowData: IcraFoyDto) => {
    IcrafoyServives.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Icra föy başarıyla silindi.", "success");
        refetchIcraFoy();
      }
    });
  };

  const deleteIcraFoyConfirm = (rowData: IcraFoyDto) => {
    confirmDialog({
      message: "İcra föyü silmek istediğinize emin misiniz?",
      header: "İcra Föy Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteIcraFoy(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  // export excel
  const exportExcel = () => {
    const filteredData = icraFoyResponse?.result?.data || [];

    const headers = [
      "Föy No",
      "Alacaklı",
      "Müşteri No",
      "Borçlu",
      "TC/VK No",
      "Borçlu Tipi",
      "İcra Müdürlüğü",
      "Dosya No",
      "Takip Çıkışı",
      "Takip Tarihi",
      "Takip Şekli",
      "Kesinleşme Tarihi",
      "Agent"
    ];

    const worksheetData = [headers, ...filteredData.map(row => [
      row.icraFoy.foyNo,
      row.icraFoy.muvekkilNames,
      '', // Müşteri No için veri mevcutsa ekleyin
      row.karsiYan.adSoyad,
      row.karsiYan.tcNo,
      row.tip,
      row.icraFoy.icraMudurlugu,
      row.icraFoy.dosyaNo,
      row.icraFoy.takipCikisi?.toLocaleString("tr-TR", {
        style: "currency",
        currency: "TRY",
        currencyDisplay: "code"
      }).replace("TRY", ""),
      row.icraFoy.takipTarihi ? new Date(row.icraFoy.takipTarihi).toLocaleDateString() : "",
      row.icraFoy.takipSekli,
      '', // Kesinleşme Tarihi için veri mevcutsa ekleyin
      ''  // Agent için veri mevcutsa ekleyin
    ])];


    //const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "IcraFoy");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          showGridlines
          value={icraFoyResponse?.result?.data || []}
          // dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} / {last} - Toplam {totalRecords} icra föy"
          filters={filters}
          // filterDisplay="row"
          loading={icraFoyResponseLoading}
          globalFilterFields={[
            "karsiYan.adSoyad",
            "karsiYan.tcNo",
            "tip",
            "icraFoy.foyNo",
            "icraFoy.icraMudurlugu",
            "borclu.adSoyad",
            "borclu.tcNo",
            "borclu.tip",
            "icraFoy.muvekkilNames",
            "icraFoy.klasor.name",
            "icraFoy.dosyaDurumu.name",
            "icraFoy.takipTuru",
            "icraFoy.takipYolu",
            "icraFoy.takipSekli",
            "icraFoy.aciklama4",
            "icraFoy.aciklama9",
            "icraFoy.takipMahiyeti",
            "icraFoy.dosyaNo",
            "icraFoy.takipCikisi",
            "icraFoy.takipTarihi"
          ]}
          emptyMessage="İcra Föy bulunamadı."
          header={CreateHeader(
            "İcra Föyler",
            globalFilterValue,
            (e) => onGlobalFilterChange(e),
            clearFilter,
            exportCSV,
            exportExcel
          )}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "13rem" }}></Column>
          <Column
            header="Föy No"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span>{rowData.icraFoy.foyNo}</span>;
            }}
            filterField="icraFoy.foyNo"
            filter
            filterPlaceholder="Föy No'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Föy No'ya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            header="Alacaklı"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span>{rowData.icraFoy.muvekkilNames}</span>;
            }}
            filterField="icraFoy.muvekkilNames"
            filter
            filterPlaceholder="Alacaklıya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Alacaklıya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            header="Müşteri No"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span></span>;
            }}
          // filterField="icraFoy.muvekkilNames"
          // filter
          // filterPlaceholder="Alacaklıya göre arayın"
          // filterClear={Filter.FilterClearTemplate}
          // filterApply={Filter.FilterApplyTemplate}
          // filterFooter={() => {
          //   return (
          //     <div className="px-3 pt-0 pb-3 text-center">
          //       Alacaklıya göre filtreleyin
          //     </div>
          //   );
          // }}
          ></Column>

          <Column
            header="Borçlu"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span>{rowData.karsiYan.adSoyad}</span>;
            }}
            filterField="karsiYan.adSoyad"
            filter
            filterPlaceholder="Borçluya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Borçluya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            header="TC/VK No"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span>{rowData.karsiYan.tcNo}</span>;
            }}
            filterField="karsiYan.tcNo"
            filter
            filterPlaceholder="TC/VK No'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">TC/VK No'ya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            header="Borçlu Tipi"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span>{rowData.tip}</span>;
            }}
            filterField="tip"
            filter
            filterPlaceholder="Tip'e göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Tip'e göre filtreleyin</div>;
            }}
          ></Column>

          <Column
            header="İcra Müdürlüğü"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span>{rowData.icraFoy.icraMudurlugu}</span>;
            }}
            filterField="icraFoy.icraMudurlugu"
            filter
            filterPlaceholder="İcra No'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İcra No'ya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            header="Dosya No"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span>{rowData.icraFoy.dosyaNo}</span>;
            }}
            filterField="icraFoy.dosyaNo"
            filter
            filterPlaceholder="Dosya No'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>;
            }}
          ></Column>

          <Column
            field="takipCikisi"
            header="Takip Çıkışı"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return (
                <span>
                  {rowData.icraFoy.takipCikisi
                    ?.toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "TRY",
                      currencyDisplay: "code"
                    })
                    .replace("TRY", "")}
                </span>
              );
            }}
            filterField="icraFoy.takipCikisi"
            filter
            filterPlaceholder="Takip Çıkışına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Takip Çıkışına göre filtreleyin</div>;
            }}
          ></Column>

          <Column
            field="takipTarihi"
            header="Takip Tarihi"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              var a = rowData.icraFoy.takipTarihi;
              return (
                <span>
                  {rowData.icraFoy.takipTarihi ? new Date(rowData.icraFoy.takipTarihi).toLocaleDateString() : ""}
                </span>
              );
            }}
            filterField="icraFoy.takipTarihi"
            filter
            filterPlaceholder="Takip Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Takip Tarihine göre filtreleyin</div>;
            }}
          ></Column>

          <Column
            field="takipTuru"
            header="Takip Şekli"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span>{rowData.icraFoy.takipSekli}</span>;
            }}
            filterField="icraFoy.takipSekli"
            filter
            filterPlaceholder="Takip Şekline göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Takip Şekline göre filtreleyin</div>;
            }}
          ></Column>

          <Column
            // field="digerAyrintilar"
            header="Kesinleşme Tarihi"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span></span>;
            }}
          ></Column>
          <Column
            // field="takipMahiyeti"
            header="Agent"
            sortable
            body={(rowData: IcraFoyKarsiYanDto) => {
              return <span></span>;
            }}
          ></Column>
        </DataTable>
      </div>
      <UpsertIcraFoy
        visible={visibleUpsertIcraFoyDialog}
        setVisible={setVisibleUpsertIcraFoyDialog}
        icraFoy={icraFoy}
        setIcraFoy={setIcraFoy}
        refetchIcraFoy={refetchIcraFoy}
      />
    </div>
  );
};

export default IcraFoyPage;
