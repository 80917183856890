import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import TebligatBilgisiService from "../../../../services/tebligat-bilgisi/tebligat-bilgisi-service";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../../../utils/DropdownValues";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import { Button } from "primereact/button";
import { useToast } from "../../../../utils/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import {
  CreateTebligatBilgisiDto,
  TebligatBilgisiDto,
  TebligatDurumu,
  UpdateTebligatBilgisiDto,
} from "../../../../services/tebligat-bilgisi/tebligat-bilgisi.dto";
import { Calendar } from "primereact/calendar";
import { IlDto, IlceDto } from "../../../../services/ilce-il/ilce-il.sto";
import { useQuery } from "@tanstack/react-query";
import IlceIlService from "../../../../services/ilce-il/ilce-il.services";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const now: Date = new Date();

const UpsertTebligatBilgisi = (props: any) => {
  const tebligatBilgisi: TebligatBilgisiDto = props.tebligatBilgisi;
  const setTebligatBilgisi = props.setTebligatBilgisi;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchTebligatBilgisi = props.refetchTebligatBilgisi;
  const icraFoy: IcraFoyDto = props.icraFoy;
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  const { data: illerResponse, isLoading: illerIsLoadinng } = useQuery({
    queryKey: ["Iller"],
    refetchOnMount: true,
    queryFn: () => IlceIlService.GetAllIl(),
  });

  const { data: ilcelerResponse, refetch: refetchIlcelerResponse, isLoading: ilcelerIsLoadinng } = useQuery({
    queryKey: ["Ilceler"],
    refetchOnMount: true,
    queryFn: () => IlceIlService.GetAllIlce(formik.values.ilId),
  });

  useEffect(() => {
    setBlocked(illerIsLoadinng || ilcelerIsLoadinng)
  }, [ilcelerIsLoadinng, illerIsLoadinng])

  let updateTebligatBilgisiDto: UpdateTebligatBilgisiDto;

  if (tebligatBilgisi === null || tebligatBilgisi === undefined) {
    updateTebligatBilgisiDto = {
      id: 0,
      tur: "",
      muhatap: "",
      address: "",
      ilceId: 0,
      ilId: 0,
      barkodNo: "",
      tebligatDurumu: TebligatDurumu.BilaUyarili,
      tebligatSerhi: "",
      yazimTarihi: now,
    };
  } else {
    updateTebligatBilgisiDto = {
      ...tebligatBilgisi,
      ilceId: tebligatBilgisi.ilce.id,
      ilId: tebligatBilgisi.il.id,
      yazimTarihi: new Date(tebligatBilgisi.yazimTarihi),
      tebligatTarihi: tebligatBilgisi.tebligatTarihi
        ? new Date(tebligatBilgisi.tebligatTarihi)
        : undefined,
      gonderimTarihi: tebligatBilgisi.gonderimTarihi
        ? new Date(tebligatBilgisi.gonderimTarihi)
        : undefined,
    };
  }

  const formik = useFormik<UpdateTebligatBilgisiDto>({
    initialValues: updateTebligatBilgisiDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateTebligatBilgisiDto> = {};
      if (!data.yazimTarihi) {
        errors.yazimTarihi = "Yazım tarihi alanı boş bırakılamaz";
      }
      if (!data.tur) {
        errors.tur = "Tür alanı boş bırakılamaz";
      }
      if (!data.muhatap) {
        errors.muhatap = "Muhatap alanı boş bırakılamaz";
      }
      if (!data.address) {
        errors.address = "Adres alanı boş bırakılamaz";
      }
      if (!data.ilId) {
        errors.ilId = "İl alanı boş bırakılamaz";
      }
      if (!data.ilceId) {
        errors.ilceId = "İlçe alanı boş bırakılamaz";
      }
      if (!data.tebligatDurumu) {
        errors.tebligatDurumu = "Tebligat durumu alanı boş bırakılamaz";
      }

      if (!data.tebligatSerhi) {
        errors.tebligatSerhi = "Tebligat şerhi alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      if (formik.values.id === 0) {
        var kullaniciTarihceNotuCreateDto: CreateTebligatBilgisiDto = {
          ...formik.values,
          icraFoyId: icraFoy.id,
        };
        TebligatBilgisiService.Create(kullaniciTarihceNotuCreateDto).then(
          (res) => {
            if (res.result && !res.result?.error) {
              setVisible(false);
              formik.resetForm();
              setTebligatBilgisi(undefined);
              refetchTebligatBilgisi();
              toast.show("Tebligat bilgisi başarıyla eklendi.", "success");
            }
          }
        );
      } else {
        var kullaniciTarihceNotuUpdateDto: UpdateTebligatBilgisiDto = {
          ...formik.values,
        };
        TebligatBilgisiService.Update(kullaniciTarihceNotuUpdateDto).then(
          (res) => {
            if (res.result && !res.result?.error) {
              setVisible(false);
              formik.resetForm();
              setTebligatBilgisi(undefined);
              refetchTebligatBilgisi();
              toast.show("Tebligat bilgisi başarıyla güncellendi.", "success");
            }
          }
        );
      }
    },
  });

  useEffect(() => {
    refetchIlcelerResponse();
  }, [formik.values.ilId]);

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.id === 0
          ? "Tebligat Bilgisi Ekle"
          : "Tebligat Bilgisi Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setTebligatBilgisi(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setTebligatBilgisi(undefined);
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={formik.submitForm}
          />
        </div>
      }
      className=""
      style={{ width: "75vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="yazimTarihi">Yazım Tarihi</label>
          <Calendar
            id="yazimTarihi"
            name="yazimTarihi"
            value={formik.values.yazimTarihi}
            onChange={(e) => {
              formik.setFieldValue("yazimTarihi", e.value);
            }}
            dateFormat="dd/mm/yy"
            showIcon
            mask="99/99/9999"
            locale="tr"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "yazimTarihi"),
            })}
          />
          {FormikValueIsValid(formik, "yazimTarihi") && (
            <div className="p-error mt-3">
              Yazım Tarihi alanı boş bırakılamaz
            </div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tur">Tebligat Türü</label>
          <Dropdown
            id="tur"
            name="tur"
            editable
            value={formik.values.tur}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tur", e.value);
            }}
            options={DropdownDatas.GetTebligatTuru()}
            optionLabel="label"
            optionValue="value"
            placeholder="Tür Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tur"),
            })}
          />
          {FormikValueIsValid(formik, "tur") && (
            <div className="p-error mt-3">{formik.errors.tur}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="muhatap">Muhatap</label>
          <Dropdown
            id="muhatap"
            name="muhatap"
            editable
            value={formik.values.muhatap}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("muhatap", e.value);
            }}
            options={DropdownDatas.GetTebligatMuhattap()}
            optionLabel="label"
            optionValue="value"
            placeholder="Tür Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "muhatap"),
            })}
          />
          {FormikValueIsValid(formik, "muhatap") && (
            <div className="p-error mt-3">{formik.errors.muhatap}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="ilId">İl</label>
          <Dropdown
            id="ilId"
            name="ilId"
            filter
            value={formik.values.ilId}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("ilId", e.value);
            }}
            options={illerResponse?.result?.data}
            optionLabel="ilText"
            optionValue="id"
            placeholder="İl Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "ilId"),
            })}
          />
          {FormikValueIsValid(formik, "ilId") && (
            <div className="p-error mt-3">{formik.errors.ilId}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="ilceId">İlçe</label>
          <Dropdown
            id="ilceId"
            name="ilceId"
            filter
            value={formik.values.ilceId}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("ilceId", e.value);
            }}
            options={ilcelerResponse?.result?.data}
            optionLabel="ilceText"
            optionValue="id"
            placeholder="İlçe Seçiniz"
            disabled={formik.values.ilId === 0}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "ilceId"),
            })}
          />
          {FormikValueIsValid(formik, "ilceId") && (
            <div className="p-error mt-3">{formik.errors.ilceId}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="address">Adres</label>
          <InputText
            id="address"
            name="address"
            value={formik.values.address}
            onChange={(e) => {
              formik.setFieldValue("address", e.target.value.toLocaleUpperCase("tr"));
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "address"),
            })}
          />
          {FormikValueIsValid(formik, "address") && (
            <div className="p-error mt-3">{formik.errors.address}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="gonderimTarihi">Gönderim Tarihi</label>
          <Calendar
            id="gonderimTarihi"
            name="gonderimTarihi"
            value={formik.values.gonderimTarihi}
            onChange={(e) => {
              formik.setFieldValue("gonderimTarihi", e.value);
            }}
            dateFormat="dd/mm/yy"
            showIcon
            mask="99/99/9999"
            locale="tr"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "gonderimTarihi"),
            })}
          />
          {FormikValueIsValid(formik, "gonderimTarihi") && (
            <div className="p-error mt-3">
              Gönderim Tarihi alanı boş bırakılamaz
            </div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="barkodNo">Barkod Numarası</label>
          <InputText
            id="barkodNo"
            name="barkodNo"
            value={formik.values.barkodNo}
            onChange={(e) => {
              formik.setFieldValue("barkodNo", e.target.value.toLocaleUpperCase("tr"));
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "barkodNo"),
            })}
          />
          {FormikValueIsValid(formik, "barkodNo") && (
            <div className="p-error mt-3">{formik.errors.barkodNo}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tebligatDurumu">Tebligat Durumu</label>
          <Dropdown
            id="tebligatDurumu"
            name="tebligatDurumu"
            value={formik.values.tebligatDurumu}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tebligatDurumu", e.value);
            }}
            options={DropdownDatas.GetTebligatDurumu()}
            optionLabel="label"
            optionValue="value"
            placeholder="Durum Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tebligatDurumu"),
            })}
          />
          {FormikValueIsValid(formik, "tebligatDurumu") && (
            <div className="p-error mt-3">{formik.errors.tebligatDurumu}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tebligatTarihi">Tebligat Tarihi</label>
          <Calendar
            id="tebligatTarihi"
            name="tebligatTarihi"
            value={formik.values.tebligatTarihi}
            onChange={(e) => {
              formik.setFieldValue("tebligatTarihi", e.value);
            }}
            dateFormat="dd/mm/yy"
            showIcon
            locale="tr"
            mask="99/99/9999"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tebligatTarihi"),
            })}
          />
          {FormikValueIsValid(formik, "tebligatTarihi") && (
            <div className="p-error mt-3">
              Tebligat Tarihi alanı boş bırakılamaz
            </div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tebligatSerhi">Tebligat Şerhi</label>
          <Dropdown
            id="tebligatSerhi"
            editable
            name="tebligatSerhi"
            value={formik.values.tebligatSerhi}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tebligatSerhi", e.value);
            }}
            options={DropdownDatas.GetTebligatSerhi()}
            optionLabel="label"
            optionValue="value"
            placeholder="Tür Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tebligatSerhi"),
            })}
          />
          {FormikValueIsValid(formik, "tebligatSerhi") && (
            <div className="p-error mt-3">{formik.errors.tebligatSerhi}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertTebligatBilgisi;
