import React, { useContext, useEffect } from "react";
import { DosyaDTO } from "../../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { useQuery } from "@tanstack/react-query";
import { useHub } from "../../../../../utils/buro-hub";
import uyapService from "../../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import SorguBilgileri from "../SorguBilgileri";
import { LayoutContext } from "../../../../../layout/context/layoutcontext";

const SgkEmeklisi = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: SgkEmekliResponse,
    refetch: refetchSgkEmekli,
    isLoading: sgkEmekliResponseLoading
  } = useQuery({
    queryKey: ["SgkEmekli", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetSskEmekliBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(sgkEmekliResponseLoading)
  }, [sgkEmekliResponseLoading])

  return (
    <div>
      {SgkEmekliResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {SgkEmekliResponse.error}</div>}

      {SgkEmekliResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={SgkEmekliResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={SgkEmekliResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={SgkEmekliResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={SgkEmekliResponse?.result?.sorgulayanBilgileri}
          />
          {SgkEmekliResponse?.result?.sorguSonucDVO && SgkEmekliResponse?.result?.sorguSonucDVO?.hataKodu != 0 ? (
            <div className="card p-2 lg:p-5 mt-3">{SgkEmekliResponse?.result?.sorguSonucDVO?.hataMesaji.toString()}</div>
          ) : (
            <div className="card p-2 lg:p-5 mt-3"></div>
          )}
        </div>
      )}
    </div>
  );
};

export default SgkEmeklisi;
