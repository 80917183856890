import React, { useContext, useEffect, useState } from "react";
import { DosyaDTO, IskiResponseDTO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import SorguBilgileri from "./SorguBilgileri";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const Iski = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: IskiResponse,
    refetch: refetchIski,
    isLoading: iskiResponseLoading
  } = useQuery({
    queryKey: ["Iski", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetIskiBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(iskiResponseLoading)
  }, [iskiResponseLoading])

  return (
    <div>
      {IskiResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {IskiResponse.error}</div>}

      {IskiResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={IskiResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={IskiResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={IskiResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={IskiResponse?.result?.sorgulayanBilgileri}
          />

          <div className="card p-2 lg:p-5 mt-3">
            {IskiResponse?.result.sorguSonucDVO?.adres ? (
              <div>
                <div className="card p-2 lg:p-5 mt-3">Iski kayıtlarına göre adres kaydı var</div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="font-bold"> ADRES : </div>
                  <div>{IskiResponse.result.sorguSonucDVO?.adres}</div>
                </div>
              </div>
            ) : (
              <div>İSKİ kayıtlarına göre İstanbul adresi kaydı yok</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Iski;
