import { UserDto } from "../account/account.dto";
import { EntityDto } from "../base/base.dto";
import { SupportMessageDto } from "./support-message.dto";

export interface SupportDto extends EntityDto<number> {
    topic: string;
    status: SupportStatus;
    createdDate: Date;
    messages: SupportMessageDto[];
    user: UserDto;
}

export interface CreateSupportDto {
    topic: string;
    message: string;
    files: File[];
}
export enum SupportStatus {
    Open = 1,
    Closed
}