import { useContext, useEffect, useState, useRef } from "react";
import { useHub } from "../../../utils/buro-hub";
import uyapService from "../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { FileViewer, FileDTO, useFileViewer } from "../../../components/@fileViewer";
import { EvrakListeleyici } from "./evrak-component/EvrakListeleyici";
import moment from "moment";
import {
  DownloadEvrakRequestDto,
  ElecronEvrakResponseDto,
  ElectronGetEvrakResponseDto,
  GetEvrakRequestDto
} from "./evrak-component/evrak.models.dto";
import { useToast } from "../../../utils/toast";
import {
  TumEvraklarResponseDTO,
  EvrakDTO,
  EkEvrakDTO,
  EvraklarDTO
} from "../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { useEvrakListeleyici, IEvrakDTO } from "./evrak-component/useEvrakListeleyici";
import { TreeNode } from "primereact/treenode";
import { EvrakTreeNode } from "./evrak-component/useEvrakListeleyici";
import { ElectronResponseDto } from "../../../utils/electron-connection";
import { useQuery } from "@tanstack/react-query";
import { LayoutContext } from "../../../layout/context/layoutcontext";
import { EvrakBulkDownloader } from "./evrak-component/EvrakBulkDownloader";
import { useEvrak } from "../../../utils/evrakContext";

interface IZipFile {
  id: string;
  files: [];
}

interface EvrakProps {
  dosyaId: string;
  dosyaNo: string;
  birimAdi: string;
  dosyaTur: string;
}

const Evrak: React.FC<EvrakProps> = ({ dosyaId, dosyaNo, birimAdi, dosyaTur }) => {
  const hub = useHub();
  const [evraklar, setEvraklar] = useState<{ result: TumEvraklarResponseDTO }>();
  const [seciliEvrak, setSeciliEvrak] = useState<FileDTO>();
  const { unarchiveZip, zipFiles, getMimeTypeFromExtension, availableMimeTypes } = useFileViewer();
  const toast = useToast();
  const [localEvraklar, setLocalEvraklar] = useState<string[]>([]);
  const [evraklarWithLocal, setEvrakWithLocal] = useState<{ result: TumEvraklarResponseDTO }>();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const isBulkLoaderOpen = useRef(false);
  const {
    data: EvraklarReponse,
    refetch: refetchEvraklar,
    isLoading: evraklarResponseLoading
  } = useQuery({
    queryKey: ["Evraklar", dosyaId],
    refetchOnMount: false,
    gcTime: 1000 * 60 * 10,
    queryFn: async () => await hub.executeUyapService(() => uyapService.GetAllEvrak(dosyaId))
  });

  useEffect(() => {
    sendGetAllLocalEvraksMessageToElectron();
  }, []);

  // listerin callbacki tiklanan nodeu alip secili evrak olarak atiyor
  const fileSelectedHandler = async (data: EvrakTreeNode) => {
    setIsFileLoading(true);
    let fileBlob: Blob = new Blob();
    let fileMimeType: string = "";

    try {
      if (data?.data?.location) {
        if (data?.data?.location == "zip" && data?.data?.file) {
          const zipedFileType = getMimeTypeFromExtension(data?.data?.file?.name as string);
          if (!zipedFileType) return console.error("zipden cikarilan dosyanin mimeTypei okunamadi");

          const blob = await data?.data?.file.async("blob");
          fileBlob = blob;
          fileMimeType = zipedFileType;
        } else {
          await sendGetEvrakMessageToElectron({ path: data?.data?.location }, data?.data?.evrakId);
        }
      } else {
        if (!data?.data?.evrakId) return console.error("evrakId not found");

        let getEvrakMimeTypeTry = 0;
        while (getEvrakMimeTypeTry < 3) {
          const responseMimeType = await hub.executeUyapService(() =>
            uyapService.GetEvrakMimeType(data?.data?.evrakId)
          );
          await delay(500);
          if (responseMimeType?.result?.mimeType) {
            fileMimeType = responseMimeType?.result?.mimeType;
            break;
          }
          getEvrakMimeTypeTry++;
        }

        if (!fileMimeType) {
          setSeciliEvrak({
            mimeType: "text/html",
            data: new Blob(["Dosya açılırken uyap kaynaklı bir hata oluştu. Daha sonra tekrar deneyiniz"], {
              type: "text/html"
            })
          });
          return console.error("mimeType not found");
        }

        let getEvrakTry = 0;
        while (getEvrakTry < 3) {
          const responseEvrak = await hub.executeUyapService(() =>
            uyapService.GetEvrak({
              evrakId: data?.data?.evrakId,
              dosyaId: dosyaId
            })
          );
          await delay(500);
          if (responseEvrak.result) {
            fileBlob = responseEvrak.result as Blob;
            break;
          }
          getEvrakTry++;
        }

        if (fileBlob.size == 0) {
          setSeciliEvrak({
            mimeType: "text/html",
            data: new Blob(["Dosya açılırken uyap kaynaklı bir hata oluştu. Daha sonra tekrar deneyiniz"], {
              type: "text/html"
            })
          });
          return console.error("fileSelectedHandler: file not founded");
        }

        if (fileBlob?.type == "text/html") {
          setSeciliEvrak({
            mimeType: "text/html",
            data: fileBlob
          });
          return;
        }

        if (fileMimeType == "application/pkcs7-mime" || fileMimeType == "application/eyp") {
          await sendDownloadEvrakMessageToElectron({
            evrakId: data?.data?.evrakId,
            birimAdi: birimAdi,
            dosyaNo: dosyaNo,
            dosyaTur: dosyaTur,
            file: await (fileBlob as Blob).arrayBuffer(),
            fileExtension: availableMimeTypes[fileMimeType],
            onaylandigiTarihNormal: data?.data?.sistemeGonderildigiTarih.replaceAll("/", "-"),
            onaylandigiTarihYilOnce: moment(data?.data?.sistemeGonderildigiTarih, "DD/MM/YYYY").format("YYYY.MM.DD"),
            tur: data?.data?.tur,
            anaEvrakId: data?.data?.anaEvrakId,
            ekEvrakName: `Ek - ${data?.data?.sira}`
          });
          unarchiveZip(fileBlob as Blob, data?.data?.evrakId);
          return;
        }
      }

      if (!fileMimeType || !fileBlob) return;

      setSeciliEvrak({
        mimeType: fileMimeType,
        data: fileBlob
      });

      if (data?.data?.file) return;

      const localIndex = localEvraklar.findIndex((p) => p.includes(data?.data?.evrakId));

      if (
        localIndex != -1 &&
        localEvraklar[localIndex].includes(dosyaNo) &&
        localEvraklar[localIndex].includes(birimAdi) &&
        localEvraklar[localIndex].includes(dosyaTur)
      )
        return;

      await sendDownloadEvrakMessageToElectron({
        evrakId: data?.data?.evrakId,
        birimAdi: birimAdi,
        dosyaNo: dosyaNo,
        dosyaTur: dosyaTur,
        file: await (fileBlob as Blob).arrayBuffer(),
        fileExtension: availableMimeTypes[fileMimeType],
        onaylandigiTarihNormal: data?.data?.sistemeGonderildigiTarih.replaceAll("/", "-"),
        onaylandigiTarihYilOnce: moment(data?.data?.sistemeGonderildigiTarih, "DD/MM/YYYY").format("YYYY.MM.DD"),
        tur: data?.data?.tur,
        anaEvrakId: data?.data?.anaEvrakId,
        ekEvrakName: `Ek - ${data?.data?.sira}`
      });
    } catch (error) {
      console.error("An error occurred in fileSelectedHandler:", error);
    } finally {
      setIsFileLoading(false);
    }
  };

  /* N4zKzvnr
    hook sonucunda donen zipin dosyalar ek dosyalar olarak ilgili evragin altina ekleniyor
  */
  useEffect(() => {
    if (EvraklarReponse?.result?.son20Evrak) {
      const son20Evrak = createAdditionalDocs(EvraklarReponse?.result?.son20Evrak, zipFiles as IZipFile);
      let tumEvraklar: any = {};
      if (EvraklarReponse?.result?.tumEvraklar)
        Object.keys(EvraklarReponse?.result?.tumEvraklar).forEach((dosyaName: string) => {
          const docs = createAdditionalDocs(
            EvraklarReponse?.result?.tumEvraklar[dosyaName] as EvrakDTO[],
            zipFiles as IZipFile
          );
          tumEvraklar[dosyaName] = docs;
        });

      setEvraklar({
        result: {
          son20Evrak,
          tumEvraklar
        }
      });
    }
  }, [zipFiles]);

  const createAdditionalDocs = (evraklar: EvrakDTO[], zipFiles: IZipFile) => {
    // orjinali zip formatindaki ek evragi bulup icindeki evraklari ek evragin => ek evragi olarak koyuyor
    return evraklar?.map((evrak: any) => {
      const evrakIndex = evrak.ekEvrakListesi.findIndex((ekEvrak: EkEvrakDTO) => ekEvrak.evrakId == zipFiles?.id);
      if (evrakIndex != -1)
        evrak.ekEvrakListesi[evrakIndex].ekEvrakListesi = zipFiles.files.map((file: any, index: number) => ({
          file,
          anaEvrakId: zipFiles?.id,
          evrakId: `${zipFiles?.id}-index`,
          location: "zip"
        }));
      return evrak;
    });
  };

  useEffect(() => {
    const tumEvraklar = EvraklarReponse?.result?.tumEvraklar;
    let son20Evrak = EvraklarReponse?.result?.son20Evrak;

    if (!son20Evrak || !tumEvraklar) return;

    setEvrakWithLocal({
      result: {
        son20Evrak,
        tumEvraklar
      }
    });

    if (localEvraklar.length > 0) {
      const tumEvraklarWithLocal: EvraklarDTO = {};
      const son20EvrakLocal: IEvrakDTO[] = [];

      Object.keys(tumEvraklar)
        .map((dosyalar: string) => {
          return tumEvraklar[dosyalar].map((dosyaEvrak: any) => {
            return {
              ...dosyaEvrak,
              ekEvrakListesi: dosyaEvrak?.ekEvrakListesi?.map((ekEvrak: any, index: number) => {
                return {
                  ...ekEvrak,
                  sira: index + 1,
                  location:
                    localEvraklar.filter(
                      (item: any) =>
                        item.includes(`${ekEvrak?.evrakId}_`) &&
                        item.includes(`${dosyaNo.replaceAll("/", "-")}`) &&
                        item.includes(`${birimAdi}`)
                    )[0] ?? ""
                };
              }),
              location:
                localEvraklar.filter(
                  (item: any) =>
                    item.includes(`${dosyaEvrak?.evrakId}_`) &&
                    item.includes(`${dosyaNo.replaceAll("/", "-")}`) &&
                    item.includes(`${birimAdi}`) &&
                    item.includes(dosyaEvrak?.tur)
                )[0] ?? ""
            };
          });
        })
        .forEach((data, index) => {
          tumEvraklarWithLocal[Object.keys(tumEvraklar)[index]] = data;
        });

      son20Evrak.map((dosyaEvrak) => {
        son20EvrakLocal.push({
          ...dosyaEvrak,
          ekEvrakListesi: dosyaEvrak?.ekEvrakListesi?.map((ekEvrak: any, index: number) => {
            return {
              ...ekEvrak,
              sira: index + 1,
              location:
                localEvraklar.filter(
                  (item: any) =>
                    item.includes(`${ekEvrak?.evrakId}_`) &&
                    item.includes(`${dosyaNo.replaceAll("/", "-")}`) &&
                    item.includes(`${birimAdi}`)
                )[0] ?? ""
            };
          }),
          location:
            localEvraklar.filter(
              (item: any) =>
                item.includes(`${dosyaEvrak?.evrakId}_`) &&
                item.includes(`${dosyaNo.replaceAll("/", "-")}`) &&
                item.includes(`${birimAdi}`) &&
                item.includes(dosyaEvrak?.tur)
            )[0] ?? ""
        });
      });

      setEvrakWithLocal({
        result: {
          son20Evrak: son20EvrakLocal,
          tumEvraklar: tumEvraklarWithLocal
        }
      });
    }
  }, [localEvraklar, EvraklarReponse]);

  // Tüm evrakları okuma işlemi
  const sendGetAllLocalEvraksMessageToElectron = async () => {
    const res: ElecronEvrakResponseDto<string[]> = await window.electron?.ipcRenderer?.invoke("get-all-local-evraks", {
      dosyaId,
      dosyaTur,
      dosyaNo,
      birimAdi
    });

    if (!res.isSuccessful) {
      toast.show(res.errorMessage, "error");
      return;
    }
    // TODO

    const allEvrakNames = res.result;
    setLocalEvraklar(allEvrakNames);
  };

  // Evrağı okuması için Electron'a mesaj gönderir.
  const sendGetEvrakMessageToElectron = async (getEvrakRequestDto: GetEvrakRequestDto, evrakId?: string) => {
    const response = await window.electron?.ipcRenderer.invoke("get-evrak", getEvrakRequestDto);
    const res: ElecronEvrakResponseDto<ElectronGetEvrakResponseDto> = response;

    if (!res.result.data)
      return setSeciliEvrak({
        mimeType: "text/html",
        data: new Blob(["Dosya açılırken hata oluştu - [hata456]"], {
          type: "text/html"
        })
      });

    if (!res.result.mimeType)
      return setSeciliEvrak({
        mimeType: "text/html",
        data: new Blob(["Dosya açılırken hata oluştu - [hata352]"], {
          type: "text/html"
        })
      });

    if (res.result.mimeType == "application/pkcs7-mime" || res.result.mimeType == "application/eyp") {
      unarchiveZip(new Blob([res.result.data]), evrakId as string);
      return;
    }

    setSeciliEvrak({
      mimeType: res.result.mimeType,
      data: new Blob([res.result.data], { type: "application/download" }) // udf formatlarinda bozuk geliyor
    });
  };

  // Evrağı indirmesi için Electron'a mesaj gönderir.
  const sendDownloadEvrakMessageToElectron = async (downloadEvrakRequestDto: DownloadEvrakRequestDto) => {
    const response = await window.electron?.ipcRenderer?.invoke("download-evrak", downloadEvrakRequestDto);

    const res: ElecronEvrakResponseDto<string> = response;

    if (!res.isSuccessful) {
      toast.show(res.errorMessage, "error");
      return;
    }
    await sendGetAllLocalEvraksMessageToElectron();
    const evrakPath = res.result; // kaydettiği evrağın pathi lazımsa eğer.
    toast.show("Evrak başarıyla indirildi.", "success");
  };

  return (
    <div className="">
      <EvrakListeleyici
        evraklar={evraklarWithLocal}
        mainFolderLabel={`${birimAdi} ${dosyaNo}`}
        onNodeClick={(data) => {
          if (!isBulkLoaderOpen.current) fileSelectedHandler(data);
        }}
        dosyaId={dosyaId}
        dosyaNo={dosyaNo}
        birimAdi={birimAdi}
        dosyaTur={dosyaTur}
        loading={isFileLoading}
        bulkDownloader={(nodes, setDownloadOpen, _ ,setSelectionOn) => (
          <EvrakBulkDownloader
            nodes={nodes}
            dosyaNo={dosyaNo}
            birimAdi={birimAdi}
            dosyaTur={dosyaTur}
            dosyaId={dosyaId}
            setDownloadOpen={setDownloadOpen}
            useContext={useEvrak}
            setSelection={setSelectionOn}
          />
        )}
        onBulkDownloadOpen={(arg) => {
          isBulkLoaderOpen.current = arg;
        }}
      >
        {seciliEvrak && <FileViewer file={seciliEvrak} loading={isFileLoading} />}
      </EvrakListeleyici>
    </div>
  );
};

export default Evrak;

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
