import React, { useContext, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { NodeRef } from "../../types/index";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import PricingPackets from "./PricingPackets";

const LandingPage = () => {
  const [isHidden, setIsHidden] = useState(false);
  const { layoutConfig } = useContext(LayoutContext);
  const menuRef = useRef<HTMLElement | null>(null);
  const navigate = useNavigate();

  const toggleMenuItemClick = () => {
    setIsHidden((prevState) => !prevState);
  };

  return (
    <div className="surface-0 flex justify-content-center">
      <div id="home" className="landing-wrapper overflow-hidden">
        <div className="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
          <Link to="/" className="flex align-items-center">
            <img
              src={`/images/${layoutConfig.colorScheme === "light" ? "logo" : "logo"}.png`}
              alt="Pratik Portal"
              height="50"
              className="mr-3 lg:mr-3"
            />
            <span className="text-900 font-medium text-2xl line-height-3 mr-2 w-12rem">Pratik Portal</span>
          </Link>
          <StyleClass
            nodeRef={menuRef as NodeRef}
            selector="@next"
            enterClassName="hidden"
            leaveToClassName="hidden"
            hideOnOutsideClick
          >
            <i ref={menuRef} className="pi pi-bars text-4xl cursor-pointer block lg:hidden text-700"></i>
          </StyleClass>
          <div
            className={classNames(
              "align-items-center surface-0 flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2",
              { hidden: isHidden }
            )}
            style={{ top: "100%" }}
          >
            <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
              <li>
                <a
                  href="#home"
                  onClick={toggleMenuItemClick}
                  className="p-ripple flex m-0 md:ml-2 xl:ml-4 px-0 py-2 text-900 font-medium line-height-3"
                >
                  <span>Ana Sayfa</span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  href="#features"
                  onClick={toggleMenuItemClick}
                  className="p-ripple flex m-0 md:ml-2 xl:ml-4 px-0 py-3 text-900 font-medium line-height-3"
                >
                  <span>Özellikler</span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  href="#highlights"
                  onClick={toggleMenuItemClick}
                  className="p-ripple flex m-0 md:ml-2 xl:ml-4 px-0 py-3 text-900 font-medium line-height-3"
                >
                  <span>Platformlar</span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  href="#pricing"
                  onClick={toggleMenuItemClick}
                  className="p-ripple flex m-0 md:ml-2 xl:ml-4 px-0 py-3 text-900 font-medium line-height-3"
                >
                  <span>Fiyatlar</span>
                  <Ripple />
                </a>
              </li>
            </ul>
            <div className="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
              <Button
                label="Giriş Yap"
                text
                rounded
                className="border-none font-light line-height-2 text-blue-500 xl:mr-2"
                onClick={() => {
                  navigate("/login");
                }}
              ></Button>
              <Button
                label="Kayıt Ol"
                rounded
                className="border-none font-light line-height-2 bg-blue-500 text-white"
                onClick={() => {
                  navigate("/register");
                }}
              ></Button>
            </div>
          </div>
        </div>

        <div
          id="hero"
          className={classNames("flex flex-column pt-4 px-4 lg:px-8 overflow-hidden", {
            "surface-card": layoutConfig.colorScheme === "dark"
          })}
          style={
            layoutConfig.colorScheme === "light"
              ? {
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EEEFAF 0%, #C3E3FA 100%)",
                  clipPath: "ellipse(150% 87% at 93% 13%)"
                }
              : {
                  clipPath: "ellipse(150% 87% at 93% 13%)"
                }
          }
        >
          <div className="mx-4 md:mx-8 mt-0 md:mt-4">
            <h1 className="text-6xl font-bold  line-height-2">
              <span className="font-light block">Pratik Portal ile farklı bir UYAP deneyimine hazır mısınız?</span>
            </h1>
            <p className="font-normal text-2xl line-height-3 md:mt-3">
              Hukuki süreçlerin yönetiminde verimlilik ve hız, başarıyı belirleyen önemli faktörlerdir.
            </p>
            <p className="font-normal text-2xl line-height-3 md:mt-3">
              İşte tam da bu noktada size yardımcı olacak güçlü bir çözüm sunuyoruz.
            </p>
            <p className="font-normal text-2xl line-height-3 md:mt-3 pb-0">
              UYAP Avukat Portal üzerinden toplu evrak indirme, hızlı evrak önizleme ve indirilen UYAP evraklarının
            </p>
            <p className="font-normal text-2xl line-height-3 md:mt-1 pt-0">
              içerisinde arama yapma gibi özellikleriyle UYAP Portal kullanım deneyiminizi farklılaştırıyoruz.
            </p>
            <Button
              type="button"
              label="Kayıt Ol"
              rounded
              className="text-xl border-none mt-3 bg-blue-500 font-normal line-height-3 px-5 text-white"
              onClick={() => {
                navigate("/register");
              }}
            ></Button>
          </div>
          <div className="flex justify-content-center md:justify-content-end">
            {/* <img src="/images/screen-1.png" alt="Hero Image" className="w-9 md:w-auto" /> */}
            <img src="/images/screen-landing-page.png" alt="Hero Image" className="w-9 md:w-5" />
          </div>
        </div>

        <div id="features" className="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8">
          <div className="grid justify-content-center">
            <div className="col-12 text-center mt-8 mb-4">
              <h2 className="text-900 font-normal mb-2">Özellikler</h2>
              <span className="text-600 text-2xl">Özgün ve Benzersiz Fonksiyonlar</span>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Bireysel Sorgu</h5>
                  <span className="text-600">
                    Bireysel Sorgu ile belirli bir dosya hakkında hızlı ve etkili bir şekilde detaylı bilgi sorgulaması
                    yapabilirsiniz.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-cyan-200 mb-3"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-palette text-2xl text-cyan-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Toplu Sorgu</h5>
                  <span className="text-600">
                    Seçilen dosyalarda istediğiniz sorguları tek tıkla gerçekleştirebilirsiniz.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(145, 226, 237, 0.2), rgba(172, 180, 223, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(246, 158, 188, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-indigo-200"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-map text-2xl text-indigo-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Sorgu Sonuçlarını Kaydetme</h5>
                  <span className="text-600">Sorgu sonuçlarını kaydederek, daha sonra kolayca erişebilirsiniz.</span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(145, 210, 204, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-bluegray-200 mb-2"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-id-card text-2xl text-bluegray-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Sık Kullanılan Dosyalar</h5>
                  <span className="text-600">
                    Sık kullanılan dosyalarınızı ekleyerek daha hızlı ve kolay erişim sağlayabilirsiniz.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(145, 226, 237, 0.2),rgba(160, 210, 250, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-orange-200 mb-2"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-star text-2xl text-orange-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Duruşma Sorgulama</h5>
                  <span className="text-600">
                    Duruşmalarınızı listeleyebilir ve doğrudan takviminize ekleyebilirsiniz.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(251, 199, 145, 0.2), rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(212, 162, 221, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-pink-200 mb-2"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-moon text-2xl text-pink-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Alt Kullanıcılar</h5>
                  <span className="text-600">
                    Alt kullanıcılar oluşturabilir ve UYAP oturmunuzu kart olmadan paylaşabilirsiniz.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(160, 210, 250, 0.2)), linear-gradient(180deg, rgba(187, 199, 205, 0.2), rgba(145, 210, 204, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-teal-200 mb-2"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-shopping-cart text-2xl text-teal-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Arama ve Önizleme</h5>
                  <span className="text-600">
                    UYAP sistemindeki tüm dosya türlerini (UDF, PDF, TİFF, EYP, EML, ZİP, HTML, JPG vb.) hızlıca
                    görüntüleyebilirsiniz.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(251, 199, 145, 0.2), rgba(160, 210, 250, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-blue-200 mb-2"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-globe text-2xl text-blue-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Gelişmiş Evrak Listeleme</h5>
                  <span className="text-600">
                    UYAP portallarındaki evrakları gelişmiş bir şekilde sıralayabilir, sadece evrak adı değil, üst
                    bilgilerde de arama yapabilirsiniz.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 xl:col-4 p-0 p-1 lg:p-3 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: layoutConfig.scale > 15 ? "210px" : layoutConfig.scale > 11 ? "170px" : "110px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(160, 210, 250, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(246, 158, 188, 0.2), rgba(212, 162, 221, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full" style={{ borderRadius: "8px" }}>
                  <div
                    className="flex align-items-center justify-content-center bg-purple-200 mb-2"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px"
                    }}
                  >
                    <i className="pi pi-fw pi-eye text-2xl text-purple-700"></i>
                  </div>
                  <h5 className="mb-1 text-900 mt-2">Toplu Evrak İndirme</h5>
                  <span className="text-600">
                    Tek tık ile vekili/tarafı olduğunuz dosyalarda bulunan evrakları arşivlenmiş bir şekilde
                    bilgisayarınıza indirebilirsiniz.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="highlights" className="py-4 px-4 lg:px-8 mx-0 my-6 lg:mx-8">
          <div className="text-center">
            <h2 className="text-900 font-normal mb-2">Platform Uyumluluğu</h2>
            <span className="text-600 text-2xl">İnternet erişiminizin olduğu her yerde, her zaman</span>
          </div>

          <div className="grid my-8 pt-2 md:pt-4">
            <div className="col-12 lg:col-6 my-auto flex flex-column text-center lg:text-left lg:align-items-start">
              <div
                className="flex align-items-center justify-content-center bg-yellow-200 align-self-center lg:align-self-start"
                style={{
                  width: "4.2rem",
                  height: "4.2rem",
                  borderRadius: "10px"
                }}
              >
                <i className="pi pi-fw pi-desktop text-5xl text-yellow-700"></i>
              </div>
              <h2 className="line-height-1 text-900 text-4xl font-normal">Bilgisayar Üzerinden Erişim</h2>
              <span className="text-700 text-2xl line-height-3 mr-0 md:mr-2" style={{ maxWidth: "650px" }}>
                Uygulamamız, Windows, Linux ve macOS işletim sistemlerinde sorunsuz çalışır ve bilgisayarınızda mükemmel
                performans sunar. İnternet erişiminiz olduğu her yerden kolayca erişebilir ve kullanabilirsiniz.
              </span>
            </div>

            <div
              className="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0"
              style={{ borderRadius: "8px" }}
            >
              <img src="/images/mockup-desktop.svg" className="w-11" alt="mockup" />
            </div>
          </div>
        </div>

        <PricingPackets />

        <div className="py-4 px-4 mx-0 mt-2 lg:mx-8">
          <div className="grid row pt-3">
            <div className="col-12 col-md-9">
              <div>
                <a
                  href="https://www.pitechkurumsal.com/"
                  target="_blank"
                  className="text-900"
                  style={{ color: "#3a4f66", textDecoration: "none", fontSize: "18px" }}
                >
                  Pi Tech Kurumsal Çözümler A.Ş.
                </a>
                <div className="mt-2">
                  <a
                    href="https://wa.me/905377784514"
                    target="_blank"
                    className="footer-contact-item flex align-items-center gap-2"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-geo-alt-fill mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"></path>
                    </svg>
                    Mansuroğlu Mah. Ankara Cad. No:47 D:109 Mavi Plaza Bayraklı/İZMİR
                  </a>
                </div>

                <div className="mt-1">
                  <a
                    href="https://wa.me/905377784514"
                    target="_blank"
                    className="footer-contact-item flex align-items-center gap-2"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-envelope-fill mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"></path>
                    </svg>
                    info@pitechkurumsal.com
                  </a>
                </div>

                <div className="mt-1">
                  <a
                    href="https://wa.me/905377784514"
                    target="_blank"
                    className="footer-contact-item flex align-items-center gap-2"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-telephone-fill mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      ></path>
                    </svg>
                    0 (232) 335 15 41 - 0 (537) 778 45 14
                  </a>
                </div>

                <div className="mt-1">
                  <a
                    href="https://wa.me/905377784514"
                    target="_blank"
                    className="footer-contact-item flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-whatsapp mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                    </svg>
                    0 (537) 778 45 14
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4">
          <div className="flex justify-content-center gap-4">
            <div>
              <a
                href="https://www.facebook.com/people/Pi-Tech-Kurumsal/100087885516557/"
                target="_blank"
                className="footer-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-facebook"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"></path>
                </svg>
              </a>
            </div>
            <div>
              <a href="https://twitter.com/PiTechKurumsal" target="_blank" className="footer-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-twitter"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15"></path>
                </svg>
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/pitechkurumsal/" target="_blank" className="footer-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-instagram"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="py-4 px-4">
          <div className="text-center">
            <p style={{ fontWeight: 300, fontSize: "12px", lineHeight: 1, color: "#3a4f66", marginBottom: 0 }}>
              Copyright © {new Date().getFullYear()} - Pi Tech Kurumsal Çözümler A.Ş.
            </p>
            <p style={{ fontWeight: 300, fontSize: "12px", lineHeight: 1, color: "#3a4f66" }}>Tüm Hakları Saklıdır.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
