import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import accountService from "../../services/account/account.service";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { AppLicenseDto, MonthlyType } from "../../services/admin/admin.dto";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { useToast } from "../../utils/toast";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";
import { ExportFile } from "../../utils/export-file";
import classNames from "classnames";
import { useBasket } from "../../utils/basket-provider";
import { LicenceToBeExtendedDto } from "../../services/payment/payment.dto";

const LicensesPage = (props: any) => {
  const [extendLicense, setExtendLicense] = useState<AppLicenseDto>();
  const [extendMonth, setExtendMonth] = useState<MonthlyType>(MonthlyType.TwelveMonth);
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<AppLicenseDto[]>>(null);
  const [visibleExtendLicense, setVisibleExtendLicense] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext);
  const navigate = useNavigate();
  const basket = useBasket();

  const {
    data: licencesResponse,
    refetch: refetchLicenses,
    isLoading: licensesResponseIsLoading
  } = useQuery({
    queryKey: ["Lisanslar"],
    refetchOnMount: true,
    queryFn: () => accountService.GetLicenses()
  });

  useEffect(() => {
    setBlocked(licensesResponseIsLoading);
  }, [licensesResponseIsLoading]);

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Lisans Satın Al"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            navigate("/app/ucret-tablosu");
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: AppLicenseDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-plus"
          rounded
          outlined
          tooltip="Lisans Süresini Uzat"
          className="mr-2"
          onClick={() => {
            setExtendLicense({ ...rowData });
            setVisibleExtendLicense(true);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: commonFilterContains,
      isActive: commonFilterContains,
      startDate: commonFilterDateIs,
      endDate: commonFilterDateIs
    });
    setGlobalFilterValue("");
  };

  // export excel
  const exportExcel = () => {
    const data = licencesResponse?.result?.data || [];

    const headers = ["İsim", "Aktiflik Durumu", "Başlangıç Tarihi", "Bitiş Tarihi"];

    const filteredData = data.map((data) => ({
      isim: data.name,
      isActive: data.isActive ? "Aktif" : "Pasif",
      baslangicTarihi:
        new Date(data.startDate).toLocaleDateString("tr-TR") +
        " " +
        new Date(data.startDate).toLocaleTimeString("tr-TR"),
      bitisTarihi:
        new Date(data.endDate).toLocaleDateString("tr-TR") + " " + new Date(data.endDate).toLocaleTimeString("tr-TR")
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "Lisanslar");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>
        <DataTable
          ref={dt}
          value={licencesResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} lisans"
          filters={filters}
          // filterDisplay="row"
          loading={licensesResponseIsLoading}
          globalFilterFields={["name", "isActive", "startDate", "endDate"]}
          emptyMessage="Lisans bulunamadı."
          header={CreateHeader("Lisanslar", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
          <Column
            field="name"
            header="İsim"
            sortable
            filterField="name"
            filter
            filterPlaceholder="İsme göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İsme göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="isActive"
            header="Aktiflik Durumu"
            sortable
            filterField="isActive"
            filter
            filterPlaceholder="Aktiflik Durumuna göre arayın"
            body={(rowData: AppLicenseDto) => (
              <div className="flex justify-content-center align-items-center p-0 m-0">
                {rowData.isActive === true ? (
                  <div className="p-0 flex align-items-center">
                    <div className="px-3 py-2 bg-green-100 text-green-900 border-round font-medium text-center">
                      {`AKTİF`}
                    </div>
                  </div>
                ) : (
                  <div className="p-0 flex align-items-center">
                    <div className="px-3 py-2 bg-blue-100 text-blue-900 border-round font-medium text-center">
                      {`PASİF`}
                    </div>
                  </div>
                )}
              </div>
            )}
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Aktiflik Durumuna göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="startDate"
            header="Başlangıç Tarihi"
            sortable
            body={(rowData: AppLicenseDto) => {
              return (
                <span>
                  {new Date(rowData.startDate).toLocaleDateString("tr-TR") +
                    " " +
                    new Date(rowData.startDate).toLocaleTimeString("tr-TR")}
                </span>
              );
            }}
            filterField="startDate"
            filter
            filterPlaceholder="Başlangıç Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Başlangıç Tarihine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="endDate"
            header="Bitiş Tarihi"
            sortable
            body={(rowData: AppLicenseDto) => {
              return (
                <span>
                  {new Date(rowData.endDate).toLocaleDateString("tr-TR") +
                    " " +
                    new Date(rowData.endDate).toLocaleTimeString("tr-TR")}
                </span>
              );
            }}
            filterField="endDate"
            filter
            filterPlaceholder="Bitiş Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Bitiş Tarihine göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visibleExtendLicense}
        modal={true}
        header={"Lisans Süresini Uzat"}
        onHide={() => {
          setExtendLicense(undefined);
          setVisibleExtendLicense(false);
        }}
        footer={
          <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
            <Button
              label={"İptal"}
              className="md:px-6 justify-content-center"
              type="button"
              severity="secondary"
              onClick={() => {
                setExtendLicense(undefined);
                setVisibleExtendLicense(false);
              }}
            />
            <Button
              label={"Kaydet"}
              className="md:px-6 justify-content-center"
              onClick={() => {
                const extendedLicense: LicenceToBeExtendedDto = {
                  licenseId: extendLicense!.id!,
                  name: extendLicense!.name,
                  month: extendMonth
                };

                const userBasket = basket.getBasket();
                const existLicense = userBasket.licencesToBeExtended.find(
                  (x) => x.licenseId === extendedLicense.licenseId
                );
                if (existLicense) {
                  existLicense.month = extendedLicense.month;
                } else {
                  userBasket.licencesToBeExtended.push(extendedLicense);
                }

                basket.setBasket(userBasket);
                toast.show("Lisans başarıyla sepetinize eklendi.", "success");
                navigate("/app/sepetim");
              }}
            />
          </div>
        }
        className=""
        style={{ width: "30vw" }}
      >
        <div className="mt-1">
          <label className="text-primary text-xl" htmlFor="tip">
            Lisans süresini ne kadar uzatmak istersiniz?
          </label>
          <div className="">
            <div className="flex align-items-center mt-3">
              <RadioButton
                inputId="extendLicenseMonthly"
                name="extendLicense"
                value={MonthlyType.Monthly}
                onChange={(e) => setExtendMonth(e.value)}
                checked={extendMonth === MonthlyType.Monthly}
              />
              <label
                className={classNames("ml-2", { "text-primary": extendMonth === MonthlyType.Monthly })}
                htmlFor="extendLicenseMonthly"
              >
                1 Ay (Aylık: 499 TL - Toplam: 499 TL)
              </label>
            </div>
            <div className="flex align-items-center mt-3">
              <RadioButton
                inputId="extendLicenseThreeMonth"
                name="extendLicense"
                value={MonthlyType.ThreeMonth}
                onChange={(e) => setExtendMonth(e.value)}
                checked={extendMonth === MonthlyType.ThreeMonth}
              />
              <label
                className={classNames("ml-2", { "text-primary": extendMonth === MonthlyType.ThreeMonth })}
                htmlFor="extendLicenseThreeMonth"
              >
                3 Ay (Aylık: 430 TL - Toplam: 1,290 TL)
              </label>
            </div>
            <div className="flex align-items-center mt-3">
              <RadioButton
                inputId="extendLicenseSixMonth"
                name="extendLicense"
                value={MonthlyType.SixMonth}
                onChange={(e) => setExtendMonth(e.value)}
                checked={extendMonth === MonthlyType.SixMonth}
              />
              <label
                className={classNames("ml-2", { "text-primary": extendMonth === MonthlyType.SixMonth })}
                htmlFor="extendLicenseSixMonth"
              >
                6 Ay (Aylık: 350 TL - Toplam: 2,100 TL)
              </label>
            </div>
            <div className="flex align-items-center mt-3">
              <RadioButton
                inputId="extendLicenseTwelveMonth"
                name="extendLicense"
                value={MonthlyType.TwelveMonth}
                onChange={(e) => setExtendMonth(e.value)}
                checked={extendMonth === MonthlyType.TwelveMonth}
              />
              <label
                className={classNames("ml-2", { "text-primary": extendMonth === MonthlyType.TwelveMonth })}
                htmlFor="extendLicenseTwelveMonth"
              >
                12 Ay (Aylık: 250 TL - Toplam: 3,000 TL)
              </label>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default LicensesPage;
