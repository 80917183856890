import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { NoContentDto } from "../base/base.dto";
import {
  TalepBilgisiDto,
  CreateTalepBilgisiDto,
  UpdateTalepBilgisiDto,
} from "./talep-bilgisi.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TalepBilgisiDto>(`TalepBilgisi/Get/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TalepBilgisiDto[]>(`TalepBilgisi/GetAll`, config);
  },
  GetAllByIcraFoy(
    icraFoyId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<TalepBilgisiDto[]>(
      `TalepBilgisi/GetAllByIcraFoy/${icraFoyId}`,
      config
    );
  },
  Create(
    dto: CreateTalepBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TalepBilgisiDto>("TalepBilgisi/Create", dto, config);
  },
  Update(
    dto: UpdateTalepBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<TalepBilgisiDto>("TalepBilgisi/Update", dto, config);
  },
  RetUyarisiKaldir(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<NoContentDto>(
      `TalepBilgisi/RetUyarisiKaldir/${id}`,
      config
    );
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`TalepBilgisi/Delete/${id}`, config);
  },
};

export default services;
