import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import TarafBilgileri from "../dosya-detaylari/components/TarafBilgileri";
import BagkurCalisaniDataTable from "./components/BagkurCalisaniDataTable";
import BankaDataTable from "./components/BankaDataTable";
import GsmDataTable from "./components/GsmDataTable";
import SorunluVeOlumsuzDataTable from "./components/SorunluVeOlumsuzDataTable";
import TahsilatKalaniDataTable from "./components/TahsilatKalaniDataTable";
import MernisDataTable from "./components/MernisDataTable";
import MersisDataTable from "./components/MersisDataTable";
import IskiDataTable from "./components/IskiDataTable";
import GibDataTable from "./components/GibDataTable";
import DisIsleriDataTable from "./components/DisIsleriDataTable";
import SskCalisaniDataTable from "./components/SskCalisaniDataTable";
import SskEmekliDataTable from "./components/SskEmekliDataTable";
import KamuCalisaniDataTable from "./components/KamuCalisaniDataTable";
import KamuEmeklisiDataTable from "./components/KamuEmeklisiDataTable";
import BagkurEmeklisiDataTable from "./components/BagkurEmeklisiDataTable";
import IsYeriDataTable from "./components/IsYeriDataTable";
import EgmDataTable from "./components/EgmDataTable";
import PostaCekiDataTable from "./components/PostaCekiDataTable";
import TakbisDataTable from "./components/TakbisDataTable";
import SgkHacizDataTable from "./components/SgkHacizDataTable";
import IcraDosyasiDataTable from "./components/IcraDosyasiDataTable";
import TarafDataTable from "./components/TarafDataTable";
import { useHub } from "../../utils/buro-hub";
import {
  dateModel,
  DosyaSorguDurum,
  TabsDto,
  TopluSorguDurum,
  TopluSorguModel,
  TopluSorguOrtakModel,
  TopluSorguTur
} from "./TopluSorgu.dto";
import { useToast } from "../../utils/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Filter } from "../filter/base-filter";
import { useTopluSorguIslemler } from "../../utils/toplu-sorgu-islemler";
import { useLocation } from "react-router-dom";
import { Fieldset } from "primereact/fieldset";

const TopluSorgulama = () => {
  const location = useLocation();
  const hub = useHub();
  const toast = useToast();
  const TopluSorguIslemler = useTopluSorguIslemler();

  let topluSorguModelLocation: TopluSorguModel = {
    key: 0,
    status: TopluSorguDurum.BASLATILMADI,
    baslamaTarihi: null,
    listName: "",
    tabs: [],
    sorgulanacakDosyalar: []
  };

  if (location.state && location.state.model) {
    const jsonString = JSON.stringify(location.state.model);
    topluSorguModelLocation =
      location.state.model.status === TopluSorguDurum.DEVAM_EDİYOR
        ? TopluSorguIslemler.topluSorguModel
        : (JSON.parse(jsonString) as TopluSorguModel);
  }
  const [topluSorguModel, setTopluSorguModel] = useState<TopluSorguModel>(topluSorguModelLocation);

  const tabs: TabsDto[] = [
    {
      title: "SORUNLU VE OLUMSUZLAR",
      type: TopluSorguTur.SORUNLU_VE_OLUMSUZ
    },
    {
      title: "TAHSİLAT KALANI",
      type: TopluSorguTur.TAHSILAT_KALANI
    },
    {
      title: "MERNİS",
      type: TopluSorguTur.MERNIS
    },
    {
      title: "MERSİS",
      type: TopluSorguTur.MERSIS
    },
    {
      title: "İSKİ",
      type: TopluSorguTur.ISKI
    },
    {
      title: "GİB",
      type: TopluSorguTur.GIB
    },
    {
      title: "GSM",
      type: TopluSorguTur.GSM
    },
    {
      title: "DIŞ İŞLERİ",
      type: TopluSorguTur.DIS_ISLERI
    },
    {
      title: "SSK ÇALIŞANI",
      type: TopluSorguTur.SSK_CALISANI
    },
    // {
    //   title: "Ssk Emeklisi",
    //   type: TopluSorguTur.SSK_EMEKLISI
    // },
    {
      title: "KAMU ÇALIŞANI",
      type: TopluSorguTur.KAMU_CALISANI
    },
    {
      title: "KAMU EMEKLİSİ",
      type: TopluSorguTur.KAMU_EMEKLISI
    },
    {
      title: "BAĞKUR ÇALIŞANI",
      type: TopluSorguTur.BAGKUR_CALISANI
    },
    {
      title: "İŞ YERİ",
      type: TopluSorguTur.SGK_ISYERI
    },
    {
      title: "EGM",
      type: TopluSorguTur.EGM
    },
    {
      title: "POSTA ÇEKİ",
      type: TopluSorguTur.POSTA_CEKI
    },
    {
      title: "TAKBİS",
      type: TopluSorguTur.TAKBIS
    },
    {
      title: "SGK HACİZ",
      type: TopluSorguTur.SGK_HACIZ
    },
    {
      title: "İCRA DOSYASI",
      type: TopluSorguTur.ICRA_DOSYASI
    },
    {
      title: "BANKA HESABI",
      type: TopluSorguTur.BANKA
    },
    {
      title: "TARAF",
      type: TopluSorguTur.TARAF
    }
  ];

  const OnClickStart = async (): Promise<void> => {
    await TopluSorguIslemler.startTopluSorgu(topluSorguModel);
  };

  return (
    <div className="card p-2">
      <div className="grid p-0">
        <div className="col-12 xl:col-5 p-3">
          <Fieldset
            legend={`Sorgulanacak Dosya Listesi (${topluSorguModel.sorgulanacakDosyalar.length})`}
            className="h-30rem md:h-28rem"
            style={{ overflowY: "auto" }}
          >
            {/* <div className="card h-30rem md:h-25rem" style={{ overflowY: "auto" }}> */}
            <DataTable
              value={topluSorguModel.sorgulanacakDosyalar}
              key={"dosyaNo"}
              showGridlines
              stripedRows
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              tableClassName="border-round-3xl"
              emptyMessage="Hiç Toplu Sorgu Listesi Bulunamadı"
              columnResizeMode="expand"
              //   selectionMode={"single"}
              //   selection={selectedTopluSorguListesi}
              //   onSelectionChange={(e) => setSelectedTopluSorguListesi(e.value as TopluSorguListesiModel)}
            >
              <Column
                field="birimAdi"
                header="BİRİM ADI"
                sortable
                filter
                filterField="birimAdi"
                filterPlaceholder="Birim Adına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
              />
              <Column
                field="dosyaNo"
                header="DOSYA NO"
                sortable
                filter
                filterField="dosyaNo"
                filterPlaceholder="Birim Adına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
              />
              <Column
                field="isSorgulandi"
                header="SORGULANMA DURUMU"
                sortable
                body={(rowData) => (
                  <span>
                    {rowData.isSorgulandi === DosyaSorguDurum.DOSYA_BULUNAMADI
                      ? "Dosya Bulunamadı"
                      : rowData.isSorgulandi === DosyaSorguDurum.SORGULANDI
                        ? "Sorgulandı"
                        : rowData.isSorgulandi === DosyaSorguDurum.SORGULANMADI
                          ? "Sorgulanmadı"
                          : "Hata Oluştu"}
                  </span>
                )}
                filter
                filterField="isSorgulandi"
                filterPlaceholder="Birim Adına göre arayın"
                filterClear={Filter.FilterClearTemplate}
                filterApply={Filter.FilterApplyTemplate}
                filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
              />
            </DataTable>
            {/* </div> */}
          </Fieldset>
        </div>

        <div className="col-12 xl:col-7 p-3">
          <Fieldset legend="Sorgu Türleri" className="h-30rem md:h-28rem" style={{ overflowY: "auto" }}>
            {/* <div className="card h-30rem md:h-25rem" style={{ overflowY: "auto" }}> */}
            <div className="grid">
              <div className="grid">
                {tabs.slice(1).map((item, index) => (
                  <div key={index} className="col-12 md:col-6 lg:col-3">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId={item.title}
                        name="category"
                        onChange={(e) => {
                          if (e.checked) {
                            topluSorguModel.tabs.push(item.type!);
                          } else {
                            const index = topluSorguModel.tabs.indexOf(item.type!);
                            if (index > -1) {
                              topluSorguModel.tabs.splice(index, 1);
                            }
                          }
                          setTopluSorguModel({ ...topluSorguModel });
                          // topluSorguModel.tabs = [...new Set(topluSorguModel.tabs)];
                        }}
                        checked={topluSorguModel.tabs.includes(item.type!)}
                        disabled={topluSorguModel.status !== TopluSorguDurum.BASLATILMADI}
                      />
                      <label htmlFor={item.title} className="ml-2">
                        {item.title}
                      </label>
                    </div>
                  </div>
                ))}
                <div className="col-12 md:col-6 lg:col-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="selectAll"
                      name="selectAll"
                      value="selectAll"
                      onChange={(e) => {
                        if (e.checked) {
                          tabs.forEach((tab) => {
                            topluSorguModel.tabs.push(tab.type!);
                          });
                        } else {
                          topluSorguModel.tabs = [1];
                        }
                        setTopluSorguModel({ ...topluSorguModel });
                      }}
                      checked={tabs.every((p) => topluSorguModel.tabs.includes(p.type!))}
                      disabled={topluSorguModel.status !== TopluSorguDurum.BASLATILMADI}
                    />
                    <label htmlFor="selectAll" className="ml-2">
                      HEPSİNİ SEÇ
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid mt-5">
              <Button
                label={
                  topluSorguModel.status === TopluSorguDurum.TAMAMLANMADI
                    ? "Toplu Sorguya Devam Et"
                    : "Toplu Sorgu Başlat"
                  // TopluSorguIslemler.topluSorguYapiliyorMu === true
                  //   ? "Şuan Bir Toplu Sorgu Yapılıyor"
                  //   : topluSorguModel.status === TopluSorguDurum.DEVAM_EDİYOR
                  //     ? "Toplu Sorguyuya Devam Et"
                  //     : "Toplu Sorguyu Başlat"
                }
                size="large"
                // disabled={
                //   TopluSorguIslemler.topluSorguYapiliyorMu ||
                //   (justLook && topluSorguModel.status === TopluSorguDurum.TAMAMLANDI)
                // }

                //BASLATILMADI = 1,
                // DEVAM_EDİYOR,
                // TAMAMLANMADI,
                // TAMAMLANDI
                visible={
                  topluSorguModel.status === TopluSorguDurum.TAMAMLANMADI ||
                  topluSorguModel.status === TopluSorguDurum.BASLATILMADI
                }
                onClick={async () => {
                  if (TopluSorguIslemler.topluSorguYapiliyorMu) {
                    toast.show("Aynı anda birden fazla sayıda toplu sorgu başlatılamaz.", "error");
                    return;
                  }

                  if (topluSorguModel.tabs.length <= 1) {
                    toast.show("En az bir sorgu türü seçmelisiniz.", "error");
                    return;
                  }

                  const key = hub.generateRandomString();
                  const oturumVarMi = await hub.reserveUyapSession(key);
                  if (oturumVarMi) {
                    await OnClickStart();
                    await hub.releaseUyapSession(key);
                  }
                }}
              />
            </div>
            {/* </div> */}
          </Fieldset>
        </div>
      </div>

      <div className="card m-2" style={{ overflowY: "auto" }}>
        <TabView className="" scrollable>
          {tabs
            .filter((tab) => topluSorguModel.tabs.includes(tab.type!))
            .map((tab) => (
              <TabPanel key={tab.type} header={tab.title}>
                <div style={{ height: "400px", overflowY: "auto" }}>
                  {tab.type === TopluSorguTur.SORUNLU_VE_OLUMSUZ && (
                    <SorunluVeOlumsuzDataTable tableData={topluSorguModel.sorunluVeOlumsuzListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.TAHSILAT_KALANI && (
                    <TahsilatKalaniDataTable tableData={topluSorguModel.tahsilatKalaniListeleri || []} />
                  )}

                  {tab.type === TopluSorguTur.MERNIS && (
                    <MernisDataTable tableData={topluSorguModel.mernisListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.MERSIS && (
                    <MersisDataTable tableData={topluSorguModel.mersisListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.ISKI && <IskiDataTable tableData={topluSorguModel.iskiListeleri || []} />}
                  {tab.type === TopluSorguTur.GIB && <GibDataTable tableData={topluSorguModel.gibListeleri || []} />}
                  {tab.type === TopluSorguTur.GSM && <GsmDataTable tableData={topluSorguModel.gsmListeleri || []} />}
                  {tab.type === TopluSorguTur.DIS_ISLERI && (
                    <DisIsleriDataTable tableData={topluSorguModel.disIsleriListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.SSK_CALISANI && (
                    <SskCalisaniDataTable tableData={topluSorguModel.sskCalisaniListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.SSK_EMEKLISI && (
                    <SskEmekliDataTable tableData={topluSorguModel.sskEmeklisiListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.KAMU_CALISANI && (
                    <KamuCalisaniDataTable tableData={topluSorguModel.kamuCalisaniListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.KAMU_EMEKLISI && (
                    <KamuEmeklisiDataTable tableData={topluSorguModel.kamuEmeklisiListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.BAGKUR_CALISANI && (
                    <BagkurCalisaniDataTable tableData={topluSorguModel.bagkurCalisaniListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.BAGKUR_EMEKLISI && (
                    <BagkurEmeklisiDataTable tableData={topluSorguModel.bagkurEmeklisiListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.SGK_ISYERI && (
                    <IsYeriDataTable tableData={topluSorguModel.sgkIsyeriListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.EGM && <EgmDataTable tableData={topluSorguModel.egmListeleri || []} />}
                  {tab.type === TopluSorguTur.POSTA_CEKI && (
                    <PostaCekiDataTable tableData={topluSorguModel.postaCekiListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.TAKBIS && (
                    <TakbisDataTable tableData={topluSorguModel.takbisListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.SGK_HACIZ && (
                    <SgkHacizDataTable tableData={topluSorguModel.sgkHacizListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.ICRA_DOSYASI && (
                    <IcraDosyasiDataTable tableData={topluSorguModel.icraDosyasiListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.BANKA && (
                    <BankaDataTable tableData={topluSorguModel.bankaListeleri || []} />
                  )}
                  {tab.type === TopluSorguTur.TARAF && (
                    <TarafDataTable tableData={topluSorguModel.tarafListeleri || []} />
                  )}
                </div>
              </TabPanel>
            ))}
        </TabView>
      </div>
    </div>
  );
};

export default TopluSorgulama;
