import { FormikErrors, useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { UpdateIcraFoyMuvekkilDto } from "../../../../services/icra-foy/icra-foy.dto";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import {
  MuvekkilDto,
  MuvekkilTipi,
} from "../../../../services/muvekkil/muvekkil.dto";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import DropdownDatas from "../../../../utils/DropdownValues";

const UpsertIcraFoyMuvekkil = (props: any) => {
  const icraFoyMuvekkil: UpdateIcraFoyMuvekkilDto = props.icraFoyMuvekkil;
  const setIcraFoyMuvekkil = props.setIcraFoyMuvekkil;
  const onUpsert = props.onUpsert;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const muvekkils: MuvekkilDto[] = props.muvekkils?.map((x: MuvekkilDto) => {
    return {
      ...x,
      adSoyad:
        x.tip === MuvekkilTipi.Gercek
          ? x.adSoyad + " (T.C. " + x.tcNo + ")"
          : x.unvan + " (VKN " + x.vergiKimlikNumarasi + ")",
    };
  });

  let updateIcraFoyMuvekkilDto: UpdateIcraFoyMuvekkilDto;

  if (icraFoyMuvekkil === null || icraFoyMuvekkil === undefined) {
    updateIcraFoyMuvekkilDto = {
      icraFoyId: 0,
      tip: "",
      muvekkilId: 0,
    };
  } else {
    updateIcraFoyMuvekkilDto = {
      ...icraFoyMuvekkil,
    };
  }

  const formik = useFormik<UpdateIcraFoyMuvekkilDto>({
    initialValues: updateIcraFoyMuvekkilDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateIcraFoyMuvekkilDto> = {};
      if (!data.muvekkilId) {
        errors.muvekkilId = "Müvekkil alanı boş bırakılamaz";
      }
      if (!data.tip) {
        errors.tip = "Tip alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      onUpsert(formik.values);
      setVisible(false);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.muvekkilId === 0 ? "Müvekkil Ekle" : "Müvekkil Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        props.setIcraFoyMuvekkil(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={() => formik.submitForm()}
          />
        </div>
      }
      className=""
      style={{ width: "50vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12">
          <label className="text-primary" htmlFor="muvekkilId">
            Muvekkil
          </label>
          <Dropdown
            id="muvekkilId"
            name="muvekkilId"
            filter
            filterBy="adSoyad,tcNo,unvan"
            value={formik.values.muvekkilId}
            onChange={(e: MultiSelectChangeEvent) => {
              formik.setFieldValue("muvekkilId", e.value);
            }}
            options={muvekkils}
            optionLabel="adSoyad"
            itemTemplate={(item: MuvekkilDto) => {
              return (
                <div className="flex align-items-center">
                  <span>{item.adSoyad}</span>
                </div>
              );
            }}
            optionValue="id"
            placeholder="Müvekkil Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "muvekkilId"),
            })}
          />
          {FormikValueIsValid(formik, "muvekkilId") && (
            <div className="p-error mt-3">{formik.errors.muvekkilId}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="sahibi">
            Tip
          </label>
          <Dropdown
            id="tip"
            name="tip"
            value={formik.values.tip}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tip", e.value);
            }}
            options={DropdownDatas.GetMuvekkilTypes()}
            optionLabel="label"
            optionValue="value"
            placeholder="Tip Seçiniz."
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tip"),
            })}
          />
          {FormikValueIsValid(formik, "tip") && (
            <div className="p-error mt-3">{formik.errors.tip}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertIcraFoyMuvekkil;
