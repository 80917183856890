import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { KarsiYanDto } from "../../../../services/karsi-yan/karsi-yan.dto";
import { MuvekkilTipi } from "../../../../services/muvekkil/muvekkil.dto";
import { TelephoneDto } from "../../../../services/telephone/telephone.dto";
import DropdownDatas from "../../../../utils/DropdownValues";
import { EmailDto } from "../../../../services/email/email.dto";
import { InputText } from "primereact/inputtext";
import EmailService from "../../../../services/email/email.service";
import TelephoneService from "../../../../services/telephone/telephone.service";
import { useContext, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import UpsertKarsiYan from "../../../karsi-yan/UpsertKarsiYan";
import { IcraFoyKarsiYanDto } from "../../../../services/icra-foy/icra-foy.dto";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const IletisimBilgileri = (props: {
  borclular: IcraFoyKarsiYanDto[];
  onUpsert: any;
  selectedBorcluId: number | undefined;
}) => {
  const [visibleUpsertKarsiYanDialog, setVisibleUpsertKarsiYanDialog] =
    useState<boolean>(false);
  const toast = useToast();

  const { setBlocked } = useContext(LayoutContext)
  const [karsiYan, setKarsiYan] = useState<KarsiYanDto>();
  const borclular: KarsiYanDto[] = props.borclular.map((x) => x.karsiYan);
  const telefonlar: TelephoneDto[] = borclular
    .map((borclu) => borclu.telefonlar)
    .flat();

  const deleteEmail = (rowData: EmailDto) => {
    setBlocked(true)
    EmailService.Delete(rowData.id!).then((res) => {
      if (!res.result?.error) {
        toast.show("E-posta başarıyla silindi.", "success");
        props.onUpsert();
      }
    }).finally(() => setBlocked(false));
  };

  const deleteEmailConfirm = (rowData: EmailDto) => {
    confirmDialog({
      message: "E-postayı silmek istediğinize emin misiniz?",
      header: "E-posta Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteEmail(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
    });
  };

  const emailActionBodyTemplate = (rowData: EmailDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setKarsiYan(borclular.find((p) => p.id === rowData.karsiYanId));
            setVisibleUpsertKarsiYanDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Sil"
          rounded
          outlined
          severity="danger"
          onClick={() => {
            setKarsiYan(borclular.find((p) => p.id === rowData.karsiYanId));
            deleteEmailConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const deleteTelephone = (rowData: TelephoneDto) => {
    setBlocked(true)
    TelephoneService.Delete(rowData.id!).then((res) => {
      if (!res.result?.error) {
        toast.show("Telefon başarıyla silindi.", "success");
        props.onUpsert();
      }
    });
  };

  const deleteTelephoneConfirm = (rowData: TelephoneDto) => {
    confirmDialog({
      message: "Telefonu silmek istediğinize emin misiniz?",
      header: "Telefon Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteTelephone(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
    });
  };

  const telephoneActionBodyTemplate = (rowData: TelephoneDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setKarsiYan(borclular.find((p) => p.id === rowData.karsiYanId));
            setVisibleUpsertKarsiYanDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Sil"
          rounded
          outlined
          severity="danger"
          onClick={() => {
            setKarsiYan(borclular.find((p) => p.id === rowData.karsiYanId));
            deleteTelephoneConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const emails: EmailDto[] = borclular.map((borclu) => borclu.epostalar).flat();

  const [telephoneFilters, setTelephoneFilters] = useState<DataTableFilterMeta>(
    {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }
  );
  const [telephoneGlobalFilterValue, setTelephoneGlobalFilterValue] =
    useState<string>("");
  const onTelephoneGlobalFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    let _filters = { ...telephoneFilters };
    // @ts-ignore
    _filters["global"].value = value;
    setTelephoneFilters(_filters);
    setTelephoneGlobalFilterValue(value);
  };

  const [emailFilters, setEmailFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [emailGlobalFilterValue, setEmailGlobalFilterValue] =
    useState<string>("");
  const onEmailGlobalFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    let _filters = { ...emailFilters };
    // @ts-ignore
    _filters["global"].value = value;
    setEmailFilters(_filters);
    setEmailGlobalFilterValue(value);
  };

  const telephoneHeader = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Telefonlar</h4>
      <Button
        label="Yeni Telefon"
        icon="pi pi-plus"
        severity="success"
        onClick={() => {
          setKarsiYan(borclular.find((p) => p.id === props.selectedBorcluId));
          setVisibleUpsertKarsiYanDialog(true);
        }}
      />
    </div>
  );

  const emailHeader = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">E-postalar</h4>
      <Button
        label="Yeni Eposta"
        icon="pi pi-plus"
        severity="success"
        onClick={() => {
          setKarsiYan(borclular.find((p) => p.id === props.selectedBorcluId));
          setVisibleUpsertKarsiYanDialog(true);
        }}
      />
    </div>
  );

  return (
    <>
      <DataTable
        key={1}
        value={telefonlar}
        showGridlines
        header={telephoneHeader}
      >
        <Column
          body={telephoneActionBodyTemplate}
          exportable={false}
          style={{ minWidth: "9rem" }}
        ></Column>
        <Column
          field="creationTime"
          header="Kayıt Tarihi"
          sortable
          body={(rowData: TelephoneDto) => {
            return (
              <span>
                {rowData.creationTime
                  ? new Date(rowData.creationTime).toLocaleDateString()
                  : ""}
              </span>
            );
          }}
        ></Column>
        <Column
          field="adSoyad"
          header="Borçlu"
          sortable
          body={(rowData: TelephoneDto) => {
            return (
              <span>
                {borclular.find((p) => p.id === rowData.karsiYanId)?.adSoyad}
              </span>
            );
          }}
        ></Column>
        <Column
          field="tcNo"
          header="TC No"
          sortable
          body={(rowData: TelephoneDto) => {
            return (
              <span>
                {borclular.find((p) => p.id === rowData.karsiYanId)?.tcNo}
              </span>
            );
          }}
        ></Column>
        <Column
          field="tip"
          header="Borçlu Tipi"
          sortable
          body={(rowData: TelephoneDto) => {
            return (
              <span>
                {borclular.find((p) => p.id === rowData.karsiYanId)?.tip ===
                  MuvekkilTipi.Gercek
                  ? "Gerçek"
                  : "Tüzel"}
              </span>
            );
          }}
        ></Column>
        <Column field="numara" header="Numara" sortable></Column>
        <Column
          field="sahibi"
          header="Sahibi"
          sortable
          body={(rowData: TelephoneDto) => {
            return (
              <span>
                {
                  DropdownDatas.GetTelefonSahibi().find(
                    (x) => x.value === rowData.sahibi
                  )?.label
                }
              </span>
            );
          }}
        ></Column>
        <Column field="aciklama" header="Açıklama" sortable></Column>
        <Column
          field="mernisMi"
          header="İletişim Onayı Var Mı?"
          body={(rowData: TelephoneDto) => {
            return <span>{rowData.iletisimOnayiVarMi ? "Evet" : "Hayır"}</span>;
          }}
        ></Column>
      </DataTable>

      <DataTable
        key={2}
        value={emails}
        header={emailHeader}
        className="mt-5"
        showGridlines
      >
        <Column
          body={emailActionBodyTemplate}
          exportable={false}
          style={{ minWidth: "9rem" }}
        ></Column>
        <Column
          field="creationTime"
          header="Kayıt Tarihi"
          sortable
          body={(rowData: EmailDto) => {
            return (
              <span>
                {rowData.creationTime
                  ? new Date(rowData.creationTime).toLocaleDateString()
                  : ""}
              </span>
            );
          }}
        ></Column>
        <Column
          field="adSoyad"
          header="Borçlu"
          sortable
          body={(rowData: EmailDto) => {
            return (
              <span>
                {borclular.find((p) => p.id === rowData.karsiYanId)?.adSoyad}
              </span>
            );
          }}
        ></Column>
        <Column
          field="tcNo"
          header="TC No"
          sortable
          body={(rowData: EmailDto) => {
            return (
              <span>
                {borclular.find((p) => p.id === rowData.karsiYanId)?.tcNo}
              </span>
            );
          }}
        ></Column>
        <Column
          field="tip"
          header="Borçlu Tipi"
          sortable
          body={(rowData: EmailDto) => {
            return (
              <span>
                {borclular.find((p) => p.id === rowData.karsiYanId)?.tip ===
                  MuvekkilTipi.Gercek
                  ? "Gerçek"
                  : "Tüzel"}
              </span>
            );
          }}
        ></Column>
        <Column field="epostaAdresi" header="E-posta Adresi" sortable></Column>
        <Column
          field="iletisim"
          header="İletişim izni var mı?"
          sortable
          body={(rowData: EmailDto) => {
            return <span>{rowData.iletisim === true ? "Evet" : "Hayır"}</span>;
          }}
        ></Column>
        <Column field="aciklama" header="Açıklama" sortable></Column>
      </DataTable>

      <UpsertKarsiYan
        visible={visibleUpsertKarsiYanDialog}
        setVisible={setVisibleUpsertKarsiYanDialog}
        karsiYan={karsiYan}
        setKarsiYan={setKarsiYan}
        refetchKarsiYan={props.onUpsert}
      />
    </>
  );
};

export default IletisimBilgileri;
