import React from "react";
import { UdfViewer } from "./UdfViewer";
import { HtmlViewer } from "./HtmlViewer";
import { PDFViewer } from "./PDFViewer";
import { TiffViewer } from "./TiffViewer";
import { IMGViewer } from "./IMGViewer";
import { ViewerBaseProps } from "./fileViewer.dto";
import { ProgressSpinner } from "primereact/progressspinner";
import { XMLViewer } from "./XMLViewer";

interface FileViewerProps extends ViewerBaseProps {
  loading: boolean;
}

export const FileViewer: React.FC<FileViewerProps> = ({ file, parentWidth, loading }) => {
  
  const renderer = () => {
    if (!file?.data) {
      console.error("FileViewer: data not founded");
      return <p>Dosya açılırken Bir hata oluştu</p>;
    }
    /*
    eger uyap tarafinda dosyanin goruntulenmesi ile ilgili bir problem varsa evrak response
    application/download degil text/html olarak donuyor alttaki durumda hatayi direkt ekrana
    basiyoruz
    */
    if (loading)
      return (
        <div className="flex justify-content-center align-items-center h-full">
          <ProgressSpinner />
        </div>
      );
    if (file?.data.type === "text/html") return <HtmlViewer file={file} parentWidth={parentWidth} isError />;
    switch (file?.mimeType) {
      case "text/udf":
        return <UdfViewer file={file} parentWidth={parentWidth} />;
      case "text/html":
        return <HtmlViewer file={file} parentWidth={parentWidth} />;
      case "application/jasperreport":
        return <PDFViewer file={file} parentWidth={parentWidth} />;
      case "application/pdf":
        return <PDFViewer file={file} parentWidth={parentWidth} />;
      case "image/tiff":
        return <TiffViewer file={file} parentWidth={parentWidth} />;
      case "image/png":
        return <IMGViewer file={file} parentWidth={parentWidth} />;
      case "image/jpeg":
        return <IMGViewer file={file} parentWidth={parentWidth} />;
      case "application/stylereport":
        return <PDFViewer file={file} parentWidth={parentWidth} />;
      case "text/xml":
        return <XMLViewer file={file} parentWidth={parentWidth} />
      case "application/pkcs7-mime":
        return
      default:
        console.error('mimeType', file?.mimeType)
        return (
          <div className="flex justify-content-center align-items-center h-full">
            <p>Bu evrak tipi desteklenmemektedir. Lütfen kayıtlı klasörünüzden açmayı deneyiniz.</p>
          </div>
        );
    }
  };

  return <div className="overflow-x-scroll border-1 border-200 border-rounded h-full">{renderer()}</div>;
};
