import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  DigerAyrintiDto,
  CreateDigerAyrintiDto,
  UpdateDigerAyrintiDto,
} from "./diger-ayrinti.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<DigerAyrintiDto>(`DigerAyrinti/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<DigerAyrintiDto[]>(`DigerAyrinti`, config);
  },
  Create(
    dto: CreateDigerAyrintiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<DigerAyrintiDto>("DigerAyrinti", dto, config);
  },
  Update(
    dto: UpdateDigerAyrintiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<DigerAyrintiDto>("DigerAyrinti", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`DigerAyrinti/${id}`, config);
  },
};

export default services;
