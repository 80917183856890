import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguDisIslerModel } from "../TopluSorgu.dto";
import { commonFilterContains, CreateHeader, Filter } from "../../filter/base-filter";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ExportFile } from "../../../utils/export-file";

const DisIsleriDataTable = (props: { tableData: TopluSorguDisIslerModel[] }) => {
  const { tableData } = props;
  const disIsleriVeri = tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguDisIslerModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      adresTuru: commonFilterContains,
      sehir: commonFilterContains,
      adres: commonFilterContains,
      postaKodu: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Adres Türü",
      "Şehir",
      "Adres",
      "Posta Kodu"
    ];

    const filteredData = disIsleriVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      adresTuru: data.adresTuru,
      sehir: data.sehir,
      adres: data.adres,
      postaKodu: data.postaKodu
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluDisIsleri");
  };

  return (
    <div>
      <DataTable
        value={disIsleriVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="index"
        showGridlines
        emptyMessage="Dıs İşleri Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Dış İşleri Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "adresTuru",
          "sehir",
          "adres",
          "postaKodu"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filter
          filterField="ortakDegerler.dosyaNo"
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filter
          filterField="ortakDegerler.alacakliAdi"
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filter
          filterField="ortakDegerler.borcluAdi"
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filter
          filterField="ortakDegerler.borcluTCVKNo"
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filter
          filterField="ortakDegerler.dosyaDurumu"
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Adres Türü"
          field="adresTuru"
          filter
          filterField="adresTuru"
          filterPlaceholder="Adres Türü'ne göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Adres Türü'ne göre filtreleyin</div>}
        />
        <Column
          header="Şehir"
          field="sehir"
          filter
          filterField="sehir"
          filterPlaceholder="Şehir'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Şehir'e göre filtreleyin</div>}
        />
        <Column
          header="Adres"
          field="adres"
          filter
          filterField="adres"
          filterPlaceholder="Adres'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Adres'e göre filtreleyin</div>}
        />
        <Column
          header="Posta Kodu"
          field="postaKodu"
          filter
          filterField="postaKodu"
          filterPlaceholder="Posta Kodu'na göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Posta Kodu'na göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default DisIsleriDataTable;
