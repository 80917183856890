import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import adminService from "../../../services/admin/admin.services";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { UyapDosyaDto } from "../../../services/uyap-dosya/uyap-dosya.dto";
import { commonFilterContains, CreateHeader, Filter } from "../../filter/base-filter";
import DosyaDetaylariDialog from "../../dosya-detaylari/DosyaDetaylariDialog";
import { useHub } from "../../../utils/buro-hub";
import uyapService from "../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { DosyaDTO, RequestDosyaTarafDTO } from "../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { ExportFile } from "../../../utils/export-file";
import { LayoutContext } from "../../../layout/context/layoutcontext";
import { InvoiceStatusType, OrderStatusType, OrderWithUserDto } from "../../../services/admin/admin.dto";

const OrdersPage = (props: any) => {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<OrderWithUserDto[]>>(null);
  const toast = useToast();
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const [selectedOrders, setSelectedOrders] = useState<OrderWithUserDto[]>([]);

  const {
    data: orderResponse,
    refetch: refetchOrders,
    isLoading: orderResponseIsLoading
  } = useQuery({
    queryKey: ["Siparisler"],
    refetchOnMount: true,
    queryFn: () => adminService.GetAllOrder()
  });

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: UyapDosyaDto) => {
    return <></>;
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dosyaId: commonFilterContains,
      dosyaNo: commonFilterContains,
      birimAdi: commonFilterContains,
      dosyaTur: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const exportExcel = () => {
    const data = orderResponse?.result?.data || [];

    const headers = ["Ad", "Soyad", "Kullanıcı Adı", "E-posta", "TC", "Durum", "Toplam Fiyat"];

    const filteredData = data.map((data) => ({
      firstName: data.user.firstName,
      lastName: data.user.lastName,
      userName: data.user.userName,
      email: data.user.email,
      tc: data.user.tc,
      status: data.status,
      totalPrice: data.totalPrice
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "SıkKullanılanDosyalar");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={orderResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={6}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} sipariş"
          filters={filters}
          // filterDisplay="row"
          loading={orderResponseIsLoading}
          globalFilterFields={[
            "user.firstName",
            "user.lastName",
            "user.userName",
            "user.email",
            "user.tc",
            "status",
            "totalPrice"
          ]}
          emptyMessage="Sipariş bulunmamaktadır."
          header={CreateHeader("Siparişler ", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
          selectionMode={"multiple"}
          selection={selectedOrders}
          onSelectionChange={(e) => {
            setSelectedOrders(e.value);
          }}
        >
          {/* <Column body={actionBodyTemplate} exportable={false} style={{ width: "9em" }}></Column> */}

          <Column
            field="user.firstName"
            header="Kullanıcı Ad Soyad"
            sortable
            body={(rowData: OrderWithUserDto) => <span>{`${rowData.user.firstName} ${rowData.user.lastName}`}</span>}
            filterField="user.firstName"
            filter
            filterPlaceholder="İsme göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İsme göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="user.email"
            header="Kullanıcı E-posta"
            sortable
            body={(rowData: OrderWithUserDto) => <span>{rowData.user.email}</span>}
            filterField="user.email"
            filter
            filterPlaceholder="E-posta göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">E-posta göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="createdDate"
            header="Sipariş Tarihi"
            sortable
            body={(rowData: OrderWithUserDto) => (
              <span>{rowData.createdDate ? new Date(rowData.createdDate).toLocaleDateString() : ""}</span>
            )}
            filterField="createdDate"
            filter
            filterPlaceholder="Sipariş Tarihine göre arayın"
            filterElement={Filter.DateFilterTemplate}
            filterMatchMode={FilterMatchMode.DATE_IS}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Sipariş Tarihine göre filtreleyin</div>}
          />
          <Column
            field="products"
            header="Ürünler"
            sortable
            body={(rowData: OrderWithUserDto) => (
              <div>
                {rowData.products.map((product, index) => (
                  <div key={index}>
                    <span>{`Adet: ${product.count}, Ay: ${product.month}, Fiyat: ${product.price.toFixed(2)} TL`}</span>
                  </div>
                ))}
              </div>
            )}
            filterField="products"
            filter
            filterPlaceholder="Ürün bilgilerine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Ürün bilgilerine göre filtreleyin</div>}
          ></Column>
          <Column
            field="status"
            header="Sipariş Durumu"
            sortable
            body={(rowData) => (
              <div className="flex justify-content-center align-items-center p-0 m-0">
                {rowData.status === OrderStatusType.Created ? (
                  <div className="p-0 flex align-items-center">OLUŞTURULDU</div>
                ) : rowData.status === OrderStatusType.Approved ? (
                  <div className="p-0 flex align-items-center">ONAYLANDI</div>
                ) : rowData.status === OrderStatusType.NotApproved ? (
                  <div className="p-0 flex align-items-center">ONAYLANMADI</div>
                ) : rowData.status === OrderStatusType.Returned ? (
                  <div className="p-0 flex align-items-center">İADE EDİLDİ</div>
                ) : null}
              </div>
            )}
            filterField="status"
            filter
            filterPlaceholder="Duruma göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>}
          ></Column>
          <Column
            field="totalPrice"
            header="Toplam Fiyat"
            sortable
            body={(rowData) => <span>{rowData.totalPrice.toFixed(2)} TL</span>}
            filterField="totalPrice"
            filter
            filterPlaceholder="Fiyata göre arayın"
            filterApply={Filter.FilterApplyTemplate}
            filterClear={Filter.FilterClearTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Fiyata göre filtreleyin</div>}
          />
          <Column
            field="invoiceStatus"
            header="Fatura Durumu"
            sortable
            body={(rowData) => (
              <div className="flex justify-content-center align-items-center p-0 m-0">
                {rowData.invoiceStatus === InvoiceStatusType.NotCreated ? (
                  <div className="p-0 flex align-items-center">OLUŞTURULMADI</div>
                ) : rowData.invoiceStatus === InvoiceStatusType.Draft ? (
                  <div className="p-0 flex align-items-center">TASLAK</div>
                ) : rowData.invoiceStatus === InvoiceStatusType.Signed ? (
                  <div className="p-0 flex align-items-center">İMZALANDI</div>
                ) : null}
              </div>
            )}
            filterField="invoiceStatus"
            filter
            filterPlaceholder="Fatura Durumuna göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Fatura Durumuna göre filtreleyin</div>}
          ></Column>
          <Column
            field="invoiceSignDate"
            header="Fatura İmzalanma Tarihi"
            sortable
            body={(rowData: OrderWithUserDto) => (
              <span>{rowData.invoiceSignDate ? new Date(rowData.invoiceSignDate).toLocaleDateString() : ""}</span>
            )}
            filterField="invoiceSignDate"
            filter
            filterPlaceholder="Tarihe göre arayın"
            filterElement={Filter.DateFilterTemplate}
            filterMatchMode={FilterMatchMode.DATE_IS}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tarihe göre filtreleyin</div>}
          />
          <Column
            field="didInvoiceMailSend"
            header="Mail Durumu"
            sortable
            body={(rowData) => (
              <div className="flex justify-content-center align-items-center p-0 m-0">
                {rowData.didInvoiceMailSend === true ? (
                  <div className="p-0 flex align-items-center">
                    <div className="px-5 py-1 bg-green-100 text-green-900 border-round font-medium">Gönderildi</div>
                  </div>
                ) : (
                  <div className="p-0 flex align-items-center">
                    <div className="px-2 py-1 bg-blue-100 text-blue-900 border-round font-medium text-center">
                      Gönderilmedi
                    </div>
                  </div>
                )}
              </div>
            )}
            filterField="didInvoiceMailSend"
            filter
            filterPlaceholder="Mail Durumuna göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Mail Durumuna göre filtreleyin</div>}
          ></Column>
          <Column
            field="invoiceMailSendDate"
            header="Mail Gönderilme Tarihi"
            sortable
            body={(rowData: OrderWithUserDto) => (
              <span>
                {rowData.invoiceMailSendDate ? new Date(rowData.invoiceMailSendDate).toLocaleDateString() : ""}
              </span>
            )}
            filterField="invoiceMailSendDate"
            filter
            filterPlaceholder="Mail Gönderilme Tarihine göre arayın"
            filterElement={Filter.DateFilterTemplate}
            filterMatchMode={FilterMatchMode.DATE_IS}
            filterFooter={() => (
              <div className="px-3 pt-0 pb-3 text-center">Mail Gönderilme Tarihine göre filtreleyin</div>
            )}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default OrdersPage;
