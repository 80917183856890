import {
  DosyaBorcluListesiResponseDTO,
  SorguBakiyesiResponseDTO
} from "../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import React, { useContext, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import Mernis from "./borclu-bilgileri-components/Mernis";
import Mersis from "./borclu-bilgileri-components/Mersis";
import Iski from "./borclu-bilgileri-components/Iski";
import Gib from "./borclu-bilgileri-components/Gib";
import Banka from "./borclu-bilgileri-components/Banka";
import IcraDosyasi from "./borclu-bilgileri-components/IcraDosyasi";
import SgkHaciz from "./borclu-bilgileri-components/SgkHaciz";
import Takbis from "./borclu-bilgileri-components/Takbis";
import PostaCeki from "./borclu-bilgileri-components/PostaCeki";
import Egm from "./borclu-bilgileri-components/Egm";
import Sgk from "./borclu-bilgileri-components/Sgk";
import DisIsleri from "./borclu-bilgileri-components/DisIsleri";
import Gsm from "./borclu-bilgileri-components/Gsm";
import mernisIcon from "../../../assets/images/mernis.png";
import sgkIcon from "../../../assets/images/sgk.png";
import iskiIcon from "../../../assets/images/iski.png";
import takbisIcon from "../../../assets/images/takbis.png";
import disisleribakanligiIcon from "../../../assets/images/disisleribakanligi.png";
import icraDosyasiIcon from "../../../assets/images/icraDosyasiIcon.svg";
import carIcon from "../../../assets/images/car.svg";
import letterIcon from "../../../assets/images/letter.svg";
import bankaIcon from "../../../assets/images/banka.svg";
import phoneIcon from "../../../assets/images/phone.svg";
import buildingIcon from "../../../assets/images/building.svg";
import { useHub } from "../../../utils/buro-hub";
import uyapService from "../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { LayoutContext } from "../../../layout/context/layoutcontext";

interface CardListItem {
  title: string;
  image: string;
  showIcon: boolean;
  visible: boolean;
  show: boolean;
}

const bireyselSorguCardListesi: CardListItem[] = [
  { title: "MERNİS", image: mernisIcon, showIcon: false, visible: true, show: true },
  { title: "MERSİS", image: buildingIcon, showIcon: false, visible: true, show: true },
  { title: "İSKİ", image: iskiIcon, showIcon: false, visible: true, show: true },
  { title: "GİB", image: "/images/gib.png", showIcon: false, visible: true, show: true },
  { title: "GSM", image: phoneIcon, showIcon: false, visible: true, show: false },
  { title: "DIŞ İŞLERİ", image: disisleribakanligiIcon, showIcon: false, visible: true, show: true },
  { title: "SGK", image: sgkIcon, showIcon: false, visible: true, show: true },
  { title: "EGM-TNB", image: carIcon, showIcon: true, visible: true, show: true },
  { title: "POSTA ÇEKİ", image: letterIcon, showIcon: true, visible: true, show: true },
  { title: "TAKBİS", image: takbisIcon, showIcon: true, visible: true, show: true },
  { title: "SGK HACİZ", image: "/images/gib.png", showIcon: true, visible: true, show: true },
  { title: "İCRA DOSYASI", image: icraDosyasiIcon, showIcon: true, visible: true, show: true },
  { title: "BANKA", image: bankaIcon, showIcon: true, visible: true, show: true }
];

interface BorcluListesiDüzenlenmiş {
  label: string;
  value: DosyaBorcluListesiResponseDTO;
}

const BorcluBilgileri = (props: { dosyaId: string }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const [selectedCard, setSelectedCard] = useState<CardListItem | null>(null);
  const [selectedBorcluDosya, setSelectedBorcluDosya] = useState<DosyaBorcluListesiResponseDTO | null>(null);
  const [selectedKisiKurumId, setSelectedKisiKurumId] = useState<number | null>(null);
  const [contentVisible, setContentVisible] = useState<boolean>(false);
  const [cardList, setCardList] = useState<CardListItem[]>(bireyselSorguCardListesi);
  const [sorguBakiyesi, setSorguBakiyesi] = useState<SorguBakiyesiResponseDTO>();
  const [borcluListesi, setBorcluListesi] = useState<BorcluListesiDüzenlenmiş[]>([]);
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const {
    data: BorcluBilgileriResponse,
    refetch: refetchBorcluBilgileri,
    isLoading: borcluBilgileriiResponseLoading
  } = useQuery({
    queryKey: ["BorcluBilgileri", props.dosyaId],
    refetchOnMount: false,
    queryFn: async () => await hub.executeUyapService(() => uyapService.GetBorcluBilgileriListesi(props.dosyaId))
  });

  delay(500);

  const {
    data: SorguBakiyesiResponse,
    refetch: refetchSorguBakiyesi,
    isLoading: sorguBakiyesiResponseLoading
  } = useQuery({
    queryKey: ["SorguBakiyesi"],
    refetchOnMount: true,
    queryFn: async () => await hub.executeUyapService(() => uyapService.GetSorguBakiyesi(props.dosyaId))
  });

  useEffect(() => {
    setBlocked(borcluBilgileriiResponseLoading || sorguBakiyesiResponseLoading);
  }, [borcluBilgileriiResponseLoading, sorguBakiyesiResponseLoading]);

  useEffect(() => {
    if (SorguBakiyesiResponse && SorguBakiyesiResponse.result) {
      setSorguBakiyesi(SorguBakiyesiResponse.result);
    }
  }, [SorguBakiyesiResponse]);

  useEffect(() => {
    if (BorcluBilgileriResponse && BorcluBilgileriResponse.result) {
      if (BorcluBilgileriResponse) {
        const updatedList = BorcluBilgileriResponse.result
          ?.map((element) => {
            if (element.kurumDVO) {
              return { label: `${element.kurumDVO.kurumAdi} ${element.kurumDVO.vergiNo}`, value: element };
            } else if (element.kisiTumDVO) {
              return {
                label: `${element.kisiTumDVO.adi} ${element.kisiTumDVO.soyadi} - ${element.kisiTumDVO.tcKimlikNo}`,
                value: element
              };
            }
            return null;
          })
          .filter((item): item is BorcluListesiDüzenlenmiş => item !== null);

        setBorcluListesi(updatedList);
      }
    }
  }, [BorcluBilgileriResponse]);

  const handleBorcluChange = (e: any) => {
    if (e.value) {
      setSelectedBorcluDosya(e.value);

      const updatedCardList = bireyselSorguCardListesi.map((item) => {
        item.visible = true;
        if (e.value.kurumDVO) {
          if (["MERNİS", "İSKİ", "GSM", "DIŞ İŞLERİ"].includes(item.title)) {
            item.visible = false;
          }
        } else {
          if (item.title === "MERSİS") {
            item.visible = false;
          }
        }
        return item;
      });

      setCardList(updatedCardList);
      setContentVisible(false);
      const selectedItem = borcluListesi?.find((item) => item.value === e.value);
      setSelectedKisiKurumId(selectedItem!.value.kisiKurumId);
    }
  };

  const handleCardClick = async (item: CardListItem) => {
    setSelectedCard(item);
    setContentVisible(true);
    await delay(500);
    refetchSorguBakiyesi();
  };

  return (
    <div>
      <Dropdown
        className="mb-3"
        value={selectedBorcluDosya}
        onChange={handleBorcluChange}
        options={borcluListesi}
        optionLabel="label"
        placeholder="Borçlu Seçin"
      />

      {selectedBorcluDosya && (
        <>
          <br />
          <div className="grid">
            {cardList
              .filter((item) => item.visible == true)
              .map((item, index) => (
                <div key={index} className="col-12 md:col-6 lg:col-4 xl:col-3">
                  <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round relative cursor-pointer">
                    {item.showIcon ? (
                      <div className="absolute top-0 right-0 p-2">
                        <i className="pi pi-turkish-lira" style={{ fontSize: "1rem" }}></i>
                      </div>
                    ) : (
                      <div className="absolute top-0 right-0 p-2">
                        <i className="pi pi-circle-fill text-blue-600 text-xs"></i>
                      </div>
                    )}
                    <div className="flex align-items-center" onClick={() => handleCardClick(item)}>
                      <div
                        className="flex align-items-center justify-content-center border-round mr-3"
                        style={{ width: "6rem", height: "3rem" }}
                      >
                        <img src={item.image} alt={item.title} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                      </div>
                      <div className="flex flex-column justify-content-center">
                        <span className="block text-2xl font-bold text-black">{item.title}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="grid mt-3">
            <div className="col-12 md:col-6 lg:col-4">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div
                  className={`flex align-items-center justify-content-center border-round mr-3`}
                  style={{ height: "3rem" }}
                >
                  <i className="pi pi-credit-card" style={{ fontSize: "3rem", marginRight: "1rem" }}></i>

                  <span className="block text-2xl font-bold text-black ">
                    Sorgu Bakiyeniz : {sorguBakiyesi?.[0].sorguBakiye}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div
                  className={`flex align-items-center justify-content-center border-round mr-3`}
                  style={{ height: "3rem" }}
                >
                  <i className="pi pi-gift" style={{ fontSize: "3rem", marginRight: "1rem" }}></i>

                  <span className="block text-2xl font-bold text-black">
                    Ücretsiz Sorgu Limiti : {sorguBakiyesi?.[0].sorguKalanIslemSayisi} /{" "}
                    {sorguBakiyesi?.[0].ucretsizSorguLimit}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div
                  className={`flex align-items-center justify-content-center border-round mr-3`}
                  style={{ height: "3rem" }}
                >
                  <i className="pi pi-history" style={{ fontSize: "3rem", marginRight: "1rem" }}></i>

                  <span className="block text-2xl font-bold text-black">
                    Toplam Sorgu Sayısı : {sorguBakiyesi?.[0].sorguToplamIslemSayisi}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {contentVisible && (
            <>
              {selectedCard && selectedCard.title === "MERNİS" && (
                <Mernis dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "MERSİS" && (
                <Mersis dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "İSKİ" && (
                <Iski dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "GİB" && (
                <Gib dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "GSM" && (
                <Gsm dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "DIŞ İŞLERİ" && (
                <DisIsleri dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "SGK" && (
                <Sgk dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} borcluBilgileri={selectedBorcluDosya} />
              )}
              {selectedCard && selectedCard.title === "EGM-TNB" && (
                <Egm dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "POSTA ÇEKİ" && (
                <PostaCeki dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "TAKBİS" && (
                <Takbis dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "SGK HACİZ" && (
                <SgkHaciz dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "İCRA DOSYASI" && (
                <IcraDosyasi dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
              {selectedCard && selectedCard.title === "BANKA" && (
                <Banka dosyaId={props.dosyaId} kisiKurumId={selectedKisiKurumId!} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BorcluBilgileri;
