import { FormikErrors, useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { UpdateIcraFoyKarsiYanDto } from "../../../../services/icra-foy/icra-foy.dto";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { KarsiYanDto } from "../../../../services/karsi-yan/karsi-yan.dto";
import { MuvekkilTipi } from "../../../../services/muvekkil/muvekkil.dto";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import DropdownDatas from "../../../../utils/DropdownValues";

const UpsertIcraFoyKarsiYan = (props: any) => {
  const icraFoyKarsiYan: UpdateIcraFoyKarsiYanDto = props.icraFoyKarsiYan;
  const setIcraFoyKarsiYan = props.setIcraFoyKarsiYan;
  const onUpsert = props.onUpsert;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const karsiYans: KarsiYanDto[] = props.karsiYans?.map((x: KarsiYanDto) => {
    return {
      ...x,
      adSoyad:
        x.tip === MuvekkilTipi.Gercek
          ? x.adSoyad + " (T.C. " + x.tcNo + ")"
          : x.unvan + " (VKN " + x.vergiKimlikNumarasi + ")",
    };
  });

  let updateIcraFoyKarsiYanDto: UpdateIcraFoyKarsiYanDto;

  if (icraFoyKarsiYan === null || icraFoyKarsiYan === undefined) {
    updateIcraFoyKarsiYanDto = {
      icraFoyId: 0,
      tip: "",
      karsiYanId: 0,
    };
  } else {
    updateIcraFoyKarsiYanDto = {
      ...icraFoyKarsiYan,
    };
  }

  const formik = useFormik<UpdateIcraFoyKarsiYanDto>({
    initialValues: updateIcraFoyKarsiYanDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateIcraFoyKarsiYanDto> = {};
      if (!data.karsiYanId) {
        errors.karsiYanId = "Borçlu alanı boş bırakılamaz";
      }
      if (!data.tip) {
        errors.tip = "Tip alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      onUpsert(formik.values);
      setVisible(false);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.karsiYanId === 0 ? "Borçlu Ekle" : "Borçlu Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setIcraFoyKarsiYan(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={() => formik.submitForm()}
          />
        </div>
      }
      className=""
      style={{ width: "50vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12">
          <label className="text-primary" htmlFor="karsiYanId">
            Borçlu
          </label>
          <Dropdown
            id="karsiYanId"
            name="karsiYanId"
            filter
            filterBy="adSoyad,tcNo,unvan"
            value={formik.values.karsiYanId}
            onChange={(e: MultiSelectChangeEvent) => {
              formik.setFieldValue("karsiYanId", e.value);
            }}
            options={karsiYans}
            optionLabel="adSoyad"
            itemTemplate={(item: KarsiYanDto) => {
              return (
                <div className="flex align-items-center">
                  <span>{item.adSoyad}</span>
                </div>
              );
            }}
            optionValue="id"
            placeholder="Borçlu Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "karsiYanId"),
            })}
          />
          {FormikValueIsValid(formik, "karsiYanId") && (
            <div className="p-error mt-3">{formik.errors.karsiYanId}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="sahibi">
            Tip
          </label>
          <Dropdown
            id="tip"
            name="tip"
            value={formik.values.tip}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tip", e.value);
            }}
            options={DropdownDatas.GetBorcluTypes()}
            optionLabel="label"
            optionValue="value"
            placeholder="Tip Seçiniz."
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tip"),
            })}
          />
          {FormikValueIsValid(formik, "tip") && (
            <div className="p-error mt-3">{formik.errors.tip}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertIcraFoyKarsiYan;
