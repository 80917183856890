export interface TopluSorguModel {
  key: number;
  baslamaTarihi: Date | null;
  status: number;
  listName: string;
  tabs: TopluSorguTur[];
  sorgulanacakDosyalar: TopluSorgulanacakDosyaDTO[];
  sorunluVeOlumsuzListeleri?: OlumsuzSorguSonucuModel[];
  tahsilatKalaniListeleri?: TopluSorguTahsilatKalaniModel[];
  mernisListeleri?: TopluSorguMernisModel[];
  mersisListeleri?: TopluSorguMersisModel[];
  iskiListeleri?: TopluSorguIskiModel[];
  gibListeleri?: TopluSorguGibModel[];
  gsmListeleri?: TopluSorguGsmModel[];
  disIsleriListeleri?: TopluSorguDisIslerModel[];
  sskCalisaniListeleri?: TopluSorguSgkCalisaniModel[];
  sskEmeklisiListeleri?: TopluSorguSgkEmekliModel[];
  kamuCalisaniListeleri?: TopluSorguKamuCalisanModel[];
  kamuEmeklisiListeleri?: TopluSorguKamuEmekliModel[];
  bagkurCalisaniListeleri?: TopluSorguBagkurCalisanModel[];
  bagkurEmeklisiListeleri?: TopluSorguBagkurEmekliModel[];
  sgkIsyeriListeleri?: TopluSorguIsyeriModel[];
  egmListeleri?: TopluSorguEgmTopluSorguModel[];
  postaCekiListeleri?: TopluSorguPostaCekiModel[];
  takbisListeleri?: TopluSorguTakbisTopluSorguModel[];
  sgkHacizListeleri?: TopluSorguSgkHacizModel[];
  icraDosyasiListeleri?: TopluSorguIcraDosyasiModel[];
  bankaListeleri?: TopluSorguBankaHesabiModel[];
  tarafListeleri?: TopluSorguTarafDataTableModel[];
}

export interface TopluSorgulanacakDosyaDTO {
  dosyaNo: string;
  dosyaDurumKod: number;
  birimAdi: string;
  dosyaTur: string;
  dosyaDurum: string;
  birimTuru2: string;
  birimTuru3: string;
  isSorgulandi: DosyaSorguDurum;
}

export enum DosyaSorguDurum {
  SORGULANMADI = 1,
  DOSYA_BULUNAMADI,
  SORGULANDI
}

export enum TopluSorguDurum {
  BASLATILMADI = 1,
  DEVAM_EDİYOR,
  TAMAMLANMADI,
  TAMAMLANDI
}

export interface TopluSorguListesiModel {
  name: string;
  key: number;
  olusturulmaTarihi: Date;
  liste: TopluSorgulanacakDosyaDTO[];
}

export enum TopluSorguTur {
  SORUNLU_VE_OLUMSUZ = 1,
  TAHSILAT_KALANI,
  MERNIS,
  MERSIS,
  ISKI,
  GIB,
  GSM,
  DIS_ISLERI,
  SSK_CALISANI,
  SSK_EMEKLISI,
  KAMU_CALISANI,
  KAMU_EMEKLISI,
  BAGKUR_CALISANI,
  BAGKUR_EMEKLISI,
  SGK_ISYERI,
  EGM,
  POSTA_CEKI,
  TAKBIS,
  SGK_HACIZ,
  ICRA_DOSYASI,
  BANKA,
  TARAF
}

export interface TabsDto {
  title: string;
  type: TopluSorguTur;
}

export interface TopluSorguOrtakModel {
  birimAdi: string;
  dosyaNo: string;
  alacakliAdi: string;
  borcluAdi: string;
  borcluTCVKNo: string;
  dosyaDurumu: string;
}

export interface OlumsuzSorguSonucuModel {
  ortakDegerler: TopluSorguOrtakModel;
  sorguTuru: string;
  aciklama: string;
}

//TAHSİLAT KALANI
export interface TopluSorguTahsilatKalaniModel {
  ortakDegerler: TopluSorguOrtakModel;
  tahsilat: number;
  sadeceToplam: number;
  sadeceMasraf: number;
  sadeceDiger: number;
}

/******************************************************************************************************/
//MERNİS
export interface TopluSorguMernisModel {
  ortakDegerler: TopluSorguOrtakModel;
  adres: string;
  il: string;
  ilce: string;
  tescilTarihi: Date | null;
  beyanTarihi: Date | null;
  tasinmaTarihi: Date | null;
  olumTarihi: Date | null;
}

/******************************************************************************************************/
//MERSİS
export interface TopluSorguMersisModel {
  ortakDegerler: TopluSorguOrtakModel;
  adres: string;
}

/******************************************************************************************************/
//İSKİ
export interface TopluSorguIskiModel {
  ortakDegerler: TopluSorguOrtakModel;
  adres: string;
}

/******************************************************************************************************/
//GİB
export interface TopluSorguGibModel {
  ortakDegerler: TopluSorguOrtakModel;
  adres: string;
}
/******************************************************************************************************/
//GSM
export interface TopluSorguGsmModel {
  ortakDegerler: TopluSorguOrtakModel;
  operator: string;
  adres: string;
}

/******************************************************************************************************/
//DIŞ İŞLERİ
export interface TopluSorguDisIslerModel {
  ortakDegerler: TopluSorguOrtakModel;
  adresTuru: string;
  sehir: string;
  adres: string;
  postaKodu: string;
}
/******************************************************************************************************/
//SGK SSK Çalışan
export interface TopluSorguSgkCalisaniModel {
  ortakDegerler: TopluSorguOrtakModel;
  isYeri: string;
  isYeriAdresi: string;
  isyeriIli: string;
  isYeriSicil: string;
  donemAy: string;
  sgkDurum: string;
  sgkSicilNo: string;
  ilkIseGirisTarihi?: Date;
  sonIseGirisTarihi?: Date;
  istenCıkısTarihi?: Date;
}

/******************************************************************************************************/
//SGK SSK Emekli
export interface TopluSorguSgkEmekliModel {
  ortakDegerler: TopluSorguOrtakModel;
  sonuc: string;
}
/******************************************************************************************************/
//SGK Kamu Çalışan
export interface TopluSorguKamuCalisanModel {
  ortakDegerler: TopluSorguOrtakModel;
  kurum: string;
  kurumIl: string;
  kurumIlce: string;
  iseBaslamaTarihi?: Date;
  unvani: string;
  kurumDurum: string;
  aciklama: string;
}

/******************************************************************************************************/
//SGK Kamu Emekli
export interface TopluSorguKamuEmekliModel {
  ortakDegerler: TopluSorguOrtakModel;
  sicilNo: string;
  aylikBaslamaTarihi?: Date;
  unvan: string;
  derece?: number;
  yakinlik: string;
  kademe?: number;
  bankaAdi: string;
  bankaIli: string;
  subeAdi: string;
  aylikOraniPayda?: number;
  aylikOraniPay?: number;
  aylikDurumu: string;
  aylikTur: string;
  aylikPeriyot: string;
}

/******************************************************************************************************/
//SGK Bağkur Calisan
export interface TopluSorguBagkurCalisanModel {
  ortakDegerler: TopluSorguOrtakModel;
  bagkurNo: string;
  tescilTarihi?: Date;
  meslekIli: number;
  meslekIlce: string;
  terkTarihi?: Date;
  terkAciklama: string;
}

/******************************************************************************************************/
//SGK Bağkur Emekli

export interface TopluSorguBagkurEmekliModel {
  ortakDegerler: TopluSorguOrtakModel;
  sonuc: string;
}

/******************************************************************************************************/
//SGK İşyeri Bilgileri
export interface TopluSorguIsyeriModel {
  ortakDegerler: TopluSorguOrtakModel;
  eskiSubeKodu: string;
  il: string;
  isYeriAciklama: string;
  isYeriAdres: string;
  isYeriMerkezAdresi: string;
  isYeriSicil: string;
  isYeriUnvani: string;
  yeniSubeKodu: string;
  siraNo: string;
  adres: string;
  mahiyet: string;
}
/******************************************************************************************************/
//ARAÇ
export interface TopluSorguEgmTopluSorguModel {
  ortakDegerler: TopluSorguOrtakModel;
  plaka: string;
  marka: string;
  model: string;
  ticariAd: string;
  renk: string;
  cins: string;
  // ekleyenBirim: string;
  // eklemeTarihi: Date;
  // takyidatAdi: string;
  // kurumAd: string;
  mahrumiyetSonuc: string;
  sonHacizTarihi?: any;
  sonYakalamaTarihi?: Date;
  serhSayisi: number;
  lblHaciz: number;
  lblYakalama: number;
  lblHacizYakalama: number;
  lblIhtiyatiHaciz: number;
  lblRehin: number;
  lblRehninPC: number;
  lblMulkiyet: number;
  lblIcraSatisSerhi: number;
  lblSatisArz: number;
  lblKesinSatis: number;
  lblIhtedbir: number;
  lblPlakaDeg: number;
  lblIhtedbir2: number;
  lblDiger: number;
  lblTrfMen20e: number;
  lblOtvMuaf3y: number;
  lblIflasSerhi: number;
}
/******************************************************************************************************/
//POSTA ÇEKİ
export interface TopluSorguPostaCekiModel {
  ortakDegerler: TopluSorguOrtakModel;
  postaCeki: string;
}

/******************************************************************************************************/
//TAKBİS
export interface TopluSorguTakbisTopluSorguModel {
  ortakDegerler: TopluSorguOrtakModel;
  tapuMudurlugu: string;
  ilIlce: string;
  mahalle: string;
  vasfi: string;
  yuzolcumu: string;
  mevkii: string;
  adaNo: string;
  parselNo: string;
  hisseAciklama: string;
  hisseTip: string;
  tapuBolumDurum: string;
  tasinmazId: number;
  hisseId: number;
  sonHacizTarihi?: Date;
  serhSayisi: number;
  beyanSayisi: number;
}

/******************************************************************************************************/
//SGK HACİZ
export interface TopluSorguSgkHacizModel {
  ortakDegerler: TopluSorguOrtakModel;
  kisiTipString: string;
}

/******************************************************************************************************/
//İCRA DOSYASI
export interface TopluSorguIcraDosyasiModel {
  ortakDegerler: TopluSorguOrtakModel;
  birimAdiDosya: string;
  takipTuru: string;
  takipYoluSekli: string;
  durumu: string;
  acilis?: Date;
  kapanis?: Date;
}
/******************************************************************************************************/
//BANKA HESABI
export interface TopluSorguBankaHesabiModel {
  ortakDegerler: TopluSorguOrtakModel;
  bankaAdi: string;
}

export interface TopluSorguTarafDataTableModel {
  ortakDegerler: TopluSorguOrtakModel;
  rol: string;
}

export interface dateModel {
  yil: number;
  ay: number;
  gun: number;
}

// export interface EgmTopluSorguDTO {
//   dosyaOzetDVO: DosyaOzetWithAdetDVO;

//   sorguSonucu: boolean;
//   sorguTarihi: string;
//   sorgulamaTuru: string;

//   sorguSonucDVO: {
//     aracBilgileri: {
//       hataMesaj: string;
//       hatakod: number;
//       sonuc: string;
//       output: {
//         plaka: string;
//         marka: string;
//         model: string;
//         ticariAd: string;
//         renk: string;
//         cins: string;
//         plakaSifreli: string;

//         aracMahrumiyet?: AracMahrumiyetResponseDTO;
//       }[];
//       kurumAdi: string;
//       kurumTipi: number;
//     };
//   };
// }

// export interface TakbisTopluSorguDTO {
//   sorguSonucDVO: {
//     tasinmazDVO: {
//       tasinmazId: number;
//       altTasinmazId: number;
//       tasinmazTip: string;
//       il: string;
//       ilce: string;
//       kurum: string;
//       mahalle: string;
//       mevkii: string;
//       nitelik: string;
//       ciltNo: string;
//       sayfaNo: string;
//       yuzolcum: number;
//       pafta: string;
//       ada: string;
//       parsel: string;
//       eklenti: number;
//       teferruat: number;
//       muhdesat: number;
//       tapuBolumDurum: string;
//       terkinIslem: TerkinIslemDTO;
//       tesisIslem: TesisIslemDTO;
//       takbisHisse?: {
//         hisseList: {
//           hisseId: number;
//           hisseTip: string;
//           istirakNo: number;
//           malik: MalikDTO;
//           pay: string;
//           payda: string;
//           tapuBolumDurum: string;
//           tasinmazId: number;
//           terkinIslem: TerkinIslemDTO;
//           tesisIslem: TesisIslemDTO;
//           hisseMahrumiyet?: TakbisHisseMahrumiyetResponseDTO;
//         }[];
//         htmlHataKodList: any[];
//         id: number;
//         isCompressed: boolean;

//         sonuc: boolean;
//       };
//     };
//     yuzolcumu: string;
//   }[];
//   sorguSonucu: boolean;
//   sorguTarihi: string;
//   sorgulamaTuru: string;
// }

// const [topluSorguModel, setTopluSorguModel] = useState<TopluSorguModel>({
//     tabs: [1],
//     sorgulanacakDosyalar: [
//       {
//         dosyaNo: "2024/71274",
//         dosyaDurumKod: 0,
//         dosyaDurum: "Açık (Durdurulmuş : Takibe İtiraz)",
//         dosyaTur: "İcra Dosyası",
//         birimAdi: "Acıpayam İcra Dairesi",
//         birimTuru2: "1101",
//         birimTuru3: "1199"
//       },
//       {
//         dosyaNo: "2024/71272",
//         dosyaDurumKod: 0,
//         dosyaDurum: "Açık",
//         dosyaTur: "İcra Dosyası",
//         birimAdi: "İzmir 21. İcra Dairesi",
//         birimTuru2: "1101",
//         birimTuru3: "1199"
//       },
//       {
//         dosyaNo: "2022/6185",
//         dosyaDurumKod: 0,
//         dosyaDurum: "Açık",
//         dosyaTur: "İcra Dosyası",
//         birimAdi: "İzmir 15. İcra Dairesi",
//         birimTuru2: "1101",
//         birimTuru3: "1199"
//       }
//     ],
//     sorunluVeOlumsuzListeleri: [
//       {
//         birimAdi: "İstanbul İcra Dairesi",
//         dosyaNo: "2023/12345",
//         alacakliAdi: "Ahmet Yılmaz",
//         borcluAdi: "Mehmet Özdemir",
//         borcluTCVKNo: "12345678901",
//         dosyaDurumu: "Kapalı",
//         sorguTuru: "Banka Hesabı Sorgulama",
//         aciklama: "Borçlu hakkında olumsuz bilgi mevcut."
//       },
//       {
//         birimAdi: "Ankara İcra Dairesi",
//         dosyaNo: "2023/67890",
//         alacakliAdi: "Ayşe Demir",
//         borcluAdi: "Ali Veli",
//         borcluTCVKNo: "98765432100",
//         dosyaDurumu: "Açık",
//         sorguTuru: "Banka Hesabı Sorgulama",
//         aciklama: "Borçlu hakkında herhangi bir bilgi bulunamadı."
//       },
//       {
//         birimAdi: "İzmir İcra Dairesi",
//         dosyaNo: "2023/54321",
//         alacakliAdi: "Mehmet Karaca",
//         borcluAdi: "Fatma Aydın",
//         borcluTCVKNo: "11223344556",
//         dosyaDurumu: "Kapalı",
//         sorguTuru: "Banka Hesabı Sorgulama",
//         aciklama: "Borçlu hakkında olumsuz bilgi mevcut."
//       },
//       {
//         birimAdi: "Bursa İcra Dairesi",
//         dosyaNo: "2023/67812",
//         alacakliAdi: "Selin Yurt",
//         borcluAdi: "Emre Kaya",
//         borcluTCVKNo: "55667788999",
//         dosyaDurumu: "Açık",
//         sorguTuru: "Banka Hesabı Sorgulama",
//         aciklama: "Borçlu hakkında herhangi bir bilgi bulunamadı."
//       },
//       {
//         birimAdi: "Antalya İcra Dairesi",
//         dosyaNo: "2023/78901",
//         alacakliAdi: "Gülseren Akman",
//         borcluAdi: "Hüseyin Çelik",
//         borcluTCVKNo: "99887766554",
//         dosyaDurumu: "Kapalı",
//         sorguTuru: "Banka Hesabı Sorgulama",
//         aciklama: "Borçlu hakkında olumsuz bilgi mevcut."
//       }
//     ],
//     tahsilatKalaniListeleri: [
//       {
//         tahsilatList: [
//           {
//             makbuzNo: "132436",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Borç Tahsilatı",
//             tahsilatTarihi: "Apr 22, 2024 4:21:29 PM",
//             kalanMiktar: 10,
//             tahsilatTutari: 5416.43,
//             kayitId: 243109513
//           },
//           {
//             makbuzNo: "168517",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Borç Tahsilatı",
//             tahsilatTarihi: "May 20, 2024 4:34:40 PM",
//             kalanMiktar: 20,
//             tahsilatTutari: 5147.22,
//             kayitId: 245985194
//           },
//           {
//             makbuzNo: "214817",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Masraf Avansı Tahsilatı",
//             tahsilatTarihi: "Jun 26, 2024 5:12:21 PM",
//             kalanMiktar: 100,
//             tahsilatTutari: 4692.28,
//             kayitId: 249261060
//           },
//           {
//             makbuzNo: "168517",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Masraf Avansı Tahsilatı",
//             tahsilatTarihi: "May 20, 2024 4:34:40 PM",
//             kalanMiktar: 200,
//             tahsilatTutari: 5147.22,
//             kayitId: 245985194
//           },
//           {
//             makbuzNo: "214817",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Diğer",
//             tahsilatTarihi: "Jun 26, 2024 5:12:21 PM",
//             kalanMiktar: 99,
//             tahsilatTutari: 4692.28,
//             kayitId: 249261060
//           }
//         ],
//         reddiyatList: [
//           {
//             makbuzNo: "231995",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 87,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "PTT",
//             reddiyatNedeni: "Posta Ücreti Reddiyatı",
//             reddiyatTarihi: "Oct 5, 2023 10:30:58 AM",
//             gelirVergisi: 0,
//             damgaVergisi: 0,
//             cezaeviHarcTutari: 0,
//             tahsilHarci: 0,
//             odenecekMiktar: 87,
//             kayitId: 224088574
//           },
//           {
//             makbuzNo: "287315",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 15,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "PTT",
//             reddiyatNedeni: "Posta Ücreti Reddiyatı",
//             reddiyatTarihi: "Nov 12, 2023 1:45:04 PM",
//             gelirVergisi: 0,
//             damgaVergisi: 0,
//             cezaeviHarcTutari: 0,
//             tahsilHarci: 0,
//             odenecekMiktar: 15,
//             kayitId: 227183435
//           },
//           {
//             makbuzNo: "336259",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 3115.08,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "NURBAY AKTAŞ",
//             reddiyatNedeni: "Borç Reddiyatı",
//             reddiyatTarihi: "Dec 25, 2023 3:36:45 PM",
//             gelirVergisi: 0,
//             damgaVergisi: 0,
//             cezaeviHarcTutari: 66.67,
//             tahsilHarci: 151.67,
//             odenecekMiktar: 3333.42,
//             kayitId: 231999180
//           }
//         ],
//         harcList: [
//           {
//             makbuzNo: "83310",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 548.7,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ Adına Avukat NURBAY AKTAŞ Yatırmıştır.",
//             tahsilatTuru: "Peşin Harç",
//             tahsilatTarihi: "Oct 4, 2023 5:36:20 PM"
//           },
//           {
//             makbuzNo: "83310",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 38.4,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ Adına Avukat NURBAY AKTAŞ Yatırmıştır.",
//             tahsilatTuru: "Vekalet Suret Harcı",
//             tahsilatTarihi: "Oct 4, 2023 5:36:20 PM"
//           },
//           {
//             makbuzNo: "83310",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 269.85,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ Adına Avukat NURBAY AKTAŞ Yatırmıştır.",
//             tahsilatTuru: "Başvurma Harcı",
//             tahsilatTarihi: "Oct 4, 2023 5:36:20 PM"
//           },
//           {
//             makbuzNo: "211674",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 87,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ",
//             tahsilatTuru: "Posta Masrafı",
//             tahsilatTarihi: "Oct 5, 2023 10:30:58 AM"
//           },
//           {
//             makbuzNo: "237900",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 30,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ adına NURBAY AKTAŞ",
//             tahsilatTuru: "Posta Masrafı",
//             tahsilatTarihi: "Oct 26, 2023 8:25:34 AM"
//           },
//           {
//             makbuzNo: "13712",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 159.96,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Jan 22, 2024 10:27:24 PM"
//           },
//           {
//             makbuzNo: "3079",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 70.32,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Jan 22, 2024 10:27:24 PM"
//           },
//           {
//             makbuzNo: "7592",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 93.88,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Feb 22, 2024 8:58:52 AM"
//           },
//           {
//             makbuzNo: "33341",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 213.57,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Feb 22, 2024 8:58:52 AM"
//           },
//           {
//             makbuzNo: "55386",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 190.35,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Mar 26, 2024 11:30:04 PM"
//           },
//           {
//             makbuzNo: "12622",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 83.67,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Mar 26, 2024 11:30:04 PM"
//           },
//           {
//             makbuzNo: "16812",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 108.33,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Apr 22, 2024 5:03:12 PM"
//           },
//           {
//             makbuzNo: "71879",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 246.44,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Apr 22, 2024 5:03:12 PM"
//           },
//           {
//             makbuzNo: "91268",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 234.19,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "May 21, 2024 10:53:48 AM"
//           },
//           {
//             makbuzNo: "21545",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 102.94,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "May 21, 2024 10:53:49 AM"
//           },
//           {
//             makbuzNo: "117858",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 213.49,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Jun 27, 2024 12:10:29 PM"
//           },
//           {
//             makbuzNo: "27506",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 93.85,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Jun 27, 2024 12:10:30 PM"
//           }
//         ],
//         toplamTahsilat: 31288.68,
//         toplamreddiyat: 31174.68,
//         haricen: 192,
//         toplamTeminat: 619.66,
//         toplamTahsilHarci: 2266.62,
//         toplamKalan: 114,
//         isIcraMi: true
//       },
//       {
//         tahsilatList: [
//           {
//             makbuzNo: "132436",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Borç Tahsilatı",
//             tahsilatTarihi: "Apr 22, 2024 4:21:29 PM",
//             kalanMiktar: 10000,
//             tahsilatTutari: 5416.43,
//             kayitId: 243109513
//           },
//           {
//             makbuzNo: "168517",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Borç Tahsilatı",
//             tahsilatTarihi: "May 20, 2024 4:34:40 PM",
//             kalanMiktar: 20000,
//             tahsilatTutari: 5147.22,
//             kayitId: 245985194
//           },
//           {
//             makbuzNo: "214817",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Masraf Avansı Tahsilatı",
//             tahsilatTarihi: "Jun 26, 2024 5:12:21 PM",
//             kalanMiktar: 100000,
//             tahsilatTutari: 4692.28,
//             kayitId: 249261060
//           },
//           {
//             makbuzNo: "168517",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Masraf Avansı Tahsilatı",
//             tahsilatTarihi: "May 20, 2024 4:34:40 PM",
//             kalanMiktar: 200000,
//             tahsilatTutari: 5147.22,
//             kayitId: 245985194
//           },
//           {
//             makbuzNo: "214817",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "SEDAT KARATAŞ",
//             tahsilatTuru: "Diğer",
//             tahsilatTarihi: "Jun 26, 2024 5:12:21 PM",
//             kalanMiktar: 99000,
//             tahsilatTutari: 4692.28,
//             kayitId: 249261060
//           }
//         ],
//         reddiyatList: [
//           {
//             makbuzNo: "231995",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 87,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "PTT",
//             reddiyatNedeni: "Posta Ücreti Reddiyatı",
//             reddiyatTarihi: "Oct 5, 2023 10:30:58 AM",
//             gelirVergisi: 0,
//             damgaVergisi: 0,
//             cezaeviHarcTutari: 0,
//             tahsilHarci: 0,
//             odenecekMiktar: 87,
//             kayitId: 224088574
//           },
//           {
//             makbuzNo: "287315",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 15,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "PTT",
//             reddiyatNedeni: "Posta Ücreti Reddiyatı",
//             reddiyatTarihi: "Nov 12, 2023 1:45:04 PM",
//             gelirVergisi: 0,
//             damgaVergisi: 0,
//             cezaeviHarcTutari: 0,
//             tahsilHarci: 0,
//             odenecekMiktar: 15,
//             kayitId: 227183435
//           },
//           {
//             makbuzNo: "336259",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 3115.08,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "NURBAY AKTAŞ",
//             reddiyatNedeni: "Borç Reddiyatı",
//             reddiyatTarihi: "Dec 25, 2023 3:36:45 PM",
//             gelirVergisi: 0,
//             damgaVergisi: 0,
//             cezaeviHarcTutari: 66.67,
//             tahsilHarci: 151.67,
//             odenecekMiktar: 3333.42,
//             kayitId: 231999180
//           }
//         ],
//         harcList: [
//           {
//             makbuzNo: "83310",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 548.7,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ Adına Avukat NURBAY AKTAŞ Yatırmıştır.",
//             tahsilatTuru: "Peşin Harç",
//             tahsilatTarihi: "Oct 4, 2023 5:36:20 PM"
//           },
//           {
//             makbuzNo: "83310",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 38.4,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ Adına Avukat NURBAY AKTAŞ Yatırmıştır.",
//             tahsilatTuru: "Vekalet Suret Harcı",
//             tahsilatTarihi: "Oct 4, 2023 5:36:20 PM"
//           },
//           {
//             makbuzNo: "83310",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 269.85,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ Adına Avukat NURBAY AKTAŞ Yatırmıştır.",
//             tahsilatTuru: "Başvurma Harcı",
//             tahsilatTarihi: "Oct 4, 2023 5:36:20 PM"
//           },
//           {
//             makbuzNo: "211674",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 87,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ",
//             tahsilatTuru: "Posta Masrafı",
//             tahsilatTarihi: "Oct 5, 2023 10:30:58 AM"
//           },
//           {
//             makbuzNo: "237900",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 30,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ adına NURBAY AKTAŞ",
//             tahsilatTuru: "Posta Masrafı",
//             tahsilatTarihi: "Oct 26, 2023 8:25:34 AM"
//           },
//           {
//             makbuzNo: "13712",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 159.96,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Jan 22, 2024 10:27:24 PM"
//           },
//           {
//             makbuzNo: "3079",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 70.32,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Jan 22, 2024 10:27:24 PM"
//           },
//           {
//             makbuzNo: "7592",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 93.88,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Feb 22, 2024 8:58:52 AM"
//           },
//           {
//             makbuzNo: "33341",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 213.57,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Feb 22, 2024 8:58:52 AM"
//           },
//           {
//             makbuzNo: "55386",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 190.35,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Mar 26, 2024 11:30:04 PM"
//           },
//           {
//             makbuzNo: "12622",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 83.67,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Mar 26, 2024 11:30:04 PM"
//           },
//           {
//             makbuzNo: "16812",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 108.33,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Apr 22, 2024 5:03:12 PM"
//           },
//           {
//             makbuzNo: "71879",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 246.44,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Apr 22, 2024 5:03:12 PM"
//           },
//           {
//             makbuzNo: "91268",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 234.19,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "May 21, 2024 10:53:48 AM"
//           },
//           {
//             makbuzNo: "21545",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 102.94,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "May 21, 2024 10:53:49 AM"
//           },
//           {
//             makbuzNo: "117858",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 213.49,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Tahsil Harcı",
//             tahsilatTarihi: "Jun 27, 2024 12:10:29 PM"
//           },
//           {
//             makbuzNo: "27506",
//             dosyaNo: "2023/55675",
//             dosyaTurAciklama: "İcra Dosyası",
//             miktar: 93.85,
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             odeyenKisi: "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ  - Av. NURBAY AKTAŞ",
//             tahsilatTuru: "Cezaevi (Nispi)",
//             tahsilatTarihi: "Jun 27, 2024 12:10:30 PM"
//           }
//         ],
//         toplamTahsilat: 31288.68,
//         toplamreddiyat: 31174.68,
//         haricen: 192,
//         toplamTeminat: 619.66,
//         toplamTahsilHarci: 2266.62,
//         toplamKalan: 114,
//         isIcraMi: true
//       }
//     ],
//     mernisListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********82",
//             adi: "AH***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.ÇAĞATAY ÜSKÜDAR",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Amasya İcra Dairesi",
//             birimId: "1052225",
//             orgKodu: "1.04.010.000.6003",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "010",
//             duzey4: "000",
//             duzey5: "6003",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2018/48256",
//           dosyaNoYil: 2018,
//           dosyaSira: 48256,
//           dosyaId: "KM18HoWxyCliZzAiJJwfMXRouuiQl4NSVCGvWvSm@utBWgYV2FftBmjwaq3Xx2WE",
//           dosyaAcilisTarihi: "20/08/2018 11:07",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "AHMET ÇITTIR, HANİFE ÇITTIR, ÜÇÜNCÜ ŞAHIS MERVE ÇITTIR, MERVE ÇITTIR, ÜÇÜNCÜ ŞAHIS ÜMİT ÇITTIR",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "18/07/2024 17:18",
//         sorguSonucDVO: {
//           kimlikBilgisi: [
//             {
//               olumKaydi: false,
//               isAvukat: false,
//               isNoter: false,
//               isTarafUyari: false,
//               isHakimSavci: false,
//               mernisDegisiklikVarmi: false,
//               mernisDegisiklikNedeni: "",
//               cuzdanSeriNo: "A38G87561",
//               verildigiIlKodu: 99,
//               verildigiIlceKodu: 0,
//               verildigiIlAdi: "Bilinmiyor",
//               sorguTuru: 0,
//               tcKimlikNo: "17947376082",
//               adi: "AHMET",
//               soyadi: "ÇITTIR",
//               babaAdi: "MEHMET",
//               anaAdi: "HANİFE",
//               dogumTarihiStr: "03/08/1989",
//               cinsiyeti: "E",
//               medeniHali: 0,
//               dogumYeri: "AMASYA",
//               aktifMernisAdresiSorgusu: true,
//               hasOlumKaydi: false
//             }
//           ],
//           mernisAdres: {
//             TCKimlikNo: 17947376082,
//             adresler: [
//               {
//                 AdresTipi: "IlIlceMerkeziAdresi",
//                 adresNo: 1102912407,
//                 ilIlceMerkeziAdresi: {
//                   ilKodu: 5,
//                   uyapIlKodu: 5,
//                   il: "AMASYA",
//                   ilceKodu: 1134,
//                   uyapIlceKodu: 1,
//                   ilce: "MERKEZ",
//                   mahalleKod: 1326,
//                   mahalle: "KOZA MAH.",
//                   csbmKodu: 345722,
//                   csbm: "CEYLAN SK.",
//                   disKapiNo: "1",
//                   icKapiNo: "1"
//                 },
//                 beyandaBulunanTCKimlikNo: 0,
//                 tescilTarih: { yil: 2024, ay: 1, gun: 26 },
//                 beyanTarih: { yil: 2024, ay: 1, gun: 26 },
//                 tasinmaTarih: { yil: 2024, ay: 1, gun: 25 }
//               }
//             ]
//           }
//         },
//         sorgulamaTuru: "Mernis",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     mersisListeleri: [
//       {
//         borcluBilgileri: {
//           kisiKurumId: 233109733,
//           kurumDVO: {
//             kurumAdi: " ED* TU**** OT******* EN**** SA**** VE Tİ***** Lİ***** Şİ*****",
//             vergiNo: "3240972867",
//             veriGelisYeri: 0,
//             faaliyetDurumu: -1,
//             tacirMi: "H",
//             mersisIlKodu: 0,
//             mersisIlceKodu: 0
//           },
//           turu: 1
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/64781",
//           dosyaNoYil: 2023,
//           dosyaSira: 64781,
//           dosyaId: "xztCPapH3AKjFPgSpRQqWriGCXL03h7C+he9xctEmd@gRtmAM4Hl10F62b8vkD+O",
//           dosyaAcilisTarihi: "07/11/2023 17:10",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 14:31",
//         sorguSonucDVO: "CUMHURİYET MAH. YEŞİLYOL CAD. KÖŞEM APT. BLOK  NO: 45A  İÇ KAPI NO: A MERKEZ / AFYONKARAHİSAR",
//         sorgulamaTuru: "Mersis",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     iskiListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********82",
//             adi: "AH***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/64781",
//           dosyaNoYil: 2023,
//           dosyaSira: 64781,
//           dosyaId: "xztCPapH3AKjFPgSpRQqWriGCXL03h7C+he9xctEmd@gRtmAM4Hl10F62b8vkD+O",
//           dosyaAcilisTarihi: "07/11/2023 17:10",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 14:31",
//         sorguSonucDVO: {
//           adres: "adres"
//         },
//         sorgulamaTuru: "Mersis",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********82",
//             adi: "AH***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/64781",
//           dosyaNoYil: 2023,
//           dosyaSira: 64781,
//           dosyaId: "xztCPapH3AKjFPgSpRQqWriGCXL03h7C+he9xctEmd@gRtmAM4Hl10F62b8vkD+O",
//           dosyaAcilisTarihi: "07/11/2023 17:10",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 14:31",
//         sorguSonucDVO: {
//           adres: "adres"
//         },
//         sorgulamaTuru: "Mersis",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********82",
//             adi: "AH***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/64781",
//           dosyaNoYil: 2023,
//           dosyaSira: 64781,
//           dosyaId: "xztCPapH3AKjFPgSpRQqWriGCXL03h7C+he9xctEmd@gRtmAM4Hl10F62b8vkD+O",
//           dosyaAcilisTarihi: "07/11/2023 17:10",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 14:31",
//         sorguSonucDVO: {
//           adres: "adres"
//         },
//         sorgulamaTuru: "Mersis",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     gibListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********82",
//             adi: "AH***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Example User",
//         portalTuru: 1,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Amasya İcra Dairesi",
//             birimId: "1052225",
//             orgKodu: "1.04.010.000.6003",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "010",
//             duzey4: "000",
//             duzey5: "6003",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2018/48256",
//           dosyaNoYil: 2018,
//           dosyaSira: 48256,
//           dosyaId: "KM18HoWxyCliZzAiJJwfMXRouuiQl4NSVCGvWvSm@utBWgYV2FftBmjwaq3Xx2WE",
//           dosyaAcilisTarihi: "20/08/2018 11:07",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "AHMET ÇITTIR, HANİFE ÇITTIR, ÜÇÜNCÜ ŞAHIS MERVE ÇITTIR, MERVE ÇITTIR, ÜÇÜNCÜ ŞAHIS ÜMİT ÇITTIR",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "2024-07-19T10:00:00Z",
//         sorguSonucDVO: {
//           caddeSokak: "4505 SK.",
//           daireNo: "6",
//           ilKodu: "035",
//           ilceAdi: "TORBALI",
//           kapiNo: "3",
//           mahalleSemt: "TEPEKÖY MAH."
//         },
//         sorgulamaTuru: "Banka Hesabı Sorgulama",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: true,
//         isMock: true
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********82",
//             adi: "AH***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Example User",
//         portalTuru: 1,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Amasya İcra Dairesi",
//             birimId: "1052225",
//             orgKodu: "1.04.010.000.6003",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "010",
//             duzey4: "000",
//             duzey5: "6003",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2018/48256",
//           dosyaNoYil: 2018,
//           dosyaSira: 48256,
//           dosyaId: "KM18HoWxyCliZzAiJJwfMXRouuiQl4NSVCGvWvSm@utBWgYV2FftBmjwaq3Xx2WE",
//           dosyaAcilisTarihi: "20/08/2018 11:07",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "AHMET ÇITTIR, HANİFE ÇITTIR, ÜÇÜNCÜ ŞAHIS MERVE ÇITTIR, MERVE ÇITTIR, ÜÇÜNCÜ ŞAHIS ÜMİT ÇITTIR",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "2024-07-19T10:00:00Z",
//         sorguSonucDVO: {
//           caddeSokak: "4505 SK.",
//           daireNo: "6",
//           ilKodu: "035",
//           ilceAdi: "TORBALI",
//           kapiNo: "3",
//           mahalleSemt: "TEPEKÖY MAH."
//         },
//         sorgulamaTuru: "Banka Hesabı Sorgulama",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: true,
//         isMock: true
//       }
//     ],
//     gsmListeleri: [],
//     disIsleriListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "4********24",
//             adi: "UM** MU*****",
//             soyadi: "OK**",
//             babaAdi: "A****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1996",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 167310185,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2024/63621",
//           dosyaNoYil: 2024,
//           dosyaSira: 63621,
//           dosyaId: "a6GCO27BgvA7@JNmlIjVqgdCfaDgsLZODtJzQRcB4x4r1viF1OkeXI5h5CXf3vES",
//           dosyaAcilisTarihi: "19/07/2024 11:30",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "UMUT MUSTAFA OKUL",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 22:12",
//         sorguSonucDVO: [
//           {
//             adres: "BEEKLUSTLAAN 35",
//             adresTipi: {
//               _value_: "Ev"
//             },
//             eyalet: "0",
//             postaKodu: "7606 SX",
//             sehir: "ALMELO",
//             ulke: "9938"
//           },
//           {
//             adres: "BEEKLUSTLAAN 35",
//             adresTipi: {
//               _value_: "Ev"
//             },
//             eyalet: "0",
//             postaKodu: "7606 SX",
//             sehir: "ALMELO",
//             ulke: "9938"
//           }
//         ],
//         sorgulamaTuru: "DIŞ İŞLERİ",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "4********24",
//             adi: "UM** MU*****",
//             soyadi: "OK**",
//             babaAdi: "A****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1996",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 167310185,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2024/63621",
//           dosyaNoYil: 2024,
//           dosyaSira: 63621,
//           dosyaId: "a6GCO27BgvA7@JNmlIjVqgdCfaDgsLZODtJzQRcB4x4r1viF1OkeXI5h5CXf3vES",
//           dosyaAcilisTarihi: "19/07/2024 11:30",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "UMUT MUSTAFA OKUL",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 22:12",
//         sorguSonucDVO: [
//           {
//             adres: "BEEKLUSTLAAN 35",
//             adresTipi: {
//               _value_: "Ev"
//             },
//             eyalet: "0",
//             postaKodu: "7606 SX",
//             sehir: "ALMELO",
//             ulke: "9938"
//           },
//           {
//             adres: "BEEKLUSTLAAN 35",
//             adresTipi: {
//               _value_: "Ev"
//             },
//             eyalet: "0",
//             postaKodu: "7606 SX",
//             sehir: "ALMELO",
//             ulke: "9938"
//           }
//         ],
//         sorgulamaTuru: "DIŞ İŞLERİ",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     sskCalisaniListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "3********70",
//             adi: "HÜ*****",
//             soyadi: "ÖZ****",
//             babaAdi: "Ü***",
//             anaAdi: "C*****",
//             dogumTarihiStr: "1999",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 218439670,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/27986",
//           dosyaNoYil: 2023,
//           dosyaSira: 27986,
//           dosyaId: "IiLADokQQdn@gx@JSbgpkBbq2e9CQkmoTUvYymTwz2xw5WqqCsqjaZnfC5NPKYDW",
//           dosyaAcilisTarihi: "29/05/2023 20:17",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "EFOR YÖNETİM HİZMETLERİ VE İNSAN KAYNAKLARI TİCARET LİMİTED ŞİRKETİ, EGE EV ÜRÜNLERİ ANONİM ŞİRKETİ, VESTEL ELEKTRONİK SANAYİ VE TİCARET ANONİM ŞİRKETİ, HÜSEYİN ÖZTÜRK",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 20:35",
//         sorguSonucDVO: {
//           sskBilgi: {
//             sonucKodu: 0,
//             tescilKaydi4AList: [
//               {
//                 kimlikBilgileri: {
//                   ad: "HÜSEYİN",
//                   anneAdi: "cemile",
//                   babaAdi: "ÜMİT",
//                   dogumTarihi: "01.12.1999",
//                   dogumYeri: "BÜNYAN",
//                   dogumYili: "1999",
//                   durumKod: "A",
//                   hataKodu: 0,
//                   ilkSoyad: "",
//                   iseGirisTarihi: "2016-02-24",
//                   ittKodu: 0,
//                   sicilNo: "3801201600220",
//                   soyad: "ÖZTÜRK",
//                   tcKimlikNo: "30667740070"
//                 },
//                 kisiSonucKod: 0,
//                 sicilNo: "3801201600220"
//               }
//             ],
//             anaHataKodu: 0
//           },
//           isYeriBilgileri: {
//             data: {
//               hataKodu: 0,
//               sonuc: {
//                 basarili: "true",
//                 hataKod: 0,
//                 mesaj: "başarılı",
//                 isyeriTescil4AOzetArray: [
//                   {
//                     teIlkod: 35,
//                     teYeniSubeKod: 1,
//                     teEskiSubeKod: 1,
//                     teSirano: 1637685,
//                     araciNo: 0,
//                     unvan: "EFOR YÖNETİM HİZMETLERİ VE İNSAN KAYNAKLARI TİCARET LİMİTEDŞİRKETİ",
//                     teKankapckTar: "",
//                     teVergiNo: "3250558853",
//                     sonDonemYil: 2024,
//                     sonDonemAy: 5,
//                     sonDonemHizmetGun: 27,
//                     isNew: true
//                   },
//                   {
//                     teIlkod: 45,
//                     teYeniSubeKod: 1,
//                     teEskiSubeKod: 1,
//                     teSirano: 1061868,
//                     araciNo: 0,
//                     unvan: "VESTEL ELEKTRONİK SANAYİ VE TİCARET ANONİM ŞİRKETİ",
//                     teKankapckTar: "",
//                     teVergiNo: "9250002659",
//                     sonDonemYil: 2024,
//                     sonDonemAy: 5,
//                     sonDonemHizmetGun: 1,
//                     isNew: true
//                   }
//                 ],
//                 isNew: true
//               },
//               isNew: true
//             },
//             metadata: {
//               FMTY: "SUCCESS",
//               FMTE: "İşlem başarıyla gerçekleştirildi!",
//               FMC: "ADALET_SUCCESS"
//             },
//             isNew: true
//           },
//           sskBilgiDetay: {
//             hasData: false,
//             aktif: false,
//             sgkIsyeriDVO: {
//               sigortaliBilgi: {
//                 ad: "HÜSEYİN",
//                 adres: "",
//                 anneAdi: "CEMİLE",
//                 babaAdi: "ÜMİT",
//                 checkDigit: "34",
//                 dogumTarihi: "01.12.1999",
//                 dogumYeri: "BÜNYAN",
//                 donemYilAy: "2024/5",
//                 durumKod: "A",
//                 eski_sube_kod: "1",
//                 ilKod: "45",
//                 ilceKod: "18",
//                 ilkIseGirisTarihi: "2016-02-24",
//                 ilkSoyad: "",
//                 isKoluKodu: "2640",
//                 isyeriAdresi: "MANİSA ORGANİZE SANAYİ BÖLGESİ 3.KISIM AHMET TÜTÜNCÜOĞLU CD. 1 0 MANİSA YUNUSEMRE",
//                 isyeriSicil: "2-2640-01-01-1061868-045-18-34-000",
//                 isyeriUnvan: "VESTEL ELE KTRONİK SANAYİ VE TİCARET ANONİM ŞİRKETİ",
//                 mahiyet: "ELEKTRİK CİHAZ ÜRETİMİ",
//                 mahiyetKod: "2",
//                 sicilNo: "3801201600220",
//                 siraNo: "1061868",
//                 soyad: "ÖZTÜRK",
//                 tcKimlikNo: "30667740070",
//                 yeni_sube_kod: "1"
//               },
//               hataKodu: 0,
//               hataMesaji: ""
//             },
//             ilAdi: "MANİSA",
//             ilceAdi: "YUNUSEMRE"
//           }
//         },
//         sorgulamaTuru: "SSK Çalışanı",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "3********70",
//             adi: "HÜ*****",
//             soyadi: "ÖZ****",
//             babaAdi: "Ü***",
//             anaAdi: "C*****",
//             dogumTarihiStr: "1999",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 218439670,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/27986",
//           dosyaNoYil: 2023,
//           dosyaSira: 27986,
//           dosyaId: "IiLADokQQdn@gx@JSbgpkBbq2e9CQkmoTUvYymTwz2xw5WqqCsqjaZnfC5NPKYDW",
//           dosyaAcilisTarihi: "29/05/2023 20:17",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "EFOR YÖNETİM HİZMETLERİ VE İNSAN KAYNAKLARI TİCARET LİMİTED ŞİRKETİ, EGE EV ÜRÜNLERİ ANONİM ŞİRKETİ, VESTEL ELEKTRONİK SANAYİ VE TİCARET ANONİM ŞİRKETİ, HÜSEYİN ÖZTÜRK",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 20:35",
//         sorguSonucDVO: {
//           sskBilgi: {
//             sonucKodu: 0,
//             tescilKaydi4AList: [
//               {
//                 kimlikBilgileri: {
//                   ad: "HÜSEYİN",
//                   anneAdi: "cemile",
//                   babaAdi: "ÜMİT",
//                   dogumTarihi: "01.12.1999",
//                   dogumYeri: "BÜNYAN",
//                   dogumYili: "1999",
//                   durumKod: "A",
//                   hataKodu: 0,
//                   ilkSoyad: "",
//                   iseGirisTarihi: "2016-02-24",
//                   ittKodu: 0,
//                   sicilNo: "3801201600220",
//                   soyad: "ÖZTÜRK",
//                   tcKimlikNo: "30667740070"
//                 },
//                 kisiSonucKod: 0,
//                 sicilNo: "3801201600220"
//               }
//             ],
//             anaHataKodu: 0
//           },
//           isYeriBilgileri: {
//             data: {
//               hataKodu: 0,
//               sonuc: {
//                 basarili: "true",
//                 hataKod: 0,
//                 mesaj: "başarılı",
//                 isyeriTescil4AOzetArray: [
//                   {
//                     teIlkod: 35,
//                     teYeniSubeKod: 1,
//                     teEskiSubeKod: 1,
//                     teSirano: 1637685,
//                     araciNo: 0,
//                     unvan: "EFOR YÖNETİM HİZMETLERİ VE İNSAN KAYNAKLARI TİCARET LİMİTEDŞİRKETİ",
//                     teKankapckTar: "",
//                     teVergiNo: "3250558853",
//                     sonDonemYil: 2024,
//                     sonDonemAy: 5,
//                     sonDonemHizmetGun: 27,
//                     isNew: true
//                   },
//                   {
//                     teIlkod: 45,
//                     teYeniSubeKod: 1,
//                     teEskiSubeKod: 1,
//                     teSirano: 1061868,
//                     araciNo: 0,
//                     unvan: "VESTEL ELEKTRONİK SANAYİ VE TİCARET ANONİM ŞİRKETİ",
//                     teKankapckTar: "",
//                     teVergiNo: "9250002659",
//                     sonDonemYil: 2024,
//                     sonDonemAy: 5,
//                     sonDonemHizmetGun: 1,
//                     isNew: true
//                   }
//                 ],
//                 isNew: true
//               },
//               isNew: true
//             },
//             metadata: {
//               FMTY: "SUCCESS",
//               FMTE: "İşlem başarıyla gerçekleştirildi!",
//               FMC: "ADALET_SUCCESS"
//             },
//             isNew: true
//           },
//           sskBilgiDetay: {
//             hasData: false,
//             aktif: false,
//             sgkIsyeriDVO: {
//               sigortaliBilgi: {
//                 ad: "HÜSEYİN",
//                 adres: "",
//                 anneAdi: "CEMİLE",
//                 babaAdi: "ÜMİT",
//                 checkDigit: "34",
//                 dogumTarihi: "01.12.1999",
//                 dogumYeri: "BÜNYAN",
//                 donemYilAy: "2024/5",
//                 durumKod: "A",
//                 eski_sube_kod: "1",
//                 ilKod: "45",
//                 ilceKod: "18",
//                 ilkIseGirisTarihi: "2016-02-24",
//                 ilkSoyad: "",
//                 isKoluKodu: "2640",
//                 isyeriAdresi: "MANİSA ORGANİZE SANAYİ BÖLGESİ 3.KISIM AHMET TÜTÜNCÜOĞLU CD. 1 0 MANİSA YUNUSEMRE",
//                 isyeriSicil: "2-2640-01-01-1061868-045-18-34-000",
//                 isyeriUnvan: "VESTEL ELE KTRONİK SANAYİ VE TİCARET ANONİM ŞİRKETİ",
//                 mahiyet: "ELEKTRİK CİHAZ ÜRETİMİ",
//                 mahiyetKod: "2",
//                 sicilNo: "3801201600220",
//                 siraNo: "1061868",
//                 soyad: "ÖZTÜRK",
//                 tcKimlikNo: "30667740070",
//                 yeni_sube_kod: "1"
//               },
//               hataKodu: 0,
//               hataMesaji: ""
//             },
//             ilAdi: "MANİSA",
//             ilceAdi: "YUNUSEMRE"
//           }
//         },
//         sorgulamaTuru: "SSK Çalışanı",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     sskEmeklisiListeleri: [],
//     kamuCalisaniListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "5********36",
//             adi: "DE***",
//             soyadi: "UZ**",
//             babaAdi: "E***",
//             anaAdi: "H****",
//             dogumTarihiStr: "1990",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 45289691,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir 22. İcra Dairesi",
//             birimId: "1018168",
//             orgKodu: "1.04.071.000.6023",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6023",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2021/13165",
//           dosyaNoYil: 2021,
//           dosyaSira: 13165,
//           dosyaId: "kiwa8qL3rQ9lhIOUICQMj0kPcQ6wlljb@@jb9cPcSLF5znPWyYTQ8fvRKLhNnn0S",
//           dosyaAcilisTarihi: "22/11/2021 15:03",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ İL VE İLÇE MİLLİ EĞİTİM MÜDÜRLÜKLERİ, DERYA UZUN, SELÇUKLU İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ MESLEKİ VE TEKNİK EĞİTİM OKULLARI",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 22:25",
//         sorguSonucDVO: {
//           adi: "DERYA                                             ",
//           durumAciklamasi4C: "Aktif Çalışan Sigortalı",
//           durumKapsami4C: "Aktif                                             ",
//           iseBaslamaTarihi: "2008-07-11",
//           kurum: "İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ MESLEKİ VE TEKNİK EĞİTİM OK",
//           kurumIl: "KONYA",
//           kurumIlce: "SELÇUKLU",
//           soyadi: "DEMİR                                             ",
//           tckn: 54364264936,
//           unvani:
//             "HİZMETLİ                                                                                            ",
//           hataKodu: 0,
//           hataMesaji: ""
//         },
//         sorgulamaTuru: "Kamu Çalışanı",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "5********36",
//             adi: "DE***",
//             soyadi: "UZ**",
//             babaAdi: "E***",
//             anaAdi: "H****",
//             dogumTarihiStr: "1990",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 45289691,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir 22. İcra Dairesi",
//             birimId: "1018168",
//             orgKodu: "1.04.071.000.6023",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6023",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2021/13165",
//           dosyaNoYil: 2021,
//           dosyaSira: 13165,
//           dosyaId: "kiwa8qL3rQ9lhIOUICQMj0kPcQ6wlljb@@jb9cPcSLF5znPWyYTQ8fvRKLhNnn0S",
//           dosyaAcilisTarihi: "22/11/2021 15:03",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ İL VE İLÇE MİLLİ EĞİTİM MÜDÜRLÜKLERİ, DERYA UZUN, SELÇUKLU İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ MESLEKİ VE TEKNİK EĞİTİM OKULLARI",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 22:25",
//         sorguSonucDVO: {
//           adi: "DERYA                                             ",
//           durumAciklamasi4C: "Aktif Çalışan Sigortalı",
//           durumKapsami4C: "Aktif                                             ",
//           iseBaslamaTarihi: "2008-07-11",
//           kurum: "İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ MESLEKİ VE TEKNİK EĞİTİM OK",
//           kurumIl: "KONYA",
//           kurumIlce: "SELÇUKLU",
//           soyadi: "DEMİR                                             ",
//           tckn: 54364264936,
//           unvani:
//             "HİZMETLİ                                                                                            ",
//           hataKodu: 0,
//           hataMesaji: ""
//         },
//         sorgulamaTuru: "Kamu Çalışanı",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "5********36",
//             adi: "DE***",
//             soyadi: "UZ**",
//             babaAdi: "E***",
//             anaAdi: "H****",
//             dogumTarihiStr: "1990",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 45289691,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir 22. İcra Dairesi",
//             birimId: "1018168",
//             orgKodu: "1.04.071.000.6023",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6023",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2021/13165",
//           dosyaNoYil: 2021,
//           dosyaSira: 13165,
//           dosyaId: "kiwa8qL3rQ9lhIOUICQMj0kPcQ6wlljb@@jb9cPcSLF5znPWyYTQ8fvRKLhNnn0S",
//           dosyaAcilisTarihi: "22/11/2021 15:03",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ İL VE İLÇE MİLLİ EĞİTİM MÜDÜRLÜKLERİ, DERYA UZUN, SELÇUKLU İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ MESLEKİ VE TEKNİK EĞİTİM OKULLARI",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 22:25",
//         sorguSonucDVO: {
//           adi: "DERYA                                             ",
//           durumAciklamasi4C: "Aktif Çalışan Sigortalı",
//           durumKapsami4C: "Aktif                                             ",
//           iseBaslamaTarihi: "2008-07-11",
//           kurum: "İLÇE MİLLİ EĞİTİM MÜDÜRLÜĞÜ MESLEKİ VE TEKNİK EĞİTİM OK",
//           kurumIl: "KONYA",
//           kurumIlce: "SELÇUKLU",
//           soyadi: "DEMİR                                             ",
//           tckn: 54364264936,
//           unvani:
//             "HİZMETLİ                                                                                            ",
//           hataKodu: 0,
//           hataMesaji: ""
//         },
//         sorgulamaTuru: "Kamu Çalışanı",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     kamuEmeklisiListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********64",
//             adi: "BA*** İS****",
//             soyadi: "GE***",
//             babaAdi: "G*****",
//             anaAdi: "N*****",
//             dogumTarihiStr: "1979",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 132163749,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/50431",
//           dosyaNoYil: 2023,
//           dosyaSira: 50431,
//           dosyaId: "hkP2@FC@hrl8IuKIVIa6X4NFnxK7tvD8JlvThcr12DREF2AID396mLSF54H4dlOB",
//           dosyaAcilisTarihi: "18/09/2023 17:12",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "BURCU GEZER, BARIŞ İSMAİL GEZER, ISS TESİS YÖNETİM HİZMETLERİ ANONİM ŞİRKETİ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 22:01",
//         sorguSonucDVO: {
//           emekliKaydi4C: {
//             aylikBilgisi: [
//               {
//                 ad: "BARIŞ İSMAİL",
//                 aylik: 0.0,
//                 aylikBaslamaTarihi: "01.01.1997",
//                 aylikDurumu: "GEÇİCİ PASİF",
//                 aylikOraniPay: 0,
//                 aylikOraniPayda: 0,
//                 aylikPeriyot: "Üç Aylık(Ocak,Nisan,Temmuz,Ekim)",
//                 derece: 5,
//                 ekGosterge: 0,
//                 ekOdeme: 0.0,
//                 gorevGosterge: 0,
//                 hizmeTopluSorguuresiAy: 8,
//                 hizmeTopluSorguuresiYil: 32,
//                 kademe: 9,
//                 kidemYili: 0,
//                 makamGosterge: 0,
//                 sicilNo: "412612614",
//                 sonOdenenAylik: {
//                   sonOdenenAylik: 1206.66,
//                   sonOdenenAylikFark: 0.0,
//                   sonOdenenDonemBorc: 0.0,
//                   sonOdenenEkOdeme: 60.33,
//                   sonOdenenEkOdemeFark: 0.0,
//                   sonOdenenNetTutar: 1266.99
//                 },
//                 soyad: "GEZER",
//                 temsilGosterge: 0,
//                 unvan: "MEMUR",
//                 yakinlik: "Malul Erkek Yetim"
//               }
//             ],
//             kimlikNo: "19727282064",
//             kod: "0",
//             kodAciklama: "İşlem başarılı"
//           },
//           hataKodu: 0,
//           hataMesaji: "İşlem başarılı"
//         },
//         sorgulamaTuru: "Kamu Emeklisi",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********64",
//             adi: "BA*** İS****",
//             soyadi: "GE***",
//             babaAdi: "G*****",
//             anaAdi: "N*****",
//             dogumTarihiStr: "1979",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 132163749,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/50431",
//           dosyaNoYil: 2023,
//           dosyaSira: 50431,
//           dosyaId: "hkP2@FC@hrl8IuKIVIa6X4NFnxK7tvD8JlvThcr12DREF2AID396mLSF54H4dlOB",
//           dosyaAcilisTarihi: "18/09/2023 17:12",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "BURCU GEZER, BARIŞ İSMAİL GEZER, ISS TESİS YÖNETİM HİZMETLERİ ANONİM ŞİRKETİ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 22:01",
//         sorguSonucDVO: {
//           emekliKaydi4C: {
//             aylikBilgisi: [
//               {
//                 ad: "BARIŞ İSMAİL",
//                 aylik: 0.0,
//                 aylikBaslamaTarihi: "01.01.1997",
//                 aylikDurumu: "GEÇİCİ PASİF",
//                 aylikOraniPay: 0,
//                 aylikOraniPayda: 0,
//                 aylikPeriyot: "Üç Aylık(Ocak,Nisan,Temmuz,Ekim)",
//                 derece: 5,
//                 ekGosterge: 0,
//                 ekOdeme: 0.0,
//                 gorevGosterge: 0,
//                 hizmeTopluSorguuresiAy: 8,
//                 hizmeTopluSorguuresiYil: 32,
//                 kademe: 9,
//                 kidemYili: 0,
//                 makamGosterge: 0,
//                 sicilNo: "412612614",
//                 sonOdenenAylik: {
//                   sonOdenenAylik: 1206.66,
//                   sonOdenenAylikFark: 0.0,
//                   sonOdenenDonemBorc: 0.0,
//                   sonOdenenEkOdeme: 60.33,
//                   sonOdenenEkOdemeFark: 0.0,
//                   sonOdenenNetTutar: 1266.99
//                 },
//                 soyad: "GEZER",
//                 temsilGosterge: 0,
//                 unvan: "MEMUR",
//                 yakinlik: "Malul Erkek Yetim"
//               }
//             ],
//             kimlikNo: "19727282064",
//             kod: "0",
//             kodAciklama: "İşlem başarılı"
//           },
//           hataKodu: 0,
//           hataMesaji: "İşlem başarılı"
//         },
//         sorgulamaTuru: "Kamu Emeklisi",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     bagkurCalisaniListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "2********02",
//             adi: "GÖ***",
//             soyadi: "ŞE*****",
//             babaAdi: "Ö*** F****",
//             anaAdi: "Ş*****",
//             dogumTarihiStr: "1984",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 129248222,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Afyonkarahisar İcra Dairesi",
//             birimId: "1039298",
//             orgKodu: "1.04.003.000.6006",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "003",
//             duzey4: "000",
//             duzey5: "6006",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/17729",
//           dosyaNoYil: 2023,
//           dosyaSira: 17729,
//           dosyaId: "ztf9gFLuxwgyP7bjHymH+b0Rkbg+nWtRM7MP05@2baT9FEOCq+hSY16MzzPqrv@y",
//           dosyaAcilisTarihi: "04/09/2023 00:00",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "AFNAK YEDİEMİN OTOPARK İŞLETMESİ, GÖZDE ŞEKERCİ, KÜÇÜKLER KAFETERYA GIDA İNŞAAT SANAYİ VE TİCARET LİMİTED ŞİRKETİ, AFYONKARAHİSAR SOSYAL GÜVENLİK İL MÜDÜRLÜĞÜ, KOÇ FİAT KREDİ FİNANSMAN ANONİM ŞİRKETİ, FURKAN AKIN",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 20:19",
//         sorguSonucDVO: {
//           islemSonuc: 0,
//           islemSonucAciklama: "Kayıt bulundu.",
//           tescilKaydi4BList: [
//             {
//               ad: "GÖZDE               ",
//               adres: "YEŞİLLİK CD NO:370 KARABAĞLAR                   İZMİR     ",
//               anneAd: "ŞENNUR              ",
//               babaAd: "ÖMER FARUK          ",
//               bagNo: "1168102541",
//               cilt: "28",
//               cinsiyet: "K",
//               dogumTarihi: 19840412,
//               dogumYeri: "KÜTAHYA             ",
//               emeklilikKodu: " ",
//               hane: "29",
//               meslekIlce: "8 ",
//               meslekiIi: 35,
//               nufusIlce: "4",
//               nufusIli: 3,
//               sira: "44",
//               soyad: "ÇENGEL              ",
//               terkAciklama:
//                 "#066-Yeniden Sigortal6-Yeniden Sigortal6-Yeniden Sigortal6-Yeniden Sigortal6-Yeniden Sigortal-Yeniden Sigortalı",
//               terkKodu: 6,
//               terkTarihi: 20240710,
//               tescilTarihi: 20070924,
//               tcKimlikNo: "21880138102",
//               tescilTerklerList: [
//                 {
//                   kodu: 0,
//                   terkTarihi: 20070924
//                 },
//                 {
//                   kodu: 13,
//                   terkTarihi: 20070924
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20200609
//                 },
//                 {
//                   kodu: 18,
//                   terkTarihi: 20200609
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20201101
//                 },
//                 {
//                   kodu: 21,
//                   terkTarihi: 20201101
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20220301
//                 },
//                 {
//                   kodu: 1,
//                   terkTarihi: 20220507
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20220701
//                 },
//                 {
//                   kodu: 1,
//                   terkTarihi: 20220906
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20231111
//                 },
//                 {
//                   kodu: 1,
//                   terkTarihi: 20240523
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20240710
//                 }
//               ]
//             }
//           ],
//           anaHataKodu: 0,
//           anaHataMesaji: "Kayıt bulundu."
//         },
//         sorgulamaTuru: "Bağkur Çalışanı",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "2********02",
//             adi: "GÖ***",
//             soyadi: "ŞE*****",
//             babaAdi: "Ö*** F****",
//             anaAdi: "Ş*****",
//             dogumTarihiStr: "1984",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 129248222,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Afyonkarahisar İcra Dairesi",
//             birimId: "1039298",
//             orgKodu: "1.04.003.000.6006",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "003",
//             duzey4: "000",
//             duzey5: "6006",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/17729",
//           dosyaNoYil: 2023,
//           dosyaSira: 17729,
//           dosyaId: "ztf9gFLuxwgyP7bjHymH+b0Rkbg+nWtRM7MP05@2baT9FEOCq+hSY16MzzPqrv@y",
//           dosyaAcilisTarihi: "04/09/2023 00:00",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "AFNAK YEDİEMİN OTOPARK İŞLETMESİ, GÖZDE ŞEKERCİ, KÜÇÜKLER KAFETERYA GIDA İNŞAAT SANAYİ VE TİCARET LİMİTED ŞİRKETİ, AFYONKARAHİSAR SOSYAL GÜVENLİK İL MÜDÜRLÜĞÜ, KOÇ FİAT KREDİ FİNANSMAN ANONİM ŞİRKETİ, FURKAN AKIN",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 20:19",
//         sorguSonucDVO: {
//           islemSonuc: 0,
//           islemSonucAciklama: "Kayıt bulundu.",
//           tescilKaydi4BList: [
//             {
//               ad: "GÖZDE               ",
//               adres: "YEŞİLLİK CD NO:370 KARABAĞLAR                   İZMİR     ",
//               anneAd: "ŞENNUR              ",
//               babaAd: "ÖMER FARUK          ",
//               bagNo: "1168102541",
//               cilt: "28",
//               cinsiyet: "K",
//               dogumTarihi: 19840412,
//               dogumYeri: "KÜTAHYA             ",
//               emeklilikKodu: " ",
//               hane: "29",
//               meslekIlce: "8 ",
//               meslekiIi: 35,
//               nufusIlce: "4",
//               nufusIli: 3,
//               sira: "44",
//               soyad: "ÇENGEL              ",
//               terkAciklama:
//                 "#066-Yeniden Sigortal6-Yeniden Sigortal6-Yeniden Sigortal6-Yeniden Sigortal6-Yeniden Sigortal-Yeniden Sigortalı",
//               terkKodu: 6,
//               terkTarihi: 20240710,
//               tescilTarihi: 20070924,
//               tcKimlikNo: "21880138102",
//               tescilTerklerList: [
//                 {
//                   kodu: 0,
//                   terkTarihi: 20070924
//                 },
//                 {
//                   kodu: 13,
//                   terkTarihi: 20070924
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20200609
//                 },
//                 {
//                   kodu: 18,
//                   terkTarihi: 20200609
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20201101
//                 },
//                 {
//                   kodu: 21,
//                   terkTarihi: 20201101
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20220301
//                 },
//                 {
//                   kodu: 1,
//                   terkTarihi: 20220507
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20220701
//                 },
//                 {
//                   kodu: 1,
//                   terkTarihi: 20220906
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20231111
//                 },
//                 {
//                   kodu: 1,
//                   terkTarihi: 20240523
//                 },
//                 {
//                   kodu: 6,
//                   terkTarihi: 20240710
//                 }
//               ]
//             }
//           ],
//           anaHataKodu: 0,
//           anaHataMesaji: "Kayıt bulundu."
//         },
//         sorgulamaTuru: "Bağkur Çalışanı",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     bagkurEmeklisiListeleri: [],
//     sgkIsyeriListeleri: [
//       {
//         borcluBilgileri: {
//           kisiKurumId: 233109733,
//           kurumDVO: {
//             kurumAdi: " ED* TU**** OT******* EN**** SA**** VE Tİ***** Lİ***** Şİ*****",
//             vergiNo: "3240972867",
//             veriGelisYeri: 0,
//             faaliyetDurumu: -1,
//             tacirMi: "H",
//             mersisIlKodu: 0,
//             mersisIlceKodu: 0
//           },
//           turu: 1
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/64781",
//           dosyaNoYil: 2023,
//           dosyaSira: 64781,
//           dosyaId: "xztCPapH3AKjFPgSpRQqWlr38XP3y@PrDfs94vIC6FJJlX9lZOrEwqH@Xj9ylBsN",
//           dosyaAcilisTarihi: "07/11/2023 17:10",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 14:44",
//         sorguSonucDVO: {
//           yoneticiBilgileri:
//             "SGK İşyeri Yönetici/Ortak/İşveren sorgusu yapabilmek için gerekli değerlerden bir veya birkaçı mevcut değil (T.C. Kimlik No)<br/>",
//           isYeriBilgi: {
//             isyeriList: [
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45A",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "YENİBAĞLAR MAH.",
//                 TE_ADRES2: "İSMET İNÖNÜ BULVARI",
//                 TE_ADRES3: "ESKİŞEHİR",
//                 TE_ADRES4: "TEPEBAŞI",
//                 TE_CHECK_DIGIT: "31",
//                 TE_DIS_KAPI: "104",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "C",
//                 TE_ILCE_KOD: "15",
//                 TE_ILKOD: "26",
//                 TE_ISKOLU_KOD: "5610",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2022-03-10",
//                 TE_MAHIYET: "LOKANTA VE RESTORAN FAALİYETİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1140521",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1140521",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1140521",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1140521",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "-",
//                 ADR_ADRES2: "-",
//                 ADR_DIS_KAPI: "-",
//                 ADR_IC_KAPI: "",
//                 ILADI: "ESKİŞEHİR",
//                 ILC_ILCEAD: "TEPEBAŞI",
//                 TE_ADRES1: "YENİBAĞLAR MAH.",
//                 TE_ADRES2: "İSMET İNÖNÜ-1 CAD.",
//                 TE_ADRES3: "ESKİŞEHİR",
//                 TE_ADRES4: "TEPEBAŞI",
//                 TE_CHECK_DIGIT: "48",
//                 TE_DIS_KAPI: "104",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "B",
//                 TE_ILCE_KOD: "15",
//                 TE_ILKOD: "26",
//                 TE_ISKOLU_KOD: "5630",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2022-05-13",
//                 TE_MAHIYET: "İÇECEK SUNUM FAALİYETLERİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1141605",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1141605",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1141605",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "MAHMUT OKTAY",
//                     TY_ANA_AD: "ZEYNEP",
//                     TY_BABA_AD: "MEHMET FUAT",
//                     TY_CINSIYET: "E",
//                     TY_CINSIYET_ACIKLAMA: "ERKEK",
//                     TY_DOGUM_TARIH: "04.12.1984",
//                     TY_DOGUM_YER: "ADIYAMAN",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "14302361752",
//                     TY_SIRANO: "1141605",
//                     TY_SOYAD: "AYHAN",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "BURAK",
//                     TY_ANA_AD: "HATİCE BERİN",
//                     TY_BABA_AD: "MEHMET",
//                     TY_CINSIYET: "E",
//                     TY_CINSIYET_ACIKLAMA: "ERKEK",
//                     TY_DOGUM_TARIH: "09.01.1986",
//                     TY_DOGUM_YER: "ADIYAMAN",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "16990272624",
//                     TY_SIRANO: "1141605",
//                     TY_SOYAD: "BOZKURT",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "BÜYÜKDERE MAH.",
//                 TE_ADRES2: "AKÇAAĞAÇ SK. B BLOK",
//                 TE_ADRES3: "ESKİŞEHİR",
//                 TE_ADRES4: "ODUNPAZARI",
//                 TE_CHECK_DIGIT: "15",
//                 TE_DIS_KAPI: "2",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "C",
//                 TE_ILCE_KOD: "14",
//                 TE_ILKOD: "26",
//                 TE_ISKOLU_KOD: "5630",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2022-06-03",
//                 TE_MAHIYET: "İÇECEK SUNUM HİZMETLERİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1142057",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "MAHMUT OKTAY",
//                     TY_ANA_AD: "ZEYNEP",
//                     TY_BABA_AD: "MEHMET FUAT",
//                     TY_CINSIYET: "E",
//                     TY_CINSIYET_ACIKLAMA: "ERKEK",
//                     TY_DOGUM_TARIH: "04.12.1984",
//                     TY_DOGUM_YER: "ADIYAMAN",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "14302361752",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "AYHAN",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   },
//                   {
//                     TY_AD: "MAHMUT OKTAY",
//                     TY_ANA_AD: "ZEYNEP",
//                     TY_BABA_AD: "MEHMET FUAT",
//                     TY_CINSIYET: "E",
//                     TY_CINSIYET_ACIKLAMA: "ERKEK",
//                     TY_DOGUM_TARIH: "04.12.1984",
//                     TY_DOGUM_YER: "ADIYAMAN",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "14302361752",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "AYHAN",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45A",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "SELÇUKLU Mahallesi",
//                 TE_ADRES2: "8.ÇİFTLİKLER KÜME EVLER",
//                 TE_ADRES3: "BOLVADİN",
//                 TE_ADRES4: "AFYONKARAHİSAR",
//                 TE_CHECK_DIGIT: "10",
//                 TE_DIS_KAPI: "198",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "1",
//                 TE_ILCE_KOD: "02",
//                 TE_ILKOD: "03",
//                 TE_ISKOLU_KOD: "5590",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2021-01-20",
//                 TE_MAHIYET: "",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1091420",
//                 TE_STATUS_STRING: "001010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1091420",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1091420",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45A",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "CUMHURİYET",
//                 TE_ADRES2: "YEŞİLYOL",
//                 TE_ADRES3: "AFYONKARAHİSAR",
//                 TE_ADRES4: "MERKEZ",
//                 TE_CHECK_DIGIT: "12",
//                 TE_DIS_KAPI: "45A",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "A",
//                 TE_ILCE_KOD: "01",
//                 TE_ILKOD: "03",
//                 TE_ISKOLU_KOD: "5610",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2021-09-01",
//                 TE_MAHIYET: "KAFE İŞLETMESİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1094526",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1094526",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1094526",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1094526",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45A",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "ERENLER",
//                 TE_ADRES2: "2755",
//                 TE_ADRES3: "AFYONKARAHİSAR",
//                 TE_ADRES4: "MERKEZ",
//                 TE_CHECK_DIGIT: "10",
//                 TE_DIS_KAPI: "11",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "A",
//                 TE_ILCE_KOD: "01",
//                 TE_ILKOD: "03",
//                 TE_ISKOLU_KOD: "5610",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2021-12-01",
//                 TE_MAHIYET: "KAFE İŞLETMESİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1095979",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1095979",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1095979",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1095979",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               }
//             ],
//             hataKodu: 0
//           }
//         },
//         sorgulamaTuru: "SSK İş Yeri Bilgisi",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiKurumId: 233109733,
//           kurumDVO: {
//             kurumAdi: " ED* TU**** OT******* EN**** SA**** VE Tİ***** Lİ***** Şİ*****",
//             vergiNo: "3240972867",
//             veriGelisYeri: 0,
//             faaliyetDurumu: -1,
//             tacirMi: "H",
//             mersisIlKodu: 0,
//             mersisIlceKodu: 0
//           },
//           turu: 1
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir Banka Alacakları İcra Dairesi",
//             birimId: "1063599",
//             orgKodu: "1.04.071.000.6051",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6051",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/64781",
//           dosyaNoYil: 2023,
//           dosyaSira: 64781,
//           dosyaId: "xztCPapH3AKjFPgSpRQqWlr38XP3y@PrDfs94vIC6FJJlX9lZOrEwqH@Xj9ylBsN",
//           dosyaAcilisTarihi: "07/11/2023 17:10",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "21/07/2024 14:44",
//         sorguSonucDVO: {
//           yoneticiBilgileri:
//             "SGK İşyeri Yönetici/Ortak/İşveren sorgusu yapabilmek için gerekli değerlerden bir veya birkaçı mevcut değil (T.C. Kimlik No)<br/>",
//           isYeriBilgi: {
//             isyeriList: [
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45A",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "YENİBAĞLAR MAH.",
//                 TE_ADRES2: "İSMET İNÖNÜ BULVARI",
//                 TE_ADRES3: "ESKİŞEHİR",
//                 TE_ADRES4: "TEPEBAŞI",
//                 TE_CHECK_DIGIT: "31",
//                 TE_DIS_KAPI: "104",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "C",
//                 TE_ILCE_KOD: "15",
//                 TE_ILKOD: "26",
//                 TE_ISKOLU_KOD: "5610",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2022-03-10",
//                 TE_MAHIYET: "LOKANTA VE RESTORAN FAALİYETİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1140521",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1140521",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1140521",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1140521",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "-",
//                 ADR_ADRES2: "-",
//                 ADR_DIS_KAPI: "-",
//                 ADR_IC_KAPI: "",
//                 ILADI: "ESKİŞEHİR",
//                 ILC_ILCEAD: "TEPEBAŞI",
//                 TE_ADRES1: "YENİBAĞLAR MAH.",
//                 TE_ADRES2: "İSMET İNÖNÜ-1 CAD.",
//                 TE_ADRES3: "ESKİŞEHİR",
//                 TE_ADRES4: "TEPEBAŞI",
//                 TE_CHECK_DIGIT: "48",
//                 TE_DIS_KAPI: "104",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "B",
//                 TE_ILCE_KOD: "15",
//                 TE_ILKOD: "26",
//                 TE_ISKOLU_KOD: "5630",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2022-05-13",
//                 TE_MAHIYET: "İÇECEK SUNUM FAALİYETLERİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1141605",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1141605",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1141605",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "MAHMUT OKTAY",
//                     TY_ANA_AD: "ZEYNEP",
//                     TY_BABA_AD: "MEHMET FUAT",
//                     TY_CINSIYET: "E",
//                     TY_CINSIYET_ACIKLAMA: "ERKEK",
//                     TY_DOGUM_TARIH: "04.12.1984",
//                     TY_DOGUM_YER: "ADIYAMAN",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "14302361752",
//                     TY_SIRANO: "1141605",
//                     TY_SOYAD: "AYHAN",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "BURAK",
//                     TY_ANA_AD: "HATİCE BERİN",
//                     TY_BABA_AD: "MEHMET",
//                     TY_CINSIYET: "E",
//                     TY_CINSIYET_ACIKLAMA: "ERKEK",
//                     TY_DOGUM_TARIH: "09.01.1986",
//                     TY_DOGUM_YER: "ADIYAMAN",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "16990272624",
//                     TY_SIRANO: "1141605",
//                     TY_SOYAD: "BOZKURT",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "BÜYÜKDERE MAH.",
//                 TE_ADRES2: "AKÇAAĞAÇ SK. B BLOK",
//                 TE_ADRES3: "ESKİŞEHİR",
//                 TE_ADRES4: "ODUNPAZARI",
//                 TE_CHECK_DIGIT: "15",
//                 TE_DIS_KAPI: "2",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "C",
//                 TE_ILCE_KOD: "14",
//                 TE_ILKOD: "26",
//                 TE_ISKOLU_KOD: "5630",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2022-06-03",
//                 TE_MAHIYET: "İÇECEK SUNUM HİZMETLERİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1142057",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "MAHMUT OKTAY",
//                     TY_ANA_AD: "ZEYNEP",
//                     TY_BABA_AD: "MEHMET FUAT",
//                     TY_CINSIYET: "E",
//                     TY_CINSIYET_ACIKLAMA: "ERKEK",
//                     TY_DOGUM_TARIH: "04.12.1984",
//                     TY_DOGUM_YER: "ADIYAMAN",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "14302361752",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "AYHAN",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   },
//                   {
//                     TY_AD: "MAHMUT OKTAY",
//                     TY_ANA_AD: "ZEYNEP",
//                     TY_BABA_AD: "MEHMET FUAT",
//                     TY_CINSIYET: "E",
//                     TY_CINSIYET_ACIKLAMA: "ERKEK",
//                     TY_DOGUM_TARIH: "04.12.1984",
//                     TY_DOGUM_YER: "ADIYAMAN",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "026",
//                     TY_MERNIS_NO: "14302361752",
//                     TY_SIRANO: "1142057",
//                     TY_SOYAD: "AYHAN",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45A",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "SELÇUKLU Mahallesi",
//                 TE_ADRES2: "8.ÇİFTLİKLER KÜME EVLER",
//                 TE_ADRES3: "BOLVADİN",
//                 TE_ADRES4: "AFYONKARAHİSAR",
//                 TE_CHECK_DIGIT: "10",
//                 TE_DIS_KAPI: "198",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "1",
//                 TE_ILCE_KOD: "02",
//                 TE_ILKOD: "03",
//                 TE_ISKOLU_KOD: "5590",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2021-01-20",
//                 TE_MAHIYET: "",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1091420",
//                 TE_STATUS_STRING: "001010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1091420",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1091420",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45A",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "CUMHURİYET",
//                 TE_ADRES2: "YEŞİLYOL",
//                 TE_ADRES3: "AFYONKARAHİSAR",
//                 TE_ADRES4: "MERKEZ",
//                 TE_CHECK_DIGIT: "12",
//                 TE_DIS_KAPI: "45A",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "A",
//                 TE_ILCE_KOD: "01",
//                 TE_ILKOD: "03",
//                 TE_ISKOLU_KOD: "5610",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2021-09-01",
//                 TE_MAHIYET: "KAFE İŞLETMESİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1094526",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1094526",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1094526",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1094526",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               },
//               {
//                 ADR_ADRES1: "CUMHURİYET MAH.",
//                 ADR_ADRES2: "YEŞİLYOL CAD. KÖŞEM APT.",
//                 ADR_DIS_KAPI: "45A",
//                 ADR_IC_KAPI: "A",
//                 ILADI: "AFYON",
//                 ILC_ILCEAD: "MERKEZ",
//                 TE_ADRES1: "ERENLER",
//                 TE_ADRES2: "2755",
//                 TE_ADRES3: "AFYONKARAHİSAR",
//                 TE_ADRES4: "MERKEZ",
//                 TE_CHECK_DIGIT: "10",
//                 TE_DIS_KAPI: "11",
//                 TE_ESKI_SUBE_KOD: "01",
//                 TE_IC_KAPI: "A",
//                 TE_ILCE_KOD: "01",
//                 TE_ILKOD: "03",
//                 TE_ISKOLU_KOD: "5610",
//                 TE_ISYERI_UNVAN: "EDC TURİZM OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                 TE_KANKAPAL_TAR: "2021-12-01",
//                 TE_MAHIYET: "KAFE İŞLETMESİ",
//                 TE_MAHIYET_KOD: "2",
//                 TE_SIRANO: "1095979",
//                 TE_STATUS_STRING: "000010000000000",
//                 TE_TESCIL_TIP: "3",
//                 TE_UNVAN1: "EDC TURİZM",
//                 TE_UNVAN2: "OTELCİLİK ENERJİ SANAYİ VE TİCARET LİMİTED ŞİRKET",
//                 TE_UNVAN3: "İ",
//                 TE_YENI_SUBE_KOD: "01",
//                 yoneticiler: [
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1095979",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "E",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ TİPİ TANIMSIZ"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1095979",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "O",
//                     TY_YKOD_ACIKLAMA: "ORTAK"
//                   },
//                   {
//                     TY_AD: "GÖZDE",
//                     TY_ANA_AD: "ŞENNUR",
//                     TY_BABA_AD: "ÖMER FARUK",
//                     TY_CINSIYET: "K",
//                     TY_CINSIYET_ACIKLAMA: "KADIN",
//                     TY_DOGUM_TARIH: "12.04.1984",
//                     TY_DOGUM_YER: "KÜTAHYA",
//                     TY_ESKI_SUBE_KOD: "01",
//                     TY_ILKOD: "003",
//                     TY_MERNIS_NO: "21880138102",
//                     TY_SIRANO: "1095979",
//                     TY_SOYAD: "ÇENGEL",
//                     TY_UNVAN: "",
//                     TY_UYRUK: "TC",
//                     TY_YENI_SUBE_KOD: "01",
//                     TY_YKOD: "Y",
//                     TY_YKOD_ACIKLAMA: "YÖNETİCİ"
//                   }
//                 ]
//               }
//             ],
//             hataKodu: 0
//           }
//         },
//         sorgulamaTuru: "SSK İş Yeri Bilgisi",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     egmListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "2********02",
//             adi: "GÖ***",
//             soyadi: "ŞE*****",
//             babaAdi: "Ö*** F****",
//             anaAdi: "Ş*****",
//             dogumTarihiStr: "1984",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 129248222,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Afyonkarahisar İcra Dairesi",
//             birimId: "1039298",
//             orgKodu: "1.04.003.000.6006",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "003",
//             duzey4: "000",
//             duzey5: "6006",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2023/17729",
//           dosyaNoYil: 2023,
//           dosyaSira: 17729,
//           dosyaId: "ztf9gFLuxwgyP7bjHymH+egT@csVMWjU5NI+IvIp8RzWeapvHOQJM3+eJEmeFtBc",
//           dosyaAcilisTarihi: "04/09/2023 00:00",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "AFNAK YEDİEMİN OTOPARK İŞLETMESİ, GÖZDE ŞEKERCİ, KÜÇÜKLER KAFETERYA GIDA İNŞAAT SANAYİ VE TİCARET LİMİTED ŞİRKETİ, AFYONKARAHİSAR SOSYAL GÜVENLİK İL MÜDÜRLÜĞÜ, KOÇ FİAT KREDİ FİNANSMAN ANONİM ŞİRKETİ, FURKAN AKIN",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false,
//           adet: 0
//         },
//         sorguTarihi: "21/07/2024 19:43",
//         sorguSonucDVO: {
//           aracBilgileri: {
//             hataMesaj: "İşlem Başarılı",
//             hatakod: 0,
//             sonuc: "-1",
//             output: [
//               {
//                 plaka: "35****34",
//                 marka: "FIAT",
//                 model: "2019",
//                 ticariAd: "FIAT EGEA",
//                 renk: "GRİ",
//                 cins: "OTOMOBİL",
//                 plakaSifreli: "2W7yixG8XGQMwP0oEK7txQ==",
//                 isMock: false,
//                 aracMahrumiyet: undefined
//               },
//               {
//                 plaka: "35****34",
//                 marka: "FIAT2",
//                 model: "2019",
//                 ticariAd: "FIAT EGEA",
//                 renk: "GRİ",
//                 cins: "OTOMOBİL",
//                 plakaSifreli: "2W7yixG8XGQMwP0oEK7txQ==",
//                 isMock: false,
//                 aracMahrumiyet: {
//                   sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//                   portalTuru: 2,
//                   dosyaOzetDVO: {
//                     birimDVO: {
//                       birimAdi: "Afyonkarahisar İcra Dairesi",
//                       birimId: "1039298",
//                       orgKodu: "1.04.003.000.6006",
//                       duzey1: "1",
//                       duzey2: "04",
//                       duzey3: "003",
//                       duzey4: "000",
//                       duzey5: "6006",
//                       birimTuru1: "11",
//                       birimTuru2: "1101",
//                       birimTuru3: "1199",
//                       evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//                       yeniBirimEkle: true,
//                       orgKoduDegisti: false,
//                       isTumunuKopyala: false,
//                       eskiAd: "",
//                       eskiOrgKod: "",
//                       taranmamisKaydetme: "",
//                       testMi: 0
//                     },
//                     rolIdList: [],
//                     isBirimKapali: false,
//                     dosyaTurKod: 35,
//                     dosyaTurAd: "İcra Dosyası",
//                     dosyaNo: "2023/17729",
//                     dosyaNoYil: 2023,
//                     dosyaSira: 17729,
//                     dosyaId: "ztf9gFLuxwgyP7bjHymH+SiX8r@c0mxke6l+Lzn062gJPWnj@swX7OIudufUa8d3",
//                     dosyaAcilisTarihi: "04/09/2023 00:00",
//                     genelDosyaDurumu: "Açık",
//                     genelDosyaDurumuAsInteger: 0,
//                     taraflar:
//                       "FURKAN AKIN, AFNAK YEDİEMİN OTOPARK İŞLETMESİ, KOÇ FİAT KREDİ FİNANSMAN ANONİM ŞİRKETİ, GÖZDE ŞEKERCİ, AFYONKARAHİSAR SOSYAL GÜVENLİK İL MÜDÜRLÜĞÜ, KÜÇÜKLER KAFETERYA GIDA İNŞAAT SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//                     vekilId: 0,
//                     vekilMi: false,
//                     vekilHazineAvukatiMi: false
//                   },
//                   sorguTarihi: "21/07/2024 22:14",
//                   sorguSonucDVO: {
//                     hataMesaj: "İşlem Başarılı",
//                     hatakod: 0,
//                     sonuc: "-1",
//                     output: [
//                       {
//                         kurumAd: "Sosyal Güvenlik Kurumu",
//                         turu: "3",
//                         ekleyenBirim: "AFYONKARAHİSAR SOSYAL GÜVENLİK İL MÜDÜRL",
//                         evrakTarihi: "8/3/2023",
//                         evrakSayisi: "kn-16820",
//                         eklemeTarihi: "8/3/2023",
//                         referansNo: "null",
//                         takyidatAdi: "HACİZLİ(H)",
//                         belgeId: "d2828eab-06ad-4be9-ae95-a34eeca12618",
//                         aciklama: "6183 sayılı kanun",
//                         alacakTutari: 18800,
//                         plaka: "35AGL734",
//                         kurumKodu: 4,
//                         takyidaTopluSorguayisi: 12,
//                         ekleyenBirimKodu: "00301001",
//                         eklemeTarihSaat: "08.03.2023 15:52:16"
//                       },
//                       {
//                         kurumAd: "Adalet Bakanlığı",
//                         turu: "3",
//                         ekleyenBirim: "İzmir Banka Alacakları İcra Dairesi",
//                         evrakTarihi: "1/12/2023",
//                         evrakSayisi: "2023-57264 İcra",
//                         eklemeTarihi: "1/12/2023",
//                         referansNo: "2023120110492750753",
//                         takyidatAdi: "HACİZLİ(H)",
//                         belgeId: "d2828eab-06ad-4be9-ae95-a34eeca12618",
//                         aciklama: " 35AGL734 - Haciz - Tarafların Talebi - ",
//                         alacakTutari: 0,
//                         plaka: "35AGL734",
//                         kurumKodu: 2,
//                         takyidaTopluSorguayisi: 12,
//                         ekleyenBirimKodu: "1063599",
//                         eklemeTarihSaat: "01.12.2023 10:49:27"
//                       },
//                       {
//                         kurumAd: "Adalet Bakanlığı",
//                         turu: "3",
//                         ekleyenBirim: "İzmir Banka Alacakları İcra Dairesi",
//                         evrakTarihi: "8/12/2023",
//                         evrakSayisi: "2023-66664 İcra",
//                         eklemeTarihi: "8/12/2023",
//                         referansNo: "2023120809501847336",
//                         takyidatAdi: "HACİZLİ(H)",
//                         belgeId: "d2828eab-06ad-4be9-ae95-a34eeca12618",
//                         aciklama: " 35AGL734 - Haciz - Tarafların Talebi - ",
//                         alacakTutari: 0,
//                         plaka: "35AGL734",
//                         kurumKodu: 2,
//                         takyidaTopluSorguayisi: 12,
//                         ekleyenBirimKodu: "1063599",
//                         eklemeTarihSaat: "08.12.2023 09:50:32"
//                       }
//                     ],
//                     kurumAdi: "Türkiye Noterler Birliği",
//                     kurumTipi: 0
//                   },
//                   sorgulamaTuru: "EGM Mahrumiyet Sorgu",
//                   sorguSonucu: true,
//                   hasError: false,
//                   hasData: false,
//                   isMock: false
//                 }
//               }
//             ],
//             kurumAdi: "Türkiye Noterler Birliği",
//             kurumTipi: 0
//           },
//           borcluBilgileri: {
//             kisiTumDVO: {
//               olumKaydi: false,
//               isAvukat: false,
//               isNoter: false,
//               isTarafUyari: false,
//               isHakimSavci: false,
//               mernisDegisiklikVarmi: false,
//               mernisDegisiklikNedeni: "",
//               sorguTuru: 0,
//               tcKimlikNo: "2********02",
//               adi: "GÖ***",
//               soyadi: "ŞE*****",
//               babaAdi: "Ö*** F****",
//               anaAdi: "Ş*****",
//               dogumTarihiStr: "1984",
//               aktifMernisAdresiSorgusu: true,
//               hasOlumKaydi: false
//             },
//             kisiKurumId: 129248222,
//             turu: 0
//           }
//         },
//         sorgulamaTuru: "EGM Sorgu",
//         sorguSonucu: false,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     postaCekiListeleri: [],
//     takbisListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "5********58",
//             adi: "AL* İN***",
//             soyadi: "ÜL**",
//             babaAdi: "M******",
//             anaAdi: "Z****",
//             dogumTarihiStr: "1969",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 87038663,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Av.ÇAĞATAY ÜSKÜDAR",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Karşıyaka 2. İcra Dairesi",
//             birimId: "1005423",
//             orgKodu: "1.04.078.000.6002",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "078",
//             duzey4: "000",
//             duzey5: "6002",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2020/1848",
//           dosyaNoYil: 2020,
//           dosyaSira: 1848,
//           dosyaId: "rb+07sq7dQQ2gE8dCmYGf7mbwqTOlow7ANL@DW7Uaya8teTHHxtJEeCPGUSTXrWP",
//           dosyaAcilisTarihi: "24/06/2020 13:17",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar:
//             "AYTEKİN ERMANER, DENİZBANK ANONİM ŞİRKETİ, YAPI VE KREDİ BANKASI ANONİM ŞİRKETİ, SEMİH ERMANER, KARŞIYAKA BELEDİYE BAŞKANLIĞI, ALİ İNANÇ ÜLKÜ",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "08/07/2024 17:20",
//         sorguSonucDVO: [
//           {
//             tasinmazDVO: {
//               tasinmazId: 127082612,
//               altTasinmazId: 0,
//               tasinmazTip: "AnaTasinmaz",
//               il: "SAKARYA",
//               ilce: "KARASU",
//               kurum: "Karasu",
//               mahalle: "AŞAĞIAZİZİYE M",
//               mevkii: "Uzungöl",
//               nitelik: "Arsa1 hepsi var",
//               ciltNo: "31",
//               sayfaNo: "2968",
//               yuzolcum: 24.0,
//               pafta: "F25-D-19-B-2-B",
//               ada: "1039",
//               parsel: "9",
//               eklenti: 0,
//               teferruat: 0,
//               muhdesat: 0,
//               tapuBolumDurum: "Aktif",
//               terkinIslem: {
//                 islemId: 0,
//                 islemDurum: "BELIRSIZ"
//               },
//               tesisIslem: {
//                 islemId: 229289185,
//                 islemTanimAd:
//                   "3402 S.Y. Kadastro Kanunun Ek 1. Maddesi Gereği Köy/Mahalle/Mevki/Ada/Parsel Bilgilerinin Düzeltilmesi İşlemi",
//                 baslamaSekilAd: "Kadastro Müdürlüğünün Yazısıyla",
//                 yevmiye: {
//                   yevmiyeId: 160951330,
//                   kurum: "Karasu",
//                   tarih: "2023-01-20T15:28:44.3",
//                   yevmiyeNo: 2074
//                 },
//                 islemDurum: "ISLEM_TAMAMLANDI"
//               },
//               takbisHisse: {
//                 borcluBilgileri: {
//                   kisiTumDVO: {
//                     olumKaydi: false,
//                     isAvukat: false,
//                     isNoter: false,
//                     isTarafUyari: false,
//                     isHakimSavci: false,
//                     mernisDegisiklikVarmi: false,
//                     mernisDegisiklikNedeni: "",
//                     sorguTuru: 0,
//                     tcKimlikNo: "51874509458",
//                     adi: "ALİ İNANÇ",
//                     soyadi: "ÜLKÜ",
//                     babaAdi: "MUSTAFA",
//                     anaAdi: "ZÜHAL",
//                     dogumTarihiStr: "31/03/1969",
//                     aktifMernisAdresiSorgusu: true,
//                     hasOlumKaydi: false
//                   },
//                   kisiKurumId: 87038663,
//                   turu: 0
//                 },
//                 hisseList: [
//                   {
//                     hisseId: 726756740,
//                     pay: "1.000",
//                     payda: "1.000",
//                     hisseTip: "Musterek",
//                     istirakNo: 0,
//                     tapuBolumDurum: "Aktif",
//                     malik: {
//                       malikId: 126188508,
//                       malikTip: "GercekKisi",
//                       malikOzetBilgi: "(SN:126188508) ALİ İNANÇ ÜLKÜ : MUSTAFA Oğlu KN:51874509458"
//                     },
//                     terkinIslem: {
//                       islemId: 0,
//                       islemDurum: "BELIRSIZ"
//                     },
//                     tesisIslem: {
//                       islemId: 229289185,
//                       islemTanimAd:
//                         "3402 S.Y. Kadastro Kanunun Ek 1. Maddesi Gereği Köy/Mahalle/Mevki/Ada/Parsel Bilgilerinin Düzeltilmesi İşlemi",
//                       baslamaSekilAd: "Kadastro Müdürlüğünün Yazısıyla",
//                       yevmiye: {
//                         yevmiyeId: 160951330,
//                         kurum: "Karasu",
//                         tarih: "2023-01-20T15:28:44.3",
//                         yevmiyeNo: 2074
//                       },
//                       islemDurum: "ISLEM_TAMAMLANDI"
//                     },
//                     tasinmazId: 127082612,
//                     hisseMahrumiyet: {
//                       serhList: [
//                         {
//                           serhBeyanId: 13798870,
//                           serhBeyanTip: "Beyan",
//                           sablon: "Yönetim Planının Belirtilmesi",
//                           tapuBolumDurum: "Aktif",
//                           cumle: "Y.P.TARİH :07/01/1991",
//                           baslamaTarih: "03/01/0001",
//                           bitisTarih: "03/01/0001",
//                           sure: 0.0,
//                           ilkTesisIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           tapuBolumSerhBeyanId: 19834805,
//                           tesisIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           terkinIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           lehtarlar: [],
//                           tapuBolum: "Zemin",
//                           tapuBolumReferans: 17314737,
//                           usTopluSorguerhBeyanlar: [],
//                           secildi: false
//                         },
//                         {
//                           serhBeyanId: 216732268,
//                           serhBeyanTip: "Serh",
//                           sablon: "İcrai Haciz",
//                           tapuBolumDurum: "Aktif",
//                           cumle: "İkiye Cumhuriyrketi lehine haciz işlenmiştir.",
//                           baslamaTarih: "03/05/2021",
//                           bitisTarih: "03/05/2021",
//                           sure: 0.0,
//                           ilkTesisIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           tapuBolumSerhBeyanId: 464503114,
//                           tesisIslem: {
//                             islemId: 190575469,
//                             islemTanimAd: "İcrai Haciz Tesisi",
//                             baslamaSekilAd: "Web Başvurusu İle",
//                             yevmiye: {
//                               yevmiyeId: 132336845,
//                               kurum: "Çiğli",
//                               kurumid: "225",
//                               tarih: "2021-05-03T12:05:47.31",
//                               yevmiyeNo: 7224
//                             },
//                             islemDurum: "ISLEM_TAMAMLANDI"
//                           },
//                           terkinIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           lehtarlar: [],
//                           tapuBolum: "ZeminHisse",
//                           tapuBolumReferans: 402021235,
//                           usTopluSorguerhBeyanlar: [],
//                           secildi: false
//                         }
//                       ],
//                       sonuc: false,
//                       id: 15702161245647422441547271652607226231644773,
//                       isCompressed: false,
//                       htmlHataKodList: [],
//                       isMock: false
//                     }
//                   },
//                   {
//                     hisseId: 726756740,
//                     pay: "1.000",
//                     payda: "1.000",
//                     hisseTip: "Musterek2",
//                     istirakNo: 0,
//                     tapuBolumDurum: "Aktif2",
//                     malik: {
//                       malikId: 126188508,
//                       malikTip: "GercekKisi",
//                       malikOzetBilgi: "(SN:126188508) ALİ İNANÇ ÜLKÜ : MUSTAFA Oğlu KN:51874509458"
//                     },
//                     terkinIslem: {
//                       islemId: 0,
//                       islemDurum: "BELIRSIZ"
//                     },
//                     tesisIslem: {
//                       islemId: 229289185,
//                       islemTanimAd:
//                         "3402 S.Y. Kadastro Kanunun Ek 1. Maddesi Gereği Köy/Mahalle/Mevki/Ada/Parsel Bilgilerinin Düzeltilmesi İşlemi",
//                       baslamaSekilAd: "Kadastro Müdürlüğünün Yazısıyla",
//                       yevmiye: {
//                         yevmiyeId: 160951330,
//                         kurum: "Karasu",
//                         tarih: "2023-01-20T15:28:44.3",
//                         yevmiyeNo: 2074
//                       },
//                       islemDurum: "ISLEM_TAMAMLANDI"
//                     },
//                     tasinmazId: 127082612,
//                     hisseMahrumiyet: {
//                       serhList: [
//                         {
//                           serhBeyanId: 13798870,
//                           serhBeyanTip: "Beyan",
//                           sablon: "Yönetim Planının Belirtilmesi",
//                           tapuBolumDurum: "Aktif",
//                           cumle: "Y.P.TARİH :07/01/1991",
//                           baslamaTarih: "03/01/0001",
//                           bitisTarih: "03/01/0001",
//                           sure: 0.0,
//                           ilkTesisIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           tapuBolumSerhBeyanId: 19834805,
//                           tesisIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           terkinIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           lehtarlar: [],
//                           tapuBolum: "Zemin",
//                           tapuBolumReferans: 17314737,
//                           usTopluSorguerhBeyanlar: [],
//                           secildi: false
//                         },
//                         {
//                           serhBeyanId: 216732268,
//                           serhBeyanTip: "Serh",
//                           sablon: "İcrai Haciz",
//                           tapuBolumDurum: "Aktif",
//                           cumle: "sı Anonim Şirketi lehine haciz işlenmiştir.",
//                           baslamaTarih: "03/05/2021",
//                           bitisTarih: "03/05/2021",
//                           sure: 0.0,
//                           ilkTesisIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           tapuBolumSerhBeyanId: 464503114,
//                           tesisIslem: {
//                             islemId: 190575469,
//                             islemTanimAd: "İcrai Haciz Tesisi",
//                             baslamaSekilAd: "Web Başvurusu İle",
//                             yevmiye: {
//                               yevmiyeId: 132336845,
//                               kurum: "Çiğli",
//                               kurumid: "225",
//                               tarih: "2021-05-03T12:05:47.31",
//                               yevmiyeNo: 7224
//                             },
//                             islemDurum: "ISLEM_TAMAMLANDI"
//                           },
//                           terkinIslem: {
//                             islemId: 0,
//                             islemDurum: "BELIRSIZ"
//                           },
//                           lehtarlar: [],
//                           tapuBolum: "ZeminHisse",
//                           tapuBolumReferans: 402021235,
//                           usTopluSorguerhBeyanlar: [],
//                           secildi: false
//                         }
//                       ],
//                       sonuc: false,
//                       id: 15702161245647422441547271652607226231644773,
//                       isCompressed: false,
//                       htmlHataKodList: [],
//                       isMock: false
//                     }
//                   }
//                 ],
//                 htmlHataKodList: [],
//                 id: 4532111142065221251050550307707401255321311,
//                 isCompressed: false,
//                 isMock: false,
//                 sonuc: false
//               }
//             },
//             yuzolcumu: "24.00000"
//           },
//           {
//             tasinmazDVO: {
//               tasinmazId: 127506356,
//               altTasinmazId: 0,
//               tasinmazTip: "AnaTasinmaz",
//               il: "MUĞLA",
//               ilce: "ULA",
//               kurum: "Ula",
//               mahalle: "ALPARSLAN M",
//               mevkii: "Bozyer",
//               nitelik: "Tarla Mahrumiyet yok",
//               ciltNo: "3",
//               sayfaNo: "261",
//               yuzolcum: 163.98,
//               pafta: "N20-C-14-D-3-C",
//               ada: "360",
//               parsel: "34",
//               eklenti: 0,
//               teferruat: 0,
//               muhdesat: 0,
//               tapuBolumDurum: "Aktif",
//               terkinIslem: {
//                 islemId: 0,
//                 islemDurum: "BELIRSIZ"
//               },
//               tesisIslem: {
//                 islemId: 230840037,
//                 islemTanimAd: "3402 S.Y.nın 22/A Md. Gereğince Yenilemenin Tescili",
//                 baslamaSekilAd: "İlgili Kurumun Yazısı",
//                 yevmiye: {
//                   yevmiyeId: 162101319,
//                   kurum: "Ula",
//                   tarih: "2023-02-16T16:19:22.32",
//                   yevmiyeNo: 831
//                 },
//                 islemDurum: "ISLEM_TAMAMLANDI"
//               },
//               takbisHisse: {
//                 borcluBilgileri: {
//                   kisiTumDVO: {
//                     olumKaydi: false,
//                     isAvukat: false,
//                     isNoter: false,
//                     isTarafUyari: false,
//                     isHakimSavci: false,
//                     mernisDegisiklikVarmi: false,
//                     mernisDegisiklikNedeni: "",
//                     sorguTuru: 0,
//                     tcKimlikNo: "51874509458",
//                     adi: "ALİ İNANÇ",
//                     soyadi: "ÜLKÜ",
//                     babaAdi: "MUSTAFA",
//                     anaAdi: "ZÜHAL",
//                     dogumTarihiStr: "31/03/1969",
//                     aktifMernisAdresiSorgusu: true,
//                     hasOlumKaydi: false
//                   },
//                   kisiKurumId: 87038663,
//                   turu: 0
//                 },
//                 hisseList: [
//                   {
//                     hisseId: 726756740,
//                     pay: "1.000",
//                     payda: "1.000",
//                     hisseTip: "Musterek",
//                     istirakNo: 0,
//                     tapuBolumDurum: "Aktif",
//                     malik: {
//                       malikId: 126188508,
//                       malikTip: "GercekKisi",
//                       malikOzetBilgi: "(SN:126188508) ALİ İNANÇ ÜLKÜ : MUSTAFA Oğlu KN:51874509458"
//                     },
//                     terkinIslem: {
//                       islemId: 0,
//                       islemDurum: "BELIRSIZ"
//                     },
//                     tesisIslem: {
//                       islemId: 229289185,
//                       islemTanimAd:
//                         "3402 S.Y. Kadastro Kanunun Ek 1. Maddesi Gereği Köy/Mahalle/Mevki/Ada/Parsel Bilgilerinin Düzeltilmesi İşlemi",
//                       baslamaSekilAd: "Kadastro Müdürlüğünün Yazısıyla",
//                       yevmiye: {
//                         yevmiyeId: 160951330,
//                         kurum: "Karasu",
//                         tarih: "2023-01-20T15:28:44.3",
//                         yevmiyeNo: 2074
//                       },
//                       islemDurum: "ISLEM_TAMAMLANDI"
//                     },
//                     tasinmazId: 127082612,
//                     hisseMahrumiyet: undefined
//                   },
//                   {
//                     hisseId: 726756740,
//                     pay: "1.000",
//                     payda: "1.000",
//                     hisseTip: "Musterek2",
//                     istirakNo: 0,
//                     tapuBolumDurum: "Aktif2",
//                     malik: {
//                       malikId: 126188508,
//                       malikTip: "GercekKisi",
//                       malikOzetBilgi: "(SN:126188508) ALİ İNANÇ ÜLKÜ : MUSTAFA Oğlu KN:51874509458"
//                     },
//                     terkinIslem: {
//                       islemId: 0,
//                       islemDurum: "BELIRSIZ"
//                     },
//                     tesisIslem: {
//                       islemId: 229289185,
//                       islemTanimAd:
//                         "3402 S.Y. Kadastro Kanunun Ek 1. Maddesi Gereği Köy/Mahalle/Mevki/Ada/Parsel Bilgilerinin Düzeltilmesi İşlemi",
//                       baslamaSekilAd: "Kadastro Müdürlüğünün Yazısıyla",
//                       yevmiye: {
//                         yevmiyeId: 160951330,
//                         kurum: "Karasu",
//                         tarih: "2023-01-20T15:28:44.3",
//                         yevmiyeNo: 2074
//                       },
//                       islemDurum: "ISLEM_TAMAMLANDI"
//                     },
//                     tasinmazId: 127082612,
//                     hisseMahrumiyet: undefined
//                   }
//                 ],
//                 htmlHataKodList: [],
//                 id: 4532111142065221251050550307707401255321311,
//                 isCompressed: false,
//                 isMock: false,
//                 sonuc: false
//               }
//             },
//             yuzolcumu: "163.98000"
//           },
//           {
//             tasinmazDVO: {
//               tasinmazId: 127506356,
//               altTasinmazId: 0,
//               tasinmazTip: "AnaTasinmaz",
//               il: "MUĞLA",
//               ilce: "ULA",
//               kurum: "Ula",
//               mahalle: "ALPARSLAN M",
//               mevkii: "Bozyer",
//               nitelik: "Tarla3 Hisse yok",
//               ciltNo: "3",
//               sayfaNo: "261",
//               yuzolcum: 163.98,
//               pafta: "N20-C-14-D-3-C",
//               ada: "360",
//               parsel: "34",
//               eklenti: 0,
//               teferruat: 0,
//               muhdesat: 0,
//               tapuBolumDurum: "Aktif",
//               terkinIslem: {
//                 islemId: 0,
//                 islemDurum: "BELIRSIZ"
//               },
//               tesisIslem: {
//                 islemId: 230840037,
//                 islemTanimAd: "3402 S.Y.nın 22/A Md. Gereğince Yenilemenin Tescili",
//                 baslamaSekilAd: "İlgili Kurumun Yazısı",
//                 yevmiye: {
//                   yevmiyeId: 162101319,
//                   kurum: "Ula",
//                   tarih: "2023-02-16T16:19:22.32",
//                   yevmiyeNo: 831
//                 },
//                 islemDurum: "ISLEM_TAMAMLANDI"
//               },
//               takbisHisse: undefined
//             },
//             yuzolcumu: "163.98000"
//           }
//         ],
//         sorgulamaTuru: "Takbis",
//         sorguSonucu: false,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],
//     sgkHacizListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********82",
//             adi: "AH***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Example User",
//         portalTuru: 1,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Amasya İcra Dairesi",
//             birimId: "1052225",
//             orgKodu: "1.04.010.000.6003",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "010",
//             duzey4: "000",
//             duzey5: "6003",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2018/48256",
//           dosyaNoYil: 2018,
//           dosyaSira: 48256,
//           dosyaId: "KM18HoWxyCliZzAiJJwfMXRouuiQl4NSVCGvWvSm@utBWgYV2FftBmjwaq3Xx2WE",
//           dosyaAcilisTarihi: "20/08/2018 11:07",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "AHMET ÇITTIR, HANİFE ÇITTIR, ÜÇÜNCÜ ŞAHIS MERVE ÇITTIR, MERVE ÇITTIR, ÜÇÜNCÜ ŞAHIS ÜMİT ÇITTIR",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "2024-07-19T10:00:00Z",
//         sorguSonucDVO: [
//           {
//             kisiTipString: "GSS Sigortalısı (Gerçek)",
//             show: true
//           },
//           {
//             kisiTipString: "Diğer - Gerçek",
//             show: false
//           },
//           {
//             kisiTipString: "Öğrenci",
//             show: true
//           }
//         ],
//         sorgulamaTuru: "SGK Haciz Sorgulama",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: true,
//         isMock: true
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "189568982",
//             adi: "AH*uuuuu**",
//             soyadi: "uuuu****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Example User",
//         portalTuru: 1,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Yozgat İcra Dairesi",
//             birimId: "1052225",
//             orgKodu: "1.04.010.000.6003",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "010",
//             duzey4: "000",
//             duzey5: "6003",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2018/48256",
//           dosyaNoYil: 2018,
//           dosyaSira: 48256,
//           dosyaId: "KM18HoWxyCliZzAiJJwfMXRouuiQl4NSVCGvWvSm@utBWgYV2FftBmjwaq3Xx2WE",
//           dosyaAcilisTarihi: "20/08/2018 11:07",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "AHMET ÇITTIR, HANİFE ÇITTIR, ÜÇÜNCÜ ŞAHIS MERVE ÇITTIR, MERVE ÇITTIR, ÜÇÜNCÜ ŞAHIS ÜMİT ÇITTIR",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "2024-07-19T10:00:00Z",
//         sorguSonucDVO: [
//           {
//             kisiTipString: "GSS Sigortalısı (Gerçek)",
//             show: true
//           },
//           {
//             kisiTipString: "Diğer - Gerçek",
//             show: false
//           },
//           {
//             kisiTipString: "Diğer - Yalan",
//             show: false
//           },
//           {
//             kisiTipString: "Öğrenci",
//             show: true
//           }
//         ],
//         sorgulamaTuru: "SGK Haciz Sorgulama",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: true,
//         isMock: true
//       }
//     ],
//     icraDosyasiListeleri: [
//       {
//         borcluBilgileri: {
//           kisiKurumId: 227203298,
//           kurumDVO: {
//             kurumAdi: " AL****** AT** PL***** ME*** GE** DÖ***** SA**** VE Tİ***** Lİ***** Şİ*****",
//             vergiNo: "0681759506",
//             veriGelisYeri: 0,
//             faaliyetDurumu: -1,
//             tacirMi: "H",
//             mersisIlKodu: 0,
//             mersisIlceKodu: 0
//           },
//           turu: 1
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir 19. İcra Dairesi",
//             birimId: "1011353",
//             orgKodu: "1.04.071.000.6019",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6019",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2024/1501",
//           dosyaNoYil: 2024,
//           dosyaSira: 1501,
//           dosyaId: "KTGxEpnEXZmPwjAvHb76Y1R3QPgexbTDuvt5J1KPnM27hdraWAKnKN6wQJs1LEg1",
//           dosyaAcilisTarihi: "09/02/2024 17:26",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "ALTUNTAŞ ATIK PLASTİK METAL GERİ DÖNÜŞÜM SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 31672,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "22/07/2024 12:35",
//         sorguSonucDVO: [
//           {
//             dosyaId: "Qb2XYOhg89PEcYLwRAI4PBNTBBji7OGvopUtJ3Daf3VtnXMBZxKzTjxwWavKQiDL",
//             dayanakBelgeZorunlumu: false,
//             takipTurAciklamasi: "iLAMLI",
//             takipYoluAciklamasi: "İlamların İcrası, Para ve Teminat Verilmesi Hakkındaki İlamların İcrası",
//             takipSekliciklamasi:
//               "( ÖRNEK: Eski No: 53 - Yeni No: 4-5 ) Para Borcuna Veya Teminat Verilmesine Veya Bir İşin Yapılması Veya Yapılmamasına , İrtifak Hakkının Kaldırılmasına Veya Yükletilmesine Dair İcra Emri",
//             dosyaAcilisTarihiStr: "2023-04-04 10:52:04",
//             dosyaDurumuAciklama: "Açık",
//             dosyaTurKodu: 35,
//             dosyaNo: "2023/1077",
//             birimAdi: "Turgutlu İcra Dairesi",
//             dosyaTurKoduAciklama: "İcra Dosyası",
//             tarafRolu: "ALACAKLI",
//             guncelKisiKurumId: 227203298
//           },
//           {
//             dosyaId: "Qb2XYOhg89PEcYLwRAI4PBNTBBji7OGvopUtJ3Daf3VtnXMBZxKzTjxwWavKQiDL",
//             dayanakBelgeZorunlumu: false,
//             takipTurAciklamasi: "",
//             takipYoluAciklamasi: " İcrası, Para ve Teminat Verilmesi Hakkındaki İlamların İcrası",
//             takipSekliciklamasi:
//               "( ÖRNEK: Eski No: 53 - Yeni No: 4-5 ) Para Borcuna Veya Teminat Verilmesine Veya Bir İşin Yapılması Veya Yapılmamasına , İrtifak Hakkının Kaldırılmasına Veya Yükletilmesine Dair İcra Emri",
//             dosyaAcilisTarihiStr: "2023-04-04 10:52:04",
//             dosyaDurumuAciklama: "Kapalı",
//             dosyaTurKodu: 35,
//             dosyaNo: "2023/1077",
//             birimAdi: "Turgutlu İcra Dairesi",
//             dosyaTurKoduAciklama: "İcra Dosyası",
//             tarafRolu: "ALACAKLI",
//             guncelKisiKurumId: 227203298
//           }
//         ],
//         sorgulamaTuru: "İCRA DOSYASI",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiKurumId: 227203298,
//           kurumDVO: {
//             kurumAdi: " AL****** AT** PL***** ME*** GE** DÖ***** SA**** VE Tİ***** Lİ***** Şİ*****",
//             vergiNo: "0681759506",
//             veriGelisYeri: 0,
//             faaliyetDurumu: -1,
//             tacirMi: "H",
//             mersisIlKodu: 0,
//             mersisIlceKodu: 0
//           },
//           turu: 1
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir 19. İcra Dairesi",
//             birimId: "1011353",
//             orgKodu: "1.04.071.000.6019",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6019",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2024/1501",
//           dosyaNoYil: 2024,
//           dosyaSira: 1501,
//           dosyaId: "KTGxEpnEXZmPwjAvHb76Y1R3QPgexbTDuvt5J1KPnM27hdraWAKnKN6wQJs1LEg1",
//           dosyaAcilisTarihi: "09/02/2024 17:26",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "ALTUNTAŞ ATIK PLASTİK METAL GERİ DÖNÜŞÜM SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 31672,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "22/07/2024 12:35",
//         sorguSonucDVO: [
//           {
//             dosyaId: "Qb2XYOhg89PEcYLwRAI4PBNTBBji7OGvopUtJ3Daf3VtnXMBZxKzTjxwWavKQiDL",
//             dayanakBelgeZorunlumu: false,
//             takipTurAciklamasi: "iLAMLI",
//             takipYoluAciklamasi: "İlamların İcrası, Para ve Teminat Verilmesi Hakkındaki İlamların İcrası",
//             takipSekliciklamasi:
//               "( ÖRNEK: Eski No: 53 - Yeni No: 4-5 ) Para Borcuna Veya Teminat Verilmesine Veya Bir İşin Yapılması Veya Yapılmamasına , İrtifak Hakkının Kaldırılmasına Veya Yükletilmesine Dair İcra Emri",
//             dosyaAcilisTarihiStr: "2023-04-04 10:52:04",
//             dosyaDurumuAciklama: "Açık",
//             dosyaTurKodu: 35,
//             dosyaNo: "2023/1077",
//             birimAdi: "Turgutlu İcra Dairesi",
//             dosyaTurKoduAciklama: "İcra Dosyası",
//             tarafRolu: "ALACAKLI",
//             guncelKisiKurumId: 227203298
//           }
//         ],
//         sorgulamaTuru: "İCRA DOSYASI",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       },
//       {
//         borcluBilgileri: {
//           kisiKurumId: 227203298,
//           kurumDVO: {
//             kurumAdi: " AL****** AT** PL***** ME*** GE** DÖ***** SA**** VE Tİ***** Lİ***** Şİ*****",
//             vergiNo: "0681759506",
//             veriGelisYeri: 0,
//             faaliyetDurumu: -1,
//             tacirMi: "H",
//             mersisIlKodu: 0,
//             mersisIlceKodu: 0
//           },
//           turu: 1
//         },
//         sorgulayanBilgileri: "Av.NURBAY AKTAŞ",
//         portalTuru: 2,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "İzmir 19. İcra Dairesi",
//             birimId: "1011353",
//             orgKodu: "1.04.071.000.6019",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "071",
//             duzey4: "000",
//             duzey5: "6019",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2024/1501",
//           dosyaNoYil: 2024,
//           dosyaSira: 1501,
//           dosyaId: "KTGxEpnEXZmPwjAvHb76Y1R3QPgexbTDuvt5J1KPnM27hdraWAKnKN6wQJs1LEg1",
//           dosyaAcilisTarihi: "09/02/2024 17:26",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "ALTUNTAŞ ATIK PLASTİK METAL GERİ DÖNÜŞÜM SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
//           vekilId: 31672,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "22/07/2024 12:35",
//         sorguSonucDVO: [
//           {
//             dosyaId: "Qb2XYOhg89PEcYLwRAI4PBNTBBji7OGvopUtJ3Daf3VtnXMBZxKzTjxwWavKQiDL",
//             dayanakBelgeZorunlumu: false,
//             takipTurAciklamasi: "iLAMLI",
//             takipYoluAciklamasi: "İlamların İcrası, Para ve Teminat Verilmesi Hakkındaki İlamların İcrası",
//             takipSekliciklamasi:
//               "( ÖRNEK: Eski No: 53 - Yeni No: 4-5 ) Para Borcuna Veya Teminat Verilmesine Veya Bir İşin Yapılması Veya Yapılmamasına , İrtifak Hakkının Kaldırılmasına Veya Yükletilmesine Dair İcra Emri",
//             dosyaAcilisTarihiStr: "2023-04-04 10:52:04",
//             dosyaDurumuAciklama: "Açık",
//             dosyaTurKodu: 35,
//             dosyaNo: "2023/1077",
//             birimAdi: "Turgutlu İcra Dairesi",
//             dosyaTurKoduAciklama: "İcra Dosyası",
//             tarafRolu: "ALACAKLI",
//             guncelKisiKurumId: 227203298
//           }
//         ],
//         sorgulamaTuru: "İCRA DOSYASI",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: false,
//         isMock: false
//       }
//     ],

//     bankaListeleri: [
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "1********82",
//             adi: "AH***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Example User",
//         portalTuru: 1,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Amasya İcra Dairesi",
//             birimId: "1052225",
//             orgKodu: "1.04.010.000.6003",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "010",
//             duzey4: "000",
//             duzey5: "6003",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2018/48256",
//           dosyaNoYil: 2018,
//           dosyaSira: 48256,
//           dosyaId: "KM18HoWxyCliZzAiJJwfMXRouuiQl4NSVCGvWvSm@utBWgYV2FftBmjwaq3Xx2WE",
//           dosyaAcilisTarihi: "20/08/2018 11:07",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "AHMET ÇITTIR, HANİFE ÇITTIR, ÜÇÜNCÜ ŞAHIS MERVE ÇITTIR, MERVE ÇITTIR, ÜÇÜNCÜ ŞAHIS ÜMİT ÇITTIR",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "2024-07-19T10:00:00Z",
//         sorguSonucDVO: [
//           {
//             bankaAdi: "Ziraat Bankası",
//             eftKodu: 10,
//             logo: [0, 1, 2, 3], // Example byte array
//             logoBulunamadi: false
//           },
//           {
//             bankaAdi: "Garanti Bankası",
//             eftKodu: 20,
//             logo: [4, 5, 6, 7], // Example byte array
//             logoBulunamadi: false
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           },
//           {
//             bankaAdi: "Garanti Bankası",
//             eftKodu: 20,
//             logo: [4, 5, 6, 7], // Example byte array
//             logoBulunamadi: false
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           },
//           {
//             bankaAdi: "Garanti Bankası",
//             eftKodu: 20,
//             logo: [4, 5, 6, 7], // Example byte array
//             logoBulunamadi: false
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           },
//           {
//             bankaAdi: "İş Bankası",
//             eftKodu: 30,
//             logo: [], // No logo found
//             logoBulunamadi: true
//           }
//         ],
//         sorgulamaTuru: "Banka Hesabı Sorgulama",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: true,
//         isMock: true
//       },
//       {
//         borcluBilgileri: {
//           kisiTumDVO: {
//             olumKaydi: false,
//             isAvukat: false,
//             isNoter: false,
//             isTarafUyari: false,
//             isHakimSavci: false,
//             mernisDegisiklikVarmi: false,
//             mernisDegisiklikNedeni: "",
//             sorguTuru: 0,
//             tcKimlikNo: "123******82",
//             adi: "Yu***",
//             soyadi: "ÇI****",
//             babaAdi: "M*****",
//             anaAdi: "H*****",
//             dogumTarihiStr: "1989",
//             aktifMernisAdresiSorgusu: true,
//             hasOlumKaydi: false
//           },
//           kisiKurumId: 135568056,
//           turu: 0
//         },
//         sorgulayanBilgileri: "Example User",
//         portalTuru: 1,
//         dosyaOzetDVO: {
//           birimDVO: {
//             birimAdi: "Ankara İcra Dairesi",
//             birimId: "1052225",
//             orgKodu: "1.04.010.000.6003",
//             duzey1: "1",
//             duzey2: "04",
//             duzey3: "010",
//             duzey4: "000",
//             duzey5: "6003",
//             birimTuru1: "11",
//             birimTuru2: "1101",
//             birimTuru3: "1199",
//             evraginBirimDVODaGonderilecegiBirimBilgisi: 0,
//             yeniBirimEkle: true,
//             orgKoduDegisti: false,
//             isTumunuKopyala: false,
//             eskiAd: "",
//             eskiOrgKod: "",
//             taranmamisKaydetme: "",
//             testMi: 0
//           },
//           rolIdList: [],
//           isBirimKapali: false,
//           dosyaTurKod: 35,
//           dosyaTurAd: "İcra Dosyası",
//           dosyaNo: "2018/48256",
//           dosyaNoYil: 2018,
//           dosyaSira: 48256,
//           dosyaId: "KM18HoWxyCliZzAiJJwfMXRouuiQl4NSVCGvWvSm@utBWgYV2FftBmjwaq3Xx2WE",
//           dosyaAcilisTarihi: "20/08/2018 11:07",
//           genelDosyaDurumu: "Açık",
//           genelDosyaDurumuAsInteger: 0,
//           taraflar: "AHMET ÇITTIR, HANİFE ÇITTIR, ÜÇÜNCÜ ŞAHIS MERVE ÇITTIR, MERVE ÇITTIR, ÜÇÜNCÜ ŞAHIS ÜMİT ÇITTIR",
//           vekilId: 0,
//           vekilMi: false,
//           vekilHazineAvukatiMi: false
//         },
//         sorguTarihi: "2024-07-19T10:00:00Z",
//         sorguSonucDVO: [
//           {
//             bankaAdi: "Ziraat Bankası",
//             eftKodu: 10,
//             logo: [0, 1, 2, 3], // Example byte array
//             logoBulunamadi: false
//           },
//           {
//             bankaAdi: "Garanti Bankası",
//             eftKodu: 20,
//             logo: [4, 5, 6, 7], // Example byte array
//             logoBulunamadi: false
//           }
//         ],
//         sorgulamaTuru: "Banka Hesabı Sorgulama",
//         sorguSonucu: true,
//         hasError: false,
//         hasData: true,
//         isMock: true
//       }
//     ],
//     tarafListeleri: [
//       {
//         birimAdi: "string1",
//         dosyaNo: "string2",
//         alacakliAdi: "string3",
//         borcluAdi: "string4",
//         borcluTCVKNo: "string5",
//         dosyaDurumu: "string6",
//         rol: "string7"
//       },
//       {
//         birimAdi: "string10",
//         dosyaNo: "string2",
//         alacakliAdi: "string3",
//         borcluAdi: "string4",
//         borcluTCVKNo: "string5",
//         dosyaDurumu: "string6",
//         rol: "string7"
//       },
//       {
//         birimAdi: "string100",
//         dosyaNo: "string2",
//         alacakliAdi: "string3",
//         borcluAdi: "string4",
//         borcluTCVKNo: "string5",
//         dosyaDurumu: "string6",
//         rol: "string7"
//       }
//     ]
//   });
