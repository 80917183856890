import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import KullaniciTarihceNotuService from "../../../../services/tarihce-kullanici-notlari/tarihce-kullanici-notlari.services";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../../../utils/DropdownValues";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import { Button } from "primereact/button";
import { useToast } from "../../../../utils/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import {
  CreateTarihceKullaniciNotuDto,
  TarihceKullaniciNotuDto,
  UpdateTarihceKullaniciNotuDto,
} from "../../../../services/tarihce-kullanici-notlari/tarihce-kullanici-notlari.dto";
import { useContext } from "react";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const now: Date = new Date();

const UpsertTarihceKullaniciNotu = (props: any) => {
  const tarihceKullaniciNotu: TarihceKullaniciNotuDto =
    props.tarihceKullaniciNotu;
  const setTarihceKullaniciNotu = props.setTarihceKullaniciNotu;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchTarihceKullaniciNotu = props.refetchTarihceKullaniciNotu;
  const icraFoy: IcraFoyDto = props.icraFoy;
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  let updateTarihceKullaniciNotuDto: UpdateTarihceKullaniciNotuDto;

  if (tarihceKullaniciNotu === null || tarihceKullaniciNotu === undefined) {
    updateTarihceKullaniciNotuDto = {
      id: 0,
      tur: "",
      aciklama: "",
    };
  } else {
    updateTarihceKullaniciNotuDto = {
      ...tarihceKullaniciNotu,
    };
  }

  const formik = useFormik<UpdateTarihceKullaniciNotuDto>({
    initialValues: updateTarihceKullaniciNotuDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateTarihceKullaniciNotuDto> = {};
      if (!data.tur) {
        errors.tur = "Tür alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      if (formik.values.id === 0) {
        var kullaniciTarihceNotuCreateDto: CreateTarihceKullaniciNotuDto = {
          ...formik.values,
          icraFoyId: icraFoy.id,
        };
        KullaniciTarihceNotuService.Create(kullaniciTarihceNotuCreateDto).then(
          (res) => {
            if (res.result && !res.result?.error) {
              setVisible(false);
              formik.resetForm();
              setTarihceKullaniciNotu(undefined);
              refetchTarihceKullaniciNotu();
              toast.show(
                "Kullanıcı tarihçe notu başarıyla eklendi.",
                "success"
              );
            }
          }
        );
      } else {
        var kullaniciTarihceNotuUpdateDto: UpdateTarihceKullaniciNotuDto = {
          ...formik.values,
        };
        KullaniciTarihceNotuService.Update(kullaniciTarihceNotuUpdateDto).then(
          (res) => {
            if (res.result && !res.result?.error) {
              setVisible(false);
              formik.resetForm();
              setTarihceKullaniciNotu(undefined);
              refetchTarihceKullaniciNotu();
              toast.show(
                "Kullanıcı tarihçe notu başarıyla güncellendi.",
                "success"
              );
            }
          }
        );
      }
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.id === 0
          ? "Tarihçe Kullanıcı Notu Ekle"
          : "Tarihçe Kullanıcı Notu Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setTarihceKullaniciNotu(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setTarihceKullaniciNotu(undefined);
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={formik.submitForm}
          />
        </div>
      }
      className=""
      style={{ width: "50vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12">
          <label className="text-primary" htmlFor="tur">Tür</label>
          <Dropdown
            id="tur"
            name="tur"
            value={formik.values.tur}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tur", e.value);
            }}
            options={DropdownDatas.GetTarihceKullaniciNotuTuru()}
            optionLabel="label"
            optionValue="value"
            placeholder="Tür Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tur"),
            })}
          />
          {FormikValueIsValid(formik, "tur") && (
            <div className="p-error mt-3">{formik.errors.tur}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="aciklama">Açıklama</label>
          <InputTextarea
            id="aciklama"
            name="aciklama"
            rows={3}
            value={formik.values.aciklama}
            onChange={(e) => {
              formik.setFieldValue("aciklama", e.target.value.toLocaleUpperCase("tr"));
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "aciklama"),
            })}
          />
          {FormikValueIsValid(formik, "aciklama") && (
            <div className="p-error mt-3">{formik.errors.aciklama}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertTarihceKullaniciNotu;
