import { classNames } from "primereact/utils";
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from "react";
import { AppTopbarRef } from "../types/types";
import { LayoutContext } from "./context/layoutcontext";
import i18n from "../lib/i18n";
import { locale } from "primereact/api";
import axios from "axios";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, UserHasPermissions } from "../utils/auth";
import { useHub } from "../utils/buro-hub";
import authService from "../services/auth/auth.service";
import { Permission } from "../services/auth/permissions";

const AppTopbar = forwardRef<AppTopbarRef>((props, ref) => {
  const { layoutConfig, layoutState, onMenuToggle, showProfileSidebar } = useContext(LayoutContext);
  const menubuttonRef = useRef(null);
  const topbarmenuRef = useRef(null);
  const topbarmenubuttonRef = useRef(null);
  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const languagesMenu = useRef<Menu>(null);
  const profileMenu = useRef<Menu>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
    topbarmenu: topbarmenuRef.current,
    topbarmenubutton: topbarmenubuttonRef.current
  }));

  let languages = [
    {
      label: "Türkçe",
      command: () => {
        i18n.changeLanguage("tr");
        locale("tr");
        axios.defaults.headers.common["Accept-Language"] = "tr-TR";
        setSelectedLang("tr");
      }
    },
    {
      label: "English(US)",
      command: () => {
        i18n.changeLanguage("en");
        // locale("en");
        axios.defaults.headers.common["Accept-Language"] = "en-US";
        setSelectedLang("en");
      }
    }
  ];

  let profileItems = [
    {
      label: t("MyProfile"),
      icon: "pi pi-user",
      command: () => {
        navigate("/app/profile");
      },
      visible: UserHasPermissions([Permission.AccountDefault])
    },
    {
      label: t("Settings"),
      icon: "pi pi-cog",
      visible: UserHasPermissions([Permission.AccountDefault]),
      command: () => {
        navigate("/app/settings");
      }
    },
    {
      label: t("ChangeEmailAddress"),
      icon: "pi pi-inbox",
      visible: UserHasPermissions([Permission.EmailChange]),
      command: () => {
        navigate("/app/change-email");
      }
    },
    {
      label: t("ChangePassword"),
      icon: "pi pi-lock",
      visible: UserHasPermissions([Permission.ChangePassword]),
      command: () => {
        navigate("/app/change-password");
      }
    },
    {
      label: "Alt Kullanıcılarım",
      icon: "pi pi-users",
      visible: UserHasPermissions([Permission.SubUserDefault]),
      command: () => {
        navigate("/app/alt-kullanicilarim");
      }
    },
    {
      label: "Lisanslarım",
      icon: "pi pi-key",
      visible: UserHasPermissions([Permission.LicencesDefault]),
      command: () => {
        navigate("/app/lisanslarim");
      }
    },
    {
      label: "Sepetim",
      icon: "pi pi-shopping-cart",
      visible: UserHasPermissions([Permission.SubUserDefault]),
      command: () => {
        navigate("/app/sepetim");
      }
    },
    {
      label: t("Logout"),
      icon: "pi pi-sign-out",
      visible: true,
      command: async () => {
        setBlocked(true);
        await hub.leaveGroup();
        await authService.RevokeRefreshToken({ token: auth.getUser().refreshToken });
        auth.removeUserFromStorage();
        setBlocked(false);
        navigate("/login", { replace: true });
      }
    }
  ];

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img src={`/images/${layoutConfig.colorScheme !== "light" ? "logo" : "logo"}.png`} height="70" alt="logo" />
        <span>Pratik Portal</span>
      </Link>

      <button
        ref={menubuttonRef}
        type="button"
        className="p-link layout-menu-button layout-topbar-button"
        onClick={onMenuToggle}
      >
        <i className="pi pi-bars" />
      </button>

      <button
        ref={topbarmenubuttonRef}
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={showProfileSidebar}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <div
        ref={topbarmenuRef}
        className={classNames("layout-topbar-menu", {
          "layout-topbar-menu-mobile-active": layoutState.profileSidebarVisible
        })}
      >
        {/* <div className="flex justify-content-center align-items-center">
          <Menu model={languages} popup ref={languagesMenu} />
          <div
            className="flex flex-row gap-2 align-items-center cursor-pointer"
            onClick={(e) => languagesMenu?.current?.toggle(e)}
          >
            <span className={`flag flag-${selectedLang}`} />
            <i className="pi pi-chevron-down"></i>
          </div>
        </div> */}

        <div className="flex justify-content-center align-items-center">
          <Menu model={profileItems} popup ref={profileMenu} />
          <div
            className="flex flex-row gap-2 align-items-center cursor-pointer"
            onClick={(e) => profileMenu?.current?.toggle(e)}
          >
            <button type="button" className="p-link layout-topbar-button">
              <i className="pi pi-user"></i>
              <span>{t("Profile")}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

AppTopbar.displayName = "AppTopbar";

export default AppTopbar;
