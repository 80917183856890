import React, { useState, createContext, useEffect, useContext } from "react";
import { LayoutState, ChildContainerProps, LayoutConfig, LayoutContextProps } from "../../types/types";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { useElectron } from "../../utils/electron-connection";
import { PrimeReactContext } from "primereact/api";
export const LayoutContext = createContext({} as LayoutContextProps);

export const LayoutProvider = ({ children }: ChildContainerProps) => {
  const { setRipple, changeTheme } = useContext(PrimeReactContext);
  const electron = useElectron();
  const [layoutConfig, setLayoutConfig] = useState<LayoutConfig>({
    ripple: false,
    inputStyle: "outlined",
    menuMode: "static",
    colorScheme: "light",
    theme: "lara-light-indigo",
    scale: 12
  });

  useEffect(() => {
    electron.getSettings().then((settings) => {
      if (settings && changeTheme) {
        if (settings.theme && settings.colorScheme) {
          changeTheme(layoutConfig.theme, settings.theme, "theme-css", () => {
            setLayoutConfig((prevState: LayoutConfig) => ({
              ...prevState,
              colorScheme: settings.colorScheme ? settings.colorScheme : "light",
              theme: settings.theme ? settings.theme : "lara-light-indigo",
              scale: settings.scale ? settings.scale : 12
            }));
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    electron.getSettings().then((settings) => {
      if (settings) {
        settings.theme = layoutConfig.theme;
        settings.colorScheme = layoutConfig.colorScheme;
        settings.scale = layoutConfig.scale;
        electron.setSettings(settings);
      }
    });
  }, [layoutConfig.theme, layoutConfig.colorScheme, layoutConfig.scale]);

  const [layoutState, setLayoutState] = useState<LayoutState>({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false
  });

  const [blocked, setBlocked] = useState<boolean>(false);

  const onMenuToggle = () => {
    if (isOverlay()) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        overlayMenuActive: !prevLayoutState.overlayMenuActive
      }));
    }

    if (isDesktop()) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive
      }));
    } else {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive
      }));
    }
  };

  const showProfileSidebar = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      profileSidebarVisible: !prevLayoutState.profileSidebarVisible
    }));
  };

  const isOverlay = () => {
    return layoutConfig.menuMode === "overlay";
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const value: LayoutContextProps = {
    layoutConfig,
    setLayoutConfig,
    layoutState,
    setLayoutState,
    onMenuToggle,
    showProfileSidebar,
    setBlocked
  };

  const pageLoader = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center w-screen h-screen">
        <ProgressSpinner />
      </div>
    );
  };
  return (
    <LayoutContext.Provider value={value}>
      <BlockUI blocked={blocked} fullScreen template={pageLoader}>
        {children}
      </BlockUI>
    </LayoutContext.Provider>
  );
};
