import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import TalimatDosyasiService from "../../../../services/talimat-dosyasi/talimat-dosyasi.service";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../../../utils/DropdownValues";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import { Button } from "primereact/button";
import { useToast } from "../../../../utils/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import {
  CreateTalimatDosyasiDto,
  UpdateTalimatDosyasiDto,
  TalimatDosyasiDto,
} from "../../../../services/talimat-dosyasi/talimat-dosyasi.dto";
import { Calendar } from "primereact/calendar";
import { useQuery } from "@tanstack/react-query";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect } from "react";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { KarsiYanDto } from "../../../../services/karsi-yan/karsi-yan.dto";
import { MuvekkilTipi } from "../../../../services/muvekkil/muvekkil.dto";
import { InputMask } from "primereact/inputmask";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const now: Date = new Date();

const UpsertTalimatDosyasi = (props: any) => {
  const talimatDosyasi: TalimatDosyasiDto = props.talimatDosyasi;
  const setTalimatDosyasi = props.setTalimatDosyasi;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchTalimatDosyasi = props.refetchTalimatDosyasi;
  const icraFoy: IcraFoyDto = props.icraFoy;
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  let upsertTalimatDosyasiDto: UpdateTalimatDosyasiDto;

  if (talimatDosyasi === null || talimatDosyasi === undefined) {
    upsertTalimatDosyasiDto = {
      id: 0,
      borcluIds: [],
      icraMudurlugu: "",
      dosyaNo: "",
      aciklama: "",
    };
  } else {
    upsertTalimatDosyasiDto = {
      ...talimatDosyasi,
      borcluIds: talimatDosyasi.borclular.map((x) => x.id),
    };
  }

  const formik = useFormik<UpdateTalimatDosyasiDto>({
    initialValues: upsertTalimatDosyasiDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateTalimatDosyasiDto> = {};
      if (data.borcluIds === undefined || data.borcluIds.length === 0) {
        errors.borcluIds = "Borçlu alanı boş bırakılamaz";
      }
      if (!data.icraMudurlugu) {
        errors.icraMudurlugu = "İcra Müdürlüğü alanı boş bırakılamaz";
      }
      if (!data.dosyaNo) {
        errors.dosyaNo = "Dosya No alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      if (formik.values.id === 0) {
        var talimatDosyasiCreateDto: CreateTalimatDosyasiDto = {
          ...formik.values,
          icraFoyId: icraFoy.id,
        };
        TalimatDosyasiService.Create(talimatDosyasiCreateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setTalimatDosyasi(undefined);
            refetchTalimatDosyasi();
            toast.show("Talimat Dosyası başarıyla eklendi.", "success");
          }
        });
      } else {
        var talimatDosyasiUpdateDto: UpdateTalimatDosyasiDto = {
          ...formik.values,
        };
        TalimatDosyasiService.Update(talimatDosyasiUpdateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setTalimatDosyasi(undefined);
            refetchTalimatDosyasi();
            toast.show("Talimat Dosyası başarıyla güncellendi.", "success");
          }
        });
      }
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.id === 0
          ? "Talimat Dosyası Ekle"
          : "Talimat Dosyası Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setTalimatDosyasi(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setTalimatDosyasi(undefined);
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={formik.submitForm}
          />
        </div>
      }
      className=""
      style={{ width: "75vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="borcluIds">Borçlular</label>
          <MultiSelect
            id="borcluIds"
            name="borcluIds"
            display="chip"
            filter
            value={formik.values.borcluIds}
            onChange={(e: MultiSelectChangeEvent) => {
              formik.setFieldValue("borcluIds", e.value);
            }}
            options={icraFoy.icraFoyKarsiYans.map((x) => x.karsiYan)}
            optionLabel="adSoyad"
            itemTemplate={(item: KarsiYanDto) => {
              return (
                <div className="flex align-items-center">
                  <span>
                    {item.tip === MuvekkilTipi.Gercek
                      ? item.adSoyad
                      : item.unvan}
                  </span>
                </div>
              );
            }}
            optionValue="id"
            placeholder="Borçlu Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "borcluIds"),
            })}
          />
          {FormikValueIsValid(formik, "borcluIds") && (
            <div className="p-error mt-3">{formik.errors.borcluIds}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="icraMudurlugu">İcra Müdürlüğü</label>
          <InputText
            id="icraMudurlugu"
            name="icraMudurlugu"
            value={formik.values.icraMudurlugu}
            onChange={(e) => {
              formik.setFieldValue("icraMudurlugu", e.target.value.toLocaleUpperCase("tr"));
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "icraMudurlugu"),
            })}
          />
          {FormikValueIsValid(formik, "icraMudurlugu") && (
            <div className="p-error mt-3">{formik.errors.icraMudurlugu}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="dosyaNo">Dosya Numarası</label>
          <InputMask
            id="dosyaNo"
            name="dosyaNo"
            keyfilter="int"
            mask="9999/9?99999"
            placeholder="2024/0"
            value={formik.values.dosyaNo}
            onChange={(e) => {
              formik.setFieldValue("dosyaNo", e.target.value);
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "dosyaNo"),
            })}
          />
          {FormikValueIsValid(formik, "dosyaNo") && (
            <div className="p-error mt-3">{formik.errors.dosyaNo}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="aciklama">Açıklama</label>
          <InputTextarea
            id="aciklama"
            name="aciklama"
            rows={3}
            value={formik.values.aciklama}
            onChange={(e) => {
              formik.setFieldValue("aciklama", e.target.value.toLocaleUpperCase("tr"));
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "aciklama"),
            })}
          />
          {FormikValueIsValid(formik, "aciklama") && (
            <div className="p-error mt-3">{formik.errors.aciklama}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertTalimatDosyasi;
