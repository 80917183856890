import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { NoContentDto } from "../base/base.dto";
import {
  UserDto,
  CreateMainUserDto,
  UpdateProfileDto,
  PasswordResetTokenDto,
  ResetPasswordDto,
  SendEmailChangeEmailDto,
  ConfirmEmailDto,
  ConfirmNewEmailDto,
  ChangePasswordDto,
  CreateSubUserDto,
  UpdateSubUserDto,
  ConfirmPhoneNumberDto,
  SendPhoneNumberConfirmSmsDto,
  VerifyUserAndSendPhoneNumberConfirmSmsDto,
  SendIcsEmailDto
} from "./account.dto";
import { AppLicenseDto } from "../admin/admin.dto";

const services = {
  Register(dto: CreateMainUserDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<UserDto>("Account/Register", dto, config);
  },
  CreateSubUser(dto: CreateSubUserDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<UserDto>("Account/CreateSubUser", dto, config);
  },
  UpdateSubUser(dto: UpdateSubUserDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<UserDto>("Account/UpdateSubUser", dto, config);
  },
  GetAllSubUser(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<UserDto[]>("Account/GetAllSubUser", config);
  },
  DeleteSubUser(id: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`Account/DeleteSubUser/${id}`, config);
  },
  GetUserInformation(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<UserDto>(`Account/GetUserInformation`, config);
  },
  UpdateProfile(dto: UpdateProfileDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<UserDto>("Account/UpdateProfile", dto, config);
  },
  DeleteAccount(config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>("Account/DeleteAccount", config);
  },
  SendPasswordResetEmail(dto: PasswordResetTokenDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/SendPasswordResetEmail", dto, config);
  },
  ResetPassword(dto: ResetPasswordDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/ResetPassword", dto, config);
  },
  SendEmailChangeEmail(dto: SendEmailChangeEmailDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/SendEmailChangeEmail", dto, config);
  },
  ConfirmEmail(dto: ConfirmEmailDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/ConfirmEmail", dto, config);
  },
  ConfirmNewEmail(dto: ConfirmNewEmailDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/ConfirmNewEmail", dto, config);
  },
  ChangePassword(dto: ChangePasswordDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/ChangePassword", dto, config);
  },
  ConfirmPhoneNumber(dto: ConfirmPhoneNumberDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/ConfirmPhoneNumber", dto, config);
  },
  SendPhoneNumberConfirmSms(dto: SendPhoneNumberConfirmSmsDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/SendPhoneNumberConfirmSms", dto, config);
  },
  VerifyUserAndSendPhoneNumberConfirmSms(
    dto: VerifyUserAndSendPhoneNumberConfirmSmsDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<UserDto>("Account/VerifyUserAndSendPhoneNumberConfirmSms", dto, config);
  },
  SendIcsEmail(dto: SendIcsEmailDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>("Account/SendIcsEmail", dto, config);
  },
  GetAllUserEmail(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<string[]>("Account/GetAllUserEmail", config);
  },
  ConnectedUyap(lawyerName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<string[]>(`Account/ConnectedUyap/?lawyerName=${lawyerName}`, config);
  },
  CreateUyapConnection(lawyerName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<string[]>(`Account/CreateUyapConnection/?lawyerName=${lawyerName}`, config);
  },
  GetLicenses(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<AppLicenseDto[]>(`Account/GetLicenses`, config);
  }
};

export default services;
