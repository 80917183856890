import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguSgkHacizModel } from "../TopluSorgu.dto";
import { commonFilterContains, CreateHeader, Filter } from "../../filter/base-filter";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ExportFile } from "../../../utils/export-file";

const SgkHacizDataTable = (props: { tableData: TopluSorguSgkHacizModel[] }) => {
  const sgkHacizVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguSgkHacizModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      kisiTipString: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Kurum"
    ];

    const filteredData = sgkHacizVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      kisiTipString: data.kisiTipString
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluSorguSgkHaciz");
  };

  return (
    <div>
      <DataTable
        value={sgkHacizVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="key"
        showGridlines
        emptyMessage="Sgk Haciz Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Sgk Haciz Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "kisiTipString"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filterField="ortakDegerler.dosyaNo"
          filter
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filterField="ortakDegerler.alacakliAdi"
          filter
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filterField="ortakDegerler.borcluAdi"
          filter
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filterField="ortakDegerler.borcluTCVKNo"
          filter
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filterField="ortakDegerler.dosyaDurumu"
          filter
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Kurum"
          field="kisiTipString"
          sortable
          filter
          filterField="kisiTipString"
          filterPlaceholder="Kuruma göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kuruma göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default SgkHacizDataTable;
