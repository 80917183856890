import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import TalepBilgisiService from "../../../../services/talep-bilgisi/talep-bilgisi.services";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../../../utils/DropdownValues";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useToast } from "../../../../utils/toast";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import {
  CreateTalepBilgisiDto,
  TalepBilgisiDto,
  TalepDurumu,
  UpdateTalepBilgisiDto,
} from "../../../../services/talep-bilgisi/talep-bilgisi.dto";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import { KarsiYanDto } from "../../../../services/karsi-yan/karsi-yan.dto";
import { MuvekkilTipi } from "../../../../services/muvekkil/muvekkil.dto";
import { useContext } from "react";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const now: Date = new Date();

const UpsertTalepBilgisi = (props: any) => {
  const talepBilgisi: TalepBilgisiDto = props.talepBilgisi;
  const setTalepBilgisi = props.setTalepBilgisi;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchTalepBilgisi = props.refetchTalepBilgisi;
  const icraFoy: IcraFoyDto = props.icraFoy;
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  let updateTalepBilgisiDto: UpdateTalepBilgisiDto;

  if (talepBilgisi === null || talepBilgisi === undefined) {
    updateTalepBilgisiDto = {
      id: 0,
      yazimTarihi: now,
      talepTuru: "",
      borcluIds: [],
      talepDurumu: TalepDurumu.RedUyarili,
      aciklama: "",
    };
  } else {
    updateTalepBilgisiDto = {
      ...talepBilgisi,
      borcluIds: talepBilgisi.borclular.map((x) => x.id),
      yazimTarihi: new Date(talepBilgisi.yazimTarihi),
    };
  }

  const formik = useFormik<UpdateTalepBilgisiDto>({
    initialValues: updateTalepBilgisiDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateTalepBilgisiDto> = {};
      if (!data.yazimTarihi) {
        errors.yazimTarihi = "Yazım Tarihi boş bırakılamaz";
      }

      if (!data.talepTuru) {
        errors.talepTuru = "Talep Türü alanı boş bırakılamaz";
      }

      if (!data.borcluIds || data.borcluIds.length === 0) {
        errors.borcluIds = "Borçlu alanı boş bırakılamaz";
      }

      if (!data.talepDurumu) {
        errors.talepDurumu = "Talep Durumu alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      //   var talepBilgisiUpdateDto: UpdateTalepBilgisiDto = {
      //     ...formik.values,
      //   };
      setBlocked(true)
      if (formik.values.id === 0) {
        var talepBilgisiCreateDto: CreateTalepBilgisiDto = {
          ...formik.values,
          icraFoyId: icraFoy.id,
        };
        TalepBilgisiService.Create(talepBilgisiCreateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setTalepBilgisi(undefined);
            refetchTalepBilgisi();
            toast.show("Talep Bilgisi başarıyla eklendi.", "success");
          }
        });
      } else {
        var talepBilgisiUpdateDto: UpdateTalepBilgisiDto = {
          ...formik.values,
        };
        TalepBilgisiService.Update(talepBilgisiUpdateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setTalepBilgisi(undefined);
            refetchTalepBilgisi();
            toast.show("Talep Bilgisi başarıyla güncellendi.", "success");
          }
        });
      }
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.id === 0 ? "Talep Bilgisi Ekle" : "Talep Bilgisi Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setTalepBilgisi(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setTalepBilgisi(undefined);
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={formik.submitForm}
          />
        </div>
      }
      className=""
      style={{ width: "75vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="yazimTarihi">
            Yazım Tarihi
          </label>
          <Calendar
            value={formik.values.yazimTarihi}
            onChange={(e) => formik.setFieldValue("yazimTarihi", e.value)}
            dateFormat="dd/mm/yy"
            showIcon
            locale="tr"
            mask="99/99/9999"
            maxDate={now}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "yazimTarihi"),
            })}
          />
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="talepTuru">
            Talep Türü
          </label>
          <Dropdown
            id="talepTuru"
            name="talepTuru"
            value={formik.values.talepTuru}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("talepTuru", e.value);
            }}
            options={DropdownDatas.GetTalepTuru()}
            optionLabel="label"
            optionValue="value"
            placeholder="Talep Türü Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "talepTuru"),
            })}
          />
          {FormikValueIsValid(formik, "talepTuru") && (
            <div className="p-error mt-3">{formik.errors.talepTuru}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="borcluIds">
            Borçlular
          </label>
          <MultiSelect
            id="borcluIds"
            name="borcluIds"
            display="chip"
            filter
            value={formik.values.borcluIds}
            onChange={(e: MultiSelectChangeEvent) => {
              formik.setFieldValue("borcluIds", e.value);
            }}
            options={icraFoy.icraFoyKarsiYans
              .map((x) => x.karsiYan)
              .filter(
                (value, index, array) =>
                  array.findIndex((t) => t.id === value.id) === index
              )}
            optionLabel="adSoyad"
            itemTemplate={(item: KarsiYanDto) => {
              return (
                <div className="flex align-items-center">
                  <span>
                    {item.tip === MuvekkilTipi.Gercek
                      ? item.adSoyad
                      : item.unvan}
                  </span>
                </div>
              );
            }}
            optionValue="id"
            placeholder="Borçlu Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "borcluIds"),
            })}
          />
          {FormikValueIsValid(formik, "borcluIds") && (
            <div className="p-error mt-3">{formik.errors.borcluIds}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="talepDurumu">
            Talep Durumu
          </label>
          <Dropdown
            id="talepDurumu"
            name="talepDurumu"
            value={formik.values.talepDurumu}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("talepDurumu", e.value);
            }}
            options={DropdownDatas.GetTalepDurumu()}
            optionLabel="label"
            optionValue="value"
            placeholder="Talep Durumu Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "talepDurumu"),
            })}
          />
          {FormikValueIsValid(formik, "talepDurumu") && (
            <div className="p-error mt-3">{formik.errors.talepDurumu}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="aciklama">
            Açıklama
          </label>
          <InputTextarea
            id="aciklama"
            name="aciklama"
            rows={3}
            value={formik.values.aciklama}
            onChange={(e) => {
              formik.setFieldValue(
                "aciklama",
                e.target.value.toLocaleUpperCase("tr")
              );
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "aciklama"),
            })}
          />
          {FormikValueIsValid(formik, "aciklama") && (
            <div className="p-error mt-3">{formik.errors.aciklama}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertTalepBilgisi;
