import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  KampanyaMektubuDto,
  CreateKampanyaMektubuDto,
  UpdateKampanyaMektubuDto,
} from "./kampanya-mektubu.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<KampanyaMektubuDto>(`KampanyaMektubu/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<KampanyaMektubuDto[]>(`KampanyaMektubu`, config);
  },
  GetAllByIcraFoyId(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<KampanyaMektubuDto[]>(
      `KampanyaMektubu/icrafoy/${id}`,
      config
    );
  },
  Create(
    dto: CreateKampanyaMektubuDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<KampanyaMektubuDto>("KampanyaMektubu", dto, config);
  },
  Update(
    dto: UpdateKampanyaMektubuDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<KampanyaMektubuDto>("KampanyaMektubu", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<KampanyaMektubuDto>(`KampanyaMektubu/${id}`, config);
  },
};

export default services;
