import React, { useContext, useEffect } from "react";
import { DosyaDTO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { useQuery } from "@tanstack/react-query";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import SorguBilgileri from "./SorguBilgileri";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const PostaCeki = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: PostaCekiResponse,
    refetch: refetchPostaCeki,
    isLoading: postaCekiResponseLoading
  } = useQuery({
    queryKey: ["PostaCeki", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetPostaCekiBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(postaCekiResponseLoading)
  }, [postaCekiResponseLoading])

  return (
    <div>
      {PostaCekiResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {PostaCekiResponse.error}</div>}

      {PostaCekiResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={PostaCekiResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={PostaCekiResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={PostaCekiResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={PostaCekiResponse?.result?.sorgulayanBilgileri}
          />
          {PostaCekiResponse?.result?.sorguSonucu === false ? (
            <div className="card p-2 lg:p-5 mt-3 mb-3">Posta çeki kaydı yok.</div>
          ) : (
            <div className="card p-2 lg:p-5 mt-3">Posta çeki kaydı var.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default PostaCeki;
