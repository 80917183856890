import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import AlacakKalemiService from "../../../../services/alacak-kalemi/alacak-kalemi.services";
import { AlacakKalemiDto } from "../../../../services/alacak-kalemi/alacak-kalemi.dto";
import { useQuery } from "@tanstack/react-query";
import UpsertAlacakKalemi from "./UpsertAlacakKalemi";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import { Calendar } from "primereact/calendar";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const AlacakKalemi = (props: any) => {
  const [alacakKalemi, setAlacakKalemi] = useState<AlacakKalemiDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<AlacakKalemiDto[]>>(null);
  const [visibleUpsertAlacakKalemiDialog, setVisibleUpsertAlacakKalemiDialog] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)
  const icraFoy: IcraFoyDto = props.icraFoy;

  const {
    data: alacakKalemiResponse,
    refetch: refetchAlacakKalemi,
    isLoading: alacakKalemiResponseIsLoading
  } = useQuery({
    queryKey: ["AlacakKalemi"],
    refetchOnMount: true,
    queryFn: () => AlacakKalemiService.GetAllByIcraFoy(icraFoy.id)
  });

  useEffect(() => {
    setBlocked(alacakKalemiResponseIsLoading)
  }, [alacakKalemiResponseIsLoading])


  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Alacak Kalemi"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertAlacakKalemiDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: AlacakKalemiDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setAlacakKalemi(rowData);
            setVisibleUpsertAlacakKalemiDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            deleteMuvekkilConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      alacakTarihi: commonFilterDateIs,
      alacakMiktari: commonFilterContains,
      alacakTuru: commonFilterContains,
      faizOrani: commonFilterContains,
      aciklama: commonFilterDateIs
    });
    setGlobalFilterValue("");
  };

  const deleteTalepBilgisi = (rowData: AlacakKalemiDto) => {
    setBlocked(true)
    AlacakKalemiService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Alacak Kalemi başarıyla silindi.", "success");
        refetchAlacakKalemi();
      }
    });
  };

  const deleteMuvekkilConfirm = (rowData: AlacakKalemiDto) => {
    confirmDialog({
      message: "Alacak Kalemini silmek istediğinize emin misiniz?",
      header: "Alacak Kalemi Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteTalepBilgisi(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  // export excel
  const exportExcel = () => {
    const data = alacakKalemiResponse?.result?.data || [];

    const headers = ["Alacak Tarihi", "Alacak Miktari", "Alacak Türü", "Faiz Orani", "Açıklama"];

    const filteredData = data.map((data) => ({
      alacakTarihi: new Date(data.alacakTarihi).toLocaleDateString(),
      alacakMiktari: data.alacakMiktari,
      alacakTuru: data.alacakTuru,
      faizOrani: data.faizOrani,
      aciklama: data.aciklama
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "AlacakKalemi");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={alacakKalemiResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} alacak kalemi"
          filters={filters}
          // filterDisplay="row"
          loading={alacakKalemiResponseIsLoading}
          globalFilterFields={["alacakTarihi", "alacakMiktari", "alacakTuru", "faizOrani", "aciklama"]}
          emptyMessage="Alacak Kalemi bulunamadı."
          header={CreateHeader("Alacak Kalemi", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
          <Column
            field="alacakTarihi"
            header="Alacak Tarihi"
            sortable
            body={(rowData: AlacakKalemiDto) => {
              return <span>{new Date(rowData.alacakTarihi).toLocaleDateString()}</span>;
            }}
            filterField="alacakTarihi"
            filter
            filterPlaceholder="Alacak Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Alacak Tarihine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="alacakMiktari"
            header="Alacak Miktari"
            sortable
            body={(rowData: AlacakKalemiDto) => {
              return (
                <span>
                  {rowData.alacakMiktari
                    ?.toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "TRY",
                      currencyDisplay: "code"
                    })
                    .replace("TRY", "")}
                </span>
              );
            }}
            filterField="alacakMiktari"
            filter
            filterPlaceholder="Alacak Miktarına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Alacak Miktarına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="alacakTuru"
            header="Alacak Türü"
            sortable
            filterField="alacakTuru"
            filter
            filterPlaceholder="Alacak Türüne göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Alacak Türüne göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="faizOrani"
            header="Faiz Orani"
            sortable
            body={(rowData: AlacakKalemiDto) => {
              return (
                <span>
                  {rowData.faizOrani
                    ?.toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "TRY",
                      currencyDisplay: "code"
                    })
                    .replace("TRY", "")}
                </span>
              );
            }}
            filterField="faizOrani"
            filter
            filterPlaceholder="Faiz Oranına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Faiz Oranına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="aciklama"
            header="Açıklama"
            sortable
            filterField="aciklama"
            filter
            filterPlaceholder="Açıklamaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Açıklamaya göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertAlacakKalemi
        visible={visibleUpsertAlacakKalemiDialog}
        setVisible={setVisibleUpsertAlacakKalemiDialog}
        alacakKalemi={alacakKalemi}
        setAlacakKalemi={setAlacakKalemi}
        refetchAlacakKalemi={refetchAlacakKalemi}
        icraFoy={icraFoy}
      />
    </div>
  );
};

export default AlacakKalemi;
