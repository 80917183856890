import { AxiosRequestConfig } from "axios";
import http from "./base/uyap-http-base.service";
import {
  SafahatResponseDTO,
  DosyaHesabiResponseDTO,
  DosyaAyrintiliBilgileriResponseDTO,
  EvrakMimeTypeResponseDTO,
  TarafResponseDTO,
  TumEvraklarResponseDTO,
  SorguBakiyesiResponseDTO,
  IcraKesinlesmeBilgileriResponseDTO,
  TalepResponseDTO,
  TahsilatReddiyatResponseDTO,
  OdemeResponseDTO,
  OdemeTipleriResponseDTO,
  HarcListesiResponseDTO,
  MernisResponseDTO,
  SgkCalisaniResponseDTO,
  BagkurEmekliResponseDTO,
  BagkurCalisanResponseDTO,
  IsyeriResponseDTO,
  KamuEmekliResponseDTO,
  KamuCalisanResponseDTO,
  SgkEmekliResponseDTO,
  IskiResponseDTO,
  BankaHesabiResponseDTO,
  SgkHacizResponseDTO,
  GibResponseDTO,
  TakbisResponseDTO,
  TakbisHisseResponseDTO,
  IcraDosyasiResponseDTO,
  AracMahrumiyetResponseDTO,
  EgmResponseDTO,
  TakbisHisseMahrumiyetResponseDTO,
  DisIslerResponseDTO,
  GsmResponseDTO,
  PostaCekiResponseDTO,
  MersisResponseDTO,
  DosyaIslemTurleriResponseDto,
  DosyaBorcluListesiResponseDTO,
  RequestYargıBirimleriDTO,
  IlDTO,
  RequestDosyaDTO,
  RequestDosyaTarafDTO,
  DosyaDTO,
  CbsBirimDTO,
  DosyaResponseDTO,
  DurusmaRequestDto,
  DurusmaResponseDto,
  RequestFormikDosyaKelimeAramaDTO
} from "./uyap-sorgulari.dto";

const services = {
  YargıBirimleriSorgula(yargiTuru: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<RequestYargıBirimleriDTO[]>(`/yargiBirimleriSorgula_brd.ajx`, { yargiTuru: yargiTuru }, config);
  },

  GetIller(config?: AxiosRequestConfig<any> | undefined) {
    return http.post<IlDTO[]>(`/illeri_getirJSON.ajx`, config);
  },

  CbsBirimSorgula(ilKodu: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<CbsBirimDTO[]>(`/cbs_birim_sorgula.ajx`, { ilKodu: ilKodu }, config);
  },

  GetDosyaBilgileriByWord(
    RequestFormikDosyaKelimeAramaDTO: RequestFormikDosyaKelimeAramaDTO,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<DosyaResponseDTO>(
      `/search_phrase.ajx`,
      { RequestFormikDosyaKelimeAramaDTO: RequestFormikDosyaKelimeAramaDTO },
      config
    );
  },

  GetDosyalarDetayli(RequestDosyaTarafDTO: RequestDosyaTarafDTO, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DosyaResponseDTO>(
      `/search_phrase_detayli.ajx`,
      { RequestDosyaTarafDTO: RequestDosyaTarafDTO },
      config
    );
  },

  GetDosyalarTarafDetayli(RequestDosyaTarafDTO: RequestDosyaTarafDTO, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DosyaResponseDTO>(
      `/search_phrase_taraf_detayli.ajx`,
      { RequestDosyaTarafDTO: RequestDosyaTarafDTO },
      config
    );
  },

  GetCbsDosyalarDetayli(RequestDosyaTarafDTO: RequestDosyaTarafDTO, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DosyaResponseDTO>(
      `/avukat_dosya_sorgula_cbs_brd.ajx`,
      { RequestDosyaTarafDTO: RequestDosyaTarafDTO },
      config
    );
  },

  GetDosyaIslemTurleri(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DosyaIslemTurleriResponseDto>(
      `/dosya_islem_turleri_sorgula_brd.ajx`,
      { dosyaId: dosyaId },
      config
    );
  },

  GetSorguBakiyesi(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<SorguBakiyesiResponseDTO>(`/ws_sorgu_bakiyesi.ajx`, { dosyaId: dosyaId }, config);
  },

  GetDosyaAyrintiliBilgileri(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DosyaAyrintiliBilgileriResponseDTO>(
      `/dosyaAyrintiBilgileri_brd.ajx`,
      { dosyaId: dosyaId },
      config
    );
  },

  GetDosyaHesabi(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DosyaHesabiResponseDTO[]>(`/dosya_hesap_bilgileri.ajx`, { dosyaId: dosyaId }, config);
  },

  GetTarafBilgileri(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<TarafResponseDTO[]>(`/dosya_taraf_bilgileri_brd.ajx`, { dosyaId: dosyaId }, config);
  },

  GetAllEvrak(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<TumEvraklarResponseDTO>(`/list_dosya_evraklar_mobil.ajx`, { dosyaId: dosyaId }, config);
  },

  GetEvrakMimeType(evrakId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<EvrakMimeTypeResponseDTO>(`/get_evrak_mimeType_brd.ajx`, { evrakId: evrakId }, config);
  },

  //Safahat
  GetSafahat(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<SafahatResponseDTO>(`/dosya_safahat_bilgileri_brd.ajx`, { dosyaId: dosyaId }, config);
  },

  //Ödeme Tekrar bak

  GetOdemeTipleri(config?: AxiosRequestConfig<any> | undefined) {
    return http.post<OdemeTipleriResponseDTO[]>(`/odeme_tipleri_sorgula.ajx`, config);
  },
  GetOdemeDosyaTarafBilgileri(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<OdemeResponseDTO[]>(`/getDosyaTarafBilgileri_brd.ajx`, { dosyaId: dosyaId }, config);
  },
  GetOdemeHarcMasrafListesi(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<HarcListesiResponseDTO>(`/harc_masraf_listesi_sorgula_brd.ajx`, { dosyaId: dosyaId }, config);
  },

  //tahsilat reddiyat
  GetDosyaTahsilatReddiyatBilgileri(
    dosyaId: string,
    dosyaTurKod: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TahsilatReddiyatResponseDTO>(
      `/dosya_tahsilat_reddiyat_bilgileri_brd.ajx`,
      { dosyaId: dosyaId, dosyaTurKod: dosyaTurKod },
      config
    );
  },

  //Bunu altında içerdeki sorgular
  GetBorcluBilgileriListesi(dosyaId: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DosyaBorcluListesiResponseDTO[]>(`/dosya_borclu_list.ajx`, { dosyaId: dosyaId }, config);
  },

  //borçulu tek aratınca
  GetIcraKesinlesmeBilgileri(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<IcraKesinlesmeBilgileriResponseDTO>(
      `/dosya_icra_kesinlesme_bilgileri_brd.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //borçulu tek telap gönder kısmı
  GetTalepListesi(dosyaId: string, islemTuru: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<TalepResponseDTO[]>(
      `/getPortalAvukatTalepList.ajx`,
      { dosyaId: dosyaId, islemTuru: islemTuru },
      config
    );
  },

  //MERNİS
  GetMernisBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<MernisResponseDTO>(
      `/borclu_bilgileri_goruntule_mernis.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //MERSİS
  GetMersisBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<MersisResponseDTO>(
      `/borclu_bilgileri_goruntule_mersis.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //İSKİ
  GetIskiBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<IskiResponseDTO>(
      `/borclu_bilgileri_goruntule_iski.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //GİB
  GetGibBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<GibResponseDTO>(
      `/borclu_bilgileri_goruntule_gib_adres.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //GSM
  GetGsmBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<GsmResponseDTO>(
      `/borclu_bilgileri_goruntule_gsm.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //DIŞ İŞLERİ
  GetDisIsleriBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DisIslerResponseDTO>(
      `/borclu_bilgileri_goruntule_dis_isleri.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //SGK SSK Çalışan
  GetSskCalisanBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<SgkCalisaniResponseDTO>(
      `/borclu_bilgileri_goruntule_sgk_sskCalisaniBilgileri.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //SGK SSK Emekli
  GetSskEmekliBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<SgkEmekliResponseDTO>(
      `/borclu_bilgileri_goruntule_sgk_sskEmekliBilgileri.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //SGK Kamu Çalışan
  GetKamuCalisanBilgiari(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<KamuCalisanResponseDTO>(
      `/borclu_bilgileri_goruntule_sgk_kamuCalisaniBilgileri.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //SGK Kamu Emekli
  GetKamuEmekliBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<KamuEmekliResponseDTO>(
      `/borclu_bilgileri_goruntule_sgk_kamuEmekliBilgileri.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //SGK Bağkur Çalışan
  GetBağkurCalisanBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<BagkurCalisanResponseDTO>(
      `/borclu_bilgileri_goruntule_sgk_bagkurCalisaniBilgileri.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //SGK Bağkur Emekli
  GetBagkurEmekliBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<BagkurEmekliResponseDTO>(
      `/borclu_bilgileri_goruntule_sgk_bagkurEmekliBilgileri.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //SGK İşyeri
  GetIsyeriBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<IsyeriResponseDTO>(
      `/borclu_bilgileri_goruntule_sgk_isYeriBilgileri.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //ARAÇ
  GetAracBilgiler(
    dosyaId: string,
    kisiKurumId: number,
    pageIndex: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<EgmResponseDTO>(
      `/borclu_bilgileri_goruntule_egm_brd.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId, pageIndex: pageIndex },
      config
    );
  },

  //ARAÇ MAHRUMİYET
  GetAracMahrumiyetBilgiler(
    dosyaId: string,
    kisiKurumId: number,
    plaka: string,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<AracMahrumiyetResponseDTO>(
      `/borclu_bilgileri_goruntule_arac_mahrumiyet_brd.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId, plaka: plaka },
      config
    );
  },

  //POSTA ÇEKİ
  GetPostaCekiBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<PostaCekiResponseDTO>(
      `/borclu_bilgileri_goruntule_posta_ceki.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //TAKBİS
  GetTakbisBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<TakbisResponseDTO>(
      `/borclu_bilgileri_goruntule_takbis.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },
  //TAKBİS HİSSE
  GetTakbisHisseBilgiler(
    dosyaId: string,
    kisiKurumId: number,
    tasinmazId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TakbisHisseResponseDTO>(
      `/borclu_bilgileri_goruntule_tasinmaz_hisse_brd.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId, tasinmazId: tasinmazId },
      config
    );
  },
  //TAKBİS TAKDİYAT (MAHRUMİYET)
  GetTakbisMahrumiyetBilgiler(
    dosyaId: string,
    kisiKurumId: number,
    tasinmazId: number,
    hisseId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TakbisHisseMahrumiyetResponseDTO>(
      `/borclu_bilgileri_goruntule_tasinmaz_takyidat_brd.ajx`,
      {
        dosyaId: dosyaId,
        kisiKurumId: kisiKurumId,
        hisseId: hisseId,
        tasinmazId: tasinmazId
      },
      config
    );
  },

  //SGK HACİZ
  GetSgkHacizBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<SgkHacizResponseDTO>(
      `/borclu_bilgileri_goruntule_sgk_haciz.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //İCRA DOSYASI
  GetIcraDosyasiBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<IcraDosyasiResponseDTO>(
      `/borclu_bilgileri_goruntule_icra_dosyasi.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  //BANKA HESABI
  GetBankaHesabiBilgiler(dosyaId: string, kisiKurumId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<BankaHesabiResponseDTO>(
      `/borclu_bilgileri_goruntule_banka.ajx`,
      { dosyaId: dosyaId, kisiKurumId: kisiKurumId },
      config
    );
  },

  AvukatDurusmaSorgula(requestDto: DurusmaRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<DurusmaResponseDto[]>(
      `/avukat_durusma_sorgula_brd.ajx`,
      {
        baslangicTarihi: requestDto.baslangicTarihi.toLocaleDateString("tr-TR"),
        bitisTarihi: requestDto.bitisTarihi.toLocaleDateString("tr-TR")
      },
      config
    );
  },

  GetEvrak({
    evrakId,
    dosyaId,
    config
  }: {
    evrakId: string;
    dosyaId: string;
    config?: AxiosRequestConfig<any> | undefined;
  }) {
    return http.get(`/download_document_brd.uyap`, {
      params: {
        evrakId,
        dosyaId
      },
      responseType: "blob",
      ...config
    });
  }
};

export default services;
