import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  UrunBilgisiDto,
  CreateUrunBilgisiDto,
  UpdateUrunBilgisiDto,
} from "./urun-bilgisi.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<UrunBilgisiDto>(`UrunBilgisi/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<UrunBilgisiDto[]>(`UrunBilgisi`, config);
  },
  GetAllByIcraFoyId(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<UrunBilgisiDto[]>(`UrunBilgisi/icrafoy/${id}`, config);
  },
  Create(
    dto: CreateUrunBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<UrunBilgisiDto>("UrunBilgisi", dto, config);
  },
  Update(
    dto: UpdateUrunBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<UrunBilgisiDto>("UrunBilgisi", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<UrunBilgisiDto>(`UrunBilgisi/${id}`, config);
  },
};

export default services;
