import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";
import { ExportFile } from "../../utils/export-file";

const TebligatTakip = () => {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      gonderenMerci: commonFilterContains,
      esasNo: commonFilterContains,
      tebligatIcerigi: commonFilterContains,
      tebligatBarkodNo: commonFilterContains,
      hazirlanmaTarihi: commonFilterDateIs,
      sonuc: commonFilterContains,
      aciklama: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    //dt.current?.exportCSV();
  };

  const exportExcel = () => {
//     const data = processedData || [];

//     const headers = [
//         "Gönderen Merci", 
//         "Esas No", 
//         "Tebligat İçeriği", 
//         "Tebligat Barkod No", 
//         "Hazırlanma Tarihi", 
//         "Sonuç", 
//         "Açıklama"
//       ];
    //   const filteredData = data.map(({ 
    //     gonderenMerci, 
    //     esasNo, 
    //     tebligatIcerigi, 
    //     tebligatBarkodNo, 
    //     hazirlanmaTarihi, 
    //     sonuc, 
    //     aciklama 
    //   }) => ({
    //     gonderenMerci,
    //     esasNo,
    //     tebligatIcerigi,
    //     tebligatBarkodNo,
    //     hazirlanmaTarihi: moment(hazirlanmaTarihi).format("DD.MM.YYYY HH:mm"), // Tarih formatını korumak için
    //     sonuc,
    //     aciklama
    //   }));

//     const worksheetData = [headers, ...filteredData.map(Object.values)];

//     ExportFile.SaveAsExcelFile(worksheetData, "Safahatlar");
   };

  return (
    <div>
      <DataTable
        //value={topluSorguListeleri}
        //ref={dt}
        showGridlines
        stripedRows
        emptyMessage="Hiç Tebligat Bulunamadı"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        filters={filters}
        globalFilterFields={[
          "gonderenMerci",
          "esasNo",
          "tebligatIcerigi",
          "tebligatBarkodNo",
          "hazirlanmaTarihi",
          "sonuc",
          "aciklama"
        ]}
        header={CreateHeader("Toplu Sorgu Listeleri", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter,
            exportCSV,
            exportExcel)}
        columnResizeMode="expand"
      >
        {/* <Column body={actionBodyTemplate} exportable={false} style={{ width: "8rem" }}></Column> */}
        <Column
          field="gonderenMerci"
          header="Gönderen Merci"
          sortable
          filterField="gonderenMerci"
          filter
          filterPlaceholder="Gönderen merciye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Gönderen merciye göre filtreleyin</div>}
        />
        <Column
          field="esasNo"
          header="Esas No"
          sortable
          filterField="esasNo"
          filter
          filterPlaceholder="Esas numarasına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Esas numarasına göre filtreleyin</div>}
        />
        <Column
          field="tebligatIcerigi"
          header="Tebligat İçeriği"
          sortable
          filterField="tebligatIcerigi"
          filter
          filterPlaceholder="Tebligat içeriğine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tebligat içeriğine göre filtreleyin</div>}
        />
        <Column
          field="tebligatBarkodNo"
          header="Tebligat Barkod No"
          sortable
          filterField="tebligatBarkodNo"
          filter
          filterPlaceholder="Barkod numarasına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Barkod numarasına göre filtreleyin</div>}
        />
        <Column
          field="hazirlanmaTarihi"
          header="Hazırlanma Tarihi"
          sortable
          body={(rowData) => moment(rowData.hazirlanmaTarihi).format("DD.MM.YYYY HH:mm")}
          filterField="hazirlanmaTarihi"
          filter
          filterPlaceholder="Hazırlanma tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterMatchMode={FilterMatchMode.DATE_IS}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Hazırlanma tarihine göre filtreleyin</div>}
        />
        <Column
          field="sonuc"
          header="Sonuç"
          sortable
          filterField="sonuc"
          filter
          filterPlaceholder="Sonuca göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Sonuca göre filtreleyin</div>}
        />
        <Column
          field="aciklama"
          header="Açıklama"
          sortable
          filterField="aciklama"
          filter
          filterPlaceholder="Açıklamaya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Açıklamaya göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default TebligatTakip;
