import React from "react";
import { TreeNode } from "primereact/treenode";
import { EvrakDTO, EkEvrakDTO, TumEvraklarResponseDTO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import moment from "moment";

interface ExtendedTreeNode extends TreeNode {
  expandableKey?: string
}

export interface IEvrakDTO extends EvrakDTO {
  location?: string;
  file?: any;
  anaEvrakId?: string;
  sira?: number;
}

export interface IEkEvrakDTO extends EkEvrakDTO {
  location?: string;
  file?: any;
  dosyaId: string;
  onaylandigiTarih: string;
  sistemeGonderildigiTarih: string;
  tur: string;
}

export interface EvrakTreeNode extends TreeNode {
  data: IEvrakDTO | IEkEvrakDTO;
}

export interface IFilterOptions {
  gonderenYerKisi: string[];
  // onaylandigiTarih: string[];
  // sistemeGonderildigiTarih: string[];
  tip: string[];
}

interface EvrakPathMakerProps {
  node: EvrakTreeNode;
  dosyaTur: string;
  birimAdi: string;
  dosyaNo: string;
}

export const useEvrakListeleyici = () => {
  const flatArray = (array: ExtendedTreeNode[]) => {
    const result: ExtendedTreeNode[] = [];

    function flatten(item: ExtendedTreeNode) {
      result.push(item);

      if (item.children && item.children.length > 0) {
        item.children.forEach(flatten);
      }
    }

    array.forEach(flatten);
    return result;
  };

  const sorter = (evraklar: any, mainFolderLabel: string, filters: IFilterOptions) => {
    const createdNodes: TreeNode[] = [];

    if (evraklar?.result) {
      createdNodes.push({
        key: mainFolderLabel,
        label: mainFolderLabel,
        data: evraklar?.result,
        icon: "pi pi-folder-open",
        children: []
      });

      if (evraklar?.result?.son20Evrak && createdNodes[0]) {
        createdNodes[0].children!.push({
          label: "Son 20 Evrak",
          key: "son20Evrak",
          data: evraklar?.result?.son20Evrak,
          icon: "pi pi-folder-open",
          selectable: true,
          children: evraklar?.result?.son20Evrak
            ?.filter((item: any) => {
              const filterResult = Object.keys(filters).filter((filter: string) => {
                if (filters[filter as keyof IFilterOptions].length > 0) {
                  return filters[filter as keyof IFilterOptions].includes(
                    item[filter as keyof IFilterOptions] as string
                  );
                } else {
                  return true;
                }
              });
              return filterResult.length == Object.keys(filters).length;
            })
            ?.map((item: any, index : number) => ({
              label: `${item?.tur} ${item?.sistemeGonderildigiTarih}`,
              key: `${item?.evrakId}-son20Evrak-${index}`,
              data: item,
              icon: item?.ekEvrakListesi?.length > 0 ? "pi pi-folder-open" : item?.location ? "pi pi-file-import text-green-500 font-semibold" : "pi pi-fw pi-file",
              selectable: true,
              children: item?.ekEvrakListesi?.map((subItem: any, index: number) => ({
                label: `Ek ${subItem.sira}`,
                key: `${subItem?.evrakId}.${subItem?.anaEvrakId}`,
                expandableKey: `${item?.evrakId}-son20Evrak-${index}`,
                data: { ...subItem, dosyaId: item?.dosyaId, onaylandigiTarih: item?.onaylandigiTarih, sistemeGonderildigiTarih: item?.sistemeGonderildigiTarih, tur: item?.tur },
                icon: subItem?.ekEvrakListesi ? "pi pi-folder-open" : "pi pi-fw pi-file",
                children: subItem!.ekEvrakListesi?.map((subItemSub: any, index: number) => ({
                  label: `${subItemSub?.file?.name}`,
                  key: `${subItemSub?.anaEvrakId}-${subItemSub?.file?.name}`,
                  icon: "pi pi-fw pi-file",
                  data: subItemSub
                }))
              }))
            }))
        });
      } else {
        console.error("Son 20 Evrak bulunamadi");
      }

      if (evraklar?.result?.tumEvraklar && createdNodes[0]) {
        createdNodes[0].children!.push({
          label: "Tüm Evraklar",
          key: "tumEvraklar",
          data: evraklar?.result?.tumEvraklar,
          icon: "pi pi-folder-open",
          selectable: true,
          children: Object.keys(evraklar?.result?.tumEvraklar).map((item: any) => ({
            label: item,
            key: item,
            data: evraklar?.result?.tumEvraklar[item],
            icon: "pi pi-folder-open",
            selectable: true,
            children: evraklar?.result?.tumEvraklar[item]
              ?.filter((subItem: any) => {
                const filterResult = Object.keys(filters).filter((filter: string) => {
                  if (filters[filter as keyof IFilterOptions].length > 0) {
                    return filters[filter as keyof IFilterOptions].includes(
                      subItem[filter as keyof IFilterOptions] as string
                    );
                  } else {
                    return true;
                  }
                });

                return filterResult.length == Object.keys(filters).length;
              })
              .map((subItem: any, subItemIndex : number) => ({
                label: `${subItem?.tur} ${subItem?.sistemeGonderildigiTarih}`,
                key: `${subItem?.evrakId}-${item}-${subItemIndex}`,
                data: subItem,
                icon: subItem?.ekEvrakListesi?.length > 0 ? "pi pi-folder-open" : subItem?.location ? "pi pi-file-import text-green-500 font-semibold" : "pi pi-fw pi-file",
                selectable: true,
                expandableKey: item,
                children: subItem?.ekEvrakListesi?.map((subItemSub: any, index: number) => ({
                  label: `Ek ${subItemSub.sira}`,
                  key: `${subItemSub?.evrakId}.${subItemSub?.anaEvrakId}-${item}-${subItemIndex}-${index}`,
                  expandableKey: `${subItem?.evrakId}-${item}-${subItemIndex}`,
                  data: {
                    ...subItemSub,
                    dosyaId: subItem?.dosyaId,
                    onaylandigiTarih: subItem?.onaylandigiTarih,
                    sistemeGonderildigiTarih: subItem?.sistemeGonderildigiTarih,
                    tur: subItem?.tur
                  },
                  icon: "pi pi-fw pi-file",
                  selectable: true,
                  children: subItemSub!.ekEvrakListesi?.map((subItemSubSub: any, index: number) => ({
                    label: `${subItemSubSub?.file?.name}`,
                    key: `${subItemSubSub?.anaEvrakId}-${subItemSubSub?.file?.name}-${item}`,
                    icon: "pi pi-fw pi-file",
                    data: subItemSubSub
                  }))
                }))
              }))
          }))
        });
      } else {
        console.error("Son 20 Evrak bulunamadi");
      }
    } else {
      console.error("evraklar bulunamadi");
    }
    return createdNodes;
  };

  return {
    flatArray,
    sorter
  };
};
