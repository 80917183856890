import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import SupportService from "../../services/support/support.services";
import { useToast } from "../../utils/toast";
import { FileUpload, FileUploadRemoveEvent, FileUploadSelectEvent } from "primereact/fileupload";
import { FormikErrors, useFormik } from "formik";
import { CreateSupportDto, SupportDto, SupportStatus } from "../../services/support/support.dto";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import MessageThread from "./components/MessageThread";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useAuth } from "../../utils/auth";
import AdminService from "../../services/admin/admin.services";
import { ConfirmPopup } from "primereact/confirmpopup";

const SupportDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const navigate = useNavigate();
  const auth = useAuth();

  const buttonClosePopup = useRef();
  const fileUploadRef = useRef<FileUpload>(null);
  const [closePopupVisible, setClosePopupVisible] = useState<boolean>(false);
  const {
    data: supportDetailResponse,
    refetch: refetchSupportDetail,
    isLoading: supportDetailIsLoading
  } = useQuery({
    queryKey: ["Support"],
    refetchOnMount: "always",
    queryFn: () => AdminService.GetSupport(Number(id)),
    staleTime: 0
  });
  const data = supportDetailResponse?.result?.data;

  const formik = useFormik<CreateSupportDto>({
    initialValues: {
      topic: "",
      message: "",
      files: []
    },
    enableReinitialize: true,
    validate: (validateData) => {
      let errors: FormikErrors<CreateSupportDto> = {};
      if (!data && !validateData.topic) {
        errors.topic = "Konu alanı boş bırakılamaz";
      }
      if (!validateData.message) {
        errors.message = "Mesaj alanı boş bırakılamaz";
      }
      return errors;
    },
    onSubmit: async () => {
      const formData = new FormData();
      formData.append("topic", formik.values.topic);
      formData.append("message", formik.values.message);
      formik.values.files.forEach((fileData) => formData.append("files", fileData));
      if (data) {
        formData.append("supportId", String(data.id));
        AdminService.ReplySupportMessage(formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((res) => {
          if (!res?.result?.error) {
            formik.resetForm();
            fileUploadRef.current?.clear();
            refetchSupportDetail();
            toast.show("Mesaj başarıyla gönderildi.", "success");
          }
        });
      } else {
        SupportService.Create(formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((res) => {
          if (!res?.result?.error) {
            formik.resetForm();
            toast.show("Mesaj başarıyla gönderildi.", "success");
            const id = res.result?.data.id;
            navigate(`/app/admin/support/${id}`);
          }
        });
      }
    }
  });
  const handleCloseSupport = () => {
    if (data?.id)
      AdminService.CloseSupport(data.id).then((res) => {
        if (!res?.result?.error) {
          formik.resetForm();
          refetchSupportDetail();
          toast.show("Kayıt Kapandı.", "success");
          setClosePopupVisible(false);
        }
      });
  };

  const onSelectFile = (event: FileUploadSelectEvent) => {
    formik.setFieldValue("files", event.files);
  };

  const onRemoveFile = (event: FileUploadRemoveEvent) => {
    const index = formik.values.files.indexOf(event.file);
    const newFiles = formik.values.files.splice(index, 1);
    formik.setFieldValue("files", newFiles);
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: false,
    className: "custom-choose-btn",
    label: "Seç"
  };

  const uploadOptions = {
    icon: "",
    iconOnly: true,
    className: "hidden",
    label: "Yükle"
  };
  return (
    <>
      <ConfirmPopup
        target={buttonClosePopup.current}
        visible={closePopupVisible}
        onHide={() => setClosePopupVisible(false)}
        message="Destek kaydını kapatmak istediğinizden emin misiniz?"
        icon="pi pi-exclamation-triangle"
        accept={handleCloseSupport}
        reject={() => setClosePopupVisible(false)}
      />
      <div className="p-4 font-bold border-round-lg msg__content">
        {data ? (
          <div>
            <div className="row">
              {data.user && (
                <div className="col-12">
                  <div className="">
                    <span className="text-semibold text-primary">Kullanıcı Ad Soyad: </span>
                    <span className="text-secondary">{data.user.firstName + " " + data.user.lastName}</span>
                  </div>
                  <div className="">
                    <span className="text-semibold text-primary">Kullanıcı E-posta: </span>
                    <span className="text-secondary">{data.user.email}</span>
                  </div>
                  <div>
                    <span className="text-semibold text-primary">Kullanıcı Telefon Numarası: </span>
                    <span className="text-secondary">{data.user.phoneNumber}</span>
                  </div>
                </div>
              )}
              <div className="col-12 mb-1 flex justify-content-between">
                <div className="flex align-items-center text-secondary">
                  <span className="text-primary text-bold mr-1">Konu: </span>
                  {data.topic}
                </div>
                <div>
                  <Button
                    ref={buttonClosePopup as any}
                    disabled={data.status == SupportStatus.Closed}
                    onClick={() => setClosePopupVisible(true)}
                    icon="pi pi-check"
                    label={data.status == SupportStatus.Closed ? "Destek Kaydı Kapalı" : "Kaydı Kapat"}
                  />
                </div>
              </div>
              <div className="col-12 card border-rounded-sm">
                <MessageThread data={data} isAdminPage={true} />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h4 className="mb-4">Destek Talebi Oluştur</h4>
          </div>
        )}
        <div className="row">
          {data ? (
            <></>
          ) : (
            <div className="col-6">
              <label className="text-primary mr-2" htmlFor="topic">
                Konu:
              </label>
              <InputText
                value={formik.values.topic}
                onChange={(e) => {
                  formik.setFieldValue("topic", e.target.value);
                }}
                className={classNames("w-full mt-2 p-inputtext-lg", {
                  "p-invalid": FormikValueIsValid(formik, "topic")
                })}
              />
              {FormikValueIsValid(formik, "topic") && <div className="p-error mt-3">{formik.errors.topic}</div>}
            </div>
          )}
          <Divider />
          {data?.status != SupportStatus.Closed && (
            <>
              <div className="col-12">
                <FileUpload
                  name="files[]"
                  ref={fileUploadRef}
                  mode="advanced"
                  multiple
                  accept="image/*"
                  maxFileSize={1000000}
                  onSelect={onSelectFile}
                  uploadOptions={uploadOptions}
                  chooseOptions={chooseOptions}
                  onRemove={onRemoveFile}
                  onClear={() => {
                    formik.setFieldValue("files", []);
                  }}
                  emptyTemplate={
                    <p className="m-0">
                      Dosya seçin veya buraya sürükleyin. <br />
                      <span className="text-sm">
                        Sadece resim formatları destekleniyor. (jpg, png, gif, webp, svg).
                      </span>
                    </p>
                  }
                />
              </div>
              <div className="col-12">
                <label className="text-primary" htmlFor="message">
                  Mesaj
                </label>
                <InputTextarea
                  id="message"
                  name="message"
                  rows={5}
                  value={formik.values.message}
                  onChange={(e) => {
                    formik.setFieldValue("message", e.target.value);
                  }}
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "message")
                  })}
                />
                {FormikValueIsValid(formik, "message") && <div className="p-error mt-3">{formik.errors.message}</div>}
              </div>
              <div className="col-12 flex justify-content-end">
                <Button label="Gönder" className="md:px-6 justify-content-center" onClick={formik.submitForm} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SupportDetailPage;
