import React, { useContext, useEffect, useState } from "react";
import { DosyaDTO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import SorguBilgileri from "./SorguBilgileri";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const Gib = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: GibResponse,
    refetch: refetchGib,
    isLoading: gibResponseLoading
  } = useQuery({
    queryKey: ["Gib", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetGibBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(gibResponseLoading)
  }, [gibResponseLoading])

  return (
    <div>
      {GibResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {GibResponse.error}</div>}
      {GibResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={GibResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={GibResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={GibResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={GibResponse?.result?.sorgulayanBilgileri}
          />

          {GibResponse?.result ? (
            <>
              <div className="card p-2 lg:p-5 mt-3 mb-3">Kişiye ait GİB kayıtlarına göre adres kaydı var.</div>
              <div className="card p-2 lg:p-5 mt-3">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="font-bold"> ADRES: </div>
                  <div>
                    {`
                        ${GibResponse?.result?.sorguSonucDVO?.mahalleSemt}
                        ${GibResponse?.result?.sorguSonucDVO?.caddeSokak} 
                        KAPI NO: ${GibResponse?.result?.sorguSonucDVO?.kapiNo}
                        DAİRE NO: ${GibResponse?.result?.sorguSonucDVO?.daireNo}
                         - ${GibResponse?.result?.sorguSonucDVO?.ilKodu}
                        ${GibResponse?.result?.sorguSonucDVO?.ilceAdi}
                        `}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="card p-2 lg:p-5 mt-2">Kişiye ait GİB kayıtlarına göre adres kaydı yok.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Gib;
