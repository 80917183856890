import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { useHub } from "./buro-hub";
import { useToast } from "./toast";
import uyapService from "../services/uyap-sorgulari/uyap-sorgulari.service";
import {
  DownloadEvrakRequestDto,
  ElecronEvrakResponseDto
} from "../pages/dosya-detaylari/components/evrak-component/evrak.models.dto";
import moment from "moment";
import { LayoutContext } from "../layout/context/layoutcontext";

interface EvrakData {
  evrakId: string;
  dosyaId: string;
  onaylandigiTarih: string;
  tur: string;
  anaEvrakId?: string;
  sira?: string;
}

interface EvrakContextType {
  startDownload: (
    evrakArray: EvrakData[],
    dosyaId: string,
    dosyaNo: string,
    birimAdi: string,
    dosyaTur: string
  ) => void;
  downloaded: number;
  total: number;
  stopDownload: () => void;
}

const EvrakContext = createContext<EvrakContextType | undefined>(undefined);

export const EvrakProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [downloaded, setDownloaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [evrakArray, setEvrakArray] = useState<{
    evraklar: EvrakData[];
    dosyaId: string;
    dosyaNo: string;
    birimAdi: string;
    dosyaTur: string;
  }>();
  const [downloading, setDownloading] = useState(false);
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)
  const toast = useToast();
  const abortControllerRef = useRef<AbortController | null>(null);

  const startDownload = (
    evrakArray: EvrakData[],
    dosyaId: string,
    dosyaNo: string,
    birimAdi: string,
    dosyaTur: string
  ) => {
    setTotal(evrakArray.length);
    setDownloaded(0);
    setDownloading(true);
    setEvrakArray({ evraklar: evrakArray, dosyaId, dosyaNo, birimAdi, dosyaTur });
    abortControllerRef.current = new AbortController();
  };

  const stopDownload = () => {
    setDownloading(false); 
    setTotal(0);
    setDownloaded(0);
    setEvrakArray(undefined)
    abortControllerRef.current?.abort(); 
  };

  useEffect(() => {
    if (!evrakArray?.evraklar || evrakArray?.evraklar.length === 0) return;

    const downloadFiles = async () => {
      let successfulDownloads = 0;
      const key = hub.generateRandomString();
      const hubResult = await hub.reserveUyapSession(key);

      if (!hubResult) {
        toast.show(
          "Uyap oturumu yetersiz. Lütfen daha sonra tekrar deneyiniz veya oturum sayısını arttırınız.",
          "error"
        );
        return;
      }

      try {
        for (const evrak of evrakArray?.evraklar) {
          if (!downloading) break;
          const responseMimeType = await uyapService.GetEvrakMimeType(evrak?.evrakId);
          await delay(500);

          const blobResult = await uyapService.GetEvrak({
            evrakId: evrak?.evrakId,
            dosyaId: evrak?.dosyaId,
            config: { signal: abortControllerRef.current?.signal }
          });

          if (blobResult) {
            await sendDownloadEvrakMessageToElectron({
              evrakId: evrak.evrakId,
              birimAdi: evrakArray?.birimAdi,
              dosyaNo: evrakArray?.dosyaNo,
              dosyaTur: evrakArray?.dosyaTur,
              file: await (blobResult.result as Blob).arrayBuffer(),
              fileExtension: availableMimeTypes[responseMimeType?.result?.mimeType as string],
              onaylandigiTarihNormal: evrak?.onaylandigiTarih.replaceAll("/", "-"),
              onaylandigiTarihYilOnce: moment(evrak?.onaylandigiTarih, "DD/MM/YYYY").format("YYYY.MM.DD"),
              tur: evrak?.tur,
              anaEvrakId: evrak?.anaEvrakId || undefined,
              ekEvrakName: evrak?.sira ? `Ek ${evrak?.sira}` : undefined
            });

            successfulDownloads += 1;
            setDownloaded((prev) => prev + 1);
          }

          await delay(1000);
        }
      } catch (error: any) {
        console.error(error)
        toast.show("İndirme işlemi iptal edildi.", "error");
        abortControllerRef.current = null
      } finally {
        await hub.releaseUyapSession(key);
        setDownloading(false); 
        setTotal(0);
        setDownloaded(0);
        setEvrakArray(undefined)
      }

      if (successfulDownloads === total) {
        toast.show("Toplu evrak indirme işlemi tamamlandı.", "success");
      }
    };

    downloadFiles();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, [evrakArray, downloading]);

  const sendDownloadEvrakMessageToElectron = async (downloadEvrakRequestDto: DownloadEvrakRequestDto) => {
    const response = await window.electron?.ipcRenderer?.invoke("download-evrak", downloadEvrakRequestDto);
    console.log(response);
    const res: ElecronEvrakResponseDto<string> = response;

    if (!res.isSuccessful) {
      toast.show(res.errorMessage, "error");
      return;
    }
  };

  return (
    <EvrakContext.Provider value={{ startDownload, downloaded, total, stopDownload }}>{children}</EvrakContext.Provider>
  );
};

export const useEvrak = (): EvrakContextType => {
  const context = useContext(EvrakContext);
  if (!context) {
    throw new Error("useEvrak must be used within an EvrakProvider");
  }
  return context;
};

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const availableMimeTypes: { [key: string]: string } = {
  "text/html": "html",
  "image/jpeg": "jpg",
  "image/png": "png",
  "application/pdf": "pdf",
  "text/udf": "udf",
  "application/jasperreport": "pdf",
  "image/tiff": "tiff",
  "application/stylereport": "pdf",
  "application/pkcs7-mime": "zip",
  "application/eyp": "zip"
};
