import moment from "moment";

const Separator = (props: { display: boolean; date: Date }) => {
    const display = props.display;

    let datetimeDate = new Date(props.date)
    datetimeDate.setHours(datetimeDate.getHours() - 8);
    let displayDate = moment(datetimeDate).format(process.env.REACT_APP_DATE_FORMAT);

    return (
        <>
            {display && (
                <div className="d-flex justify-content-center">
                    <div className="text-center msg-date-heading my-3 align-items-center">{displayDate}</div>
                </div>
            )}
        </>
    );
};
export default Separator