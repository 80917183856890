import React, { useEffect, useState } from "react";
import { ViewerBaseProps } from "./fileViewer.dto";
interface IMGViewerProps extends ViewerBaseProps{}

export const IMGViewer: React.FC<IMGViewerProps> = ({ file, parentWidth }) => {
  const { data } = file
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    const imageUrl = window.URL.createObjectURL(data);
    setUrl(imageUrl)
  }, [data]);
  return <div><img src={url} width={parentWidth} /></div>;
};
