import React, { useEffect, useState } from "react";
import { Document, pdfjs, Page } from "react-pdf";
import { ViewerBaseProps } from "./fileViewer.dto";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { InputNumber } from "primereact/inputnumber";
import { ProgressSpinner } from "primereact/progressspinner";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PDFViewerProps extends ViewerBaseProps {}

export const PDFViewer: React.FC<PDFViewerProps> = ({ file, parentWidth }) => {
  const { data } = file;
  const [url, setUrl] = useState<any>(null);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    try {
      initialization(data);
    } catch (error) {
      console.error("PDFViewer", error);
    }
  }, [data]);

  const initialization = async (data: Blob) => {
    const url = URL.createObjectURL(data);
    const pdf = await pdfjs.getDocument(url).promise;
    setTotalPage(pdf.numPages);
    setUrl(url);
  };

  return (
    <div>
      {url ? (
        <Document
          file={url}
          loading={
            <div className="flex justify-content-center align-items-center h-full">
              <ProgressSpinner />
            </div>
          }
        >
          {totalPage > 1 && (
            <div className="flex w-full justify-content-center gap-3 p-3">
              {/* <Button
                icon="pi pi-chevron-left"
                onClick={() => (currentPage > 1 ? setCurrentPage(currentPage - 1) : null)}
              /> */}
              <div className="">
                <InputNumber
                  inputId="horizontal-buttons"
                  value={currentPage}
                  suffix={`/${totalPage}`}
                  onValueChange={(e) => setCurrentPage(e.value as number)}
                  showButtons
                  buttonLayout="horizontal"
                  step={1}
                  // decrementButtonClassName="p-button-danger"
                  // incrementButtonClassName="p-button-success"
                  incrementButtonIcon="pi pi-chevron-right"
                  decrementButtonIcon="pi pi-chevron-left"
                  mode="decimal"
                  max={totalPage}
                  min={1}
                  inputStyle={{ width: 50 }}
                />
              </div>
              {/* <Button icon="pi pi-chevron-right" onClick={() => setCurrentPage(currentPage + 1)} /> */}
            </div>
          )}
          <Page pageNumber={currentPage} width={parentWidth} />
        </Document>
      ) : (
        <div className="flex justify-content-center align-items-center h-full">
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
};
