import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  BuroSettingsModel,
  ReactToElectronDto,
  ElectronHubResponseDto,
  HubStoreModel,
  SessionNumber,
  UpdateUserSharingSessionDto,
  UpdateSessionDto,
  ElectronFunction,
  SessionModel
} from "../services/hubs/hub.service.dto";
import { useAuth } from "./auth";
import { useToast } from "./toast";
import CryptoJS from "crypto-js";
import authService from "../services/auth/auth.service";
import accountService from "../services/account/account.service";

interface HubContextModel {
  sessions: BuroSettingsModel;
  stop: () => Promise<void>;
  getBuroSettings: () => HubStoreModel;
  joinGroup: () => Promise<void>;
  leaveGroup: () => Promise<void>;
  refreshBuroSettings: () => Promise<void>;
  updateBuroSettings: (updateBuroSettingsDto: UpdateUserSharingSessionDto) => Promise<void>;
  connectSession: (lawyerName: string, sessionPin: string) => void;
  refreshNewUyapSessionMessageToHub: () => Promise<void>;
  reserveUyapSession: (key: string) => Promise<boolean>;
  releaseUyapSession: (key: string) => Promise<void>;
  executeUyapService: <T>(uyapService: () => T) => Promise<T>;
  openUyapWindow: () => Promise<void>;
  getLawyerNameMessageToElectron: () => Promise<boolean>;
  updateMaxCookieAndCardPin: (cardPinNumber: string, amISharingUyapSession: boolean) => void;
  closeUyapSession: () => Promise<void>;
  generateRandomString: () => string;
}

const HubContext = createContext<HubContextModel>({} as HubContextModel);

export const useHub = () => {
  return useContext(HubContext);
};

type FunctionType<T> = () => T;

export const HubProvider = ({ children }: { children: React.ReactNode }) => {
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const [sessions, setSessions] = useState<BuroSettingsModel>({
    lawyerSessions: []
  });

  const hubStore = useRef<HubStoreModel>({
    amISharingUyapSession: false,
    cardPassword: "",
    maxCookieSize: 4,
    connectedSessionLawyerName: "",
    reservedUyapSession: undefined,
    reservedSessionLawyerName: undefined,
    keys: [],
    sessionPin: "",
    firstSessionProcessIsStarted: false
  });

  const hubUrl = process.env.REACT_APP_BASE_URL + "hub/uyapOturum";
  const connection = useRef<HubConnection>(
    new HubConnectionBuilder()
      .withUrl(hubUrl)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect([2000, 3000, 5000, 10000, 15000, 30000, 60000, 120000, 300000])
      .build()
  );
  const auth = useAuth();
  const toast = useToast();

  const start = async () => {
    try {
      if (connection.current) {
        await connection.current.start();
        //console.log("SignalR connection started");

        connection.current.on("CreateUyapSession", (sessionNumber: SessionNumber) => {
          // console.log(
          //   "Received CreateUyapSession. sessionNumber:",
          //   sessionNumber
          // );
          createNewUyapSessionMessageToElectron(sessionNumber);
        });

        connection.current.on("ReceiveUyapOturum", (buroSettings: string) => {
          const buroSettingsModel: BuroSettingsModel = JSON.parse(buroSettings);

          if (buroSettingsModel && buroSettingsModel.lawyerSessions) {
            setSessions(buroSettingsModel);
          }

          // console.log("Received ReceiveUyapOturum. buroSettings:", buroSettingsModel);

          if (hubStore.current.reservedUyapSession && hubStore.current.reservedSessionLawyerName) {
            const findLawyerSession = buroSettingsModel.lawyerSessions.find(
              (p) => p.lawyerName === hubStore.current.reservedSessionLawyerName
            );

            if (findLawyerSession) {
              const findSession = findLawyerSession.sessions.find(
                (x) => x.sessionNumber === hubStore.current.reservedUyapSession!.sessionNumber
              );

              if (findSession) {
                let decryptedCookie = "";

                if (findSession.cookie !== "") {
                  try {
                    const bytes = CryptoJS.AES.decrypt(findSession.cookie, hubStore.current.sessionPin);

                    if (!bytes) {
                      toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
                      return false;
                    }

                    decryptedCookie = bytes.toString(CryptoJS.enc.Utf8);

                    if (!decryptedCookie) {
                      toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
                      return false;
                    }
                  } catch (error) {
                    toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
                    return false;
                  }
                }

                window.electron?.ipcRenderer?.invoke("set-uyap-cookie", decryptedCookie);
                hubStore.current.reservedUyapSession!.cookie = decryptedCookie;
                // setHubStore({ ...hubStore });
              }
            }
          }

          if (hubStore.current.connectedSessionLawyerName) {
            const findLawyerSession = buroSettingsModel.lawyerSessions.find(
              (p) => p.lawyerName === hubStore.current.connectedSessionLawyerName
            );

            if (!findLawyerSession) {
              hubStore.current.connectedSessionLawyerName = "";
            }
          }

          // hubStore.sessions = buroSettingsModel;
          // setHubStore({
          //   ...hubStore,
          //   sessions: buroSettingsModel
          // });
        });

        if (auth.getUser().mainUserId) {
          await joinGroup();
          await refreshBuroSettings();
        }

        window.electron?.ipcRenderer?.invoke("set-uyap-cookie", "");
      }
    } catch (error) {
      console.error("Error starting SignalR connection:", error);
      setTimeout(() => start(), 3000);
    }
  };

  useEffect(() => {
    start();
  }, []);

  const createNewUyapSessionMessageToElectron = async (sessionNumber: SessionNumber) => {
    const reactToElectronDto: ReactToElectronDto = {
      function: ElectronFunction.CreateUyapSession,
      tc: auth.getUser().allowedLawyerTc,
      cardPassword: hubStore.current.cardPassword,
      sessionNumber
    };

    var response = await window.electron?.ipcRenderer?.invoke("run-signer-jar", reactToElectronDto);


    const responseObj: ElectronHubResponseDto = JSON.parse(response);


    if (!responseObj.isSuccessful) {
      if(typeof responseObj.errorMessage == 'string'){
        toast.show(responseObj.errorMessage, "error");
        return false;
      }else{
        toast.show("Uyap oturumu açılamadı.", "error");
        return false;
      }
    }
    
    const encryptedCookie = CryptoJS.AES.encrypt(responseObj.cookie, hubStore.current.sessionPin).toString();
    await updateUyapSession({
      cookie: encryptedCookie,
      sessionNumber: responseObj.sessionNumber,
      lawyerName: responseObj.lawyerName
    } as UpdateSessionDto);

    if (hubStore.current.firstSessionProcessIsStarted === true) {
      toast.show("Uyap Oturumu seçildi.", "success");
      hubStore.current.firstSessionProcessIsStarted = false;
    }
  };

  const getLawyerNameMessageToElectron = async (): Promise<boolean> => {
    toast.show("Uyap oturumu açılıyor...", "info");

    const reactToElectronDto: ReactToElectronDto = {
      function: ElectronFunction.GetLawyerName,
      tc: auth.getUser().allowedLawyerTc,
      cardPassword: hubStore.current.cardPassword,
      sessionNumber: SessionNumber.First
    };

    const response = await window.electron?.ipcRenderer?.invoke("run-signer-jar", reactToElectronDto);
    
    const responseObj: ElectronHubResponseDto = JSON.parse(response);

    if (!responseObj.isSuccessful) {
      if(typeof responseObj.errorMessage == 'string'){
        toast.show(responseObj.errorMessage, "error");
        return false;
      }else{
        toast.show("Uyap oturumu açılamadı.", "error");
        return false;
      }
    }

    updateBuroSettings({
      userName: auth.getUser().username,
      lawyerName: responseObj.lawyerName,
      maxCookieSize: getBuroSettings().maxCookieSize
    } as UpdateUserSharingSessionDto);

    hubStore.current.connectedSessionLawyerName = responseObj.lawyerName;
    await accountService.CreateUyapConnection(responseObj.lawyerName);
    hubStore.current.firstSessionProcessIsStarted = true;
    return true;
  };

  const getBuroSettings = (): HubStoreModel => {
    return hubStore.current;
  };

  const stop = async () => {
    try {
      await connection.current.stop();
      //console.log("SignalR connection stopped");
    } catch (error) {
      console.error("Error stopping SignalR connection:", error);
    }
  };

  const joinGroup = async () => {
    try {
      await connection.current.invoke("JoinGroup", auth.getUser().mainUserId, auth.getUser().username);
      //console.log("Joined group:", auth.getUser().mainUserId);
    } catch (error) {
      console.error("Error joining group:", error);
    }
  };

  const leaveGroup = async () => {
    try {
      await connection.current.invoke("LeaveGroup", auth.getUser().mainUserId);
      //console.log("Left group:", auth.getUser().mainUserId);
    } catch (error) {
      console.error("Error leaving group:", error);
    }
  };

  // refresh own buro settings from hub
  const refreshBuroSettings = async () => {
    try {
      await connection.current.invoke("SendBuroSettingsToUser", auth.getUser().mainUserId);
    } catch (error) {
      console.error("Error invoking method SendBuroSettingsToUser. error: ", error);
    }
  };

  const updateBuroSettings = async (updateUserSharingSessionDto: UpdateUserSharingSessionDto) => {
    try {
      await connection.current.invoke("UpdateBuroSettings", auth.getUser().mainUserId, updateUserSharingSessionDto);
      //console.log("UpdateBuroSettings method invoked.");
    } catch (error) {
      console.error("Error invoking UpdateBuroSettings method:", error);
    }
  };

  const updateMaxCookieAndCardPin = (cardPinNumber: string, amISharingUyapSession: boolean) => {
    // hubStore.current.maxCookieSize = maxCookieSize;
    hubStore.current.cardPassword = cardPinNumber;
    hubStore.current.amISharingUyapSession = amISharingUyapSession;
  };

  const connectSession = (lawyerName: string, sessionPin: string) => {
    const lawyerSessions = sessions.lawyerSessions.find((x) => x.lawyerName === lawyerName);
    if (lawyerSessions) {
      hubStore.current.connectedSessionLawyerName = lawyerSessions.lawyerName;
      hubStore.current.sessionPin = sessionPin;
      // setHubStore({ ...hubStore });
      toast.show("Uyap oturumu seçildi.", "success");
    }
  };

  window.electron?.ipcRenderer?.removeAllListeners("release-uyap-cookie");
  window.electron?.ipcRenderer?.on("release-uyap-cookie", async (event, sessionNumber) => {
    try {
      if (!hubStore.current.connectedSessionLawyerName) {
        return;
      }

      await connection.current.invoke(
        "ReleaseUyapSession",
        auth.getUser().mainUserId,
        hubStore.current.connectedSessionLawyerName,
        sessionNumber
      );
      //console.log("release-uyap-cookie method invoked.");
    } catch (error) {
      console.error("Error invoking release-uyap-cookie method:", error);
    }
  });

  const updateUyapSession = async (updateSessionDto: UpdateSessionDto) => {
    try {
      await connection.current.invoke("UpdateUyapSession", auth.getUser().mainUserId, updateSessionDto);
    } catch (error) {
      console.error("Error invoking UpdateUyapSession method:", error);
    }
  };

  window.electron?.ipcRenderer?.removeAllListeners("close-connection");
  window.electron?.ipcRenderer?.on("close-connection", async (event, arg) => {
    try {
      if (auth.getUser().refreshToken) {
        await authService.RevokeRefreshToken({ token: auth.getUser().refreshToken });
      }
      await stop();
    } catch (error) {
      console.error("Error invoking release-uyap-cookie method:", error);
    }
  });

  const refreshNewUyapSessionMessageToHub = async () => {
    // oturum düştüğünde bunu çağırıp huba bildir ki hub yenilesin.
    try {
      await connection.current.invoke(
        "RefreshUyapSession",
        auth.getUser().mainUserId,
        hubStore.current.reservedSessionLawyerName,
        hubStore.current.reservedUyapSession!.sessionNumber
      );
      //console.log(
      // "RefreshUyapSession method invoked. reservedSession",
      // reservedSession.current
      // );
    } catch (error) {
      console.error("Error invoking RefreshUyapSession method:", error);
    }
  };

  const reserveUyapSession = async (key: string): Promise<boolean> => {
    try {
      const lawyerSessions = sessions.lawyerSessions.find(
        (x) => x.lawyerName === hubStore.current.connectedSessionLawyerName!
      );

      if (!lawyerSessions) {
        toast.show("Lütfen önce Uyap'a bağlanınız.", "error");
        return false;
      }

      if (hubStore.current.firstSessionProcessIsStarted === true) {
        toast.show("Uyap oturumu henüz açılmadı. Daha sonra tekrar deneyiniz.", "error");
        return false;
      }

      if (hubStore.current.reservedUyapSession) {
        hubStore.current.keys.push(key);
        return true;
      }

      let session = lawyerSessions.sessions.find((s) => s.isSessionReserved === false && s.cookie);

      if (!session) {
        session = lawyerSessions.sessions.find((s) => s.isSessionReserved === false);
      }

      // şimdilik boşta yoksa uyarı veriyoruz. İleride oturum bulana kadar döngü yapılabilir. (ör: 15 saniye)
      if (!session) {
        toast.show(
          "Oturum sayısı yetersiz. Lütfen daha sonra tekrar deneyiniz veya oturum sayısını arttırınız.",
          "error"
        );
        return false;
      }

      let decryptedCookie = "";

      if (session.cookie !== "") {
        try {
          const bytes = CryptoJS.AES.decrypt(session.cookie, hubStore.current.sessionPin);

          if (!bytes) {
            toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
            return false;
          }

          decryptedCookie = bytes.toString(CryptoJS.enc.Utf8);

          if (!decryptedCookie) {
            toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
            return false;
          }
        } catch (error) {
          toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
          return false;
        }
      }

      await connection.current.invoke(
        "ReserveUyapSession",
        auth.getUser().mainUserId,
        lawyerSessions.lawyerName,
        session.sessionNumber
      );

      window.electron?.ipcRenderer?.invoke("set-uyap-cookie", decryptedCookie);
      hubStore.current.reservedUyapSession = { ...session };
      hubStore.current.reservedSessionLawyerName = lawyerSessions.lawyerName;
      hubStore.current.keys.push(key);
      return true;
    } catch (error) {
      console.error("Error invoking ReserveUyapSession method:", error);
      return false;
    }
  };

  const releaseUyapSession = async (key: string) => {
    try {
      const index = hubStore.current.keys.indexOf(key);
      if (index > -1) {
        hubStore.current.keys.splice(index, 1);
      }

      if (hubStore.current.keys.length > 0) {
        return;
      }

      if (!hubStore.current.reservedUyapSession || !hubStore.current.reservedSessionLawyerName) {
        return;
      }

      await connection.current.invoke(
        "ReleaseUyapSession",
        auth.getUser().mainUserId,
        hubStore.current.reservedSessionLawyerName,
        hubStore.current.reservedUyapSession.sessionNumber
      );

      window.electron?.ipcRenderer?.invoke("set-uyap-cookie", "");
      hubStore.current.reservedUyapSession = undefined;
      hubStore.current.reservedSessionLawyerName = undefined;
    } catch (error) {
      console.error("Error invoking ReleaseUyapSession method:", error);
    }
  };

  const openUyapWindow = async () => {
    try {
      const lawyerSessions = sessions.lawyerSessions.find(
        (x) => x.lawyerName === hubStore.current.connectedSessionLawyerName!
      );

      if (!lawyerSessions) {
        toast.show("Lütfen önce Uyap'a bağlanınız.", "error");
        return;
      }

      const session = lawyerSessions.sessions.find((s) => s.isSessionReserved === false);
      const releaseSessionsCount = lawyerSessions.sessions.filter((s) => s.isSessionReserved === false).length;
      // şimdilik boşta yoksa uyarı veriyoruz. İleride oturum bulana kadar döngü yapılabilir. (ör: 15 saniye)
      if (releaseSessionsCount < 2 || !session) {
        toast.show(
          "Oturum sayısı yetersiz. Lütfen daha sonra tekrar deneyiniz veya oturum sayısını arttırınız.",
          "error"
        );
        return;
      }

      let decryptedCookie = "";

      if (session.cookie !== "") {
        try {
          const bytes = CryptoJS.AES.decrypt(session.cookie, hubStore.current.sessionPin);

          if (!bytes) {
            toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
            return;
          }

          decryptedCookie = bytes.toString(CryptoJS.enc.Utf8);

          if (!decryptedCookie) {
            toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
            return;
          }
        } catch (error) {
          toast.show("Oturum açılamadı. Lütfen oturum pinini kontrol ediniz.", "error");
          return;
        }
      }

      await connection.current.invoke(
        "ReserveUyapSession",
        auth.getUser().mainUserId,
        lawyerSessions.lawyerName,
        session.sessionNumber
      );

      window.electron?.ipcRenderer?.invoke("open-uyap-window", { ...session, cookie: decryptedCookie } as SessionModel);
    } catch (error) {
      console.error("Error openUyapWindow method:", error);
    }
  };

  async function executeUyapService<T>(uyapService: FunctionType<T>): Promise<T> {
    const key = generateRandomString();
    const isReserved = await reserveUyapSession(key);
    if (!isReserved) {
      await delay(1500);
      // throw new Error("Oturum yetersiz.");
      console.error("Oturum yetersiz.");
      return {} as T;
    }
    const response = await uyapService();
    await releaseUyapSession(key);
    return response;
  }

  const closeUyapSession = async () => {
    try {
      hubStore.current.connectedSessionLawyerName = "";
      // setHubStore({ ...hubStore });
      await connection.current.invoke("RemoveUyapShare", auth.getUser().mainUserId);
      //console.log("Joined group:", auth.getUser().mainUserId);
    } catch (error) {
      console.error("Error joining group:", error);
    }
  };

  const generateRandomString = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  const value: HubContextModel = {
    sessions,
    stop,
    getBuroSettings,
    joinGroup,
    leaveGroup,
    refreshBuroSettings,
    updateBuroSettings,
    connectSession,
    refreshNewUyapSessionMessageToHub,
    reserveUyapSession,
    releaseUyapSession,
    executeUyapService,
    openUyapWindow,
    getLawyerNameMessageToElectron,
    updateMaxCookieAndCardPin,
    closeUyapSession,
    generateRandomString
  };
  return <HubContext.Provider value={value}>{children}</HubContext.Provider>;
};
