import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import KampanyaMektubuService from "../../../../services/kampanya-mektubu/kampanya-mektubu.service";
import { KampanyaMektubuDto } from "../../../../services/kampanya-mektubu/kampanya-mektubu.dto";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import UpsertKampanyaMektubu from "./UpsertKampanyaMektubu";
import { Calendar } from "primereact/calendar";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const KampanyaMektubu = (props: any) => {
  const [kampanyaMektubu, setKampanyaMektubu] = useState<KampanyaMektubuDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<KampanyaMektubuDto[]>>(null);
  const [visibleUpsertKampanyaMektubuDialog, setVisibleUpsertKampanyaMektubuDialog] = useState<boolean>(false);
  const toast = useToast();

  const { setBlocked } = useContext(LayoutContext)
  const icraFoy: IcraFoyDto = props.icraFoy;

  const {
    data: kampanyaMektubuResponse,
    refetch: refetchKampanyaMektubu,
    isLoading: kampanyaMektubuResponseIsLoading
  } = useQuery({
    queryKey: ["KampanyaMektubu"],
    refetchOnMount: true,
    queryFn: () => KampanyaMektubuService.GetAllByIcraFoyId(icraFoy.id)
  });

  useEffect(() => {
    setBlocked(kampanyaMektubuResponseIsLoading)
  }, [kampanyaMektubuResponseIsLoading])

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni KampanyaMektubu"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertKampanyaMektubuDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: KampanyaMektubuDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setKampanyaMektubu(rowData);
            setVisibleUpsertKampanyaMektubuDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            deleteKampanyaMektubuConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      yazimTarihi: commonFilterContains,
      muhatap: commonFilterContains,
      muhatapAdresi: commonFilterContains,
      "il.ilText": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      "ilce.ilceText": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
      },
      durum: commonFilterContains,
      gonderimTarihi: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const deleteKampanyaMektubu = (rowData: KampanyaMektubuDto) => {
    setBlocked(true)
    KampanyaMektubuService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Kampanya Mektubu başarıyla silindi.", "success");
        refetchKampanyaMektubu();
      }
    });
  };

  const deleteKampanyaMektubuConfirm = (rowData: KampanyaMektubuDto) => {
    confirmDialog({
      message: "Kampanya Mektubunu silmek istediğinize emin misiniz?",
      header: "Kampanya Mektubu Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteKampanyaMektubu(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  // export excel
  const exportExcel = () => {
    const data = kampanyaMektubuResponse?.result?.data || [];

    const headers = ["Yazım Tarihi", "Muhatap", "Muhatap Adresi", "İl", "İlçe", "Durum", "Gönderim Tarihi"];

    const filteredData = data.map((data) => ({
      yazimTarihi: new Date(data.yazimTarihi).toLocaleDateString(),
      muhatap: data.muhatap,
      muhatapAdresi: data.muhatapAdresi,
      "il.ilText": data.il.ilText,
      "ilce.ilceText": data.ilce.ilceText,
      durum: data.durum,
      gonderimTarihi: data.gonderimTarihi ? new Date(data.gonderimTarihi).toLocaleDateString() : ""
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "KampanyaMektubu");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={kampanyaMektubuResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} kampanya mektubu"
          filters={filters}
          // filterDisplay="row"
          loading={kampanyaMektubuResponseIsLoading}
          globalFilterFields={[
            "yazimTarihi",
            "muhatap",
            "muhatapAdresi",
            "il.ilText",
            "ilce.ilceText",
            "durum",
            "gonderimTarihi"
          ]}
          emptyMessage="Kampanya Mektubu bulunamadı."
          header={CreateHeader("Kampanya Mektubu", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "6rem" }}></Column>
          <Column
            field="yazimTarihi"
            header="Yazım Tarihi"
            sortable
            body={(rowData: KampanyaMektubuDto) => {
              return <span>{new Date(rowData.yazimTarihi).toLocaleDateString()}</span>;
            }}
            filterField="yazimTarihi"
            filter
            filterPlaceholder="Yazım Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Yazım Tarihine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="muhatap"
            header="Muhatap"
            sortable
            filterField="muhatap"
            filter
            filterPlaceholder="Muhatapa göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Muhatapa göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="muhatapAdresi"
            header="Muhatap Adresi"
            sortable
            filterField="muhatapAdresi"
            filter
            filterPlaceholder="Muhatap Adresine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Muhatap Adresine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="il.ilText"
            header="İl"
            sortable
            filterField="il.ilText"
            filter
            filterPlaceholder="İle göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İle göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="ilce.ilceText"
            header="İlçe"
            sortable
            filterField="ilce.ilceText"
            filter
            filterPlaceholder="İlçeye göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İlçeye göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="durum"
            header="Durum"
            sortable
            filterField="durum"
            filter
            filterPlaceholder="Duruma göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="gonderimTarihi"
            header="Gönderim Tarihi"
            sortable
            body={(rowData: KampanyaMektubuDto) => {
              return <span>{rowData.gonderimTarihi ? new Date(rowData.gonderimTarihi).toLocaleDateString() : ""}</span>;
            }}
            filterField="gonderimTarihi"
            filter
            filterPlaceholder="Gönderim Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Gönderim Tarihine göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertKampanyaMektubu
        visible={visibleUpsertKampanyaMektubuDialog}
        setVisible={setVisibleUpsertKampanyaMektubuDialog}
        kampanyaMektubu={kampanyaMektubu}
        setKampanyaMektubu={setKampanyaMektubu}
        refetchKampanyaMektubu={refetchKampanyaMektubu}
        icraFoy={icraFoy}
      />
    </div>
  );
};

export default KampanyaMektubu;
