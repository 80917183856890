import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { NoContentDto } from "../base/base.dto";
import {
  TarihceKullaniciNotuDto,
  CreateTarihceKullaniciNotuDto,
  UpdateTarihceKullaniciNotuDto,
} from "./tarihce-kullanici-notlari.dto";
const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TarihceKullaniciNotuDto>(
      `TarihceKullaniciNotu/${id}`,
      config
    );
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TarihceKullaniciNotuDto[]>(
      `TarihceKullaniciNotu/GetAll`,
      config
    );
  },
  GetAllByIcraFoy(
    icraFoyId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<TarihceKullaniciNotuDto[]>(
      `TarihceKullaniciNotu/GetAllByIcraFoy/${icraFoyId}`,
      config
    );
  },
  Create(
    dto: CreateTarihceKullaniciNotuDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TarihceKullaniciNotuDto>(
      "TarihceKullaniciNotu/Create",
      dto,
      config
    );
  },
  Update(
    dto: UpdateTarihceKullaniciNotuDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<TarihceKullaniciNotuDto>(
      "TarihceKullaniciNotu/Update",
      dto,
      config
    );
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(
      `TarihceKullaniciNotu/Delete/${id}`,
      config
    );
  },
};

export default services;
