import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useEvrakListeleyici } from "./useEvrakListeleyici";
import { useToast } from "../../../../utils/toast";

interface EvrakBulkDownloaderProps {
  nodes: any;
  dosyaNo: string;
  birimAdi: string;
  dosyaTur: string;
  dosyaId: string;
  setDownloadOpen: (e: boolean) => void;
  useContext: () => {
    startDownload: (node: any, dosyaId: string, dosyaNo: string, birimAdi: string, dosyaTur: string) => void;
    downloaded: number;
    total: number;
  };
  setSelection?: (e: any) => void;
}

const bulkDownloadOption = [
  {
    label: "İndirilmeyenleri İndir",
    value: "PARTIALLY"
  },
  {
    label: "Seçilenleri İndir",
    value: "SELECTED"
  },
  {
    label: "Seçilmeyenleri İndir",
    value: "UNSELECTED"
  },
  {
    label: "Hepsini İndir",
    value: "ALL"
  }
];

export const EvrakBulkDownloader: React.FC<EvrakBulkDownloaderProps> = ({
  nodes,
  dosyaNo,
  birimAdi,
  dosyaTur,
  dosyaId,
  setDownloadOpen,
  useContext,
  setSelection
}) => {
  const { flatArray } = useEvrakListeleyici();
  const [selectedBulkDownloadOption, setSelectedBulkDownloadOption] = useState(null);
  // const [selection, setSelection] = useState<any>();
  const { startDownload, downloaded, total } = useContext(); // disiri alinacak
  const toast = useToast();

  const bulkDownloadHandler = () => {
    setDownloadOpen(false);
    if (!selectedBulkDownloadOption) return console.error("selectedBulkDownloadOption is null");
    type SelectionValue = {
      [key: string]: { checked: boolean };
    };
    type Selections = {
      value: SelectionValue;
    };
    let userSelection: string[] = [];
    // if (selection) userSelection = Object.keys(selection).filter((key: any) => selection[key]?.checked == true);
    let downloadRequests: any = [];

    switch (selectedBulkDownloadOption) {
      case "PARTIALLY":
        downloadRequests = flatArray(nodes)
          .filter((item) => item?.data?.evrakId)
          .filter((item) => !item?.data?.location);
        break;
      case "SELECTED":
        downloadRequests = flatArray(nodes)
          .filter((item) => item?.data?.evrakId)
          .filter((item) => userSelection.includes(item.key as string));
        break;
      case "UNSELECTED":
        downloadRequests = flatArray(nodes)
          .filter((item) => item?.data?.evrakId)
          .filter((item) => !userSelection.includes(item.key as string));
        break;
      case "ALL":
        downloadRequests = flatArray(nodes).filter((item) => item?.data?.evrakId);
        break;
      default:
        // TO DO
        break;
    }

    if (downloadRequests.length > 0) {
      let uniques: any[] = [];

      downloadRequests.forEach((item: any) => {
        const evrakId = item?.data?.evrakId;
        if (!uniques.some((uItem: any) => uItem?.evrakId === evrakId)) {
          uniques.push(item?.data);
        }
      });

      //toplu indirme contexine gidiyor
      if (total > 0 && downloaded < total) {
        toast.show("Evrak indirme işlemi devam ediyor. Lütfen bekleyiniz.", "error");
        return;
      }

      startDownload(uniques, dosyaId, dosyaNo, birimAdi, dosyaTur);
    }
  };

  useEffect(() => {
    let userSelection: string[] = [];
    let downloadRequests: any = [];
    if (selectedBulkDownloadOption) {
      switch (selectedBulkDownloadOption) {
        case "PARTIALLY":
          downloadRequests = flatArray(nodes)
            .filter((item) => item?.data?.evrakId)
            .filter((item) => !item?.data?.location);
          break;
        case "SELECTED":
          downloadRequests = flatArray(nodes)
            .filter((item) => item?.data?.evrakId)
            .filter((item) => userSelection.includes(item.key as string));
          break;
        case "UNSELECTED":
          downloadRequests = flatArray(nodes)
            .filter((item) => item?.data?.evrakId)
            .filter((item) => !userSelection.includes(item.key as string));
          break;
        case "ALL":
          downloadRequests = flatArray(nodes).filter((item) => item?.data?.evrakId);
          break;
        default:
          // TO DO
          break;
      }
    }
    let selectionKeys : any = {}
    downloadRequests.forEach((element : any) => {
      selectionKeys[element?.key] = {
        checked: true,
        partialChecked: false
      }
    });
    setSelection?.(selectionKeys)
    console.log("downloadRequests", downloadRequests)
    console.log("downloadRequests", selectionKeys)
  }, [selectedBulkDownloadOption]);

  return (
    <div className="flex flex-column border-1 border-200 border-round p-3 bg-100 w-full gap-3">
      <Dropdown
        value={selectedBulkDownloadOption}
        onChange={(e) => setSelectedBulkDownloadOption(e.value)}
        options={bulkDownloadOption}
        optionLabel="label"
        placeholder="İndirme Seçenekleri"
        className="w-full"
      />
      <Button
        onClick={bulkDownloadHandler}
        className="w-full"
        label="İndir"
        disabled={selectedBulkDownloadOption ? false : true}
      />
    </div>
  );
};
