import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DosyaDTO,
  IcraDosyasiResponseDTO,
  SorguSonucIcraDosyasi
} from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import SorguBilgileri from "./SorguBilgileri";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const IcraDosyasi = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<SorguSonucIcraDosyasi[]>>(null);

  const {
    data: IcraDosyasiResponse,
    refetch: refetchIcraDosyasi,
    isLoading: icraDosyasiResponseLoading
  } = useQuery({
    queryKey: ["IcraDosyasi", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetIcraDosyasiBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(icraDosyasiResponseLoading)
  }, [icraDosyasiResponseLoading])

  const birimAdıActionBodyTemplate = (rowData: SorguSonucIcraDosyasi) => {
    return `${rowData.birimAdi}  ${rowData.dosyaNo} ${rowData.dosyaTurKoduAciklama}`;
  };

  const takipYoluActionBodyTemplate = (rowData: SorguSonucIcraDosyasi) => {
    return `${rowData.takipYoluAciklamasi}  ${rowData.takipSekliciklamasi} `;
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      birim: commonFilterContains,
      takipTuru: commonFilterContains,
      takipYolu: commonFilterContains,
      durumu: commonFilterContains,
      acilis: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
      },
      kapanis: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
      }
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const data = processedData || [];

    const headers = ["Birim Adı/Dosya", "Takip Türü", "Takip Yolu / Şekli", "Durumu", "Açılış", "Kapanış"];

    const filteredData = data.map((data) => ({
      birim: `${data.birimAdi}  ${data.dosyaNo} ${data.dosyaTurKoduAciklama}`,
      takipTurAciklamasi: data.takipTurAciklamasi,
      takipYolu: `${data.takipYoluAciklamasi}  ${data.takipSekliciklamasi} `,
      dosyaDurumuAciklama: data.dosyaDurumuAciklama,
      dosyaAcilisTarihiStr: moment(data.dosyaAcilisTarihiStr).format("DD.MM.YYYY HH:mm"),
      dosyaKapanisTarihiStr: ""
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "IcraDosyasi");
  };

  const processedData =
    IcraDosyasiResponse?.result?.sorguSonucDVO?.map((item, index) => ({
      ...item,
      key: index,
      dosyaAcilisTarihiStrFormated: moment(item.dosyaAcilisTarihiStr, "YYYY-MM-DD hh:mm:ss").toDate()
    })) || [];

  return (
    <div>
      {IcraDosyasiResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {IcraDosyasiResponse.error}</div>}

      {IcraDosyasiResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={IcraDosyasiResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={IcraDosyasiResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={IcraDosyasiResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={IcraDosyasiResponse?.result?.sorgulayanBilgileri}
          />
          <br />

          {IcraDosyasiResponse?.result.sorguSonucu === true ? (
            <div>
              <div className="card p-2 lg:p-5 mt-3 ">Alacaklı olduğu İcra Dosyası kaydı var.</div>
              <div className="overflow-x-auto">
                <DataTable
                  //value={IcraDosyasiResponse?.result?.sorguSonucDVO?.map((item, index) => ({ ...item, key: index })) || []}
                  value={processedData}
                  ref={dt}
                  dataKey="key"
                  showGridlines
                  scrollable
                  loading={icraDosyasiResponseLoading}
                  scrollHeight="250px"
                  style={{ minHeight: "7rem" }}
                  selectionMode={"single"}
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  filters={filters}
                  // filterDisplay="row"
                  globalFilterFields={["birim", "takipTuru", "takipYolu", "durumu", "acilis", "kapanis"]}
                  header={CreateHeader(
                    "İcra Dosyaları",
                    globalFilterValue,
                    (e) => onGlobalFilterChange(e),
                    clearFilter,
                    exportCSV,
                    exportExcel
                  )}
                  columnResizeMode="expand"
                >
                  <Column
                    header="No"
                    body={(rowData, { rowIndex }) => rowIndex + 1}
                    className="w-4rem text-center"
                  ></Column>
                  <Column
                    field="birim"
                    body={birimAdıActionBodyTemplate}
                    header="Birim Adı/Dosya"
                    sortable
                    filterField="birim"
                    filter
                    filterPlaceholder="Birim adı veya dosya numarasına göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return (
                        <div className="px-3 pt-0 pb-3 text-center">
                          Birim adı veya dosya numarasına göre filtreleyin
                        </div>
                      );
                    }}
                  ></Column>
                  <Column
                    field="takipTurAciklamasi"
                    header="Takip Türü"
                    sortable
                    filterField="takipTuru"
                    filter
                    filterPlaceholder="Takip türüne göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Takip türüne göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    body={takipYoluActionBodyTemplate}
                    header="Takip Yolu / Şekli"
                    sortable
                    filterField="takipYolu"
                    filter
                    filterPlaceholder="Takip yolu veya şekline göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Takip yolu veya şekline göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="dosyaDurumuAciklama"
                    header="Durumu"
                    sortable
                    filterField="durumu"
                    filter
                    filterPlaceholder="Duruma göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="dosyaAcilisTarihiStr"
                    header="Açılış"
                    sortable
                    body={(rowData) => (
                      <span>
                        {rowData.dosyaAcilisTarihiStr
                          ? moment(rowData.dosyaAcilisTarihiStr).format(process.env.REACT_APP_DATE_FORMAT)
                          : ""}
                      </span>
                    )}
                    filterField="acilis"
                    filter
                    filterPlaceholder="Açılış Tarihine göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterElement={Filter.DateFilterTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Açılış Tarihine göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column field="" header="Kapanış"></Column>
                </DataTable>
              </div>
            </div>
          ) : (
            <div className="card p-2 lg:p-5">Alacaklı olduğu İcra Dosyası kaydı Yok.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default IcraDosyasi;
