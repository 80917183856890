import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguMernisModel } from "../TopluSorgu.dto";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";

const MernisDataTable = (props: { tableData: TopluSorguMernisModel[] }) => {
  const mernisVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguMernisModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      adres: commonFilterContains,
      il: commonFilterContains,
      ilce: commonFilterContains,
      tescilTarihi: commonFilterDateIs,
      beyanTarihi: commonFilterDateIs,
      tasinmaTarihi: commonFilterDateIs,
      olumTarihi: commonFilterDateIs
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Adres",
      "İl",
      "İlçe",
      "Tescil Tarihi",
      "Beyan Tarihi",
      "Taşınma Tarihi",
      "Ölüm Tarihi"
    ];

    const filteredData = mernisVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      adres: data.adres,
      il: data.il,
      ilce: data.ilce,
      tescilTarihi: data.tescilTarihi ? moment(data.tescilTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "",
      beyanTarihi: data.beyanTarihi ? moment(data.beyanTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "",
      tasinmaTarihi: data.tasinmaTarihi ? moment(data.tasinmaTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "",
      olumTarihi: data.olumTarihi ? moment(data.olumTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "",
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluSorguMernis");
  };

  return (
    <div>
      <DataTable
        value={mernisVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="key"
        showGridlines
        emptyMessage="Mernis Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Mernis Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "adres",
          "il",
          "ilce",
          "tescilTarihi",
          "beyanTarihi",
          "tasinmaTarihi",
          "olumTarihi"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filter
          filterField="ortakDegerler.dosyaNo"
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filter
          filterField="ortakDegerler.alacakliAdi"
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filter
          filterField="ortakDegerler.borcluAdi"
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filter
          filterField="ortakDegerler.borcluTCVKNo"
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filter
          filterField="ortakDegerler.dosyaDurumu"
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />

        <Column
          header="Adres"
          field="adres"
          sortable
          filter
          filterField="adres"
          filterPlaceholder="Adrese göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Adrese göre filtreleyin</div>}
        />

        <Column
          header="İl"
          field="il"
          sortable
          filter
          filterField="il"
          filterPlaceholder="İle göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İle göre filtreleyin</div>}
        />

        <Column
          header="İlçe"
          field="ilce"
          sortable
          filter
          filterField="ilce"
          filterPlaceholder="İlçeye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İlçeye göre filtreleyin</div>}
        />
        <Column
          header="Tescil Tarihi"
          field="tescilTarihi"
          sortable
          body={(rowData) => (rowData.tescilTarihi ? moment(rowData.tescilTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "")}
          filter
          filterField="tescilTarihi"
          filterPlaceholder="Tescil Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tescil Tarihine göre filtreleyin</div>}
        />
        <Column
          header="Beyan Tarihi"
          field="beyanTarihi"
          sortable
          body={(rowData) => (rowData.beyanTarihi ? moment(rowData.beyanTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "")}
          filter
          filterField="beyanTarihi"
          filterPlaceholder="Beyan Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Beyan Tarihine göre filtreleyin</div>}
        />

        <Column
          header="Taşınma Tarihi"
          field="tasinmaTarihi"
          sortable
          body={(rowData) => (rowData.tasinmaTarihi ? moment(rowData.tasinmaTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "")}
          filter
          filterField="tasinmaTarihi"
          filterPlaceholder="Taşınma Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Taşınma Tarihine göre filtreleyin</div>}
        />
        <Column
          header="Ölüm Tarihi"
          field="olumTarihi"
          sortable
          body={(rowData) => (rowData.olumTarihi ? moment(rowData.olumTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "")}
          filter
          filterField="olumTarihi"
          filterPlaceholder="Ölüm Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Ölüm Tarihine göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default MernisDataTable;
