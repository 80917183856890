import React, { createContext, useContext } from "react";
import { TopluSorguListesiModel, TopluSorguModel } from "../pages/toplu-sorgulama/TopluSorgu.dto";
import { useToast } from "./toast";

export interface SaveJsonFileRequestDto {
  jsonString: string;
  relativePath: string;
}

export interface ElectronResponseDto {
  isSuccessful: boolean;
  errorMessage: string;
  data?: any;
}

export interface SettingsModel {
  email: string;
  password: string;
  rememberMe: boolean;
  folderPath: string;
  theme: string;
  colorScheme: string;
  scale: number;
}
interface ElectronContextModel {
  getSettings: () => Promise<SettingsModel | null>;
  setSettings: (settingModel: SettingsModel) => Promise<boolean>;
  getTopluSorguListesi: () => Promise<TopluSorguListesiModel[] | null>;
  setTopluSorguListesi: (topluSorguListesi: TopluSorguListesiModel[]) => Promise<boolean>;
  getTopluSorguSonuclarListesi: () => Promise<TopluSorguModel[] | null>;
  setTopluSorguSonuclarListesi: (sorguModel: TopluSorguModel[]) => Promise<boolean>;
  showElectronFolderDiyalog: () => Promise<string>;
  showNotification: (header: string, body: string) => Promise<void>;
}

let ElectronContext = createContext<ElectronContextModel>(null!);

export const useElectron = () => {
  return useContext(ElectronContext);
};

const SavedFiledLocatedPath = {
  GetTopluSorguList() {
    return "saved-file/topluSorguListesi.json";
  },
  GetTopluSorguSonuclarList() {
    return "saved-file/topluSorguSonuclarListesi.json";
  },
  GetSettings() {
    return "saved-file/settings.json";
  }
};

export const ElectronProvider = ({ children }: { children: React.ReactNode }) => {
  const toast = useToast();

  const setSettings = async (settingsModel: SettingsModel) => {
    const jsonString = JSON.stringify(settingsModel);
    const data = {
      file: jsonString,
      path: SavedFiledLocatedPath.GetSettings()
    };
    const res: ElectronResponseDto = await window.electron?.ipcRenderer.invoke("save-json-file", data);

    if (!res.isSuccessful) {
      return false;
    }

    return true;
  };

  const getSettings = async (): Promise<SettingsModel | null> => {
    const res: ElectronResponseDto = await window.electron?.ipcRenderer?.invoke(
      "read-json-file",
      SavedFiledLocatedPath.GetSettings()
    );

    if (!res.isSuccessful) {
      toast.show("Ayarlar dosyası okunurken bir sorun oluştu.", "error");
      return null;
    }

    const parsedData: SettingsModel = JSON.parse(res.data);
    return parsedData;
  };

  const setTopluSorguListesi = async (topluSorguListesiModel: TopluSorguListesiModel[]) => {
    const jsonString = JSON.stringify(topluSorguListesiModel);
    const data = {
      file: jsonString,
      path: SavedFiledLocatedPath.GetTopluSorguList()
    };
    const res: ElectronResponseDto = await window.electron?.ipcRenderer.invoke("save-json-file", data);

    if (!res.isSuccessful) {
      return false;
    }

    return true;
  };

  const getTopluSorguListesi = async (): Promise<TopluSorguListesiModel[] | null> => {
    const res: ElectronResponseDto = await window.electron?.ipcRenderer?.invoke(
      "read-json-file",
      SavedFiledLocatedPath.GetTopluSorguList()
    );

    if (!res.isSuccessful) {
      toast.show("Ayarlar dosyası okunurken bir sorun oluştu.", "error");
      return null;
    }

    const parsedData: TopluSorguListesiModel[] = JSON.parse(res.data);
    return parsedData;
  };

  const setTopluSorguSonuclarListesi = async (topluSorguSonuclarListesiModel: TopluSorguModel[]) => {
    const jsonString = JSON.stringify(topluSorguSonuclarListesiModel);
    const data = {
      file: jsonString,
      path: SavedFiledLocatedPath.GetTopluSorguSonuclarList()
    };
    const res: ElectronResponseDto = await window.electron?.ipcRenderer.invoke("save-json-file", data);

    if (!res.isSuccessful) {
      return false;
    }

    return true;
  };

  const getTopluSorguSonuclarListesi = async (): Promise<TopluSorguModel[] | null> => {
    const res: ElectronResponseDto = await window.electron?.ipcRenderer?.invoke(
      "read-json-file",
      SavedFiledLocatedPath.GetTopluSorguSonuclarList()
    );

    if (!res.isSuccessful) {
      toast.show("Ayarlar dosyası okunurken bir sorun oluştu.", "error");
      return null;
    }

    const parsedData: TopluSorguModel[] = JSON.parse(res.data);
    return parsedData;
  };

  const showElectronFolderDiyalog = async (): Promise<string> => {
    const res: ElectronResponseDto = await window.electron?.ipcRenderer?.invoke("openFolder", "");

    if (!res.isSuccessful) {
      return "";
    }
    return res.data as string;
  };

  const showNotification = async (header: string, body: string): Promise<void> => {
    await window.electron?.ipcRenderer?.invoke("show-notification", {
      title: header,
      body: body
    });
  };

  const value: ElectronContextModel = {
    getSettings,
    setSettings,
    getTopluSorguListesi,
    setTopluSorguListesi,
    getTopluSorguSonuclarListesi,
    setTopluSorguSonuclarListesi,
    showElectronFolderDiyalog,
    showNotification
  };

  return <ElectronContext.Provider value={value}>{children}</ElectronContext.Provider>;
};
