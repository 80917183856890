import React, { useEffect, useState } from "react";
import { ViewerBaseProps } from "./fileViewer.dto";
interface HTMLViewerProps extends ViewerBaseProps {
  isError?: boolean;
}

export const HtmlViewer: React.FC<HTMLViewerProps> = ({ file, isError = false }) => {
  const { data } = file;
  const [html, setHtml] = useState<any>(null);

  useEffect(() => {
    
    data?.text().then((text) => {
      setHtml(text);
    });
  }, [file]);

  if (isError) return <div className="h-full flex justify-content-center align-items-center p-6 text-center"><p dangerouslySetInnerHTML={{ __html: html }} style={{fontSize: 14, fontWeight: 600}}/></div>;

  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
};
