import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import AccountService from "../../services/account/account.service";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { UserDto } from "../../services/account/account.dto";
import SubUsersComponent from "./SubUsersComponent";
import { LayoutContext } from "../../layout/context/layoutcontext";

const SubUsersPage = (props: any) => {
  const [subUser, setSubUser] = useState<UserDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<UserDto[]>>(null);
  const [visibleUpsertSubUserDialog, setVisibleUpsertSubUserDialog] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: subUserResponse,
    refetch: refetchSubUser,
    isLoading: subUsersResponseIsLoading
  } = useQuery({
    queryKey: ["SubUsers"],
    refetchOnMount: true,
    queryFn: () => AccountService.GetAllSubUser()
  });
  useEffect(() => {
    setBlocked(subUsersResponseIsLoading)
  }, [subUsersResponseIsLoading])



  const deleteSubUser = (rowData: UserDto) => {
    setBlocked(true)
    AccountService.DeleteSubUser(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Alt kullanıcı başarıyla silindi.", "success");
        refetchSubUser();
      }
    });
  };

  const deleteSubUserConfirm = (rowData: UserDto) => {
    confirmDialog({
      message: "Alt kullanıcıyı silmek istediğinize emin misiniz?",
      header: "Alt Kullanıcı Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteSubUser(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  return (
    <div>
      {subUserResponse &&
        subUserResponse.result && (
          <div>
            <SubUsersComponent
              users={subUserResponse.result.data}
              refetchSubUser={refetchSubUser}
              isAdminPanel={false}
            />
          </div>
        )}
    </div>
  );
};

export default SubUsersPage;
