import { useHub } from "../../../utils/buro-hub";
import uyapService from "../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../layout/context/layoutcontext";
import moment from "moment";
const DosyaBilgileri = (props: { dosyaId: string; dosyaTurKod: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const {
    data: DosyaBilgileriResponse,
    refetch: refetchDosyaBilgileri,
    isLoading: dosyaBilgileriResponseLoading
  } = useQuery({
    queryKey: ["DosyaBilgileri", props.dosyaId],
    gcTime: 1000 * 60 * 11,
    refetchOnMount: false,
    queryFn: async () => await hub.executeUyapService(() => uyapService.GetDosyaAyrintiliBilgileri(props.dosyaId))
  });

  useEffect(() => {
    setBlocked(dosyaBilgileriResponseLoading);
  }, [dosyaBilgileriResponseLoading]);

  //ceza dosya bilgisi yok
  //hukuk 15
  //icra 35
  //idari yargı 9
  //vergi dava dosya 28
  //talimat 1
  //satış memurluğu 168
  //Arabuluculuk 422
  //cbs yok
  return (
    <div style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}>
      {props.dosyaTurKod === 35 ? (
        <>
          {DosyaBilgileriResponse?.result?.takibinTuruAciklama && (
            <div className="p-3 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "130px" }}>
                Türü
              </span>
              <span>{`${DosyaBilgileriResponse?.result?.takibinTuruAciklama}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result?.takibinYoluAciklama && (
            <div className="p-3">
              <span className="font-bold inline-block" style={{ width: "130px" }}>
                Yolu
              </span>
              <span>{`${DosyaBilgileriResponse?.result?.takibinYoluAciklama}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result?.takibinSekliAciklama && (
            <div className="p-3 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "130px" }}>
                Şekli
              </span>
              <span>{`${DosyaBilgileriResponse?.result?.takibinSekliAciklama.toString()}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result?.dosyaDurumu && (
            <div className="p-3 flex align-items-center">
              <span className="font-bold" style={{ width: "130px" }}>
                Dosya Durumu
              </span>
              <span className="px-5 py-1 bg-blue-100 text-blue-900 border-round  font-medium">
                {`${DosyaBilgileriResponse.result.dosyaDurumu}`}
              </span>
            </div>
          )}
        </>
      ) : props.dosyaTurKod === 15 ? (
        <>
          {DosyaBilgileriResponse?.result?.davaAcilisTuruStr && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                Dava Açılış Türü
              </span>
              <span>{`${DosyaBilgileriResponse.result.davaAcilisTuruStr}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                Başvuruya Bırakılma Sayısı
              </span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                Başvuruya Bırakılma Tarihi
              </span>
              <span>{`${DosyaBilgileriResponse.result.basvuruyaBirakilmaTarihiStr || "-"}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                Dava Türleri
              </span>
              <span>{`${DosyaBilgileriResponse.result.davaTurleriStr}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                İlgili Dosyalar
              </span>
              <span>{`${DosyaBilgileriResponse.result.ilgiliDosyaListesiStr || ""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                İlgili Davalar
              </span>
              <span>{`${DosyaBilgileriResponse.result.ilgiliDavaListesiStr}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                İlgili Seri Davalar
              </span>
              <span>{`${DosyaBilgileriResponse.result.ilgiliSeriDavaListesiStr || ""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                Birleşen Dosyalar
              </span>
              <span>{`${DosyaBilgileriResponse.result.birlesenDosyaListStr || ""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result?.dosyaDurumu && (
            <div className="p-2 surface-100 border-round flex align-items-center">
              <span className="font-bold" style={{ width: "220px" }}>
                Dosya Durumu
              </span>
              <span className="px-5 py-1 bg-blue-100 text-blue-900 border-round  font-medium">
                {`${DosyaBilgileriResponse.result.dosyaDurumu}`}
              </span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                Duruşma Tarihi
              </span>
              <span>{`${DosyaBilgileriResponse.result.durusmaTarihiStr ? DosyaBilgileriResponse.result.durusmaTarihiStr : ""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                Keşif Tarihi
              </span>
              <span>{`${DosyaBilgileriResponse.result.kesifTarihiStr || ""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "220px" }}>
                Ön İnceleme Tarihi
              </span>
              <span>
                {DosyaBilgileriResponse.result.onIncelemTarihi && moment(DosyaBilgileriResponse.result.onIncelemTarihi).isValid()
                  ? moment(DosyaBilgileriResponse.result.onIncelemTarihi).format(process.env.REACT_APP_DATE_FORMAT)
                  : ""}
              </span>
            </div>
          )}
        </>
      ) : props.dosyaTurKod === 422 ? (
        <>
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Dosya No
              </span>
              <span>{`${DosyaBilgileriResponse.result.dosyaNo}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Gelen Dosya No
              </span>
              <span>{`${DosyaBilgileriResponse.result.gelenDosyaNo}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result?.dosyaDurumu && (
            <div className="p-2 flex align-items-center">
              <span className="font-bold" style={{ width: "180px" }}>
                Dosya Durumu
              </span>
              <span className="px-5 py-1 bg-blue-100 text-blue-900 border-round  font-medium">
                {`${DosyaBilgileriResponse.result.dosyaDurumu}`}
              </span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Gelen Birim Adı
              </span>
              <span>{`${DosyaBilgileriResponse.result.gelenBirimAdi}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Karar No
              </span>
              <span>{`${DosyaBilgileriResponse.result.kararNo}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Gönderilme Tarihi
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.gonderilmeTarihi}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Karar Tarihi
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.kararTarihi}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Karar Açıklama
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.kararAciklama}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Arabulucu
              </span>
              <span>{`${DosyaBilgileriResponse.result.arabulucu}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Arabulucu Telefon
              </span>
              <span>{`${DosyaBilgileriResponse.result.arabulucuTel}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Arabulucu Eposta
              </span>
              <span>{`${DosyaBilgileriResponse.result.arabulucuEposta}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "180px" }}>
                Arabulucu Cep Telefon
              </span>
              <span>{`${DosyaBilgileriResponse.result.arabulucuCepTel}`}</span>
            </div>
          )}
        </>
      ) : // ) : {dosyaTurKod === 1 || dosyaTurKod === 168 && (
      props.dosyaTurKod === 9 || props.dosyaTurKod === 28 ? (
        <>
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Davanın Konusu
              </span>
              <span>{`${DosyaBilgileriResponse.result.davaKonusu}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Heyetli
              </span>
              <span>{`${DosyaBilgileriResponse.result.heyetli}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Delil Tespiti İstemli
              </span>
              <span>{`${DosyaBilgileriResponse.result.delilTespiti}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                YD
              </span>
              <span>{`${DosyaBilgileriResponse.result.yd}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Karar No
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Karar
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.gonderilmeTarihi}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Karar Tarihi
              </span>
              <span>{`${DosyaBilgileriResponse.result.kararTarihi ?? ""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result?.dosyaDurumu && (
            <div className="p-2 surface-100 border-round flex align-items-center">
              <span className="font-bold" style={{ width: "200px" }}>
                Dosya Durumu
              </span>
              <span className="px-5 py-1 bg-blue-100 text-blue-900 border-round  font-medium">
                {`${DosyaBilgileriResponse.result.dosyaDurumu}`}
              </span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Keşif Tarihi
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Adil Yardımlı
              </span>
              <span>{`${DosyaBilgileriResponse.result.adliMuzaheret}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Duruşmalı
              </span>
              <span>{`${DosyaBilgileriResponse.result.durusma}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                İlişkili Dosya İlişki Türü
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                İlişkili Dosya Birim
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                İlişkili Dosya Esas No
              </span>
              <span>{`${DosyaBilgileriResponse.result.iliskiliDosyaEsasNo}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                İlişkili Dosya Açılış Tarihi
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
          {DosyaBilgileriResponse?.result && (
            <div className="p-2 surface-100 border-round">
              <span className="font-bold inline-block" style={{ width: "200px" }}>
                Duruşma Tarihi
              </span>
              {/* <span>{`${DosyaBilgileriResponse.result.}`}</span> */}
              <span>{`${""}`}</span>
            </div>
          )}
        </>
      ) : (
        // ) : {dosyaTurKod === 1 || dosyaTurKod === 168 && (
        <>
          {DosyaBilgileriResponse?.result && (
            <div className="p-2  flex align-items-center">
              <span className="font-bold" style={{ width: "130px" }}>
                Dosya Durumu
              </span>
              <span className="px-5 py-1 bg-blue-100 text-blue-900 border-round  font-medium">
                {`${DosyaBilgileriResponse.result.dosyaDurumu}`}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DosyaBilgileri;
