import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useRef, useState } from "react";
import { MuvekkilDto, MuvekkilTipi, UpdateMuvekkilDto } from "../../services/muvekkil/muvekkil.dto";
import { FormikErrors, useFormik } from "formik";
import MuvekkilService from "../../services/muvekkil/muvekkil.service";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../utils/DropdownValues";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useToast } from "../../utils/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AddressDto, UpdateAddressDto } from "../../services/address/address.dto";
import { TelephoneDto, UpdateTelephoneDto } from "../../services/telephone/telephone.dto";
import UpsertAddress from "../../components/UpsertAddress";
import { confirmDialog } from "primereact/confirmdialog";
import UpsertTelephone from "../../components/UpsertTelephone";
import UpsertEmail from "../../components/UpsertEmail";
import { UpdateEmailDto } from "../../services/email/email.dto";
import { LayoutContext } from "../../layout/context/layoutcontext";

const UpsertMuvekkil = (props: any) => {
  const muvekkil: MuvekkilDto = props.muvekkil;
  const setMuvekkil = props.setMuvekkil;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchMuvekkil = props.refetchMuvekkil;
  const toast = useToast();
  const dtAdresses = useRef<DataTable<UpdateAddressDto[]>>(null);
  const dtTelephones = useRef<DataTable<UpdateTelephoneDto[]>>(null);
  const dtEmails = useRef<DataTable<UpdateEmailDto[]>>(null);
  const [visibleUpsertAddressDialog, setVisibleUpsertAddressDialog] = useState<boolean>(false);
  const [visibleUpsertTelephoneDialog, setVisibleUpsertTelephoneDialog] = useState<boolean>(false);
  const [visibleUpsertEmailDialog, setVisibleUpsertEmailDialog] = useState<boolean>(false);
  const [address, setAddress] = useState<UpdateAddressDto>();
  const [telephone, setTelephone] = useState<UpdateTelephoneDto>();
  const [email, setEmail] = useState<UpdateEmailDto>();

  const { setBlocked } = useContext(LayoutContext)
  let updateMuvekkilDto: UpdateMuvekkilDto;

  if (muvekkil === null || muvekkil === undefined) {
    updateMuvekkilDto = {
      id: 0,
      tip: MuvekkilTipi.Gercek,
      adSoyad: "",
      tcNo: "",
      unvan: "",
      vergiKimlikNumarasi: "",
      babaAdi: "",
      anaAdi: "",
      cinsiyet: "",
      vergiDairesi: "",
      mersisNo: "",
      vekaletBilgisi: "",
      adresler: [],
      telefonlar: [],
      epostalar: []
    };
  } else {
    updateMuvekkilDto = {
      ...muvekkil,
      adresler: [...muvekkil.adresler],
      telefonlar: [...muvekkil.telefonlar],
      dogumTarihi: muvekkil.dogumTarihi ? new Date(muvekkil.dogumTarihi) : undefined
    };
  }

  const formik = useFormik<UpdateMuvekkilDto>({
    initialValues: updateMuvekkilDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateMuvekkilDto> = {};
      if (!data.tip) {
        errors.tip = "Tip alanı boş bırakılamaz";
      }

      if (data.tip == MuvekkilTipi.Gercek) {
        if (!data.adSoyad) {
          errors.adSoyad = "Ad Soyad alanı boş bırakılamaz";
        }
        if (!data.tcNo) {
          errors.tcNo = "TC No alanı boş bırakılamaz";
        }
      }
      if (data.tip == MuvekkilTipi.Tuzel) {
        if (!data.unvan) {
          errors.unvan = "Ünvan alanı boş bırakılamaz";
        }
        if (!data.vergiKimlikNumarasi) {
          errors.vergiKimlikNumarasi = "Vergi Kimlik Numarası alanı boş bırakılamaz";
        }
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      var muvekkilUpsertDto: UpdateMuvekkilDto = {
        ...formik.values
      };
      if (muvekkilUpsertDto.tip === MuvekkilTipi.Gercek) {
        muvekkilUpsertDto.unvan = "";
        muvekkilUpsertDto.vergiKimlikNumarasi = "";
      } else {
        muvekkilUpsertDto.adSoyad = "";
        muvekkilUpsertDto.tcNo = "";
        muvekkilUpsertDto.babaAdi = "";
        muvekkilUpsertDto.anaAdi = "";
        muvekkilUpsertDto.cinsiyet = "";
        muvekkilUpsertDto.dogumTarihi = undefined;
      }

      muvekkilUpsertDto.adresler.map((x) => {
        if (x.id < 0) {
          x.id = 0;
        }
      });

      muvekkilUpsertDto.telefonlar.map((x) => {
        if (x.id < 0) {
          x.id = 0;
        }
      });

      muvekkilUpsertDto.epostalar.map((x) => {
        if (x.id < 0) {
          x.id = 0;
        }
      });

      if (updateMuvekkilDto.id === 0) {
        MuvekkilService.Create(muvekkilUpsertDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setMuvekkil(undefined);
            refetchMuvekkil();
            toast.show("Müvekkil başarıyla eklendi.", "success");
          }
        });
      } else {
        MuvekkilService.Update(muvekkilUpsertDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setMuvekkil(undefined);
            refetchMuvekkil();
            toast.show("Müvekkil başarıyla güncellendi.", "success");
          }
        });
      }
    }
  });

  const addressActionBodyTemplate = (rowData: UpdateAddressDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          tooltip="Düzenle"
          rounded
          outlined
          className="mr-2"
          onClick={() => {
            setAddress(rowData);
            setVisibleUpsertAddressDialog(true);
          }}
        />
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => deleteAddressConfirm(rowData)} />
      </div>
    );
  };

  const telephoneActionBodyTemplate = (rowData: UpdateTelephoneDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          tooltip="Düzenle"
          rounded
          outlined
          className="mr-2"
          onClick={() => {
            setTelephone(rowData);
            setVisibleUpsertTelephoneDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Sil"
          rounded
          outlined
          severity="danger"
          onClick={() => deleteTelephoneConfirm(rowData)}
        />
      </div>
    );
  };

  const emailActionBodyTemplate = (rowData: UpdateEmailDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          tooltip="Düzenle"
          outlined
          className="mr-2"
          onClick={() => {
            setEmail(rowData);
            setVisibleUpsertEmailDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Sil"
          rounded
          outlined
          severity="danger"
          onClick={() => deleteEmailConfirm(rowData)}
        />
      </div>
    );
  };

  const deleteEmailConfirm = (rowData: UpdateEmailDto) => {
    confirmDialog({
      message: "E-postayı silmek istediğinize emin misiniz?",
      header: "E-posta Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => removeEmail(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const removeEmail = (rowData: UpdateEmailDto) => {
    var epostalar = [...formik.values.epostalar];
    var index = formik.values.epostalar.findIndex((x) => x.id === rowData.id);
    epostalar.splice(index, 1);
    formik.setFieldValue("epostalar", [...epostalar]);
  };

  const removeAddress = (rowData: UpdateAddressDto) => {
    var addresses = [...formik.values.adresler];
    var index = formik.values.adresler.findIndex((x) => x.id === rowData.id);
    addresses.splice(index, 1);
    formik.setFieldValue("adresler", [...addresses]);
  };

  const deleteAddressConfirm = (rowData: UpdateAddressDto) => {
    confirmDialog({
      message: "Adresi silmek istediğinize emin misiniz?",
      header: "Adres Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => removeAddress(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const removeTelephone = (rowData: UpdateTelephoneDto) => {
    var telephones = [...formik.values.telefonlar];
    var index = formik.values.telefonlar.findIndex((x) => x.id === rowData.id);
    telephones.splice(index, 1);
    formik.setFieldValue("telefonlar", [...telephones]);
  };

  const deleteTelephoneConfirm = (rowData: UpdateTelephoneDto) => {
    confirmDialog({
      message: "Telefonu silmek istediğinize emin misiniz?",
      header: "Telefon Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => removeTelephone(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  return (
    <>
      <Dialog
        visible={visible}
        modal={true}
        header={formik.values.id === 0 ? "Müvekkil Ekle" : "Müvekkil Güncelle"}
        onHide={() => {
          setVisible(false);
          formik.resetForm();
          setMuvekkil(undefined);
        }}
        footer={
          <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
            <Button
              label={"İptal"}
              className="md:px-6 justify-content-center"
              type="button"
              severity="secondary"
              onClick={() => {
                setVisible(false);
                formik.resetForm();
                setMuvekkil(undefined);
              }}
            />
            <Button label={"Kaydet"} className="md:px-6 justify-content-center" onClick={() => formik.submitForm()} />
          </div>
        }
        className=""
        style={{ width: "75vw" }}
      >
        <div className="grid mt-1">
          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="tip">
              Tip
            </label>
            <Dropdown
              id="tip"
              name="tip"
              value={formik.values.tip}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("tip", e.value);
              }}
              options={DropdownDatas.GetMuvekkilType()}
              optionLabel="label"
              optionValue="value"
              placeholder="Tip Seçiniz"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "tip")
              })}
            />
            {FormikValueIsValid(formik, "tip") && <div className="p-error mt-3">{formik.errors.tip}</div>}
          </div>
          <div className="col-12"></div>

          {formik.values.tip === MuvekkilTipi.Gercek && (
            <>
              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="adSoyad">
                  Ad Soyad
                </label>
                <InputText
                  id="adSoyad"
                  name="adSoyad"
                  value={formik.values.adSoyad}
                  onChange={(e) => {
                    formik.setFieldValue("adSoyad", e.target.value.toLocaleUpperCase("tr"));
                  }}
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "adSoyad")
                  })}
                />
                {FormikValueIsValid(formik, "adSoyad") && <div className="p-error mt-3">{formik.errors.adSoyad}</div>}
              </div>

              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="tcNo">
                  TC Kimlik Numarası
                </label>
                <InputText
                  id="tcNo"
                  name="tcNo"
                  keyfilter="int"
                  maxLength={11}
                  value={formik.values.tcNo}
                  onChange={(e) => {
                    formik.setFieldValue("tcNo", e.target.value);
                  }}
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "tcNo")
                  })}
                />
                {FormikValueIsValid(formik, "tcNo") && <div className="p-error mt-3">{formik.errors.tcNo}</div>}
              </div>
            </>
          )}

          {formik.values.tip === MuvekkilTipi.Tuzel && (
            <>
              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="unvan">
                  Unvan
                </label>
                <InputText
                  id="unvan"
                  name="unvan"
                  value={formik.values.unvan}
                  onChange={(e) => {
                    formik.setFieldValue("unvan", e.target.value.toLocaleUpperCase("tr"));
                  }}
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "unvan")
                  })}
                />
                {FormikValueIsValid(formik, "unvan") && <div className="p-error mt-3">{formik.errors.unvan}</div>}
              </div>

              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="vergiKimlikNumarasi">
                  Vergi Kimlik Numarasi
                </label>
                <InputText
                  id="vergiKimlikNumarasi"
                  name="vergiKimlikNumarasi"
                  value={formik.values.vergiKimlikNumarasi}
                  onChange={(e) => {
                    formik.setFieldValue("vergiKimlikNumarasi", e.target.value.toLocaleUpperCase("tr"));
                  }}
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "vergiKimlikNumarasi")
                  })}
                />
                {FormikValueIsValid(formik, "vergiKimlikNumarasi") && (
                  <div className="p-error mt-3">{formik.errors.vergiKimlikNumarasi}</div>
                )}
              </div>
            </>
          )}

          {formik.values.tip === MuvekkilTipi.Gercek && (
            <>
              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="dogumTarihi">
                  Doğum Tarihi
                </label>
                <Calendar
                  id="dogumTarihi"
                  name="dogumTarihi"
                  maxDate={new Date()}
                  value={formik.values.dogumTarihi}
                  onChange={(e) => {
                    formik.setFieldValue("dogumTarihi", e.value);
                  }}
                  dateFormat="dd/mm/yy"
                  showIcon
                  mask="99/99/9999"
                  locale="tr"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "dogumTarihi")
                  })}
                />
              </div>

              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="babaAdi">
                  Baba Adı
                </label>
                <InputText
                  id="babaAdi"
                  name="babaAdi"
                  value={formik.values.babaAdi}
                  onChange={(e) => {
                    formik.setFieldValue("babaAdi", e.target.value.toLocaleUpperCase("tr"));
                  }}
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "babaAdi")
                  })}
                />
                {FormikValueIsValid(formik, "babaAdi") && <div className="p-error mt-3">{formik.errors.babaAdi}</div>}
              </div>

              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="anaAdi">
                  Ana Adı
                </label>
                <InputText
                  id="anaAdi"
                  name="anaAdi"
                  value={formik.values.anaAdi}
                  onChange={(e) => {
                    formik.setFieldValue("anaAdi", e.target.value.toLocaleUpperCase("tr"));
                  }}
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "anaAdi")
                  })}
                />
                {FormikValueIsValid(formik, "anaAdi") && <div className="p-error mt-3">{formik.errors.anaAdi}</div>}
              </div>

              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="cinsiyet">
                  Cinsiyet
                </label>
                <Dropdown
                  id="cinsiyet"
                  name="cinsiyet"
                  value={formik.values.cinsiyet}
                  onChange={(e: DropdownChangeEvent) => {
                    formik.setFieldValue("cinsiyet", e.value);
                  }}
                  showClear
                  options={DropdownDatas.GetCinsiyet()}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Cinsiyet Seçiniz"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "cinsiyet")
                  })}
                />
                {FormikValueIsValid(formik, "cinsiyet") && <div className="p-error mt-3">{formik.errors.cinsiyet}</div>}
              </div>

              <div className="col-12 lg:col-6">
                <label className="text-primary" htmlFor="vergiKimlikNumarasi">
                  Vergi Kimlik Numarasi
                </label>
                <InputText
                  id="vergiKimlikNumarasi"
                  name="vergiKimlikNumarasi"
                  value={formik.values.vergiKimlikNumarasi}
                  keyfilter={"int"}
                  onChange={(e) => {
                    formik.setFieldValue("vergiKimlikNumarasi", e.target.value);
                  }}
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "vergiKimlikNumarasi")
                  })}
                />
                {FormikValueIsValid(formik, "vergiKimlikNumarasi") && (
                  <div className="p-error mt-3">{formik.errors.vergiKimlikNumarasi}</div>
                )}
              </div>
            </>
          )}

          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="vergiDairesi">
              Vergi Dairesi
            </label>
            <InputText
              id="vergiDairesi"
              name="vergiDairesi"
              value={formik.values.vergiDairesi}
              onChange={(e) => {
                formik.setFieldValue("vergiDairesi", e.target.value.toLocaleUpperCase("tr"));
              }}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "vergiDairesi")
              })}
            />
            {FormikValueIsValid(formik, "vergiDairesi") && (
              <div className="p-error mt-3">{formik.errors.vergiDairesi}</div>
            )}
          </div>

          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="mersisNo">
              Mersis Numarası
            </label>
            <InputText
              id="mersisNo"
              name="mersisNo"
              value={formik.values.mersisNo}
              keyfilter={"int"}
              onChange={(e) => {
                formik.setFieldValue("mersisNo", e.target.value);
              }}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "mersisNo")
              })}
            />
            {FormikValueIsValid(formik, "mersisNo") && <div className="p-error mt-3">{formik.errors.mersisNo}</div>}
          </div>

          <div className="col-12 lg:col-6">
            <label className="text-primary" htmlFor="vekaletBilgisi">
              Vekalet Bilgisi
            </label>
            <InputText
              id="vekaletBilgisi"
              name="vekaletBilgisi"
              value={formik.values.vekaletBilgisi}
              onChange={(e) => {
                formik.setFieldValue("vekaletBilgisi", e.target.value);
              }}
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "vekaletBilgisi")
              })}
            />
            {FormikValueIsValid(formik, "vekaletBilgisi") && (
              <div className="p-error mt-3">{formik.errors.vekaletBilgisi}</div>
            )}
          </div>

          <div className="col-12 mt-3">
            <DataTable
              showGridlines
              ref={dtAdresses}
              value={formik.values.adresler}
              dataKey="id"
              columnResizeMode="expand"
              header={
                <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                  <h4 className="m-0">Adresler</h4>
                  <Button
                    label="Yeni Adres"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={() => {
                      setAddress(undefined);
                      setVisibleUpsertAddressDialog(true);
                    }}
                  />
                </div>
              }
            >
              <Column body={addressActionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
              <Column
                field="il"
                header="İl"
                sortable
                body={(rowData: AddressDto) => {
                  return <span>{rowData.il?.ilText}</span>;
                }}
              ></Column>
              <Column
                field="ilce"
                header="İlçe"
                sortable
                body={(rowData: AddressDto) => {
                  return <span>{rowData.ilce?.ilceText}</span>;
                }}
              ></Column>
              <Column field="address" header="Adres" sortable></Column>
              <Column field="tip" header="Tip" sortable></Column>
              <Column
                field="mernisMi"
                header="Mernis Mi?"
                body={(rowData: AddressDto) => {
                  return <span>{rowData.mernisMi ? "Evet" : "Hayır"}</span>;
                }}
              ></Column>
              <Column
                field="tasinmaTarihi"
                header="Taşınma Tarihi"
                body={(rowData: AddressDto) => {
                  return (
                    <span>{rowData.tasinmaTarihi ? new Date(rowData.tasinmaTarihi).toLocaleDateString() : ""}</span>
                  );
                }}
                sortable
              ></Column>
              <Column
                field="tescilTarihi"
                header="Tescil Tarihi"
                body={(rowData: AddressDto) => {
                  return <span>{rowData.tescilTarihi ? new Date(rowData.tescilTarihi).toLocaleDateString() : ""}</span>;
                }}
                sortable
              ></Column>
              <Column field="aciklama" header="Açıklama" sortable></Column>
            </DataTable>
          </div>

          <div className="col-12 mt-3">
            <DataTable
              showGridlines
              ref={dtTelephones}
              value={formik.values.telefonlar}
              dataKey="id"
              columnResizeMode="expand"
              header={
                <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                  <h4 className="m-0">Telefonlar</h4>
                  <Button
                    label="Yeni Telefon"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={() => {
                      setTelephone(undefined);
                      setVisibleUpsertTelephoneDialog(true);
                    }}
                  />
                </div>
              }
            >
              <Column body={telephoneActionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
              <Column field="numara" header="Numara" sortable></Column>
              <Column
                field="sahibi"
                header="Sahibi"
                sortable
                body={(rowData: TelephoneDto) => {
                  return <span>{DropdownDatas.GetTelefonSahibi().find((x) => x.value === rowData.sahibi)?.label}</span>;
                }}
              ></Column>
              <Column field="aciklama" header="Açıklama" sortable></Column>
              <Column
                field="mernisMi"
                header="İletişim Onayı Var Mı?"
                body={(rowData: UpdateTelephoneDto) => {
                  return <span>{rowData.iletisimOnayiVarMi ? "Evet" : "Hayır"}</span>;
                }}
              ></Column>
            </DataTable>
          </div>

          <div className="col-12 mt-3">
            <DataTable
              showGridlines
              ref={dtEmails}
              value={formik.values.epostalar}
              dataKey="id"
              columnResizeMode="expand"
              header={
                <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                  <h4 className="m-0">E-postalar</h4>
                  <Button
                    label="Yeni Eposta"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={() => {
                      setEmail(undefined);
                      setVisibleUpsertEmailDialog(true);
                    }}
                  />
                </div>
              }
            >
              <Column body={emailActionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
              <Column field="epostaAdresi" header="E-posta Adresi" sortable></Column>
              <Column
                field="iletisim"
                header="İletişim izni var mı?"
                sortable
                body={(rowData: UpdateEmailDto) => {
                  return <span>{rowData.iletisim === true ? "Evet" : "Hayır"}</span>;
                }}
              ></Column>
              <Column field="aciklama" header="Açıklama" sortable></Column>
            </DataTable>
          </div>
        </div>
      </Dialog>

      <UpsertAddress
        visible={visibleUpsertAddressDialog}
        setVisible={setVisibleUpsertAddressDialog}
        address={address}
        onUpsert={(address: AddressDto) => {
          var addresses = [...formik.values.adresler];

          if (address.id === 0) {
            var leastId = -1;
            addresses.forEach((x) => {
              if (x.id < leastId) {
                leastId = x.id;
              }
            });

            address.id = leastId - 1;
            formik.setFieldValue("adresler", [...formik.values.adresler, address]);
          } else {
            var index = addresses.findIndex((x) => x.id === address.id);
            addresses[index] = address;
            formik.setFieldValue("adresler", [...addresses]);
          }
        }}
      ></UpsertAddress>

      <UpsertTelephone
        visible={visibleUpsertTelephoneDialog}
        setVisible={setVisibleUpsertTelephoneDialog}
        telephone={telephone}
        onUpsert={(telephone: UpdateTelephoneDto) => {
          var telephones = [...formik.values.telefonlar];

          if (telephone.id === 0) {
            var leastId = -1;
            telephones.forEach((x) => {
              if (x.id < leastId) {
                leastId = x.id;
              }
            });

            telephone.id = leastId - 1;
            formik.setFieldValue("telefonlar", [...formik.values.telefonlar, telephone]);
          } else {
            var index = telephones.findIndex((x) => x.id === telephone.id);
            telephones[index] = telephone;
            formik.setFieldValue("telefonlar", [...telephones]);
          }
        }}
      ></UpsertTelephone>

      <UpsertEmail
        visible={visibleUpsertEmailDialog}
        setVisible={setVisibleUpsertEmailDialog}
        email={email}
        onUpsert={(email: UpdateEmailDto) => {
          var emails = [...formik.values.epostalar];

          if (email.id === 0) {
            var leastId = -1;
            emails.forEach((x) => {
              if (x.id < leastId) {
                leastId = x.id;
              }
            });

            email.id = leastId - 1;
            formik.setFieldValue("epostalar", [...formik.values.epostalar, email]);
          } else {
            var index = emails.findIndex((x) => x.id === email.id);
            emails[index] = email;
            formik.setFieldValue("epostalar", [...emails]);
          }
        }}
      ></UpsertEmail>
    </>
  );
};

export default UpsertMuvekkil;
