import SorguBilgileri from "../SorguBilgileri";
import { useHub } from "../../../../../utils/buro-hub";
import uyapService from "../../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../../../layout/context/layoutcontext";

const KamuEmeklisi = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: KamuEmeklisiResponse,
    refetch: refetchKamuEmeklisi,
    isLoading: kamuEmeklisiResponseLoading
  } = useQuery({
    queryKey: ["KamuEmeklisi", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetKamuEmekliBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(kamuEmeklisiResponseLoading)
  }, [kamuEmeklisiResponseLoading])

  return (
    <div>
      {KamuEmeklisiResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {KamuEmeklisiResponse.error}</div>}

      {KamuEmeklisiResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={KamuEmeklisiResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={KamuEmeklisiResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={KamuEmeklisiResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={KamuEmeklisiResponse?.result?.sorgulayanBilgileri}
          />

          {KamuEmeklisiResponse?.result?.sorguSonucDVO?.hataKodu != 0 ? (
            <div className="card p-2 lg:p-5 mt-3">
              {KamuEmeklisiResponse?.result?.sorguSonucDVO?.hataMesaji.toString()}
            </div>
          ) : (
            <div className="card p-1 lg:p-5mt-3">
              <div className="grid">
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Emekli Sicil No</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.sicilNo}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Aylık Başlama Tarihi</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.aylikBaslamaTarihi}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Ünvan</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.unvan}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Derece</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.derece}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Yakınlık</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.yakinlik}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Kademe</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.kademe}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Banka Adı</div>
                    <div>{` : ${""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Banka İli</div>
                    <div>{` : ${""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Şube Adı</div>
                    <div>{` : ${""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Aylık Payda</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.aylikOraniPayda}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Aylık Pay</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.aylikOraniPay}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Aylık Durum</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.aylikDurumu}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Aylık Tur</div>
                    <div>{` : ${""}`}</div>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex gap-3">
                    <div className="font-bold w-12rem">Aylık Periyot</div>
                    <div>{` : ${KamuEmeklisiResponse?.result?.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0]?.aylikPeriyot}`}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KamuEmeklisi;
