import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  AlacakKalemiDto,
  CreateAlacakKalemiDto,
  UpdateAlacakKalemiDto,
} from "./alacak-kalemi.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<AlacakKalemiDto>(`AlacakKalemi/Get/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<AlacakKalemiDto[]>(`AlacakKalemi/GetAll`, config);
  },
  GetAllByIcraFoy(
    icraFoyId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<AlacakKalemiDto[]>(`AlacakKalemi/GetAllByIcraFoy/${icraFoyId}`, config);
  },
  Create(
    dto: CreateAlacakKalemiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<AlacakKalemiDto>("AlacakKalemi/Create", dto, config);
  },
  Update(
    dto: UpdateAlacakKalemiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<AlacakKalemiDto>("AlacakKalemi/Update", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`AlacakKalemi/Delete/${id}`, config);
  },
};

export default services;
