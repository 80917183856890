import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { CreateSubUserDto, UpdateSubUserDto, UserDto } from "../account/account.dto";
import { NoContentDto } from "../base/base.dto";
import {
  AppLogDto,
  LicenseCreateDto,
  LicenseUpdateDto,
  OrderWithUserDto,
  UpdatedUserDto,
  UpdateMainUserDto
} from "./admin.dto";
import { SupportDto } from "../support/support.dto";

const services = {
  GetMainUser(id: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<UpdatedUserDto>(`Admin/GetMainUser/${id}`, config);
  },
  GetAllMainUsers(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<UserDto[]>(`Admin/GetAllMainUsers`, config);
  },
  UpdateMainUser(dto: UpdateMainUserDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<NoContentDto>("Admin/UpdateMainUser", dto, config);
  },
  ActivateUser(id: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>(`Admin/ActivateUser/${id}`, config);
  },
  DeactivateUser(id: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>(`Admin/DeactivateUser/${id}`, config);
  },
  GetAllAppLogs(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<AppLogDto[]>(`Admin/GetAllAppLogs`, config);
  },
  CreateSubUser(dto: CreateSubUserDto, id: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<UserDto>(`Admin/CreateSubUser/${id}`, dto, config);
  },
  UpdateSubUser(dto: UpdateSubUserDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<UserDto>("Admin/UpdateSubUser", dto, config);
  },
  GetAllSupport(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<SupportDto[]>("Admin/GetAllSupport", config);
  },
  GetSupport(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<SupportDto>(`Admin/GetSupport/${id}`, config);
  },
  ReplySupportMessage(dto: FormData, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<SupportDto[]>("Admin/ReplySupportMessage", dto, config);
  },
  CloseSupport(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<NoContentDto>(`Admin/CloseSupport/${id}`, { supportId: id }, config);
  },
  CreateLicense(dto: LicenseCreateDto, id: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<UserDto>(`Admin/CreateLicense/${id}`, dto, config);
  },
  UpdateLicense(dto: LicenseUpdateDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<UserDto>("Admin/UpdateLicense", dto, config);
  },
  GetAllOrder(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<OrderWithUserDto[]>(`Admin/GetAllOrder`, config);
  }
};

export default services;
