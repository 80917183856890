import React, { useEffect, useRef, useState } from "react";
import { AppLicenseDto } from "../../services/admin/admin.dto";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";
import { Button } from "primereact/button";
import LicenseUpdate from "./LicenseUpdate";
import { Toolbar } from "primereact/toolbar";

const License = (props: { licenses?: AppLicenseDto[]; refetchMainUsers: () => void; mainUserId?: string }) => {
  const [license, setLicense] = useState<AppLicenseDto>();
  const [visibleUpsertLicenseDialog, setVisibleUpsertLicenseDialog] = useState<boolean>(false);
  const dt = useRef<DataTable<AppLicenseDto[]>>(null);
  const [filtersLicense, setFiltersLicense] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValueLicense, setGlobalFilterValueLicense] = useState<string>("");

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Lisans Ekle"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertLicenseDialog(true);
          }}
        />
      </div>
    );
  };

  const actionBodyTemplateLicense = (rowData: AppLicenseDto) => {
    return (
      <div className="flex justify-content-center">
        {/* <Button
          icon="pi pi-ban"
          rounded
          outlined
          tooltip="Pasif Yap"
          severity="info"
          className="mr-2"
          onClick={() => {}}
        /> */}
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setLicense(rowData);
            setVisibleUpsertLicenseDialog(true);
          }}
        />
        {/* <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {}}
        /> */}
      </div>
    );
  };

  const onGlobalFilterChangeLicense = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filtersLicense };
    // @ts-ignore
    _filters["global"].value = value;
    setFiltersLicense(_filters);
    setGlobalFilterValueLicense(value);
  };

  const clearFilterLicense = () => {
    initFiltersLicense();
  };

  const initFiltersLicense = () => {
    setFiltersLicense({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: commonFilterContains,
      id: commonFilterContains,
      isActive: commonFilterContains,
      startDate: commonFilterDateIs,
      endDate: commonFilterDateIs
    });
    setGlobalFilterValueLicense("");
  };

  useEffect(() => {
    initFiltersLicense();
  }, []);

  return (
    <div>
      <Toolbar className="mb-4" start={leftToolbarTemplate}></Toolbar>
      <DataTable
        ref={dt}
        value={props.licenses}
        dataKey="id"
        showGridlines
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Toplam {totalRecords} lisans"
        filters={filtersLicense}
        globalFilterFields={["name", "id", "isActive", "startDate", "endDate"]}
        emptyMessage="Lisans bulunmamaktadır."
        header={CreateHeader(
          "Lisanslar",
          globalFilterValueLicense,
          (e) => onGlobalFilterChangeLicense(e),
          clearFilterLicense
        )}
        columnResizeMode="expand"
      >
        <Column body={actionBodyTemplateLicense} exportable={false} style={{ width: "9em" }}></Column>
        <Column
          field="name"
          header="Lisans Adı"
          sortable
          filterField="name"
          filter
          filterPlaceholder="Kullanıcı adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Kullanıcı adına göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="isActive"
          header="Durum"
          sortable
          body={(rowData) => (
            <div className="flex justify-content-center align-items-center p-0 m-0">
              {rowData.isActive === true ? (
                <div className="p-0 flex align-items-center">
                  <div className="px-3 py-2 bg-green-100 text-green-900 border-round font-medium text-center">
                    {`AKTİF`}
                  </div>
                </div>
              ) : (
                <div className="p-0 flex align-items-center">
                  <div className="px-3 py-2 bg-blue-100 text-blue-900 border-round font-medium text-center">
                    {`PASİF`}
                  </div>
                </div>
              )}
            </div>
          )}
          filterField="isActive"
          filter
          filterPlaceholder="Kullanıcı adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Kullanıcı adına göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="startDate"
          header="Başlangıç Tarihi"
          sortable
          body={(rowData) => (
            <span>{rowData.startDate ? moment(rowData.startDate).format(process.env.REACT_APP_DATE_FORMAT) : ""}</span>
          )}
          filterField="startDate"
          filter
          filterPlaceholder="Başlangıç Tarihine göre arayın"
          filterElement={Filter.DateFilterTemplate}
          filterMatchMode={FilterMatchMode.DATE_IS}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Başlangıç Tarihine göre filtreleyin</div>;
          }}
        />
        <Column
          field="endDate"
          header="Bitiş Tarihi"
          sortable
          body={(rowData) => <span>{new Date(rowData.endDate).toLocaleDateString("tr-TR")}</span>}
          filterField="endDate"
          filter
          filterPlaceholder="Bitiş Tarihine göre arayın"
          filterElement={Filter.DateFilterTemplate}
          filterMatchMode={FilterMatchMode.DATE_IS}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Bitiş Tarihine göre filtreleyin</div>;
          }}
        />
      </DataTable>

      <LicenseUpdate
        visible={visibleUpsertLicenseDialog}
        setVisible={setVisibleUpsertLicenseDialog}
        license={license}
        setLicense={setLicense}
        refetchMainUsers={props.refetchMainUsers}
        mainUserId={props.mainUserId}
      />
    </div>
  );
};

export default License;
