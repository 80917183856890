import { Galleria } from "primereact/galleria";
import { useRef, useState } from "react";
import { SupportMessagePhotoDto } from "../../../services/support/support-message-photo.dto";

const FileGaleria = (props: {
    files: SupportMessagePhotoDto[]
}) => {
    const { files } = props
    const galleria = useRef<Galleria>(null)

    const [activeFileIndex, setActiveFileIndex] = useState<number>(0);
    const itemTemplate = (item: any) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item: any) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    }
    return (
        <>
            <Galleria ref={galleria} value={files.map(imgFile => ({
                itemImageSrc: process.env.REACT_APP_BASE_URL + 'support-images/' + imgFile.id + imgFile.extension,
                thumbnailImageSrc: process.env.REACT_APP_BASE_URL + 'support-images/' + imgFile.id + imgFile.extension
            }))} numVisible={5} style={{ maxWidth: '100%' }}
                activeIndex={activeFileIndex} onItemChange={(e) => setActiveFileIndex(e.index)}
                circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
            <div className="flex justify-content-start" style={{ maxWidth: '100%' }}>
                {
                    files.map((file, index) => {
                        let imgEl = <img src={process.env.REACT_APP_BASE_URL + 'support-images/' + file.id + file.extension}
                            style={{ cursor: 'pointer', width: "45px", height: "auto", borderRadius: "3px" }}
                            onClick={() => { setActiveFileIndex(index); galleria.current?.show() }
                            } />
                        return (
                            <div className="p-2" key={index}>
                                {imgEl}
                            </div>
                        )
                    })
                }
            </div></>
    )
}

export default FileGaleria