import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  TahsilatBilgisiDto,
  CreateTahsilatBilgisiDto,
  UpdateTahsilatBilgisiDto,
} from "./tahsilat-bilgisi.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TahsilatBilgisiDto>(`TahsilatBilgisi/Get/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TahsilatBilgisiDto[]>(`TahsilatBilgisi/GetAll`, config);
  },
  GetAllByIcraFoy(
    icraFoyId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<TahsilatBilgisiDto[]>(
      `TahsilatBilgisi/GetAllByIcraFoy/${icraFoyId}`,
      config
    );
  },
  Create(
    dto: CreateTahsilatBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TahsilatBilgisiDto>("TahsilatBilgisi/Create", dto, config);
  },
  Update(
    dto: UpdateTahsilatBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<TahsilatBilgisiDto>("TahsilatBilgisi/Update", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`TahsilatBilgisi/Delete/${id}`, config);
  },
};

export default services;
