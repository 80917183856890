import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { IlDto, IlceDto } from "./ilce-il.sto";

const services = {
  GetAllIl(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<IlDto[]>(`IlceIl/GetAllIl`, config);
  },
  GetAllIlce(ilId: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<IlceDto[]>(`IlceIl/GetAllIlce/${ilId}`, config);
  },
};

export default services;
