import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  dateModel,
  DosyaSorguDurum,
  TopluSorguDurum,
  TopluSorgulanacakDosyaDTO,
  TopluSorguModel,
  TopluSorguOrtakModel,
  TopluSorguTur
} from "../pages/toplu-sorgulama/TopluSorgu.dto";
import { useToast } from "./toast";
import moment from "moment";
import uyapService from "../services/uyap-sorgulari/uyap-sorgulari.service";
import { DosyaDTO, RequestDosyaTarafDTO, TarafResponseDTO } from "../services/uyap-sorgulari/uyap-sorgulari.dto";
import { useHub } from "./buro-hub";
import { useElectron } from "./electron-connection";

interface TopluSorguIslemlerModel {
  startTopluSorgu: (topluSorguModel: TopluSorguModel) => Promise<void>;
  topluSorguModel: TopluSorguModel;
  topluSorguYapiliyorMu: boolean;
  topluSorguYapilan: number;
  totalTopluSorgu: number;
}

let TopluSorguIslemlerContext = createContext<TopluSorguIslemlerModel>(null!);

export const useTopluSorguIslemler = () => {
  return useContext(TopluSorguIslemlerContext);
};

const delayTime: number = 2000;

export const TopluSorguIslemlerProvider = ({ children }: { children: React.ReactNode }) => {
  const toast = useToast();
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const hub = useHub();
  const electron = useElectron();
  const tamamlananTopluSorguSayisi = useRef<number>(0);
  const totalTopluSorgu = useRef<number>(0);
  const [topluSorguYapiliyorMu, setTopluSorguYapiliyorMu] = useState<boolean>(false);
  const topluSorguModel = useRef<TopluSorguModel>({
    key: 0,
    tabs: [1],
    listName: "",
    baslamaTarihi: null,
    status: TopluSorguDurum.BASLATILMADI,
    sorgulanacakDosyalar: [],
    sorunluVeOlumsuzListeleri: [],
    tahsilatKalaniListeleri: [],
    mernisListeleri: [],
    mersisListeleri: [],
    iskiListeleri: [],
    gibListeleri: [],
    gsmListeleri: [],
    disIsleriListeleri: [],
    sskCalisaniListeleri: [],
    sskEmeklisiListeleri: [],
    kamuCalisaniListeleri: [],
    kamuEmeklisiListeleri: [],
    bagkurCalisaniListeleri: [],
    bagkurEmeklisiListeleri: [],
    sgkIsyeriListeleri: [],
    egmListeleri: [],
    postaCekiListeleri: [],
    takbisListeleri: [],
    sgkHacizListeleri: [],
    icraDosyasiListeleri: [],
    bankaListeleri: [],
    tarafListeleri: []
  });
  const [abc, setAbc] = useState<string>("Test123");

  const addOlumsuzSorguSonucu = (topluSorguOrtakModel: TopluSorguOrtakModel, sorguTuru: string, aciklama: string) => {
    const newList = topluSorguModel.current.sorunluVeOlumsuzListeleri || [];
    newList.push({
      ortakDegerler: topluSorguOrtakModel,
      sorguTuru: sorguTuru,
      aciklama: aciklama
    });

    topluSorguModel.current.sorunluVeOlumsuzListeleri = newList;
  };

  const dateStringTransform = (dateString: dateModel): Date | null => {
    if (
      dateString.yil !== null &&
      dateString.ay !== null &&
      dateString.gun !== null &&
      dateString.yil > 0 &&
      dateString.gun > 0 &&
      dateString.ay > 0 &&
      dateString.ay < 13 &&
      dateString.gun < 32
    ) {
      const year = dateString.yil;
      const month = dateString.ay - 1; // JavaScript'te aylar 0'dan başlar
      const day = dateString.gun;
      const date = new Date(year, month, day);
      return isNaN(date.getTime()) ? null : date;
    } else {
      return null;
    }
  };

  function dateCheckAndParse(dateString: string | undefined, dateFormat: string): Date | undefined {
    return dateString && moment(dateString, dateFormat).isValid() ? moment(dateString, dateFormat).toDate() : undefined;
  }

  const startTopluSorgu = async (topluSorguModelParamerte: TopluSorguModel): Promise<void> => {
    setTopluSorguYapiliyorMu(true);

    const topluSorguSonuclarListJson: TopluSorguModel[] = (await electron.getTopluSorguSonuclarListesi()) ?? [];

    toast.show("Toplu Sorgu Başlatıldı", "success");
    topluSorguModelParamerte.status = TopluSorguDurum.DEVAM_EDİYOR;
    topluSorguModelParamerte.baslamaTarihi = moment().toDate();

    topluSorguModel.current = topluSorguModelParamerte;

    tamamlananTopluSorguSayisi.current = topluSorguModel.current.sorgulanacakDosyalar.filter(
      (liste) => liste.isSorgulandi !== DosyaSorguDurum.SORGULANMADI
    ).length;
    totalTopluSorgu.current = topluSorguModel.current.sorgulanacakDosyalar.length;

    for await (const dosya of topluSorguModel.current.sorgulanacakDosyalar || []) {
      setAbc("Test456");
      let ortakDegerler = {
        birimAdi: dosya.birimAdi,
        dosyaNo: dosya.dosyaNo,
        alacakliAdi: "",
        borcluAdi: "",
        borcluTCVKNo: "",
        dosyaDurumu: dosya.dosyaDurum
      };
      let updatedDosyalar: TopluSorgulanacakDosyaDTO[] = [];

      try {
        //Eğer dosya sorgulanmış ise tekrar sorgulama yapma
        if (dosya.isSorgulandi !== DosyaSorguDurum.SORGULANMADI) {
          continue;
        }
        //Esas no ve yıla göre Dosya Id'yi bulma
        let dosyaBilgileriSorgu = await uyapService.GetDosyalarDetayli({
          dosyaDurumKod: dosya.dosyaDurumKod,
          pageNumber: 1,
          pageSize: 500,
          dosyaYil: parseInt(dosya.dosyaNo.split("/")[0]),
          dosyaSira: dosya.dosyaNo.split("/")[1],
          birimTuru2: dosya.birimTuru2,
          birimTuru3: dosya.birimTuru3
        } as RequestDosyaTarafDTO);
        await delay(delayTime);

        // if (dosyaBilgileriSorgu?.result?.[1] === 0) {
        //   addOlumsuzSorguSonucu(ortakDegerler, "DOSYA ID SORGUSU", "Dosya Bulunamadı");
        //   continue;
        // }

        //await delay(delayTime);
        if (dosyaBilgileriSorgu && dosyaBilgileriSorgu.result && dosyaBilgileriSorgu.result[1] === 1) {
          const dosyaBilgileri: DosyaDTO = dosyaBilgileriSorgu.result[0][0];
          const dosyaId = dosyaBilgileri.dosyaId;

          //Dosya Id'ye göre taraf bilgileri getirme
          let tarafBilgileri = await uyapService.GetTarafBilgileri(dosyaId);
          await delay(delayTime);
          let alacakliAdi: string;
          if (!tarafBilgileri || !tarafBilgileri.result) {
            addOlumsuzSorguSonucu(ortakDegerler, "DOSYA TARAF BİLGİLERİ SORGUSU", "Taraf Bilgileri Yok");
            continue;
          }

          const tarafSatiri = tarafBilgileri.result.find((item: TarafResponseDTO) => item.rol === "Alacaklı");
          if (!tarafSatiri) {
            addOlumsuzSorguSonucu(ortakDegerler, "DOSYA TARAF BİLGİLERİ SORGUSU", "Alacaklı Bulunamadı");
            continue;
          }

          let avukatDosyadaAlacakliMi;
          alacakliAdi = tarafSatiri.adi;
          for await (const taraf of tarafBilgileri.result) {
            const vekilAdi = taraf.vekil || "";
            if (taraf.rol === "Alacaklı") {
              avukatDosyadaAlacakliMi = vekilAdi.includes(hub.getBuroSettings().connectedSessionLawyerName);
              if (avukatDosyadaAlacakliMi) {
                break;
              }
            }
          }

          ortakDegerler.alacakliAdi = alacakliAdi;

          //Dosya Id'ye göre borçlu listesini getirme
          let borcluBilgileri = await uyapService.GetBorcluBilgileriListesi(dosyaId);
          await delay(delayTime);
          if (!borcluBilgileri || !borcluBilgileri.result) {
            addOlumsuzSorguSonucu(
              ortakDegerler,
              "BORÇLU BİLGİLERİ SORGUSU",
              borcluBilgileri.error || "Borçlu Bilgileri Yok"
            );
          }

          const borcluAdlarıList: string[] = [];
          const borcluTcVkNoList: string[] = [];
          for await (const borclu of borcluBilgileri.result || []) {
            if (borclu) {
              let kisiKurumId: number = 0;
              let borcluAdi = "";
              let borcluTcVkNo = "";
              if (borclu.kisiTumDVO) {
                kisiKurumId = borclu.kisiKurumId;
                borcluAdi = borclu.kisiTumDVO.adi + " " + borclu.kisiTumDVO.soyadi;
                borcluTcVkNo = borclu.kisiTumDVO.tcKimlikNo;
                borcluAdlarıList.push(borcluAdi);
                borcluTcVkNoList.push(borcluTcVkNo);
              } else if (borclu.kurumDVO) {
                kisiKurumId = borclu.kisiKurumId;
                borcluAdi = borclu.kurumDVO.kurumAdi;
                borcluTcVkNo = borclu.kurumDVO.vergiNo;
                borcluAdlarıList.push(borcluAdi);
                borcluTcVkNoList.push(borcluTcVkNo);
              }

              let ortakDegerler = {
                birimAdi: dosya.birimAdi,
                dosyaNo: dosya.dosyaNo,
                alacakliAdi: alacakliAdi,
                borcluAdi: borcluAdi,
                borcluTCVKNo: borcluTcVkNo,
                dosyaDurumu: dosya.dosyaDurum
              };

              if (!avukatDosyadaAlacakliMi) {
                setAbc("Test789");
                continue;
              }
              for await (const tab of topluSorguModel.current.tabs) {
                setAbc("Test789");

                if (tab === TopluSorguTur.MERNIS) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Mernis", "Kurumlar için Mernis Sorgusu Yapılamaz");
                      continue;
                    }
                    let mernisSonuc = await uyapService.GetMernisBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (
                      !mernisSonuc ||
                      !mernisSonuc.result ||
                      mernisSonuc?.result?.sorguSonucDVO?.mernisAdres?.adresler?.[0]?.AdresTipi === "Bilinmiyor"
                    ) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Mernis", mernisSonuc?.error || "Mernis Sonucu Yok");
                    } else {
                      const mernisVeri = mernisSonuc.result.sorguSonucDVO;
                      const adresler = mernisVeri?.mernisAdres?.adresler?.[0];
                      let adres = "";
                      let il = "";
                      let ilce = "";

                      if (adresler?.ilIlceMerkeziAdresi) {
                        const ilIlce = adresler.ilIlceMerkeziAdresi;
                        adres = `
                                ${ilIlce.mahalle ?? ""}
                                ${ilIlce.csbm ?? ""}
                                ${ilIlce.disKapiNo ? `Dış Kapı No: ${ilIlce.disKapiNo}` : ""}
                                ${ilIlce.icKapiNo ? `İç Kapı No: ${ilIlce.icKapiNo}` : ""}
                            `;
                        il = ilIlce.il ?? "";
                        ilce = ilIlce.ilce ?? "";
                        //   adres = `${mernisVeri.mernisAdres.adresler[0].ilIlceMerkeziAdresi.mahalle}
                        //         ${mernisVeri.mernisAdres.adresler[0].ilIlceMerkeziAdresi.csbm}
                        //         Dış Kapı No: ${mernisVeri.mernisAdres.adresler[0].ilIlceMerkeziAdresi.disKapiNo}
                        //         İç Kapı No: ${mernisVeri.mernisAdres.adresler[0].ilIlceMerkeziAdresi.icKapiNo}`;
                        //   il = mernisVeri.mernisAdres.adresler[0].ilIlceMerkeziAdresi.il;
                        //   ilce = mernisVeri.mernisAdres.adresler[0].ilIlceMerkeziAdresi.ilce;
                      } else if (adresler?.yurtDisiAdresi) {
                        const yurtDisi = adresler.yurtDisiAdresi;
                        adres = yurtDisi.acikAdres ?? "";
                        il = yurtDisi.ulke ?? "";
                        ilce = yurtDisi.sehir ?? "";
                      }

                      topluSorguModel.current.mernisListeleri?.push({
                        ortakDegerler,
                        adres: adres,
                        il: il,
                        ilce: ilce,
                        tescilTarihi: mernisVeri?.mernisAdres?.adresler?.[0]?.tescilTarih
                          ? dateStringTransform(mernisVeri?.mernisAdres?.adresler?.[0]?.tescilTarih)
                          : null,
                        beyanTarihi: mernisVeri?.mernisAdres?.adresler?.[0]?.beyanTarih
                          ? dateStringTransform(mernisVeri?.mernisAdres?.adresler?.[0]?.beyanTarih)
                          : null,
                        tasinmaTarihi: mernisVeri?.mernisAdres?.adresler?.[0]?.tasinmaTarih
                          ? dateStringTransform(mernisVeri?.mernisAdres?.adresler?.[0]?.tasinmaTarih)
                          : null,
                        olumTarihi: null
                      });
                    }
                    //settopluSorguModel.current({ ...topluSorguModel.current });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Mernis", "Mernis Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.MERSIS) {
                  try {
                    if (borclu.kisiTumDVO) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Mersis", "Kişiler için Mersis Sorgusu Yapılamaz");
                      continue;
                    }
                    let mersisSonuc = await uyapService.GetMersisBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!mersisSonuc || !mersisSonuc.result) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Mersis", mersisSonuc?.error || "Mersis Sonucu Yok");
                    } else {
                      topluSorguModel.current.mersisListeleri?.push({
                        ortakDegerler,
                        adres: mersisSonuc?.result?.sorguSonucDVO ?? ""
                      });
                    }
                    //settopluSorguModel.current({ ...topluSorguModel.current });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Mersis", "Mersis Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.ISKI) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(ortakDegerler, "İski", "Kurumlar için İski Sorgusu Yapılamaz");
                      continue;
                    }
                    let iskiSonuc = await uyapService.GetIskiBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!iskiSonuc || !iskiSonuc.result || !iskiSonuc.result.sorguSonucDVO?.adres) {
                      addOlumsuzSorguSonucu(ortakDegerler, "İski", iskiSonuc?.error || "İski Sonucu Yok");
                    } else {
                      topluSorguModel.current.iskiListeleri?.push({
                        ortakDegerler,
                        adres: iskiSonuc.result.sorguSonucDVO?.adres
                      });
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "İski", "İski Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.GIB) {
                  try {
                    let gibSonuc = await uyapService.GetGibBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!gibSonuc || !gibSonuc.result) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Gib", gibSonuc?.error || "Gib Sonucu Yok");
                    } else {
                      const gibVeri = gibSonuc.result.sorguSonucDVO;
                      topluSorguModel.current.gibListeleri?.push({
                        ortakDegerler,
                        adres: `
                        ${gibVeri?.mahalleSemt}
                        ${gibVeri?.caddeSokak}
                        KAPI NO: ${gibVeri?.kapiNo}
                        DAİRE NO: ${gibVeri?.daireNo}
                        ${gibVeri?.ilKodu}
                        ${gibVeri?.ilceAdi}
                        `
                      });
                    }

                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Gib", "Gib Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.GSM) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Gsm", "Kurumlar için Gsm Sorgusu Yapılamaz");
                      continue;
                    }

                    let gsmSonuc = await uyapService.GetGsmBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!gsmSonuc || !gsmSonuc.result) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Gsm", gsmSonuc?.error || "Gsm Sonucu Yok");
                    } else {
                      const processedData =
                        gsmSonuc.result.sorguSonucDVO?.flatMap(
                          (item) =>
                            item.adresBilgileri?.map((adres) => ({
                              operator: item.operatorTuru,
                              adres: adres.adres
                            })) || []
                        ) || [];

                      processedData.forEach((data) => {
                        topluSorguModel.current.gsmListeleri?.push({
                          ortakDegerler,
                          operator: data.operator,
                          adres: data.adres
                        });
                      });
                    }
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Gsm", "Gsm Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.DIS_ISLERI) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Dış İşleri", "Kurumlar için Dış İşleri Sorgusu Yapılamaz");
                      continue;
                    }
                    let disIsleriSonuc = await uyapService.GetDisIsleriBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!disIsleriSonuc || !disIsleriSonuc.result || !disIsleriSonuc.result.sorguSonucDVO) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Dış İşleri",
                        disIsleriSonuc?.error || "Dış İşleri Sonucu Yok"
                      );
                    } else {
                      const disIsleriVeriListesi = disIsleriSonuc.result.sorguSonucDVO;
                      if (disIsleriVeriListesi && disIsleriVeriListesi.length > 0) {
                        disIsleriVeriListesi.forEach((disIsleriVeri) => {
                          topluSorguModel.current.disIsleriListeleri?.push({
                            ortakDegerler,
                            adresTuru: disIsleriVeri.adresTipi?._value_ ?? "",
                            sehir: disIsleriVeri.sehir ?? "",
                            adres: disIsleriVeri.adres ?? "",
                            postaKodu: disIsleriVeri.postaKodu ?? ""
                          });
                        });
                      }
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Dış İşleri", "Dış İşleri Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.SSK_CALISANI) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Ssk Çalışanı",
                        "Kurumlar için SSK Çalışanı Sorgusu Yapılamaz"
                      );
                      continue;
                    }
                    let sskCalisaniSonuc = await uyapService.GetSskCalisanBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!sskCalisaniSonuc || !sskCalisaniSonuc.result) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Ssk Çalışanı",
                        sskCalisaniSonuc?.error || "Ssk Çalışanı Sonucu Yok"
                      );
                    } else if (sskCalisaniSonuc.result.sorguSonucDVO?.sskBilgi?.sonucKodu !== 0) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Ssk Çalışanı",
                        sskCalisaniSonuc?.result?.sorguSonucDVO?.sskBilgiDetay?.sgkIsyeriDVO?.hataMesaji.toString() ||
                          "Kisi bulunamadi."
                      );
                    } else {
                      const sskCalisaniVeri = sskCalisaniSonuc.result?.sorguSonucDVO;
                      topluSorguModel.current.sskCalisaniListeleri?.push({
                        ortakDegerler,
                        isYeri: sskCalisaniVeri?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.isyeriUnvan ?? "",
                        isYeriAdresi: sskCalisaniVeri.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.isyeriAdresi ?? "",
                        isyeriIli: sskCalisaniVeri.sskBilgiDetay?.ilAdi ?? "",
                        isYeriSicil: sskCalisaniVeri.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.isyeriSicil ?? "",
                        donemAy: sskCalisaniVeri.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.donemYilAy ?? "",
                        sgkDurum: sskCalisaniVeri.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.durumKod ?? "",
                        sgkSicilNo: sskCalisaniVeri?.sskBilgi?.tescilKaydi4AList?.[0]?.sicilNo ?? "",
                        ilkIseGirisTarihi: dateCheckAndParse(
                          sskCalisaniVeri?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.ilkIseGirisTarihi ?? undefined,
                          "YYYY-MM-DD"
                        ),
                        istenCıkısTarihi: dateCheckAndParse(
                          sskCalisaniVeri?.sskBilgiDetay?.sgkIsyeriDVO?.sigortaliBilgi?.istenCikisTarihi,
                          "YYYY-MM-DD"
                        )
                      });
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Ssk Çalışan", "Ssk Çalışan Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.SSK_EMEKLISI) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Ssk Emeklisi",
                        "Kurumlar için SSK Emeklisi Sorgusu Yapılamaz"
                      );
                      continue;
                    }
                    let sskEmeklisiSonuc = await uyapService.GetSskEmekliBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!sskEmeklisiSonuc || !sskEmeklisiSonuc.result) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Ssk Emeklisi",
                        sskEmeklisiSonuc?.error || "Ssk Emeklisi Sonucu Yok"
                      );
                    } else {
                      topluSorguModel.current.sskEmeklisiListeleri?.push({
                        ortakDegerler,
                        sonuc: "olumlu sonuç bul ve güncelle"
                      });
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Ssk Emeklisi", "Ssk Emeklisi Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.KAMU_CALISANI) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Kamu Çalışanı",
                        "Kurumlar için Kamu Çalışanı Sorgusu Yapılamaz"
                      );
                      continue;
                    }
                    let kamuCalisaniSonuc = await uyapService.GetKamuCalisanBilgiari(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (
                      !kamuCalisaniSonuc ||
                      !kamuCalisaniSonuc.result ||
                      kamuCalisaniSonuc?.result?.sorguSonucDVO?.hataKodu !== 0
                    ) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Kamu Çalışanı",
                        kamuCalisaniSonuc?.error || "Kamu Çalışanı Sonucu Yok"
                      );
                    } else {
                      const kamuCalisaniVeri = kamuCalisaniSonuc.result.sorguSonucDVO;
                      topluSorguModel.current.kamuCalisaniListeleri?.push({
                        ortakDegerler,
                        kurum: kamuCalisaniVeri.kurum,
                        kurumIl: kamuCalisaniVeri.kurumIl,
                        kurumIlce: kamuCalisaniVeri.kurumIlce,
                        iseBaslamaTarihi: dateCheckAndParse(kamuCalisaniVeri.iseBaslamaTarihi, "YYYY-MM-DD"),
                        unvani: kamuCalisaniVeri.unvani,
                        kurumDurum: kamuCalisaniVeri.durumKapsami4C,
                        aciklama: kamuCalisaniVeri.durumAciklamasi4C
                      });
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Kamu Çalışanı", "Kamu Çalışanı Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.KAMU_EMEKLISI) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Kamu Emeklisi",
                        "Kurumlar için Kamu Emeklisi Sorgusu Yapılamaz"
                      );
                      continue;
                    }
                    let kamuEmeklisiSonuc = await uyapService.GetKamuEmekliBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (
                      !kamuEmeklisiSonuc ||
                      !kamuEmeklisiSonuc.result ||
                      kamuEmeklisiSonuc?.result?.sorguSonucDVO?.hataKodu !== 0
                    ) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Kamu Emeklisi",
                        kamuEmeklisiSonuc?.error || "Kamu Emeklisi Sonucu Yok"
                      );
                    } else {
                      const kamuEmeklisiVeri = kamuEmeklisiSonuc.result.sorguSonucDVO?.emekliKaydi4C?.aylikBilgisi?.[0];
                      topluSorguModel.current.kamuEmeklisiListeleri?.push({
                        ortakDegerler,
                        sicilNo: kamuEmeklisiVeri?.sicilNo ?? "",
                        aylikBaslamaTarihi: dateCheckAndParse(kamuEmeklisiVeri?.aylikBaslamaTarihi, "YYYY-MM-DD"),
                        unvan: kamuEmeklisiVeri?.unvan ?? "",
                        derece: kamuEmeklisiVeri?.derece,
                        yakinlik: kamuEmeklisiVeri?.yakinlik ?? "",
                        kademe: kamuEmeklisiVeri?.kademe,
                        bankaAdi: "", // Boş bırakılan alanlar varsa uygun veriler ile doldurulmalıdır
                        bankaIli: "",
                        subeAdi: "",
                        aylikOraniPayda: kamuEmeklisiVeri?.aylikOraniPayda,
                        aylikOraniPay: kamuEmeklisiVeri?.aylikOraniPay,
                        aylikDurumu: kamuEmeklisiVeri?.aylikDurumu ?? "",
                        aylikTur: "",
                        aylikPeriyot: kamuEmeklisiVeri?.aylikPeriyot ?? ""
                      });
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Kamu Emeklisi", "Kamu Emeklisi Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.BAGKUR_CALISANI) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Bağkur Çalışanı",
                        "Kurumlar için Bağkur Çalışanı Sorgusu Yapılamaz"
                      );
                      continue;
                    }
                    let bagkurCalisaniSonuc = await uyapService.GetBağkurCalisanBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);
                    if (!bagkurCalisaniSonuc || !bagkurCalisaniSonuc.result) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Bağkur Çalışanı",
                        bagkurCalisaniSonuc?.error || "Bağkur Çalışanı Sonucu Yok"
                      );
                    } else if (bagkurCalisaniSonuc?.result?.sorguSonucDVO?.islemSonuc !== 0) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Bağkur Çalışanı",
                        bagkurCalisaniSonuc?.result?.sorguSonucDVO?.anaHataMesaji.toString() ||
                          "Bağkur Çalışanı Sonucu Yok"
                      );
                    } else {
                      const bagkurCalisaniVeri = bagkurCalisaniSonuc.result.sorguSonucDVO?.tescilKaydi4BList?.[0];
                      if (bagkurCalisaniVeri) {
                        topluSorguModel.current.bagkurCalisaniListeleri?.push({
                          ortakDegerler,
                          bagkurNo: bagkurCalisaniVeri.bagNo,
                          tescilTarihi: bagkurCalisaniVeri.tescilTarihi
                            ? moment(bagkurCalisaniVeri.tescilTarihi, "YYYYMMDD").toDate()
                            : undefined,
                          meslekIli: bagkurCalisaniVeri.meslekiIi,
                          meslekIlce: bagkurCalisaniVeri.meslekIlce,
                          terkTarihi: bagkurCalisaniVeri.tescilTarihi
                            ? moment(bagkurCalisaniVeri.terkTarihi, "YYYYMMDD").toDate()
                            : undefined,
                          terkAciklama: bagkurCalisaniVeri.terkAciklama
                        });
                      }
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Bağkur Çalışanı", "Bağkur Çalışanı Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.BAGKUR_EMEKLISI) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Bağkur Emeklisi",
                        "Kurumlar için Bağkur Emeklisi Sorgusu Yapılamaz"
                      );
                      continue;
                    }
                    let bagkurEmeklisiSonuc = await uyapService.GetBagkurEmekliBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!bagkurEmeklisiSonuc || !bagkurEmeklisiSonuc.result) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Bağkur Emeklisi",
                        bagkurEmeklisiSonuc?.error || "Bağkur Emeklisi Sonucu Yok"
                      );
                    } else if (bagkurEmeklisiSonuc?.result?.sorguSonucDVO?.hataKodu !== 0) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Bağkur Çalışanı",
                        bagkurEmeklisiSonuc?.result?.sorguSonucDVO?.hataMesaji.toString() ||
                          "Bağkur Çalışanı Sonucu Yok"
                      );
                    } else {
                      topluSorguModel.current.bagkurEmeklisiListeleri?.push({
                        ortakDegerler,
                        sonuc: "Olumlu sonuc bul"
                      });
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Bağkur Emeklisi", "Bağkur Emeklisi Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.SGK_ISYERI) {
                  try {
                    let sgkIsyeriSonuc = await uyapService.GetIsyeriBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!sgkIsyeriSonuc || !sgkIsyeriSonuc.result) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Sgk İş Yeri",
                        sgkIsyeriSonuc?.error || "Sgk İş Yeri Sonucu Yok"
                      );
                    } else if (sgkIsyeriSonuc.result.sorguSonucDVO?.isYeriBilgi?.hataMesaji) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "İş Yeri",
                        sgkIsyeriSonuc?.result?.sorguSonucDVO?.isYeriBilgi?.hataMesaji || "Sgk İş Yeri Sonucu Yok"
                      );
                    } else {
                      const sgkIsyeriVeriListesi = sgkIsyeriSonuc.result.sorguSonucDVO?.isYeriBilgi?.isyeriList;
                      if (sgkIsyeriVeriListesi && sgkIsyeriVeriListesi.length > 0) {
                        sgkIsyeriVeriListesi.forEach((isYeriVeri) => {
                          const isYeriAdres = [
                            isYeriVeri.ADR_ADRES1,
                            isYeriVeri.ADR_ADRES2,
                            isYeriVeri.ADR_DIS_KAPI,
                            isYeriVeri.ADR_IC_KAPI,
                            isYeriVeri.ILC_ILCEAD,
                            isYeriVeri.ILADI
                          ]
                            .filter(Boolean) // Değer null, undefined veya boşsa filtrele
                            .join(" "); // Kalan değerleri birleştir

                          const formattedAddress = `${isYeriAdres.length > 0 ? `${isYeriAdres}` : ""}`; // Eğer bir adres varsa ekle

                          topluSorguModel.current.sgkIsyeriListeleri?.push({
                            ortakDegerler,
                            eskiSubeKodu: isYeriVeri.TE_ESKI_SUBE_KOD,
                            il: isYeriVeri.TE_ILKOD,
                            isYeriAciklama: "", // Eksik veya boş, gerekirse ekleyin
                            //isYeriAdres: `${isYeriVeri.ADR_ADRES1} ${isYeriVeri.ADR_ADRES2} ${isYeriVeri.ADR_DIS_KAPI} ${isYeriVeri.ADR_IC_KAPI} ${isYeriVeri.ILC_ILCEAD}/${isYeriVeri.ILADI}`,
                            isYeriAdres: formattedAddress,
                            isYeriMerkezAdresi: isYeriVeri.ADR_ADRES2,
                            isYeriSicil: "", // Eksik veya boş, gerekirse ekleyin
                            isYeriUnvani: isYeriVeri.TE_ISYERI_UNVAN,
                            yeniSubeKodu: isYeriVeri.TE_YENI_SUBE_KOD,
                            siraNo: isYeriVeri.TE_SIRANO,
                            adres: `${isYeriVeri.TE_ADRES1} ${isYeriVeri.TE_ADRES2} ${isYeriVeri.TE_ADRES3} ${isYeriVeri.TE_ADRES4}`,
                            mahiyet: isYeriVeri.TE_MAHIYET
                          });
                        });
                      }
                    }

                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Sgk İş Yeri", "Sgk İş Yeri Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.EGM) {
                  try {
                    let egmSonuc = await uyapService.GetAracBilgiler(dosyaId, kisiKurumId, 0);
                    await delay(delayTime);

                    if (!egmSonuc || !egmSonuc.result) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Egm", egmSonuc?.error || "Egm Sorgu Hatası");
                    } else if (egmSonuc.result.sorguSonucDVO?.aracBilgileri?.hatakod !== 0) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Egm",
                        egmSonuc?.result?.sorguSonucDVO?.aracBilgileri?.hataMesaj.toString() || "Egm Araç Kaydı Yok"
                      );
                    } else {
                      const egmVeriListesi = egmSonuc.result.sorguSonucDVO?.aracBilgileri.output;
                      //const egmVeriListesi = egmSonuc.sorguSonucDVO?.aracBilgileri.output;
                      if (egmVeriListesi && egmVeriListesi.length > 0) {
                        for await (const arac of egmVeriListesi) {
                          let egmMahrumiyetSonuc = await uyapService.GetAracMahrumiyetBilgiler(
                            dosyaId,
                            kisiKurumId,
                            arac.plakaSifreli
                          );

                          await delay(delayTime);

                          if (!egmMahrumiyetSonuc || !egmMahrumiyetSonuc.result) {
                            topluSorguModel.current.egmListeleri?.push({
                              ortakDegerler,
                              plaka: arac.plaka,
                              marka: arac.marka,
                              model: arac.model,
                              ticariAd: arac.ticariAd,
                              renk: arac.renk,
                              cins: arac.cins,
                              mahrumiyetSonuc: egmMahrumiyetSonuc.error || "Egm Mahrumiyet sorgu hatası",
                              sonHacizTarihi: undefined,
                              sonYakalamaTarihi: undefined,
                              serhSayisi: 0,
                              lblHaciz: 0,
                              lblYakalama: 0,
                              lblHacizYakalama: 0,
                              lblIhtiyatiHaciz: 0,
                              lblRehin: 0,
                              lblRehninPC: 0,
                              lblMulkiyet: 0,
                              lblIcraSatisSerhi: 0,
                              lblSatisArz: 0,
                              lblKesinSatis: 0,
                              lblIhtedbir: 0,
                              lblPlakaDeg: 0,
                              lblIhtedbir2: 0,
                              lblDiger: 0,
                              lblTrfMen20e: 0,
                              lblOtvMuaf3y: 0,
                              lblIflasSerhi: 0
                            });
                          } else {
                            const egmMahrumiyetVeriListesi = egmMahrumiyetSonuc.result.sorguSonucDVO?.output;

                            let serhSayisi = 0;
                            let lblHaciz = 0;
                            let lblYakalama = 0;
                            let lblHacizYakalama = 0;
                            let lblIhtiyatiHaciz = 0;
                            let lblRehin = 0;
                            let lblRehninPC = 0;
                            let lblMulkiyet = 0;
                            let lblIcraSatisSerhi = 0;
                            let lblSatisaArz = 0;
                            let lblKesinSatis = 0;
                            let lblIhtedbir = 0;
                            let lblPlakaDeg = 0;
                            let lblIhtedbir2 = 0;
                            let lblDiger = 0;
                            let lblTrfMen20e = 0;
                            let lblOtvMuaf3y = 0;
                            let lblIflasSerhi = 0;
                            let sonHacizTarihi: Date | undefined = undefined;
                            let sonYakalamaTarihi: Date | undefined = undefined;

                            //egmMahrumiyetVeriListesi.forEach((mahrumiyetItem) => {
                            for await (const mahrumiyetItem of egmMahrumiyetVeriListesi ?? []) {
                              const status = mahrumiyetItem.takyidatAdi;
                              serhSayisi += 1;
                              let bizimDosya: boolean = false;
                              if (
                                mahrumiyetItem.ekleyenBirim
                                  .toLowerCase()
                                  .includes(ortakDegerler.birimAdi.toLowerCase()) &&
                                mahrumiyetItem.evrakSayisi
                                  .toLowerCase()
                                  .includes(ortakDegerler.dosyaNo.replace("/", "-"))
                              ) {
                                bizimDosya = true;
                              }

                              const itemDate = dateCheckAndParse(mahrumiyetItem.eklemeTarihSaat, "DD-MM-YYYY hh:mm:ss");

                              if (status === "HACİZLİ(H)") {
                                lblHaciz += 1;

                                if (bizimDosya && itemDate) {
                                  if (!sonHacizTarihi || itemDate > sonHacizTarihi) {
                                    sonHacizTarihi = itemDate;
                                  }
                                }
                              } else if (status === "YAKALAMA") {
                                lblYakalama += 1;
                                if (bizimDosya && itemDate) {
                                  if (!sonYakalamaTarihi || itemDate > sonYakalamaTarihi) {
                                    sonYakalamaTarihi = itemDate;
                                  }
                                }
                              } else if (status === "HACİZLİ YAKALAMALI") {
                                lblHacizYakalama += 1;
                              } else if (status === "İHTİYATİ HACİZ") {
                                lblIhtiyatiHaciz += 1;
                              } else if (status === "REHİNLİ (R)") {
                                lblRehin += 1;
                              } else if (status === "REHİNİN PARAYA ÇEVRİLMESİ") {
                                lblRehninPC += 1;
                              } else if (status === "MÜLKİYETLİ (M)") {
                                lblMulkiyet += 1;
                              } else if (status === "İCRA SATIŞ ŞERHİ") {
                                lblIcraSatisSerhi += 1;
                              } else if (status === "SATIŞA ARZ") {
                                lblSatisaArz += 1;
                              } else if (status === "KESİN SATIŞ") {
                                lblKesinSatis += 1;
                              } else if (status === "İH.TEDBİR (T)") {
                                lblIhtedbir += 1;
                              } else if (status === "PLAKA DEĞ.GEREKLİ(ATY40)") {
                                lblPlakaDeg += 1;
                              } else if (status === "İHTİYATİ TEDBİR-2") {
                                lblIhtedbir2 += 1;
                              } else if (status === "DİĞER(D)") {
                                lblDiger += 1;
                              } else if (status === "Trf.Men(20/E)") {
                                lblTrfMen20e += 1;
                              } else if (status === "ÖTV MUAF TİC.TAŞ.SATILAMAZ(3Y)") {
                                lblOtvMuaf3y += 1;
                              } else if (status === "İFLAS ŞERHİ") {
                                lblIflasSerhi += 1;
                              }
                            }

                            const plaka = egmMahrumiyetVeriListesi?.find((veri) => veri.plaka)?.plaka ?? arac.plaka;

                            if (egmMahrumiyetVeriListesi && egmMahrumiyetVeriListesi.length > 0) {
                              topluSorguModel.current.egmListeleri?.push({
                                ortakDegerler,
                                plaka: plaka,
                                marka: arac.marka,
                                model: arac.model,
                                ticariAd: arac.ticariAd,
                                renk: arac.renk,
                                cins: arac.cins,
                                // ekleyenBirim: mahrumiyet.ekleyenBirim,
                                // eklemeTarihi: moment(mahrumiyet.eklemeTarihSaat, "YYYY-MM-DD hh:mm:ss").toDate(),
                                // takyidatAdi: mahrumiyet.takyidatAdi,
                                // kurumAd: mahrumiyet.kurumAd
                                mahrumiyetSonuc: "Egm Mahrumiyet sorgu başarılı",
                                sonHacizTarihi: sonHacizTarihi,
                                sonYakalamaTarihi: sonYakalamaTarihi,
                                serhSayisi: serhSayisi,
                                lblHaciz: lblHaciz,
                                lblYakalama: lblYakalama,
                                lblHacizYakalama: lblHacizYakalama,
                                lblIhtiyatiHaciz: lblIhtiyatiHaciz,
                                lblRehin: lblRehin,
                                lblRehninPC: lblRehninPC,
                                lblMulkiyet: lblMulkiyet,
                                lblIcraSatisSerhi: lblIcraSatisSerhi,
                                lblSatisArz: lblSatisaArz,
                                lblKesinSatis: lblKesinSatis,
                                lblIhtedbir: lblIhtedbir,
                                lblPlakaDeg: lblPlakaDeg,
                                lblIhtedbir2: lblIhtedbir2,
                                lblDiger: lblDiger,
                                lblTrfMen20e: lblTrfMen20e,
                                lblOtvMuaf3y: lblOtvMuaf3y,
                                lblIflasSerhi: lblIflasSerhi
                              });
                            }
                          }
                        }
                      }
                    }
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "EGM", "EGM Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.POSTA_CEKI) {
                  try {
                    let postaCekiSonuc = await uyapService.GetPostaCekiBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!postaCekiSonuc || !postaCekiSonuc.result) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Posta Çeki",
                        postaCekiSonuc?.error || "Posta Çeki Sonucu Yok"
                      );
                    } else if (!postaCekiSonuc?.result?.sorguSonucu) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Posta Çeki", "Posta Çeki Kaydı Yok");
                    } else {
                      topluSorguModel.current.postaCekiListeleri?.push({
                        ortakDegerler,
                        postaCeki: "Posta Çeki Kaydı Var"
                      });
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Posta Çeki", "Posta Çeki Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.TAKBIS) {
                  try {
                    let takbisSonuc = await uyapService.GetTakbisBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);
                    if (!takbisSonuc || !takbisSonuc.result) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Takbis", takbisSonuc?.error || "Takbis Sorgu Hatası");
                    } else if (takbisSonuc.result.hasError) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "Takbis",
                        takbisSonuc.result.sorguSonucDVO?.toString() || "Takbis sonucu bulunamadı."
                      );
                    } else if (takbisSonuc.result.sorguSonucDVO?.length === 0) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Takbis", "Takbis sonucu bulunamadı.");
                    } else {
                      const takbisVeriListesi = takbisSonuc.result.sorguSonucDVO;

                      for await (const takbis of takbisVeriListesi ?? []) {
                        let takbisHisseSonuc = await uyapService.GetTakbisHisseBilgiler(
                          dosyaId,
                          kisiKurumId,
                          takbis.tasinmazDVO?.tasinmazId ?? 0
                        );
                        await delay(delayTime);

                        if (!takbisHisseSonuc || !takbisHisseSonuc.result) {
                          addOlumsuzSorguSonucu(
                            ortakDegerler,
                            "Takbis Hisse",
                            takbisHisseSonuc?.error || "Takbis Hisse Sorgu Hatası"
                          );
                        } else {
                          const takbisHisseVeriListesi = takbisHisseSonuc.result.hisseList;
                          for await (const takbisHisse of takbisHisseVeriListesi) {
                            let takbisMahrumiyetSonuc = await uyapService.GetTakbisMahrumiyetBilgiler(
                              dosyaId,
                              kisiKurumId,
                              takbis?.tasinmazDVO?.tasinmazId ?? 0,
                              takbisHisse.hisseId
                            );
                            await delay(delayTime);
                            if (!takbisMahrumiyetSonuc || !takbisMahrumiyetSonuc.result) {
                              addOlumsuzSorguSonucu(
                                ortakDegerler,
                                "Takbis Mahrumiyet",
                                takbisMahrumiyetSonuc?.error || "Takbis Mahrumiyet Sorgu Hatası"
                              );
                            } else {
                              const dateRegex = /\b\d{2}\/\d{2}\/\d{4}\b/;
                              const takbisMahrumiyetVeriListesi = takbisMahrumiyetSonuc.result.serhList;
                              let serhSayisi = 0;
                              let beyanSayisi = 0;
                              let sonHacizTarihi: Date | undefined = undefined;
                              for await (const takbisMahrumiyet of takbisMahrumiyetVeriListesi ?? []) {
                                if (takbisMahrumiyet.serhBeyanTip === "Serh") {
                                  serhSayisi += 1;
                                  if (
                                    //Bizim dosya kontrolü
                                    takbisMahrumiyet.cumle
                                      .toLowerCase()
                                      .includes(ortakDegerler.birimAdi.toLowerCase()) &&
                                    takbisMahrumiyet.cumle.toLowerCase().includes(ortakDegerler.dosyaNo)
                                  ) {
                                    const match = takbisMahrumiyet.cumle.match(dateRegex);
                                    if (match) {
                                      const tarih = match[0];

                                      const itemDate = dateCheckAndParse(tarih, "DD/MM/YYYY");
                                      if (!sonHacizTarihi || !itemDate || itemDate > sonHacizTarihi) {
                                        sonHacizTarihi = itemDate;
                                      }
                                    }
                                  }
                                } else if (takbisMahrumiyet.serhBeyanTip === "Beyan") {
                                  beyanSayisi += 1;
                                }
                              }
                              if(takbis.tasinmazDVO){
                                topluSorguModel.current.takbisListeleri?.push({
                                  ortakDegerler,
                                  tapuMudurlugu: takbis.tasinmazDVO.kurum,
                                  ilIlce: `${takbis.tasinmazDVO.il} / ${takbis.tasinmazDVO.ilce}`,
                                  mahalle: takbis.tasinmazDVO.mahalle,
                                  vasfi: takbis.tasinmazDVO.nitelik,
                                  yuzolcumu: takbis.yuzolcumu,
                                  mevkii: takbis.tasinmazDVO.mevkii,
                                  adaNo: takbis.tasinmazDVO.ada,
                                  parselNo: takbis.tasinmazDVO.parsel,
                                  hisseAciklama: "",
                                  hisseTip: takbisHisse.hisseTip,
                                  tapuBolumDurum: takbisHisse.tapuBolumDurum,
                                  tasinmazId: takbis.tasinmazDVO.tasinmazId,
                                  hisseId: takbisHisse.hisseId,
                                  sonHacizTarihi: sonHacizTarihi,
                                  //   sonHacizTarihi.getTime() === new Date(0).getTime() ? null : sonHacizTarihi,
                                  serhSayisi: serhSayisi,
                                  beyanSayisi: beyanSayisi
                                });
                              }
                            }
                          }
                        }
                      }
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Takbis", "Takbis Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.SGK_HACIZ) {
                  try {
                    let sgkHacizSonuc = await uyapService.GetSgkHacizBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!sgkHacizSonuc || !sgkHacizSonuc.result) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Sgk Haciz", sgkHacizSonuc?.error || "Sgk Haciz Sonucu Yok");
                    } else {
                      const sgkHacizVeriListesi = sgkHacizSonuc.result.sorguSonucDVO;
                      if (sgkHacizVeriListesi && sgkHacizVeriListesi.length > 0) {
                        sgkHacizVeriListesi.forEach((sgkHacizVeri) => {
                          topluSorguModel.current.sgkHacizListeleri?.push({
                            ortakDegerler,
                            kisiTipString: sgkHacizVeri.kisiTipString
                          });
                        });
                      }
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Sgk Haciz", "Sgk Haciz Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.ICRA_DOSYASI) {
                  try {
                    let icraDosyasiSonuc = await uyapService.GetIcraDosyasiBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!icraDosyasiSonuc || !icraDosyasiSonuc.result) {
                      addOlumsuzSorguSonucu(
                        ortakDegerler,
                        "İcra Dosyası",
                        icraDosyasiSonuc?.error || "İcra Dosyası Sonucu Yok"
                      );
                    } else {
                      const icraVeriListesi = icraDosyasiSonuc.result.sorguSonucDVO;
                      if (icraVeriListesi && icraVeriListesi.length > 0) {
                        icraVeriListesi.forEach((icraVeri) => {
                          topluSorguModel.current.icraDosyasiListeleri?.push({
                            ortakDegerler,
                            birimAdiDosya: icraVeri.birimAdi,
                            takipTuru: icraVeri.takipTurAciklamasi,
                            takipYoluSekli: `${icraVeri.takipYoluAciklamasi}  ${icraVeri.takipSekliciklamasi}`,
                            durumu: icraVeri.dosyaDurumuAciklama,
                            acilis: dateCheckAndParse(icraVeri.dosyaAcilisTarihiStr, "YYYY-MM-DD hh:mm:ss"),
                            // icraVeri.dosyaAcilisTarihiStr
                            //   ? moment(icraVeri.dosyaAcilisTarihiStr, "YYYY-MM-DD hh:mm:ss").toDate()
                            //   : undefined,
                            kapanis: undefined
                          });
                        });
                      }
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "İcra Dosyası", "İcra Dosyası Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.BANKA) {
                  try {
                    if (borclu.kurumDVO) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Banka", "Kurumlar için Banka Sorgusu Yapılamaz");
                      continue;
                    }
                    let bankaSonuc = await uyapService.GetBankaHesabiBilgiler(dosyaId, kisiKurumId);
                    await delay(delayTime);

                    if (!bankaSonuc || !bankaSonuc.result) {
                      addOlumsuzSorguSonucu(ortakDegerler, "Banka", bankaSonuc?.error || "Banka Sonucu Yok");
                    } else {
                      const bankaVeriListesi = bankaSonuc.result.sorguSonucDVO;
                      if (bankaVeriListesi && bankaVeriListesi.length > 0) {
                        bankaVeriListesi.forEach((bankaVeri) => {
                          topluSorguModel.current.bankaListeleri?.push({
                            ortakDegerler,
                            bankaAdi: bankaVeri.bankaAdi
                          });
                        });
                      }
                    }
                    //setTopluSorguModel({ ...topluSorguModel });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Banka", "Banka Sorgusu Hatası");
                  }
                }
                if (tab === TopluSorguTur.TARAF) {
                  try {
                    //let tarafSonuc = await uyapService.GetTarafBilgileri(dosyaId);
                    const vekılAranan = hub.getBuroSettings().connectedSessionLawyerName;
                    let rolBulunan: string = "";

                    tarafBilgileri.result?.forEach((taraf) => {
                      if (taraf.vekil && taraf.vekil.includes(vekılAranan)) {
                        rolBulunan = taraf.rol;
                      }
                    });

                    topluSorguModel.current.tarafListeleri?.push({
                      ortakDegerler,
                      rol: rolBulunan
                    });
                  } catch (error) {
                    addOlumsuzSorguSonucu(ortakDegerler, "Taraf", "Taraf Sorgusu Hatası");
                  }
                }
                setAbc(tab.toString());
              }
            }
          }

          if (!avukatDosyadaAlacakliMi) {
            addOlumsuzSorguSonucu(
              {
                birimAdi: dosya.birimAdi,
                dosyaNo: dosya.dosyaNo,
                alacakliAdi: alacakliAdi,
                borcluAdi: borcluAdlarıList.toString(),
                borcluTCVKNo: borcluTcVkNoList.toString(),
                dosyaDurumu: dosya.dosyaDurum
              },
              "Dosya",
              "Dosyada ALACAKLI vekili değilsiniz."
            );
            setAbc("Test789");
          }

          if (topluSorguModel.current.tabs.includes(TopluSorguTur.TAHSILAT_KALANI)) {
            try {
              let tahsilatKalanSonuc = await uyapService.GetDosyaTahsilatReddiyatBilgileri(dosyaId, 0);
              await delay(delayTime);

              if (!tahsilatKalanSonuc || !tahsilatKalanSonuc.result) {
                addOlumsuzSorguSonucu(
                  {
                    birimAdi: dosya.birimAdi,
                    dosyaNo: dosya.dosyaNo,
                    alacakliAdi: alacakliAdi,
                    borcluAdi: borcluAdlarıList.toString(),
                    borcluTCVKNo: borcluTcVkNoList.toString(),
                    dosyaDurumu: dosya.dosyaDurum
                  },
                  "Tahsilat Kalanı",
                  tahsilatKalanSonuc?.error || "Tahsilat Kalanı Sonucu Yok"
                );
              } else {
                const toplam = tahsilatKalanSonuc.result.toplamKalan;
                let sadecetahsilattoplam = 0;
                let sadecemasraftoplam = 0;
                let sadecedigertoplam = 0;

                tahsilatKalanSonuc.result.tahsilatList.forEach((item) => {
                  if (item.tahsilatTuru === "Borç Tahsilatı") {
                    sadecetahsilattoplam += item.kalanMiktar;
                  } else if (item.tahsilatTuru === "Masraf Avansı Tahsilatı") {
                    sadecemasraftoplam += item.kalanMiktar;
                  } else if (item.tahsilatTuru === "Diğer") {
                    sadecedigertoplam += item.kalanMiktar;
                  }
                });

                topluSorguModel.current.tahsilatKalaniListeleri?.push({
                  ortakDegerler: {
                    birimAdi: dosya.birimAdi,
                    dosyaNo: dosya.dosyaNo,
                    alacakliAdi: alacakliAdi,
                    borcluAdi: borcluAdlarıList.toString(),
                    borcluTCVKNo: borcluTcVkNoList.toString(),
                    dosyaDurumu: dosya.dosyaDurum
                  },
                  tahsilat: toplam,
                  sadeceToplam: sadecetahsilattoplam,
                  sadeceMasraf: sadecemasraftoplam,
                  sadeceDiger: sadecedigertoplam
                });
              }

              //settopluSorguModel.current({ ...topluSorguModel.current });
            } catch (error) {
              addOlumsuzSorguSonucu(ortakDegerler, "Tahsilat Kalanı", "Tahsilat Kalanı Sorgusu Hatası");
            }
          }

          updatedDosyalar = topluSorguModel.current.sorgulanacakDosyalar.map((d) =>
            d.dosyaNo === dosya.dosyaNo ? { ...d, isSorgulandi: DosyaSorguDurum.SORGULANDI } : d
          );

          topluSorguModel.current.sorgulanacakDosyalar = updatedDosyalar;
        } else {
          console.log("Dosya bilgileri bulunamadı");
          addOlumsuzSorguSonucu(ortakDegerler, "DOSYA ID SORGUSU", "Dosya Bulunamadı");

          updatedDosyalar = topluSorguModel.current.sorgulanacakDosyalar.map((d) =>
            d.dosyaNo === dosya.dosyaNo ? { ...d, isSorgulandi: DosyaSorguDurum.DOSYA_BULUNAMADI } : d
          );

          topluSorguModel.current.sorgulanacakDosyalar = updatedDosyalar;
        }
      } catch (error) {
        console.error(`Error fetching data for ${dosya}:`, error);
      }

      const guncellenmisTopluSorguSonuclarListesi: TopluSorguModel[] = topluSorguSonuclarListJson.filter(
        (topluSorguSonuc) => {
          return topluSorguSonuc.key !== topluSorguModel.current.key;
        }
      );

      if (totalTopluSorgu.current === tamamlananTopluSorguSayisi.current + 1) {
        topluSorguModel.current.status = TopluSorguDurum.TAMAMLANDI;
        //setTopluSorguModel((topluSorguModel) => ({ ...topluSorguModel, status: TopluSorguDurum.TAMAMLANDI }));
      }

      guncellenmisTopluSorguSonuclarListesi.push(topluSorguModel.current);

      tamamlananTopluSorguSayisi.current += 1;
      setAbc(dosya.dosyaNo);

      const res = await electron.setTopluSorguSonuclarListesi(guncellenmisTopluSorguSonuclarListesi);

      if (!res) {
        toast.show("Toplu sorgu sonucu kaydedilirken bir sorun oluştu", "error");
        continue;
      }
      await delay(5000);
    }
    setTopluSorguYapiliyorMu(false);
    toast.show("Toplu Sorgu Başarıyla Tamamlandı", "success");
  };

  const value: TopluSorguIslemlerModel = {
    startTopluSorgu,
    topluSorguModel: topluSorguModel.current,
    topluSorguYapiliyorMu: topluSorguYapiliyorMu,
    topluSorguYapilan: tamamlananTopluSorguSayisi.current,
    totalTopluSorgu: totalTopluSorgu.current
  };

  useEffect(() => {
    electron.getTopluSorguSonuclarListesi().then((res) => {
      if (!res) return;

      res.forEach((topluSorguSonuc) => {
        if (topluSorguSonuc.status === TopluSorguDurum.DEVAM_EDİYOR) {
          topluSorguSonuc.status = TopluSorguDurum.TAMAMLANMADI;
        }
      });

      electron.setTopluSorguSonuclarListesi(res);
    });
  }, []);

  return <TopluSorguIslemlerContext.Provider value={value}>{children}</TopluSorguIslemlerContext.Provider>;
};
