import {
  AddressDto,
  CreateAddressDto,
  UpdateAddressDto,
} from "../address/address.dto";
import { EntityDto } from "../base/base.dto";
import { CreateEmailDto, EmailDto, UpdateEmailDto } from "../email/email.dto";
import {
  CreateTelephoneDto,
  TelephoneDto,
  UpdateTelephoneDto,
} from "../telephone/telephone.dto";

export interface MuvekkilDto extends EntityDto<number> {
  tip: MuvekkilTipi;
  adSoyad: string;
  tcNo: string;
  unvan: string;
  vergiKimlikNumarasi: string;
  dogumTarihi?: Date;
  babaAdi: string;
  anaAdi: string;
  cinsiyet: string;
  vergiDairesi: string;
  mersisNo: string;
  vekaletBilgisi: string;
  adresler: AddressDto[];
  telefonlar: TelephoneDto[];
  epostalar: EmailDto[];
}

export interface CreateMuvekkilDto {
  tip: MuvekkilTipi;
  adSoyad: string;
  tcNo: string;
  unvan: string;
  vergiKimlikNumarasi: string;
  dogumTarihi?: Date;
  babaAdi: string;
  anaAdi: string;
  cinsiyet: string;
  vergiDairesi: string;
  mersisNo: string;
  vekaletBilgisi: string;
  adresler: CreateAddressDto[];
  telefonlar: CreateTelephoneDto[];
  epostalar: CreateEmailDto[];
}

export interface UpdateMuvekkilDto extends EntityDto<number> {
  tip: MuvekkilTipi;
  adSoyad: string;
  tcNo: string;
  unvan: string;
  vergiKimlikNumarasi: string;
  dogumTarihi?: Date;
  babaAdi: string;
  anaAdi: string;
  cinsiyet: string;
  vergiDairesi: string;
  mersisNo: string;
  vekaletBilgisi: string;
  adresler: AddressDto[];
  telefonlar: UpdateTelephoneDto[];
  epostalar: UpdateEmailDto[];
}

export enum MuvekkilTipi {
  Gercek = 1,
  Tuzel,
}
