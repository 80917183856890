import React from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useState } from "react";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";
import { FilterMatchMode } from "primereact/api";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { SupportDto, SupportStatus } from "../../services/support/support.dto";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Dropdown } from "primereact/dropdown";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import moment from "moment";
import { useAuth } from "../../utils/auth";
import AdminService from "../../services/admin/admin.services";
const SupportAdminPage = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const navigate = useNavigate();
  const auth = useAuth();

  const defFilters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    topic: commonFilterContains,
    firstName: commonFilterContains,
    lastName: commonFilterContains,
    createdDate: commonFilterDateIs,
    status: { value: null, matchMode: FilterMatchMode.CONTAINS }
  };
  const [filters, setFilters] = useState<DataTableFilterMeta>(defFilters);

  const {
    data: supportResponse,
    refetch: refetchSupport,
    isLoading: supportResponseIsLoading
  } = useQuery({
    queryKey: ["Supports"],
    refetchOnMount: true,
    queryFn: () => AdminService.GetAllSupport()
  });
  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const clearFilter = () => {
    setFilters(defFilters);
    setGlobalFilterValue("");
  };


  const actionBodyTemplate = (rowData: SupportDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-search"
          rounded
          outlined
          severity="success"
          tooltip="Detay"
          onClick={() => {
            navigate(`/app/admin/support/${rowData.id}`);
          }}
        />
      </div>
    );
  };

  const [representatives] = useState([
    { label: "Açık", value: SupportStatus.Open },
    { label: "Kapalı", value: SupportStatus.Closed }
  ]);

  const representativesItemTemplate = (option: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.label}</span>
      </div>
    );
  };

  const representativeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e: MultiSelectChangeEvent) => {
          options.filterCallback(e.value);
        }}
        optionLabel="label"
        optionValue="value"
        placeholder="Durum Seçiniz"
        className="p-column-filter"
      />
    );
  };

  console.log(supportResponse)
  const formattedData = supportResponse?.result?.data?.map((i) => ({
    ...i,
    createdDate: new Date(i.createdDate),
    firstName: i.user?.firstName,
    lastName: i.user?.lastName,
    email: i.user?.email
  }));
  return (
    <div className="card">
      <DataTable
        value={formattedData ?? []}
        filters={filters}
        key="id"
        showGridlines
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Destek Kayıt bilgisi bulunamadı."
        globalFilterFields={["firstName", "lastName", "topic", "createdDate", "status"]}
        currentPageReportTemplate="{first} / {last} - Toplam {totalRecords} destek kaydı"
        header={CreateHeader("Destek Kayıtları", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
      >
        <Column body={actionBodyTemplate} style={{ width: "6rem" }}></Column>
        <Column
          field="firstName"
          header="Ad"
          sortable
          showFilterMatchModes={false}
          filter
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
        ></Column>
        <Column
          field="lastName"
          header="Soyad"
          sortable
          showFilterMatchModes={false}
          filter
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
        ></Column>
        <Column
          field="email"
          header="E-posta"
          sortable
          showFilterMatchModes={false}
          filter
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
        ></Column>
        <Column
          field="topic"
          header="Konu"
          sortable
          showFilterMatchModes={false}
          filter
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
        ></Column>
        <Column
          field="createdDate"
          header="Oluşturulma Tarihi"
          body={(rowData: SupportDto) => {
            return <span>{moment(rowData.createdDate).format("DD.MM.YYYY HH:mm")}</span>;
          }}
          sortable
          filterField="createdDate"
          filter
          filterPlaceholder="Oluşturulma Tarihine göre arayın."
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterMatchMode={FilterMatchMode.DATE_IS}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Oluşturulma Tarihine göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="status"
          header="Durum"
          sortable
          filterField="status"
          filter
          showFilterMatchModes={false}
          filterElement={representativeFilterTemplate}
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          body={(rowData: SupportDto) => {
            return <span>{rowData.status === SupportStatus.Open ? "Açık" : "Kapalı"}</span>;
          }}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>;
          }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default SupportAdminPage;
