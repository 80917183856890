import React, { useContext, useEffect } from "react";
import { DosyaDTO } from "../../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { useQuery } from "@tanstack/react-query";
import { useHub } from "../../../../../utils/buro-hub";
import SorguBilgileri from "../SorguBilgileri";
import uyapService from "../../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { LayoutContext } from "../../../../../layout/context/layoutcontext";

const BagkurEmeklisi = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: BagkurEmekliResponse,
    refetch: refetchBagkurEmekli,
    isLoading: bagkurEmekliResponseLoading
  } = useQuery({
    queryKey: ["BagkurEmekli", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetBagkurEmekliBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(bagkurEmekliResponseLoading)
  }, [bagkurEmekliResponseLoading])

  return (
    <div>
      {BagkurEmekliResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {BagkurEmekliResponse.error}</div>}

      {BagkurEmekliResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={BagkurEmekliResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={BagkurEmekliResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={BagkurEmekliResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={BagkurEmekliResponse?.result?.sorgulayanBilgileri}
          />
          {BagkurEmekliResponse?.result?.sorguSonucDVO?.hataKodu != 0 ? (
            <div className="card p-2 lg:p-5 mt-3">{BagkurEmekliResponse?.result?.sorguSonucDVO?.hataMesaji.toString()}</div>
          ) : (
            <div className="card p-2 lg:p-5 mt-3"></div>
          )}
        </div>
      )}
    </div>
  );
};

export default BagkurEmeklisi;
