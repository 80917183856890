import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguEgmTopluSorguModel } from "../TopluSorgu.dto";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";

const EgmDataTable = (props: { tableData: TopluSorguEgmTopluSorguModel[] }) => {
  const egmVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguEgmTopluSorguModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      plaka: commonFilterContains,
      marka: commonFilterContains,
      model: commonFilterContains,
      ticariAd: commonFilterContains,
      renk: commonFilterContains,
      cins: commonFilterContains,
      mahrumiyetSonuc: commonFilterContains,
      sonHacizTarihi: commonFilterDateIs,
      sonYakalamaTarihi: commonFilterDateIs,
      serhSayisi: commonFilterContains,
      lblHaciz: commonFilterContains,
      lblYakalama: commonFilterContains,
      lblHacizYakalama: commonFilterContains,
      lblIhtiyatiHaciz: commonFilterContains,
      lblRehin: commonFilterContains,
      lblRehninPC: commonFilterContains,
      lblMulkiyet: commonFilterContains,
      lblIcraSatisSerhi: commonFilterContains,
      lblSatisArz: commonFilterContains,
      lblKesinSatis: commonFilterContains,
      lblIhtedbir: commonFilterContains,
      lblPlakaDeg: commonFilterContains,
      lblIhtedbir2: commonFilterContains,
      lblDiger: commonFilterContains,
      lblTrfMen20e: commonFilterContains,
      lblOtvMuaf3y: commonFilterContains,
      lblIflasSerhi: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Plaka",
      "Marka",
      "Model",
      "Tipi",
      "Renk",
      "Cins",
      "Mahrumiyet Sonuc",
      "Son Haciz Tarihi",
      "Son Yakalama Tarihi",
      "Şerh Sayısı",
      "Haciz",
      "Yakalama",
      "Haciz Yakalama",
      "İhtiyati Haciz",
      "Rehin",
      "Rehnin Paraya Çevirilmesi",
      "Mülkiyet",
      "İcra Satış Şerhi",
      "Satışa Arz",
      "Kesin Satış",
      "İhtedbir",
      "Plaka Değişikliği",
      "İhtedbir 2",
      "Diğer",
      "TRF Men 20e",
      "ÖTV Muaf 3y",
      "İflas Şerhi"
    ];

    const filteredData = egmVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      plaka: data.plaka,
      marka: data.marka,
      model: data.model,
      tipi: data.ticariAd,
      renk: data.renk,
      cins: data.cins,
      mahrumiyetSonuc: data.mahrumiyetSonuc,
      sonHacizTarihi:
        data.sonHacizTarihi && moment(data.sonHacizTarihi).isValid()
          ? moment(data.sonHacizTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
      sonYakalamaTarihi:
        data.sonYakalamaTarihi && moment(data.sonYakalamaTarihi).isValid()
          ? moment(data.sonYakalamaTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
      serhSayisi: data.serhSayisi,
      haciz: data.lblHaciz,
      yakalama: data.lblYakalama,
      hacizYakalama: data.lblHacizYakalama,
      ihtiyatiHaciz: data.lblIhtiyatiHaciz,
      rehin: data.lblRehin,
      rehninParayaCevirilmesi: data.lblRehninPC,
      mulkiyet: data.lblMulkiyet,
      icraSatisSerhi: data.lblIcraSatisSerhi,
      satisaArz: data.lblSatisArz,
      kesinSatis: data.lblKesinSatis,
      ihtedbir: data.lblIhtedbir,
      plakaDegisikligi: data.lblPlakaDeg,
      ihtedbir2: data.lblIhtedbir2,
      diger: data.lblDiger,
      trfMen20e: data.lblTrfMen20e,
      otvMuaf3y: data.lblOtvMuaf3y,
      iflasSerhi: data.lblIflasSerhi
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluSorguEgm");
  };

  return (
    <div>
      <DataTable
        value={egmVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="index"
        showGridlines
        emptyMessage="Egm Araç Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Egm Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "plaka",
          "marka",
          "model",
          "ticariAd",
          "renk",
          "cins",
          "mahrumiyetSonuc",
          "sonHacizTarihi",
          "sonYakalamaTarihi",
          "serhSayisi",
          "lblHaciz",
          "lblYakalama",
          "lblHacizYakalama",
          "lblIhtiyatiHaciz",
          "lblRehin",
          "lblRehninPC",
          "lblMulkiyet",
          "lblIcraSatisSerhi",
          "lblSatisArz",
          "lblKesinSatis",
          "lblIhtedbir",
          "lblPlakaDeg",
          "lblIhtedbir2",
          "lblDiger",
          "lblTrfMen20e",
          "lblOtvMuaf3y",
          "lblIflasSerhi"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filter
          filterField="ortakDegerler.dosyaNo"
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filter
          filterField="ortakDegerler.alacakliAdi"
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filter
          filterField="ortakDegerler.borcluAdi"
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filter
          filterField="ortakDegerler.borcluTCVKNo"
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filter
          filterField="ortakDegerler.dosyaDurumu"
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Plaka"
          field="plaka"
          sortable
          filter
          filterField="plaka"
          filterPlaceholder="Plakaya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Plakaya göre filtreleyin</div>}
        />
        <Column
          header="Marka"
          field="marka"
          sortable
          filter
          filterField="marka"
          filterPlaceholder="Markaya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Markaya göre filtreleyin</div>}
        />
        <Column
          header="Model"
          field="model"
          sortable
          filter
          filterField="model"
          filterPlaceholder="Modele göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Modele göre filtreleyin</div>}
        />
        <Column
          header="Tipi"
          field="ticariAd"
          sortable
          filter
          filterField="ticariAd"
          filterPlaceholder="Tipine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tipine göre filtreleyin</div>}
        />
        <Column
          header="Renk"
          field="renk"
          sortable
          filter
          filterField="renk"
          filterPlaceholder="Renke göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Renke göre filtreleyin</div>}
        />
        <Column
          header="Cins"
          field="cins"
          sortable
          filter
          filterField="cins"
          filterPlaceholder="Cinsine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Cinsine göre filtreleyin</div>}
        />
        <Column
          header="Mahrumiyet Bilgisi"
          field="mahrumiyetSonuc"
          sortable
          filter
          filterField="mahrumiyetSonuc"
          filterPlaceholder="Mahrumiyete göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Mahrumiyete göre filtreleyin</div>}
        />
        <Column
          header="Son Haciz Tarihi"
          field="sonHacizTarihi"
          sortable
          body={(rowData) =>
            rowData.sonHacizTarihi && moment(rowData.sonHacizTarihi).isValid() ? (
              moment(rowData.sonHacizTarihi).format(process.env.REACT_APP_DATE_FORMAT)
            ) : (
              <></>
            )
          }
          filter
          filterField="sonHacizTarihi"
          filterPlaceholder="Son haciz tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Son haciz tarihine göre filtreleyin</div>}
        />
        <Column
          header="Son Yakalama Tarihi"
          field="sonYakalamaTarihi"
          sortable
          body={(rowData) =>
            rowData.sonYakalamaTarihi && moment(rowData.sonYakalamaTarihi).isValid() ? (
              moment(rowData.sonYakalamaTarihi).format(process.env.REACT_APP_DATE_FORMAT)
            ) : (
              <></>
            )
          }
          //body={(rowData) => moment(rowData.sonYakalamaTarihi).format(process.env.REACT_APP_DATE_FORMAT)}
          filter
          filterField="sonYakalamaTarihi"
          filterPlaceholder="Son yakalama tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Son yakalama tarihine göre filtreleyin</div>}
        />
        <Column
          header="Şerh Sayısı"
          field="serhSayisi"
          sortable
          filter
          filterField="serhSayisi"
          filterPlaceholder="Şerh sayısına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Şerh sayısına göre filtreleyin</div>}
        />
        <Column
          header="Haciz"
          field="lblHaciz"
          sortable
          filter
          filterField="lblHaciz"
          filterPlaceholder="Hacize göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Hacize göre filtreleyin</div>}
        />
        <Column
          header="Yakalama"
          field="lblYakalama"
          sortable
          filter
          filterField="lblYakalama"
          filterPlaceholder="Yakalamaya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Yakalamaya göre filtreleyin</div>}
        />
        <Column
          header="Haciz Yakalama"
          field="lblHacizYakalama"
          sortable
          filter
          filterField="lblHacizYakalama"
          filterPlaceholder="Haciz yakalamaya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Haciz yakalamaya göre filtreleyin</div>}
        />
        <Column
          header="İhtiyati Haciz"
          field="lblIhtiyatiHaciz"
          sortable
          filter
          filterField="lblIhtiyatiHaciz"
          filterPlaceholder="İhtiyati hacize göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İhtiyati hacize göre filtreleyin</div>}
        />
        <Column
          header="Rehin"
          field="lblRehin"
          sortable
          filter
          filterField="lblRehin"
          filterPlaceholder="Rehine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Rehine göre filtreleyin</div>}
        />
        <Column
          header="Rehnin Paraya Çevirilmesi"
          field="lblRehninPC"
          sortable
          filter
          filterField="lblRehninPC"
          filterPlaceholder="Rehnin PC'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Rehnin PC'ye göre filtreleyin</div>}
        />
        <Column
          header="Mülkiyet"
          field="lblMulkiyet"
          sortable
          filter
          filterField="lblMulkiyet"
          filterPlaceholder="Mülkiyete göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Mülkiyete göre filtreleyin</div>}
        />
        <Column
          header="İcra Satış Şerhi"
          field="lblIcraSatisSerhi"
          sortable
          filter
          filterField="lblIcraSatisSerhi"
          filterPlaceholder="İcra satış şerhine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İcra satış şerhine göre filtreleyin</div>}
        />
        <Column
          header="Satışa Arz"
          field="lblSatisArz"
          sortable
          filter
          filterField="lblSatisArz"
          filterPlaceholder="Satışa arza göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Satışa arza göre filtreleyin</div>}
        />
        <Column
          header="Kesin Satış"
          field="lblKesinSatis"
          sortable
          filter
          filterField="lblKesinSatis"
          filterPlaceholder="Kesin satışa göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kesin satışa göre filtreleyin</div>}
        />
        <Column
          header="İhtedbir"
          field="lblIhtedbir"
          sortable
          filter
          filterField="lblIhtedbir"
          filterPlaceholder="İhtedbire göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İhtedbire göre filtreleyin</div>}
        />
        <Column
          header="Plaka Değişikliği"
          field="lblPlakaDeg"
          sortable
          filter
          filterField="lblPlakaDeg"
          filterPlaceholder="Plaka değişikliğine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Plaka değişikliğine göre filtreleyin</div>}
        />
        <Column
          header="İhtedbir-2"
          field="lblIhtedbir2"
          sortable
          filter
          filterField="lblIhtedbir2"
          filterPlaceholder="İhtedbir-2'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İhtedbir-2'ye göre filtreleyin</div>}
        />
        <Column
          header="Diğer"
          field="lblDiger"
          sortable
          filter
          filterField="lblDiger"
          filterPlaceholder="Diğere göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Diğere göre filtreleyin</div>}
        />
        <Column
          header="Trf.Men(20/E)"
          field="lblTrfMen20e"
          sortable
          filter
          filterField="lblTrfMen20e"
          filterPlaceholder="Trf.Men(20/E)'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Trf.Men(20/E)'ye göre filtreleyin</div>}
        />
        <Column
          header="ÖTV Muaf (3Y)"
          field="lblOtvMuaf3y"
          sortable
          filter
          filterField="lblOtvMuaf3y"
          filterPlaceholder="ÖTV Muaf (3Y)'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">ÖTV Muaf (3Y)'ye göre filtreleyin</div>}
        />
        <Column
          header="İflas Şerhi"
          field="lblIflasSerhi"
          sortable
          filter
          filterField="lblIflasSerhi"
          filterPlaceholder="İflas şerhine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İflas şerhine göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default EgmDataTable;
