import { EntityDto } from "../base/base.dto";
import { KarsiYanDto } from "../karsi-yan/karsi-yan.dto";

export interface TalepBilgisiDto extends EntityDto<number> {
  yazimTarihi: Date;
  talepTuru: string;
  borclular: KarsiYanDto[];
  talepDurumu: TalepDurumu;
  aciklama?: string;
  icraFoyId: number;
  creatorUserName: string;
  borclularNames: string;
}

export interface CreateTalepBilgisiDto {
  yazimTarihi: Date;
  talepTuru: string;
  borcluIds: number[];
  talepDurumu: TalepDurumu;
  aciklama?: string;
  icraFoyId: number;
}

export interface UpdateTalepBilgisiDto extends EntityDto<number> {
  yazimTarihi: Date;
  talepTuru: string;
  borcluIds: number[];
  talepDurumu: TalepDurumu;
  aciklama?: string;
}

export enum TalepDurumu {
  Yazildi = 1,
  Imzalandi,
  Sorguda,
  Kabul,
  RedUyarisiz,
  RedUyarili,
}
