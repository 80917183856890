import AuthCode from "react-auth-code-input";
import { useContext, useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import accountService from "../../services/account/account.service";
import {
  ConfirmPhoneNumberDto,
  SendPhoneNumberConfirmSmsDto,
} from "../../services/account/account.dto";
import PhoneVerifyImage from "../../assets/images/confirm-phone-number.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../utils/toast";
import { LayoutContext } from "../../layout/context/layoutcontext";

const VerificationPage = () => {
  const { userId, phoneNumber } = useParams();
  if (!userId || !phoneNumber) return null;

  return (
    <div className="px-6">
      <div className="pt-5">
        <div className="flex justify-content-center pt-2">
          <img src={PhoneVerifyImage} alt="PhoneVerifyImage" />
        </div>

        <p className="text-center font-medium text-2xl mt-2 mb-0">
          Telefon Doğrulama
        </p>

        <p className="font-normal text-center verify-text-color">
          Telefon numaranıza gönderdiğimiz doğrulama kodunu giriniz.
        </p>

        <div className="mt-5">
          <AuthValidation userId={userId} phoneNumber={phoneNumber} />
        </div>
      </div>
    </div>
  );
};

const AuthValidation = (props: { userId: string; phoneNumber: string }) => {
  const [showSendAgainCode, setShowSendAgainCode] = useState(false);
  const [seconds, setSeconds] = useState(119);
  const navigate = useNavigate();
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)
  const phoneNumber: string = props.phoneNumber;
  const userId: string = props.userId;

  useEffect(() => {
    let intervalId: any;
    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      setShowSendAgainCode(true);
    }
    return () => clearInterval(intervalId);
  }, [seconds]);

  const minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
  const remainingSeconds = String(seconds % 60).padStart(2, "0");

  const handleOnChange = (res: string) => {
    formik.setFieldValue("token", res);
  };

  const handleSendAgain = async (e: any) => {
    e.preventDefault();
    setBlocked(true)
    const res = await accountService.SendPhoneNumberConfirmSms({
      userId: userId,
      phoneNumber: phoneNumber,
    } as SendPhoneNumberConfirmSmsDto);

    if (res.result && !res.result.error) {
      setSeconds(119);
      toast.show("Doğrulama kodu tekrar gönderildi.", "success");
      setBlocked(false)
    }
  };

  const formik = useFormik({
    initialValues: {
      token: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.token.length < 6) {
        errors = { token: "6 karakterli doğrulama kodunu giriniz." };
      }
      return errors;
    },

    onSubmit: async (data) => {
      setBlocked(true)
      const res = await accountService.ConfirmPhoneNumber({
        userId: userId,
        phoneNumber: phoneNumber,
        token: data.token,
      } as ConfirmPhoneNumberDto);

      if (res.result && !res.result.error) {
        toast.show("Telefon numaranız doğrulandı.", "success");
        setBlocked(false)
        navigate("/login", { replace: true });
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="div-authcode mx-2 text-center">
          <AuthCode
            autoFocus={true}
            onChange={handleOnChange}
            length={6}
            allowedCharacters={"numeric"}
          />

          {FormikValueIsValid(formik, "token") && (
            <div className="p-error mt-3">{formik.errors.token}</div>
          )}

          <p className="font-medium text-2xl mt-3 mb-3">
            {minutes}:{remainingSeconds}
          </p>
        </div>

        <div className="flex justify-content-center mt-2 md:mt-5">
          <Button label="Doğrula" className="px-8" type="submit" />
        </div>
      </form>
      <div className={classNames({ hidden: showSendAgainCode === false })}>
        <div className="align-items-center justify-content-center gap-2 mt-2 text-center">
          Doğrulama kodu gelmedi mi?
        </div>
        <p
          className="no-underline text-center cursor-pointer text-primary"
          onClick={handleSendAgain}
        >
          Tekrar Gönder
        </p>
      </div>
    </>
  );
};

export default VerificationPage;
