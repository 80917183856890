import { AxiosRequestConfig } from "axios";
import { uyapAxios } from "./uyap.axios";

export interface HttpResponse<T> {
  [x: string]: any;
  result?: T;
  error?: string;
}

async function post<TResponse>(
  url: string,
  data: any,
  config?: AxiosRequestConfig<any> | undefined
): Promise<HttpResponse<TResponse>> {
  try {
    const response = await uyapAxios.post(url, data, config);
    if (response.data.error) {
      return { error: response.data.error as string };
    }
    return { result: response.data as TResponse };
  } catch (error: any) {
    return { error: error?.response?.data };
  }
}

async function get<TResponse>(
    url: string,
    config?: AxiosRequestConfig<any> | undefined
): Promise<HttpResponse<TResponse>> {
    try {
        const response = await uyapAxios.get(url, config)
        if (response.data.error) throw new Error(response.data.error);
        return { result: response.data as TResponse };
    } catch (error) {
        return { error: error as string };
    }
}

export default {
    post,
    get
};
