import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  MuvekkilDto,
  CreateMuvekkilDto,
  UpdateMuvekkilDto,
} from "./muvekkil.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<MuvekkilDto>(`Muvekkil/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<MuvekkilDto[]>(`Muvekkil`, config);
  },
  Create(dto: CreateMuvekkilDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<MuvekkilDto>("Muvekkil", dto, config);
  },
  Update(dto: UpdateMuvekkilDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<MuvekkilDto>("Muvekkil", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<MuvekkilDto>(`Muvekkil/${id}`, config);
  },
};

export default services;
