import { useLocation, useParams } from "react-router-dom";

const PaymentPage = (props: any) => {
  const location = useLocation();
  const paymentUrl = location.state.paymentUrl;

  if (!paymentUrl) {
    return <div>Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.</div>;
  }

  console.log(`https://www.paytr.com/odeme/guvenli/${paymentUrl}`);

  return (
    <div className="h-screen">
      <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
      <iframe src={`${paymentUrl}`} id="paytriframe" frameBorder="0" scrolling="no" style={{ width: "100%", height: "100%" }}></iframe>
      <script>iFrameResize({}, "#paytriframe")</script>
    </div>
  );
};

export default PaymentPage;
