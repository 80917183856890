import React, { useContext, useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { LoginDto } from "../../services/auth/auth.dto";
import accountService from "../../services/account/account.service";
import { useAuth } from "../../utils/auth";
import { useHub } from "../../utils/buro-hub";
import { PasswordResetTokenDto } from "../../services/account/account.dto";
import { useToast } from "../../utils/toast";
import { LayoutContext } from "../../layout/context/layoutcontext";

const ForgotPassword = () => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const auth = useAuth();
  const uyapHub = useHub();
  const toast = useToast();
  const navigate = useNavigate();

  const { setBlocked } = useContext(LayoutContext)
  const [isVisiblePhoneConfirmation, setIsVisiblePhoneConfirmation] = useState<boolean>(false);

  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  );

  const formik = useFormik<PasswordResetTokenDto>({
    initialValues: {
      email: ""
    },
    enableReinitialize: true,
    validate: (data) => {
      const errors: any = {};

      if (!data.email) {
        errors.email = t("EmailIsRequired");
      }

      if (data.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
        errors.email = "Geçerli bir e-posta adresi giriniz.";
      }

      return errors;
    },
    onSubmit: (values) => {
      setBlocked(true)
      accountService.SendPasswordResetEmail(values).then((res) => {
        if (res.result && !res.result.error) {
          toast.show("Parola sıfırlama bağlantısı e-posta adresinize başarıyla gönderildi.", "success");
          setBlocked(false)
          navigate("/login", { replace: true });
        }
      }).finally(() => setBlocked(false));
    }
  });

  return (
    <form className={containerClassName} onSubmit={formik.handleSubmit}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
          }}
        >
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
            <div className="text-center mb-5">
              <div>
                <img src="/images/logo.png" alt="Image" height="70" className="mb-3" />
              </div>
              <div>
                <span className="text-600 font-medium">{"E-posta adresinizi giriniz."}</span>
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
                {"E-Posta Adresi"}
              </label>
              <InputText
                id="email"
                type="text"
                placeholder={"E-Posta Adresi"}
                className="w-full md:w-30rem"
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                style={{ padding: "1rem" }}
              />
              {formik.errors.email && <div className="p-error mt-3">{formik.errors.email}</div>}
            </div>
            <div>
              <Button label={"Sıfırlama Bağlantısı Gönder"} className="w-full p-3 text-xl mt-5" type="submit"></Button>

              <div className="mt-3 text-center">
                <Link
                  to="/login"
                  className="font-medium no-underline ml-2 text-right cursor-pointer"
                  style={{ color: "var(--primary-color)" }}
                >
                  Giriş Yap
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
