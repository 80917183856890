import React, { useState, useEffect } from "react";
import Tiff from "tiff.js";
import { ViewerBaseProps } from "./fileViewer.dto";
import { InputNumber } from "primereact/inputnumber";
import { ProgressSpinner } from "primereact/progressspinner";

interface TiffViewerProps extends ViewerBaseProps {}

export const TiffViewer: React.FC<TiffViewerProps> = ({ file, parentWidth }) => {
  const { data } = file;
  const [imgUrl, setImgUrl] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        Tiff.initialize({
          TOTAL_MEMORY: 16777216 * 10
        });
        const tiff = new Tiff({
          buffer: reader.result
        });
        setTotalPage(tiff.countDirectory());
        const urls = [];
        for (var i = 0, len = tiff.countDirectory(); i < len; ++i) {
          tiff.setDirectory(i);
          urls.push(tiff.toDataURL());
        }
        // const dataUri = tiff.toDataURL();
        setImgUrl(urls);
        setLoading(false);
      };
      reader.readAsArrayBuffer(data);
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }, [data]);
  return (
    <div>
      {imgUrl.length > 0 && !loading? (
        <div>
          {totalPage > 1 && (
            <div className="flex w-full justify-content-center gap-3 p-3">
              {/* <Button
                        icon="pi pi-chevron-left"
                        onClick={() => (currentPage > 1 ? setCurrentPage(currentPage - 1) : null)}
                      /> */}
              <div className="">
                <InputNumber
                  inputId="horizontal-buttons"
                  value={currentPage}
                  suffix={`/${totalPage}`}
                  onValueChange={(e) => setCurrentPage(e.value as number)}
                  showButtons
                  buttonLayout="horizontal"
                  step={1}
                  // decrementButtonClassName="p-button-danger"
                  // incrementButtonClassName="p-button-success"
                  incrementButtonIcon="pi pi-chevron-right"
                  decrementButtonIcon="pi pi-chevron-left"
                  mode="decimal"
                  max={totalPage}
                  min={1}
                  inputStyle={{ width: 50 }}
                />
              </div>
              {/* <Button icon="pi pi-chevron-right" onClick={() => setCurrentPage(currentPage + 1)} /> */}
            </div>
          )}
          <img src={imgUrl[currentPage - 1]} width={parentWidth} />
        </div>
      ) : (
        <div className="flex justify-content-center align-items-center h-full">
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
};
