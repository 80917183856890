import React, { useEffect, useState } from 'react'
import { ViewerBaseProps } from "./fileViewer.dto";
import { ProgressSpinner } from "primereact/progressspinner";

interface XMLViewerProps extends ViewerBaseProps {}

export const XMLViewer: React.FC<XMLViewerProps> = ({ file, parentWidth }) => {
    const [xmlContent, setXmlContent] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
      const loadXmlContent = async () => {
        try {
          const text = await file.data.text();
          setXmlContent(text);
        } catch (error) {
          console.error('Failed to read XML content:', error);
        } finally {
          setLoading(false);
        }
      };
  
      loadXmlContent();
    }, [file]);
  
    return (
      <div style={{ width: parentWidth || '100%', overflow: 'auto' }}>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {xmlContent}
          </pre>
        )}
      </div>
    );
  };
