import React, { useContext, useEffect, useState } from "react";
import AppMenuitem from "./AppMenuitem";
import { LayoutContext } from "./context/layoutcontext";
import { MenuProvider } from "./context/menucontext";
import { AppMenuItem } from "../types/types";
import { useAuth, UserHasPermissions } from "../utils/auth";
import { Permission } from "../services/auth/permissions";
import { useToast } from "../utils/toast";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { LawyerSessionModel } from "../services/hubs/hub.service.dto";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FormikValueIsValid from "../utils/FormikValueIsValid";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { UpdateUyapDosyaDto } from "../services/uyap-dosya/uyap-dosya.dto";
import uyapDosyaService from "../services/uyap-dosya/uyap-dosya.service";
import { useElectron } from "../utils/electron-connection";
import uyapService from "../services/uyap-sorgulari/uyap-sorgulari.service";
import { useHub } from "../utils/buro-hub";
import { FormikErrors, setIn, useFormik } from "formik";
import accountService from "../services/account/account.service";
import { UpdateBuroSettingsModel } from "../services/uyap-sorgulari/uyap-sorgulari.dto";

const AppMenu = () => {
  const { layoutConfig } = useContext(LayoutContext);
  const toast = useToast();
  const [isVisibleDiyalog, setIsVisibleDiyalog] = useState(false);
  const hub = useHub();

  const model: AppMenuItem[] = [
    {
      label: "Ana Sayfa",
      visible: UserHasPermissions([Permission.AccountDefault]),
      items: [{ label: "Ana Sayfa", icon: "pi pi-fw pi-home", to: "/app/homepage" }]
    },
    {
      label: "Pratik Portal",
      visible:
        UserHasPermissions([Permission.SubUserDefault]) ||
        UserHasPermissions([Permission.DosyaSorgulamaDefault]) ||
        UserHasPermissions([Permission.TopluSorgulamaDefault]) ||
        UserHasPermissions([Permission.DurusmaSorgulamaDefault]) ||
        UserHasPermissions([Permission.SikKullanilanlarDefault]),
      items: [
        {
          label: "Avukat Portal Oturum Aç",
          icon: "pi pi-sign-in",
          // to: "/app/bilirkisi-portal",
          // disabled: true,
          visible: UserHasPermissions([Permission.UyapOturumDefault]),
          command: () => {
            setIsVisibleDiyalog(true);
          }
        },
        {
          label: "Avukat Portal Anasayfa Aç",
          icon: "pi pi-external-link",
          // to: "/app/bilirkisi-portal",
          // disabled: true,
          visible: UserHasPermissions([Permission.UyapOturumDefault]),
          command: () => {
            hub.openUyapWindow();
          }
        },
        {
          label: "Dosya Sorgulama",
          icon: "pi pi-search",
          to: "/app/dosya-sorgulama",
          visible: UserHasPermissions([Permission.DosyaSorgulamaDefault])
        },
        {
          label: "Toplu Sorgu Listelerim",
          icon: "pi pi-folder",
          to: "/app/toplu-sorgulama-listesi",
          visible: UserHasPermissions([Permission.TopluSorgulamaDefault])
        },
        // {
        //   label: "Toplu Sorgulama",
        //   icon: "pi pi-fw pi-exclamation-circle",
        //   to: "/app/toplu-sorgulama",
        //   visible: UserHasPermissions([Permission.TopluSorgulamaDefault])
        // },
        {
          label: "Toplu Sorgu Sonuçlarım",
          icon: "pi pi-file-check",
          to: "/app/toplu-sorgu-sonuclar-listesi",
          visible: UserHasPermissions([Permission.TopluSorgulamaDefault])
        },
        // {
        //   label: "Tebligat Takibi",
        //   icon: "pi pi-envelope",
        //   to: "/app/tebligat-takip",
        //   visible: UserHasPermissions([Permission.SikKullanilanlarDefault])
        // },
        {
          label: "Duruşma Sorgulama",
          icon: "pi pi-fw pi-hammer",
          to: "/app/durusma-sorgula",
          visible: UserHasPermissions([Permission.DurusmaSorgulamaDefault])
        },
        {
          label: "Sık Kullanılan Dosyalarım",
          icon: "pi pi-star",
          to: "/app/sik-kullanilanlar",
          visible: UserHasPermissions([Permission.SikKullanilanlarDefault])
        },
        {
          label: "Bilirkişi Portal",
          icon: "pi pi-star",
          // to: "/app/bilirkisi-portal",
          // disabled: true,
          visible: true,
          command: () => {
            toast.show("Bilirkişi Portal Çok Yakında", "info");
          }
        },
        {
          label: "Vatandaş Portal",
          icon: "pi pi-star",
          to: "/app/vatandas-portal",
          // disabled: true,
          visible: UserHasPermissions([Permission.VatandasPortalDefault])
        }
      ]
    },
    {
      label: "Pratik İcra",
      visible:
        UserHasPermissions([Permission.MuvekkilDefault]) ||
        UserHasPermissions([Permission.KarsiYanDefault]) ||
        UserHasPermissions([Permission.IcraFoyDefault]),
      items: [
        {
          label: "Müvekkiler",
          icon: "pi pi-fw pi-user-plus",
          to: "/app/muvekkiller",
          visible: UserHasPermissions([Permission.MuvekkilDefault])
        },
        {
          label: "Karşı Yanlar",
          icon: "pi pi-fw pi-user-minus",
          to: "/app/karsi-yanlar",
          visible: UserHasPermissions([Permission.KarsiYanDefault])
        },
        {
          label: "İcra Föyleri",
          icon: "pi pi-fw pi-folder-open",
          to: "/app/icra-foyleri",
          visible: UserHasPermissions([Permission.IcraFoyDefault])
        }
      ]
    },
    {
      label: "Admin",
      visible: UserHasPermissions([Permission.RoleDefault]),
      items: [
        {
          label: "Roller",
          icon: "pi pi-fw pi-lock",
          to: "/app/roles",
          visible: UserHasPermissions([Permission.RoleDefault])
        },
        {
          label: "Ana Kullanıcılar",
          icon: "pi pi-fw pi-users",
          to: "/app/main-users",
          visible: UserHasPermissions([Permission.MainUsersDefault])
        },
        {
          label: "Siparişler",
          icon: "pi pi-fw pi-heart",
          to: "/app/orders",
          visible: UserHasPermissions([Permission.AdminOrderDefault])
        },
        {
          label: "Uygulama Logları",
          icon: "pi pi-fw pi-list",
          to: "/app/applogs",
          visible: UserHasPermissions([Permission.AppLogDefault])
        },
        {
          label: "Destek & Talep Kayıtları",
          icon: "pi pi-fw pi-question-circle",
          to: "/app/admin/support",
          visible: UserHasPermissions([Permission.SupportAdminDefault])
        }
      ]
    },
    {
      label: "Destek",
      visible: UserHasPermissions([Permission.SupportDefault]),
      items: [
        {
          label: "Destek & Talep",
          icon: "pi pi-fw pi-question-circle",
          to: "/app/support",
          visible: UserHasPermissions([Permission.SupportDefault])
        }
      ]
    }
  ];

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {model.map((item, i) => {
          return !item?.seperator ? (
            <AppMenuitem item={item} root={true} index={i} key={item.label} />
          ) : (
            <li className="menu-separator"></li>
          );
        })}
      </ul>
      <UyapSettingsDialog visible={isVisibleDiyalog} setVisible={setIsVisibleDiyalog} />
    </MenuProvider>
  );
};

const UyapSettingsDialog = (props: { visible: boolean; setVisible: (visible: boolean) => void }) => {
  const isVisibleDiyalog = props.visible;
  const setIsVisibleDiyalog = props.setVisible;
  const hub = useHub();
  const auth = useAuth();
  const toast = useToast();
  const buroSettings = hub.getBuroSettings();
  const lawyerSession = hub.sessions.lawyerSessions.find(
    (x) => x.lawyerName === buroSettings.connectedSessionLawyerName
  );
  const electron = useElectron();
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const formik = useFormik<UpdateBuroSettingsModel>({
    initialValues: {
      maxCookieSize: lawyerSession?.maxCookieSize ?? 4,
      cardPinNumber: buroSettings.cardPassword ?? "",
      amISharingUyapSession: buroSettings.amISharingUyapSession,
      sessionPin: buroSettings.sessionPin ?? ""
    },
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateBuroSettingsModel> = {};
      if (data.amISharingUyapSession && !data.maxCookieSize) {
        errors.maxCookieSize = "Oturum sayısı boş bırakılamaz";
      }

      if (data.amISharingUyapSession && (data.maxCookieSize < 1 || data.maxCookieSize > 5)) {
        errors.maxCookieSize = "Oturum sayısı 1 ile 5 arasında olmalıdır";
      }

      if (data.amISharingUyapSession && (!data.cardPinNumber || data.cardPinNumber === "")) {
        errors.cardPinNumber = "Oturum açılabilmesi için kart parolası girmelisiniz";
      }

      if (data.amISharingUyapSession && (!data.sessionPin || data.sessionPin === "")) {
        errors.sessionPin = "Oturum açılabilmesi için oturum şifreleme pini girmelisiniz";
      }

      return errors;
    },
    onSubmit: async () => {
      setIsVisibleDiyalog(false);
      if (formik.values.amISharingUyapSession) {
        const buroSettings = hub.getBuroSettings();

        buroSettings.amISharingUyapSession = true;
        buroSettings.cardPassword = formik.values.cardPinNumber;
        buroSettings.sessionPin = formik.values.sessionPin;
        buroSettings.maxCookieSize = formik.values.maxCookieSize;

        // hub.updateMaxCookieAndCardPin(formik.values.cardPinNumber, true);
        const isSuccess = await hub.getLawyerNameMessageToElectron();
        if (!isSuccess) {
          buroSettings.cardPassword = "";
          buroSettings.sessionPin = "";
          buroSettings.maxCookieSize = 0;
          buroSettings.amISharingUyapSession = false;

          // hub.updateMaxCookieAndCardPin("", false);
        }
        return;
      }

      buroSettings.cardPassword = "";
      buroSettings.sessionPin = "";
      buroSettings.maxCookieSize = 4;
      buroSettings.amISharingUyapSession = false;
      toast.show("Uyap oturumu kapandı.", "info");
      // hub.updateMaxCookieAndCardPin("", false);
      hub.releaseUyapSession("");
      await hub.closeUyapSession();
    }
  });

  useEffect(() => {
    if (!buroSettings.connectedSessionLawyerName) {
      return;
    }
    checkNewFile();
  }, [buroSettings.connectedSessionLawyerName]);

  const checkNewFile = async () => {
    await delay(30000);
    if (!hub.getBuroSettings().connectedSessionLawyerName) {
      return;
    }
    const key = hub.generateRandomString();
    try {
      const oturumVarMi = await hub.reserveUyapSession(key);
      if (oturumVarMi) {
        const responseEvraklar = await uyapDosyaService.GetAll();
        for await (const dosya of responseEvraklar?.result?.data || []) {
          const jsonString: string = JSON.stringify([dosya.dosyaNo, dosya.birimAdi, dosya.dosyaTur]);

          var res = await uyapService.GetDosyaBilgileriByWord({
            dosyaDurumKod: 0,
            pageNumber: 1,
            pageSize: 500,
            phrases: jsonString
          });
          if (!res || !res.result || res.result[1] !== 1) {
            await delay(1000);
            res = await uyapService.GetDosyaBilgileriByWord({
              dosyaDurumKod: 1,
              pageNumber: 1,
              pageSize: 500,
              phrases: jsonString
            });
          }

          if (res && res.result && res.result[1] === 1) {
            const dosyaId = res.result[0][0].dosyaId;

            await delay(1000);
            const data = await uyapService.GetAllEvrak(dosyaId);

            const newSonEvrakId = data?.result?.son20Evrak?.[0]?.evrakId;
            if (newSonEvrakId && dosya.sonEvrakId) {
              if (newSonEvrakId !== dosya.sonEvrakId) {
                await electron.showNotification(
                  "Yeni evrak bulundu",
                  `${dosya.birimAdi} - ${dosya.dosyaNo} numaralı dosyada yeni bir evrak bulundu.`
                );

                await uyapDosyaService.Update({
                  ...dosya,
                  sonEvrakId: newSonEvrakId
                } as UpdateUyapDosyaDto);
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error", error);
    } finally {
      await hub.releaseUyapSession(key);
    }
  };

  return (
    <Dialog
      visible={isVisibleDiyalog}
      onHide={() => setIsVisibleDiyalog(false)}
      modal={true}
      header="Uyap Oturum Ayarları"
      style={{ width: "40vw" }}
    >
      <div className="mt-1 w-full">
        {hub.getBuroSettings().amISharingUyapSession && (
          <div className="grid mt-3">
            <div className="col-12 text-xl">Şu anda Uyap oturumu paylaşmaktasınız.</div>
            <div className="col-12 mt-1">
              <Button
                label="Uyap Bağlantısını Kapat"
                onClick={async () => {
                  // hub.updateMaxCookieAndCardPin("", false);
                  hub.getBuroSettings().cardPassword = "";
                  hub.getBuroSettings().sessionPin = "";
                  hub.getBuroSettings().maxCookieSize = 4;
                  hub.getBuroSettings().amISharingUyapSession = false;
                  await hub.closeUyapSession();
                  setIsVisibleDiyalog(false);
                  toast.show("Uyap oturumu kapandı.", "info");
                }}
              />
            </div>
          </div>
        )}

        {!hub.getBuroSettings().amISharingUyapSession && (
          <>
            <div className="grid w-full">
              <div className="col-12 md:col-8 flex align-items-center ">
                <label className="text-primary cursor-pointer" htmlFor="amISharingUyapSession">
                  Uyap oturumlarını benim üzerimden paylaş
                </label>
              </div>

              <div className="col-12 md:col-4">
                <Checkbox
                  // id="amISharingUyapSession"
                  inputId="amISharingUyapSession"
                  onChange={(e) => formik.setFieldValue("amISharingUyapSession", e.target.checked)}
                  checked={formik.values.amISharingUyapSession === true}
                ></Checkbox>
                {FormikValueIsValid(formik, "amISharingUyapSession") && (
                  <div className="p-error mt-3">{formik.errors.amISharingUyapSession}</div>
                )}
              </div>
            </div>
            {formik.values.amISharingUyapSession && (
              <div className="grid mt-1 w-full">
                <div className="col-12 md:col-8 flex align-items-center">
                  <label className="text-primary" htmlFor="cardPinNumber">
                    Kart Parolası
                  </label>
                </div>
                <div className="col-12 md:col-4">
                  <div className="login-password">
                    <Password
                      inputId="cardPinNumber"
                      value={formik.values.cardPinNumber}
                      onChange={(e) => formik.setFieldValue("cardPinNumber", e.target.value)}
                      placeholder={"Kart Parolası"}
                      className="w-full"
                      toggleMask={true}
                      feedback={false}
                      inputClassName={classNames("w-full p-3 inline", {
                        "p-invalid": FormikValueIsValid(formik, "cardPinNumber")
                      })}
                    />
                  </div>

                  {FormikValueIsValid(formik, "cardPinNumber") && (
                    <div className="p-error mt-3">{formik.errors.cardPinNumber}</div>
                  )}
                </div>

                <div className="col-12 md:col-8 flex align-items-center gap-2">
                  <label className="text-primary" htmlFor="sessionPin">
                    Oturum Şifreleme Pini
                  </label>
                  <Tooltip target=".custom-target-iconnn" />
                  <i
                    className="custom-target-iconnn pi pi-info-circle text-primary"
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                    data-pr-tooltip="Uyap Oturumlarınız burada belirlediğiniz pin ile şifrelenecektir. 
                    Bağlanmak isteyen kullanıcılar da bu pin'i kullanarak oturum açabilecektir."
                    data-pr-position="top"
                    // data-pr-at="right top"
                    // data-pr-my="left center-2"
                  ></i>
                </div>
                <div className="col-12 md:col-4">
                  <div className="login-password">
                    <Password
                      inputId="sessionPin"
                      value={formik.values.sessionPin}
                      onChange={(e) => formik.setFieldValue("sessionPin", e.target.value)}
                      placeholder={"Oturum Şifreleme Pini"}
                      className="w-full"
                      toggleMask={true}
                      feedback={false}
                      inputClassName={classNames("block w-full p-3", {
                        "p-invalid": FormikValueIsValid(formik, "sessionPin")
                      })}
                    />
                  </div>

                  {FormikValueIsValid(formik, "sessionPin") && (
                    <div className="p-error mt-3">{formik.errors.sessionPin}</div>
                  )}
                </div>

                <div className="col-12 md:col-8 flex align-items-center gap-2">
                  <label className="text-primary" htmlFor="maxCookieSize">
                    Açılacak Maksimum Oturum Sayısı
                  </label>
                  <Tooltip target=".custom-target-iconn" />
                  <i
                    className="custom-target-iconn pi pi-info-circle text-primary"
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                    data-pr-tooltip="Pratik Portal dışından Avukat Portal'a giriş yapacak iseniz açılacak maksimum oturum sayısı ile Pratik Portal dışından açılacak oturum sayısı toplamının 5'ten fazla olmamasına dikkat ediniz."
                    data-pr-position="top"
                    // data-pr-at="right top"
                    // data-pr-my="left center-2"
                  ></i>
                </div>
                <div className="col-12 md:col-4">
                  <div className="w-full">
                    <InputNumber
                      // id="maxCookieSize"
                      inputId="maxCookieSize"
                      min={1}
                      max={5}
                      showButtons
                      mode="decimal"
                      value={formik.values.maxCookieSize}
                      onChange={(e: any) => {
                        formik.setFieldValue("maxCookieSize", e.value);
                      }}
                      className={classNames("mt-2", {
                        "p-invalid": FormikValueIsValid(formik, "maxCookieSize")
                      })}
                      inputClassName="w-full"
                    />
                    {FormikValueIsValid(formik, "maxCookieSize") && (
                      <div className="p-error mt-3">{formik.errors.maxCookieSize}</div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="grid w-full mt-3">
              <div className="col-12">
                <div className="flex justify-content-end">
                  <Button label={"Bağlan"} className="md:px-7" type="button" onClick={() => formik.submitForm()} />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="grid w-full mt-3">
          <div className="col-12 text-xl font-semibold">Uyap Paylaşımları</div>
          {hub.sessions.lawyerSessions.length === 0 && (
            <div key={0} className="col-12 text-center mt-3">
              Paylaşılan bir uyap oturumu bulunamadı
            </div>
          )}
          {hub.sessions.lawyerSessions.length > 0 && (
            <div className="col-12" key={1}>
              <DataTable value={[...hub.sessions.lawyerSessions] ?? []} tableStyle={{ width: "100%" }}>
                <Column field="lawyerName" header="Avukat"></Column>
                <Column
                  field="name"
                  header="Paylaşan Kullanıcı"
                  body={(rowData: LawyerSessionModel) => {
                    return <div>{rowData.userSharingSession.userName}</div>;
                  }}
                ></Column>
                <Column field="maxCookieSize" header="Max Oturum Sayısı"></Column>
                <Column
                  field=""
                  header=""
                  className="px-2"
                  body={(rowData: LawyerSessionModel) => {
                    if (formik.values.amISharingUyapSession) return <></>;
                    return (
                      <div className="flex gap-3 justify-content-end">
                        {hub.getBuroSettings().connectedSessionLawyerName === "" && (
                          <div className="login-password w-10rem">
                            <Password
                              inputId={rowData.lawyerName + "_sessionPin"}
                              placeholder={"Oturum Pini"}
                              className="w-full"
                              toggleMask={true}
                              feedback={false}
                              inputClassName="w-full p-3"
                            />
                          </div>
                        )}
                        {hub.getBuroSettings().connectedSessionLawyerName === "" && (
                          <Button
                            label="Bağlan"
                            disabled={
                              formik.values.amISharingUyapSession &&
                              rowData.lawyerName === hub.getBuroSettings().connectedSessionLawyerName
                            }
                            onClick={async () => {
                              const sessionPin = (
                                document.getElementById(rowData.lawyerName + "_sessionPin") as HTMLInputElement
                              ).value;
                              if (!sessionPin) {
                                toast.show("Oturum pini boş bırakılamaz", "error");
                                return;
                              }
                              hub.connectSession(rowData.lawyerName, sessionPin);
                              await accountService.ConnectedUyap(rowData.lawyerName);
                              setIsVisibleDiyalog(false);
                            }}
                          />
                        )}

                        {hub.getBuroSettings().connectedSessionLawyerName &&
                          rowData.lawyerName === hub.getBuroSettings().connectedSessionLawyerName && (
                            <Button
                              label="Bağlantıyı Kes"
                              disabled={
                                formik.values.amISharingUyapSession &&
                                rowData.lawyerName === hub.getBuroSettings().connectedSessionLawyerName
                              }
                              severity="danger"
                              onClick={async () => {
                                setIsVisibleDiyalog(false);
                                hub.getBuroSettings().cardPassword = "";
                                hub.getBuroSettings().sessionPin = "";
                                hub.getBuroSettings().maxCookieSize = 4;
                                hub.getBuroSettings().connectedSessionLawyerName = "";
                                hub.releaseUyapSession("");
                                toast.show("Uyap oturumu kapandı.", "info");
                              }}
                            />
                          )}
                      </div>
                    );
                  }}
                ></Column>
              </DataTable>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AppMenu;
