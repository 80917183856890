import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { UserDto } from "../../../services/account/account.dto";
import { UpdateMainUserDto } from "../../../services/admin/admin.dto";
import { useToast } from "../../../utils/toast";
import { InputText } from "primereact/inputtext";
import FormikValueIsValid from "../../../utils/FormikValueIsValid";
import DropdownDatas from "../../../utils/DropdownValues";
import { useContext } from "react";
import { LayoutContext } from "../../../layout/context/layoutcontext";

const UpsertMainUserDiyalog = (props: {
  user: UserDto;
  visible: boolean;
  onHide: () => void;
  onOk: (updateMainUserDto: UpdateMainUserDto) => void;
}) => {
  const user: UserDto = props.user;
  const visible: boolean = props.visible;
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext);

  const formik = useFormik<UpdateMainUserDto>({
    initialValues: { ...user },
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateMainUserDto> = {};
      if (!data.userName) {
        errors.userName = "Kullanıcı adı zorunludur";
      }

      if (!data.firstName) {
        errors.firstName = "Ad alanı zorunludur";
      }

      if (!data.lastName) {
        errors.lastName = "Soyad alanı zorunludur";
      }

      if (!data.address) {
        errors.address = "Adres alanı zorunludur";
      }

      if (!data.tc) {
        errors.tc = "TC kimlik numarası zorunludur";
      }

      if (!data.email) {
        errors.email = "Email alanı zorunludur";
      }

      if (!data.allowedLawyerTc) {
        errors.allowedLawyerTc = "İzin verilen avukatlar tc'si zorunludur";
      }

      return errors;
    },
    onSubmit: () => {
      props.onOk(formik.values);
      setBlocked(false);
    }
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={"Ana Kullanıcı Güncelle"}
      onHide={() => {
        formik.resetForm();
        props.onHide();
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              formik.resetForm();
              props.onHide();
            }}
          />
          <Button label={"Kaydet"} className="md:px-6 justify-content-center" onClick={formik.submitForm} />
        </div>
      }
      className=""
      style={{ width: "75vw" }}
      maximizable
    >
      <div className="grid">
        <div className="col-12 md:col-6">
          <label htmlFor="firstName" className="text-primary">
            İsim
          </label>
          <InputText
            id="firstName"
            type="text"
            placeholder="İsim"
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "firstName")
            })}
            value={formik.values.firstName}
            onChange={(e) => formik.setFieldValue("firstName", e.target.value.toLocaleUpperCase("tr"))}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "firstName") && <div className="p-error mt-3">{formik.errors.firstName}</div>}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="lastName" className="text-primary">
            Soy isim
          </label>
          <InputText
            id="lastName"
            type="text"
            placeholder="Soy isim"
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "lastName")
            })}
            value={formik.values.lastName}
            onChange={(e) => formik.setFieldValue("lastName", e.target.value.toLocaleUpperCase("tr"))}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "lastName") && <div className="p-error mt-3">{formik.errors.lastName}</div>}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="userName" className="text-primary">
            Kullanıcı Adı
          </label>
          <InputText
            id="userName"
            type="text"
            placeholder="Kullanıcı Adı"
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "userName")
            })}
            value={formik.values.userName}
            onChange={(e) => formik.setFieldValue("userName", e.target.value)}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "userName") && <div className="p-error mt-3">{formik.errors.userName}</div>}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="email" className="text-primary">
            E-posta Adresi
          </label>
          <InputText
            id="email"
            type="email"
            placeholder="E-posta Adresi"
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "email")
            })}
            value={formik.values.email}
            onChange={(e) => formik.setFieldValue("email", e.target.value)}
            style={{ padding: "1rem" }}
            autoComplete="email"
          />
          {FormikValueIsValid(formik, "email") && <div className="p-error mt-3">{formik.errors.email}</div>}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="tc" className="text-primary">
            {"TC Kimlik Numarası"}
          </label>
          <InputText
            id="tc"
            keyfilter="int"
            maxLength={11}
            placeholder={"TC Kimlik Numarası"}
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tc")
            })}
            value={formik.values.tc}
            onChange={(e) => formik.setFieldValue("tc", e.target.value)}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "tc") && <div className="p-error mt-3">{formik.errors.tc}</div>}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="vergiNumarasi" className="text-primary">
            Vergi Numarasi
          </label>
          <InputText
            id="vergiNumarasi"
            type="string"
            placeholder="Vergi Numarasi"
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "vergiNumarasi")
            })}
            value={formik.values.vergiNumarasi}
            onChange={(e) => formik.setFieldValue("vergiNumarasi", e.target.value)}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "vergiNumarasi") && (
            <div className="p-error mt-3">{formik.errors.vergiNumarasi}</div>
          )}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="vergiDairesi" className="text-primary">
            Vergi Dairesi
          </label>
          <InputText
            id="vergiDairesi"
            type="string"
            placeholder="Vergi Dairesi"
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "vergiDairesi")
            })}
            value={formik.values.vergiDairesi}
            onChange={(e) => formik.setFieldValue("vergiDairesi", e.target.value)}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "vergiDairesi") && (
            <div className="p-error mt-3">{formik.errors.vergiDairesi}</div>
          )}
        </div>

        <div className="col-12 md:col-6">
          <label className="text-primary" htmlFor="bagliBulunulanBaro">
            Bağlı Bulunduğunuz Baro
          </label>
          <Dropdown
            id="bagliBulunulanBaro"
            name="bagliBulunulanBaro"
            inputId="bagliBulunulanBaro"
            filter
            value={formik.values.bagliBulunulanBaro}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("bagliBulunulanBaro", e.value);
            }}
            options={DropdownDatas.getBaro()}
            optionLabel="label"
            optionValue="value"
            placeholder="Bağlı Bulunduğunuz Baro"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "bagliBulunulanBaro")
            })}
          />
          {FormikValueIsValid(formik, "bagliBulunulanBaro") && (
            <div className="p-error mt-3">{formik.errors.bagliBulunulanBaro}</div>
          )}
        </div>
        <div className="col-12 md:col-6">
          <label htmlFor="baroSicilNumarasi" className="text-primary">
            Baro Sicil Numarası
          </label>
          <InputText
            id="baroSicilNumarasi"
            type="string"
            placeholder="Baro Sicil Numarası"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "baroSicilNumarasi")
            })}
            value={formik.values.baroSicilNumarasi}
            onChange={(e) => formik.setFieldValue("baroSicilNumarasi", e.target.value)}
          />
          {FormikValueIsValid(formik, "baroSicilNumarasi") && (
            <div className="p-error mt-3">{formik.errors.baroSicilNumarasi}</div>
          )}
        </div>

        {/* 
              <div className="col-12 md:col-6">
                <label htmlFor="icHatDahilisi" className="text-primary">
                  İç Hat Dahilisi
                </label>
                <InputText
                  id="icHatDahilisi"
                  type="string"
                  placeholder="İç Hat Dahilisi"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "icHatDahilisi"),
                  })}
                  value={formik.values.icHatDahilisi}
                  onChange={(e) =>
                    formik.setFieldValue("icHatDahilisi", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "icHatDahilisi") && (
                  <div className="p-error mt-3">
                    {formik.errors.icHatDahilisi}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label
                  htmlFor="garantiHtsKullaniciAdi"
                  className="text-primary"
                >
                  Garanti HTS Kullanıcı Adı
                </label>
                <InputText
                  id="garantiHtsKullaniciAdi"
                  type="string"
                  placeholder="Garanti HTS Kullanıcı Adı"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsKullaniciAdi"
                    ),
                  })}
                  value={formik.values.garantiHtsKullaniciAdi}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "garantiHtsKullaniciAdi",
                      e.target.value
                    )
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsKullaniciAdi") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsKullaniciAdi}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="garantiHtsBuroKodu" className="text-primary">
                  Garanti HTS Büro Kodu
                </label>
                <InputText
                  id="garantiHtsBuroKodu"
                  type="string"
                  placeholder="Garanti HTS Büro Kodu"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsBuroKodu"
                    ),
                  })}
                  value={formik.values.garantiHtsBuroKodu}
                  onChange={(e) =>
                    formik.setFieldValue("garantiHtsBuroKodu", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsBuroKodu") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsBuroKodu}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="garantiHtsSifresi" className="text-primary">
                  Garanti HTS Şifresi
                </label>
                <InputText
                  id="garantiHtsSifresi"
                  type="string"
                  placeholder="Garanti HTS Şifresi"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsSifresi"
                    ),
                  })}
                  value={formik.values.garantiHtsSifresi}
                  onChange={(e) =>
                    formik.setFieldValue("garantiHtsSifresi", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsSifresi") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsSifresi}
                  </div>
                )}
              </div> */}

        <div className="col-12">
          <label htmlFor="address" className="text-primary">
            Adres
          </label>
          <InputTextarea
            id="address"
            placeholder="Adres"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "address")
            })}
            value={formik.values.address}
            onChange={(e) => formik.setFieldValue("address", e.target.value)}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "address") && <div className="p-error mt-3">{formik.errors.address}</div>}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertMainUserDiyalog;
