import moment from "moment";
import { TopluSorguModel } from "../pages/toplu-sorgulama/TopluSorgu.dto";
import * as XLSX from "xlsx";

type GenericObject = { [key: string]: any };
const localFormatType = process.env.REACT_APP_DATE_FORMAT || "DD.MM.YYYY";
export const ExportFile = {
  SaveAsExcelFile(worksheetData: any, fileName: any) {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
      const columnWidths = worksheetData[0].map(() => ({ wch: 15 }));
      worksheet["!cols"] = columnWidths;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });

      import("file-saver").then((module) => {
        if (module && module.default) {
          let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          let EXCEL_EXTENSION = ".xlsx";
          const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
        }
      });
    });
  },

  generateSheetFromList<T extends GenericObject>(list: T[], headers: string[], xlsx: any): XLSX.WorkSheet {
    const data = list.map((item) => headers.map((header) => item[header as keyof T]));
    const sheetData = [headers, ...data];
    return xlsx.utils.aoa_to_sheet(sheetData);
  },

  // Toplu Excel oluşturma fonksiyonu
  CeateTopluExcelFile(data: TopluSorguModel, fileName: string) {
    import("xlsx").then((xlsx) => {
      const workbook = xlsx.utils.book_new();

      // Sorunlu Listesi
      if (data.sorunluVeOlumsuzListeleri && data.sorunluVeOlumsuzListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Sorgu Türü",
          "Açıklama"
        ];

        const filteredData = data.sorunluVeOlumsuzListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          sorguTuru: data.sorguTuru,
          aciklama: data.aciklama
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Olumsuz Sonuclar");
      }

      // Tahsilat Kalanı Listesi
      if (data.tahsilatKalaniListeleri && data.tahsilatKalaniListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Kalan Tahsilat",
          "Kalan Masraf",
          "Kalan Diğer",
          "Kalan Toplam"
        ];

        const filteredData = data.tahsilatKalaniListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          sadeceToplam: data.sadeceToplam,
          sadeceMasraf: data.sadeceMasraf,
          sadeceDiger: data.sadeceDiger,
          tahsilat: data.tahsilat
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Tahsilat Kalanı");
      }

      // Mernis Listesi
      if (data.mernisListeleri && data.mernisListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Adres",
          "İl",
          "İlçe",
          "Tescil Tarihi",
          "Beyan Tarihi",
          "Taşınma Tarihi",
          "Ölüm Tarihi"
        ];
        const filteredData = data.mernisListeleri.map((item) => ({
          birimAdi: item.ortakDegerler.birimAdi,
          dosyaNo: item.ortakDegerler.dosyaNo,
          alacakliAdi: item.ortakDegerler.alacakliAdi,
          borcluAdi: item.ortakDegerler.borcluAdi,
          borcluTCVKNo: item.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: item.ortakDegerler.dosyaDurumu,
          adres: item.adres,
          il: item.il,
          ilce: item.ilce,
          tescilTarihi: item.tescilTarihi ? moment(item.tescilTarihi).format(localFormatType) : "",
          beyanTarihi: item.beyanTarihi ? moment(item.beyanTarihi).format(localFormatType) : "",
          tasinmaTarihi: item.tasinmaTarihi ? moment(item.tasinmaTarihi).format(localFormatType) : "",
          olumTarihi: item.olumTarihi ? moment(item.olumTarihi).format(localFormatType) : ""
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Mernis");
      }

      // Mersis Listesi
      if (data.mersisListeleri && data.mersisListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Mersis Adresi"
        ];

        const filteredData = data.mersisListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          adres: data.adres
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Mersis");
      }

      // Iski Listesi
      if (data.gibListeleri && data.gibListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "İski Adresi"
        ];

        const filteredData = data.gibListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          adres: data.adres
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "ISKI");
      }

      // GIB Listesi
      if (data.gibListeleri && data.gibListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Gib Adresi"
        ];

        const filteredData = data.gibListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          adres: data.adres
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "GIB");
      }

      // GSM Listesi
      if (data.gsmListeleri && data.gsmListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Operatör",
          "Adres"
        ];

        const filteredData = data.gsmListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          operator: data.operator,
          adres: data.adres
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "GSM");
      }

      // Dis Isleri Listesi
      if (data.disIsleriListeleri && data.disIsleriListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Adres Türü",
          "Şehir",
          "Adres",
          "Posta Kodu"
        ];

        const filteredData = data.disIsleriListeleri.map((item) => ({
          birimAdi: item.ortakDegerler.birimAdi,
          dosyaNo: item.ortakDegerler.dosyaNo,
          alacakliAdi: item.ortakDegerler.alacakliAdi,
          borcluAdi: item.ortakDegerler.borcluAdi,
          borcluTCVKNo: item.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: item.ortakDegerler.dosyaDurumu,
          adresTuru: item.adresTuru,
          sehir: item.sehir,
          adres: item.adres,
          postaKodu: item.postaKodu
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Dis Isleri");
      }

      // SSK Çalışanı Listesi
      if (data.sskCalisaniListeleri && data.sskCalisaniListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "İş Yeri",
          "İş Yeri Adresi",
          "İş Yeri İli",
          "İş Yeri Sicil",
          "Dönem/Ay",
          "Durum",
          "Sicil No",
          "İlk İşe Giriş Tarihi",
          "Son İşe Giriş Tarihi",
          "İşten Çıkış Tarihi"
        ];

        const filteredData = data.sskCalisaniListeleri.map((item) => ({
          birimAdi: item.ortakDegerler.birimAdi,
          dosyaNo: item.ortakDegerler.dosyaNo,
          alacakliAdi: item.ortakDegerler.alacakliAdi,
          borcluAdi: item.ortakDegerler.borcluAdi,
          borcluTCVKNo: item.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: item.ortakDegerler.dosyaDurumu,
          isYeri: item.isYeri,
          isYeriAdresi: item.isYeriAdresi,
          isyeriIli: item.isyeriIli,
          isYeriSicil: item.isYeriSicil,
          donemAy: item.donemAy,
          sgkDurum: item.sgkDurum,
          sgkSicilNo: item.sgkSicilNo,
          ilkIseGirisTarihi: item.ilkIseGirisTarihi ? moment(item.ilkIseGirisTarihi).format(localFormatType) : "",
          sonIseGirisTarihi: item.sonIseGirisTarihi ? moment(item.sonIseGirisTarihi).format(localFormatType) : "",
          istenCıkısTarihi: item.istenCıkısTarihi ? moment(item.istenCıkısTarihi).format(localFormatType) : ""
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "SSK Çalışanı");
      }

      // Kamu Çalışanı Listesi
      if (data.kamuCalisaniListeleri && data.kamuCalisaniListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Kurum",
          "Kurum İl",
          "Kurum İlçe",
          "İşe Başlama Tarihi",
          "Ünvan",
          "Kurum Durum",
          "Açıklama"
        ];

        const filteredData = data.kamuCalisaniListeleri.map((item) => ({
          birimAdi: item.ortakDegerler.birimAdi,
          dosyaNo: item.ortakDegerler.dosyaNo,
          alacakliAdi: item.ortakDegerler.alacakliAdi,
          borcluAdi: item.ortakDegerler.borcluAdi,
          borcluTCVKNo: item.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: item.ortakDegerler.dosyaDurumu,
          kurum: item.kurum,
          kurumIl: item.kurumIl,
          kurumIlce: item.kurumIlce,
          iseBaslamaTarihi: item.iseBaslamaTarihi ? moment(item.iseBaslamaTarihi).format(localFormatType) : "",
          unvani: item.unvani,
          kurumDurum: item.kurumDurum,
          aciklama: item.aciklama
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Kamu Çalışanı");
      }

      // Kamu Emekli Listesi
      if (data.kamuEmeklisiListeleri && data.kamuEmeklisiListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Emekli Sicil No",
          "Aylık Başlama Tarihi",
          "Ünvan",
          "Derece",
          "Yakınlık",
          "Kademe",
          "Banka Adı",
          "Banka İli",
          "Şube Adı",
          "Aylık Payda",
          "Aylık Pay",
          "Aylık Durum",
          "Aylık Tur",
          "Aylık Periyot"
        ];

        const filteredData = data.kamuEmeklisiListeleri.map((item) => ({
          birimAdi: item.ortakDegerler.birimAdi,
          dosyaNo: item.ortakDegerler.dosyaNo,
          alacakliAdi: item.ortakDegerler.alacakliAdi,
          borcluAdi: item.ortakDegerler.borcluAdi,
          borcluTCVKNo: item.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: item.ortakDegerler.dosyaDurumu,
          sicilNo: item.sicilNo,
          aylikBaslamaTarihi: item.aylikBaslamaTarihi ? moment(item.aylikBaslamaTarihi).format(localFormatType) : "",
          unvan: item.unvan,
          derece: item.derece,
          yakinlik: item.yakinlik,
          kademe: item.kademe,
          bankaAdi: item.bankaAdi,
          bankaIli: item.bankaIli,
          subeAdi: item.subeAdi,
          aylikOraniPayda: item.aylikOraniPayda,
          aylikOraniPay: item.aylikOraniPay,
          aylikDurumu: item.aylikDurumu,
          aylikTur: item.aylikTur,
          aylikPeriyot: item.aylikPeriyot
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Kamu Emekli");
      }

      // Bagkur Calisani Listesi
      if (data.bagkurCalisaniListeleri && data.bagkurCalisaniListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Bağkur No",
          "Tescil Tarihi",
          "Meslek İli",
          "Meslek İlçe",
          "Terk Tarihi",
          "Terk Açıklama"
        ];

        const filteredData = data.bagkurCalisaniListeleri.map((item) => ({
          birimAdi: item.ortakDegerler.birimAdi,
          dosyaNo: item.ortakDegerler.dosyaNo,
          alacakliAdi: item.ortakDegerler.alacakliAdi,
          borcluAdi: item.ortakDegerler.borcluAdi,
          borcluTCVKNo: item.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: item.ortakDegerler.dosyaDurumu,
          bagkurNo: item.bagkurNo,
          tescilTarihi: item.tescilTarihi && moment(item.tescilTarihi).isValid() ? moment(item.tescilTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "",
          meslekIli: item.meslekIli,
          meslekIlce: item.meslekIlce,
          terkTarihi: item.terkTarihi && moment(item.terkTarihi).isValid() ? moment(item.terkTarihi).format(process.env.REACT_APP_DATE_FORMAT) : "",
          terkAciklama: item.terkAciklama
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Bagkur Calisani");
      }

      // İş Yeri Verileri
      if (data.sgkIsyeriListeleri && data.sgkIsyeriListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Eski Şube Kodu",
          "İl",
          "İş Yeri Açıklama",
          "İş Yeri Adres",
          "İş Yeri Merkez Adresi",
          "İş Yeri Sicil",
          "İş Yeri Ünvanı",
          "Yeni Şube Kodu",
          "Sıra No",
          "Adres",
          "Mahiyet"
        ];

        const filteredData = data.sgkIsyeriListeleri.map((item) => ({
          birimAdi: item.ortakDegerler.birimAdi,
          dosyaNo: item.ortakDegerler.dosyaNo,
          alacakliAdi: item.ortakDegerler.alacakliAdi,
          borcluAdi: item.ortakDegerler.borcluAdi,
          borcluTCVKNo: item.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: item.ortakDegerler.dosyaDurumu,
          eskiSubeKodu: item.eskiSubeKodu,
          il: item.il,
          isYeriAciklama: item.isYeriAciklama,
          isYeriAdres: item.isYeriAdres,
          isYeriMerkezAdresi: item.isYeriMerkezAdresi,
          isYeriSicil: item.isYeriSicil,
          isYeriUnvani: item.isYeriUnvani,
          yeniSubeKodu: item.yeniSubeKodu,
          siraNo: item.siraNo,
          adres: item.adres,
          mahiyet: item.mahiyet
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Sgk Is Yeri");
      }

      // Egm Verileri
      if (data.egmListeleri && data.egmListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Plaka",
          "Marka",
          "Model",
          "Tipi",
          "Renk",
          "Cins",
          "Mahrumiyet Sonuc",
          "Son Haciz Tarihi",
          "Son Yakalama Tarihi",
          "Şerh Sayısı",
          "Haciz",
          "Yakalama",
          "Haciz Yakalama",
          "İhtiyati Haciz",
          "Rehin",
          "Rehnin Paraya Çevirilmesi",
          "Mülkiyet",
          "İcra Satış Şerhi",
          "Satışa Arz",
          "Kesin Satış",
          "İhtedbir",
          "Plaka Değişikliği",
          "İhtedbir 2",
          "Diğer",
          "TRF Men 20e",
          "ÖTV Muaf 3y",
          "İflas Şerhi"
        ];

        const filteredData = data.egmListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          plaka: data.plaka,
          marka: data.marka,
          model: data.model,
          tipi: data.ticariAd,
          renk: data.renk,
          cins: data.cins,
          mahrumiyetSonuc: data.mahrumiyetSonuc,
          sonHacizTarihi: data.sonHacizTarihi ? moment(data.sonHacizTarihi).format(localFormatType) : "",
          sonYakalamaTarihi: data.sonYakalamaTarihi ? moment(data.sonYakalamaTarihi).format(localFormatType) : "",
          serhSayisi: data.serhSayisi,
          haciz: data.lblHaciz,
          yakalama: data.lblYakalama,
          hacizYakalama: data.lblHacizYakalama,
          ihtiyatiHaciz: data.lblIhtiyatiHaciz,
          rehin: data.lblRehin,
          rehninParayaCevirilmesi: data.lblRehninPC,
          mulkiyet: data.lblMulkiyet,
          icraSatisSerhi: data.lblIcraSatisSerhi,
          satisaArz: data.lblSatisArz,
          kesinSatis: data.lblKesinSatis,
          ihtedbir: data.lblIhtedbir,
          plakaDegisikligi: data.lblPlakaDeg,
          ihtedbir2: data.lblIhtedbir2,
          diger: data.lblDiger,
          trfMen20e: data.lblTrfMen20e,
          otvMuaf3y: data.lblOtvMuaf3y,
          iflasSerhi: data.lblIflasSerhi
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "EGM");
      }

      // Posta Çeki Verileri
      if (data.postaCekiListeleri && data.postaCekiListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Posta Çeki"
        ];

        const filteredData = data.postaCekiListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          postaCeki: data.postaCeki
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Posta Ceki");
      }

      // Takbis Verileri
      if (data.takbisListeleri && data.takbisListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Tapu Müdürlüğü",
          "İl / İlçe",
          "Mahalle",
          "Vasfı",
          "Yüzölçümü",
          "Mevki",
          "Ada No",
          "Parsel No",
          "Hisse Açıklama",
          "Hisse Tipi",
          "Durum",
          "Taşınmaz Id",
          "Hisse Id",
          "Son Haciz Tarihi",
          "Serh Sayısı",
          "Beyan Sayısı"
        ];

        const filteredData = data.takbisListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          tapuMudurlugu: data.tapuMudurlugu,
          ilIlce: data.ilIlce,
          mahalle: data.mahalle,
          vasfi: data.vasfi,
          yuzolcumu: data.yuzolcumu,
          mevkii: data.mevkii,
          adaNo: data.adaNo,
          parselNo: data.parselNo,
          hisseAciklama: data.hisseAciklama,
          hisseTip: data.hisseTip,
          tapuBolumDurum: data.tapuBolumDurum,
          tasinmazId: data.tasinmazId,
          hisseId: data.hisseId,
          sonHacizTarihi: data.sonHacizTarihi ? moment(data.sonHacizTarihi).format(localFormatType) : "",
          serhSayisi: data.serhSayisi,
          beyanSayisi: data.beyanSayisi
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Takbis");
      }

      // Sgk Haciz Verileri
      if (data.sgkHacizListeleri && data.sgkHacizListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Kurum"
        ];

        const filteredData = data.sgkHacizListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          kisiTipString: data.kisiTipString
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Sgk Haciz");
      }

      // Icra Dosya Verileri
      if (data.icraDosyasiListeleri && data.icraDosyasiListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Açılış",
          "Kapanış",
          "Birim Adı/Dosya",
          "Takip Türü",
          "Takip Yolu / Şekli",
          "Durumu"
        ];

        const filteredData = data.icraDosyasiListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          acilis: data.acilis,
          kapanis: data.kapanis,
          birimAdiDosya: data.birimAdiDosya,
          takipTuru: data.takipTuru,
          takipYoluSekli: data.takipYoluSekli,
          durumu: data.durumu
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Icra Dosya");
      }

      // Banka Verileri
      if (data.bankaListeleri && data.bankaListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Banka Adı"
        ];

        const filteredData = data.bankaListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          bankaAdi: data.bankaAdi
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Banka");
      }

      // Taraf Verileri
      if (data.tarafListeleri && data.tarafListeleri.length > 0) {
        const headers = [
          "Birim Adı",
          "Dosya No",
          "Alacaklı Adı/Ünvanı",
          "Borçlu Adı/Ünvanı",
          "Borçlu TC/VK No",
          "Dosya Durumu",
          "Rol"
        ];

        const filteredData = data.tarafListeleri.map((data) => ({
          birimAdi: data.ortakDegerler.birimAdi,
          dosyaNo: data.ortakDegerler.dosyaNo,
          alacakliAdi: data.ortakDegerler.alacakliAdi,
          borcluAdi: data.ortakDegerler.borcluAdi,
          borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
          dosyaDurumu: data.ortakDegerler.dosyaDurumu,
          rol: data.rol
        }));

        const worksheetData = [headers, ...filteredData.map(Object.values)];
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Taraf");
      }

      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });

      import("file-saver").then((module) => {
        if (module && module.default) {
          let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          let EXCEL_EXTENSION = ".xlsx";
          const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
        }
      });
    });
  },

  SaveAsCSVFile: (csvContent: string, fileName: string) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        const CSV_TYPE = "text/csv;charset=utf-8;";
        const CSV_EXTENSION = ".csv";
        const data = new Blob([csvContent], {
          type: CSV_TYPE
        });
        module.default.saveAs(data, fileName + new Date().getTime() + CSV_EXTENSION);
      }
    });
  }
};
