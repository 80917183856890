import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguKamuCalisanModel } from "../TopluSorgu.dto";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";

const KamuCalisaniDataTable = (props: { tableData: TopluSorguKamuCalisanModel[] }) => {
  const kamuCalisaniVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguKamuCalisanModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      kurum: commonFilterContains,
      kurumIl: commonFilterContains,
      kurumIlce: commonFilterContains,
      iseBaslamaTarihi: commonFilterDateIs,
      unvani: commonFilterContains,
      kurumDurum: commonFilterContains,
      aciklama: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Kurum",
      "Kurum İl",
      "Kurum İlçe",
      "İşe Başlama Tarihi",
      "Ünvan",
      "Kurum Durum",
      "Açıklama"
    ];

    const filteredData = kamuCalisaniVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      kurum: data.kurum,
      kurumIl: data.kurumIl,
      kurumIlce: data.kurumIlce,
      iseBaslamaTarihi:
        data.iseBaslamaTarihi && moment(data.iseBaslamaTarihi).isValid()
          ? moment(data.iseBaslamaTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
      unvani: data.unvani,
      kurumDurum: data.kurumDurum,
      aciklama: data.aciklama
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluTahsilatKamuCalisani");
  };

  return (
    <div>
      <DataTable
        value={kamuCalisaniVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="index"
        showGridlines
        emptyMessage="Kamu Çalışanı Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Kamu Çalışanı Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "kurum",
          "kurumIl",
          "kurumIlce",
          "iseBaslamaTarihi",
          "unvani",
          "kurumDurum",
          "aciklama"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filter
          filterField="ortakDegerler.dosyaNo"
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filter
          filterField="ortakDegerler.alacakliAdi"
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filter
          filterField="ortakDegerler.borcluAdi"
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filter
          filterField="ortakDegerler.borcluTCVKNo"
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filter
          filterField="ortakDegerler.dosyaDurumu"
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Kurum"
          field="kurum"
          sortable
          filter
          filterField="kurum"
          filterPlaceholder="Kuruma göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kuruma göre filtreleyin</div>}
        />
        <Column
          header="Kurum İl"
          field="kurumIl"
          sortable
          filter
          filterField="kurumIl"
          filterPlaceholder="Kurum İl'ine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kurum İl'ine göre filtreleyin</div>}
        />
        <Column
          header="Kurum İlçe"
          field="kurumIlce"
          sortable
          filter
          filterField="kurumIlce"
          filterPlaceholder="Kurum İlçe'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kurum İlçe'ye göre filtreleyin</div>}
        />
        <Column
          header="İşe Başlama Tarihi"
          field="iseBaslamaTarihi"
          sortable
          body={(rowData) =>
            rowData.iseBaslamaTarihi && moment(rowData.iseBaslamaTarihi).isValid() ? (
              moment(rowData.iseBaslamaTarihi).format(process.env.REACT_APP_DATE_FORMAT)
            ) : (
              <></>
            )
          }
          filter
          filterField="iseBaslamaTarihi"
          filterPlaceholder="İşe Başlama Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İşe Başlama Tarihine göre filtreleyin</div>}
        />
        <Column
          header="Ünvan"
          field="unvani"
          sortable
          filter
          filterField="unvani"
          filterPlaceholder="Ünvana göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Ünvana göre filtreleyin</div>}
        />
        <Column
          header="Kurum Durum"
          field="kurumDurum"
          sortable
          filter
          filterField="kurumDurum"
          filterPlaceholder="Kurum Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kurum Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Açıklama"
          field="aciklama"
          sortable
          filter
          filterField="aciklama"
          filterPlaceholder="Açıklamaya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Açıklamaya göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default KamuCalisaniDataTable;
