import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import accountService from "../../services/account/account.service";
import { ConfirmEmailDto } from "../../services/account/account.dto";
import { useToast } from "../../utils/toast";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../layout/context/layoutcontext";

const EmailConfirmPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const { t } = useTranslation();

  const { setBlocked } = useContext(LayoutContext)

  const token = searchParams.get("token") || "";
  const userId = searchParams.get("id") || "";
  console.log("token", token);

  useEffect(() => {
    if (token === undefined || token === "" || userId === undefined || userId === "") {
      navigate("/login");
      return;
    }

    setBlocked(true)
    const confirmEmailDto: ConfirmEmailDto = {
      token: token,
      userId: userId
    };

    accountService.ConfirmEmail(confirmEmailDto).then((res) => {
      if (res.result && !res.result.error) {
        toast.show(t("EmailConfirmed"), "success");
        setBlocked(false)
        navigate("/login", { replace: true });
        return;
      }
      navigate("/login", { replace: true });
    }).finally(() => setBlocked(false));
  }, []);

  return <></>;
};

export default EmailConfirmPage;
