import { FC, useEffect, useRef, useState } from "react"
import Separator from "./Separator"
import { SupportMessageDto } from "../../../services/support/support-message.dto";
import moment from "moment";
import { SupportDto, SupportStatus } from "../../../services/support/support.dto";
import { ScrollPanel } from "primereact/scrollpanel";
import { HttpResponse, ResponseDto } from "../../../services/base/base.dto";
import { UserDto } from "../../../services/account/account.dto";
import accountService from "../../../services/account/account.service"

import { Galleria } from "primereact/galleria";
import FileGaleria from "./FileGaleria";

interface IProps {
    data: SupportDto
    isAdminPage: boolean
}

const MessageThread: FC<IProps> = (props): ReturnType<FC> => {
    const { data, isAdminPage } = props
    const messages = data.messages
    let lastDate: Date | undefined;
    const isDateSame = (date1: Date | undefined, date2: Date) => {
        if (!date1) return false
        if (moment(date1).format("DD.MM.YYYY") != moment(date2).format("DD.MM.YYYY"))
            return true
        return false;
    };


    const assignLastDate = (newDate: Date) => {
        lastDate = new Date(newDate)
        return <></>
    }

    if (!messages.length) {
        return (
            <div className="shadow-1 card border-round-sm">
                <div style={{ overflowY: "scroll", height: '50px' }} className="flex align-items-center justify-content-center mb-3">
                    Bu destek kaydında bir mesaj bulunmuyor.
                </div>
            </div>)
    }
    return (<ScrollPanel style={{ width: '100%', maxHeight: "250px" }} className="mb-3 custombar2">
        {messages.map((message: SupportMessageDto, index) => {

            if (message.isUserMessage == isAdminPage)
                return (
                    <>
                        <div key={index}>
                            <Separator key={index} display={isDateSame(lastDate, new Date(message.dateTime))} date={message.dateTime} />
                            <div className="mb-5 flex justify-content-start" style={{ maxWidth: '90%' }}>
                                <div className="md:col-5 mt-2">
                                    <span className="message__date-first text-sm">{moment(message.dateTime).format('DD.MM.YYYY HH:mm')}</span>
                                    <div className="border-round-sm message__box-first border-radius-md mt-2 p-3">
                                        <div className="mb-1">{message.message}</div>
                                        <FileGaleria files={message.files} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {assignLastDate(message.dateTime)}
                    </>
                )
            return (<>
                <div key={index}>
                    <Separator display={isDateSame(lastDate, new Date(message.dateTime))} date={message.dateTime} />
                    <div className="mb-5 flex justify-content-end">
                        <div className="md:col-5 mt-2 text-right">
                            <span className="text-sm message__date-second">{moment(message.dateTime).format('DD.MM.YYYY HH:mm')}</span>
                            <div className="border-round-sm message__box-second mt-2 p-3">
                                <div className="mb-1">{message.message}</div>
                                <FileGaleria files={message.files} />
                            </div>
                        </div>
                    </div>
                </div>
                {assignLastDate(message.dateTime)}
            </>)

        })}
    </ScrollPanel>)
}

export default MessageThread