import { addLocale } from "primereact/api";

export const AddTrLocaleforPrimeReact = () => {
  addLocale("tr", 
    {
      
        "accept": "Tamam",
        "addRule": "Kural Ekle",
        "am": "ÖÖ",
        "apply": "Uygula",
        "cancel": "Vazgeç",
        "choose": "Seç",
        "chooseDate": "Tarih Seçiniz",
        "chooseMonth": "Ay Seçiniz",
        "chooseYear": "Yıl Seçiniz",
        "clear": "Temiz",
        // "completed": "Tamamlandı",
        "contains": "Barındırır",
        "custom": "Özel",
        "dateAfter": "Tarihten sonra",
        "dateBefore": "Tarihten önce",
        "dateFormat": "dd.mm.yy",
        "dateIs": "Tarih",
        "dateIsNot": "Tarih değildir",
        "dayNames": [
          "Pazar",
          "Pazartesi",
          "Salı",
          "Çarşamba",
          "Perşembe",
          "Cuma",
          "Cumartesi"
        ],
        "dayNamesMin": [
          "Pz",
          "Pt",
          "Sa",
          "Ça",
          "Pe",
          "Cu",
          "Ct"
        ],
        "dayNamesShort": [
          "Paz",
          "Pzt",
          "Sal",
          "Çar",
          "Per",
          "Cum",
          "Cmt"
        ],
        "emptyFilterMessage": "Kullanılabilir seçenek yok",
        "emptyMessage": "Sonuç bulunamadı",
        // "emptySearchMessage": "Sonuç bulunmadı",
        // "emptySelectionMessage": "Öge seçilmedi",
        "endsWith": "Bitiş",
        "equals": "Eşittir",
        "fileSizeTypes": [
          "B",
          "KB",
          "MB",
          "GB",
          "TB",
          "PB",
          "EB",
          "ZB",
          "YB"
        ],
        "filter": "Filtre",
        "firstDayOfWeek": 1,
        "gt": "Daha Fazla",
        "gte": "Daha fazla veya Eşit",
        "lt": "Daha az",
        "lte": "Daha az veya Eşit",
        "matchAll": "Tümüyle eşleşir",
        "matchAny": "Herhangi birine eşleşir",
        "medium": "Orta",
        "monthNames": [
          "Ocak",
          "Şubat",
          "Mart",
          "Nisan",
          "Mayıs",
          "Haziran",
          "Temmuz",
          "Ağustos",
          "Eylül",
          "Ekim",
          "Kasım",
          "Aralık"
        ],
        "monthNamesShort": [
          "Oca",
          "Şub",
          "Mar",
          "Nis",
          "May",
          "Haz",
          "Tem",
          "Ağu",
          "Eyl",
          "Eki",
          "Kas",
          "Ara"
        ],
        "nextDecade": "Gelecek On Yıl",
        "nextHour": "Gelecek Saat",
        "nextMinute": "Gelecek Dakika",
        "nextMonth": "Gelecek Ay",
        "nextSecond": "Gelecek Saniye",
        "nextYear": "Gelecek Yıl",
        "noFilter": "Filtresiz",
        "notContains": "İçinde Barındırmaz",
        "notEquals": "Eşit Değildir",
        "now": "Şimdi",
        "passwordPrompt": "Parola Giriniz",
        "pending": "Askıda",
        "pm": "ÖS",
        "prevDecade": "Önceki On Yıl",
        "prevHour": "Geçen Saat",
        "prevMinute": "Geçen Dakika",
        "prevMonth": "Geçen Ay",
        "prevSecond": "Geçen Saniye",
        "prevYear": "Geçen Yıl",
        "reject": "İptal",
        "removeRule": "Kuralı Sil",
        // "searchMessage": "{0} sonuç bulundu",
        // "selectionMessage": "{0} öge seçildi",
        // "showMonthAfterYear": false,
        "startsWith": "Başlangıç",
        "strong": "Güçlü",
        "today": "Bugün",
        "upload": "Yükle",
        "weak": "Zayıf",
        "weekHeader": "Hf",
        "aria": {
          "cancelEdit": "Düzenlemeyi İptal Et",
          "close": "Kapat",
          // "collapseLabel": "Yıkılmak",
          "collapseRow": "Satır Daraltıldı",
          "editRow": "Satırı Düzenle",
          // "expandLabel": "Genişletmek",
          "expandRow": "Satır Genişletildi",
          "falseLabel": "Yanlış",
          "filterConstraint": "Filter Constraint",
          "filterOperator": "Filtre Araçları",
          "firstPageLabel": "İlk Sayfa",
          "gridView": "Izgara Görünümü",
          "hideFilterMenu": "Filtre Menüsünü Gizle",
          "jumpToPageDropdownLabel": "Açılan Sayfaya Git",
          "jumpToPageInputLabel": "Giriş Yapılan Sayfaya git",
          "lastPageLabel": "Son Sayfa",
          "listView": "Liste Görünümü",
          "moveAllToSource": "Tümünü kaynağa taşı",
          "moveAllToTarget": "Tümünü hedefe taşı",
          "moveBottom": "En aşağıya taşı",
          "moveDown": "Aşağıya taşı",
          "moveTop": "En üste taşı",
          "moveToSource": "Kaynağa taşı",
          "moveToTarget": "Hedefe taşı",
          "moveUp": "Üste taşı",
          "navigation": "Navigasyon",
          "next": "Sonraki",
          "nextPageLabel": "Sonraki Sayfa",
          "nullLabel": "Seçilmedi",
          "otpLabel": "Lütfen tek kullanımlık şifre karakteri {0} girin",
          "pageLabel": "Sayfa {page}",
          // "passwordHide": "Şifreyi gizle",
          // "passwordShow": "Şifreyi göster",
          "previous": "Önceki",
          "previousPageLabel": "Önceki Sayfa",
          // "removeLabel": "Sil",
          "rotateLeft": "Sola Döndür",
          "rotateRight": "Sağa Döndür",
          "rowsPerPageLabel": "Sayfa başına satır",
          "saveEdit": "Düzenlemeyi Kaydet",
          "scrollTop": "Yukarı çık",
          "selectAll": "Tüm ögeler seçildi",
          // "selectLabel": "Seçme",
          "selectRow": "Satır Seçildi",
          "showFilterMenu": "Filtre Menüsünü Göster",
          "slide": "Slayt",
          "slideNumber": "{slideNumber}",
          "star": "1 yıldız",
          "stars": "{star} yıldız",
          "trueLabel": "Doğru",
          "unselectAll": "Tüm ögelerden seçim kaldırıldı",
          // "unselectLabel": "Seçimi kaldır",
          "unselectRow": "Satır Seçilmedi",
          "zoomImage": "Görüntüyü Yakınlaştır",
          "zoomIn": "Yakınlaştır",
          "zoomOut": "Uzaklaştır"
        }
      });
};
