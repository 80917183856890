import { count } from "console";
import { Button } from "primereact/button";
import { NewLicenceItemDto } from "../../services/payment/payment.dto";
import { MonthlyType } from "../../services/admin/admin.dto";
import { useBasket } from "../../utils/basket-provider";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../utils/toast";
import { useHub } from "../../utils/buro-hub";

export const PricingPackets = () => {
  const basket = useBasket();
  const navigate = useNavigate();
  const toast = useToast();
  const hub = useHub();

  return (
    <div id="pricing" className="py-4 px-4 lg:px-8 my-2 md:my-4">
      <div className="text-center">
        <h2 className="text-900 font-normal mb-2">Uygun Paketi Seç</h2>
        <span className="text-600 text-2xl">
          İhtiyaç ve hedeflerine göre paketini seç ve Pratik Portalı kullanmaya başla!
        </span>
      </div>

      <div className="grid justify-content-between mt-8 md:mt-0">
        <div className="col-12 lg:col-6 xl:col-3 p-0 md:p-3">
          <div className="p-3 flex flex-column border-200 pricing-card border-2 hover:border-primary transition-duration-300 transition-all">
            <h3 className="text-900 text-center my-5">Aylık</h3>
            <img src="/images/free.svg" className="w-10 h-10 mx-auto" alt="free" />
            <div className="my-5 text-center">
              <span className="text-white text-sm block font-medium mb-2" style={{ visibility: "hidden" }}>
                0
              </span>
              <span className="text-5xl font-bold mr-2 text-900">₺499.99</span>

              <div className=" font-bold mr-2 text-700"> Toplam Fiyat: ₺499.00</div>
              <Button
                label="Satın Al"
                rounded
                className="block mx-auto mt-4 border-none ml-3 font-light line-height-2 bg-blue-500 text-white"
                onClick={() => {
                  const newLicense: NewLicenceItemDto = {
                    id: crypto.randomUUID(),
                    count: 1,
                    month: MonthlyType.Monthly
                  };

                  const userBasket = basket.getBasket();
                  userBasket.newLicences.push(newLicense);
                  basket.setBasket(userBasket);

                  toast.show("Lisans başarıyla sepetinize eklendi.", "success");
                  navigate("/app/sepetim");
                }}
              ></Button>
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-6 xl:col-3 p-0 md:p-3 mt-4 md:mt-0">
          <div className="p-3 flex flex-column border-200 pricing-card border-2 hover:border-primary transition-duration-300 transition-all">
            <h3 className="text-900 text-center my-5">3 Aylık</h3>
            <img src="/images/startup.svg" className="w-10 h-10 mx-auto" alt="startup" />
            <div className="my-5 text-center">
              <span className="text-blue-500 text-sm font-medium block mb-2">%15 daha ucuz</span>
              <span className="text-5xl font-bold mr-2 text-900">₺430.00</span>
              <span className="text-600">aylık</span>
              <div className=" font-bold mr-2 text-700"> Toplam Fiyat: ₺1290.00</div>
              <Button
                label="Satın Al"
                rounded
                className="block mx-auto mt-4 border-none ml-3 font-light line-height-2 bg-blue-500 text-white"
                onClick={() => {
                  const newLicense: NewLicenceItemDto = {
                    id: crypto.randomUUID(),
                    count: 1,
                    month: MonthlyType.ThreeMonth
                  };

                  const userBasket = basket.getBasket();
                  userBasket.newLicences.push(newLicense);
                  basket.setBasket(userBasket);

                  toast.show("Lisans başarıyla sepetinize eklendi.", "success");
                  navigate("/app/sepetim");
                }}
              ></Button>
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-6 xl:col-3 p-0 md:p-3 mt-4 md:mt-0">
          <div className="p-3 flex flex-column border-200 pricing-card border-2 hover:border-primary transition-duration-300 transition-all">
            <h3 className="text-900 text-center my-5">6 Aylık</h3>
            <img src="/images/enterprise.svg" className="w-10 h-10 mx-auto" alt="enterprise" />
            <div className="my-5 text-center">
              <span className="text-blue-500 text-sm font-medium block mb-2">%30 daha ucuz</span>
              <span className="text-5xl font-bold mr-2 text-900">₺350.00</span>
              <span className="text-600">aylık</span>
              <div className=" font-bold mr-2 text-700"> Toplam Fiyat: ₺2100.00</div>
              <Button
                label="Satın Al"
                rounded
                className="block mx-auto mt-4 border-none ml-3 font-light line-height-2 bg-blue-500 text-white"
                onClick={() => {
                  const newLicense: NewLicenceItemDto = {
                    id: crypto.randomUUID(),
                    count: 1,
                    month: MonthlyType.SixMonth
                  };

                  const userBasket = basket.getBasket();
                  userBasket.newLicences.push(newLicense);
                  basket.setBasket(userBasket);

                  toast.show("Lisans başarıyla sepetinize eklendi.", "success");
                  navigate("/app/sepetim");
                }}
              ></Button>
            </div>
          </div>
        </div>
        <div className="col-12 lg:col-6 xl:col-3 p-0 md:p-3 mt-4 md:mt-0">
          <div className="p-3 flex flex-column border-200 pricing-card border-2 hover:border-primary transition-duration-300 transition-all">
            <h3 className="text-900 text-center my-5">12 Aylık</h3>
            <img src="/images/enterprise.svg" className="w-10 h-10 mx-auto" alt="enterprise" />
            <div className="my-5 text-center">
              <span className="text-blue-500 text-sm font-medium block mb-2">%50 daha ucuz</span>
              <span className="text-5xl font-bold mr-2 text-900">₺250.00</span>
              <span className="text-600">aylık</span>
              <div className=" font-bold mr-2 text-700"> Toplam Fiyat: ₺3000.00</div>
              <Button
                label="Satın Al"
                rounded
                className="block mx-auto mt-4 border-none ml-3 font-light line-height-2 bg-blue-500 text-white"
                onClick={() => {
                  const newLicense: NewLicenceItemDto = {
                    id: crypto.randomUUID(),
                    count: 1,
                    month: MonthlyType.TwelveMonth
                  };

                  const userBasket = basket.getBasket();
                  userBasket.newLicences.push(newLicense);
                  basket.setBasket(userBasket);

                  toast.show("Lisans başarıyla sepetinize eklendi.", "success");
                  navigate("/app/sepetim");
                }}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPackets;
