import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguKamuEmekliModel } from "../TopluSorgu.dto";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";

const KamuEmeklisiDataTable = (props: { tableData: TopluSorguKamuEmekliModel[] }) => {
  const kamuEmekliVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguKamuEmekliModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      sicilNo: commonFilterContains,
      aylikBaslamaTarihi: commonFilterDateIs,
      unvan: commonFilterContains,
      derece: commonFilterContains,
      yakinlik: commonFilterContains,
      kademe: commonFilterContains,
      bankaAdi: commonFilterContains,
      bankaIli: commonFilterContains,
      subeAdi: commonFilterContains,
      aylikOraniPayda: commonFilterContains,
      aylikOraniPay: commonFilterContains,
      aylikDurumu: commonFilterContains,
      aylikTur: commonFilterContains,
      aylikPeriyot: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Emekli Sicil No",
      "Aylık Başlama Tarihi",
      "Ünvan",
      "Derece",
      "Yakınlık",
      "Kademe",
      "Banka Adı",
      "Banka İli",
      "Şube Adı",
      "Aylık Payda",
      "Aylık Pay",
      "Aylık Durum",
      "Aylık Tur",
      "Aylık Periyot"
    ];

    const filteredData = kamuEmekliVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      sicilNo: data.sicilNo,
      aylikBaslamaTarihi: data.aylikBaslamaTarihi && moment(data.aylikBaslamaTarihi).isValid()
        ? moment(data.aylikBaslamaTarihi).format(process.env.REACT_APP_DATE_FORMAT)
        : "",
      unvan: data.unvan,
      derece: data.derece,
      yakinlik: data.yakinlik,
      kademe: data.kademe,
      bankaAdi: data.bankaAdi,
      bankaIli: data.bankaIli,
      subeAdi: data.subeAdi,
      aylikOraniPayda: data.aylikOraniPayda,
      aylikOraniPay: data.aylikOraniPay,
      aylikDurumu: data.aylikDurumu,
      aylikTur: data.aylikTur,
      aylikPeriyot: data.aylikPeriyot
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluSorguKamuEmekli");
  };

  return (
    <div>
      <DataTable
        value={kamuEmekliVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="key"
        showGridlines
        emptyMessage="Kamu Emeklisi Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Kamu Emeklisi Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "sicilNo",
          "aylikBaslamaTarihi",
          "unvan",
          "derece",
          "yakinlik",
          "kademe",
          "bankaAdi",
          "bankaIli",
          "subeAdi",
          "aylikOraniPayda",
          "aylikOraniPay",
          "aylikDurumu",
          "aylikTur",
          "aylikPeriyot"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filter
          filterField="ortakDegerler.dosyaNo"
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filter
          filterField="ortakDegerler.alacakliAdi"
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filter
          filterField="ortakDegerler.borcluAdi"
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filter
          filterField="ortakDegerler.borcluTCVKNo"
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filter
          filterField="ortakDegerler.dosyaDurumu"
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Emekli Sicil No"
          field="sicilNo"
          sortable
          filter
          filterField="sicilNo"
          filterPlaceholder="Emekli Sicil Numarasına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => (
            <div className="px-3 pt-0 pb-3 text-center">Emekli Sicil Numarasına göre filtreleyin</div>
          )}
        />
        <Column
          header="Aylık Başlama Tarihi"
          field="aylikBaslamaTarihi"
          sortable
          body={(rowData) =>
            rowData.aylikBaslamaTarihi && moment(rowData.aylikBaslamaTarihi).isValid() ? (
              moment(rowData.aylikBaslamaTarihi).format(process.env.REACT_APP_DATE_FORMAT)
            ) : (
              <></>
            )
          }
          filter
          filterField="aylikBaslamaTarihi"
          filterPlaceholder="Aylık Başlama Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Aylık Başlama Tarihine göre filtreleyin</div>}
        />
        <Column
          header="Ünvan"
          field="unvan"
          sortable
          filter
          filterField="unvan"
          filterPlaceholder="Ünvana göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Ünvana göre filtreleyin</div>}
        />
        <Column
          header="Derece"
          field="derece"
          sortable
          filter
          filterField="derece"
          filterPlaceholder="Dereceye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dereceye göre filtreleyin</div>}
        />
        <Column
          header="Yakınlık"
          field="yakinlik"
          sortable
          filter
          filterField="yakinlik"
          filterPlaceholder="Yakınlığa göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Yakınlığa göre filtreleyin</div>}
        />
        <Column
          header="Kademe"
          field="kademe"
          sortable
          filter
          filterField="kademe"
          filterPlaceholder="Kademe göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Kademe göre filtreleyin</div>}
        />
        <Column
          header="Banka Adı"
          field="bankaAdi"
          sortable
          filter
          filterField="bankaAdi"
          filterPlaceholder="Banka adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Banka adına göre filtreleyin</div>}
        />
        <Column
          header="Banka İli"
          field="bankaIli"
          sortable
          filter
          filterField="bankaIli"
          filterPlaceholder="Banka iline göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Banka iline göre filtreleyin</div>}
        />
        <Column
          header="Şube Adı"
          field="subeAdi"
          sortable
          filter
          filterField="subeAdi"
          filterPlaceholder="Şube adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Şube adına göre filtreleyin</div>}
        />
        <Column
          header="Aylık Payda"
          field="aylikOraniPayda"
          sortable
          filter
          filterField="aylikOraniPayda"
          filterPlaceholder="Aylık payda oranına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Aylık payda oranına göre filtreleyin</div>}
        />
        <Column
          header="Aylık Pay"
          field="aylikOraniPay"
          sortable
          filter
          filterField="aylikOraniPay"
          filterPlaceholder="Aylık pay oranına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Aylık pay oranına göre filtreleyin</div>}
        />
        <Column
          header="Aylık Durum"
          field="aylikDurumu"
          sortable
          filter
          filterField="aylikDurumu"
          filterPlaceholder="Aylık duruma göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Aylık duruma göre filtreleyin</div>}
        />
        <Column
          header="Aylık Tur"
          field="aylikTur"
          sortable
          filter
          filterField="aylikTur"
          filterPlaceholder="Aylık tura göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Aylık tura göre filtreleyin</div>}
        />
        <Column
          header="Aylık Periyot"
          field="aylikPeriyot"
          sortable
          filter
          filterField="aylikPeriyot"
          filterPlaceholder="Aylık periyoda göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Aylık periyoda göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default KamuEmeklisiDataTable;
