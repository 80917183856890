import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import AdminService from "../../services/admin/admin.services";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { Button } from "primereact/button";
import { useToast } from "../../utils/toast";
import { InputText } from "primereact/inputtext";
import { useContext, useMemo } from "react";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { LicenseCreateDto, AppLicenseDto, LicenseUpdateDto, UpsertLicenseDto } from "../../services/admin/admin.dto";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const now = new Date();

const LicenseUpdate = (props: any) => {
  const license: AppLicenseDto = props.license;
  const setLicense = props.setLicense;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchMainUsers = props.refetchMainUsers;
  const mainUserId = props.mainUserId;
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext);

  let upsertLicenseDto: UpsertLicenseDto;

  if (license === null || license === undefined) {
    upsertLicenseDto = {
      name: "",
      isActive: true,
      endDate: now,
      id: ""
    };
  } else {
    upsertLicenseDto = {
      ...license,
      endDate: new Date(license.endDate)
    };
  }

  const formik = useFormik<UpsertLicenseDto>({
    initialValues: {
      ...upsertLicenseDto
    },
    enableReinitialize: true,
    validate: (data) => {
      console.log("license data ", data);
      let errors: FormikErrors<UpsertLicenseDto> = {};
      if (!data.name) {
        errors.name = "Lisans Adı alanı boş bırakılamaz";
      }

      if (!data.endDate) {
        errors.endDate = "Bitiş Tarihi alanı boş bırakılamaz";
      } else {
        if (formik.values.id === "" && moment(data.endDate).isBefore(now)) {
          errors.endDate = "Bitiş Tarihi bugünden küçük olamaz";
        } else if (formik.values.id && moment(data.endDate).isBefore(new Date(license.startDate))) {
          errors.endDate = "Bitiş Tarihi başlangıç tarihinden küçük olamaz";
        }
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true);
      if (formik.values.id === "") {
        var licenseCreateDto: LicenseCreateDto = {
          ...formik.values
        };

        AdminService.CreateLicense(licenseCreateDto, mainUserId ?? "").then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setLicense(undefined);
            refetchMainUsers();
            toast.show("Lisans başarıyla eklendi.", "success");
          }
        });
      } else {
        var updateLicenseDto: LicenseUpdateDto = {
          ...formik.values
        };
        AdminService.UpdateLicense(updateLicenseDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setLicense(undefined);
            refetchMainUsers();
            toast.show("Lisans başarıyla güncellendi.", "success");
          }
        });
      }

      setBlocked(false);
    }
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={formik.values.id === "" ? "Lisans Ekle" : "Lisans Güncelle"}
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setLicense(undefined);
      }}
      className="w-5"
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setLicense(undefined);
            }}
          />
          <Button label={"Kaydet"} className="md:px-6 justify-content-center" onClick={formik.submitForm} />
        </div>
      }
      //className="wh-100-50"
      // style={{ width: "60vw" }}
    >
      <div className="grid">
        <div className="col-12">
          <label htmlFor="name" className="text-primary">
            Lisans Adı
          </label>
          <InputText
            id="name"
            type="text"
            placeholder={"Lisans Adı"}
            className={classNames("w-full  mt-2", {
              "p-invalid": FormikValueIsValid(formik, "name")
            })}
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            style={{ padding: "1rem" }}
          />
          {FormikValueIsValid(formik, "name") && <div className="p-error mt-3">{formik.errors.name}</div>}
        </div>
        <div className="col-12">
          <label htmlFor="isActive" className="text-primary mr-3">
            Lisans Durumu
          </label>
          <div className="flex align-items-center mt-2">
            <InputSwitch
              id="isActive"
              checked={formik.values.isActive}
              onChange={(e) => {
                formik.setFieldValue("isActive", e.value);
              }}
              className="mr-2"
            />
            <span>{formik.values.isActive ? "Aktif" : "Pasif"}</span>
          </div>
        </div>
        <div className="col-12">
          <label htmlFor="id" className="text-primary">
            Lisans Bitiş Tarihi
          </label>
          <Calendar
            value={formik.values.endDate}
            onChange={(e) => formik.setFieldValue("endDate", e.value)}
            id="endDate"
            locale="tr"
            placeholder="dd.mm.yyyy"
            dateFormat="dd.mm.yy"
            mask="99.99.9999"
            showIcon
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "endDate")
            })}
          />
          {FormikValueIsValid(formik, "endDate") && (
            <div className="p-error mt-3">{formik.errors.endDate as string}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default LicenseUpdate;
