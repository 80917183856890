import { EntityDto } from "../base/base.dto";
import { IlDto, IlceDto } from "../ilce-il/ilce-il.sto";

export interface TebligatBilgisiDto extends EntityDto<number> {
  yazimTarihi: Date;
  tur: string;
  muhatap: string;
  address: string;
  ilce: IlceDto;
  il: IlDto;
  gonderimTarihi?: Date;
  barkodNo?: string;
  tebligatDurumu: TebligatDurumu;
  tebligatTarihi?: Date;
  tebligatSerhi: string;
  icraFoyId: number;
}

export interface UpdateTebligatBilgisiDto extends EntityDto<number> {
  yazimTarihi: Date;
  tur: string;
  muhatap: string;
  address: string;
  ilceId: number;
  ilId: number;
  gonderimTarihi?: Date;
  barkodNo?: string;
  tebligatDurumu: TebligatDurumu;
  tebligatTarihi?: Date;
  tebligatSerhi: string;
}

export interface CreateTebligatBilgisiDto {
  yazimTarihi: Date;
  tur: string;
  muhatap: string;
  address: string;
  ilceId: number;
  ilId: number;
  gonderimTarihi?: Date;
  barkodNo?: string;
  tebligatDurumu: TebligatDurumu;
  tebligatTarihi?: Date;
  tebligatSerhi: string;
  icraFoyId: number;
}

export enum TebligatDurumu {
  Yazildi = 1,
  MasrafVerildi,
  Gonderildi,
  Teblig,
  BilaUyarili,
  BilaUyarisiz,
}
