import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableValueArray } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import MuvekkilService from "../../services/muvekkil/muvekkil.service";
import { MuvekkilDto, MuvekkilTipi } from "../../services/muvekkil/muvekkil.dto";
import { useQuery } from "@tanstack/react-query";
import UpsertMuvekkil from "./UpsertMuvekkil";
import { useToast } from "../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { AddressDto } from "../../services/address/address.dto";
import { TelephoneDto } from "../../services/telephone/telephone.dto";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import DropdownDatas from "../../utils/DropdownValues";
import { EmailDto } from "../../services/email/email.dto";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Calendar, CalendarViewChangeEvent } from "primereact/calendar";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";
import { ExportFile } from "../../utils/export-file";
import { LayoutContext } from "../../layout/context/layoutcontext";

const MuvekkilPage = () => {
  const [muvekkil, setMuvekkil] = useState<MuvekkilDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<MuvekkilDto[]>>(null);
  const [visibleUpsertMuvekilDialog, setVisibleUpsertMuvekilDialog] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

  const {
    data: muvekkilResponse,
    refetch: refetchMuvekkil,
    isLoading: muvekkilResponseIsLoading
  } = useQuery({
    queryKey: ["Muvekkils"],
    refetchOnMount: true,
    queryFn: () => MuvekkilService.GetAll()
  });

  useEffect(() => {
    setBlocked(muvekkilResponseIsLoading)
  }, [muvekkilResponseIsLoading])

  useEffect(() => {
    initFilters();
  }, []);

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      tip: { value: null, matchMode: FilterMatchMode.CONTAINS },
      adSoyad: commonFilterContains,
      tcNo: commonFilterContains,
      unvan: commonFilterContains,
      vergiKimlikNumarasi: commonFilterContains,
      dogumTarihi: commonFilterDateIs,
      babaAdi: commonFilterContains,
      anaAdi: commonFilterContains,
      cinsiyet: commonFilterContains,
      vergiDairesi: commonFilterContains,
      mersisNo: commonFilterContains,
      vekaletBilgisi: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Müvekkil"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            // setMuvekkil(undefined);
            setVisibleUpsertMuvekilDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: MuvekkilDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          tooltip="Düzenle"
          rounded
          outlined
          className="mr-2"
          onClick={() => {
            setMuvekkil(rowData);
            setVisibleUpsertMuvekilDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Sil"
          rounded
          outlined
          severity="danger"
          onClick={() => {
            deleteMuvekkilConfirm(rowData);
          }}
        />
      </div>
    );
  };


  const deleteMuvekkil = (rowData: MuvekkilDto) => {
    setBlocked(true)
    MuvekkilService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Müvekkil başarıyla silindi.", "success");
        refetchMuvekkil();
      }
    });
  };

  const deleteMuvekkilConfirm = (rowData: MuvekkilDto) => {
    confirmDialog({
      message: "Müvekkili silmek istediğinize emin misiniz?",
      header: "Müvekkil Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteMuvekkil(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const addressAndTelephoneRowExpansionTemplate = (muvekkil: MuvekkilDto) => {
    return (
      <div className="p-3">
        <h5>Adresler</h5>
        <DataTable value={muvekkil.adresler} showGridlines>
          <Column
            field="il"
            header="İl"
            sortable
            body={(rowData: AddressDto) => {
              return <span>{rowData.il?.ilText}</span>;
            }}
          ></Column>
          <Column
            field="ilce"
            header="İlçe"
            sortable
            body={(rowData: AddressDto) => {
              return <span>{rowData.ilce?.ilceText}</span>;
            }}
          ></Column>
          <Column field="address" header="Adres" sortable></Column>
          <Column field="tip" header="Tip" sortable></Column>
          <Column
            field="mernisMi"
            header="Mernis Mi?"
            body={(rowData: AddressDto) => {
              return <span>{rowData.mernisMi ? "Evet" : "Hayır"}</span>;
            }}
          ></Column>
          <Column
            field="tasinmaTarihi"
            header="Taşınma Tarihi"
            body={(rowData: AddressDto) => {
              return <span>{rowData.tasinmaTarihi ? new Date(rowData.tasinmaTarihi).toLocaleDateString() : ""}</span>;
            }}
            sortable
          ></Column>
          <Column
            field="tescilTarihi"
            header="Tescil Tarihi"
            body={(rowData: AddressDto) => {
              return <span>{rowData.tescilTarihi ? new Date(rowData.tescilTarihi).toLocaleDateString() : ""}</span>;
            }}
            sortable
          ></Column>
          <Column field="aciklama" header="Açıklama" sortable></Column>
        </DataTable>

        <h5>Telefonlar</h5>
        <DataTable value={muvekkil.telefonlar} showGridlines>
          <Column field="numara" header="Numara" sortable></Column>
          <Column
            field="sahibi"
            header="Sahibi"
            sortable
            body={(rowData: TelephoneDto) => {
              return <span>{DropdownDatas.GetTelefonSahibi().find((x) => x.value === rowData.sahibi)?.label}</span>;
            }}
          ></Column>
          <Column field="aciklama" header="Açıklama" sortable></Column>
          <Column
            field="mernisMi"
            header="İletişim Onayı Var Mı?"
            body={(rowData: TelephoneDto) => {
              return <span>{rowData.iletisimOnayiVarMi ? "Evet" : "Hayır"}</span>;
            }}
          ></Column>
        </DataTable>

        <h5>E-postalar</h5>
        <DataTable value={muvekkil.epostalar} showGridlines>
          <Column field="epostaAdresi" header="E-posta Adresi" sortable></Column>
          <Column
            field="iletisim"
            header="İletişim izni var mı?"
            sortable
            body={(rowData: EmailDto) => {
              return <span>{rowData.iletisim === true ? "Evet" : "Hayır"}</span>;
            }}
          ></Column>
          <Column field="aciklama" header="Açıklama" sortable></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData: MuvekkilDto) => {
    return rowData.adresler.length + rowData.telefonlar.length + rowData.epostalar.length > 0;
  };

  const exportExcel = () => {
    const data = muvekkilResponse?.result?.data || [];

    const headers = [
      "Tip",
      "Ad Soyad",
      "TC",
      "Unvan",
      "Vergi Kimlik Numarası",
      "Doğum Tarihi",
      "Baba Adı",
      "Ana Adı",
      "Cinsiyet",
      "Vergi Dairesi",
      "Mersis No",
      "Vekalet Bilgisi"
    ];

    const filteredData = data.map((data) => ({
      tip: data.tip == MuvekkilTipi.Gercek ? "Gerçek" : "Tüzel",
      adSoyad: data.adSoyad,
      tcNo: data.tcNo,
      unvan: data.unvan,
      vergiKimlikNumarasi: data.vergiKimlikNumarasi,
      dogumTarihi: data.dogumTarihi ? new Date(data.dogumTarihi).toLocaleDateString() : "",
      babaAdi: data.babaAdi,
      anaAdi: data.anaAdi,
      cinsiyet: DropdownDatas.GetCinsiyet().find((x) => x.value === data.cinsiyet)?.label,
      vergiDairesi: data.vergiDairesi,
      mersisNo: data.mersisNo,
      vekaletBilgisi: data.vekaletBilgisi
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "Muvekkil");
  };

  const [representatives] = useState([
    { label: "Gerçek", value: MuvekkilTipi.Gercek },
    { label: "Tüzel", value: MuvekkilTipi.Tuzel }
  ]);

  const representativesItemTemplate = (option: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.label}</span>
      </div>
    );
  };

  const representativeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e: MultiSelectChangeEvent) => {
          options.filterCallback(e.value);
        }}
        optionLabel="label"
        optionValue="value"
        placeholder="Tip Seçiniz"
        className="p-column-filter"
      />
    );
  };

  const a = (rowData: MuvekkilDto) => {
    return new Date(rowData.dogumTarihi!).toLocaleDateString();
    // rowData.dogumTarihi
    //   ? new Date(rowData.dogumTarihi).toLocaleDateString()
    //   : ""
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={muvekkilResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} müvekkil"
          filters={filters}
          // filterDisplay="row"
          loading={muvekkilResponseIsLoading}
          globalFilterFields={[
            "tip",
            "adSoyad",
            "tcNo",
            "unvan",
            "vergiKimlikNumarasi",
            "dogumTarihi",
            "babaAdi",
            "anaAdi",
            "cinsiyet",
            "vergiDairesi",
            "mersisNo",
            "vekaletBilgisi"
          ]}
          emptyMessage="Müvekkil bulunamadı."
          header={CreateHeader(
            "Müvekkiller",
            globalFilterValue,
            (e) => onGlobalFilterChange(e),
            clearFilter,
          )}
          rowExpansionTemplate={addressAndTelephoneRowExpansionTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
          <Column expander={allowExpansion} style={{ width: "1rem" }} />
          <Column
            field="tip"
            header="Tip"
            sortable
            body={(rowData: MuvekkilDto) => {
              return <span>{rowData.tip == MuvekkilTipi.Gercek ? "Gerçek" : "Tüzel"}</span>;
            }}
            showFilterMatchModes={false}
            filter
            filterElement={representativeFilterTemplate}
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
          ></Column>
          <Column
            field="adSoyad"
            header="Ad Soyad"
            sortable
            filterField="adSoyad"
            filter
            filterPlaceholder="İsme göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İsme göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="tcNo"
            header="TC"
            sortable
            filterField="tcNo"
            filter
            filterPlaceholder="TC'ye göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">TC'ye göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="unvan"
            header="Unvan"
            sortable
            filterField="unvan"
            filter
            filterPlaceholder="Ünvana göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Ünvana göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="vergiKimlikNumarasi"
            header="Vergi Kimlik Numarası"
            sortable
            filterField="vergiKimlikNumarasi"
            filter
            filterPlaceholder="Vergi Kimlik Numarasına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Vergi Kimlik Numarasına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="dogumTarihi"
            header="Doğum Tarihi"
            body={(rowData: MuvekkilDto) => {
              return (
                <span>
                  {rowData.tip === MuvekkilTipi.Gercek && rowData.dogumTarihi
                    ? new Date(rowData.dogumTarihi).toLocaleDateString()
                    : ""}
                </span>
              );
            }}
            dataType="date"
            sortable
            filterField="dogumTarihi"
            filter
            filterPlaceholder="Doğum Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Doğum Tarihine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="babaAdi"
            header="Baba Adı"
            sortable
            filterField="babaAdi"
            filter
            filterPlaceholder="Baba Adına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Baba Adına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="anaAdi"
            header="Ana Adı"
            sortable
            filterField="anaAdi"
            filter
            filterPlaceholder="Ana Adına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Ana Adına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="cinsiyet"
            header="Cinsiyet"
            sortable
            body={(rowData: MuvekkilDto) => {
              return <span>{DropdownDatas.GetCinsiyet().find((x) => x.value === rowData.cinsiyet)?.label}</span>;
            }}
            filterField="cinsiyet"
            filter
            filterPlaceholder="Cinsiyete göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Cinsiyete göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="vergiDairesi"
            header="Vergi Dairesi"
            sortable
            filterField="vergiDairesi"
            filter
            filterPlaceholder="Vergi Dairesine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Vergi Dairesine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="mersisNo"
            header="Mersis No"
            sortable
            filterField="mersisNo"
            filter
            filterPlaceholder="Mersis No'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Mersis No'ya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="vekaletBilgisi"
            header="Vekalet Bilgisi"
            sortable
            filterField="vekaletBilgisi"
            filter
            filterPlaceholder="Vekalet Bilgisine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Vekalet Bilgisine göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertMuvekkil
        visible={visibleUpsertMuvekilDialog}
        setVisible={setVisibleUpsertMuvekilDialog}
        muvekkil={muvekkil}
        setMuvekkil={setMuvekkil}
        refetchMuvekkil={refetchMuvekkil}
      />
    </div>
  );
};

export default MuvekkilPage;
