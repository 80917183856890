import React, { useState, forwardRef } from "react";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { TreeNode } from "primereact/treenode";

interface EvrakNodeProps extends React.RefAttributes<HTMLDivElement> {
  e: TreeNode;
}

export const EvrakNode = forwardRef<HTMLDivElement, EvrakNodeProps>(({ e }, ref) => {
  const [visible, setVisible] = useState(false);

  const tooltipText = `${e?.data?.tur && "Tür: " + e?.data?.tur}\n${e?.data?.aciklama && "Açıklama: " + e?.data?.aciklama}\n${e?.data?.birimEvrakNo && "Birim Evrak No: " + e?.data?.birimEvrakNo}\n${e?.data?.gonderenDosyaNo && "Gönderen Dosya No: " + e?.data?.gonderenDosyaNo}\n${e?.data?.gonderenSayi && "Gönderen Sayı: " + e?.data?.gonderenSayi}\n${e?.data?.gonderenYerKisi && "Gönderen Yer Kişi: " + e?.data?.gonderenYerKisi}\n${e?.data?.onaylandigiTarih && "Onaylandığı Tarih: " + e?.data?.onaylandigiTarih}\n${e?.data?.sistemeGonderildigiTarih && "Sisteme Gönderildiği Tarih: " + e?.data?.sistemeGonderildigiTarih}`;

  return (
    <div
      ref={ref}
      className="flex justify-content-between align-items-center gap-4 w-10"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <Tooltip target=".custom-target-icon" />
      <div
        className="custom-target-icon"
        data-pr-tooltip={e?.data?.tip ? tooltipText : ""}
        data-pr-position="right"
        data-pr-showdelay={1000}
      >
        <p className={`${e?.data?.location && "text-green-600 font-normal"}`}>{e.label}</p>
      </div>
    </div>
  );
});
