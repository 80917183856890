import React, { useState } from "react";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { useHub } from "../../../utils/buro-hub";
import { ProgressSpinner } from "primereact/progressspinner";
import { useToast } from "../../../utils/toast";

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

export const uyapAxios = axios.create({
  baseURL: "https://avukatbeta.uyap.gov.tr",
  headers: {
    Accept: "application/json, text/plain, */*",
    // "Accept-Encoding": "gzip, deflate, br, zstd",
    "Accept-Language": "en-US,en;q=0.9,tr-TR;q=0.8,tr;q=0.7",
    "Cache-Control": "no-cache",
    // Connection: "keep-alive",
    // "Content-Length": 78,
    "Content-Type": "application/json",
    // Cookie:
    //   "_b8e1e=37b425258bc4fbf6; JSESSIONID=00009mZY67XuWjEktjcvdIFcjNe:1hqmuo7lv",
    Expires: "0",
    // Host: "avukatbeta.uyap.gov.tr",
    // Origin: "https://avukatbeta.uyap.gov.tr",
    Pragma: "no-cache"
    // Referer: "https://avukatbeta.uyap.gov.tr/dosya-sorgulama",
    //"Sec-Ch-Ua": "\" Not A;Brand\";v=\"99\", \"Chromium\";v=\"96\", \"Google Chrome\";v=\"96\"",
    // "Sec-Ch-Ua-Mobile": "?0",
    // "Sec-Ch-Ua-Platform": "Windows",
    // "Sec-Fetch-Dest": "empty",
    // "Sec-Fetch-Mode": "cors",
    // "Sec-Fetch-Site": "same-origin",
    // "User-Agent":
    //   "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36",
  },
  withCredentials: true
});

let UyapAxiosContext = React.createContext<AxiosInstance>(null!);
export function useUyapAxiosContext() {
  return React.useContext(UyapAxiosContext);
}

export function UyapAxiosContextProvider({ children }: { children: React.ReactNode }) {
  const hub = useHub();
  const [isUyapAxiosInterceptorAdded, setIsUyapAxiosInterceptorAdded] = useState<boolean>(false);
  const toast = useToast();

  // Create a list to hold the request queue
  const refreshAndRetryQueue: RetryQueueItem[] = [];

  // Flag to prevent multiple token refresh requests
  let isRefreshing = false;

  if (!isUyapAxiosInterceptorAdded) {
    uyapAxios.interceptors.request.use(
      async (config) => {
        return config;
      },
      async (error) => {
        return Promise.reject(error);
      }
    );

    uyapAxios.interceptors.response.use(
      async (response) => {
        const originalRequest: AxiosRequestConfig = response.config;
        if (
          response.status === 200 &&
          response.data &&
          response.data.error &&
          (response.data.error.includes("Eş zamanlı olarak birden fazla") ||
            response.data.error.includes("Başka bir bilgisayarda"))
        ) {
          // refresh the original request
          return uyapAxios.request(originalRequest);
          // return uyapAxios
          //         .request(originalRequest)
          //         .then((response) => resolve(response))
          //         .catch((err) => reject(err));
          // return uyapAxios(originalRequest);
        }

        // if (
        //   response.status === 200 &&
        //   response.data &&
        //   response.data.error &&
        //   response.data.error.includes("Başka bir bilgisayarda")
        // ) {
        //   response.status = 401;
        //   return Promise.reject(response);
        //   // throw new Error(response);
        // }

        return response;
      },
      async (error) => {
        if (error.config.retryCount === undefined) {
          error.config.retryCount = 0;
        }
        if (error.config.retryCount < 3) {
          error.config.retryCount++;
        }

        const originalRequest: AxiosRequestConfig = error.config;

        // If the request has already been retried 3 times, reject the request
        if (originalRequest.retryCount && originalRequest.retryCount >= 3) {
          toast.show("Uyap'a bağlanılırken bir sorun oluştu. Uyap bağlantı ayarlarınızı kontrol ediniz.", "error");
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              // Refresh the session
              await hub.refreshNewUyapSessionMessageToHub();

              // wait 5 seconds for the session to be refreshed
              await new Promise((resolve) => setTimeout(resolve, 5000));

              // Retry all requests in the queue with the new token
              for await (const { config, resolve, reject } of refreshAndRetryQueue) {
                uyapAxios
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              }
              //   refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
              //     uyapAxios
              //       .request(config)
              //       .then((response) => resolve(response))
              //       .catch((err) => reject(err));
              //   });

              // Clear the queue
              refreshAndRetryQueue.length = 0;


              // Retry the original request
              return uyapAxios(originalRequest);
            } catch (error) {
              return Promise.reject(error);
            } finally {
              isRefreshing = false;
            }
          }

          // Add the original request to the queue
          return new Promise<void>((resolve, reject) => {
            refreshAndRetryQueue.push({
              config: originalRequest,
              resolve,
              reject
            });
          });
        } else if (error.response.status === 403) {
          return Promise.reject(error);
        } else if (error.response.status === 400 || error.response.status === 404) {
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );

    setIsUyapAxiosInterceptorAdded(true);
  }

  const pageLoader = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center w-screen h-screen">
        <ProgressSpinner />
      </div>
    );
  };

  return (
    <UyapAxiosContext.Provider value={uyapAxios}>
      {children}
    </UyapAxiosContext.Provider>
  );
}
