import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import accountService from "../../services/account/account.service";
import { CreateMainUserDto } from "../../services/account/account.dto";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { useToast } from "../../utils/toast";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../utils/DropdownValues";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
import { LayoutContext } from "../../layout/context/layoutcontext";

const RegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext);

  const [iysConfirm, setIysConfirm] = useState(false);

  const formik = useFormik<CreateMainUserDto>({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      tc: "",
      kvkkAydinlatmaOnay: false,
      iysEmailAcikRizaOnay: false,
      iysPhoneAcikRizaOnay: false,
      iysSmsAcikRizaOnay: false,
      kvkkAcikRizaOnay: false
    },
    enableReinitialize: true,
    validate: (data) => {
      const errors: any = {};

      if (!data.userName) {
        errors.userName = "Kullanıcı adı zorunludur";
      }

      if (!data.email) {
        errors.email = "Eposta zorunludur";
      }

      if (!data.phoneNumber) {
        errors.phoneNumber = "Telefon numarası zorunludur";
      }

      if (!data.password) {
        errors.password = "Parola zorunludur";
      }

      if (!data.passwordConfirm) {
        errors.passwordConfirm = "Parola tekrarı zorunludur";
      }

      if (data.password && data.passwordConfirm && data.password !== data.passwordConfirm) {
        errors.passwordConfirm = "Parolalar eşleşmiyor";
      }

      if (!data.firstName) {
        errors.firstName = "Ad alanı zorunludur";
      }

      if (!data.lastName) {
        errors.lastName = "Soyad alanı zorunludur";
      }

      if (!data.tc) {
        errors.tc = "TC Kimlik Numarası zorunludur";
      }

      if (!data.address) {
        errors.address = "Adres alanı zorunludur";
      }

      if (data.kvkkAydinlatmaOnay === false) {
        errors.kvkkAydinlatmaOnay = "Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metninini onaylamalısınız";
      }

      return errors;
    },
    onSubmit: async (values) => {
      values.phoneNumber = values.phoneNumber
        .replace(/_/g, "")
        .replace(/ /g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/-/g, "");
      setBlocked(true);
      const res = await accountService.Register(values);
      if (!res?.result?.error && res?.result?.data) {
        toast.show("Kayıt işlemi başarılı. Telefonunuzu doğrulayınız.", "success");
        setBlocked(false);
        navigate(`/phone-verify/${res.result.data.id}/${res.result.data.phoneNumber}`);
      }
    }
  });

  useEffect(() => {
    if (
      formik.values.iysEmailAcikRizaOnay === false &&
      formik.values.iysPhoneAcikRizaOnay === false &&
      formik.values.iysSmsAcikRizaOnay === false
    ) {
      setIysConfirm(false);
    }
    if (
      formik.values.iysEmailAcikRizaOnay === true &&
      formik.values.iysPhoneAcikRizaOnay === true &&
      formik.values.iysSmsAcikRizaOnay === true
    ) {
      setIysConfirm(true);
    }
  }, [formik.values.iysEmailAcikRizaOnay, formik.values.iysPhoneAcikRizaOnay, formik.values.iysSmsAcikRizaOnay]);

  return (
    <form
      className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pt-5"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)",
            maxWidth: "1000px"
          }}
        >
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
            <div className="text-center mb-5">
              <img src="/images/logo.png" alt="Image" height="70" className="mb-3" />
              <div className="text-900 text-3xl font-medium mb-3">{t("Register")}</div>
              <span className="text-600 font-medium">{t("RegisterToContinue")}</span>
            </div>

            <div className="grid">
              <div className="col-12 md:col-6">
                <label htmlFor="firstName" className="text-primary">
                  {t("FirstName")}
                </label>
                <InputText
                  id="firstName"
                  type="text"
                  placeholder={t("FirstName")}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "firstName")
                  })}
                  value={formik.values.firstName}
                  onChange={(e) => formik.setFieldValue("firstName", e.target.value.toLocaleUpperCase("tr"))}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "firstName") && (
                  <div className="p-error mt-3">{formik.errors.firstName}</div>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="lastName" className="text-primary">
                  Soy isim
                </label>
                <InputText
                  id="lastName"
                  type="text"
                  placeholder="Soy isim"
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "lastName")
                  })}
                  value={formik.values.lastName}
                  onChange={(e) => formik.setFieldValue("lastName", e.target.value.toLocaleUpperCase("tr"))}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "lastName") && <div className="p-error mt-3">{formik.errors.lastName}</div>}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="userName" className="text-primary">
                  {t("UserName")}
                </label>
                <InputText
                  id="userName"
                  type="text"
                  placeholder={t("UserName")}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "userName")
                  })}
                  value={formik.values.userName}
                  onChange={(e) => formik.setFieldValue("userName", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "userName") && <div className="p-error mt-3">{formik.errors.userName}</div>}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="email" className="text-primary">
                  {t("Email")}
                </label>
                <InputText
                  id="email"
                  type="email"
                  placeholder={t("EmailAddress")}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "email")
                  })}
                  value={formik.values.email}
                  onChange={(e) => formik.setFieldValue("email", e.target.value)}
                  style={{ padding: "1rem" }}
                  autoComplete="email"
                />
                {FormikValueIsValid(formik, "email") && <div className="p-error mt-3">{formik.errors.email}</div>}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="tc" className="text-primary">
                  {"TC Kimlik Numarası"}
                </label>
                <InputText
                  id="tc"
                  keyfilter="int"
                  maxLength={11}
                  placeholder={"TC Kimlik Numarası"}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "tc")
                  })}
                  value={formik.values.tc}
                  onChange={(e) => formik.setFieldValue("tc", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "tc") && <div className="p-error mt-3">{formik.errors.tc}</div>}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="phoneNumber" className="text-primary">
                  {t("PhoneNumber")}
                </label>
                <InputMask
                  id="phoneNumber"
                  keyfilter="int"
                  mask="(0999) 999-99-99"
                  placeholder={t("PhoneNumber")}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "phoneNumber")
                  })}
                  value={formik.values.phoneNumber}
                  onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "phoneNumber") && (
                  <div className="p-error mt-3">{formik.errors.phoneNumber}</div>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="password" className="text-primary">
                  {t("Password")}
                </label>
                <Password
                  inputId="password"
                  value={formik.values.password}
                  onChange={(e) => formik.setFieldValue("password", e.target.value)}
                  placeholder={t("Password")}
                  toggleMask
                  className={classNames("block w-full mt-2 login-password", {
                    "p-invalid": FormikValueIsValid(formik, "password")
                  })}
                  autoComplete="new-password"
                  inputClassName={classNames("block w-full p-3", {
                    "p-invalid": FormikValueIsValid(formik, "password")
                  })}
                ></Password>
                {FormikValueIsValid(formik, "password") && <div className="p-error mt-3">{formik.errors.password}</div>}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="passwordConfirm" className="text-primary">
                  Parola Tekrar
                </label>
                <Password
                  inputId="passwordConfirm"
                  value={formik.values.passwordConfirm}
                  onChange={(e) => formik.setFieldValue("passwordConfirm", e.target.value)}
                  placeholder="Parola Tekrar"
                  toggleMask
                  className={classNames("block w-full mt-2 login-password", {
                    "p-invalid": FormikValueIsValid(formik, "passwordConfirm")
                  })}
                  autoComplete="new-password"
                  inputClassName={classNames("block w-full p-3", {
                    "p-invalid": FormikValueIsValid(formik, "passwordConfirm")
                  })}
                ></Password>
                {FormikValueIsValid(formik, "passwordConfirm") && (
                  <div className="p-error mt-3">{formik.errors.passwordConfirm}</div>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="vergiNumarasi" className="text-primary">
                  Vergi Numarasi
                </label>
                <InputText
                  id="vergiNumarasi"
                  type="string"
                  placeholder="Vergi Numarasi"
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "vergiNumarasi")
                  })}
                  value={formik.values.vergiNumarasi}
                  onChange={(e) => formik.setFieldValue("vergiNumarasi", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "vergiNumarasi") && (
                  <div className="p-error mt-3">{formik.errors.vergiNumarasi}</div>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="vergiDairesi" className="text-primary">
                  Vergi Dairesi
                </label>
                <InputText
                  id="vergiDairesi"
                  type="string"
                  placeholder="Vergi Dairesi"
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "vergiDairesi")
                  })}
                  value={formik.values.vergiDairesi}
                  onChange={(e) => formik.setFieldValue("vergiDairesi", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "vergiDairesi") && (
                  <div className="p-error mt-3">{formik.errors.vergiDairesi}</div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label className="text-primary" htmlFor="bagliBulunulanBaro">
                  Bağlı Bulunduğunuz Baro
                </label>
                <Dropdown
                  id="bagliBulunulanBaro"
                  name="bagliBulunulanBaro"
                  inputId="bagliBulunulanBaro"
                  filter
                  value={formik.values.bagliBulunulanBaro}
                  onChange={(e: DropdownChangeEvent) => {
                    formik.setFieldValue("bagliBulunulanBaro", e.value);
                  }}
                  options={DropdownDatas.getBaro()}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Bağlı Bulunduğunuz Baro"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "bagliBulunulanBaro")
                  })}
                />
                {FormikValueIsValid(formik, "bagliBulunulanBaro") && (
                  <div className="p-error mt-3">{formik.errors.bagliBulunulanBaro}</div>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="baroSicilNumarasi" className="text-primary">
                  Baro Sicil Numarası
                </label>
                <InputText
                  id="baroSicilNumarasi"
                  type="string"
                  placeholder="Baro Sicil Numarası"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "baroSicilNumarasi")
                  })}
                  value={formik.values.baroSicilNumarasi}
                  onChange={(e) => formik.setFieldValue("baroSicilNumarasi", e.target.value)}
                />
                {FormikValueIsValid(formik, "baroSicilNumarasi") && (
                  <div className="p-error mt-3">{formik.errors.baroSicilNumarasi}</div>
                )}
              </div>

              {/* 
              <div className="col-12 md:col-6">
                <label htmlFor="icHatDahilisi" className="text-primary">
                  İç Hat Dahilisi
                </label>
                <InputText
                  id="icHatDahilisi"
                  type="string"
                  placeholder="İç Hat Dahilisi"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "icHatDahilisi"),
                  })}
                  value={formik.values.icHatDahilisi}
                  onChange={(e) =>
                    formik.setFieldValue("icHatDahilisi", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "icHatDahilisi") && (
                  <div className="p-error mt-3">
                    {formik.errors.icHatDahilisi}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label
                  htmlFor="garantiHtsKullaniciAdi"
                  className="text-primary"
                >
                  Garanti HTS Kullanıcı Adı
                </label>
                <InputText
                  id="garantiHtsKullaniciAdi"
                  type="string"
                  placeholder="Garanti HTS Kullanıcı Adı"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsKullaniciAdi"
                    ),
                  })}
                  value={formik.values.garantiHtsKullaniciAdi}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "garantiHtsKullaniciAdi",
                      e.target.value
                    )
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsKullaniciAdi") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsKullaniciAdi}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="garantiHtsBuroKodu" className="text-primary">
                  Garanti HTS Büro Kodu
                </label>
                <InputText
                  id="garantiHtsBuroKodu"
                  type="string"
                  placeholder="Garanti HTS Büro Kodu"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsBuroKodu"
                    ),
                  })}
                  value={formik.values.garantiHtsBuroKodu}
                  onChange={(e) =>
                    formik.setFieldValue("garantiHtsBuroKodu", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsBuroKodu") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsBuroKodu}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="garantiHtsSifresi" className="text-primary">
                  Garanti HTS Şifresi
                </label>
                <InputText
                  id="garantiHtsSifresi"
                  type="string"
                  placeholder="Garanti HTS Şifresi"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsSifresi"
                    ),
                  })}
                  value={formik.values.garantiHtsSifresi}
                  onChange={(e) =>
                    formik.setFieldValue("garantiHtsSifresi", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsSifresi") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsSifresi}
                  </div>
                )}
              </div> */}

              <div className="col-12">
                <label htmlFor="address" className="text-primary">
                  Adres
                </label>
                <InputTextarea
                  id="address"
                  placeholder="Adres"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "address")
                  })}
                  value={formik.values.address}
                  onChange={(e) => formik.setFieldValue("address", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "address") && <div className="p-error mt-3">{formik.errors.address}</div>}
              </div>

              <div className="col-12">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="kvkkAydinlatmaOnay"
                    checked={formik.values.kvkkAydinlatmaOnay}
                    onChange={(e) => formik.setFieldValue("kvkkAydinlatmaOnay", e.checked ?? false)}
                    className="mr-2"
                  ></Checkbox>
                  <label className="text-color" htmlFor="kvkkAydinlatmaOnay">
                    Pratik Portal Yazılımı Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metnini okumak için
                    tıklayınız.
                  </label>
                </div>
                <div className="mt-2">
                  <a target="_blank" href="https://www.pratikportal.com/Home/KvkkAydinlatmaMetni">
                    Pratik Portal Yazılımı Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metnini okumak için
                    tıklayınız.
                  </a>
                </div>
                {FormikValueIsValid(formik, "kvkkAydinlatmaOnay") && (
                  <div className="p-error mt-3">{formik.errors.kvkkAydinlatmaOnay}</div>
                )}
              </div>

              <div className="col-12 mt-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="kvkkAcikRizaOnay"
                    checked={formik.values.kvkkAcikRizaOnay}
                    onChange={(e) => formik.setFieldValue("kvkkAcikRizaOnay", e.checked ?? false)}
                    className="mr-2"
                  ></Checkbox>
                  <label className="text-color" htmlFor="kvkkAcikRizaOnay">
                    Pratik Portal Yazılımı Kişisel Verilerin İşlenmesine İlişkin Açık Rıza Metni kapsamında verilerimin
                    işlenmesine onay veriyorum.
                  </label>
                </div>
                <div className="mt-2">
                  <a target="_blank" href="https://www.pratikportal.com/Home/AcikRizaMetni">
                    Pratik Portal Yazılımı Kişisel Verilerin İşlenmesine İlişkin Açık Rıza Metnini okumak için
                    tıklayınız.
                  </a>
                </div>
                {FormikValueIsValid(formik, "kvkkAcikRizaOnay") && (
                  <div className="p-error mt-3">{formik.errors.kvkkAcikRizaOnay}</div>
                )}
              </div>

              <div className="col-12 mt-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="iysAcikRizaOnay"
                    checked={iysConfirm}
                    onChange={(e) => {
                      if (e.checked === true) {
                        formik.setFieldValue("iysEmailAcikRizaOnay", true);
                        formik.setFieldValue("iysPhoneAcikRizaOnay", true);
                        formik.setFieldValue("iysSmsAcikRizaOnay", true);
                      } else {
                        formik.setFieldValue("iysEmailAcikRizaOnay", false);
                        formik.setFieldValue("iysPhoneAcikRizaOnay", false);
                        formik.setFieldValue("iysSmsAcikRizaOnay", false);
                      }
                      setIysConfirm(e.checked ?? false);
                    }}
                    className="mr-2"
                  ></Checkbox>
                  <div>
                    <label className="text-color" htmlFor="iysAcikRizaOnay">
                      Ticari Elektronik İleti Aydınlatma ve Onay Metni kapsamında tarafıma ileti gönderilmesine onay
                      veriyorum.
                    </label>
                  </div>
                </div>
                <div className="mt-2">
                  <a target="_blank" href="https://www.pratikportal.com/Home/IysAydinlatmaMetni">
                    Ticari Elektronik İleti Aydınlatma ve Onay Metnini okumak için tıklayınız.
                  </a>
                </div>
              </div>
              {iysConfirm && (
                <>
                  <div className="col-12 md:pl-3">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="iysEmailAcikRizaOnay"
                        checked={formik.values.iysEmailAcikRizaOnay}
                        onChange={(e) => formik.setFieldValue("iysEmailAcikRizaOnay", e.checked ?? false)}
                        className="mr-2"
                      ></Checkbox>
                      <div>
                        <label className="text-color" htmlFor="iysEmailAcikRizaOnay">
                          E-posta
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:pl-3">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="iysSmsAcikRizaOnay"
                        checked={formik.values.iysSmsAcikRizaOnay}
                        onChange={(e) => formik.setFieldValue("iysSmsAcikRizaOnay", e.checked ?? false)}
                        className="mr-2"
                      ></Checkbox>
                      <div>
                        <label className="text-color" htmlFor="iysSmsAcikRizaOnay">
                          SMS
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 md:pl-3">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="iysPhoneAcikRizaOnay"
                        checked={formik.values.iysPhoneAcikRizaOnay}
                        onChange={(e) => formik.setFieldValue("iysPhoneAcikRizaOnay", e.checked ?? false)}
                        className="mr-2"
                      ></Checkbox>
                      <div>
                        <label className="text-color" htmlFor="iysPhoneAcikRizaOnay">
                          Arama
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="w-full mt-3">
                <Button label={t("SignUp")} className="w-full text-xl mt-2" type="submit"></Button>
              </div>
              <div className="w-full mt-3 text-center">
                <Link
                  to="/login"
                  className="font-medium no-underline ml-2 text-right cursor-pointer"
                  style={{ color: "var(--primary-color)" }}
                >
                  Zaten hesabınız var mı? Giriş yapın
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RegisterPage;
