import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguSgkCalisaniModel } from "../TopluSorgu.dto";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";

const SskCalisaniDataTable = (props: { tableData: TopluSorguSgkCalisaniModel[] }) => {
  const sskCalisaniVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguSgkCalisaniModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      isYeri: commonFilterContains,
      isYeriAdresi: commonFilterContains,
      isyeriIli: commonFilterContains,
      isYeriSicil: commonFilterContains,
      donemAy: commonFilterContains,
      sgkDurum: commonFilterContains,
      sgkSicilNo: commonFilterContains,
      ilkIseGirisTarihi: commonFilterDateIs,
      sonIseGirisTarihi: commonFilterDateIs,
      istenCıkısTarihi: commonFilterDateIs
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "İş Yeri",
      "İş Yeri Adresi",
      "İş Yeri İli",
      "İş Yeri Sicil",
      "Dönem/Ay",
      "Durum",
      "Sicil No",
      "İlk İşe Giriş Tarihi",
      "Son İşe Giriş Tarihi",
      "İşten Çıkış Tarihi"
    ];

    const filteredData = sskCalisaniVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      isYeri: data.isYeri,
      isYeriAdresi: data.isYeriAdresi,
      isyeriIli: data.isyeriIli,
      isYeriSicil: data.isYeriSicil,
      donemAy: data.donemAy,
      sgkDurum: data.sgkDurum,
      sgkSicilNo: data.sgkSicilNo,
      ilkIseGirisTarihi:
        data.ilkIseGirisTarihi && moment(data.ilkIseGirisTarihi).isValid()
          ? moment(data.ilkIseGirisTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
      sonIseGirisTarihi:
        data.sonIseGirisTarihi && moment(data.sonIseGirisTarihi).isValid()
          ? moment(data.sonIseGirisTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
      istenCıkısTarihi:
        data.istenCıkısTarihi && moment(data.istenCıkısTarihi).isValid()
          ? moment(data.istenCıkısTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : ""
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluSorguSskCalisani");
  };

  return (
    <div>
      <DataTable
        value={sskCalisaniVeri || []}
        ref={dt}
        dataKey="sgkSicilNo"
        showGridlines
        emptyMessage="Ssk Çalışanı Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Ssk Çalışanı Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "isYeri",
          "isYeriAdresi",
          "isyeriIli",
          "isYeriSicil",
          "donemAy",
          "sgkDurum",
          "sgkSicilNo",
          "ilkIseGirisTarihi",
          "sonIseGirisTarihi",
          "istenCıkısTarihi"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filterField="ortakDegerler.dosyaNo"
          filter
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filterField="ortakDegerler.alacakliAdi"
          filter
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filterField="ortakDegerler.borcluAdi"
          filter
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filterField="ortakDegerler.borcluTCVKNo"
          filter
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filterField="ortakDegerler.dosyaDurumu"
          filter
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="İş Yeri"
          field="isYeri"
          sortable
          filter
          filterField="isYeri"
          filterPlaceholder="İş Yeri adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İş Yeri adına göre filtreleyin</div>}
        />
        <Column
          header="İş Yeri Adresi"
          field="isYeriAdresi"
          sortable
          filter
          filterField="isYeriAdresi"
          filterPlaceholder="İş Yeri adresine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İş Yeri adresine göre filtreleyin</div>}
        />
        <Column
          header="İş Yeri İli"
          field="isyeriIli"
          sortable
          filter
          filterField="isyeriIli"
          filterPlaceholder="İş Yeri iline göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İş Yeri iline göre filtreleyin</div>}
        />
        <Column
          header="İş Yeri Sicil"
          field="isYeriSicil"
          sortable
          filter
          filterField="isYeriSicil"
          filterPlaceholder="İş Yeri siciline göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İş Yeri siciline göre filtreleyin</div>}
        />
        <Column
          header="Dönem/Ay"
          field="donemAy"
          sortable
          filter
          filterField="donemAy"
          filterPlaceholder="Dönem/Aya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dönem/Aya göre filtreleyin</div>}
        />
        <Column
          header="Durum"
          field="sgkDurum"
          sortable
          filter
          filterField="sgkDurum"
          filterPlaceholder="Duruma göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>}
        />
        <Column
          header="Sicil No"
          field="sgkSicilNo"
          sortable
          filter
          filterField="sgkSicilNo"
          filterPlaceholder="Sicil numarasına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Sicil numarasına göre filtreleyin</div>}
        />
        <Column
          header="İlk İşe Giriş Tarihi"
          field="ilkIseGirisTarihi"
          sortable
          body={(rowData) =>
            rowData.ilkIseGirisTarihi && moment(rowData.ilkIseGirisTarihi).isValid()
              ? moment(rowData.ilkIseGirisTarihi).format(process.env.REACT_APP_DATE_FORMAT)
              : ""
          }
          filter
          filterField="ilkIseGirisTarihi"
          filterPlaceholder="İlk işe giriş tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İlk işe giriş tarihine göre filtreleyin</div>}
        />
        <Column
          header="Son İşe Giriş Tarihi"
          field="sonIseGirisTarihi"
          sortable
          body={(rowData) =>
            rowData.sonIseGirisTarihi && moment(rowData.sonIseGirisTarihi).isValid() ? (
              moment(rowData.sonIseGirisTarihi).format(process.env.REACT_APP_DATE_FORMAT)
            ) : (
              <></>
            )
          }
          filter
          filterField="sonIseGirisTarihi"
          filterPlaceholder="Son işe giriş tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Son işe giriş tarihine göre filtreleyin</div>}
        />
        <Column
          header="İşten Çıkış Tarihi"
          field="istenCıkısTarihi"
          sortable
          body={(rowData) =>
            rowData.istenCıkısTarihi && moment(rowData.istenCıkısTarihi).isValid()
              ? moment(rowData.istenCıkısTarihi).format(process.env.REACT_APP_DATE_FORMAT)
              : ""
          }
          filter
          filterField="istenCıkısTarihi"
          filterPlaceholder="İşten çıkış tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İşten çıkış tarihine göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default SskCalisaniDataTable;
