import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../utils/toast";
import { UpdateProfileDto, UserDto } from "../../services/account/account.dto";
import { useParams } from "react-router-dom";
import SubUsersComponent from "../sub-users/SubUsersComponent";
import { useAuth } from "../../utils/auth";
import AdminService from "../../services/admin/admin.services";
import moment from "moment";
import { LayoutContext } from "../../layout/context/layoutcontext";
import License from "./License";
import Order from "./Order";
import { UpdateMainUserDto } from "../../services/admin/admin.dto";
import UpsertMainUserDiyalog from "../admin/users/UpsertMainUser";
import { Button } from "primereact/button";

const SubUsersDetails = (props: {}) => {
  let { id } = useParams();
  const dt = useRef<DataTable<UserDto[]>>(null);
  const toast = useToast();

  const { setBlocked } = useContext(LayoutContext);
  const [userInfo, setUserInfo] = useState<UserDto>();

  const [visibleUpdateUserDialog, setVisibleUpdateUserDialog] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserDto>();

  const {
    data: mainUserResponse,
    refetch: refetchMainUsers,
    isLoading: mainUserResponseIsLoading
  } = useQuery({
    queryKey: ["MainUsers", id],
    refetchOnMount: true,
    queryFn: () => (id ? AdminService.GetMainUser(id) : null)
  });

  useEffect(() => {
    setBlocked(mainUserResponseIsLoading);
  }, [mainUserResponseIsLoading]);

  useEffect(() => {
    if (mainUserResponse && mainUserResponse.result?.data) {
      setUserInfo({
        userName: mainUserResponse.result.data.userName,
        firstName: mainUserResponse.result.data.firstName,
        lastName: mainUserResponse.result.data.lastName,
        tc: mainUserResponse.result.data.tc,
        vergiNumarasi: mainUserResponse.result.data.vergiNumarasi,
        vergiDairesi: mainUserResponse.result.data.vergiDairesi,
        address: mainUserResponse.result.data.address,
        icHatDahilisi: mainUserResponse.result.data.icHatDahilisi,
        garantiHtsKullaniciAdi: mainUserResponse.result.data.garantiHtsKullaniciAdi,
        garantiHtsSifresi: mainUserResponse.result.data.garantiHtsSifresi,
        garantiHtsBuroKodu: mainUserResponse.result.data.garantiHtsBuroKodu,
        iysEmailAcikRizaOnay: mainUserResponse.result.data.iysEmailAcikRizaOnay,
        iysPhoneAcikRizaOnay: mainUserResponse.result.data.iysPhoneAcikRizaOnay,
        iysSmsAcikRizaOnay: mainUserResponse.result.data.iysSmsAcikRizaOnay,
        kvkkAcikRizaOnay: mainUserResponse.result.data.kvkkAcikRizaOnay,
        baroSicilNumarasi: mainUserResponse.result.data.baroSicilNumarasi,
        bagliBulunulanBaro: mainUserResponse.result.data.bagliBulunulanBaro,
        phoneNumber: mainUserResponse.result.data.phoneNumber,
        id: mainUserResponse.result.data.id || "",
        email: mainUserResponse.result.data.email || "",
        allowedLawyerTc: mainUserResponse.result.data.allowedLawyerTc || "",
        isActive: mainUserResponse.result.data.isActive || "",
        isDeleted: mainUserResponse.result.data.isDeleted ?? false,
        creationTime: mainUserResponse.result.data.creationTime ?? new Date().toISOString(),
        registerIPNumber: mainUserResponse.result.data.registerIPNumber || "",
        registerPortNumber: mainUserResponse.result.data.registerPortNumber || ""
      });
    }
  }, [mainUserResponse]);

  const exportExcel = () => {
    // const data = props.users || [];
    // const filteredData = data.map((data) => ({
    //   userName: data.userName,
    //   email: data.email,
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   tc: data.tc,
    //   phoneNumber: data.phoneNumber
    // }));
    // const headers = ["Kullanıcı Adı", "Eposta", "Ad", "Soyad", "TC", "Telefon Numarası"];
    // const worksheetData = [headers, ...filteredData.map(Object.values)];
    // ExportFile.SaveAsExcelFile(worksheetData, "AltKullanıcılar");
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  return (
    <div>
      <div className="card p-2 lg:p-5 mt-3 mb-3">
      <div className="mb-3 flex flex-row-reverse">
          <Button
            label="Ana Kullanıcı Düzenle"
            icon="pi pi-plus"
            severity="info"
            onClick={() => {
              setVisibleUpdateUserDialog(true);
              setSelectedUser(userInfo);
            }}
          />
        </div>

        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">T.C.</div>
              <div>{`: ${mainUserResponse?.result?.data?.tc ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">Telefon Numarası</div>
              <div>{`: ${userInfo?.phoneNumber ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">E-posta</div>
              <div>{`: ${userInfo?.email ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">Kullanıcı Adı</div>
              <div>{`: ${userInfo?.userName ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">Vergi Numarası</div>
              <div>{`: ${userInfo?.vergiNumarasi ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">Vergi Dairesi</div>
              <div>{`: ${userInfo?.vergiDairesi ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">Adres</div>
              <div>{`: ${userInfo?.address ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">Bağlı Bulunduğu Baro</div>
              <div>{`: ${userInfo?.bagliBulunulanBaro ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">KVKK Açık Rıza Onay</div>
              <div>{`: ${userInfo?.kvkkAcikRizaOnay ? "Onaylandı" : "Onaylanmadı"}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">IYS Email Açık Rıza Onay</div>
              <div>{`: ${userInfo?.iysEmailAcikRizaOnay ? "Onaylandı" : "Onaylanmadı"}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">IYS Telefon Açık Rıza Onay</div>
              <div>{`: ${userInfo?.iysPhoneAcikRizaOnay ? "Onaylandı" : "Onaylanmadı"}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">IYS Sms Açık Rıza Onay</div>
              <div>{`: ${userInfo?.iysSmsAcikRizaOnay ? "Onaylandı" : "Onaylanmadı"}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">Kayıt Olduğu IP Adresi</div>
              <div>{`: ${userInfo?.registerIPNumber ?? ""}`}</div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex gap-3">
              <div className="font-bold w-14rem">Kayıt Olduğu Port Numarası</div>
              <div>{`: ${userInfo?.registerPortNumber ?? ""}`}</div>
            </div>
          </div>
        </div>


      </div>

      <SubUsersComponent
        // users={mainUserResponse?.result?.data?.subUsers}
        users={mainUserResponse?.result?.data?.subUsers}
        refetchSubUser={refetchMainUsers}
        isAdminPanel={true}
        id={id}
      />

      <div className="card mt-3 mb-3">
        <License
          licenses={mainUserResponse?.result?.data?.licenses}
          refetchMainUsers={refetchMainUsers}
          mainUserId={id}
        />
      </div>
      <div className="card">
        <Order orders={mainUserResponse?.result?.data?.orders ?? []} />
      </div>

      <UpsertMainUserDiyalog
        visible={visibleUpdateUserDialog}
        user={selectedUser!}
        onHide={() => {
          setVisibleUpdateUserDialog(false);
          setSelectedUser(undefined);
        }}
        onOk={async (updateUser: UpdateMainUserDto) => {
          setBlocked(true); // refetchUsers'tan sonra false'a donecek
          var res = await AdminService.UpdateMainUser(updateUser);
          if (res.result && !res.result?.error) {
            toast.show("Kullanıcı başarılı şekilde güncellendi.", "success");
            setVisibleUpdateUserDialog(false);
            setSelectedUser(undefined);
            refetchMainUsers();
          }
        }}
      />
    </div>
  );
};

export default SubUsersDetails;
