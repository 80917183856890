import React, { useContext, useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { LoginDto } from "../../services/auth/auth.dto";
import authService from "../../services/auth/auth.service";
import { useAuth } from "../../utils/auth";
import { useHub } from "../../utils/buro-hub";
import CryptoJS from "crypto-js";
import { SettingsModel, useElectron } from "../../utils/electron-connection";
import { LayoutContext } from "../../layout/context/layoutcontext";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { confirmDialog } from "primereact/confirmdialog";

const Login = () => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const auth = useAuth();
  const uyapHub = useHub();
  const navigate = useNavigate();
  const [isVisiblePhoneConfirmation, setIsVisiblePhoneConfirmation] = useState<boolean>(false);
  const secretKey = "Oja7.?36FsdsfA#[yddue(9*+ausdsFaUASDA";
  const [selecetedFolderPath, setFolderPath] = useState<string>("");
  const electron = useElectron();
  const [visibleForceLoginDialog, setVisibleForceLoginDialog] = useState<boolean>(false);

  const { setBlocked } = useContext(LayoutContext);

  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  );

  const readSettings = async () => {
    const settings = await electron.getSettings();

    if (!settings) {
      return;
    }

    const bytes = CryptoJS.AES.decrypt(settings.password, secretKey);
    const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);

    if (settings.rememberMe) {
      formik.setFieldValue("email", settings.email);
      formik.setFieldValue("password", decryptedPassword);
      setChecked(settings.rememberMe);

      setTimeout(() => {
        formik.validateForm();
        formik.setTouched(
          {
            email: true,
            password: true
          },
          false
        );
      }, 0);
    }
    setFolderPath(settings.folderPath);
  };

  useEffect(() => {
    readSettings();
  }, []);

  useEffect(() => {
    if (
      auth.getUser().accessToken &&
      auth.getUser().accessToken.length > 0 &&
      auth.getUser().refreshToken &&
      auth.getUser().refreshToken.length > 0
    ) {
      navigate("/app/homepage", { replace: true });
    }
  }, []);

  const forceLoginConfirm = () => {
    confirmDialog({
      message:
        "Lisans sınırına ulaştınız. Eğer yine de giriş yapmak isterseniz aktif kullanıcılardan birisi sistemden çıkartılacaktır. Devam etmek istiyor musunuz?",
      header: "Lisans Sınırı",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        formik.setFieldValue("forceLogin", true);
        formik.submitForm();
      },
      reject: () => {},
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      className: "w-6"
    });
  };

  const formik = useFormik<LoginDto>({
    initialValues: {
      email: "",
      password: "",
      forceLogin: false
    },
    enableReinitialize: true,
    validate: (data) => {
      const errors: any = {};

      if (!data.email) {
        errors.email = t("EmailIsRequired");
      }

      if (!data.password) {
        errors.password = t("PasswordIsRequired");
      }

      return errors;
    },
    onSubmit: (values) => {
      setBlocked(true);
      authService
        .CreateToken(values)
        .then(async (res) => {
          if (res.result && !res.result.error) {
            auth.removeUserFromStorage();
            auth.setToken(res.result.data, checked);
            uyapHub.joinGroup();
            uyapHub.refreshBuroSettings();
            setBlocked(false);
            navigate("/app/homepage", { replace: true });
            formik.resetForm();

            const encryptedPassword = CryptoJS.AES.encrypt(values.password, secretKey).toString();

            let settings: SettingsModel = (await electron.getSettings()) ?? {
              email: "",
              password: "",
              rememberMe: false,
              folderPath: selecetedFolderPath,
              theme: "lara-light-indigo",
              colorScheme: "light",
              scale: 12
            };

            settings.email = checked ? values.email : "";
            settings.password = checked ? encryptedPassword : "";
            settings.rememberMe = checked;
            settings.folderPath = selecetedFolderPath;

            await electron.setSettings(settings);
          }

          if (
            res.result &&
            res.result.error &&
            res.result.error.errors.some((x) => x.includes("Lütfen önce telefon numaranızı onaylayınız"))
          ) {
            setBlocked(false);
            setIsVisiblePhoneConfirmation(true);
          }

          if (
            res.result &&
            res.result.statusCode === 400 &&
            res.result.error &&
            res.result.error.errors.some((x) => x.includes("Yetersiz Lisans"))
          ) {
            forceLoginConfirm();
          }
        })
        .finally(() => {
          setBlocked(false);
        });
    }
  });

  return (
    <form className={containerClassName} onSubmit={formik.handleSubmit}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
          }}
        >
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
            <div className="text-center mb-5">
              <Link to="/">
                <img src="/images/logo.png" alt="Image" height="70" className="mb-3" />
              </Link>

              <div className="text-900 text-3xl font-medium mb-3">{t("Welcome")}</div>
              <span className="text-600 font-medium">{t("SignInToContinue")}</span>
            </div>

            <div>
              <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
                {"Kullanıcı Adı veya E-Posta Adresi"}
              </label>
              <InputText
                id="email"
                type="text"
                placeholder={"Kullanıcı Adı veya E-Posta Adresi"}
                className={classNames("w-full md:w-30rem", {
                  "p-invalid": FormikValueIsValid(formik, "email")
                })}
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                style={{ padding: "1rem" }}
              />
              {formik.errors.email && <div className="p-error mt-3">{formik.errors.email}</div>}

              <label htmlFor="password" className="block text-900 font-medium text-xl mb-2 mt-5">
                {t("Password")}
              </label>
              <div className="login-password">
                <Password
                  inputId="password"
                  value={formik.values.password}
                  onChange={(e) => formik.setFieldValue("password", e.target.value)}
                  placeholder={t("Password")}
                  className="block w-full"
                  toggleMask={true}
                  feedback={false}
                  inputClassName={classNames("block w-full p-3", {
                    "p-invalid": FormikValueIsValid(formik, "password")
                  })}
                ></Password>
                {formik.errors.password && <div className="p-error mt-3">{formik.errors.password}</div>}
              </div>

              <div className="flex align-items-center justify-content-between mb-5 gap-5 mt-5">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="rememberme"
                    checked={checked}
                    onChange={(e) => setChecked(e.checked ?? false)}
                    className="mr-2"
                  ></Checkbox>
                  <label className="text-primary" htmlFor="rememberme">
                    {t("RememberMe")}
                  </label>
                </div>
                <Link
                  to="/forgot-password"
                  className="font-medium no-underline ml-2 text-right cursor-pointer"
                  style={{ color: "var(--primary-color)" }}
                >
                  {t("ForgotPassword")}
                </Link>
              </div>
              <Button label={t("Login")} className="w-full p-3 text-xl" type="submit"></Button>
              <div className="mt-3 text-center">
                <Link
                  to="/register"
                  className="font-medium no-underline ml-2 text-right cursor-pointer"
                  style={{ color: "var(--primary-color)" }}
                >
                  Hesabınız yok mu? Kayıt olun
                </Link>
              </div>
              {isVisiblePhoneConfirmation && (
                <div className="mt-3 text-center">
                  <Link
                    to="/verify-user-and-confirm-phone-number"
                    className="font-medium no-underline ml-2 text-right cursor-pointer"
                    style={{ color: "var(--primary-color)" }}
                  >
                    Telefon numarası doğrulama
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
