import React, { useState, useRef, useContext, useEffect } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import RoleService from "../../services/role/role.services";
import { useQuery } from "@tanstack/react-query";
import UpsertRole from "./UpsertRole";
import { useToast } from "../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode } from "primereact/api";
import { RoleDto } from "../../services/role/role.dto";
import { ExportFile } from "../../utils/export-file";
import { LayoutContext } from "../../layout/context/layoutcontext";

const RolePage = () => {
  const [role, setRole] = useState<RoleDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<RoleDto[]>>(null);
  const [visibleUpsertRoleDialog, setVisibleUpsertRoleDialog] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  const { data: roleResponse, refetch: refetchRole, isLoading: isLoadingRole } = useQuery({
    queryKey: ["Roles"],
    refetchOnMount: true,
    queryFn: () => RoleService.GetAll()
  });

  useEffect(() => {
    setBlocked(isLoadingRole)
  }, [isLoadingRole])

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label={"Yeni Rol Ekle"}
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertRoleDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV()} data-pr-tooltip="CSV" />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: RoleDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => {
            setRole(rowData);
            setVisibleUpsertRoleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => {
            deleteRoleConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{"Roller"}</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={"Ara"} />
      </span>
    </div>
  );

  const deleteRole = (rowData: RoleDto) => {
    setBlocked(true)
    RoleService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Rol başarıyla silindi.", "success");
        refetchRole();
      }
    });
  };

  const deleteRoleConfirm = (rowData: RoleDto) => {
    confirmDialog({
      message: "Rolü silmek istediğinize emin misiniz?",
      header: "Rol Sil",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteRole(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  // export excel
  const exportExcel = () => {
    const data = roleResponse?.result?.data || [];

    const headers = ["Rol Adı", "Yetkiler"];

    const filteredData = data.map((data) => ({
      name: data.name,
      permissions: data.permissions.map((permission) => permission.name).join(", ")
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "Rol");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={roleResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} Rol"
          filters={filters}
          // filterDisplay="row"
          loading={roleResponse?.isLoading}
          globalFilterFields={["name"]}
          emptyMessage={"Kayıt bulunamadı."}
          header={header}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
          <Column field="name" header={"Rol Adı"} sortable></Column>
          <Column
            field="permissions"
            header={"Yetkiler"}
            body={(rowData: RoleDto) => {
              return <span>{rowData.permissions.map((permission) => permission.name).join(", ")}</span>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertRole
        visible={visibleUpsertRoleDialog}
        setVisible={setVisibleUpsertRoleDialog}
        role={role}
        setRole={setRole}
        refetchRole={refetchRole}
      />
    </div>
  );
};

export default RolePage;
