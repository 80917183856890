import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import KampanyaMektubuService from "../../../../services/kampanya-mektubu/kampanya-mektubu.service";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../../../utils/DropdownValues";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useToast } from "../../../../utils/toast";
import {
  CreateKampanyaMektubuDto,
  KampanyaMektubuDto,
  UpdateKampanyaMektubuDto,
} from "../../../../services/kampanya-mektubu/kampanya-mektubu.dto";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import { InputText } from "primereact/inputtext";
import { useQuery } from "@tanstack/react-query";
import IlceIlService from "../../../../services/ilce-il/ilce-il.services";
import { useContext, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const now: Date = new Date();

const UpsertKampanyaMektubu = (props: any) => {
  const kampanyaMektubu: KampanyaMektubuDto = props.kampanyaMektubu;
  const setKampanyaMektubu = props.setKampanyaMektubu;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchKampanyaMektubu = props.refetchKampanyaMektubu;
  const icraFoy: IcraFoyDto = props.icraFoy;
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)

  let updateKampanyaMektubuDto: UpdateKampanyaMektubuDto;

  if (kampanyaMektubu === null || kampanyaMektubu === undefined) {
    updateKampanyaMektubuDto = {
      id: 0,
      ilId: 0,
      ilceId: 0,
      muhatap: "",
      muhatapAdresi: "",
      yazimTarihi: now,
      durum: "",
      gonderimTarihi: undefined,
    };
  } else {
    updateKampanyaMektubuDto = {
      ...kampanyaMektubu,
      yazimTarihi: new Date(kampanyaMektubu.yazimTarihi),
      gonderimTarihi: kampanyaMektubu.gonderimTarihi
        ? new Date(kampanyaMektubu.gonderimTarihi)
        : undefined,
      ilceId: kampanyaMektubu.ilce.id,
      ilId: kampanyaMektubu.il.id,
    };
  }

  const { data: illerResponse, isLoading: isLoadingIller } = useQuery({
    queryKey: ["Iller"],
    refetchOnMount: true,
    queryFn: () => IlceIlService.GetAllIl(),
  });

  const { data: ilcelerResponse, refetch: refetchIlcelerResponse, isLoading: isLoadingIlceler } = useQuery({
    queryKey: ["Ilceler"],
    refetchOnMount: true,
    queryFn: () => IlceIlService.GetAllIlce(formik.values.ilId),
  });

  useEffect(() => {
    setBlocked(isLoadingIller || isLoadingIlceler)
  }, [isLoadingIlceler, isLoadingIller])

  const formik = useFormik<UpdateKampanyaMektubuDto>({
    initialValues: updateKampanyaMektubuDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateKampanyaMektubuDto> = {};

      if (!data.yazimTarihi) {
        errors.yazimTarihi = "Yazım Tarihi alanı boş bırakılamaz";
      }

      if (!data.muhatap) {
        errors.muhatap = "Muhatap alanı boş bırakılamaz";
      }

      if (!data.muhatapAdresi) {
        errors.muhatapAdresi = "Muhatap Adresi alanı boş bırakılamaz";
      }

      if (!data.ilId) {
        errors.ilId = "İl alanı boş bırakılamaz";
      }

      if (!data.ilceId) {
        errors.ilceId = "İlçe alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      if (formik.values.id === 0) {
        var kampanyaTarihiCreateDto: CreateKampanyaMektubuDto = {
          ...formik.values,
          icraFoyId: icraFoy.id,
        };
        KampanyaMektubuService.Create(kampanyaTarihiCreateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setKampanyaMektubu(undefined);
            refetchKampanyaMektubu();
            toast.show("Kampanya Mektubu başarıyla eklendi.", "success");
          }
        });
      } else {
        var kampanyaMektubuUpdateDto: UpdateKampanyaMektubuDto = {
          ...formik.values,
        };
        KampanyaMektubuService.Update(kampanyaMektubuUpdateDto).then((res) => {
          if (res.result && !res.result?.error) {
            setVisible(false);
            formik.resetForm();
            setKampanyaMektubu(undefined);
            refetchKampanyaMektubu();
            toast.show("Kampanya Mektubu başarıyla güncellendi.", "success");
          }
        });
      }
    },
  });

  useEffect(() => {
    refetchIlcelerResponse();
  }, [formik.values.ilId]);

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.id === 0
          ? "Kampanya Mektubu Ekle"
          : "Kampanya Mektubu Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setKampanyaMektubu(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setKampanyaMektubu(undefined);
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={formik.submitForm}
          />
        </div>
      }
      className=""
      style={{ width: "75vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="yazimTarihi">
            Yazım Tarihi
          </label>
          <Calendar
            value={formik.values.yazimTarihi}
            onChange={(e) => formik.setFieldValue("yazimTarihi", e.value)}
            dateFormat="dd/mm/yy"
            showIcon
            locale="tr"
            mask="99/99/9999"
            maxDate={now}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "yazimTarihi"),
            })}
          />

          {!formik.values.yazimTarihi && (
            <div className="p-error mt-3">
              Yazım Tarihi alanı boş bırakılamaz
            </div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="muhatap">
            Muhatap
          </label>
          <InputText
            id="muhatap"
            name="muhatap"
            value={formik.values.muhatap}
            onChange={(e) => {
              formik.setFieldValue(
                "muhatap",
                e.target.value.toLocaleUpperCase("tr")
              );
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "muhatap"),
            })}
          />
          {FormikValueIsValid(formik, "muhatap") && (
            <div className="p-error mt-3">{formik.errors.muhatap}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="muhatapAdresi">
            Muhatap Adresi
          </label>
          <InputTextarea
            id="muhatapAdresi"
            name="muhatapAdresi"
            value={formik.values.muhatapAdresi}
            onChange={(e) => {
              formik.setFieldValue(
                "muhatapAdresi",
                e.target.value.toLocaleUpperCase("tr")
              );
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "muhatapAdresi"),
            })}
            rows={3}
          />
          {FormikValueIsValid(formik, "muhatapAdresi") && (
            <div className="p-error mt-3">{formik.errors.muhatapAdresi}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="ilId">
            İl
          </label>
          <Dropdown
            id="ilId"
            name="ilId"
            filter
            value={formik.values.ilId}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("ilId", e.value);
            }}
            options={illerResponse?.result?.data}
            optionLabel="ilText"
            optionValue="id"
            placeholder="İl Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "ilId"),
            })}
          />
          {FormikValueIsValid(formik, "ilId") && (
            <div className="p-error mt-3">{formik.errors.ilId}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="ilceId">
            İlçe
          </label>
          <Dropdown
            id="ilceId"
            name="ilceId"
            filter
            value={formik.values.ilceId}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("ilceId", e.value);
            }}
            options={ilcelerResponse?.result?.data}
            optionLabel="ilceText"
            optionValue="id"
            placeholder="İlçe Seçiniz"
            disabled={formik.values.ilId === 0}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "ilceId"),
            })}
          />
          {FormikValueIsValid(formik, "ilceId") && (
            <div className="p-error mt-3">{formik.errors.ilceId}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="durum">
            Durum
          </label>
          <Dropdown
            id="durum"
            name="durum"
            value={formik.values.durum}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("durum", e.value);
            }}
            options={DropdownDatas.GetKampanyaMektubuDurum()}
            optionLabel="label"
            optionValue="value"
            placeholder="Durum Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "durum"),
            })}
          />
          {FormikValueIsValid(formik, "durum") && (
            <div className="p-error mt-3">{formik.errors.durum}</div>
          )}
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="gonderimTarihi">
            Gönderim Tarihi
          </label>
          <Calendar
            value={formik.values.gonderimTarihi}
            onChange={(e) => formik.setFieldValue("gonderimTarihi", e.value)}
            dateFormat="dd/mm/yy"
            showIcon
            locale="tr"
            mask="99/99/9999"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "gonderimTarihi"),
            })}
          />

          {!formik.values.gonderimTarihi && (
            <div className="p-error mt-3">
              Gönderim Tarihi alanı boş bırakılamaz
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertKampanyaMektubu;
