import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import accountService from "../../services/account/account.service";
import { useAuth } from "../../utils/auth";
import { UpdateProfileDto } from "../../services/account/account.dto";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { useToast } from "../../utils/toast";
import { Checkbox } from "primereact/checkbox";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../utils/DropdownValues";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { LayoutContext } from "../../layout/context/layoutcontext";

const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const user = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const { setBlocked } = useContext(LayoutContext)

  useEffect(() => {
    setBlocked(true)
    accountService.GetUserInformation().then((res) => {
      if (res && res.result?.data) {
        formik.setValues({
          userName: res.result.data.userName,
          firstName: res.result.data.firstName,
          lastName: res.result.data.lastName,
          tc: res.result.data.tc,
          vergiNumarasi: res.result.data.vergiNumarasi,
          vergiDairesi: res.result.data.vergiDairesi,
          address: res.result.data.address,
          icHatDahilisi: res.result.data.icHatDahilisi,
          garantiHtsKullaniciAdi: res.result.data.garantiHtsKullaniciAdi,
          garantiHtsSifresi: res.result.data.garantiHtsSifresi,
          garantiHtsBuroKodu: res.result.data.garantiHtsBuroKodu,
          iysEmailAcikRizaOnay: res.result.data.iysEmailAcikRizaOnay,
          iysPhoneAcikRizaOnay: res.result.data.iysPhoneAcikRizaOnay,
          iysSmsAcikRizaOnay: res.result.data.iysSmsAcikRizaOnay,
          kvkkAcikRizaOnay: res.result.data.kvkkAcikRizaOnay,
          baroSicilNumarasi: res.result.data.baroSicilNumarasi,
          bagliBulunulanBaro: res.result.data.bagliBulunulanBaro
        });
        setPhoneNumber(res.result.data.phoneNumber);
        setEmail(res.result.data.email);
      }
      setBlocked(false)
    }).finally(() => setBlocked(false));
  }, [user]);

  const formik = useFormik<UpdateProfileDto>({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      address: "",
      iysEmailAcikRizaOnay: false,
      iysPhoneAcikRizaOnay: false,
      iysSmsAcikRizaOnay: false,
      kvkkAcikRizaOnay: false,
      tc: ""
    },
    enableReinitialize: true,
    validate: (data) => {
      const errors: any = {};

      if (!data.userName) {
        errors.userName = "Kullanıcı adı zorunludur";
      }

      if (!data.firstName) {
        errors.firstName = "Ad alanı zorunludur";
      }

      if (!data.lastName) {
        errors.lastName = "Soyad alanı zorunludur";
      }

      if (!data.address) {
        errors.address = "Adres alanı zorunludur";
      }

      return errors;
    },
    onSubmit: (values) => {
      setBlocked(true)
      accountService.UpdateProfile(values).then((res) => {
        if (!res?.result?.error && res?.result?.data) {
          toast.show(t("UpdateProfileSuccessful"), "success");
          setBlocked(false)
          navigate("/app/homepage");
        }
      }).finally(() => setBlocked(false));
    }
  });

  return (
    <form className="surface-ground flex align-items-center justify-content-center pt-5" onSubmit={formik.handleSubmit}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)",
            maxWidth: "1000px"
          }}
        >
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
            <div className="text-center mb-5">
              <img src="/images/logo.png" alt="Image" height="70" className="mb-3" />
              <div className="text-900 text-3xl font-medium mb-3">Profilimi Güncelle</div>
              <span className="text-600 font-medium">Bu ekrandan profil bilgilerinizi güncelleyebilirsiniz.</span>
            </div>

            <div className="grid">
              <div className="col-12 md:col-6">
                <label htmlFor="firstName" className="text-primary">
                  {t("FirstName")}
                </label>
                <InputText
                  id="firstName"
                  type="text"
                  placeholder={t("FirstName")}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "firstName")
                  })}
                  value={formik.values.firstName}
                  onChange={(e) => formik.setFieldValue("firstName", e.target.value.toLocaleUpperCase("tr"))}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "firstName") && (
                  <div className="p-error mt-3">{formik.errors.firstName}</div>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="lastName" className="text-primary">
                  Soy isim
                </label>
                <InputText
                  id="lastName"
                  type="text"
                  placeholder="Soy isim"
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "lastName")
                  })}
                  value={formik.values.lastName}
                  onChange={(e) => formik.setFieldValue("lastName", e.target.value.toLocaleUpperCase("tr"))}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "lastName") && <div className="p-error mt-3">{formik.errors.lastName}</div>}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="userName" className="text-primary">
                  {t("UserName")}
                </label>
                <InputText
                  id="userName"
                  type="text"
                  placeholder={t("UserName")}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "userName")
                  })}
                  value={formik.values.userName}
                  onChange={(e) => formik.setFieldValue("userName", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "userName") && <div className="p-error mt-3">{formik.errors.userName}</div>}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="tc" className="text-primary">
                  {"TC Kimlik Numarası"}
                </label>
                <InputText
                  id="tc"
                  keyfilter="int"
                  maxLength={11}
                  placeholder={"TC Kimlik Numarası"}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "tc")
                  })}
                  value={formik.values.tc}
                  disabled={true}
                  onChange={(e) => { }}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "tc") && <div className="p-error mt-3">{formik.errors.tc}</div>}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="tc" className="text-primary">
                  {"E-Posta Adresi"}
                </label>
                <InputText
                  keyfilter="int"
                  maxLength={11}
                  placeholder={"E-Posta Adresi"}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "tc")
                  })}
                  value={email}
                  disabled={true}
                  onChange={(e) => {}}
                  style={{ padding: "1rem" }}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="tc" className="text-primary">
                  {"Telefon Numarası"}
                </label>
                <InputText
                  keyfilter="int"
                  maxLength={11}
                  placeholder={"Telefon Numarası"}
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "tc")
                  })}
                  value={phoneNumber}
                  disabled={true}
                  onChange={(e) => {}}
                  style={{ padding: "1rem" }}
                />
              </div>
              
              <div className="col-12 md:col-6">
                <label htmlFor="vergiNumarasi" className="text-primary">
                  Vergi Numarasi
                </label>
                <InputText
                  id="vergiNumarasi"
                  type="string"
                  placeholder="Vergi Numarasi"
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "vergiNumarasi")
                  })}
                  value={formik.values.vergiNumarasi}
                  onChange={(e) => formik.setFieldValue("vergiNumarasi", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "vergiNumarasi") && (
                  <div className="p-error mt-3">{formik.errors.vergiNumarasi}</div>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="vergiDairesi" className="text-primary">
                  Vergi Dairesi
                </label>
                <InputText
                  id="vergiDairesi"
                  type="string"
                  placeholder="Vergi Dairesi"
                  className={classNames("w-full  mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "vergiDairesi")
                  })}
                  value={formik.values.vergiDairesi}
                  onChange={(e) => formik.setFieldValue("vergiDairesi", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "vergiDairesi") && (
                  <div className="p-error mt-3">{formik.errors.vergiDairesi}</div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label className="text-primary" htmlFor="bagliBulunulanBaro">
                  Bağlı Bulunduğunuz Baro
                </label>
                <Dropdown
                  id="bagliBulunulanBaro"
                  name="bagliBulunulanBaro"
                  inputId="bagliBulunulanBaro"
                  filter
                  value={formik.values.bagliBulunulanBaro}
                  onChange={(e: DropdownChangeEvent) => {
                    formik.setFieldValue("bagliBulunulanBaro", e.value);
                  }}
                  options={DropdownDatas.getBaro()}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Bağlı Bulunduğunuz Baro"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "bagliBulunulanBaro")
                  })}
                />
                {FormikValueIsValid(formik, "bagliBulunulanBaro") && (
                  <div className="p-error mt-3">{formik.errors.bagliBulunulanBaro}</div>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="baroSicilNumarasi" className="text-primary">
                  Baro Sicil Numarası
                </label>
                <InputText
                  id="baroSicilNumarasi"
                  type="string"
                  placeholder="Baro Sicil Numarası"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "baroSicilNumarasi")
                  })}
                  value={formik.values.baroSicilNumarasi}
                  onChange={(e) => formik.setFieldValue("baroSicilNumarasi", e.target.value)}
                />
                {FormikValueIsValid(formik, "baroSicilNumarasi") && (
                  <div className="p-error mt-3">{formik.errors.baroSicilNumarasi}</div>
                )}
              </div>

              {/* <div className="col-12 md:col-6">
                <label htmlFor="icHatDahilisi" className="text-primary">
                  İç Hat Dahilisi
                </label>
                <InputText
                  id="icHatDahilisi"
                  type="string"
                  placeholder="İç Hat Dahilisi"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "icHatDahilisi"),
                  })}
                  value={formik.values.icHatDahilisi}
                  onChange={(e) =>
                    formik.setFieldValue("icHatDahilisi", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "icHatDahilisi") && (
                  <div className="p-error mt-3">
                    {formik.errors.icHatDahilisi}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label
                  htmlFor="garantiHtsKullaniciAdi"
                  className="text-primary"
                >
                  Garanti HTS Kullanıcı Adı
                </label>
                <InputText
                  id="garantiHtsKullaniciAdi"
                  type="string"
                  placeholder="Garanti HTS Kullanıcı Adı"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsKullaniciAdi"
                    ),
                  })}
                  value={formik.values.garantiHtsKullaniciAdi}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "garantiHtsKullaniciAdi",
                      e.target.value
                    )
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsKullaniciAdi") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsKullaniciAdi}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="garantiHtsBuroKodu" className="text-primary">
                  Garanti HTS Büro Kodu
                </label>
                <InputText
                  id="garantiHtsBuroKodu"
                  type="string"
                  placeholder="Garanti HTS Büro Kodu"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsBuroKodu"
                    ),
                  })}
                  value={formik.values.garantiHtsBuroKodu}
                  onChange={(e) =>
                    formik.setFieldValue("garantiHtsBuroKodu", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsBuroKodu") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsBuroKodu}
                  </div>
                )}
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="garantiHtsSifresi" className="text-primary">
                  Garanti HTS Şifresi
                </label>
                <InputText
                  id="garantiHtsSifresi"
                  type="string"
                  placeholder="Garanti HTS Şifresi"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(
                      formik,
                      "garantiHtsSifresi"
                    ),
                  })}
                  value={formik.values.garantiHtsSifresi}
                  onChange={(e) =>
                    formik.setFieldValue("garantiHtsSifresi", e.target.value)
                  }
                />
                {FormikValueIsValid(formik, "garantiHtsSifresi") && (
                  <div className="p-error mt-3">
                    {formik.errors.garantiHtsSifresi}
                  </div>
                )}
              </div> */}

              <div className="col-12">
                <label htmlFor="address" className="text-primary">
                  Adres
                </label>
                <InputTextarea
                  id="address"
                  placeholder="Adres"
                  className={classNames("w-full mt-2", {
                    "p-invalid": FormikValueIsValid(formik, "address")
                  })}
                  value={formik.values.address}
                  onChange={(e) => formik.setFieldValue("address", e.target.value)}
                  style={{ padding: "1rem" }}
                />
                {FormikValueIsValid(formik, "address") && <div className="p-error mt-3">{formik.errors.address}</div>}
              </div>

              <div className="col-12 mt-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="kvkkAcikRizaOnay"
                    checked={formik.values.kvkkAcikRizaOnay}
                    onChange={(e) => formik.setFieldValue("kvkkAcikRizaOnay", e.checked ?? false)}
                    className="mr-2"
                  ></Checkbox>
                  <label className="text-color" htmlFor="kvkkAcikRizaOnay">
                    <a target="_blank" href="https://www.pratikportal.com/Home/AcikRizaMetni">
                      Pratik Portal Yazılımı Kişisel Verilerin İşlenmesine İlişkin Açık Rıza Metni
                    </a>{" "}
                    kapsamında verilerimin işlenmesine onay veriyorum.
                  </label>
                </div>
                {FormikValueIsValid(formik, "kvkkAcikRizaOnay") && (
                  <div className="p-error mt-3">{formik.errors.kvkkAcikRizaOnay}</div>
                )}
              </div>

              <div className="col-12">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="iysEmailAcikRizaOnay"
                    checked={formik.values.iysEmailAcikRizaOnay}
                    onChange={(e) => formik.setFieldValue("iysEmailAcikRizaOnay", e.checked ?? false)}
                    className="mr-2"
                  ></Checkbox>
                  <div>
                    <label className="text-color" htmlFor="iysEmailAcikRizaOnay">
                      <a target="_blank" href="https://www.pratikportal.com/Home/IysAydinlatmaMetni">
                        Ticari Elektronik İleti Aydınlatma ve Onay Metni
                      </a>{" "}
                      kapsamında tarafıma <strong>E-posta</strong> gönderilmesine onay veriyorum.
                    </label>
                  </div>
                </div>

                <div className="flex align-items-center mt-3">
                  <Checkbox
                    inputId="iysSmsAcikRizaOnay"
                    checked={formik.values.iysSmsAcikRizaOnay}
                    onChange={(e) => formik.setFieldValue("iysSmsAcikRizaOnay", e.checked ?? false)}
                    className="mr-2"
                  ></Checkbox>
                  <div>
                    <label className="text-color" htmlFor="iysSmsAcikRizaOnay">
                      <a target="_blank" href="https://www.pratikportal.com/Home/IysAydinlatmaMetni">
                        Ticari Elektronik İleti Aydınlatma ve Onay Metni
                      </a>{" "}
                      kapsamında tarafıma <strong>SMS</strong> gönderilmesine onay veriyorum.
                    </label>
                  </div>
                </div>

                <div className="flex align-items-center mt-3">
                  <Checkbox
                    inputId="iysPhoneAcikRizaOnay"
                    checked={formik.values.iysPhoneAcikRizaOnay}
                    onChange={(e) => formik.setFieldValue("iysPhoneAcikRizaOnay", e.checked ?? false)}
                    className="mr-2"
                  ></Checkbox>
                  <div>
                    <label className="text-color" htmlFor="iysPhoneAcikRizaOnay">
                      <a target="_blank" href="https://www.pratikportal.com/Home/IysAydinlatmaMetni">
                        Ticari Elektronik İleti Aydınlatma ve Onay Metni
                      </a>{" "}
                      kapsamında tarafıma <strong>Arama</strong> yapılmasına onay veriyorum.
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <Button label="Güncelle" className="w-full text-xl mt-2" type="submit"></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProfilePage;
