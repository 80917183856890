import React, { useContext } from "react";
import { LayoutContext } from "./context/layoutcontext";

const AppFooter = () => {
  const { layoutConfig } = useContext(LayoutContext);

  return (
    <span className="layout-footer">
      <a className="inline" href="https://www.pitechkurumsal.com/" target="_blank">
        <img
          src={`/images/${layoutConfig.colorScheme === "light" ? "pi-tech-logo" : "pi-tech-logo"}.png`}
          alt="Logo"
          height="30"
          className="mr-2"
        />
        <span className="font-medium ml-2 text-primary">Pi Tech Kurumsal Çözümler A.Ş.</span>
      </a>
    </span>
  );
};

export default AppFooter;
