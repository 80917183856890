import React, { createContext, useContext, useState, useEffect, useRef, ReactNode } from "react";
import { useToast } from "./toast";
import {
  DownloadEvrakRequestDto,
  ElecronEvrakResponseDto
} from "../pages/dosya-detaylari/components/evrak-component/evrak.models.dto";
import moment from "moment";

interface EvrakData {
  evrakId: string;
  dosyaId: string;
  onaylandigiTarih: string;
  tur: string;
  anaEvrakId?: string;
  sira?: string;
}

interface VatandasPortalContextType {
  startDownload: (
    evrakArray: EvrakData[],
    dosyaId: string,
    dosyaNo: string,
    birimAdi: string,
    dosyaTur: string
  ) => void;
  downloaded: number;
  total: number;
  stopDownload: () => void;
}

const VatandasPortalContext = createContext<VatandasPortalContextType | undefined>(undefined);

export const VatandasPortalProvider = ({ children }: { children: ReactNode }) => {
  const [downloaded, setDownloaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [evrakArray, setEvrakArray] = useState<{
    evraklar: EvrakData[];
    dosyaId: string;
    dosyaNo: string;
    birimAdi: string;
    dosyaTur: string;
  }>();
  const downloading = useRef(false);
  // const [downloading, setDownloading] = useState(false);
  const toast = useToast();
  const abortControllerRef = useRef<AbortController | null>(null);

  const startDownload = (
    evrakArray: EvrakData[],
    dosyaId: string,
    dosyaNo: string,
    birimAdi: string,
    dosyaTur: string
  ) => {
    setTotal(evrakArray.length);
    setDownloaded(0);
    downloading.current = true;
    setEvrakArray({ evraklar: evrakArray, dosyaId, dosyaNo, birimAdi, dosyaTur });
    abortControllerRef.current = new AbortController();
  };

  const stopDownload = () => {
    downloading.current = false;
    setTotal(0);
    setDownloaded(0);
    setEvrakArray(undefined);
    abortControllerRef.current?.abort();
  };

  useEffect(() => {
    if (!evrakArray?.evraklar || evrakArray?.evraklar.length === 0) return;

    const downloadFiles = async () => {
      let successfulDownloads = 0;

      try {
        const cookies = await window.electron.ipcRenderer.invoke("vatandas-portal-get-cookies", {});
        if (!cookies) {
          toast.show("Vatandaş oturumu alınamadı.", "error");
          return;
        }
        for (const evrak of evrakArray?.evraklar) {
          if (!downloading.current) break;

          const response = await window.electron.ipcRenderer.invoke("vatandasportal-dosya-indir", {
            evrak: evrak,
            cookies: cookies
          });

          if (!response?.isSuccessful) {
            toast.show(`İndirme başarısız: ${evrak.evrakId}`, "error");
            continue;
          }

          const fileMimeType = response.result.mimeType;
          const fileBlob = new Blob([response.result.file], { type: "application/download" });

          await sendDownloadEvrakMessageToElectron({
            evrakId: evrak.evrakId,
            birimAdi: evrakArray?.birimAdi,
            dosyaNo: evrakArray?.dosyaNo,
            dosyaTur: evrakArray?.dosyaTur,
            file: await (fileBlob as Blob).arrayBuffer(),
            fileExtension: availableMimeTypes[fileMimeType as string],
            onaylandigiTarihNormal: evrak?.onaylandigiTarih.replaceAll("/", "-"),
            onaylandigiTarihYilOnce: moment(evrak?.onaylandigiTarih, "DD/MM/YYYY").format("YYYY.MM.DD"),
            tur: evrak?.tur,
            anaEvrakId: evrak?.anaEvrakId || undefined,
            ekEvrakName: evrak?.sira ? `Ek ${evrak?.sira}` : undefined
          });
          successfulDownloads += 1;
          setDownloaded((prev) => prev + 1);

          await delay(1000);
        }
      } catch (error: any) {
        console.error(error);
        toast.show("İndirme işlemi iptal edildi.", "error");
        abortControllerRef.current = null;
      } finally {
        downloading.current = false;
        setTotal(0);
        setDownloaded(0);
        setEvrakArray(undefined);
      }

      if (successfulDownloads === total) {
        toast.show("Toplu evrak indirme işlemi tamamlandı.", "success");
      }
    };

    downloadFiles();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, [evrakArray, downloading]);

  const sendDownloadEvrakMessageToElectron = async (downloadEvrakRequestDto: DownloadEvrakRequestDto) => {
    const response = await window.electron?.ipcRenderer?.invoke("download-evrak", downloadEvrakRequestDto);
    console.log(response);
    const res: ElecronEvrakResponseDto<string> = response;

    if (!res.isSuccessful) {
      toast.show(res.errorMessage, "error");
      return;
    }
  };

  return (
    <VatandasPortalContext.Provider value={{ startDownload, downloaded, total, stopDownload }}>
      {children}
    </VatandasPortalContext.Provider>
  );
};

export const useVatandasPortal = (): VatandasPortalContextType => {
  const context = useContext(VatandasPortalContext);
  if (!context) {
    throw new Error("useVatandasPortal must be used within a VatandasPortalProvider");
  }
  return context;
};

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const availableMimeTypes: { [key: string]: string } = {
  "text/html": "html",
  "image/jpeg": "jpg",
  "image/png": "png",
  "application/pdf": "pdf",
  "text/udf": "udf",
  "application/jasperreport": "pdf",
  "image/tiff": "tiff",
  "application/stylereport": "pdf",
  "application/pkcs7-mime": "zip",
  "application/eyp": "zip"
};
