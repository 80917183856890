import React, { useContext, useEffect, useState } from "react";
import { DosyaDTO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import SorguBilgileri from "./SorguBilgileri";
import { useQuery } from "@tanstack/react-query";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useHub } from "../../../../utils/buro-hub";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const Mersis = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: MersisResponse,
    refetch: refetchMersis,
    isLoading: mersisResponseLoading
  } = useQuery({
    queryKey: ["Mersis", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetMersisBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(mersisResponseLoading)
  }, [mersisResponseLoading])

  return (
    <div>
      {MersisResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {MersisResponse.error}</div>}

      {MersisResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={MersisResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={MersisResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={MersisResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={MersisResponse?.result?.sorgulayanBilgileri}
          />

          {MersisResponse?.result?.sorguSonucu === true ? (
            <>
              <div className="card p-2 lg:p-5 mt-3 mb-3">Kuruma ait MERSİS kayıtlarına göre adres kaydı var.</div>

              <div className="card p-2 lg:p-5" style={{ display: "flex", alignItems: "center" }}>
                <div className="font-bold"> ADRES: </div>
                <div>{MersisResponse?.result?.sorguSonucDVO?.toString()}</div>
              </div>
            </>
          ) : (
            <div className="card p-2 lg:p-5 mt-3">Kuruma ait MERSİS kayıtlarına göre adres kaydı bulunamadı.</div>
          )}

        </div>
      )}
    </div>
  );
};

export default Mersis;
