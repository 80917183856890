import { FormikErrors, useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../utils/FormikValueIsValid";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { UpdateTelephoneDto } from "../services/telephone/telephone.dto";
import { InputMask } from "primereact/inputmask";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../utils/DropdownValues";
import { useState } from "react";

const now: Date = new Date();
const UpsertTelephone = (props: any) => {
  const telephone: UpdateTelephoneDto = props.telephone;
  const onUpsert = props.onUpsert;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const [yabanciNumara, setYabanciNumara] = useState<boolean>(false);

  let updateTelephoneDto: UpdateTelephoneDto;

  if (telephone === null || telephone === undefined) {
    updateTelephoneDto = {
      id: 0,
      numara: "",
      sahibi: "",
      aciklama: "",
      iletisimOnayiVarMi: false,
      creationTime: now,
    };
  } else {
    updateTelephoneDto = { ...telephone, creationTime: now };
  }

  const formik = useFormik<UpdateTelephoneDto>({
    initialValues: updateTelephoneDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateTelephoneDto> = {};
      if (!data.numara) {
        errors.numara = "Numara alanı boş bırakılamaz";
      }
      if (!data.sahibi) {
        errors.sahibi = "Sahibi alanı boş bırakılamaz";
      }
      if (
        data.iletisimOnayiVarMi === undefined ||
        data.iletisimOnayiVarMi === null
      ) {
        errors.iletisimOnayiVarMi =
          "İletişim Onayı Var Mı alanı boş bırakılamaz";
      }

      return errors;
    },
    onSubmit: () => {
      let formikValues = formik.values;
      formikValues.numara = formikValues.numara
        .replace(/_/g, "")
        .replace(/ /g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/-/g, "");
      onUpsert(formik.values);
      setVisible(false);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={formik.values.id === 0 ? "Telefon Ekle" : "Telefon Güncelle"}
      onHide={() => {
        setVisible(false);
        formik.resetForm();
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
            }}
          />
          <Button
            label={"Ekle"}
            className="md:px-6 justify-content-center"
            onClick={() => formik.submitForm()}
          />
        </div>
      }
      className=""
      style={{ width: "50vw" }}
    >
      <div className="grid mt-1">
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tip">
            Numara Yabancı mı?
          </label>
          <div className="flex flex-wrap gap-5 mt-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="yabanciNumaraYes"
                name="yabanciNumara"
                value={true}
                onChange={(e: any) => {
                  setYabanciNumara(true);
                  formik.setFieldValue("numara", "");
                }}
                checked={yabanciNumara === true}
              />
              <label className="text-primary ml-2" htmlFor="yabanciNumaraYes">
                Evet
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="yabanciNumaraNo"
                name="yabanciNumara"
                value={false}
                onChange={(e: any) => {
                  setYabanciNumara(false);
                  formik.setFieldValue("numara", "");
                }}
                checked={yabanciNumara === false}
              />
              <label className="text-primary ml-2" htmlFor="yabanciNumaraNo">
                Hayır
              </label>
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="numara">
            Numara
          </label>
          <InputMask
            id="numara"
            name="numara"
            keyfilter="int"
            mask={
              yabanciNumara === true
                ? "?999999999999999999999999"
                : "(0999) 999-99-99"
            }
            // placeholder="Numara Giriniz."
            value={formik.values.numara}
            aria-labelledby="phone"
            onChange={(e) => {
              formik.setFieldValue("numara", e.target.value);
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "numara"),
            })}
          />
          {FormikValueIsValid(formik, "numara") && (
            <div className="p-error mt-3">{formik.errors.numara}</div>
          )}
        </div>
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="sahibi">
            Sahibi
          </label>
          <Dropdown
            id="sahibi"
            name="sahibi"
            value={formik.values.sahibi}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("sahibi", e.value);
            }}
            options={DropdownDatas.GetTelefonSahibi()}
            optionLabel="label"
            optionValue="value"
            placeholder="Sahibi Seçiniz."
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "sahibi"),
            })}
          />
          {FormikValueIsValid(formik, "sahibi") && (
            <div className="p-error mt-3">{formik.errors.sahibi}</div>
          )}
        </div>
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="aciklama">
            Açıklama
          </label>
          <InputText
            id="aciklama"
            name="aciklama"
            value={formik.values.aciklama}
            onChange={(e) => {
              formik.setFieldValue(
                "aciklama",
                e.target.value.toLocaleUpperCase("tr")
              );
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "aciklama"),
            })}
          />
          {FormikValueIsValid(formik, "aciklama") && (
            <div className="p-error mt-3">{formik.errors.aciklama}</div>
          )}
        </div>
        <div className="col-12 lg:col-6">
          <label className="text-primary" htmlFor="tip">
            İletişim Onayı Var Mı?
          </label>
          <div className="flex flex-wrap gap-5 mt-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="iletisimOnayiVarMiYes"
                name="iletisimOnayiVarMi"
                value={true}
                onChange={(e) =>
                  formik.setFieldValue("iletisimOnayiVarMi", e.value)
                }
                checked={formik.values.iletisimOnayiVarMi === true}
              />
              <label
                className="text-primary ml-2"
                htmlFor="iletisimOnayiVarMiYes"
              >
                Evet
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="iletisimOnayiVarMiNo"
                name="iletisimOnayiVarMi"
                value={false}
                onChange={(e) =>
                  formik.setFieldValue("iletisimOnayiVarMi", e.value)
                }
                checked={formik.values.iletisimOnayiVarMi === false}
              />
              <label
                className="text-primary ml-2"
                htmlFor="iletisimOnayiVarMiNo"
              >
                Hayır
              </label>
            </div>
          </div>
          {FormikValueIsValid(formik, "iletisimOnayiVarMi") && (
            <div className="p-error mt-3">
              {formik.errors.iletisimOnayiVarMi}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertTelephone;
