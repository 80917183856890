import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import TalimatDosyasiService from "../../../../services/talimat-dosyasi/talimat-dosyasi.service";
import { TalimatDosyasiDto } from "../../../../services/talimat-dosyasi/talimat-dosyasi.dto";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import UpsertTalimatDosyasi from "./UpsertTalimatDosyasi";
import { Calendar } from "primereact/calendar";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const TalimatDosyasi = (props: any) => {
  const [talimatDosyasi, setTalimatDosyasi] = useState<TalimatDosyasiDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TalimatDosyasiDto[]>>(null);
  const [visibleUpsertTalimatDosyasiDialog, setVisibleUpsertTalimatDosyasiDialog] = useState<boolean>(false);
  const toast = useToast();
  const { setBlocked } = useContext(LayoutContext)
  const icraFoy: IcraFoyDto = props.icraFoy;

  const {
    data: talimatDosyasiResponse,
    refetch: refetchTalimatDosyasi,
    isLoading: talimatDosyasiResponseIsLoading
  } = useQuery({
    queryKey: ["TalimatDosyasi"],
    refetchOnMount: true,
    queryFn: () => TalimatDosyasiService.GetAllByIcraFoy(icraFoy.id)
  });

  useEffect(() => {
    setBlocked(talimatDosyasiResponseIsLoading)
    if (!talimatDosyasiResponseIsLoading) {
      talimatDosyasiResponse?.result?.data.forEach((p) => {
        p.borclularNames = p.borclular.map((p) => p.adSoyad).join(", ");
      });
    }
  }, [talimatDosyasiResponseIsLoading])

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Talimat Dosyası"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertTalimatDosyasiDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: TalimatDosyasiDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          tooltip="Düzenle"
          onClick={() => {
            setTalimatDosyasi(rowData);
            setVisibleUpsertTalimatDosyasiDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          tooltip="Sil"
          onClick={() => {
            deleteTalimatDosyasiConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      borclularNames: commonFilterContains,
      icraMudurlugu: commonFilterContains,
      dosyaNo: commonFilterContains,
      aciklama: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const deleteTalimatDosyasi = (rowData: TalimatDosyasiDto) => {
    setBlocked(true)
    TalimatDosyasiService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Talimat Dosyası başarıyla silindi.", "success");
        refetchTalimatDosyasi();
      }
    });
  };

  const deleteTalimatDosyasiConfirm = (rowData: TalimatDosyasiDto) => {
    confirmDialog({
      message: "Talimat Dosyasını silmek istediğinize emin misiniz?",
      header: "Talimat Dosyası Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteTalimatDosyasi(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const exportExcel = () => {
    const data = talimatDosyasiResponse?.result?.data || [];

    const headers = ["Borçlular", "İcra Müdürlüğü", "Dosya Numarası", "Açıklama"];

    const filteredData = data.map((data) => ({
      borclularNames: data.borclularNames,
      icraMudurlugu: data.icraMudurlugu,
      dosyaNo: data.dosyaNo,
      aciklama: data.aciklama
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TalimatDosyası");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={talimatDosyasiResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} talimat dosyası"
          filters={filters}
          // filterDisplay="row"
          loading={talimatDosyasiResponseIsLoading}
          globalFilterFields={["borclularNames", "icraMudurlugu", "aciklama", "icraNo", "dosyaNo"]}
          emptyMessage="Talimat dosyası bulunamadı."
          header={CreateHeader(
            "Talimat Dosyaları",
            globalFilterValue,
            (e) => onGlobalFilterChange(e),
            clearFilter
          )}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
          <Column
            field="borclularNames"
            header="Borçlular"
            sortable
            filterField="borclularNames"
            filter
            filterPlaceholder="Borçlulara göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Borçlulara göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="icraMudurlugu"
            header="İcra Müdürlüğü"
            sortable
            filterField="icraMudurlugu"
            filter
            filterPlaceholder="İcra Müdürlüğüne göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İcra Müdürlüğüne göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="dosyaNo"
            header="Dosya Numarası"
            sortable
            filterField="dosyaNo"
            filter
            filterPlaceholder="Dosya Numarasına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Dosya Numarasına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="aciklama"
            header="Açıklama"
            sortable
            filterField="aciklama"
            filter
            filterPlaceholder="Açıklamaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Açıklamaya göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertTalimatDosyasi
        visible={visibleUpsertTalimatDosyasiDialog}
        setVisible={setVisibleUpsertTalimatDosyasiDialog}
        talimatDosyasi={talimatDosyasi}
        setTalimatDosyasi={setTalimatDosyasi}
        refetchTalimatDosyasi={refetchTalimatDosyasi}
        icraFoy={icraFoy}
      />
    </div>
  );
};

export default TalimatDosyasi;
