import { locale } from "moment";
import { AddTrLocaleforPrimeReact } from "../lib/AddLocaleTranslationforPrime";
import i18n from "../lib/i18n";
import { LayoutProvider } from "./context/layoutcontext";
import { PrimeReactProvider } from "primereact/api";

interface RootLayoutProps {
  children: React.ReactNode;
}



export default function RootLayout({ children }: RootLayoutProps) {
  i18n.changeLanguage("tr");
  return (
    <PrimeReactProvider>
      <LayoutProvider>{children}</LayoutProvider>
    </PrimeReactProvider>
  );
}
