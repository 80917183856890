import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { UyapDosyaDto, CreateUyapDosyaDto, UpdateUyapDosyaDto } from "./uyap-dosya.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<UyapDosyaDto[]>(`UyapDosya`, config);
  },
  Create(dto: CreateUyapDosyaDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<UyapDosyaDto>("UyapDosya", dto, config);
  },
  Update(dto: UpdateUyapDosyaDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<UyapDosyaDto>("UyapDosya", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`UyapDosya/${id}`, config);
  }
};

export default services;
