import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<File>(`File/${id}`, config);
  },
};

export default services;
