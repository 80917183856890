import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import BelgeArsiviService from "../../../../services/belge-arsivi/belge-arsivi.services";
import FileService from "../../../../services/file/file.services";
import { useQuery } from "@tanstack/react-query";
import UpsertBelgeArsivi from "./UpsertBelgeArsivi";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import DropdownDatas from "../../../../utils/DropdownValues";
import { BelgeArsiviDto } from "../../../../services/belge-arsivi/belge-arsivi.dto";
import { useNavigate } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const BelgeArsivi = (props: any) => {
  const [belgeArsivi, setBelgeArsivi] = useState<BelgeArsiviDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<BelgeArsiviDto[]>>(null);
  const [visibleUpsertBelgeArsiviDialog, setVisibleUpsertBelgeArsiviDialog] = useState<boolean>(false);
  const toast = useToast();
  const icraFoy: IcraFoyDto = props.icraFoy;
  const navigate = useNavigate();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: belgeArsiviResponse,
    refetch: refetchBelgeArsivi,
    isLoading: belgeArsiviResponseIsLoading
  } = useQuery({
    queryKey: ["BelgeArsivi"],
    refetchOnMount: true,
    queryFn: () => BelgeArsiviService.GetAllByIcraFoyId(icraFoy.id)
  });

  useEffect(() => {
    setBlocked(belgeArsiviResponseIsLoading)
  }, [belgeArsiviResponseIsLoading])

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Belge"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertBelgeArsiviDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: BelgeArsiviDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-download"
          rounded
          outlined
          className="mr-2"
          severity="success"
          tooltip="Dosyayı İndir"
          onClick={() => {
            FileService.Get(rowData.fileId!).then((res) => {
              window.open(process.env.REACT_APP_BASE_URL + "api/" + "File/" + rowData.fileId!, "_blank");
            });
          }}
        />
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setBelgeArsivi(rowData);
            setVisibleUpsertBelgeArsiviDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            deleteMuvekkilConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      creationTime: commonFilterDateIs,
      tip: commonFilterContains,
      aciklama: commonFilterContains,
      creationUsername: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const deleteTalepBilgisi = (rowData: BelgeArsiviDto) => {
    setBlocked(true)
    BelgeArsiviService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Belge başarıyla silindi.", "success");
        refetchBelgeArsivi();
      }
    });
  };

  const deleteMuvekkilConfirm = (rowData: BelgeArsiviDto) => {
    confirmDialog({
      message: "Belgeyi silmek istediğinize emin misiniz?",
      header: "Belge Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteTalepBilgisi(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const exportExcel = () => {
    const data = belgeArsiviResponse?.result?.data || [];

    const headers = ["Eklenme Tarihi", "Belge Tipi", "Açıklama", "Kullanıcı"];

    const filteredData = data.map((data) => ({
      creationTime: new Date(data.creationTime).toLocaleDateString(),
      tip: data.tip,
      aciklama: data.aciklama,
      creationUsername: data.creationUsername
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "BelgeArsivi");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={belgeArsiviResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} talep bilgisi"
          filters={filters}
          // filterDisplay="row"
          loading={belgeArsiviResponseIsLoading}
          globalFilterFields={["creationTime", "tip", "aciklama", "creationUsername"]}
          emptyMessage="Talep Bilgisi bulunamadı."
          header={CreateHeader("Belge Arşivi", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9em" }}></Column>
          <Column
            field="creationTime"
            header="Eklenme Tarihi"
            sortable
            body={(rowData: BelgeArsiviDto) => {
              return <span>{new Date(rowData.creationTime).toLocaleDateString()}</span>;
            }}
            filterField="creationTime"
            filter
            filterPlaceholder="Kayıt Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Kayıt Tarihine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="tip"
            header="Belge Tipi"
            sortable
            filterField="tip"
            filter
            filterPlaceholder="Tip'e göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Tip'e göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="aciklama"
            header="Açıklama"
            sortable
            filterField="aciklama"
            filter
            filterPlaceholder="Açıklamaya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Açıklamaya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="creationUsername"
            header="Kullanıcı"
            sortable
            filterField="creationUsername"
            filter
            filterPlaceholder="Kullanıcı'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Kullanıcı'ya göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertBelgeArsivi
        visible={visibleUpsertBelgeArsiviDialog}
        setVisible={setVisibleUpsertBelgeArsiviDialog}
        belgeArsivi={belgeArsivi}
        setBelgeArsivi={setBelgeArsivi}
        refetchBelgeArsivi={refetchBelgeArsivi}
        icraFoy={icraFoy}
      />
    </div>
  );
};

export default BelgeArsivi;
