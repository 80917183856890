import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { KlasorDto, CreateKlasorDto, UpdateKlasorDto } from "./klasor.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<KlasorDto>(`Klasor/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<KlasorDto[]>(`Klasor`, config);
  },
  Create(dto: CreateKlasorDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<KlasorDto>("Klasor", dto, config);
  },
  Update(dto: UpdateKlasorDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<KlasorDto>("Klasor", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`Klasor/${id}`, config);
  },
};

export default services;
