import { Link, useNavigate, useSearchParams } from "react-router-dom";
import accountService from "../../services/account/account.service";
import { ResetPasswordDto } from "../../services/account/account.dto";
import { useToast } from "../../utils/toast";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { Button } from "primereact/button";
import { useContext } from "react";
import { LayoutContext } from "../../layout/context/layoutcontext";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useToast();

  const { setBlocked } = useContext(LayoutContext);

  const id = searchParams.get("id") || "";
  const token = searchParams.get("token") || "";

  if (!token || !id) {
    navigate("/login");
  }

  const formik = useFormik<ResetPasswordDto>({
    initialValues: {
      userId: id,
      token: token,
      newPassword: "",
      newPasswordConfirm: ""
    },
    enableReinitialize: true,
    validate: (data) => {
      const errors: any = {};

      if (!data.newPassword) {
        errors.newPassword = "Parola zorunludur";
      }

      if (!data.newPasswordConfirm) {
        errors.newPasswordConfirm = "Parola tekrarı zorunludur";
      }

      if (data.newPassword && data.newPasswordConfirm && data.newPassword !== data.newPasswordConfirm) {
        errors.newPasswordConfirm = "Parolalar eşleşmiyor";
      }

      return errors;
    },
    onSubmit: (values) => {
      setBlocked(true);
      accountService
        .ResetPassword(values)
        .then((res) => {
          if (res.result && !res.result.error) {
            toast.show("Parolanız başarıyla güncellendi.", "success");
            setBlocked(false);
            navigate("/login", { replace: true });
          }
        })
        .finally(() => setBlocked(false));
    }
  });

  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  );

  return (
    <form className={containerClassName} onSubmit={formik.handleSubmit}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
          }}
        >
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
            <div className="text-center mb-5">
              <div>
                <img src="/images/logo.png" alt="Image" height="70" className="mb-3" />
              </div>
              <div>
                <span className="text-600 font-medium">{"E-posta adresinizi giriniz."}</span>
              </div>
            </div>
            <div>
              <label htmlFor="newPassword" className="text-primary">
                Parola
              </label>
              <Password
                inputId="newPassword"
                value={formik.values.newPassword}
                onChange={(e) => formik.setFieldValue("newPassword", e.target.value)}
                placeholder="Parola"
                toggleMask
                className={"w-full mt-2 login-password block"}
                autoComplete="new-password"
                inputClassName={classNames("block w-full p-3", {
                  "p-invalid": FormikValueIsValid(formik, "newPassword")
                })}
              ></Password>
              {FormikValueIsValid(formik, "newPassword") && (
                <div className="p-error mt-3">{formik.errors.newPassword}</div>
              )}
            </div>

            <div className="mt-5">
              <label htmlFor="newPasswordConfirm" className="text-primary">
                Parola Tekrar
              </label>
              <Password
                inputId="newPasswordConfirm"
                value={formik.values.newPasswordConfirm}
                onChange={(e) => formik.setFieldValue("newPasswordConfirm", e.target.value)}
                placeholder="Parola Tekrar"
                toggleMask
                className={"w-full mt-2 login-password block"}
                autoComplete="new-password"
                inputClassName={classNames("block w-full p-3", {
                  "p-invalid": FormikValueIsValid(formik, "newPasswordConfirm")
                })}
              ></Password>
              {FormikValueIsValid(formik, "newPasswordConfirm") && (
                <div className="p-error mt-3">{formik.errors.newPasswordConfirm}</div>
              )}
            </div>
            <div>
              <Button label={"Parolamı Değiştir"} className="w-full p-3 text-xl mt-5" type="submit"></Button>

              <div className="mt-3 text-center">
                <Link
                  to="/login"
                  className="font-medium no-underline ml-2 text-right cursor-pointer"
                  style={{ color: "var(--primary-color)" }}
                >
                  Giriş Yap
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
