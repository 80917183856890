import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UrunBilgisiService from "../../../../services/urun-bilgisi/urun-bilgisi.service";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import { UrunBilgisiDto } from "../../../../services/urun-bilgisi/urun-bilgisi.dto";
import UpsertUrunBilgisi from "./UpsertUrunBilgisi";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const UrunBilgisi = (props: any) => {
  const [urunBilgisi, setUrunBilgisi] = useState<UrunBilgisiDto>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<UrunBilgisiDto[]>>(null);
  const [visibleUpsertUrunBilgisiDialog, setVisibleUpsertUrunBilgisiDialog] = useState<boolean>(false);
  const toast = useToast();
  const icraFoy: IcraFoyDto = props.icraFoy;
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: urunBilgisiResponse,
    refetch: refetchUrunBilgisi,
    isLoading: urunBilgisiResponseIsLoading
  } = useQuery({
    queryKey: ["UrunBilgisi"],
    refetchOnMount: true,
    queryFn: () => UrunBilgisiService.GetAllByIcraFoyId(icraFoy.id)
  });

  useEffect(() => {
    setBlocked(urunBilgisiResponseIsLoading)
  }, [urunBilgisiResponseIsLoading])

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Ürün Bilgisi"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleUpsertUrunBilgisiDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: UrunBilgisiDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setUrunBilgisi(rowData);
            setVisibleUpsertUrunBilgisiDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            deleteUrunBilgisiConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      no: commonFilterContains,
      tip: commonFilterContains,
      subeNo: commonFilterContains,
      subeAdi: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const deleteUrunBilgisi = (rowData: UrunBilgisiDto) => {
    setBlocked(true)
    UrunBilgisiService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Ürün Bilgisi başarıyla silindi.", "success");
        refetchUrunBilgisi();
      }
    });
  };

  const deleteUrunBilgisiConfirm = (rowData: UrunBilgisiDto) => {
    confirmDialog({
      message: "Ürün Bilgisini silmek istediğinize emin misiniz?",
      header: "Ürün Bilgisi Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteUrunBilgisi(rowData),
      reject: () => { },
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const exportExcel = () => {
    const data = urunBilgisiResponse?.result?.data || [];

    const headers = ["Ürün No", "Ürün Tipi", "Şube No", "Şube Adı"];

    const filteredData = data.map((data) => ({
      no: data.no,
      tip: data.tip,
      subeNo: data.subeNo,
      subeAdi: data.subeAdi
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "UrunBilgisi");
  };

  return (
    <div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={urunBilgisiResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} ürün Bilgisi"
          filters={filters}
          // filterDisplay="row"
          loading={urunBilgisiResponseIsLoading}
          globalFilterFields={["no", "tip", "subeNo", "subeAdi"]}
          emptyMessage="Ürün Bilgisi bulunamadı."
          header={CreateHeader(
            "Ürün Bilgileri",
            globalFilterValue,
            (e) => onGlobalFilterChange(e),
            clearFilter
          )}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "6rem" }}></Column>
          <Column
            field="no"
            header="Ürün No"
            sortable
            filterField="no"
            filter
            filterPlaceholder="Ürün No'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Ürün No'ya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="tip"
            header="Ürün Tipi"
            sortable
            filterField="tip"
            filter
            filterPlaceholder="Ürün Tip'ine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Ürün Tip'ine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="subeNo"
            header="Şube No"
            sortable
            filterField="subeNo"
            filter
            filterPlaceholder="Şube No'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Şube No'ya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="subeAdi"
            header="Şube Adı"
            sortable
            filterField="subeAdi"
            filter
            filterPlaceholder="Şube Ad'ına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Şube Ad'ına göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      <UpsertUrunBilgisi
        visible={visibleUpsertUrunBilgisiDialog}
        setVisible={setVisibleUpsertUrunBilgisiDialog}
        urunBilgisi={urunBilgisi}
        setUrunBilgisi={setUrunBilgisi}
        refetchUrunBilgisi={refetchUrunBilgisi}
        icraFoy={icraFoy}
      />
    </div>
  );
};

export default UrunBilgisi;
