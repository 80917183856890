import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguGibModel, TopluSorguIsyeriModel } from "../TopluSorgu.dto";
import { commonFilterContains, CreateHeader, Filter } from "../../filter/base-filter";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ExportFile } from "../../../utils/export-file";

const IsYeriDataTable = (props: { tableData: TopluSorguIsyeriModel[] }) => {
  const isYeriVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguGibModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      eskiSubeKodu: commonFilterContains,
      il: commonFilterContains,
      isYeriAciklama: commonFilterContains,
      isYeriAdres: commonFilterContains,
      isYeriMerkezAdresi: commonFilterContains,
      isYeriSicil: commonFilterContains,
      isYeriUnvani: commonFilterContains,
      //referansNo: commonFilterContains,
      //tescilTip: commonFilterContains,
      yeniSubeKodu: commonFilterContains,
      siraNo: commonFilterContains,
      adres: commonFilterContains,
      mahiyet: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Eski Şube Kodu",
      "İl",
      "İş Yeri Açıklama",
      "İş Yeri Adres",
      "İş Yeri Merkez Adresi",
      "İş Yeri Sicil",
      "İş Yeri Ünvanı",
      //"Referans No",
      //"Tescil Tip",
      "Yeni Şube Kodu",
      "Sıra No",
      "Adres",
      "Mahiyet"
    ];

    const filteredData = isYeriVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      eskiSubeKodu: data.eskiSubeKodu,
      il: data.il,
      isYeriAciklama: data.isYeriAciklama,
      isYeriAdres: data.isYeriAdres,
      isYeriMerkezAdresi: data.isYeriMerkezAdresi,
      isYeriSicil: data.isYeriSicil,
      isYeriUnvani: data.isYeriUnvani,
      //referansNo: data.referansNo,
      //tescilTip: data.tescilTip,
      yeniSubeKodu: data.yeniSubeKodu,
      siraNo: data.siraNo,
      adres: data.adres,
      mahiyet: data.mahiyet
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluSorguIsYeri");
  };

  return (
    <div>
      <DataTable
        value={isYeriVeri.map((item, index) => ({ ...item, key: index }))}
        ref={dt}
        dataKey="key"
        showGridlines
        emptyMessage="İş Yeri Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Sgk İş Yeri Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "eskiSubeKodu",
          "il",
          "isYeriAciklama",
          "isYeriAdres",
          "isYeriMerkezAdresi",
          "isYeriSicil",
          "isYeriUnvani",
          //"referansNo",
          //"tescilTip",
          "yeniSubeKodu",
          "siraNo",
          "adres",
          "mahiyet"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filter
          filterField="ortakDegerler.dosyaNo"
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filter
          filterField="ortakDegerler.alacakliAdi"
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filter
          filterField="ortakDegerler.borcluAdi"
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filter
          filterField="ortakDegerler.borcluTCVKNo"
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filter
          filterField="ortakDegerler.dosyaDurumu"
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Eski Şube Kodu"
          field="eskiSubeKodu"
          sortable
          filter
          filterField="eskiSubeKodu"
          filterPlaceholder="Eski Şube Kodu'na göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Eski Şube Kodu'na göre filtreleyin</div>}
        />
        <Column
          header="İl"
          field="il"
          sortable
          filter
          filterField="il"
          filterPlaceholder="İl'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İl'e göre filtreleyin</div>}
        />
        <Column
          header="İş Yeri Açıklama"
          field="isYeriAciklama"
          sortable
          filter
          filterField="isYeriAciklama"
          filterPlaceholder="İş Yeri Açıklama'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İş Yeri Açıklama'ya göre filtreleyin</div>}
        />
        <Column
          header="İş Yeri Adres"
          field="isYeriAdres"
          sortable
          filter
          filterField="isYeriAdres"
          filterPlaceholder="İş Yeri Adres'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İş Yeri Adres'e göre filtreleyin</div>}
        />
        <Column
          header="İş Yeri Merkez Adresi"
          field="isYeriMerkezAdresi"
          sortable
          filter
          filterField="isYeriMerkezAdresi"
          filterPlaceholder="İş Yeri Merkez Adresi'ne göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => (
            <div className="px-3 pt-0 pb-3 text-center">İş Yeri Merkez Adresi'ne göre filtreleyin</div>
          )}
        />
        <Column
          header="İş Yeri Sicil"
          field="isYeriSicil"
          sortable
          filter
          filterField="isYeriSicil"
          filterPlaceholder="İş Yeri Sicil'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İş Yeri Sicil'e göre filtreleyin</div>}
        />
        <Column
          header="İş Yeri Ünvanı"
          field="isYeriUnvani"
          sortable
          filter
          filterField="isYeriUnvani"
          filterPlaceholder="İş Yeri Ünvanı'na göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">İş Yeri Ünvanı'na göre filtreleyin</div>}
        />
        {/* <Column
          header="Referans No"
          field="referansNo"
          sortable
          filter
          filterField="referansNo"
          filterPlaceholder="Referans No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Referans No'ya göre filtreleyin</div>}
        />
        <Column
          header="Tescil Tip"
          field="tescilTip"
          sortable
          filter
          filterField="tescilTip"
          filterPlaceholder="Tescil Tip'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tescil Tip'e göre filtreleyin</div>}
        /> */}
        <Column
          header="Yeni Şube Kodu"
          field="yeniSubeKodu"
          sortable
          filter
          filterField="yeniSubeKodu"
          filterPlaceholder="Yeni Şube Kodu'na göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Yeni Şube Kodu'na göre filtreleyin</div>}
        />
        <Column
          header="Sıra No"
          field="siraNo"
          sortable
          filter
          filterField="siraNo"
          filterPlaceholder="Sıra No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Sıra No'ya göre filtreleyin</div>}
        />
        <Column
          header="Adres"
          field="adres"
          sortable
          filter
          filterField="adres"
          filterPlaceholder="Adres'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Adres'e göre filtreleyin</div>}
        />
        <Column
          header="Mahiyet"
          field="mahiyet"
          sortable
          filter
          filterField="mahiyet"
          filterPlaceholder="Mahiyet'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Mahiyet'e göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default IsYeriDataTable;
