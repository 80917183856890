import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AdminService from "../../../services/admin/admin.services";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";
import { UserDto } from "../../../services/account/account.dto";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import UpsertMainUserDiyalog from "./UpsertMainUser";
import { UpdateMainUserDto } from "../../../services/admin/admin.dto";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../../../layout/context/layoutcontext";

const UsersPage = () => {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<UserDto[]>>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { setBlocked } = useContext(LayoutContext);

  const {
    data: userResponse,
    refetch: refetchUsers,
    isLoading: userResponseIsLoading
  } = useQuery({
    queryKey: ["MainUsers"],
    refetchOnMount: true,
    queryFn: () => AdminService.GetAllMainUsers()
  });

  useEffect(() => {
    setBlocked(userResponseIsLoading);
  }, [userResponseIsLoading]);

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: commonFilterContains,
      lastName: commonFilterContains,
      userName: commonFilterContains,
      email: commonFilterContains,
      creationTime: commonFilterDateIs
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: UserDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-search"
          rounded
          outlined
          tooltip="Detay"
          severity="success"
          className="mr-2"
          onClick={() => {
            //setSelectedUser(rowData);
            //setVisibleUpdateUserDialog(true);
            navigate(`/app/main-users/${rowData.id}`);
          }}
        />
        {!rowData.isActive && (
          <Button
            icon="pi pi-check"
            tooltip="Aktive Et"
            rounded
            outlined
            severity="success"
            onClick={() => {
              activateUserConfirm(rowData.id);
            }}
          />
        )}
        {rowData.isActive && (
          <Button
            icon="pi pi-times"
            tooltip="Pasif Yap"
            rounded
            outlined
            severity="danger"
            onClick={() => {
              deactivateUserConfirm(rowData.id);
            }}
          />
        )}
      </div>
    );
  };

  const activateUserConfirm = (userId: string) => {
    confirmDialog({
      message: "Kullanıcıyı aktif etmek istediğinize emin misiniz?",
      header: "Kullanıcı Aktivasyonu",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        setBlocked(true); // refetchUsers'tan sonra false'a donecek
        const res = await AdminService.ActivateUser(userId);
        if (res.result && !res.result?.error) {
          toast.show("Kullanıcı başarılı şekilde aktif edildi.", "success");
          refetchUsers();
        }

        setBlocked(false);
      },
      reject: () => {},
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const deactivateUserConfirm = (userId: string) => {
    confirmDialog({
      message: "Kullanıcıyı pasif etmek istediğinize emin misiniz?",
      header: "Kullanıcı Pasif Yapma",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        setBlocked(true); // refetchUsers'tan sonra false'a donecek
        const res = await AdminService.DeactivateUser(userId);
        if (res.result && !res.result?.error) {
          toast.show("Kullanıcı başarılı şekilde pasif edildi.", "success");
          refetchUsers();
        }
        setBlocked(false);
      },
      reject: () => {},
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const exportExcel = () => {
    const data = userResponse?.result?.data || [];

    const headers = ["Id", "Tc", "İsim", "Soyisim", "Kullanıcı Adı", "E-posta", "Kayıt Tarihi", "Allowed Lawyer Tc"];

    const filteredData = data.map((data) => ({
      id: data.id,
      tc: data.tc,
      firstName: data.firstName,
      lastName: data.lastName,
      userName: data.userName,
      email: data.email,
      creationTime: data.creationTime ? new Date(data.creationTime).toLocaleDateString() : "",
      allowedLawyerTc: data.allowedLawyerTc
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "AnaKullanıcılar");
  };

  return (
    <div>
      <div className="card">
        {/* <Toolbar className="mb-4" end={rightToolbarTemplate}></Toolbar> */}

        <DataTable
          ref={dt}
          showGridlines
          value={userResponse?.result?.data || []}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} / {last} - Toplam {totalRecords} ana kullanıcı"
          filters={filters}
          // filterDisplay="row"
          loading={userResponseIsLoading}
          globalFilterFields={["firstName", "lastName", "userName", "email", "creationTime"]}
          emptyMessage="Ana Kullanıcı bulunamadı."
          header={CreateHeader(
            "Ana Kullanıcılar",
            globalFilterValue,
            (e) => onGlobalFilterChange(e),
            clearFilter,
            exportCSV,
            exportExcel
          )}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
          <Column
            field="firstName"
            header="İsim"
            sortable
            showFilterMatchModes={false}
            filter
            filterPlaceholder="İsme göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
          ></Column>
          <Column
            field="lastName"
            header="Soyisim"
            sortable
            filterField="lastName"
            filter
            filterPlaceholder="Soyisime göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Soyisime göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="userName"
            header="Kullanıcı Adı"
            sortable
            filterField="userName"
            filter
            filterPlaceholder="Kullanıcı Adına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">ullanıcı Adına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="email"
            header="E-posta"
            sortable
            filterField="email"
            filter
            filterPlaceholder="E-posta göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">E-posta göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="creationTime"
            header="Kayıt Tarihi"
            body={(rowData: UserDto) => {
              return <span>{rowData.creationTime ? new Date(rowData.creationTime).toLocaleDateString() : ""}</span>;
            }}
            sortable
            filterField="creationTime"
            filter
            filterPlaceholder="Kayıt Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Kayıt Tarihine göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default UsersPage;
