import React, { useContext, useEffect } from "react";
import SorguBilgileri from "./SorguBilgileri";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const Mernis = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: MernisResponse,
    refetch: refetchMernis,
    isLoading: mernisResponseLoading
  } = useQuery({
    queryKey: ["Mernis", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetMernisBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(mernisResponseLoading)
  }, [mernisResponseLoading])

  return (
    <div>
      {MernisResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {MernisResponse.error}</div>}
      {MernisResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={MernisResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={MernisResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={MernisResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={MernisResponse?.result?.sorgulayanBilgileri}
          />
          <div className="card p-2 lg:p-5 mt-3 mb-3">
            <div className="grid">
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">T.C. Kimlik No</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.tcKimlikNo ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Cinsiyeti</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.cinsiyeti ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Adı</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.adi ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Soyadı</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.soyadi ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Baba Adı</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.babaAdi ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Ana Adı</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.anaAdi ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Doğum Yeri</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.dogumYeri ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Doğum Tarihi</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.dogumTarihiStr ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Nüf. Kay. İl</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Nüf. Kay. İlçe</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Mah/Köy</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Ver. Nedeni</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.verilisNedeniAciklamasi ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Ver. Tarih</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.verildigiTarih ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Ver. İl</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.verildigiIlAdi ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Ver. İlçe</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.verildigiIlceAdi ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Cüzdan Seri</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.cuzdanSeriNo ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Cüzdan No</div>
                  <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.kimlikBilgisi?.[0]?.cuzdanNo ?? ""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Cilt No</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Aile Sıra No</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Sıra No</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Dini</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Önceki Soyadı</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex gap-3">
                  <div className="font-bold w-8rem">Ölüm Tarihi</div>
                  <div>{`: ${""}`}</div>
                </div>
              </div>
            </div>
          </div>

          {MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler?.[0]?.yurtDisiAdresi ? (
            <>
              <div className="card p-2 lg:p-5 mt-3">
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3">
                      <div className="font-bold w-8rem">Adres Tipi</div>
                      <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].AdresTipi ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3">
                      <div className="font-bold w-8rem">Beyan Tarihi</div>
                      <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.beyanTarih?.gun}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.beyanTarih?.ay}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.beyanTarih?.yil}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3">
                      <div className="font-bold w-8rem">Taşınma Tarihi</div>
                      <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.tasinmaTarih?.gun}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.tasinmaTarih?.ay}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.tasinmaTarih?.yil}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3">
                      <div className="font-bold w-8rem">Tescil Tarihi</div>
                      <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.tescilTarih?.gun}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.tescilTarih?.ay}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler[0]?.tescilTarih?.yil}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3">
                      <div className="font-bold w-8rem">Açık Adres</div>
                      <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].yurtDisiAdresi.acikAdres ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3">
                      <div className="font-bold w-8rem">Ülke</div>
                      <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].yurtDisiAdresi.ulke ?? ""}`}</div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="flex gap-3">
                      <div className="font-bold w-8rem">Şehir</div>
                      <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].yurtDisiAdresi.sehir ?? ""}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler?.[0]?.AdresTipi === "Bilinmiyor" ? (
                <div className="card p-2 lg:p-5 mt-3 mb-3">Adres bilgisi bulunamadı.</div>
              ) : MernisResponse?.result?.sorguSonucDVO?.mernisAdres?.adresler?.[0]?.AdresTipi ?  (
                <>
                  <div className="card p-2 lg:p-5 mt-3">
                    <div className="grid">
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">Adres Tipi</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].AdresTipi ?? ""}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">Beyan Tarihi</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].beyanTarih.gun}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].beyanTarih.ay}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].beyanTarih.yil}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">Taşınma Tarihi</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].tasinmaTarih.gun}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].tasinmaTarih.ay}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].tasinmaTarih.yil}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">Tescil Tarihi</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].tescilTarih.gun}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].tescilTarih.ay}/${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler[0].tescilTarih.yil}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">Mahalle</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler?.[0]?.ilIlceMerkeziAdresi?.mahalle ?? ""}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">Cadde/Sokak</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler?.[0]?.ilIlceMerkeziAdresi?.csbm ?? ""}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">Dış Kapı No</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler?.[0]?.ilIlceMerkeziAdresi?.disKapiNo ?? ""}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">İç Kapı No</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler?.[0]?.ilIlceMerkeziAdresi?.icKapiNo ?? ""}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">İl</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler?.[0]?.ilIlceMerkeziAdresi?.il ?? ""}`}</div>
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                          <div className="font-bold w-8rem">İlçe</div>
                          <div>{`: ${MernisResponse?.result?.sorguSonucDVO?.mernisAdres.adresler?.[0]?.ilIlceMerkeziAdresi?.ilce ?? ""}`}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </>
              ) : (
                <>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Mernis;
