import { useQuery } from "@tanstack/react-query";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../../layout/context/layoutcontext";
import { useHub } from "../../../../utils/buro-hub";
import SorguBilgileri from "./SorguBilgileri";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { FilterMatchMode } from "primereact/api";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";

const Gsm = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const {
    data: GsmResponse,
    refetch: refetchGsm,
    isLoading: gsmResponseLoading
  } = useQuery({
    queryKey: ["Gsm", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetGsmBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(gsmResponseLoading);
  }, [gsmResponseLoading]);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      operator: commonFilterContains,
      adres: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const processedData =
    GsmResponse?.result?.sorguSonucDVO?.flatMap(
      (item) =>
        item.adresBilgileri?.map((adres) => ({
          operator: item.operatorTuru,
          adres: adres.adres
        })) || []
    ) || [];

  return (
    <div>
      {GsmResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {GsmResponse.error}</div>}
      {GsmResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={GsmResponse?.result?.sorgulamaTuru ?? ""}
            borcluBilgileri={GsmResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={GsmResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={GsmResponse?.result?.sorgulayanBilgileri}
          />
          {GsmResponse?.result?.sorguSonucu === false ? (
            <div className="card p-2 lg:p-5 mt-3">Kişiye ait GSM operatörlerinde kaydı yok.</div>
          ) : (
            <>
              <div className="card p-2 lg:p-5 mt-3">Kişiye ait GSM operatörlerinde kaydı var.</div>

              <div className="overflow-x-auto mt-3">
                <DataTable
                  value={processedData}
                  dataKey="key"
                  showGridlines
                  scrollable
                  scrollHeight="250px"
                  style={{ minHeight: "7rem" }}
                  selectionMode={"single"}
                  loading={gsmResponseLoading}
                  filters={filters}
                  globalFilterFields={["operator", "adres"]}
                  columnResizeMode="expand"
                  header={CreateHeader("Gsm", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
                >
                  <Column
                    field="operator"
                    header="Operator"
                    sortable
                    filterField="operator"
                    filter
                    filterPlaceholder="Operatör'e göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Operatör'e göre filtreleyin</div>;
                    }}
                  ></Column>
                  <Column
                    field="adres"
                    header="Adres"
                    sortable
                    filterField="adres"
                    filter
                    filterPlaceholder="Adres'e göre arayın"
                    filterClear={Filter.FilterClearTemplate}
                    filterApply={Filter.FilterApplyTemplate}
                    filterFooter={() => {
                      return <div className="px-3 pt-0 pb-3 text-center">Adres'e göre filtreleyin</div>;
                    }}
                  ></Column>
                </DataTable>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Gsm;
