import React, { useContext, useEffect } from "react";
import {
  DosyaDTO,
  IsYeri,
  IsyeriResponseDTO,
  Yonetici
} from "../../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { Accordion, AccordionTab } from "primereact/accordion";
import SorguBilgileri from "../SorguBilgileri";
import { useHub } from "../../../../../utils/buro-hub";
import uyapService from "../../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { LayoutContext } from "../../../../../layout/context/layoutcontext";

const Isyeri = (props: { dosyaId: string; kisiKurumId: number }) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const {
    data: IsyeriResponse,
    refetch: refetchIsyeri,
    isLoading: isyeriResponseLoading
  } = useQuery({
    queryKey: ["Isyeri", props.dosyaId, props.kisiKurumId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() => uyapService.GetIsyeriBilgiler(props.dosyaId, props.kisiKurumId))
  });

  useEffect(() => {
    setBlocked(isyeriResponseLoading)
  }, [isyeriResponseLoading])

  const IsyeriComponent = (props: {
    isYeri: IsYeri;
  }) => {
    const { isYeri } = props;

    // const cardList: any[] = [
    //     { id: "eskiSubeKodu", title: "Eski Şube Kodu", value: isYeri.TE_ESKI_SUBE_KOD },
    //     { id: "il", title: "İl", value: isYeri.TE_ILKOD },
    //     { id: "isYeriAciklama", title: "İş Yeri Açıklama", value: "" },
    //     { id: "isYeriAdres", title: "İş Yeri Adres", value: `${isYeri.ADR_ADRES1} ${isYeri.ADR_ADRES2} ${isYeri.ADR_DIS_KAPI} ${isYeri.ADR_IC_KAPI} ${isYeri.ILC_ILCEAD}/${isYeri.ILADI}` },
    //     { id: "isYeriMerkezAdresi", title: "İş Yeri Merkez Adresi", value: isYeri.ADR_ADRES2 },
    //     { id: "isYeriSicil", title: "İş Yeri Sicil", value: "" },
    //     { id: "isYeriUnvani", title: "İş Yeri Ünvanı", value: isYeri.TE_ISYERI_UNVAN },
    //     { id: "referansNo", title: "Referans No", value: "" },
    //     { id: "tescilTip", title: "Tescil Tip", value: "" },
    //     { id: "yeniSubeKodu", title: "Yeni Şube Kodu", value: isYeri.TE_YENI_SUBE_KOD },
    //     { id: "siraNo", title: "Sıra No", value: isYeri.TE_SIRANO },
    //     { id: "adres", title: "Adres", value: `${isYeri.TE_ADRES1} ${isYeri.TE_ADRES2} ${isYeri.TE_ADRES3} ${isYeri.TE_ADRES4}` },
    //     { id: "mahiyet", title: "Mahiyet", value: isYeri.TE_MAHIYET },
    // ];

    return (
      <div>
        <div className="card p-1 lg:p-5 mt-4">
          <div className="grid">
            {/* {cardList.map((item) => (
                            <div key={item.id} className={item.title === "Sıra No" ? "col-12" : "col-12 md:col-6"}>
                                <div className='flex gap-3 ' >
                                    <div className="font-bold w-12rem" > {item.title} </div>
                                    <div className="" >{` : ${item.value}`}</div>
                                </div>
                            </div>
                        ))} */}
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">Eski Şube Kodu</div>
                <div>{` : ${isYeri.TE_ESKI_SUBE_KOD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">İl</div>
                <div>{` : ${isYeri.TE_ILKOD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">İş Yeri Açıklama</div>
                <div>{` : ${""}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">İş Yeri Adres</div>
                {/* <div>{` : ${isYeri.ADR_ADRES1} ${isYeri.ADR_ADRES2} ${isYeri.ADR_DIS_KAPI} ${isYeri.ADR_IC_KAPI} ${isYeri.ILC_ILCEAD}/${isYeri.ILADI}`}</div> */}
                <div>{` : ${""}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">İş Yeri Merkez Adresi</div>
                <div>{` : ${""}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">İş Yeri Sicil</div>
                <div>{` : ${""}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">İş Yeri Ünvanı</div>
                <div>{` : ${isYeri.TE_ISYERI_UNVAN}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">Referans No</div>
                <div>{` : ${""}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">Tescil Tip</div>
                <div>{` : ${""}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">Yeni Şube Kodu</div>
                <div>{` : ${isYeri.TE_YENI_SUBE_KOD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">Sıra No</div>
                <div>{` : ${isYeri.TE_SIRANO}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">Adres</div>
                <div>{` : ${isYeri.TE_ADRES1} ${isYeri.TE_ADRES2} ${isYeri.TE_ADRES3} ${isYeri.TE_ADRES4}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className='flex gap-3'>
                <div className="font-bold w-12rem">Mahiyet</div>
                <div>{` : ${isYeri.TE_MAHIYET}`}</div>
              </div>
            </div>
          </div>

          <Accordion className="mt-4" activeIndex={-1}>
            <AccordionTab header="Yöneticiler">
              {isYeri?.yoneticiler?.map((item, index) => (
                <p className="m-0">
                  <YoneticiComponent yonetici={item} />
                </p>
              ))}
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    );
  };

  const YoneticiComponent = (props: { yonetici: Yonetici }) => {
    const { yonetici } = props;

    return (
      <div>
        <div className="card p-1 lg:p-5 mt-4">
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">T.C. Kimlik No</div>
                <div>{` : ${yonetici.TY_MERNIS_NO}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Uyruk</div>
                <div>{` : ${yonetici.TY_UYRUK}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Adı</div>
                <div>{` : ${yonetici.TY_AD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Soyadı</div>
                <div>{` : ${yonetici.TY_SOYAD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Baba Adı</div>
                <div>{` : ${yonetici.TY_BABA_AD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Ana Adı</div>
                <div>{` : ${yonetici.TY_ANA_AD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Cinsiyet</div>
                <div>{` : ${yonetici.TY_CINSIYET}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Doğum Tarihi</div>
                <div>{` : ${yonetici.TY_DOGUM_TARIH}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Doğum Yeri</div>
                <div>{` : ${yonetici.TY_DOGUM_YER}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Eski Şube Kodu</div>
                <div>{` : ${yonetici.TY_ESKI_SUBE_KOD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Yeni Şube Kodu</div>
                <div>{` : ${yonetici.TY_YENI_SUBE_KOD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">İl</div>
                <div>{` : ${yonetici.TY_ILKOD}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Referans No</div>
                <div>{` : ${""}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Sıra No</div>
                <div>{` : ${yonetici.TY_SIRANO}`}</div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex gap-3">
                <div className="font-bold w-12rem">Yönetici Kod</div>
                <div>{` : ${yonetici.TY_YKOD_ACIKLAMA}`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {IsyeriResponse?.error && <div className="card p-2 lg:p-5 mt-3">Hata : {IsyeriResponse.error}</div>}
      {IsyeriResponse?.result && (
        <div>
          <SorguBilgileri
            sorguAdı={IsyeriResponse?.result.sorgulamaTuru ?? ""}
            borcluBilgileri={IsyeriResponse?.result?.borcluBilgileri}
            dosyaOzetBilgileri={IsyeriResponse?.result?.dosyaOzetDVO}
            sorgulayanBilgileri={IsyeriResponse?.result?.sorgulayanBilgileri}
          />
          {IsyeriResponse?.result?.sorguSonucDVO?.isYeriBilgi?.hataMesaji ? (
            <div className="card p-2 lg:p-5 mt-3">{IsyeriResponse?.result?.sorguSonucDVO?.isYeriBilgi?.hataMesaji}</div>
          ) : (
            <div>
              <div className="grid">
                {IsyeriResponse?.result?.sorguSonucDVO?.isYeriBilgi?.isyeriList?.map((item, index) => (
                  <IsyeriComponent key={index} isYeri={item} />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Isyeri;
