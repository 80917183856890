import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useRef, useState } from "react";
import {
  DosyaDTO,
  HisseListDTO,
  SorguSonucTakbisDVO,
  TakbisHisseResponseDTO
} from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import TakbisMahrumiyet from "./TakbisMahrumiyet";
import { useHub } from "../../../../utils/buro-hub";
import uyapService from "../../../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { commonFilterContains, Filter, CreateHeader } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const TakbisHisse = (props: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  takbisData?: SorguSonucTakbisDVO;
  dosyaId: string;
  kisiKurumId: number;
}) => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext)

  const [takbisHisseData, setTakbisHisseData] = useState<HisseListDTO>();
  const [visibleTakbisMahrumiyetDialog, setVisibleTakbisMahrumiyetDialog] = useState(false);
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<HisseListDTO[]>>(null);

  const {
    data: TakbisHisseResponse,
    refetch: refetchTakbisHisse,
    isLoading: takbisHisseResponseLoading
  } = useQuery({
    queryKey: ["TakbisHisse", props.dosyaId, props.kisiKurumId, props.takbisData?.tasinmazDVO?.tasinmazId],
    refetchOnMount: false,
    queryFn: async () =>
      await hub.executeUyapService(() =>
        uyapService.GetTakbisHisseBilgiler(
          props.dosyaId,
          props.kisiKurumId,
          props.takbisData?.tasinmazDVO?.tasinmazId || 0
        )
      )
  });

  useEffect(() => {
    setBlocked(takbisHisseResponseLoading)
  }, [takbisHisseResponseLoading])

  const mahrumiyetBilgisiActionBodyTemplate = (rowData: TakbisHisseResponseDTO["hisseList"][number]) => {
    return (
      <button onClick={() => handleButtonClick(rowData)} className="p-button p-component p-button-info">
        Mahrumiyet Bilgisi
      </button>
    );
  };

  const handleButtonClick = (rowData: TakbisHisseResponseDTO["hisseList"][number]) => {
    setVisibleTakbisMahrumiyetDialog(true);
    setTakbisHisseData(rowData);
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      aciklama: commonFilterContains,
      hisseTip: commonFilterContains,
      tapuBolumDurum: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const data = TakbisHisseResponse?.result?.hisseList?.map((item, index) => ({ ...item, key: index })) || [];

    const headers = ["Hisse Tipi", "Durum"];

    // const filteredData = data.map(({ aciklama, hisseTip, tapuBolumDurum }) => ({
    //     aciklama, hisseTip, tapuBolumDurum
    // }));

    const filteredData = data.map(({ hisseTip, tapuBolumDurum }) => ({
      hisseTip,
      tapuBolumDurum
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TarafBilgileri");
  };



  return (
    <div>
      {TakbisHisseResponse?.error && <div className="card p-2 lg:p-5 mt-2">Hata : {TakbisHisseResponse.error}</div>}
      {TakbisHisseResponse?.result && (
        <Dialog
          header={props.takbisData?.tasinmazDVO?.tasinmazId + " - Hisse Bilgisi"}
          visible={props.visible}
          maximizable
          style={{ width: "60%", height: "60%" }}
          onHide={() => {
            if (!props.visible) return;
            props.setVisible(false);
          }}
        >
          <div className="card p-2 lg:p-5 mt-2">
            <div className="overflow-x-auto">
              <DataTable
                value={TakbisHisseResponse?.result?.hisseList}
                ref={dt}
                dataKey="hisseId"
                showGridlines
                scrollable
                scrollHeight="250px"
                loading={takbisHisseResponseLoading}
                // style={{ minHeight: "7rem" }}
                // selectionMode={"single"}
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                filters={filters}
                // filterDisplay="row"
                globalFilterFields={["aciklama", "hisseTip", "tapuBolumDurum"]}
                header={CreateHeader(
                  "Takbis Hisse Bilgileri",
                  globalFilterValue,
                  (e) => onGlobalFilterChange(e),
                  clearFilter,
                  exportCSV,
                  exportExcel
                )}
                columnResizeMode="expand"
              >
                <Column
                  header="No"
                  body={(rowData, { rowIndex }) => rowIndex + 1}
                  className="w-4rem text-center"
                ></Column>
                <Column
                  field=""
                  header="Açıklama"
                  sortable
                  filterField="aciklama"
                  filter
                  filterPlaceholder="Açıklamaya göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Açıklamaya göre filtreleyin</div>;
                  }}
                ></Column>
                <Column
                  field="hisseTip"
                  header="Hisse Tipi"
                  sortable
                  filterField="hisseTip"
                  filter
                  filterPlaceholder="Hisse Tipine göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Hisse Tipine göre filtreleyin</div>;
                  }}
                ></Column>
                <Column
                  field="tapuBolumDurum"
                  header="Durum"
                  sortable
                  filterField="tapuBolumDurum"
                  filter
                  filterPlaceholder="Duruma göre arayın"
                  filterClear={Filter.FilterClearTemplate}
                  filterApply={Filter.FilterApplyTemplate}
                  filterFooter={() => {
                    return <div className="px-3 pt-0 pb-3 text-center">Duruma göre filtreleyin</div>;
                  }}
                ></Column>
                <Column body={mahrumiyetBilgisiActionBodyTemplate} header="Mahrumiyet Bilgisi"></Column>
              </DataTable>
            </div>

            {visibleTakbisMahrumiyetDialog && (
              <>
                <TakbisMahrumiyet
                  visible={visibleTakbisMahrumiyetDialog}
                  setVisible={setVisibleTakbisMahrumiyetDialog}
                  dosyaId={props.dosyaId}
                  kisiKurumId={props.kisiKurumId}
                  takbisData={props.takbisData}
                  takbisHisseData={takbisHisseData}
                />
              </>
            )}
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default TakbisHisse;
