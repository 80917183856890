import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  DosyaDurumuDto,
  CreateDosyaDurumuDto,
  UpdateDosyaDurumuDto,
} from "./dosya-durumu.dto";
import { NoContentDto } from "../base/base.dto";

const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<DosyaDurumuDto>(`DosyaDurumu/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<DosyaDurumuDto[]>(`DosyaDurumu`, config);
  },
  Create(
    dto: CreateDosyaDurumuDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<DosyaDurumuDto>("DosyaDurumu", dto, config);
  },
  Update(
    dto: UpdateDosyaDurumuDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<DosyaDurumuDto>("DosyaDurumu", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`DosyaDurumu/${id}`, config);
  },
};

export default services;
