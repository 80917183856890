import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import uyapDosyaService from "../../services/uyap-dosya/uyap-dosya.service";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { UyapDosyaDto } from "../../services/uyap-dosya/uyap-dosya.dto";
import { commonFilterContains, CreateHeader, Filter } from "../filter/base-filter";
import DosyaDetaylariDialog from "../dosya-detaylari/DosyaDetaylariDialog";
import { useHub } from "../../utils/buro-hub";
import uyapService from "../../services/uyap-sorgulari/uyap-sorgulari.service";
import { DosyaDTO, RequestDosyaTarafDTO } from "../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { ExportFile } from "../../utils/export-file";
import { LayoutContext } from "../../layout/context/layoutcontext";

const FrequentlyUsedFiles = (props: any) => {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<UyapDosyaDto[]>>(null);
  const toast = useToast();
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const [visibleDosyaDetayleriDialog, setVisibleDosyaDetayleriDialog] = useState(false);
  const [selectedDosya, setSelectedDosya] = useState<UyapDosyaDto | null>(null);
  const [selectedUyapDosya, setSelectedUyapDosya] = useState<DosyaDTO | null>(null);
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleSetVisibleDosyaDetayleriDialog = (visible: boolean) => {
    setVisibleDosyaDetayleriDialog(visible);
    if (visible === false) {
      setSelectedDosya(null);
    }
  };

  const {
    data: uyapDosyaResponse,
    refetch: refetchUyapDosya,
    isLoading: uyapDosyaResponseIsLoading
  } = useQuery({
    queryKey: ["SikKullanilanDosyalar"],
    refetchOnMount: true,
    queryFn: () => uyapDosyaService.GetAll()
  });

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData: UyapDosyaDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-search"
          rounded
          outlined
          className="mr-2"
          severity="success"
          tooltip="Detay"
          onClick={async () => {
            if (!hub.getBuroSettings().connectedSessionLawyerName) {
              toast.show("Lütfen önce Uyap'a bağlanınız.", "error");
              setBlocked(false);
              return;
            }
            setBlocked(true);

            const jsonString: string = JSON.stringify([rowData.dosyaNo, rowData.birimAdi, rowData.dosyaTur]);

            var res = await hub.executeUyapService(() =>
              uyapService.GetDosyaBilgileriByWord({
                dosyaDurumKod: 0,
                pageNumber: 1,
                pageSize: 500,
                phrases: jsonString
              })
            );

            if (res && res.result && res.result[1] === 1) {
              const dosyaId = res.result[0][0].dosyaId;
              const copyDosya = { ...rowData, dosyaId: dosyaId };
              setSelectedDosya(copyDosya);
              setSelectedUyapDosya(res.result[0][0]);
              setVisibleDosyaDetayleriDialog(true);
              setBlocked(false);
              return;
            }

            await delay(1000);
            var res = await hub.executeUyapService(() =>
              uyapService.GetDosyaBilgileriByWord({
                dosyaDurumKod: 1,
                pageNumber: 1,
                pageSize: 500,
                phrases: jsonString
              })
            );

            if (res && res.result && res.result[1] === 1) {
              const dosyaId = res.result[0][0].dosyaId;
              const copyDosya = { ...rowData, dosyaId: dosyaId };
              setSelectedDosya(copyDosya);
              setVisibleDosyaDetayleriDialog(true);
              setSelectedUyapDosya(res.result[0][0]);
              setBlocked(false);
              return;
            }

            setBlocked(false);
            toast.show("Dosya detayları alınırken bir sorun oluştu.", "error");
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          tooltip="Sil"
          severity="danger"
          className="mr-2"
          onClick={() => {
            deleteUyapDosyaConfirm(rowData);
          }}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dosyaId: commonFilterContains,
      dosyaNo: commonFilterContains,
      birimAdi: commonFilterContains,
      dosyaTur: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const deleteUyapDosya = (rowData: UyapDosyaDto) => {
    uyapDosyaService.Delete(rowData.id!).then((res) => {
      if (res.result && !res.result?.error) {
        toast.show("Sık kullanılan dosya başarıyla silindi.", "success");
        refetchUyapDosya();
      }
    });
  };

  const deleteUyapDosyaConfirm = (rowData: UyapDosyaDto) => {
    confirmDialog({
      message: "Sık kullanılan dosyayı silmek istediğinize emin misiniz?",
      header: "Sık Kullanılan Dosya Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteUyapDosya(rowData),
      reject: () => {},
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const exportExcel = () => {
    const data = uyapDosyaResponse?.result?.data || [];

    const headers = ["Birim Adı", "Dosya Numarası", "Dosya Türü"];

    const filteredData = data.map((data) => ({
      birimAdi: data.birimAdi,
      dosyaNo: data.dosyaNo,
      dosyaTur: data.dosyaTur
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "SıkKullanılanDosyalar");
  };

  return (
    <div>
      <div className="card">
        {/* <Toolbar
          className="mb-4"
          // start={leftToolbarTemplate}
          end={rightToolbarTemplate}
        ></Toolbar> */}

        <DataTable
          ref={dt}
          value={uyapDosyaResponse?.result?.data || []}
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} sık kullanılan dosya"
          filters={filters}
          loading={uyapDosyaResponseIsLoading}
          globalFilterFields={["dosyaId", "dosyaNo", "birimAdi", "dosyaTur"]}
          emptyMessage="Sık kullanılan dosyanız bulunamadı."
          header={CreateHeader(
            "Sık Kullanılan Dosyalar",
            globalFilterValue,
            (e) => onGlobalFilterChange(e),
            clearFilter,
            exportCSV,
            exportExcel
          )}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
          <Column
            field="birimAdi"
            header="Birim Adı"
            sortable
            filterField="birimAdi"
            filter
            filterPlaceholder="Birim adına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Birim adına göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="dosyaNo"
            header="Dosya Numarası"
            sortable
            filterField="dosyaNo"
            filter
            filterPlaceholder="Dosya numarasına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Dosya numarasına göre filtreleyin</div>;
            }}
          ></Column>

          <Column
            field="dosyaTur"
            header="Dosya Türü"
            sortable
            filterField="dosyaTur"
            filter
            filterPlaceholder="Dosya türüne göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Dosya türüne göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      {selectedUyapDosya && (
        <DosyaDetaylariDialog
          visible={visibleDosyaDetayleriDialog}
          handleSetVisibleDosyaDetayleriDialog={handleSetVisibleDosyaDetayleriDialog}
          dosyaId={selectedUyapDosya.dosyaId}
          dosyaTur={selectedUyapDosya.dosyaTur}
          dosyaNo={selectedUyapDosya.dosyaNo}
          birimAdi={selectedUyapDosya.birimAdi}
          dosyaTurKod={selectedUyapDosya.dosyaTurKod}
        />
      )}
    </div>
  );
};

export default FrequentlyUsedFiles;
