import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguBagkurCalisanModel } from "../TopluSorgu.dto";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../../filter/base-filter";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { ExportFile } from "../../../utils/export-file";

const BagkurCalisaniDataTable = (props: { tableData: TopluSorguBagkurCalisanModel[] }) => {
  const bagkurCalisaniVeri = props.tableData.map((item, index) => ({ ...item, key: index }));
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguBagkurCalisanModel[]>>(null);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "ortakDegerler.birimAdi": commonFilterContains,
      "ortakDegerler.dosyaNo": commonFilterContains,
      "ortakDegerler.alacakliAdi": commonFilterContains,
      "ortakDegerler.borcluAdi": commonFilterContains,
      "ortakDegerler.borcluTCVKNo": commonFilterContains,
      "ortakDegerler.dosyaDurumu": commonFilterContains,
      bagkurNo: commonFilterContains,
      tescilTarihi: commonFilterDateIs,
      meslekIli: commonFilterContains,
      meslekIlce: commonFilterContains,
      terkTarihi: commonFilterDateIs,
      terkAciklama: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, []);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportExcel = () => {
    const headers = [
      "Birim Adı",
      "Dosya No",
      "Alacaklı Adı/Ünvanı",
      "Borçlu Adı/Ünvanı",
      "Borçlu TC/VK No",
      "Dosya Durumu",
      "Bağkur No",
      "Tescil Tarihi",
      "Meslek İli",
      "Meslek İlçe",
      "Terk Tarihi",
      "Terk Açıklama"
    ];

    const filteredData = bagkurCalisaniVeri.map((data) => ({
      birimAdi: data.ortakDegerler.birimAdi,
      dosyaNo: data.ortakDegerler.dosyaNo,
      alacakliAdi: data.ortakDegerler.alacakliAdi,
      borcluAdi: data.ortakDegerler.borcluAdi,
      borcluTCVKNo: data.ortakDegerler.borcluTCVKNo,
      dosyaDurumu: data.ortakDegerler.dosyaDurumu,
      bagkurNo: data.bagkurNo,
      tescilTarihi:
        data.tescilTarihi && moment(data.tescilTarihi).isValid()
          ? moment(data.tescilTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
      meslekIli: data.meslekIli,
      meslekIlce: data.meslekIlce,
      terkTarihi:
        data.terkTarihi && moment(data.terkTarihi).isValid()
          ? moment(data.terkTarihi).format(process.env.REACT_APP_DATE_FORMAT)
          : "",
      terkAciklama: data.terkAciklama
    }));

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "TopluSorguBagkurCalisani");
  };

  return (
    <div>
      <DataTable
        value={bagkurCalisaniVeri}
        ref={dt}
        dataKey="bagkurNo"
        showGridlines
        scrollable
        emptyMessage="Bağkur Çalışanı Bilgisi bulunamadı."
        columnResizeMode="expand"
        header={CreateHeader(
          "Bağkur Çalışanı Bilgileri",
          globalFilterValue,
          (e) => onGlobalFilterChange(e),
          clearFilter,
          exportCSV,
          exportExcel
        )}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        filters={filters}
        globalFilterFields={[
          "ortakDegerler.birimAdi",
          "ortakDegerler.dosyaNo",
          "ortakDegerler.alacakliAdi",
          "ortakDegerler.borcluAdi",
          "ortakDegerler.borcluTCVKNo",
          "ortakDegerler.dosyaDurumu",
          "bagkurNo",
          "tescilTarihi",
          "meslekIli",
          "meslekIlce",
          "terkTarihi",
          "terkAciklama"
        ]}
      >
        <Column
          header="Birim Adı"
          field="ortakDegerler.birimAdi"
          sortable
          filterField="ortakDegerler.birimAdi"
          filter
          filterPlaceholder="Birim Adına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
        />
        <Column
          header="Dosya No"
          field="ortakDegerler.dosyaNo"
          sortable
          filterField="ortakDegerler.dosyaNo"
          filter
          filterPlaceholder="Dosya No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>}
        />
        <Column
          header="Alacaklı Adı/Ünvanı"
          field="ortakDegerler.alacakliAdi"
          sortable
          filterField="ortakDegerler.alacakliAdi"
          filter
          filterPlaceholder="Alacaklı Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Alacaklı Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu Adı/Ünvanı"
          field="ortakDegerler.borcluAdi"
          sortable
          filterField="ortakDegerler.borcluAdi"
          filter
          filterPlaceholder="Borçlu Adı/Ünvanına göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu Adı/Ünvanına göre filtreleyin</div>}
        />
        <Column
          header="Borçlu TC/VK No"
          field="ortakDegerler.borcluTCVKNo"
          sortable
          filterField="ortakDegerler.borcluTCVKNo"
          filter
          filterPlaceholder="Borçlu TC/VK No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Borçlu TC/VK No'ya göre filtreleyin</div>}
        />
        <Column
          header="Dosya Durumu"
          field="ortakDegerler.dosyaDurumu"
          sortable
          filterField="ortakDegerler.dosyaDurumu"
          filter
          filterPlaceholder="Dosya Durumuna göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya Durumuna göre filtreleyin</div>}
        />
        <Column
          header="Bağkur No"
          field="bagkurNo"
          sortable
          filter
          filterField="bagkurNo"
          filterPlaceholder="Bağkur No'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Bağkur No'ya göre filtreleyin</div>}
        />
        <Column
          header="Tescil Tarihi"
          field="tescilTarihi"
          sortable
          body={(rowData) =>
            rowData.tescilTarihi && moment(rowData.tescilTarihi).isValid() ? (
              moment(rowData.tescilTarihi).format(process.env.REACT_APP_DATE_FORMAT)
            ) : (
              <></>
            )
          }
          filter
          filterField="tescilTarihi"
          filterPlaceholder="Tescil Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tescil Tarihine göre filtreleyin</div>}
        />
        <Column
          header="Meslek İli"
          field="meslekIli"
          sortable
          filter
          filterField="meslekIli"
          filterPlaceholder="Meslek İl'ine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Meslek İl'ine göre filtreleyin</div>}
        />
        <Column
          header="Meslek İlçe"
          field="meslekIlce"
          sortable
          filter
          filterField="meslekIlce"
          filterPlaceholder="Meslek İlçe'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Meslek İlçe'ye göre filtreleyin</div>}
        />
        <Column
          header="Terk Tarihi"
          field="terkTarihi"
          sortable
          body={(rowData) =>
            rowData.terkTarihi && moment(rowData.terkTarihi).isValid() ? (
              moment(rowData.terkTarihi).format(process.env.REACT_APP_DATE_FORMAT)
            ) : (
              <></>
            )
          }
          filter
          filterField="terkTarihi"
          filterPlaceholder="Terk Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Terk Tarihine göre filtreleyin</div>}
        />
        <Column
          header="Terk Açıklama"
          field="terkAciklama"
          sortable
          filter
          filterField="terkAciklama"
          filterPlaceholder="Terk Açıklama'ya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Terk Açıklama'ya göre filtreleyin</div>}
        />
      </DataTable>
    </div>
  );
};

export default BagkurCalisaniDataTable;
