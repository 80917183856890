import React, { useState } from "react";
import { DosyaBorcluListesiResponseDTO, DosyaDTO } from "../../../../services/uyap-sorgulari/uyap-sorgulari.dto";
import SgkCalisani from "./sgk-components/SgkCalisani";
import SgkEmeklisi from "./sgk-components/SgkEmeklisi";
import KamuCalisani from "./sgk-components/KamuCalisani";
import KamuEmeklisi from "./sgk-components/KamuEmeklisi";
import BagkurCalisani from "./sgk-components/BagkurCalisani";
import BagkurEmeklisi from "./sgk-components/BagkurEmeklisi";
import Isyeri from "./sgk-components/Isyeri";

interface CardListItem {
  id: number;
  title: string;
  showIcon: boolean;
  visible: boolean;
}

const sgkCardList: CardListItem[] = [
  { id: 1, title: "SSK Çalışanı", showIcon: true, visible: true },
  { id: 2, title: "SSK Emeklisi", showIcon: true, visible: true },
  { id: 3, title: "Kamu Çalışanı", showIcon: true, visible: true },
  { id: 4, title: "Kamu Emeklisi", showIcon: true, visible: true },
  { id: 5, title: "Bağkur Çalışanı", showIcon: true, visible: true },
  { id: 6, title: "Bağkur Emeklisi", showIcon: true, visible: true },
  { id: 7, title: "SSK İş Yeri Bilgisi", showIcon: true, visible: true }
];

const Sgk = (props: { dosyaId: string; kisiKurumId: number; borcluBilgileri: DosyaBorcluListesiResponseDTO }) => {
  const [selectedCard, setSelectedCard] = useState<CardListItem | null>(null);

  sgkCardList.forEach((item) => {
    item.visible = false;
  });

  if (props.borcluBilgileri?.kurumDVO) {
    sgkCardList[6].visible = true;
  } else {
    sgkCardList.forEach((item) => {
      item.visible = true;
    });
  }

  return (
    <div>
      <div className="card p-2 lg:p-5 mt-2">
        <div className="grid">
          {sgkCardList
            .filter((item) => item.visible == true)
            .map((item) => (
              <div key={item.id} className="col-12 md:col-6 lg:col-4 xl:col-3">
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round relative cursor-pointer">
                  {item.showIcon && (
                    <div className="absolute top-0 right-0 p-2">
                      <i className="pi pi-circle-fill text-blue-600 text-xs"></i>
                    </div>
                  )}
                  <div className="flex align-items-center" onClick={() => setSelectedCard(item)}>
                    <div className="flex flex-column justify-content-center">
                      <span className="block text-2xl font-bold text-black">{item.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {selectedCard && selectedCard.id === 1 && (
        <div>
          <SgkCalisani dosyaId={props.dosyaId} kisiKurumId={props.kisiKurumId!} />
        </div>
      )}
      {selectedCard && selectedCard.id === 2 && (
        <div>
          <SgkEmeklisi dosyaId={props.dosyaId} kisiKurumId={props.kisiKurumId!} />
        </div>
      )}
      {selectedCard && selectedCard.id === 3 && (
        <div>
          <KamuCalisani dosyaId={props.dosyaId} kisiKurumId={props.kisiKurumId!} />
        </div>
      )}
      {selectedCard && selectedCard.id === 4 && (
        <div>
          <KamuEmeklisi dosyaId={props.dosyaId} kisiKurumId={props.kisiKurumId!} />
        </div>
      )}
      {selectedCard && selectedCard.id === 5 && (
        <div>
          <BagkurCalisani dosyaId={props.dosyaId} kisiKurumId={props.kisiKurumId!} />
        </div>
      )}
      {selectedCard && selectedCard.id === 6 && (
        <div>
          <BagkurEmeklisi dosyaId={props.dosyaId} kisiKurumId={props.kisiKurumId!} />
        </div>
      )}
      {selectedCard && selectedCard.id === 7 && (
        <div>
          <Isyeri dosyaId={props.dosyaId} kisiKurumId={props.kisiKurumId!} />
        </div>
      )}
    </div>
  );
};

export default Sgk;
