import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
    SupportDto,
    CreateSupportDto
} from "./support.dto";
import { SendSupportMessageDto } from "./support-message.dto";

const services = {
    Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
        return http.get<SupportDto>(`Support/${id}`, config);
    },
    GetAll(config?: AxiosRequestConfig<any> | undefined) {
        return http.get<SupportDto[]>(`Support`, config);
    },
    Create(dto: FormData, config?: AxiosRequestConfig<any> | undefined) {
        return http.post<SupportDto>("Support", dto, config);
    },
    Update(dto: FormData, config?: AxiosRequestConfig<any> | undefined) {
        return http.put<SupportDto>("Support", dto, config);
    },
};

export default services;
