import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { NoContentDto } from "../base/base.dto";
import {
  TebligatBilgisiDto,
  CreateTebligatBilgisiDto,
  UpdateTebligatBilgisiDto,
} from "./tebligat-bilgisi.dto";
const services = {
  Get(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TebligatBilgisiDto>(`TebligatBilgisi/Get/${id}`, config);
  },
  GetAll(config?: AxiosRequestConfig<any> | undefined) {
    return http.get<TebligatBilgisiDto[]>(`TebligatBilgisi/GetAll`, config);
  },
  GetAllByIcraFoy(
    icraFoyId: number,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.get<TebligatBilgisiDto[]>(
      `TebligatBilgisi/GetAllByIcraFoy/${icraFoyId}`,
      config
    );
  },
  Create(
    dto: CreateTebligatBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TebligatBilgisiDto>("TebligatBilgisi/Create", dto, config);
  },
  Update(
    dto: UpdateTebligatBilgisiDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.put<TebligatBilgisiDto>("TebligatBilgisi/Update", dto, config);
  },
  Delete(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.delete<NoContentDto>(`TebligatBilgisi/Delete/${id}`, config);
  },
  RetUyarisiKaldir(id: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.put<NoContentDto>(
      `TebligatBilgisi/RetUyarisiKaldir/${id}`,
      config
    );
  },
};

export default services;
