import { Dialog } from "primereact/dialog";
import { FormikErrors, useFormik } from "formik";
import BelgeArsiviService from "../../../../services/belge-arsivi/belge-arsivi.services";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../../../utils/DropdownValues";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../../../utils/FormikValueIsValid";
import { Button } from "primereact/button";
import { useToast } from "../../../../utils/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { IcraFoyDto } from "../../../../services/icra-foy/icra-foy.dto";
import {
  BelgeArsiviDto,
  CreateBelgeArsiviDto,
  UpdateBelgeArsiviDto,
} from "../../../../services/belge-arsivi/belge-arsivi.dto";
import { FileUpload, FileUploadSelectEvent } from "primereact/fileupload";
import { useContext, useRef } from "react";
import { LayoutContext } from "../../../../layout/context/layoutcontext";

const UpsertBelgeArsivi = (props: any) => {
  const belgeArsivi: BelgeArsiviDto = props.belgeArsivi;
  const setBelgeArsivi = props.setBelgeArsivi;
  const visible: boolean = props.visible;
  const setVisible = props.setVisible;
  const refetchBelgeArsivi = props.refetchBelgeArsivi;
  const icraFoy: IcraFoyDto = props.icraFoy;
  const toast = useToast();

  const { setBlocked } = useContext(LayoutContext)
  const listfileUploadRef = useRef<FileUpload>(null);

  let belgeArsiviDto: UpdateBelgeArsiviDto;

  if (belgeArsivi === null || belgeArsivi === undefined) {
    belgeArsiviDto = {
      id: 0,
      tip: "",
      aciklama: "",
      file: undefined,
    };
  } else {
    belgeArsiviDto = {
      ...belgeArsivi,
    };
  }

  const formik = useFormik<UpdateBelgeArsiviDto>({
    initialValues: belgeArsiviDto,
    enableReinitialize: true,
    validate: (data) => {
      let errors: FormikErrors<UpdateBelgeArsiviDto> = {};
      if (!data.tip) {
        errors.tip = "Tip alanı boş bırakılamaz";
      }

      if (!data.aciklama) {
        errors.aciklama = "Açıklama alanı boş bırakılamaz";
      }

      if (belgeArsivi === null || belgeArsivi === undefined) {
        if (!data.file) {
          errors.file = "Belge alanı boş bırakılamaz";
        }
      }

      return errors;
    },
    onSubmit: () => {
      setBlocked(true)
      if (formik.values.id === 0) {
        var belgeArsiviCreateDto: CreateBelgeArsiviDto = {
          ...formik.values,
          icraFoyId: icraFoy.id,
        };
        BelgeArsiviService.Create(belgeArsiviCreateDto, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          if (!res?.result?.error) {
            setVisible(false);
            formik.resetForm();
            setBelgeArsivi(undefined);
            refetchBelgeArsivi();
            toast.show("Belge başarıyla eklendi.", "success");
          }
        });
      } else {
        var belgeArsiviUpdateDto: UpdateBelgeArsiviDto = {
          ...formik.values,
        };
        BelgeArsiviService.Update(belgeArsiviUpdateDto).then((res) => {
          if (!res?.result?.error) {
            setVisible(false);
            formik.resetForm();
            setBelgeArsivi(undefined);
            refetchBelgeArsivi();
            toast.show("Belge başarıyla güncellendi.", "success");
          }
        });
      }
    },
  });

  const onSelectFile = (event: FileUploadSelectEvent) => {
    formik.setFieldValue("file", event.files[0]);
  };

  const onRemoveFile = (e: any) => {
    formik.setFieldValue("file", undefined);
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: false,
    className: "custom-choose-btn",
    label: "Seç",
  };

  const uploadOptions = {
    icon: "",
    iconOnly: true,
    className: "hidden",
    label: "Yükle",
  };

  return (
    <Dialog
      visible={visible}
      modal={true}
      header={
        formik.values.id === 0 ? "Talep Bilgisi Ekle" : "Talep Bilgisi Güncelle"
      }
      onHide={() => {
        setVisible(false);
        formik.resetForm();
        setBelgeArsivi(undefined);
      }}
      footer={
        <div className="flex flex-row gap-3 justify-content-center md:justify-content-end mt-5">
          <Button
            label={"İptal"}
            className="md:px-6 justify-content-center"
            type="button"
            severity="secondary"
            onClick={() => {
              setVisible(false);
              formik.resetForm();
              setBelgeArsivi(undefined);
            }}
          />
          <Button
            label={"Kaydet"}
            className="md:px-6 justify-content-center"
            onClick={formik.submitForm}
          />
        </div>
      }
      className=""
      style={{ width: "75vw" }}
    >
      <div className="grid mt-1">
        {formik.values.id === 0 && (
          <div className="col-12">
            <FileUpload
              name="file[]"
              ref={listfileUploadRef}
              multiple={false}
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}

              accept="*"
              emptyTemplate={
                <p className="m-0">Dosya seçin veya buraya sürükleyin.</p>
              }
              onSelect={onSelectFile}
              onRemove={onRemoveFile}
              mode="advanced"
            />
            {FormikValueIsValid(formik, "file") && (
              <div className="p-error mt-3">{formik.errors.file}</div>
            )}
          </div>
        )}

        <div className="col-12">
          <label className="text-primary" htmlFor="tip">Belge Tipi</label>
          <Dropdown
            id="tip"
            name="tip"
            editable
            value={formik.values.tip}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("tip", e.value);
            }}
            options={DropdownDatas.GetBelgeTipi()}
            optionLabel="label"
            optionValue="value"
            placeholder="Tür Seçiniz"
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "tip"),
            })}
          />
          {FormikValueIsValid(formik, "tip") && (
            <div className="p-error mt-3">{formik.errors.tip}</div>
          )}
        </div>

        <div className="col-12">
          <label className="text-primary" htmlFor="aciklama">Açıklama</label>
          <InputTextarea
            id="aciklama"
            name="aciklama"
            rows={3}
            value={formik.values.aciklama}
            onChange={(e) => {
              formik.setFieldValue("aciklama", e.target.value.toLocaleUpperCase("tr"));
            }}
            className={classNames("w-full mt-2", {
              "p-invalid": FormikValueIsValid(formik, "aciklama"),
            })}
          />
          {FormikValueIsValid(formik, "aciklama") && (
            <div className="p-error mt-3">{formik.errors.aciklama}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpsertBelgeArsivi;
