import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { commonFilterContains, commonFilterDateIs, CreateHeader, Filter } from "../filter/base-filter";
import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { TopluSorguDurum, TopluSorgulanacakDosyaDTO, TopluSorguListesiModel, TopluSorguModel } from "./TopluSorgu.dto";
import { Dialog } from "primereact/dialog";
import { useToast } from "../../utils/toast";
import { filter } from "jszip";
import { ConfirmPopup } from "primereact/confirmpopup";
import { confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { useElectron } from "../../utils/electron-connection";

const TopluSorguListesi = () => {
  const [selectedList, setSelectedList] = useState<TopluSorguListesiModel>();
  const [visibleDosyalar, setVisibleDosyalar] = useState(false);
  const [topluSorguListeleri, setTopluSorguListeleri] = useState<TopluSorguListesiModel[]>([]);
  const toast = useToast();
  const navigate = useNavigate();
  const [filtersListeler, setFiltersListeler] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [filtersDosyalar, setFiltersDosyalar] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValueListeler, setGlobalFilterValueListeler] = useState<string>("");
  const [globalFilterValueDosyalar, setGlobalFilterValueDosyalar] = useState<string>("");
  const dt = useRef<DataTable<TopluSorguListesiModel[]>>(null);
  const electron = useElectron();

  const clearFilterListeler = () => {
    initFiltersListeler();
  };
  const clearFilterDosyalar = () => {
    initFiltersDosyalar();
  };
  const initFiltersListeler = () => {
    setFiltersListeler({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: commonFilterContains,
      olusturulmaTarihi: commonFilterDateIs
    });
    setGlobalFilterValueListeler("");
  };
  const initFiltersDosyalar = () => {
    setFiltersDosyalar({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dosyaNo: commonFilterContains,
      birimAdi: commonFilterContains,
      dosyaTur: commonFilterContains,
      dosyaDurum: commonFilterContains
    });
    setGlobalFilterValueListeler("");
  };
  useEffect(() => {
    initFiltersListeler();
    initFiltersDosyalar();
  }, []);

  const onGlobalFilterChangeListeler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filtersListeler };
    // @ts-ignore
    _filters["global"].value = value;
    setFiltersListeler(_filters);
    setGlobalFilterValueListeler(value);
  };

  const onGlobalFilterChangeDosyalar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filtersDosyalar };
    // @ts-ignore
    _filters["global"].value = value;
    setFiltersDosyalar(_filters);
    setGlobalFilterValueDosyalar(value);
  };

  const readData = async () => {
    const topluSorguListeleri = await electron.getTopluSorguListesi();
    if (topluSorguListeleri) {
      setTopluSorguListeleri(topluSorguListeleri);
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const actionBodyTemplate = (rowData: TopluSorguListesiModel) => {
    return (
      <div className="flex justify-content-center">
        <Toast />
        <ConfirmPopup />
        <Button
          icon="pi pi-search"
          rounded
          outlined
          className="mr-2"
          severity="success"
          tooltip="Dosyaları Görüntüle"
          onClick={() => {
            setSelectedList(rowData);
            setVisibleDosyalar(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          className="mr-2"
          severity="danger"
          tooltip="Listeyi sil"
          onClick={(e) => deletePopup(e, rowData)}
        />
        <Button
          icon="pi pi-file-arrow-up"
          rounded
          outlined
          className="mr-2"
          tooltip="Toplu Sorguya Aktar"
          onClick={async () => {
            try {
              // Statusu BASLATILMADI olan elemanları listeden çıkar
              //   const updatedList = topluSorguSonuclarListesi.filter(
              //     (item) => item.status !== TopluSorguDurum.BASLATILMADI
              //   );

              const model: TopluSorguModel = {
                key: new Date().getTime(),
                status: TopluSorguDurum.BASLATILMADI,
                baslamaTarihi: null,
                listName: rowData.name,
                tabs: [1],
                sorgulanacakDosyalar: rowData.liste,
                sorunluVeOlumsuzListeleri: [],
                tahsilatKalaniListeleri: [],
                mernisListeleri: [],
                mersisListeleri: [],
                iskiListeleri: [],
                gibListeleri: [],
                gsmListeleri: [],
                disIsleriListeleri: [],
                sskCalisaniListeleri: [],
                sskEmeklisiListeleri: [],
                kamuCalisaniListeleri: [],
                kamuEmeklisiListeleri: [],
                bagkurCalisaniListeleri: [],
                bagkurEmeklisiListeleri: [],
                sgkIsyeriListeleri: [],
                egmListeleri: [],
                postaCekiListeleri: [],
                takbisListeleri: [],
                sgkHacizListeleri: [],
                icraDosyasiListeleri: [],
                bankaListeleri: [],
                tarafListeleri: []
              };

              navigate("/app/toplu-sorgulama", { state: { model: model } });
            } catch (error) {
              console.error("Error stopping SignalR connection:", error);
            }
          }}
        />
      </div>
    );
  };

  const deletePopup = (event: { currentTarget: any }, rowData: TopluSorguListesiModel) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Bu listeyi silmek istediğinize emin misiniz?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: async () => {
        let updatedTopluSorguListesi: TopluSorguListesiModel[] = [];

        for await (const item of topluSorguListeleri) {
          if (item.key !== rowData.key) {
            updatedTopluSorguListesi.push(item);
          }
        }

        setTopluSorguListeleri(updatedTopluSorguListesi);

        const res = await electron.setTopluSorguListesi(updatedTopluSorguListesi);
        if (res) {
          toast.show("Liste silinirken sorun oluştu.", "success");
        }

        toast.show("Liste başarıyla silindi", "success");
      },
      reject: () => {}
    });
  };

  const actionBodyTemplateDosyalar = (rowData: TopluSorgulanacakDosyaDTO) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          className="mr-2"
          severity="danger"
          tooltip="Dosyayı sil"
          onClick={(e) => deletePopupDosya(e, rowData)}
        />
      </div>
    );
  };

  const deletePopupDosya = (event: { currentTarget: any }, rowData: TopluSorgulanacakDosyaDTO) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Bu dosyayı silmek istediğinize emin misiniz?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: async () => {
        if (selectedList) {
          let updatedTopluSorgulanacakListesi: TopluSorgulanacakDosyaDTO[] = [];
          for await (const item of selectedList.liste || []) {
            if (item.dosyaNo !== rowData.dosyaNo) {
              updatedTopluSorgulanacakListesi.push(item);
            }
          }

          let updatedTopluSorguListesi: TopluSorguListesiModel[] = [];
          for await (const item of topluSorguListeleri) {
            if (item.key !== selectedList.key) {
              updatedTopluSorguListesi.push(item);
            }
          }
          updatedTopluSorguListesi.push({
            olusturulmaTarihi: selectedList.olusturulmaTarihi,
            key: selectedList.key,
            name: selectedList.name,
            liste: updatedTopluSorgulanacakListesi
          });

          setTopluSorguListeleri(updatedTopluSorguListesi);
          setSelectedList({
            olusturulmaTarihi: selectedList.olusturulmaTarihi,
            key: selectedList.key,
            name: selectedList.name,
            liste: updatedTopluSorgulanacakListesi
          });

          const res = await electron.setTopluSorguListesi(updatedTopluSorguListesi);
          if (!res) {
            toast.show("Dosya silinirken sorun oluştu.", "error");
          }

          toast.show("Dosya başarıyla silindi", "success");
        }
      },
      reject: () => {}
    });
  };

  return (
    <>
      <div className="card">
        <DataTable
          value={topluSorguListeleri}
          ref={dt}
          showGridlines
          stripedRows
          emptyMessage="Hiç Dosya Bulunamadı"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          filters={filtersListeler}
          globalFilterFields={["name", "olusturulmaTarihi"]}
          header={CreateHeader(
            "Toplu Sorgu Listeleri",
            globalFilterValueListeler,
            (e) => onGlobalFilterChangeListeler(e),
            clearFilterListeler
          )}
          columnResizeMode="expand"
          sortField="olusturulmaTarihi"
          sortOrder={-1}
        >
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "8rem" }}></Column>
          <Column
            field="name"
            header="Liste Adı"
            sortable
            filterField="name"
            filter
            filterPlaceholder="Ada göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Ada göre filtreleyin</div>}
          />
          <Column
            field="olusturulmaTarihi"
            header="Oluşturulma Tarihi"
            sortable
            body={(rowData) => moment(rowData.olusturulmaTarihi).format("DD.MM.YYYY HH:mm")}
            filterField="olusturulmaTarihi"
            filter
            filterPlaceholder="Tarihe göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterMatchMode={FilterMatchMode.DATE_IS}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Tarihe göre filtreleyin</div>}
          />
        </DataTable>
      </div>

      <Dialog
        header={selectedList?.name}
        visible={visibleDosyalar}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visibleDosyalar) return;
          setVisibleDosyalar(false);
        }}
      >
        <DataTable
          value={selectedList?.liste}
          showGridlines
          stripedRows
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          emptyMessage="Hiç Dosya Bulunamadı"
          filters={filtersDosyalar}
          globalFilterFields={["birimAdi", "dosyaNo", "dosyaTur", "dosyaDurum"]}
          header={CreateHeader(
            "Dosyalar",
            globalFilterValueDosyalar,
            (e) => onGlobalFilterChangeDosyalar(e),
            clearFilterDosyalar
          )}
          columnResizeMode="expand"
        >
          <Column body={actionBodyTemplateDosyalar} exportable={false} style={{ width: "8rem" }}></Column>

          <Column
            field="birimAdi"
            header="Birim Adı"
            sortable
            filter
            filterField="birimAdi"
            filterPlaceholder="Birim Adına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Birim Adına göre filtreleyin</div>}
          />
          <Column
            field="dosyaNo"
            header="Dosya No"
            sortable
            filter
            filterField="dosyaNo"
            filterPlaceholder="Dosya nuamrasına göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya nuamrasına göre filtreleyin</div>}
          />
          <Column
            field="dosyaTur"
            header="Dosya Türü"
            sortable
            filter
            filterField="dosyaTur"
            filterPlaceholder="Dosya türüne göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya türüne göre filtreleyin</div>}
          />
          <Column
            field="dosyaDurum"
            header="Dosya Durumu"
            sortable
            filter
            filterField="dosyaDurum"
            filterPlaceholder="Dosya durumuna göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => <div className="px-3 pt-0 pb-3 text-center">Dosya durumuna göre filtreleyin</div>}
          />
        </DataTable>
      </Dialog>
    </>
  );
};

export default TopluSorguListesi;
