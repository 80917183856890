import React, { createContext, useContext, useEffect, useState } from "react";
import { CreateOrderDto } from "../services/payment/payment.dto";
import accountService from "../services/account/account.service";
import { UserDto } from "../services/account/account.dto";
import { MonthlyType } from "../services/admin/admin.dto";
import { useAuth } from "./auth";

interface BasketContextModel {
  getBasket: () => CreateOrderDto;
  setBasket: (basket: CreateOrderDto) => void;
  getUser: () => UserDto | undefined;
  setUserInformation: () => void;
  getTotalPriceInBasket: () => number;
  getALicenseTotalPrice: (monthlyType: MonthlyType, count: number) => number;
}

let BasketContext = createContext<BasketContextModel>(null!);

export const useBasket = () => {
  return useContext(BasketContext);
};

export const BasketProvider = ({ children }: { children: React.ReactNode }) => {
  const [order, setOrder] = useState<CreateOrderDto>({ licencesToBeExtended: [], newLicences: [] });
  const [user, setUser] = useState<UserDto>();
  const auth = useAuth();

  const MonthlyPrice = 499;
  const ThreeMonthPrice = 430;
  const SixMonthPrice = 350;
  const TwelvePrice = 250;

  useEffect(() => {
    if (auth.getUser()?.accessToken) {
      setUserInformation();
    }
  }, []);

  const setUserInformation = async () => {
    const res = await accountService.GetUserInformation();
    if (res && res.result?.data) {
      setUser(res.result.data);
    }
  };

  const getUser = (): UserDto | undefined => {
    return user;
  };

  const getBasket = (): CreateOrderDto => {
    return order ?? ({ licencesToBeExtended: [], newLicences: [] } as CreateOrderDto);
  };

  const setBasket = (basket: CreateOrderDto) => {
    setOrder(basket);
  };

  const getTotalPriceInBasket = (): number => {
    let newLicensesTotalPrice = 0;
    let extendedLicensesTotalPrice = 0;

    order.newLicences.forEach((item) => {
      newLicensesTotalPrice += getALicenseTotalPrice(item.month, item.count);
    });

    order.licencesToBeExtended.forEach((item) => {
      extendedLicensesTotalPrice += getALicenseTotalPrice(item.month, 1);
    });

    return newLicensesTotalPrice + extendedLicensesTotalPrice;
  };

  const getALicenseTotalPrice = (monthlyType: MonthlyType, count: number) => {
    switch (monthlyType) {
      case MonthlyType.Monthly:
        return MonthlyPrice * count;
      case MonthlyType.ThreeMonth:
        return ThreeMonthPrice * count * 3;
      case MonthlyType.SixMonth:
        return SixMonthPrice * count * 6;
      case MonthlyType.TwelveMonth:
        return TwelvePrice * count * 12;
      default:
        return 0;
    }
  };

  const value: BasketContextModel = {
    getBasket,
    setBasket,
    setUserInformation,
    getUser,
    getTotalPriceInBasket,
    getALicenseTotalPrice
  };

  return <BasketContext.Provider value={value}>{children}</BasketContext.Provider>;
};
