import React, { useEffect, useRef, useState } from "react";
import { ViewerBaseProps } from "./fileViewer.dto";
import { ProgressSpinner } from "primereact/progressspinner";

interface UDFViewerProps extends ViewerBaseProps {}

export const UdfViewer: React.FC<UDFViewerProps> = ({ file, parentWidth }) => {
  const { data } = file;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeSrc, setIframeSrc] = useState<string>("https://udf.pratikportal.com/?editormode=read");

  useEffect(() => {
    const handleIframeLoad = async () => {
      try {
        if (iframeRef.current && iframeRef.current.contentWindow) {
          const base64 = (await blobToBase64(data)) as string;
          iframeRef.current.contentWindow.postMessage(
            {
              channel: "backup",
              action: "openUDF",
              name: "Belge Adı",
              content: base64?.replace("data:application/download;base64,", "")
            },
            "*"
          );
        } else {
          console.error("Iframe or contentWindow is null.");
        }
      } catch (error) {
        console.error("Error handling iframe load:", error);
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
    } else {
      console.error("Iframe reference is null.");
    }

    const uniqueUrl = `https://udf.pratikportal.com/?editormode=read&timestamp=${Date.now()}`;
    setIframeSrc(uniqueUrl);

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
      }
    };
  }, [data]);

  // useEffect(() => {
  //   const uniqueUrl = `https://udf.pratikportal.com/?editormode=read&timestamp=${Date.now()}`;
  //   setIframeSrc(uniqueUrl);
  // }, [data]);

  return data ? (
    <iframe
      ref={iframeRef}
      src={`https://udf.pratikportal.com/?editormode=read&timestamp=${Date.now()}`}
      width={parentWidth ? parentWidth : "60vw"}
      height="900"
      title="Iframe Example"
    />
  ) : (
    <div className="flex justify-content-center align-items-center h-full">
      <ProgressSpinner />
    </div>
  );
};

function blobToBase64(blob: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error("Failed to convert blob to base64"));
    reader.readAsDataURL(blob);
  });
}
