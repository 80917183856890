import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UyapService from "../../services/uyap-sorgulari/uyap-sorgulari.service";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "../../utils/toast";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { DurusmaRequestDto, DurusmaResponseDto } from "../../services/uyap-sorgulari/uyap-sorgulari.dto";
import { useHub } from "../../utils/buro-hub";
import { FormikErrors, useFormik } from "formik";
import { classNames } from "primereact/utils";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import DosyaDetaylariDialog from "../dosya-detaylari/DosyaDetaylariDialog";
import uyapDosyaService from "../../services/uyap-dosya/uyap-dosya.service";
import { CreateUyapDosyaDto } from "../../services/uyap-dosya/uyap-dosya.dto";
import moment from "moment";
import CreateIcsDiyalog from "./CreateIcsDiyalog";
import { ExportFile } from "../../utils/export-file";
import { commonFilterContains, commonFilterDateIs, Filter, CreateHeader } from "../filter/base-filter";
import uyapService from "../../services/uyap-sorgulari/uyap-sorgulari.service";
import { LayoutContext } from "../../layout/context/layoutcontext";
import {
  locale,
  addLocale,
  updateLocaleOption,
  updateLocaleOptions,
  localeOption,
  localeOptions
} from "primereact/api";

const DurusmaSorgulamaPage = () => {
  const hub = useHub();
  const { setBlocked } = useContext(LayoutContext);
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<DurusmaResponseDto[]>>(null);
  const toast = useToast();
  const [visibleDosyaDetayleriDialog, setVisibleDosyaDetayleriDialog] = useState(false);
  const [visibleCreateIcsDialog, setVisibleCreateIcsDialog] = useState(false);
  const [selectedDurusma, setSelectedDurusma] = useState<DurusmaResponseDto | null>(null);
  const [selectedDurusmas, setSelectedDurusmas] = useState<DurusmaResponseDto[]>([]);
  const handleSetVisibleDosyaDetayleriDialog = (visible: boolean) => {
    setVisibleDosyaDetayleriDialog(visible);
    if (visible === false) {
      setSelectedDurusma(null);
    }
  };
  const formik = useFormik<DurusmaRequestDto>({
    initialValues: {
      baslangicTarihi: new Date(),
      bitisTarihi: new Date(new Date().setDate(new Date().getDate() + 7))
    },
    enableReinitialize: false,
    validate: (data) => {
      let errors: FormikErrors<DurusmaRequestDto> = {};

      if (!data.baslangicTarihi) {
        errors.baslangicTarihi = "Başlangıç tarihi alanı zorunludur.";
      }

      if (!data.bitisTarihi) {
        errors.bitisTarihi = "Bitiş tarihi alanı zorunludur.";
      }

      if (
        data.bitisTarihi &&
        data.baslangicTarihi &&
        new Date(data.bitisTarihi).getTime() - new Date(data.baslangicTarihi).getTime() > 1000 * 60 * 60 * 24 * 30
      ) {
        errors.bitisTarihi = "Başlangıç ve bitiş tarih arasında 30 günden fazla olamaz.";
      }

      return errors;
    },
    onSubmit: () => {
      refetchDurusma();
    }
  });

  const {
    data: durusmaResponse,
    refetch: refetchDurusma,
    isLoading: durusmaResponseIsLoading
  } = useQuery({
    queryKey: ["DurusmaSorgulama"],
    refetchOnMount: true,
    queryFn: async () => await hub.executeUyapService(() => UyapService.AvukatDurusmaSorgula(formik.values))
  });

  useEffect(() => {
    setBlocked(durusmaResponseIsLoading);
  }, [durusmaResponseIsLoading]);

  useEffect(() => {
    initFilters();
  }, []);

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      yerelBirimAd: commonFilterContains,
      dosyaNo: commonFilterContains,
      dosyaTurKodAciklama: commonFilterContains,
      dosyaTaraflari: commonFilterContains,
      tarihSaat: commonFilterDateIs,
      islemTuruAciklama: commonFilterContains,
      islemSonucuAciklama: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const leftToolbarTemplate = () => {
    if (selectedDurusmas.length === 0) {
      return null;
    }
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Seçili Duruşmaları Takvime Ekle"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setVisibleCreateIcsDialog(true);
          }}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const handleAddToFavorites = async (rowData: DurusmaResponseDto) => {
    setBlocked(true);
    try {
      const responseEvraklar = await hub.executeUyapService(() => uyapService.GetAllEvrak(rowData.dosyaId));
      const sonEvrakId = responseEvraklar?.result?.son20Evrak?.[0]?.evrakId;
      if (sonEvrakId) {
        const res = await uyapDosyaService.Create({
          birimAdi: rowData.yerelBirimAd,
          dosyaNo: rowData.dosyaNo,
          dosyaTur: rowData.dosyaTurKodAciklama,
          sonEvrakId: sonEvrakId
        } as CreateUyapDosyaDto);

        if (res && res.result && !res.result.error) {
          toast.show("Sık kullanılan dosyalarınıza başarıyla eklendi.", "success");
        }
      } else {
        toast.show("Evrak ID'si bulunamadı.", "error");
      }
    } catch (error) {
      toast.show("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
      setBlocked(false);
    }
    setBlocked(false);
  };

  const actionBodyTemplate = (rowData: DurusmaResponseDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-search"
          rounded
          outlined
          className="mr-2"
          severity="success"
          tooltip="Detay"
          onClick={() => {
            setSelectedDurusma(rowData);
            setVisibleDosyaDetayleriDialog(true);
          }}
        />
        <Button
          icon="pi pi-plus"
          rounded
          outlined
          className="mr-2"
          tooltip="Sık Kullanılan Dosyalarıma Ekle"
          onClick={() => handleAddToFavorites(rowData)}
        />
      </div>
    );
  };

  const exportExcel = () => {
    const data = durusmaResponse?.result || [];

    const filteredData = data.map((data) => ({
      yerelBirimAd: data.yerelBirimAd,
      dosyaNo: data.dosyaNo,
      dosyaTurKodAciklama: data.dosyaTurKodAciklama,
      tarihSaat: data.tarihSaat,
      dosyaTaraflari: data.dosyaTaraflari
        .map((taraf) => `${taraf.isim ?? ""} ${taraf.soyad ?? ""} - ${taraf.sifat ?? ""}`)
        .join(", "),
      islemTuruAciklama: data.islemTuruAciklama,
      islemSonucuAciklama: data.islemSonucuAciklama
    }));

    const headers = ["Birim", "Dosya No", "Dosya Türü", "Duruşma Tarihi", "Taraf Bilgisi", "İşlem", "Sonuç"];

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "Durusmalar");
  };

  return (
    <div>
      <div className="card">
        <div className="grid">
          <div className="col-12 md:col-3">
            <label className="text-primary" htmlFor="baslangicTarihi">
              Başlangıç Tarihi
            </label>
            <Calendar
              value={formik.values.baslangicTarihi}
              onChange={(e) => formik.setFieldValue("baslangicTarihi", e.value)}
              dateFormat="dd/mm/yy"
              showIcon
              mask="99/99/9999"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "baslangicTarihi")
              })}
              locale="tr"
            />
            {FormikValueIsValid(formik, "baslangicTarihi") && (
              <div className="p-error mt-3">
                {formik.errors.baslangicTarihi && "Başlangıç tarihi alanı zorunludur."}
              </div>
            )}
          </div>
          <div className="col-12 md:col-3">
            <label className="text-primary" htmlFor="bitisTarihi">
              Bitiş Tarihi
            </label>
            <Calendar
              value={formik.values.bitisTarihi}
              onChange={(e) => formik.setFieldValue("bitisTarihi", e.value)}
              dateFormat="dd/mm/yy"
              showIcon
              mask="99/99/9999"
              className={classNames("w-full mt-2", {
                "p-invalid": FormikValueIsValid(formik, "bitisTarihi")
              })}
              locale="tr"
            />
            {FormikValueIsValid(formik, "bitisTarihi") && (
              <div className="p-error mt-3">
                {formik.errors.bitisTarihi && !formik.values.bitisTarihi && "Bitiş tarihi alanı zorunludur."}
                {formik.errors.bitisTarihi &&
                  formik.values.bitisTarihi &&
                  "Başlangıç ve bitiş tarih arasında 30 günden fazla olamaz."}
              </div>
            )}
          </div>
          <div className="col-12 md:col-3 mt-auto">
            <Button label={"Sorgula"} className="px-7 md:px-8" onClick={() => formik.submitForm()} />
          </div>
        </div>
      </div>
      <div className="card">
        <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>
        <DataTable
          ref={dt}
          value={
            durusmaResponse?.result?.map((value, index) => {
              return { ...value, id: index };
            }) || []
          }
          dataKey="id"
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Toplam {totalRecords} duruşma"
          filters={filters}
          // filterDisplay="row"
          loading={durusmaResponseIsLoading}
          globalFilterFields={[
            "yerelBirimAd",
            "dosyaNo",
            "dosyaTurKodAciklama",
            "tarihSaat",
            "dosyaTaraflari",
            "islemTuruAciklama",
            "islemSonucuAciklama"
          ]}
          emptyMessage="Duruşma bulunamadı."
          header={CreateHeader("Duruşmalar", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
          columnResizeMode="expand"
          selectionMode={"multiple"}
          selection={selectedDurusmas}
          onSelectionChange={(e) => setSelectedDurusmas(e.value)}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
          <Column
            field="yerelBirimAd"
            header="Birim"
            sortable
            filterField="yerelBirimAd"
            filter
            filterPlaceholder="Birim Ad'a göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Birim Ad'a göre arayın</div>;
            }}
          ></Column>
          <Column
            field="dosyaNo"
            header="Dosya No"
            sortable
            filterField="dosyaNo"
            filter
            filterPlaceholder="Dosya No'ya göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Dosya No'ya göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="dosyaTurKodAciklama"
            header="Dosya Türü"
            sortable
            filterField="dosyaTurKodAciklama"
            filter
            filterPlaceholder="Dosya Türüne göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Dosya Türüne göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="tarihSaat"
            header="Duruşma Tarihi"
            sortable
            filterField="tarihSaat"
            filter
            filterPlaceholder="Duruşma Tarihine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterElement={Filter.DateFilterTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Duruşma Tarihine göre filtreleyin</div>;
            }}
            body={(rowData: DurusmaResponseDto) => {
              const date = new Date(rowData.tarihSaat);
              return <span>{date ? `${date.toLocaleString("tr-TR")}` : ""}</span>;
            }}
          ></Column>
          <Column
            field="dosyaTaraflari"
            header="Taraf Bilgisi"
            sortable
            body={(rowData: DurusmaResponseDto) => {
              return (
                <span>
                  {rowData.dosyaTaraflari
                    .map((taraf) => `${taraf.isim ?? ""} ${taraf.soyad ?? ""} - ${taraf.sifat ?? ""}`)
                    .join(", ")}
                </span>
              );
            }}
            filterField="dosyaTaraflari"
            filter
            filterPlaceholder="Taraf Bilgisine göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Taraf Bilgisine göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="islemTuruAciklama"
            header="İşlem"
            sortable
            filterField="islemTuruAciklama"
            filter
            filterPlaceholder="İşleme göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">İşleme göre filtreleyin</div>;
            }}
          ></Column>
          <Column
            field="islemSonucuAciklama"
            header="Sonuç"
            sortable
            filterField="islemSonucuAciklama"
            filter
            filterPlaceholder="Sonuca göre arayın"
            filterClear={Filter.FilterClearTemplate}
            filterApply={Filter.FilterApplyTemplate}
            filterFooter={() => {
              return <div className="px-3 pt-0 pb-3 text-center">Sonuca göre filtreleyin</div>;
            }}
          ></Column>
        </DataTable>
      </div>

      {selectedDurusma && (
        <DosyaDetaylariDialog
          visible={visibleDosyaDetayleriDialog}
          handleSetVisibleDosyaDetayleriDialog={handleSetVisibleDosyaDetayleriDialog}
          dosyaId={selectedDurusma.dosyaId}
          dosyaTur={selectedDurusma.dosyaTurKodAciklama}
          dosyaNo={selectedDurusma.dosyaNo}
          birimAdi={selectedDurusma.yerelBirimAd}
          dosyaTurKod={selectedDurusma.dosyaTurKod}
        />
      )}

      <CreateIcsDiyalog
        durusmalar={selectedDurusmas}
        onComplete={() => {
          setSelectedDurusmas([]);
          setVisibleCreateIcsDialog(false);
        }}
        visible={visibleCreateIcsDialog}
        setVisible={setVisibleCreateIcsDialog}
      />
    </div>
  );
};

export default DurusmaSorgulamaPage;
