import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { CreateOrderDto, PaytrPaymentLinkDto } from "./payment.dto";

const services = {
  CreatePayment(dto: CreateOrderDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<PaytrPaymentLinkDto>("Payment/CreatePayment", dto, config);
  }
};

export default services;
