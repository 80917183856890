import { useState, useCallback } from "react";
import JSZip from "jszip";

interface IZip {
  files?: JSZip.JSZipObject[];
  id?: string;
}

export const useFileViewer = () => {
  const [zipFiles, setZipFiles] = useState<IZip>();

  const unarchiveZip = useCallback(async (blob: Blob, id: string) => {
    try {
      const text = await blob?.text()
      const { files } = await JSZip.loadAsync(blob);
      const fileList: JSZip.JSZipObject[] = [];

      const keys = Object.keys(files);
      for (let index = 0; index < keys.length; index++) {
        const file = files[keys[index]];

        if (!file.dir) {
          const content = await file.async("text");
          // console.log(`File: ${keys[index]}, Content:`, content);

          if (keys[index].endsWith(".html")) {
            // console.log("HTML Content:", content);
          }

          fileList.push(file);
        }
      }

      setZipFiles({ files: fileList, id });
      return files;
    } catch (error) {
      console.error("Error handling PKCS7 file:", error);
      throw error;
    }
  }, []);

  const clearZipFiles = useCallback(() => {
    setZipFiles({});
  }, []);

  function getMimeTypeFromExtension(fileName: string): string | null {
    const extensionToMimeType: { [key: string]: string } = {
      html: "text/html",
      pdf: "application/pdf",
      jpeg: "image/jpeg",
      jpg: "image/jpeg",
      png: "image/png",
      tif: "image/tiff",
      tiff: "image/tiff",
      zip: "application/pkcs7-mime",
      eyp: "application/eyp",
      xml: "text/xml",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      docx: "application/msword"
    };
  
    const parts = fileName.split(".");
    if (parts.length > 1) {
      const extension = parts[parts.length - 1].toLowerCase();
      return extensionToMimeType[extension] || null;
    }
  
    return null;
  }

  const availableMimeTypes: { [key: string]: string } = {
    "text/html": "html",
    "image/jpeg": "jpg",
    "image/png": "png",
    "application/pdf": "pdf",
    "text/udf": "udf",
    "application/jasperreport": "pdf",
    "image/tiff": "tiff",
    "application/stylereport": "pdf",
    "application/pkcs7-mime": "zip",
    "application/eyp": "zip",
    "text/xml" : "xml",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "xlsx",
    "application/msword" : "docx"
  };

  return {
    unarchiveZip,
    clearZipFiles,
    zipFiles,
    getMimeTypeFromExtension,
    availableMimeTypes
  };
};
