import {
  Column,
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions
} from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { KarsiYanDto } from "../../../../services/karsi-yan/karsi-yan.dto";
import { AddressDto } from "../../../../services/address/address.dto";
import { MuvekkilTipi } from "../../../../services/muvekkil/muvekkil.dto";
import UpsertKarsiYan from "../../../karsi-yan/UpsertKarsiYan";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import AddressService from "../../../../services/address/address.service";
import { useToast } from "../../../../utils/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { IcraFoyKarsiYanDto } from "../../../../services/icra-foy/icra-foy.dto";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { commonFilterContains, CreateHeader, Filter } from "../../../filter/base-filter";
import { ExportFile } from "../../../../utils/export-file";

const AdresBilgileri = (props: {
  borclular: IcraFoyKarsiYanDto[];
  onUpsert: any;
  selectedBorcluId: number | undefined;
}) => {
  const [visibleUpsertKarsiYanDialog, setVisibleUpsertKarsiYanDialog] = useState<boolean>(false);
  const toast = useToast();
  const borclular: KarsiYanDto[] = props.borclular.map((p) => p.karsiYan);
  const [karsiYan, setKarsiYan] = useState<KarsiYanDto>();
  const adresler: AddressDto[] = borclular.map((borclu) => borclu.adresler).flat();
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const dt = useRef<DataTable<AddressDto[]>>(null);

  const deleteAddress = (rowData: AddressDto) => {
    AddressService.Delete(rowData.id!).then((res) => {
      if (!res.result?.error) {
        toast.show("Adres başarıyla silindi.", "success");
        props.onUpsert();
      }
    });
  };

  const deleteAddressConfirm = (rowData: AddressDto) => {
    confirmDialog({
      message: "Adresi silmek istediğinize emin misiniz?",
      header: "Adres Silme",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteAddress(rowData),
      reject: () => {},
      acceptLabel: "Evet",
      rejectLabel: "Hayır"
    });
  };

  const actionBodyTemplate = (rowData: AddressDto) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          tooltip="Düzenle"
          className="mr-2"
          onClick={() => {
            setKarsiYan(borclular.find((p) => p.id === rowData.karsiYanId));
            setVisibleUpsertKarsiYanDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Sil"
          rounded
          outlined
          severity="danger"
          onClick={() => {
            setKarsiYan(borclular.find((p) => p.id === rowData.karsiYanId));
            deleteAddressConfirm(rowData);
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      creationTime: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
      },
      "il.ilText": commonFilterContains,
      "ilce.ilceText": commonFilterContains,
      address: commonFilterContains,
      mernisMi: { value: null, matchMode: FilterMatchMode.CONTAINS },
      tasinmaTarihi: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
      },
      tescilTarihi: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
      },
      aciklama: commonFilterContains
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Yeni Adres"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            setKarsiYan(borclular.find((p) => p.id === props.selectedBorcluId));
            setVisibleUpsertKarsiYanDialog(true);
          }}
        />
      </div>
    );
  };

  // export excel
  const exportExcel = () => {
    const data = adresler || [];

    const filteredData = data.map((data) => ({
      creationTime: data.creationTime,
      adSoyad: borclular.find((p) => p.id === data.karsiYanId)?.adSoyad,
      tcNo: borclular.find((p) => p.id === data.karsiYanId)?.tcNo,
      tip: borclular.find((p) => p.id === data.karsiYanId)?.tip === MuvekkilTipi.Gercek ? "Gerçek" : "Tüzel",
      il: data.il?.ilText,
      ilce: data.ilce?.ilceText,
      address: data.address,
      borcluTip: data.tip,
      mernisMi: data.mernisMi ? "Evet" : "Hayır",
      tasinmaTarihi: data.tasinmaTarihi ? new Date(data.tasinmaTarihi).toLocaleDateString() : "",
      tescilTarihi: data.tescilTarihi ? new Date(data.tescilTarihi).toLocaleDateString() : "",
      aciklama: data.aciklama
    }));

    const headers = [
      "Kayıt Tarihi",
      "Borçlu",
      "TC No",
      "Borçlu Tipi",
      "İl",
      "İlçe",
      "Adres",
      "Tip",
      "Mernis Mi?",
      "Taşınma Tarihi",
      "Tescil Tarihi",
      "Açıklama"
    ];

    const worksheetData = [headers, ...filteredData.map(Object.values)];

    ExportFile.SaveAsExcelFile(worksheetData, "AdresBilgileri");
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-row gap-2">
        <Button
          type="button"
          icon="pi pi-file-export"
          rounded
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => exportCSV()}
          data-pr-tooltip="CSV"
        />

        <Button
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          tooltip="XLS"
          tooltipOptions={{ position: "top" }}
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      </div>
    );
  };

  const [representatives] = useState([
    { label: "Evet", value: true },
    { label: "Hayır", value: false }
  ]);

  const representativesItemTemplate = (option: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.label}</span>
      </div>
    );
  };

  const representativeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e: MultiSelectChangeEvent) => {
          options.filterCallback(e.value);
        }}
        optionLabel="label"
        optionValue="value"
        placeholder="Mernis mi?"
        className="p-column-filter"
      />
    );
  };

  return (
    <>
      <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

      <DataTable
        value={adresler}
        showGridlines
        filters={filters}
        header={CreateHeader("Adresler", globalFilterValue, (e) => onGlobalFilterChange(e), clearFilter)}
        globalFilterFields={[
          "creationTime",
          "il.ilText",
          "ilce.ilceText",
          "address",
          "mernisMi",
          "tasinmaTarihi",
          "tescilTarihi",
          "aciklama"
        ]}
      >
        <Column body={actionBodyTemplate} exportable={false} style={{ width: "9rem" }}></Column>
        <Column
          field="creationTime"
          header="Kayıt Tarihi"
          sortable
          body={(rowData: AddressDto) => {
            return <span>{rowData.creationTime ? new Date(rowData.creationTime).toLocaleDateString() : ""}</span>;
          }}
          filterField="creationTime"
          filter
          filterPlaceholder="Kayıt Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Kayıt Tarihine göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="adSoyad"
          header="Borçlu"
          sortable
          body={(rowData: AddressDto) => {
            return <span>{borclular.find((p) => p.id === rowData.karsiYanId)?.adSoyad}</span>;
          }}
        ></Column>
        <Column
          field="tcNo"
          header="TC No"
          sortable
          body={(rowData: AddressDto) => {
            return <span>{borclular.find((p) => p.id === rowData.karsiYanId)?.tcNo}</span>;
          }}
        ></Column>
        <Column
          field="tip"
          header="Borçlu Tipi"
          sortable
          body={(rowData: AddressDto) => {
            return (
              <span>
                {borclular.find((p) => p.id === rowData.karsiYanId)?.tip === MuvekkilTipi.Gercek ? "Gerçek" : "Tüzel"}
              </span>
            );
          }}
        ></Column>
        <Column
          field="il"
          header="İl"
          sortable
          body={(rowData: AddressDto) => {
            return <span>{rowData.il?.ilText}</span>;
          }}
          filterField="il.ilText"
          filter
          filterPlaceholder="İl'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">İl'e göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="ilce"
          header="İlçe"
          sortable
          body={(rowData: AddressDto) => {
            return <span>{rowData.ilce?.ilceText}</span>;
          }}
          filterField="ilce.ilceText"
          filter
          filterPlaceholder="İlçe'ye göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">İlçe'ye göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="address"
          header="Adres"
          sortable
          filterField="address"
          filter
          filterPlaceholder="Adres'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Adres'e göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="tip"
          header="Tip"
          sortable
          filterField="tip"
          filter
          filterPlaceholder="Tip'e göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Tip'e göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="mernisMi"
          header="Mernis Mi?"
          body={(rowData: AddressDto) => {
            return <span>{rowData.mernisMi ? "Evet" : "Hayır"}</span>;
          }}
          showFilterMatchModes={false}
          filter
          filterElement={representativeFilterTemplate}
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
        ></Column>
        <Column
          field="tasinmaTarihi"
          header="Taşınma Tarihi"
          body={(rowData: AddressDto) => {
            return <span>{rowData.tasinmaTarihi ? new Date(rowData.tasinmaTarihi).toLocaleDateString() : ""}</span>;
          }}
          sortable
          filterField="tasinmaTarihi"
          filter
          filterPlaceholder="Taşınma Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Taşınma Tarihine göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="tescilTarihi"
          header="Tescil Tarihi"
          body={(rowData: AddressDto) => {
            return <span>{rowData.tescilTarihi ? new Date(rowData.tescilTarihi).toLocaleDateString() : ""}</span>;
          }}
          sortable
          filterField="tescilTarihi"
          filter
          filterPlaceholder="Tescil Tarihine göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterElement={Filter.DateFilterTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Tescil Tarihine göre filtreleyin</div>;
          }}
        ></Column>
        <Column
          field="aciklama"
          header="Açıklama"
          sortable
          filterField="aciklama"
          filter
          filterPlaceholder="Açıklamaya göre arayın"
          filterClear={Filter.FilterClearTemplate}
          filterApply={Filter.FilterApplyTemplate}
          filterFooter={() => {
            return <div className="px-3 pt-0 pb-3 text-center">Açıklamaya göre filtreleyin</div>;
          }}
        ></Column>
      </DataTable>

      <UpsertKarsiYan
        visible={visibleUpsertKarsiYanDialog}
        setVisible={setVisibleUpsertKarsiYanDialog}
        karsiYan={karsiYan}
        setKarsiYan={setKarsiYan}
        refetchKarsiYan={props.onUpsert}
      />
    </>
  );
};

export default AdresBilgileri;
